import React from "react";

const WalmartLogo = () => (
  <svg
    width="105"
    height="26"
    viewBox="0 0 112 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100.256 0.00276246C101.589 0.607742 101.649 0.737578 101.511 2.23483C101.329 4.21276 101.185 6.19621 101.004 8.17413C100.909 9.21558 100.096 9.7294 99.1494 9.30398C98.8594 9.17414 98.5259 8.78464 98.4934 8.48077C98.2385 6.16582 98.0542 3.84259 97.8427 1.52212C97.7587 0.610505 98.4717 0.364645 99.0437 0H100.256V0.00276246Z"
      fill="#FFFFFF"
    />
    <path
      d="M99.044 28C97.9623 27.5746 97.7508 26.7983 97.9081 25.663C98.1738 23.7209 98.2551 21.7541 98.4367 19.7982C98.5153 18.9391 98.9355 18.5662 99.738 18.5745C100.513 18.58 100.923 18.9391 100.999 19.7623C101.205 21.9889 101.389 24.221 101.587 26.4503C101.674 27.4447 100.939 27.6961 100.259 28H99.0467H99.044Z"
      fill="#FFFFFF"
    />
    <path
      d="M0 5.92248C1.12778 5.92248 2.15796 5.93906 3.18814 5.9142C3.61106 5.90591 3.63545 6.16282 3.6951 6.47498C4.40267 10.312 5.11837 14.1491 5.83408 17.9862C5.84763 18.0635 5.86932 18.1409 5.91541 18.3536C6.85884 14.1325 7.77244 10.0496 8.68876 5.95287H12.2836C13.0996 9.98607 13.9183 14.0331 14.7451 18.116C15.5584 14.0414 16.3636 10.0082 17.1715 5.95563H20.5331C20.4979 6.16834 20.4789 6.36172 20.4328 6.5468C19.1478 11.5386 17.8492 16.5248 16.5805 21.5221C16.4477 22.047 16.2145 22.2514 15.6913 22.3232C13.9047 22.5636 12.7282 21.8288 12.3432 20.0608C11.6302 16.7845 10.9714 13.4999 10.2964 10.2596C10.0876 11.2237 9.87889 12.2679 9.6349 13.3066C8.98697 16.058 8.30651 18.8039 7.67485 21.5608C7.55556 22.0746 7.32242 22.2431 6.83173 22.3067C4.99096 22.5498 3.90927 21.9973 3.47551 20.2707C2.27995 15.5386 1.17115 10.7872 0 5.92248Z"
      fill="#FFFFFF"
    />
    <path
      d="M47.4829 22.1549H44.2676C44.2568 21.8924 44.2351 21.6521 44.2351 21.4118C44.2351 19.3316 44.2486 17.2515 44.2215 15.1714C44.2161 14.6769 44.1456 14.1575 43.9803 13.699C43.4896 12.3536 41.9931 12.0387 40.9765 13.0387C40.4126 13.594 40.1821 14.2874 40.1848 15.0802C40.193 17.4117 40.1848 19.7405 40.1848 22.1328H36.9832V10.0415H40.0059V11.3951C40.9765 10.2459 42.1449 9.67681 43.6034 9.71272C45.0891 9.74863 46.168 10.4254 46.862 11.8481C47.0166 11.6741 47.1467 11.5304 47.2687 11.384C48.3585 10.0746 49.7547 9.5304 51.4084 9.75692C52.9727 9.97239 53.9161 10.9779 54.4257 12.4448C54.5532 12.8094 54.6887 13.1962 54.6914 13.5719C54.7131 16.4034 54.7023 19.2322 54.7023 22.1797C53.7724 22.1797 52.7964 22.1963 51.8232 22.1549C51.7202 22.1493 51.5494 21.7902 51.5494 21.5941C51.5277 19.6272 51.5494 17.6603 51.5277 15.6962C51.5223 15.1299 51.487 14.547 51.3569 14.0001C51.1021 12.9255 50.4677 12.4476 49.4971 12.4807C48.5618 12.5139 47.7946 13.1548 47.5967 14.1189C47.5154 14.5194 47.4883 14.9338 47.4856 15.3426C47.4747 17.3786 47.4802 19.4117 47.4802 21.4477C47.4802 21.6687 47.4802 21.8924 47.4802 22.1521L47.4829 22.1549Z"
      fill="#FFFFFF"
    />
    <path
      d="M66.4276 22.1687C64.8525 22.6189 64.0446 22.3123 63.2259 20.9421C63.0741 21.0747 62.9304 21.2018 62.784 21.3261C61.4285 22.4781 59.8832 22.7488 58.2431 22.1935C56.9228 21.7488 56.1285 20.7377 55.9468 19.3261C55.7327 17.6714 56.2695 16.304 57.6738 15.3675C58.994 14.489 60.4986 14.257 62.033 14.152C62.3177 14.1327 62.6078 14.1492 62.8843 14.1492C62.9819 13.1299 62.5725 12.4697 61.6888 12.2238C60.4146 11.8702 59.2028 12.1741 58.0208 12.663C57.8364 12.7404 57.6602 12.8371 57.3864 12.9697C57.1695 12.2348 56.9635 11.5304 56.7357 10.7542C59.0753 9.6768 61.4366 9.20442 63.8494 10.221C65.7092 11.0055 66.2324 12.7238 66.292 14.5995C66.3463 16.3122 66.2975 18.0277 66.3164 19.7405C66.3246 20.536 66.3896 21.3316 66.4303 22.1659L66.4276 22.1687ZM63.0578 16.3426C61.9626 16.2819 60.9703 16.373 60.0649 16.8924C59.3898 17.2791 59.1187 17.9062 59.2109 18.6797C59.2922 19.3537 59.6555 19.8068 60.3197 19.9836C61.5776 20.3151 62.9385 19.3731 63.0524 18.0554C63.0985 17.5167 63.0605 16.9697 63.0605 16.3454L63.0578 16.3426Z"
      fill="#FFFFFF"
    />
    <path
      d="M30.1959 22.1356C28.7184 22.655 27.9024 22.3566 26.9481 20.9367C26.1321 21.8152 25.1371 22.3097 23.9551 22.434C22.1062 22.6301 20.4796 21.7047 19.8859 20.0831C19.2624 18.3787 19.8561 16.4753 21.3688 15.4228C22.7162 14.4836 24.2723 14.2654 25.8501 14.1521C26.1131 14.1328 26.3815 14.1521 26.6363 14.1521C26.7692 13.1631 26.3598 12.4891 25.4706 12.2322C24.294 11.8924 23.1581 12.1548 22.0493 12.5692C21.7782 12.6714 21.518 12.804 21.1438 12.9698C20.9324 12.2405 20.7291 11.5388 20.5067 10.7681C22.8274 9.67691 25.1751 9.22387 27.5689 10.2018C29.4639 10.9753 30.0061 12.7101 30.0576 14.6217C30.1064 16.3814 30.0603 18.1439 30.082 19.9036C30.0901 20.6522 30.1606 21.3981 30.2013 22.1356H30.1959ZM26.7936 16.3345C25.7173 16.2875 24.7034 16.3621 23.7871 16.9201C23.1256 17.3234 22.8599 17.9588 22.9738 18.7295C23.0741 19.4091 23.4726 19.8317 24.1314 19.992C25.2754 20.271 26.5957 19.4478 26.7773 18.2682C26.8722 17.6521 26.7936 17.0085 26.7936 16.3345Z"
      fill="#FFFFFF"
    />
    <path
      d="M82.1486 19.5746C82.1486 20.3536 82.1703 21.058 82.1324 21.7597C82.1242 21.9199 81.9046 22.1879 81.7664 22.1989C80.7904 22.279 79.7928 22.4531 78.8385 22.3177C77.2797 22.0967 76.3769 20.8785 76.282 19.0083C76.198 17.3204 76.2251 15.627 76.2197 13.9392C76.2115 11.5413 76.2197 9.14076 76.2197 6.74294C76.2197 6.49708 76.2197 6.25122 76.2197 5.96393H79.3861V9.75956H82.1758V12.8701H79.4024C79.4024 13.5635 79.3997 14.1933 79.4024 14.8259C79.4078 15.9005 79.3672 16.9779 79.4431 18.047C79.538 19.3508 79.9988 19.71 81.2784 19.6437C81.5224 19.6298 81.7664 19.605 82.1486 19.5774V19.5746Z"
      fill="#FFFFFF"
    />
    <path d="M31.8704 5.94452H35.007V22.1464H31.8704V5.94452Z" fill="#FFFFFF" />
    <path
      d="M67.9106 10.0136H70.7518V12.0441C71.6952 10.4694 72.888 9.48048 74.84 9.77607V12.8949C74.4713 12.8949 74.1405 12.8866 73.8098 12.8949C72.4218 12.9336 71.4106 13.754 71.294 15.1573C71.1666 16.6795 71.2154 18.2154 71.1964 19.743C71.1855 20.5165 71.1964 21.2928 71.1964 22.1298H67.9161V10.0136H67.9106Z"
      fill="#FFFFFF"
    />
    <path
      d="M111.994 8.48068C111.82 8.69339 111.601 9.19892 111.226 9.38953C109.201 10.4172 107.141 11.3702 105.086 12.3343C104.528 12.5968 103.988 12.3619 103.627 11.7487C103.232 11.0746 103.28 10.4918 103.812 10.1105C105.661 8.77626 107.52 7.46409 109.369 6.12982C109.811 5.81214 110.25 5.81766 110.692 6.07181C111.503 6.5359 111.874 7.28453 111.994 8.48068Z"
      fill="#FFFFFF"
    />
    <path
      d="M111.997 19.5114C111.886 20.6992 111.528 21.4562 110.72 21.9258C110.259 22.1937 109.807 22.191 109.34 21.854C107.532 20.5418 105.708 19.2572 103.894 17.9534C103.303 17.5279 103.222 16.9727 103.62 16.2793C103.994 15.6301 104.526 15.4119 105.133 15.6964C107.166 16.6522 109.208 17.5942 111.214 18.6081C111.591 18.7987 111.818 19.2959 112 19.5141L111.997 19.5114Z"
      fill="#FFFFFF"
    />
    <path
      d="M89.5233 5.89204C89.6182 5.93624 89.8595 6.00254 90.0547 6.14067C91.8981 7.44455 93.7362 8.75396 95.5661 10.0717C96.1517 10.4943 96.2222 11.0634 95.8101 11.7485C95.4225 12.3949 94.9128 12.591 94.292 12.2982C92.2777 11.3507 90.2634 10.4032 88.2573 9.43629C88.0268 9.32579 87.7828 9.1407 87.6554 8.92247C87.0238 7.83958 88.1434 5.88928 89.5233 5.88928V5.89204Z"
      fill="#FFFFFF"
    />
    <path
      d="M87.4678 19.8843C87.4868 19.2434 87.7036 18.8208 88.2431 18.5666C90.2791 17.6136 92.3069 16.6384 94.3402 15.6826C94.9149 15.4119 95.4219 15.6191 95.8014 16.2461C96.2053 16.9147 96.1674 17.5003 95.636 17.8843C93.7708 19.2324 91.9084 20.5887 90.0026 21.876C89.7016 22.0805 89.1079 22.0777 88.7609 21.9202C87.9205 21.5335 87.5572 20.7434 87.4678 19.8871V19.8843Z"
      fill="#FFFFFF"
    />
  </svg>
);
export default WalmartLogo;
