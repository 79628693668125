import React, { SyntheticEvent } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  panelTitle: string;
  panelDetails: string | JSX.Element;
}

const ProductDetailsAccordion = ({ panelDetails, panelTitle }: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (e: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      disableGutters
      sx={{
        backgroundColor: "transparent",
      }}
      square
      elevation={0}
      expanded={expanded === panelTitle}
      onChange={handleChange(panelTitle)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 0,
          borderBottom: "1px solid black",
        }}
      >
        <Typography variant="subtitle2" color="black">
          {panelTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pl: 0.5, borderBottom: "1px solid black" }}>
        <Typography variant="body1">{panelDetails}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
export default React.memo(ProductDetailsAccordion);
