import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import IcoBottle from "../ico-bottle-images/ico-polish-bottle";
import { CollectionProductInterface } from "../../pages/shop-all/interfaces/collection-interfaces";
import ShopifyProductBottleBackground from "../product-backgrounds/shopify-product-background";
import AddToCartButton from "../add-to-cart-button";
import useCartActions from "../../pages/cart/hooks/useCartActions";
import ShopifyCardBadge from "./shopify-card-badge";
import { getProductMaterial } from "../../utils/product-material";
import { AddToCartInterface } from "../../pages/cart/interfaces/add-to-cart.interface";
import PixelService from "../../services/pixels/pixel-service";
import { useAppSelector } from "../../utils/hooks/redux-hooks";
import { selectCartTotal } from "../../pages/cart/state/cart-slice";
import { selectIsWalmartPath } from "../../pages/walmart/state/walmart-slice";
import { WALMART } from "../../routing/CONSTANTS";
import { useKioskHook } from "../../utils/hooks/useKioskHook";
import cuticleOil from "../../assets/cuticleOilBottleRender.webp";
import cuticleOilPng from "../../assets/cuticleOilBottleRender.png";
interface ShopifyPolishProductCardProps {
  product: CollectionProductInterface;
  landing: boolean;
}

const ShopifyPolishProductCard = ({
  product,
  landing,
}: ShopifyPolishProductCardProps) => {
  const navigate = useNavigate();
  const { createOrAddToCart } = useCartActions();
  const cartSubTotal = useAppSelector(selectCartTotal);
  const isWalmart = useAppSelector(selectIsWalmartPath);



  // Get a specific query parameter by name
  const isKiosk = useKioskHook()

  const createCartOrAddNewCartItem = () => {
    const variantMaterial = getProductMaterial(product.variants[0]);
    const itemToAdd: AddToCartInterface = {
      merchId: product.variants[0].variantId,
      key: product.title,
      value: `${variantMaterial} / 15ml`,
      quantity: 1,
      img: product.image,
    };

    createOrAddToCart(itemToAdd, 1);
    PixelService.pixelAddToCart(
      product.productId,
      parseInt(product.variants[0].price),
      product.title,
      product.variants[0].variantId,
      "shop-all",
      cartSubTotal
    );
  };
  const handleNavigateToProductPage = () => {
    const productTitle = product.handle;
    let productUrl

    if (isWalmart) {
      productUrl = `${WALMART}/products/${productTitle}`
    } else {
      productUrl = `/products/${productTitle}`
    }

    if (isKiosk) {
      productUrl = `${productUrl}?isKiosk=1`

    }

    navigate(productUrl);

  };
  const productPrice = parseInt(product.variants[0].price.split(".")[0]);

  return (
    <Box
      sx={{
        width: "100%",
        py: { xs: 3, md: "10%" },
        mb: { xs: 2, md: 0 },

        textAlign: "center",
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        onClick={handleNavigateToProductPage}
        sx={{ cursor: "pointer" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        width="100%"
      >
        <ShopifyCardBadge tags={product.tags} />
        <Box
          width="100%"
          maxWidth={{ xs: "170px", sm: "220px", md: "260px" }}
          maxHeight={{ xs: "170px", sm: "220px", md: "260px" }}
          left="0"
          bottom="0"
          textAlign="center"
          sx={{ zIndex: 1 }}
        >
          <ShopifyProductBottleBackground
            productImg={product.image}
            isCart={false}
          />
        </Box>
        <Box
          top="0"
          display="flex"
          justifyContent="center"
          pb={{ xs: "5px", md: "10px" }}
          maxWidth="100px"
          m="0 auto"
        >
          <Box
            position="absolute"
            top={{ xs: "-60px", sm: "-70px", md: landing ? "-60px" : "-80px" }}
            height={{ xs: "115%", md: landing ? "105%" : "110%" }}
            width="100%"
            sx={{ zIndex: 3 }}
          >
            <IcoBottle
              imgSrc={product.title?.toLowerCase().includes('cuticle') ? cuticleOil : undefined}
              imgSrcFallback={product.title?.toLowerCase().includes('cuticle') ? cuticleOilPng : undefined}
              imgHeight="100%" />
          </Box>
        </Box>
      </Box>

      <Box
        onClick={handleNavigateToProductPage}
        sx={{ cursor: "pointer" }}
        textAlign="center"
      >
        <Typography
          fontSize={{ xs: "16px", sm: "20px", md: landing ? "20px" : "24px" }}
          fontFamily="ABC Favorit Book"
          sx={{
            textDecoration: "underline",
          }}
        >
          {product.title}
        </Typography>
      </Box>
      <Box className="productSize" textAlign="center" mb={1}>
        <Typography>15ml</Typography>
      </Box>
      <AddToCartButton
        btnStyle="gridBtn"
        isLoading={false}
        polishPrice={productPrice}
        createOrAddToCart={createCartOrAddNewCartItem}
      />
    </Box>
  );
};
export default React.memo(ShopifyPolishProductCard);
