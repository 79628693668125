import React from "react";

const AddItemIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39 20C39 30.4934 30.4934 39 20 39C9.50659 39 1 30.4934 1 20C1 9.50659 9.50659 1 20 1C30.4934 1 39 9.50659 39 20Z"
      fill="white"
      stroke="black"
      strokeWidth="2"
    />
    <path d="M14 20.1567L26.3132 20.1567" stroke="black" strokeWidth="3" />
    <line
      x1="19.9482"
      y1="26.3135"
      x2="19.9482"
      y2="14.0002"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
);
export default AddItemIcon;
