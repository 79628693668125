import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Clear from "@mui/icons-material/Clear";
import { useAppDispatch } from "../../../utils/hooks/redux-hooks";
import { setInstructionsDisplayed } from "../state/color-picker-slice";

interface InstructionOverlayProps {
  handleClosePopup: () => void;
}

const InstructionOverlay = ({ handleClosePopup }: InstructionOverlayProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setInstructionsDisplayed(true));
  }, [dispatch]);

  return (
    <Box
      py={2}
      pl={3}
      pr={2}
      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
      display="flex"
      justifyContent="space-between"
      borderRadius="10px"
      width={{ xs: "328px", md: "470px" }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "start", md: "unset" }}
      >
        <Box display="flex" alignItems={{ xs: "center", md: "flex-start" }}>
          <Typography
            fontSize={{ xs: "18px", md: "18px" }}
            lineHeight="22px"
            variant="subtitle2"
            fontWeight={600}
            component="span"
          >
            Tap & drag
          </Typography>
          <Typography
            fontSize={{ xs: "20px", md: "20px" }}
            lineHeight="22px"
            fontFamily="ABC Favorit Light"
            fontWeight={300}
            sx={{ ml: 0.5 }}
            component="span"
          >
            anywhere
          </Typography>
        </Box>
        <Box>
          <Typography
            fontSize={{ xs: "20px", md: "20px" }}
            lineHeight="22px"
            fontFamily="ABC Favorit Light"
            fontWeight={300}
            sx={{ ml: { xs: 0.25, md: 0.5 } }}
            component="span"
          >
            to select a color
          </Typography>
        </Box>
      </Box>
      <Box>
        <IconButton onClick={handleClosePopup}>
          <Clear htmlColor="black" />
        </IconButton>
      </Box>
    </Box>
  );
};
export default React.memo(InstructionOverlay);
