import React from "react";
import Box from "@mui/material/Box";
import CloseModalIcon from "./close-modal-icon";

interface Props {
  handleCloseModal: () => void;
}
const CloseModalFloat = ({ handleCloseModal }: Props) => (
  <Box
    position="absolute"
    top={{ xs: "10px", md: "17px" }}
    right="17px"
    onClick={handleCloseModal}
    sx={{
      cursor: "pointer",
    }}
  >
    <CloseModalIcon />
  </Box>
);
export default CloseModalFloat;
