import topCoat from "../assets/upsell-backgrounds/baseCoat.webp";
import topCoatPng from "../assets/upsell-backgrounds/baseCoat.png";
import baseCoatPng from "../assets/upsell-backgrounds/topCoat.png";
import baseCoat from "../assets/upsell-backgrounds/topCoat.png";
import pearl from "../assets/upsell-backgrounds/pearl.webp";
import pearlPng from "../assets/upsell-backgrounds/pearl.png";
import matteTopCoat from "../assets/upsell-backgrounds/matteTopCoat.webp";
import matteTopCoatPng from "../assets/upsell-backgrounds/matteTopCoat.png";
import cuticleOil from "../assets/upsell-backgrounds/cuticleOil.webp";
import cuticleOilPng from "../assets/upsell-backgrounds/cuticleOil.png";
import topCoatMob from "../assets/upsell-backgrounds/topCoatMobile.webp";
import topCoatMobPng from "../assets/upsell-backgrounds/topCoatMobile.png";
import baseCoatMob from "../assets/upsell-backgrounds/baseCoatMobile.webp";
import baseCoatMobPng from "../assets/upsell-backgrounds/baseCoatMobile.png";
import pearlMob from "../assets/upsell-backgrounds/pearlMobile.webp";
import pearlMobPng from "../assets/upsell-backgrounds/pearlMobile.png";
import matteTopCoatMob from "../assets/upsell-backgrounds/matteTopCoatMobile.webp";
import matteTopCoatMobPng from "../assets/upsell-backgrounds/matteTopCoatMobile.png";
import cuticleOilMob from "../assets/upsell-backgrounds/cuticleOilMobile.webp";
import cuticleOilMobPng from "../assets/upsell-backgrounds/cuticleOilMobile.png";



type Size = "mobile" | "desktop";
export const getFillImage = (title: string, size: Size = "desktop"): string => {
  if (title.toLowerCase().includes("fast")) {
    return size === "desktop" ? topCoat : topCoatMob;
  } else if (title.toLowerCase().includes("base")) {
    return size === "desktop" ? baseCoat : baseCoatMob;
  } else if (title.toLowerCase().includes("matte")) {
    return size === "desktop" ? matteTopCoat : matteTopCoatMob;
  } else if (title.toLowerCase().includes("cuticle")) {
    return size === "desktop" ? cuticleOil : cuticleOilMob;
  } else {
    return size === "desktop" ? pearl : pearlMob;
  }
};

export const getFillImageBackup = (
  title: string,
  size: string = "desktop"
): string => {
  if (title.toLowerCase().includes("fast")) {
    return size === "desktop" ? topCoatPng : topCoatMobPng;
  } else if (title.toLowerCase().includes("base")) {
    return size === "desktop" ? baseCoatPng : baseCoatMobPng;
  } else if (title.toLowerCase().includes("matte")) {
    return size === "desktop" ? matteTopCoatPng : matteTopCoatMobPng;
  } else if (title.toLowerCase().includes("cuticle")) {
    return size === "desktop" ? cuticleOilPng : cuticleOilMobPng;
  } else {
    return size === "desktop" ? pearlPng : pearlMobPng;
  }
};
