import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import "../../pages/product/product.css";
import { hsv2rgb } from "../../utils/color-conversions";

interface Props {
  hsv: number[];
  maxHeight?: string;
  isCartShape: boolean;
  isProdPage?: boolean;
}

const xs = "225px";
const sm = "400px";
const md = "550px";

export const Shape1 = ({ hsv, maxHeight, isCartShape, isProdPage }: Props) => {
  const x = useMemo(() => hsv2rgb(hsv[0], hsv[1], hsv[2]), [hsv]);
  return (
    <Box
      className="custom-product-shape"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={!isProdPage ? "unset" : { xs: xs, sm: sm, md: md }}
      maxHeight={maxHeight ? { xs: maxHeight, sm: "100%" } : "unset"}
      sx={{
        verticalAlign: "bottom",
        filter: {
          xs: !isCartShape
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCartShape
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
        WebkitFilter: {
          xs: !isCartShape
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCartShape
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 138 137"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M136.25 68.4993C136.25 105.502 106.253 135.499 69.25 135.499C32.2469 135.499 2.25 105.502 2.25 68.4993C2.25 31.4966 32.2469 1.5 69.25 1.5C106.253 1.5 136.25 31.4966 136.25 68.4993Z"
          fill={`rgb(${x[0]}%, ${x[1]}%, ${x[2]}%)`}
          stroke="black"
          strokeWidth="2"
        />
      </svg>
    </Box>
  );
};

export const Shape2 = ({ hsv, maxHeight, isCartShape, isProdPage }: Props) => {
  const x = useMemo(() => hsv2rgb(hsv[0], hsv[1], hsv[2]), [hsv]);
  return (
    <Box
      className="custom-product-shape"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={!isProdPage ? "unset" : { xs: xs, sm: sm, md: md }}
      maxHeight={maxHeight ? { xs: maxHeight, sm: "100%" } : "unset"}
      sx={{
        verticalAlign: "bottom",
        filter: {
          xs: !isCartShape
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCartShape
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
        WebkitFilter: {
          xs: !isCartShape
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCartShape
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 141 141"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M138.958 81.0918C140.158 73.3401 137.582 65.882 132.607 60.5707C133.579 58.5023 134.277 56.2642 134.642 53.8891C136.676 40.6956 127.802 28.3438 114.741 26.0013C112.785 16.1812 104.895 8.16204 94.4358 6.54385C89.3237 5.75432 84.342 6.60909 80.0189 8.70361C76.5303 5.39545 72.0377 3.07908 66.9256 2.28956C56.4601 0.671362 46.5162 5.93702 41.691 14.7131C28.6694 13.2059 16.6846 22.2625 14.6632 35.3321C14.2916 37.7528 14.285 40.141 14.6046 42.4443C8.35133 46.0265 3.72825 52.3166 2.54151 59.99C1.35477 67.6568 3.86518 75.0366 8.72952 80.3414C7.75143 82.4229 7.04722 84.6675 6.67555 87.0556C4.66069 100.119 13.3396 112.359 26.1851 114.872C28.0956 124.75 36.0051 132.828 46.5162 134.453C51.6283 135.243 56.61 134.388 60.9332 132.293C64.4217 135.601 68.9144 137.918 74.0265 138.707C84.5311 140.332 94.501 135.021 99.3067 126.192C112.452 127.882 124.62 118.793 126.654 105.613C127.013 103.27 127.026 100.96 126.739 98.7223C133.064 95.1531 137.758 88.8304 138.952 81.0918H138.958Z"
          fill={`rgb(${x[0]}%, ${x[1]}%, ${x[2]}%)`}
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </Box>
  );
};

export const Shape3 = ({ hsv, maxHeight, isCartShape, isProdPage }: Props) => {
  const x = useMemo(() => hsv2rgb(hsv[0], hsv[1], hsv[2]), [hsv]);
  return (
    <Box
      className="custom-product-shape"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={!isProdPage ? "unset" : { xs: xs, sm: sm, md: md }}
      maxHeight={maxHeight ? { xs: maxHeight, sm: "100%" } : "unset"}
      sx={{
        verticalAlign: "bottom",
        filter: {
          xs: !isCartShape
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCartShape
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
        WebkitFilter: {
          xs: !isCartShape
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCartShape
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 141 141"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M139.25 70.4985C139.25 79.2592 130.047 86.1313 126.889 93.7487C123.611 101.65 125.14 112.982 119.19 118.938C113.24 124.888 101.902 123.366 94.0003 126.638C86.3828 129.796 79.5107 138.999 70.75 138.999C61.9893 138.999 55.1172 129.796 47.4998 126.638C39.5981 123.359 28.2667 124.888 22.3105 118.938C16.3606 112.988 17.8828 101.65 14.611 93.7487C11.4528 86.1313 2.25 79.2592 2.25 70.4985C2.25 61.7379 11.4528 54.8658 14.611 47.2483C17.8891 39.3467 16.3606 28.0153 22.3105 22.059C28.2604 16.1091 39.5981 17.6313 47.4998 14.3595C55.1172 11.2013 61.9893 1.99854 70.75 1.99854C79.5107 1.99854 86.3828 11.2013 94.0003 14.3595C101.902 17.6376 113.233 16.1091 119.19 22.059C125.14 28.009 123.617 39.3467 126.889 47.2483C130.161 55.15 139.25 61.7379 139.25 70.4985Z"
          fill={`rgb(${x[0]}%, ${x[1]}%, ${x[2]}%)`}
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </Box>
  );
};

export const Shape4 = ({ hsv, maxHeight, isCartShape, isProdPage }: Props) => {
  const x = useMemo(() => hsv2rgb(hsv[0], hsv[1], hsv[2]), [hsv]);
  return (
    <Box
      className="custom-product-shape"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={!isProdPage ? "unset" : { xs: xs, sm: sm, md: md }}
      maxHeight={maxHeight ? { xs: maxHeight, sm: "100%" } : "unset"}
      sx={{
        verticalAlign: "bottom",
        filter: {
          xs: !isCartShape
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCartShape
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
        WebkitFilter: {
          xs: !isCartShape
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCartShape
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 141 141"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M138.671 81.0693C137.983 85.5004 132.849 88.7296 131.38 92.7914C129.859 96.9762 131.691 102.747 129.432 106.461C127.13 110.24 121.149 111.261 118.195 114.483C115.212 117.727 114.698 123.765 111.158 126.357C107.617 128.949 101.702 127.617 97.7124 129.485C93.752 131.331 90.9717 136.725 86.671 137.775C82.4426 138.811 77.4975 135.321 73.052 135.502C68.7368 135.683 64.1102 139.6 59.6792 138.919C55.2481 138.231 52.019 133.098 47.9572 131.628C43.7723 130.108 38.0018 131.94 34.2875 129.681C30.5081 127.378 29.4872 121.398 26.2653 118.444C23.0216 115.461 16.9832 114.947 14.3912 111.406C11.7992 107.866 13.1314 101.95 11.2634 97.9609C9.41715 94.0005 4.02314 91.2202 2.9733 86.9195C1.93794 82.6912 5.42776 77.7461 5.24675 73.3005C5.06574 68.9853 1.14875 64.3588 1.82933 59.9277C2.51716 55.4967 7.65052 52.2675 9.1203 48.2057C10.6408 44.0208 8.80897 38.2503 11.0679 34.5361C13.3703 30.7566 19.3508 29.7357 22.3049 26.5138C25.2878 23.2702 25.8019 17.2318 29.3424 14.6398C32.8829 12.0477 38.7982 13.3799 42.7876 11.512C46.748 9.66569 49.5283 4.27168 53.829 3.22183C58.0574 2.18647 63.0025 5.67629 67.448 5.49528C71.7632 5.31428 76.3898 1.39728 80.8208 2.07787C85.2519 2.75846 88.481 7.89906 92.5428 9.36883C96.7277 10.8893 102.498 9.0575 106.212 11.3165C109.992 13.6189 111.013 19.5994 114.235 22.5534C117.478 25.5364 123.517 26.0504 126.109 29.5909C128.701 33.1314 127.369 39.0467 129.237 43.0361C131.083 46.9966 136.477 49.7768 137.527 54.0776C138.562 58.3059 135.072 63.251 135.253 67.6965C135.434 72.0117 139.351 76.6383 138.671 81.0693Z"
          fill={`rgb(${x[0]}%, ${x[1]}%, ${x[2]}%)`}
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </Box>
  );
};
