import axios, { AxiosError } from "axios";
import http from "../../../services/http/http-common";
import { ServerError } from "../../../services/types/serverError.type";
import { InquiriesState } from "../../wholesale/components/landing_components/inquiries/hooks/useInquiryFormControls";
import { AboutUsSendData, SendData } from "../interfaces/form-state.interface";

const sendContactEmail = async (sendData: SendData) => {
  const data = {
    fromName: sendData.fromName,
    email: sendData.email,
    orderNumber: sendData.orderNumber,
    message: sendData.message,
    phoneNumber: sendData.phoneNumber,
  };
  try {
    const response = await http.post("/webAppContactForm", data);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      const serverError = e as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw serverError.response.data;
      }
    }
    throw new Error(`Error sending contact form: ${e}`);
  }
};

const sendHelpEmail = async (sendData: SendData) => {
  const data = {
    fromName: sendData.fromName,
    email: sendData.email,
    orderNumber: sendData.orderNumber,
    message: sendData.message,
    phoneNumber: sendData.phoneNumber,
    storeLocation: sendData.storeLocation,
    receiverEmail:'support@blankbeauty.com'
  };
  try {
    const response = await http.post("/webAppContactForm", data);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      const serverError = e as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw serverError.response.data;
      }
    }
    throw new Error(`Error sending help form: ${e}`);
  }
};

const sendFileUploadEmail = async (sendData: AboutUsSendData) => {
  const data: AboutUsSendData = {
    name: sendData.name,
    email: sendData.email,
    phoneNumber: sendData.phoneNumber,
    message: sendData.message,
    file: sendData.file,
  };
  try {
    const response = await http.post("/aboutUsContactForm", data);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      const serverError = e as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw serverError.response.data;
      }
    }
    throw new Error(`Error sending file upload form: ${e}`);
  }
};

const sendInquiryFormEmail = async (sendData: InquiriesState) => {
  const data = {
    fromName: sendData.name,
    phoneNumber: sendData.phoneNumber,
    message: sendData.message,
    companyName: sendData.companyName,
    file: sendData.uploadedFile,
    email: sendData.email,
  };
  try {
    const response = await http.post("/wholeSaleInquiriesForm", data);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      const serverError = e as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw serverError.response.data;
      }
    }
    throw new Error(`Error sending inquiry form: ${e}`);
  }
};

const ContactService = {
  sendContactEmail,
  sendFileUploadEmail,
  sendHelpEmail,
  sendInquiryFormEmail,
};

export default ContactService;
