import Box from "@mui/material/Box";
import React from "react";

interface ReadMoreProps {
  switchSlide: (slideTitle: string) => void;
  title: string;
}
export const ReadMore = ({ switchSlide, title }: ReadMoreProps) => (
  <>
    <Box mr={0.5} component="span">
      ...
    </Box>
    <Box
      onClick={() => switchSlide(title)}
      component="span"
      fontFamily="ABC Favorit Lining"
      textAlign="left"
      fontSize={{ xs: "14px" }}
      lineHeight="20px"
      sx={{ cursor: "pointer" }}
    >
      {" "}
      read more
    </Box>
  </>
);

interface ReadLessProps {
  captP2: string;
  handleCloseSlide: () => void;
  activeSlide: string;
  title: string;
}
export const ReadLess = ({
  captP2,
  handleCloseSlide,
  activeSlide,
  title,
}: ReadLessProps) => (
  <>
    <Box
      fontFamily="ABC Favorit Book"
      component="span"
      textAlign={{ xs: "left", sm: "center", md: "left" }}
      fontSize={{ xs: "14px" }}
      lineHeight="20px"
    >
      {captP2}
    </Box>
    <Box
      onClick={handleCloseSlide}
      component="span"
      ml={0.15}
      fontFamily="ABC Favorit Lining"
      textAlign={{ xs: "left", sm: "center", md: "left" }}
      fontSize={{ xs: "14.5px", md: "16px" }}
      lineHeight="20px"
      sx={{
        pl: "5px",
        cursor: "pointer",
        opacity: activeSlide !== title ? 0 : 1,
        transition: "all 1s ease-in",
      }}
    >
      read less
    </Box>
  </>
);
