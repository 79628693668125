import {
  BASE_COAT_MERCH_ID,
  PEARL_TOP_COAT_MERCH_ID,
  TOP_COAT_MERCH_ID,
} from "../pages/cart/CONSTANTS/merch-ids";
import { CollectionProductInterface } from "../pages/shop-all/interfaces/collection-interfaces";

export const isUpsellProduct = (
  product: CollectionProductInterface | undefined
): boolean | undefined => {
  if (!product) return false;
  if (
    product.merchId === TOP_COAT_MERCH_ID ||
    product.merchId === BASE_COAT_MERCH_ID ||
    product.merchId === PEARL_TOP_COAT_MERCH_ID
  ) {
    return true;
  } else {
    return false;
  }
};
