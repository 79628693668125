import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useGetColorByHSVQuery } from "../hooks/useGetColorByHSVQuery";
import { useKioskHook } from "../../../utils/hooks/useKioskHook";
import { useInternalCanvasTestHook } from "../../../utils/hooks/useInternalCanvasTestHook";

interface ConfirmButtonProps {
  color?: string;
  isDisplayed: boolean;
  colorId?: string;
}

const ConfirmButton = ({ color, isDisplayed, colorId }: ConfirmButtonProps) => {
  const { fetchColor } = useGetColorByHSVQuery();
  const isKiosk = useKioskHook()
  const isInternalCanvasTest = useInternalCanvasTestHook()
  
  const navigateToProductPage = () => fetchColor(color, isKiosk, colorId, isInternalCanvasTest);

  return (
    <Box width={isKiosk ? '70%' : { xs: "225px", md: "260px" }} >
      <Button
        sx={{ py: 1.15 }}
        fullWidth={true}
        variant="contained"
        onClick={navigateToProductPage}
        disabled={!isDisplayed}
      >
        Select Color
      </Button>
    </Box>
  );
};
export default ConfirmButton;
