import React from "react";
import Box from "@mui/material/Box";
import { BuyInBulkInterface } from "./interfaces/buy-in-bulk.interface";
import BlankLabsBtn from "../go-to-blank-labs-btn";
import BuyInBulkDesktop from "./components/buy-in-bulk-desktop";
import BuyInBulkMobile from "./components/buy-in-bulk-mobile";

interface Props {
  bibData: BuyInBulkInterface;
}
const BuyInBulk = ({ bibData }: Props) => (
  <Box
    pt={{ xs: 3, md: 7 }}
    pb={5}
    sx={{
      backgroundColor: "#E7E7E7",
    }}
  >
    <BuyInBulkDesktop bibData={bibData} />
    <BuyInBulkMobile bibData={bibData} />
    <Box
      display={{ xs: "block", sm: "none" }}
      width={{ xs: "271px", sm: "305px" }}
      m="0 auto"
    >
      <BlankLabsBtn btnColor="black" btnText="get in touch" textColor="white" />
    </Box>
  </Box>
);
export default BuyInBulk;
