import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useInternalCanvasTestHook } from "../../../../utils/hooks/useInternalCanvasTestHook";
const CustomProductDescription = () => {
  const baseCoatLink = (
    <Link
      style={{ color: "rgba(0, 0, 0, 0.85)" }}
      to={"/products/anti-peel-base-coat"}
    >
      base
    </Link>
  );
  const topCoatLink = (
    <Link
      style={{ color: "rgba(0, 0, 0, 0.85)" }}
      to={"/products/fast-dry-top-coat"}
    >
      top
    </Link>
  );

  const isInternalTesing = useInternalCanvasTestHook()
  return (
    <Box display="flex" flexDirection="column">
      <Box mb={1}>
        <Typography variant="body1">Great color choice 😉</Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body1">
          {`Your new nail ${isInternalTesing ? 'shade' : 'polish' } is uniquely yours and:`}
        </Typography>
      </Box>
      <List
        sx={{
          listStyleType: "disc",
          pl: 2,
          ml: 3,
          "& .MuiListItem-root": {
            display: "list-item",
          },
        }}
      >
        <ListItem disableGutters disablePadding>
          <Typography variant="body1">13 free</Typography>
        </ListItem>
        <ListItem disableGutters disablePadding>
          <Typography variant="body1">
            Full coverage in two coats, every time.
          </Typography>
        </ListItem>
        <ListItem disableGutters disablePadding>
          <Typography variant="body1">Cruelty free</Typography>
        </ListItem>
        <ListItem disableGutters disablePadding>
          <Typography variant="body1">Made in the USA</Typography>
        </ListItem>
      </List>
      <Box>
        <Typography variant="body1">
          Your color comes in a high gloss finish - take a look at our range of{" "}
          {baseCoatLink} and {topCoatLink} coat effects for an even longer
          lasting finish.
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomProductDescription;
