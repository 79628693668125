import PixelService from "../../../services/pixels/pixel-service";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../walmart/state/walmart-slice";
import {
  BASE_COAT_MERCH_ID,
  CUTICLE_OIL_MECH_ID,
  MATT_TOP_COAT_MERCH_ID,
  PEARL_TOP_COAT_MERCH_ID,
  TOP_COAT_MERCH_ID,
} from "../CONSTANTS/merch-ids";
import { AddToCartInterface } from "../interfaces/add-to-cart.interface";
import { DiscountInterface } from "../interfaces/discount.interface";
import { ProductInterface } from "../interfaces/product.interface";
import { UpSellInterface } from "../interfaces/up-sell-interface";
import {
  addCartItem,
  createCart,
  deleteCartItem,
  removeCartItem,
  shopifyCheckout,
} from "../state/cart-actions";
import {
  selectCartItems,
  selectIsCartOpen,
  setIsCartOpen,
} from "../state/cart-slice";
import { setIsUpSellBarShown } from "../state/upSell-slice";
//TODO: change this to not use upSell functions -- everything should be the main one
const useCartActions = () => {
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector(selectCartItems);
  const isCartOpen = useAppSelector(selectIsCartOpen);
  const isWalmart = useAppSelector(selectIsWalmartPath);

  const openAndCloseCart = () => {
    if (isCartOpen === false) {
      dispatch(setIsCartOpen(true));
      setTimeout(function () {
        dispatch(setIsUpSellBarShown(true));
      }, 350);
      PixelService.fireHotjarEvent("Cart opened");
    } else {
      dispatch(setIsUpSellBarShown(false));
      setTimeout(function () {
        dispatch(setIsCartOpen(false));
      }, 200);
    }
  };
  const closeCart = () => {
    dispatch(setIsUpSellBarShown(false));
    setTimeout(function () {
      dispatch(setIsCartOpen(false));
    }, 200);
  };

  const addItemToCart = (item: AddToCartInterface) => {
    const isUpSell = getIsUpsell(item.merchId);
    const upSellMaterial = item.key.includes("top")
      ? "Top coat / 15ml"
      : "Base coat / 15ml";
    const itemImage = item.img !== undefined ? item.img : "";
    const itemToAdd: AddToCartInterface = {
      merchId: item.merchId,
      key: item.key,
      value: isUpSell ? upSellMaterial : item.value,
      quantity: 1,
      img: itemImage,
    };
    dispatch(addCartItem({ cartItems: cartItems, product: itemToAdd }));
  };

  const getIsUpsell = (merchId: string): boolean => {
    if (
      merchId === BASE_COAT_MERCH_ID ||
      merchId === TOP_COAT_MERCH_ID ||
      merchId === PEARL_TOP_COAT_MERCH_ID
    ) {
      return true;
    } else {
      return false;
    }
  };

  const createOrAddToCart = (item: AddToCartInterface, quantity: number) => {
    const cartId = localStorage.getItem("cartId");
    const itemImage = item.img !== undefined ? item.img : "";
    if (cartId != null) {
      const itemToAdd: AddToCartInterface = {
        merchId: item.merchId,
        key: item.key,
        value: item.value,
        quantity: quantity,
        img: itemImage,
      };
      dispatch(addCartItem({ cartItems: cartItems, product: itemToAdd }));
      openAndCloseCart();
    } else {
      const itemToAdd: AddToCartInterface = {
        key: item.key,
        value: item.value,
        quantity: quantity,
        merchId: item.merchId,
        img: itemImage,
      };
      dispatch(createCart(itemToAdd));
      openAndCloseCart();
    }
  };

  const removeItemFromCart = (item: ProductInterface) => {
    const itemToRemove = {
      cartItems: cartItems,
      itemToRemove: item,
    };
    dispatch(removeCartItem(itemToRemove));
  };

  const deleteItemFromCart = (item: ProductInterface) => {
    const itemToRemove = {
      itemToRemove: item,
    };
    dispatch(deleteCartItem(itemToRemove));
  };

  // UPSELL ITEMS
  const getKey = (merchId: string): string => {
    if (merchId === BASE_COAT_MERCH_ID) {
      return "Anti-peel base coat";
    } else if (merchId === TOP_COAT_MERCH_ID) {
      return "Fast-dry top coat";
    } else if (merchId === CUTICLE_OIL_MECH_ID) {
      return "Hydrating Rose Cuticle Oil";
    } else if (merchId === MATT_TOP_COAT_MERCH_ID) {
      return "Matte Top Coat";
    } else {
      return "Pearl top coat";
    }
  };

  const addUpSellToCart = (item: UpSellInterface, quantity = 1) => {


    let material
    if (item.title.includes("top")) {
      material = "Top coat / 15ml"
    }
    else if (item.title.toLowerCase().includes("cuticle")) {

      material = 'Cuticle oil/15ml'

    }
    else {
      material = "Base coat / 15ml"
    }
    const key = getKey(item.merchId);


    const cartId = localStorage.getItem("cartId");
    if (cartId != null) {
      const itemToAdd: AddToCartInterface = {
        merchId: item.merchId,
        key: key,
        value: material,
        quantity: quantity,
        img: item.image,
      };
      dispatch(addCartItem({ cartItems: cartItems, product: itemToAdd }));
    } else {
      const itemToAdd: AddToCartInterface = {
        key: key,
        value: material,
        quantity: 1,
        merchId: item.merchId,
        img: item.image,
      };
      dispatch(createCart(itemToAdd));
    }
  };

  const addOpenEndorseItemsToCart = async (items: AddToCartInterface[]) => {
    const cartId = localStorage.getItem("cartId");
    const addArr: AddToCartInterface[] = [];
    items.forEach((item) => {
      const itemToAdd: AddToCartInterface = {
        merchId: item.merchId,
        key: item.key,
        value: item.value,
        quantity: 1,
        img: item.img,
      };
      addArr.push(itemToAdd);
    });
    if (!cartId) {
      await dispatch(createCart(addArr[0]));
      for (let i = 1; i < addArr.length; i++) {
        await dispatch(
          addCartItem({ cartItems: cartItems, product: addArr[i] })
        );
      }
      openAndCloseCart();
    } else {
      for (let i = 0; i < addArr.length; i++) {
        await dispatch(
          addCartItem({ cartItems: cartItems, product: addArr[i] })
        );
      }
      openAndCloseCart();
    }
  };

  const handleShopifyCheckout = async (
    cartItems: ProductInterface[],
    discountData: DiscountInterface[]
  ) => {
    await PixelService.pixelInitiateCheckout(cartItems, isWalmart);
    dispatch(shopifyCheckout({ cartItems, discountData }));
  };

  return {
    openAndCloseCart,
    closeCart,
    createOrAddToCart,
    removeItemFromCart,
    deleteItemFromCart,
    addUpSellToCart,
    addItemToCart,
    addOpenEndorseItemsToCart,
    handleShopifyCheckout,
  };
};

export default useCartActions;
