import { useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { initialProductState } from "../../product/CONSTANTS/initial-product-state";
import ProductService from "../../product/services/product-service";
import {
  CollectionInterface,
  CollectionProductInterface,
  initialCollectionState,
} from "../../shop-all/interfaces/collection-interfaces";
import CollectionService from "../../shop-all/services/collections-service";

export const useGetCollectionProducts = (urlParam: string) => {
  const rendered = useRef<boolean>(true);
  const [product, setProduct] =
    useState<CollectionProductInterface>(initialProductState);
  const [collectionProduct, setCollectionProduct] =
    useState<CollectionInterface>(initialCollectionState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  useEffect(() => {
    if (rendered.current) {
      setIsLoading(true);
      CollectionService.fetchSingleCollection(urlParam)
        .then((res) => {
          setCollectionProduct(res);
          setIsSuccess(true);
        })
        .catch((e) => {


          setIsError(true);
          setIsSuccess(false);
          Sentry.captureException(e);
        });
      const productHandle = !urlParam.includes("collection")
        ? `${urlParam}-collection`
        : urlParam;
      ProductService.fetchCollectionAsProduct(productHandle)
        .then((res) => {
          setProduct(res);
        })
        .catch((err) => {

          setIsError(true);
          setIsSuccess(false);
          Sentry.captureException(err);
        })
        .finally(() => setIsLoading(false));
    }
    return () => {
      rendered.current = false;
    };
  }, [urlParam]);

  return {
    collectionProduct,
    product,
    isLoading,
    isError,
    isSuccess,
  };
};
