import React from "react";
import Box from "@mui/material/Box";
import AddMoreCloud from "./add-more-cloud-shape";
import AddMoreCloudPlusSymbol from "./add-more-cloud-plus-symbol";

interface Props {
  cartTotal: number;
  handleNavigateShopAll: () => void;
}
const AddMoreItems = ({ handleNavigateShopAll, cartTotal }: Props) => {
  const getBobbleText = () => {
    if (cartTotal === 0) {
      return "You're cart is empty. Spend $20.00 to get free delivery!";
    } else if (cartTotal >= 20) {
      return "Free delivery applied. Fancy another shade?";
    }
    return `Spend just $${20 - cartTotal}.00 more for free delivery.`;
  };
  return (
    <Box
      width="100%"
      mb={{ xs: "15px", sm: "20px", md: "50px" }}
      pb={{ xs: "15px", sm: 0, md: 0 }}
      position="relative"
      display="flex"
      flexWrap="nowrap"
      justifyContent="flex-start"
      alignContent="stretch"
      alignItems="center"
    >
      <Box width={{ xs: "60px", sm: "80px", md: "130px" }} mt={0}>
        <Box width="100%">
          <Box position="relative">
            <Box
              data-testid="cloud-container"
              onClick={handleNavigateShopAll}
              component="figure"
              sx={{ cursor: "pointer" }}
            >
              <AddMoreCloud height="100%" width="100%" />
            </Box>
            <AddMoreCloudPlusSymbol />
          </Box>
        </Box>
      </Box>
      <Box
        onClick={handleNavigateShopAll}
        width="100%"
        maxWidth="232px"
        ml={{ xs: "21px", md: "27px" }}
        fontSize={{ xs: "14px", sm: "18px", md: "20px" }}
        fontFamily="Nib Pro"
        sx={{ textDecoration: "underline", cursor: "pointer" }}
        lineHeight={1}
      >
        {getBobbleText()}
      </Box>
    </Box>
  );
};

export default AddMoreItems;
