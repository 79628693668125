import React, { useRef } from "react";
import { Box, IconButton } from "@mui/material";
import useOnClickOutside from "../utils/hooks/useOnClickOutside";
import { useAppSelector } from "../utils/hooks/redux-hooks";
import {
  selectCartCount,
  selectIsCartOpen,
} from "../pages/cart/state/cart-slice";
import BlankShoppingBag from "./icons/shopping-bag-icon";
import CartDrawer from "../pages/cart/cart-drawer";
import { selectIsUpsellBarShown } from "../pages/cart/state/upSell-slice";
import useCartActions from "../pages/cart/hooks/useCartActions";

interface ShoppingCartProps {
  color: string;
  fill: string;
}

const ShoppingCartBadge = ({ color, fill }: ShoppingCartProps) => {
  const cartCount = useAppSelector(selectCartCount);
  const isCartOpen = useAppSelector(selectIsCartOpen);
  const isUpsellBarOpen = useAppSelector(selectIsUpsellBarShown);
  const { openAndCloseCart, closeCart } = useCartActions();
  const ref = useRef<HTMLElement>(null);

  useOnClickOutside(ref, () => closeCart());

  return (
    <Box mb={{ xs: "1px", md: "-1px" }}>
      <IconButton
        aria-label="shopping cart button"
        sx={{ pb: 0 }}
        onClick={openAndCloseCart}
      >
        <BlankShoppingBag color={color} fill={fill} cartCount={cartCount} />
      </IconButton>

      <CartDrawer
        setDropDownIsOpen={closeCart}
        dropDownIsOpen={isCartOpen}
        upSellBarIsOpen={isUpsellBarOpen}
        ref={ref}
      />
    </Box>
  );
};
export default ShoppingCartBadge;
