import React from "react";
import Grid from "@mui/material/Grid";
import ColorLibrarySkeltonCard from "./color-library-skeleton-card";

interface Props {
  isMobile: boolean;
}
const ColorLibrarySkeletonGrid = ({ isMobile }: Props) => (
  <Grid
    container
    justifyContent="center"
    spacing={{ xs: 1, md: 3 }}
    rowSpacing={{ xs: 5, md: 10 }}
    mt={{ md: "30px" }}
  >
    {Array.from(new Array(8)).map((_, idx) => (
      <Grid key={idx} item xs={6} sm={4} md={3}>
        <ColorLibrarySkeltonCard key={idx} isMobile={isMobile} />
      </Grid>
    ))}
  </Grid>
);

export default ColorLibrarySkeletonGrid;
