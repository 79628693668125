import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface Props {
  closeModal: () => void;
  cartCount: number;
}
const CartTop = ({ closeModal, cartCount }: Props) => (
  <Box
    sx={{
      m: "0 auto",
      width: { xs: "calc(100% - 44px)", md: "calc(100% - 90px)" },
      p: { xs: "21px 0 0", sm: "35px 0 0", md: "45px 0 0" },
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "2px solid black",
    }}
    data-testid="cart-top-component"
  >
    <Typography
      variant="h3"
      fontSize={{ xs: "20px", sm: "32px", md: "38px" }}
      lineHeight={{ xs: "20px", sm: "45px", md: "53px" }}
      sx={{
        WebkitTextSizeAdjust: "100%",
      }}
    >
      Your cart (
      <Typography
        variant="h3"
        fontSize={{ xs: "20px", sm: "32px", md: "38px" }}
        lineHeight={{ xs: "20px", sm: "45px", md: "53px" }}
        component="span"
      >
        {cartCount}
      </Typography>
      )
    </Typography>
    <IconButton onClick={closeModal}>
      <CloseOutlinedIcon />
    </IconButton>
  </Box>
);
export default React.memo(CartTop);
