import React from "react";
import Box from "@mui/material/Box";
import guaranteePolish from "../../../../assets/guanranteeAssets/guaranteeImg.png";

const GuaranteePolish = () => (
  <Box
    width="100%"
    maxWidth={{ xs: "150px", sm: "173px" }}
    mb={{ xs: 2, md: 0 }}
    display={{ xs: "block", sm: "flex" }}
    justifyContent="center"
    mr={{ sm: 3 }}
  >
    <Box
      component="img"
      src={guaranteePolish}
      width={{ xs: "150px", sm: "173px" }}
      height={{ xs: "155.93px", sm: "179.84px" }}
      alt="hand holding polish bottle"
    />
  </Box>
);

export default GuaranteePolish;
