import React, { useEffect } from "react";
import { Fade, Grow } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "./components/modal";
import PixelService from "../../../../services/pixels/pixel-service";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const MatchPromiseModal = ({ isVisible, setIsVisible }: Props) => {
  useEffect(() => {
    if (isVisible) document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isVisible]);
  useEffect(() => {
    PixelService.pixelModalView("/match-promise-modal");
  }, []);
  return (
    <Fade in={isVisible}>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 99,
          display: "block",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 98,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grow
            in={isVisible}
            style={{
              transformOrigin: "0 0 0",
            }}
            mountOnEnter
            unmountOnExit
            {...(isVisible ? { timeout: 1000 } : {})}
          >
            <Box
              width="100%"
              sx={{ display: "flex", justifyContent: "center" }}
              px={{ xs: 2, md: 0 }}
            >
              <Modal setIsVisible={setIsVisible} />
            </Box>
          </Grow>
        </Box>
      </Box>
    </Fade>
  );
};
export default MatchPromiseModal;
