import React, { useEffect, useRef } from "react";
import { AutoPlayVideo } from "../../utils/hooks/useAutoPlayVideo";

interface Props {
  video: string;
  id: string;
  poster: string;
  idx: number;
  isVisible: boolean | undefined;
  minHeight: { xs: string; sm: string; md: string };
  activeIdx: number;
  setActiveIdx: (value: React.SetStateAction<number>) => void;
}

const HowItWorksVideoGraphic = ({
  video,
  id,
  poster,
  minHeight,
  idx,
  activeIdx,
  setActiveIdx,
  isVisible,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const playNextVideo = (activeIdx: number) => {
    if (activeIdx < 2) {
      setActiveIdx((prev) => prev + 1);
    } else {
      setActiveIdx(0);
    }
  };

  const handleOnEnded = () => {
    const currentRef = videoRef.current;
    if (currentRef) {
      currentRef.load();
    }
    playNextVideo(activeIdx);
  };

  useEffect(() => {
    let isPlaying = false;
    const playVideo = () => {
      const currentRef = videoRef.current;
      if (currentRef) {
        if (activeIdx === idx) {
          if (!isPlaying) {
            currentRef.play();
            isPlaying = true;
          }
        } else {
          currentRef.pause();
        }
      }
    };
    const pauseVideo = () => {
      const currentRef = videoRef.current;
      if (currentRef) {
        if (isPlaying) {
          currentRef.pause();
          currentRef.currentTime = 0;
          isPlaying = false;
        }
      }
      setActiveIdx(0);
    };
    if (isVisible) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [isVisible, activeIdx, idx, setActiveIdx]);
  return (
    <AutoPlayVideo
      width={{ xs: "100%" }}
      height={minHeight}
      autoPlay={false}
      onEnded={handleOnEnded}
      video={video}
      id={id}
      videoRef={videoRef}
      poster={poster}
    />
  );
};

export default HowItWorksVideoGraphic;
