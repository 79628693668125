import { useState } from "react";

export const useDisplayColor = () => {
  const [displayColor, setDisplayColor] = useState<string>("");
  const [textColor, setTextColor] = useState<string>("");

  const getColorHex = (collection: string) => {
    const color = collection === "Nude" ? "brown" : collection.toLowerCase();

    switch (color) {
      case "pink":
        setDisplayColor("#ffa892");
        setTextColor("black");
        break;
      case "purple":
        setDisplayColor("#ae98da");
        setTextColor("black");
        break;
      case "red":
        setDisplayColor("#d64b38");
        setTextColor("white");
        break;
      case "blue":
        setDisplayColor("#addae3");
        setTextColor("black");
        break;
      case "cyan":
        setDisplayColor("#96ded3");
        setTextColor("black");
        break;
      case "turquoise":
        setDisplayColor("#00727c");
        setTextColor("white");
        break;
      case "green":
        setDisplayColor("#b9d1a0");
        setTextColor("black");
        break;
      case "yellow":
        setDisplayColor("#FFFF8F");
        setTextColor("black");
        break;
      case "orange":
        setDisplayColor("#fd8c00");
        setTextColor("black");
        break;
      case "brown":
        setDisplayColor("#d8a39b");
        setTextColor("white");
        break;
      case "grey":
        setDisplayColor("#818589");
        setTextColor("white");
        break;
      default:
        setDisplayColor("white");
    }
  };

  const getFilterCircles = (collection: string) => {
    const color = collection === "Nude" ? "brown" : collection.toLowerCase();

    switch (color) {
      case "pink":
        return "#ffa892";
      case "purple":
        return "#ae98da";
      case "red":
        return "#d64b38";

      case "blue":
        return "#addae3";

      case "green":
        return "#b9d1a0";
      case "yellow":
        return "#FFFF8F";
      case "orange":
        return "#fd8c00";
      case "brown":
        return "#d8a39b";
      case "grey":
        return "#818589";
      default:
        return "black";
    }
  };

  return {
    getColorHex,
    displayColor,
    textColor,
    getFilterCircles,
  };
};
