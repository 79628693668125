import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterBarItem from "./filter-bar-item.component";
import { useAppDispatch } from "../../../../../utils/hooks/redux-hooks";
import { setNewTag } from "../../state/library-colors-slice";
import { useParams } from "react-router-dom";

interface FilterByShadeProps {
  colorCount: number;
  tags: string[];
  title: string;
  expanded: boolean;
  handleFilterBarToggle: () => void;
  isMobile: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}
const FilterByShadeFilter = ({
  colorCount,
  tags,
  title,
  expanded,
  handleFilterBarToggle,
  setIsVisible,
  isMobile,
}: FilterByShadeProps) => {
  const dispatch = useAppDispatch();
  const { collection } = useParams();
  const [active, setActive] = useState<string>(
    collection ? `ALL ${collection.toUpperCase()}S` : ""
  );
  const handleFilter = (tag: string) => {
    if (tag.includes("ALL")) {
      const oGTag = tag.split(" ")[1].slice(0, -1);
      dispatch(setNewTag(oGTag));
    } else {
      dispatch(setNewTag(tag));
    }
    if (setIsVisible) setIsVisible(false);
    setActive(tag);
  };

  useEffect(() => {
    setActive(collection ? `ALL ${collection.toUpperCase()}S` : "");
  }, [collection]);

  return (
    <Box
      width={{ xs: "100%", sm: "363px" }}
      display="flex"
      justifyContent={{ xs: "flex-start", sm: "space-between" }}
      alignItems="center"
    >
      <Box position="relative" mr={{ sm: 3 }}>
        <Box
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleFilterBarToggle}
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Box display="flex" alignItems="center">
            <Typography ml={isMobile ? 0 : 1}>Shades:</Typography>
            <Typography ml={1} mr={1}>
              {title}
            </Typography>
            {!expanded ? (
              <ExpandMoreIcon htmlColor="black" />
            ) : (
              <ExpandLessIcon htmlColor="black" />
            )}
          </Box>

          <Collapse
            in={expanded}
            sx={{
              position: isMobile ? "unset" : "absolute",
              width: { xs: "100%", sm: "355px" },
              top: "35px",
              left: "10px",
              backgroundColor: "#d0d0d0",
              zIndex: 4,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              py={isMobile ? 1 : 2}
              px={isMobile ? 0 : 2}
            >
              {tags.map((tag) => (
                <FilterBarItem
                  key={tag}
                  item={tag === collection!.toUpperCase() ? `ALL ${tag}S` : tag}
                  active={active}
                  handleFilter={handleFilter}
                  isCollection={false}
                />
              ))}
            </Box>
          </Collapse>
        </Box>
      </Box>
      <Box display={{ xs: "none", sm: "block" }}>
        <Typography>{colorCount} products</Typography>
      </Box>
    </Box>
  );
};
export default FilterByShadeFilter;
