import { createAsyncThunk } from "@reduxjs/toolkit";
import CollectionService from "../services/collections-service";
import { CollectionInterface } from "../interfaces/collection-interfaces";
import { SortState } from "../interfaces/sort-state";
import * as Sentry from "@sentry/react";

export const fetchAllProductsFirstPage = createAsyncThunk<
  CollectionInterface,
  SortState
>(
  "collections/allProductsFirstPage",
  async (sortState: SortState, { rejectWithValue }) => {
    try {
      const response = await CollectionService.getAllProductsCollections(
        sortState
      );
      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

export const fetchAllProductsSecondPage = createAsyncThunk<
  CollectionInterface,
  { cursor: string; sortState: SortState }
>(
  "collections/allProductsSecondPage",
  async (
    { cursor, sortState }: { cursor: string; sortState: SortState },
    { rejectWithValue }
  ) => {
    try {
      const response = await CollectionService.getAllProductsNextPage(
        cursor,
        sortState
      );
      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

export const fetchCollectionPageCollections = createAsyncThunk(
  "collections/allCollections",
  async (_, { rejectWithValue }) => {
    try {
      const response = await CollectionService.getAllCollectionsWithProducts();
      return response;
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(e);
    }
  }
);

interface CountData {
  currCount: number;
  hasNextPage: boolean;
  finalCursor: string;
}
export const getCollectionProductsCount = createAsyncThunk(
  "collections/productsCount",
  async (sortState: SortState, { rejectWithValue }) => {
    try {
      let count = 0;
      let nextPage = true;
      let cursor: string | undefined = undefined;
      while (nextPage) {
        const { currCount, hasNextPage, finalCursor }: CountData =
          await CollectionService.getCollectionProductCount(sortState, cursor);
        count += currCount;
        nextPage = hasNextPage;
        cursor = finalCursor;
      }
      return count;
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(e);
    }
  }
);

export const fetchOpenEndorseCollection = createAsyncThunk(
  "collections/openEndorse",
  async (urlParam: string, { rejectWithValue }) => {
    try {
      const response = await CollectionService.fetchSingleCollection(urlParam);
      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);
