import React from "react";
import Box from "@mui/material/Box";

const MobileEdgeBlur = () => (
  <Box
    height="100%"
    width="30px"
    position="absolute"
    top="0"
    right="0"
    sx={{
      background: "linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
      zIndex: 2,
    }}
  />
);

export default React.memo(MobileEdgeBlur);
