import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestState } from "../../../services/types/requestState.type";
import { RootState } from "../../../store";
import { DiscountInterface } from "../interfaces/discount.interface";
import { ProductInterface } from "../interfaces/product.interface";
import {
  fetchCart,
  createCart,
  addCartItem,
  removeCartItem,
  shopifyCheckout,
  deleteCartItem,
  renameCustomCartItem,
  checkForDiscounts,
} from "./cart-actions";

interface CartState {
  cartItems: ProductInterface[];
  discounts: DiscountInterface[];
  attentiveDiscount: string | null;
  isCartOpen: boolean;
  status: RequestState | null | undefined;
  discountStatus: RequestState | undefined;
}

const initialState: CartState = {
  cartItems: [],
  discounts: [],
  attentiveDiscount: null,
  isCartOpen: false,
  status: null,
  discountStatus: undefined,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setIsCartOpen(state, action: PayloadAction<boolean>) {
      state.isCartOpen = action.payload;
    },
    setAttentiveDiscount(state, action: PayloadAction<string | null>) {
      state.attentiveDiscount = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCart.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.cartItems = action.payload;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(createCart.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createCart.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.cartItems = action.payload;
      })
      .addCase(createCart.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(checkForDiscounts.pending, (state) => {
        state.discountStatus = "pending";
      })
      .addCase(checkForDiscounts.fulfilled, (state, action) => {
        state.discountStatus = "fulfilled";
        state.discounts = action.payload;
      })
      .addCase(checkForDiscounts.rejected, (state) => {
        state.discountStatus = "rejected";
      })
      .addCase(addCartItem.pending, (state) => {
        state.status = "pending";
      })
      .addCase(addCartItem.fulfilled, (state, action) => {
        state.status = "fulfilled";

        const itemInCart = state.cartItems.find(
          (item) => item.key === action.payload.key
        );

        if (itemInCart) {
          const addedQuantity = action.payload.quantity - itemInCart.quantity;
          itemInCart.quantity += addedQuantity;
          itemInCart.value = action.payload.value;
        } else {
          state.cartItems.push(action.payload);
        }
      })
      .addCase(addCartItem.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(removeCartItem.fulfilled, (state, action) => {
        state.status = "fulfilled";
        const removeItem = state.cartItems.find(
          (cartItem) => cartItem.key === action.payload.key
        );
        if (removeItem) {
          if (removeItem.quantity > 1) {
            removeItem.quantity -= 1;
          } else {
            const deleted = state.cartItems.filter(
              (item) => item.key !== action.payload.key
            );
            state.cartItems = deleted;
          }
        }
      })
      .addCase(removeCartItem.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(deleteCartItem.fulfilled, (state, action) => {
        state.status = "fulfilled";
        const deleted = state.cartItems.filter(
          (item) => item.lineId !== action.payload.lineId
        );
        state.cartItems = deleted;
      })
      .addCase(shopifyCheckout.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(renameCustomCartItem.fulfilled, (state, action) => {
        const updated = state.cartItems.find(
          (item) => item.key === action.payload.key
        );
        if (updated) {
          const updatedName = action.payload.value;
          updated.value = updatedName;
        } else {
          return;
        }
      });
  },
});

export const selectCartItems = (state: RootState) => state.cart.cartItems;

export const selectCartTotal = (state: RootState) => {
  const newCartTotal = state.cart.cartItems.reduce(
    (cartTotal, cartItem) => cartTotal + cartItem.price * cartItem.quantity,
    0
  );
  return newCartTotal;
};
export const selectCartCount = (state: RootState) => {
  const newCartCount = state.cart.cartItems.reduce(
    (total, cartItem) => total + cartItem.quantity,
    0
  );
  return newCartCount;
};

export const selectIsCartOpen = (state: RootState) => state.cart.isCartOpen;
export const selectAttentiveDiscount = (state: RootState) =>
  state.cart.attentiveDiscount;

export const { setIsCartOpen, setAttentiveDiscount } = cartSlice.actions;

export default cartSlice.reducer;
