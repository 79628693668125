import { useEffect, useRef } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../utils/hooks/redux-hooks";
import { RootState } from "../../../store";
import { fetchUpSells } from "../state/upSell-actions";
import { UpSellInterface } from "../interfaces/up-sell-interface";

export const useGetUpSellProductsQuery = () => {
  const dispatch = useAppDispatch();
  const rendered = useRef<boolean>(true);

  const upSellData = useAppSelector(
    (state: RootState) => state.upSells.upSellItems
  );
  const status = useAppSelector((state: RootState) => state.upSells.status);

  const isUninitialized = status === undefined;
  const isLoadingShopAll = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const selectCartUpsellList = (state: RootState) => {
    const upSellArr: UpSellInterface[] = [];

    if (state.upSells.upSellItems.length) {
      const item1 = state.cart.cartItems.find(
        (item) =>
          item.key.toLowerCase() ===
          state.upSells.upSellItems[0].title.toLowerCase()
      );

      const item2 = state.cart.cartItems.find(
        (item) =>
          item.key.toLowerCase() ===
          state.upSells.upSellItems[1].title.toLowerCase()
      );
      const item3 = state.cart.cartItems.find(
        (item) =>
          item.key.toLowerCase() ===
          state.upSells.upSellItems[2].title.toLowerCase()
      );
      const item4 = state.cart.cartItems.find(
        (item) =>
          item.key.toLowerCase() ===
          state.upSells.upSellItems[3].title.toLowerCase()
      );
      const item5 = state.cart.cartItems.find(
        (item) =>
          item.key.toLowerCase() ===
          state.upSells.upSellItems[4].title.toLowerCase()
      );

      if (item1 === undefined) upSellArr.push(state.upSells.upSellItems[0]);
      if (item2 === undefined) upSellArr.push(state.upSells.upSellItems[1]);
      if (item3 === undefined) upSellArr.push(state.upSells.upSellItems[2]);
      if (item4 === undefined) upSellArr.push(state.upSells.upSellItems[3]);
      if (item5 === undefined) upSellArr.push(state.upSells.upSellItems[4]);
    }


    return upSellArr;
  };

  const cartUpSellList = useAppSelector(selectCartUpsellList);

  useEffect(() => {
    if (rendered.current) {
      if (status === undefined) {
        dispatch(fetchUpSells());
      }
    }
    return () => {
      rendered.current = false;
    };
  }, [status, dispatch]);
  return {
    upSellData,
    cartUpSellList,
    isUninitialized,
    isLoadingShopAll,
    isError,
    isSuccess,
    status,
  };
};
