import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { SHOP_ALL } from "../../../../routing/CONSTANTS";

interface Props {
  isWalmartComponent?: boolean;
}

const ShopAllButton = ({ isWalmartComponent }: Props) => {
  const navigate = useNavigate();

  const handleNavigateToShopAll = () => {
    navigate(SHOP_ALL);
  };

  const handleNavigateToWalmartShopAll = () => {
    navigate("/walmart/shop-all");
  };

  return (
    <Box mt={{ xs: 3, md: 5 }} mb={8}>
      <Button
        fullWidth
        onClick={
          isWalmartComponent
            ? handleNavigateToWalmartShopAll
            : handleNavigateToShopAll
        }
        variant="contained"
        sx={{
          pt: { xs: "9px", md: "10.5px" },
          pb: { xs: "9px", md: "10.5px" },
          px: 4,
          fontSize: { xs: "16px", md: "20px" },
          backgroundColor: "white",
          color: "black",
          border: "2px solid black",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        }}
      >
        explore what's trending
      </Button>
    </Box>
  );
};

export default ShopAllButton;
