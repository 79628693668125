import {
  PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import colorPickerReducer from "./pages/color_picker/state/color-picker-slice";
import libraryListReducer from "./pages/color-library/color-library-list-page/state/library-list-slice";
import libraryColorsReducer from "./pages/color-library/color-library-color-page/state/library-colors-slice";
import cartReducer from "./pages/cart/state/cart-slice";
import landingReducer from "./pages/landing/state/landing-slice";
import collectionReducer from "./pages/shop-all/state/collection-slice";
import upSellReducer from "./pages/cart/state/upSell-slice";
import walmartReducer from "./pages/walmart/state/walmart-slice";

const reduxLogger = createLogger({
  level: "info",
  collapsed: true,
  diff: true,
  duration: true,
  timestamp: true,
  colors: {
    title: () => "#FFFFFF",
    prevState: () => "#DFC100",
    action: () => "#4F91E7",
    nextState: () => "#1a9134",
  },
});
const rootReducer = combineReducers({
  colorPicker: colorPickerReducer,
  libraryList: libraryListReducer,
  libraryColors: libraryColorsReducer,
  cart: cartReducer,
  upSells: upSellReducer,
  landing: landingReducer,
  collections: collectionReducer,
  walmart: walmartReducer,
});

export function setupStore(
  testEnv: boolean = false,
  preloadedState?: PreloadedState<RootState>
) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: !testEnv
      ? (getDefaultMiddleware) =>
          process.env.NODE_ENV !== "production"
            ? getDefaultMiddleware().concat(reduxLogger)
            : getDefaultMiddleware()
      : (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== "production",
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export default setupStore;
