import React from "react";

const WSHeroTextMobile = () => (
  <svg
    width="286"
    height="91"
    viewBox="0 0 286 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5111 36.7939C16.9174 36.7939 21.5301 33.6244 22.6709 28.2263H18.455C17.6118 31.1482 15.1814 33.2282 11.9575 33.2282H11.5111C7.24551 33.2282 4.71595 30.3063 4.71595 25.7501C4.71595 19.312 8.4359 14.6073 13.3462 14.6073H13.7926C17.0166 14.6073 19.447 16.192 19.447 19.6092H23.6629C23.6629 14.4092 19.5958 11.0416 13.9414 11.0416C6.60072 11.0416 0.5 16.7368 0.5 26.0473C0.5 33.0301 5.21194 36.7939 11.5111 36.7939Z"
      fill="black"
    />
    <path
      d="M32.5057 36.8795C30.5669 36.8795 29.0642 36.2826 27.9978 35.0887C26.9638 33.8627 26.4467 32.1365 26.4467 29.9101C26.4467 28.4259 26.786 26.7158 27.4646 24.7799C28.1433 22.8117 29.5166 19.5852 31.5848 15.1003L32.118 13.9387L29.0158 10.938H36.8682L34.687 15.5842C32.8127 19.6497 31.4878 22.7795 30.7123 24.9735C29.9367 27.1353 29.5489 28.8615 29.5489 30.1521C29.5489 31.5395 29.9044 32.6366 30.6153 33.4432C31.3586 34.2176 32.3603 34.6048 33.6206 34.6048C36.2058 34.6048 38.6617 32.8624 40.9884 29.3778C43.3473 25.8608 45.4155 20.8758 47.1928 14.4227L47.2897 13.8903L44.236 10.938H51.119L46.3203 28.797C46.0295 29.7972 45.884 30.7813 45.884 31.7493C45.884 32.6527 46.0456 33.3464 46.3688 33.8304C46.6919 34.3144 47.1605 34.5564 47.7745 34.5564C48.1622 34.5564 48.5015 34.4596 48.7924 34.266C49.1155 34.0724 49.4225 33.7659 49.7133 33.3464L50.0526 33.6852C49.8264 34.5241 49.3902 35.1855 48.7439 35.6695C48.1299 36.1212 47.3705 36.3471 46.4657 36.3471C45.3347 36.3471 44.4622 35.9599 43.8482 35.1855C43.2342 34.3789 42.9272 33.2657 42.9272 31.8461C42.9272 30.8136 43.0888 29.7004 43.412 28.5066C40.4067 34.0885 36.7713 36.8795 32.5057 36.8795Z"
      fill="black"
    />
    <path
      d="M72.5948 36.2987V33.8113H75.086V31.3239H77.5772V26.3321H75.086V23.8447H70.0866V21.3573H61.3503V16.3654H70.0866V18.8528H77.5772V16.3654H75.086V13.878H72.5948V11.3906H60.1047V13.878H57.6135V16.3654H55.1223V21.3573H57.6135V23.8447H62.5959V26.3321H71.3322V31.3239H62.5959V28.8365H55.1223V31.3239H57.6135V33.8113H60.1047V36.2987H72.5948Z"
      fill="black"
    />
    <path
      d="M87.2986 36.8795C85.7152 36.8795 84.4711 36.3955 83.5663 35.4275C82.6615 34.4596 82.2091 33.169 82.2091 31.5557C82.2091 30.6522 82.3706 29.5552 82.6938 28.2646L86.8624 12.9223H81.8698L82.403 10.938H87.3471L91.3218 3.24269H92.6306L90.5947 10.938H97.0415L96.5568 12.9223H90.11L85.9414 28.3614C85.6183 29.4907 85.4567 30.4748 85.4567 31.3137C85.4567 32.2817 85.7314 33.0722 86.2807 33.6852C86.8301 34.2982 87.6218 34.6048 88.6559 34.6048C89.593 34.6048 90.4978 34.3466 91.3703 33.8304C92.2751 33.3141 93.1153 32.6043 93.8908 31.7009L94.3756 32.0881C93.6646 33.54 92.679 34.7016 91.4188 35.5727C90.1908 36.4439 88.8174 36.8795 87.2986 36.8795Z"
      fill="black"
    />
    <path
      d="M107.709 36.8795C104.736 36.8795 102.409 35.9599 100.729 34.1208C99.0484 32.2816 98.2083 29.7488 98.2083 26.5223C98.2083 23.5538 98.8869 20.8113 100.244 18.2945C101.634 15.7456 103.492 13.729 105.818 12.2448C108.145 10.7606 110.682 10.0184 113.429 10.0184C116.401 10.0184 118.712 10.9057 120.36 12.6803C122.04 14.455 122.881 16.9555 122.881 20.1821C122.881 23.1505 122.186 25.9253 120.796 28.5066C119.439 31.0556 117.597 33.0883 115.27 34.6048C112.944 36.1212 110.423 36.8795 107.709 36.8795ZM101.65 27.0546C101.65 29.6359 102.183 31.6202 103.249 33.0076C104.316 34.3628 105.835 35.0403 107.806 35.0403C109.842 35.0403 111.748 34.3466 113.525 32.9592C115.335 31.5718 116.773 29.7165 117.839 27.3934C118.906 25.038 119.439 22.5213 119.439 19.8433C119.439 17.2621 118.922 15.2939 117.888 13.9387C116.854 12.5513 115.351 11.8576 113.38 11.8576C111.344 11.8576 109.421 12.5513 107.612 13.9387C105.802 15.3261 104.348 17.1975 103.249 19.5529C102.183 21.876 101.65 24.3766 101.65 27.0546Z"
      fill="black"
    />
    <path
      d="M131.183 14.1323L127.887 10.938H134.722L131.959 21.876C133.672 18.1655 135.594 15.2616 137.727 13.1643C139.892 11.0671 142.041 10.0184 144.174 10.0184C145.757 10.0184 147.018 10.567 147.955 11.664C148.892 12.7287 149.36 14.1484 149.36 15.923C149.36 16.5038 149.28 17.2298 149.118 18.101C152.253 12.7126 155.742 10.0184 159.588 10.0184C161.398 10.0184 162.836 10.5831 163.902 11.7124C165.001 12.8094 165.55 14.2936 165.55 16.165C165.55 17.7138 165.146 19.2302 164.338 20.7145L160.46 28.1678C159.523 30.0069 159.055 31.4427 159.055 32.4752C159.055 33.2819 159.249 33.9111 159.636 34.3628C160.024 34.8145 160.574 35.0403 161.284 35.0403C161.705 35.0403 162.076 34.9436 162.399 34.75C162.722 34.5564 163.029 34.2498 163.32 33.8304L163.854 34.2176C163.595 35.0242 163.094 35.6695 162.351 36.1535C161.608 36.6375 160.735 36.8795 159.733 36.8795C158.538 36.8795 157.568 36.4923 156.825 35.7179C156.114 34.9436 155.759 33.9111 155.759 32.6204C155.759 31.2976 156.26 29.6359 157.261 27.6354L160.994 20.7145C161.834 19.327 162.254 17.859 162.254 16.3102C162.254 15.0519 161.963 14.0839 161.381 13.4063C160.8 12.6965 159.992 12.3416 158.958 12.3416C157.471 12.3416 155.904 13.0675 154.256 14.5195C152.64 15.9392 151.089 17.9396 149.603 20.5209C148.116 23.0698 146.84 25.9899 145.773 29.281L143.447 36.2987H140.151L145.386 20.5693C146 18.5365 146.307 17.0039 146.307 15.9714C146.307 14.8099 146.048 13.9064 145.531 13.2611C145.014 12.6158 144.271 12.2932 143.301 12.2932C141.686 12.2932 140.005 13.1966 138.26 15.0035C136.515 16.7781 134.851 19.2948 133.268 22.5536C131.717 25.7801 130.375 29.4907 129.244 33.6852L128.517 36.2987H125.27L131.183 14.1323Z"
      fill="black"
    />
    <path
      d="M196.241 36.2987V33.8113H198.732V31.3239H201.224V26.3321H196.241V28.8365H193.733V31.3239H186.242V28.8365H183.751V18.8528H186.242V16.3654H193.733V18.8528H196.241V21.3573H201.224V16.3654H198.732V13.878H196.241V11.3906H183.751V13.878H181.26V16.3654H178.769V31.3239H181.26V33.8113H183.751V36.2987H196.241Z"
      fill="black"
    />
    <path
      d="M213.32 36.8795C210.347 36.8795 208.021 35.9599 206.34 34.1208C204.66 32.2816 203.82 29.7488 203.82 26.5223C203.82 23.5538 204.498 20.8113 205.855 18.2945C207.245 15.7456 209.103 13.729 211.43 12.2448C213.756 10.7606 216.293 10.0184 219.04 10.0184C222.013 10.0184 224.323 10.9057 225.971 12.6803C227.652 14.455 228.492 16.9555 228.492 20.1821C228.492 23.1505 227.797 25.9253 226.408 28.5066C225.05 31.0556 223.208 33.0883 220.882 34.6048C218.555 36.1212 216.035 36.8795 213.32 36.8795ZM207.261 27.0546C207.261 29.6359 207.794 31.6202 208.861 33.0076C209.927 34.3628 211.446 35.0403 213.417 35.0403C215.453 35.0403 217.359 34.3466 219.137 32.9592C220.946 31.5718 222.384 29.7165 223.451 27.3934C224.517 25.038 225.05 22.5213 225.05 19.8433C225.05 17.2621 224.533 15.2939 223.499 13.9387C222.465 12.5513 220.963 11.8576 218.991 11.8576C216.956 11.8576 215.033 12.5513 213.223 13.9387C211.414 15.3261 209.959 17.1975 208.861 19.5529C207.794 21.876 207.261 24.3766 207.261 27.0546Z"
      fill="black"
    />
    <path
      d="M235.259 36.8795C234.031 36.8795 233.062 36.4923 232.351 35.7179C231.64 34.9436 231.285 33.8143 231.285 32.33C231.285 31.2653 231.479 29.9424 231.866 28.3614L238.604 3.33948L234.92 0H242.676L235.066 28.797C234.678 30.4102 234.484 31.5395 234.484 32.1849C234.484 33.1206 234.694 33.8465 235.114 34.3628C235.534 34.8468 236.1 35.0887 236.811 35.0887C237.231 35.0887 237.586 34.992 237.877 34.7984C238.2 34.6048 238.507 34.2982 238.798 33.8788L239.331 34.2176C239.073 34.992 238.572 35.6373 237.828 36.1535C237.118 36.6375 236.261 36.8795 235.259 36.8795Z"
      fill="black"
    />
    <path
      d="M253.494 36.7939C260.835 36.7939 266.588 30.5539 266.588 22.2835C266.588 15.5978 262.62 11.0416 255.776 11.0416C248.435 11.0416 242.681 17.3311 242.681 25.552C242.681 32.2378 246.649 36.7939 253.494 36.7939ZM253.444 33.2282C249.229 33.2282 246.897 29.7616 246.897 25.4035C246.897 18.5197 251.262 14.6073 255.329 14.6073H255.776C259.992 14.6073 262.372 18.0244 262.372 22.432C262.372 29.3158 257.958 33.2282 253.891 33.2282H253.444Z"
      fill="black"
    />
    <path
      d="M271.215 36.2987L273.745 24.4625C275.134 17.9749 278.655 15.3997 282.822 15.3997H284.657L285.5 11.2892H283.615C279.598 11.2892 277.118 13.2206 275.431 16.4397L276.423 11.5368H272.257L266.999 36.2987H271.215Z"
      fill="black"
    />
    <path
      d="M55.4259 81.9011C54.2303 81.9011 53.2931 81.5623 52.6145 80.8848C51.9359 80.1749 51.5966 79.1908 51.5966 77.9325C51.5966 76.4805 52.0652 74.8511 53.0023 73.0443L56.7831 65.7361C57.4617 64.381 57.801 63.0581 57.801 61.7675C57.801 60.4446 57.4941 59.4121 56.8801 58.67C56.2661 57.8956 55.4098 57.5084 54.3111 57.5084C52.6307 57.5084 50.8857 58.3957 49.0761 60.1703C47.2988 61.9449 45.6022 64.4455 43.9865 67.672C42.4031 70.8663 41.0459 74.5446 39.9148 78.7068L39.1878 81.3204H35.9401L41.8537 59.1539L38.5576 55.9597H45.6345L42.8232 66.8009C44.5358 62.8967 46.4101 59.9606 48.4459 57.9924C50.5141 56.0242 52.6953 55.0401 54.9897 55.0401C56.8639 55.0401 58.3666 55.637 59.4976 56.8308C60.6286 58.0247 61.1941 59.5895 61.1941 61.5255C61.1941 62.9451 60.8386 64.3487 60.1277 65.7361L56.2015 73.5282C55.7167 74.4639 55.3774 75.2383 55.1835 75.8513C54.9897 76.4644 54.8927 77.0452 54.8927 77.5937C54.8927 78.4003 55.0704 79.0134 55.4259 79.4328C55.8137 79.8523 56.3469 80.062 57.0255 80.062C57.4779 80.062 57.8657 79.9813 58.1888 79.82C58.512 79.6264 58.8028 79.3199 59.0613 78.9004L59.5945 79.2392C59.3037 80.0781 58.7866 80.7396 58.0434 81.2236C57.3002 81.6753 56.4277 81.9011 55.4259 81.9011Z"
      fill="black"
    />
    <path
      d="M79.9306 81.3204V78.8329H82.4389V81.3204H87.4213V61.3871H84.9301V58.8997H82.4389V56.4123H69.9488V58.8997H67.4576V61.3871H64.9664V63.8745H72.44V61.3871H79.9306V63.8745H82.4389V66.3789H72.44V68.8663H67.4576V71.3537H64.9664V76.3455H67.4576V78.8329H69.9488V81.3204H79.9306ZM71.1944 76.3455V71.3537H79.9306V68.8663H82.4389V73.8581H79.9306V76.3455H71.1944Z"
      fill="black"
    />
    <path
      d="M96.9691 47.7804C96.9691 47.1028 97.1953 46.5381 97.6477 46.0864C98.1324 45.6347 98.698 45.4088 99.3442 45.4088C99.9905 45.4088 100.54 45.6347 100.992 46.0864C101.445 46.5381 101.671 47.1028 101.671 47.7804C101.671 48.4579 101.445 49.0226 100.992 49.4743C100.572 49.926 100.023 50.1519 99.3442 50.1519C98.6656 50.1519 98.1001 49.926 97.6477 49.4743C97.1953 49.0226 96.9691 48.4579 96.9691 47.7804ZM95.1272 81.9011C93.8992 81.9011 92.9136 81.5139 92.1704 80.7396C91.4594 79.9652 91.104 78.8359 91.104 77.3517C91.104 76.416 91.314 75.0931 91.7341 73.383L95.5634 59.2023L92.2188 55.9597H99.6835L94.8848 73.8186C94.5617 75.206 94.4001 76.3353 94.4001 77.2065C94.4001 79.1424 95.0949 80.1104 96.4844 80.1104C97.2923 80.1104 97.9547 79.7071 98.4717 78.9004L99.0049 79.2392C98.7464 80.0136 98.2779 80.6589 97.5993 81.1752C96.9206 81.6591 96.0966 81.9011 95.1272 81.9011Z"
      fill="black"
    />
    <path
      d="M107.009 81.9011C105.781 81.9011 104.811 81.5139 104.1 80.7396C103.389 79.9652 103.034 78.8359 103.034 77.3517C103.034 76.2869 103.228 74.964 103.615 73.383L110.353 48.3611L106.669 45.0216H114.425L106.815 73.8186C106.427 75.4319 106.233 76.5612 106.233 77.2065C106.233 78.1422 106.443 78.8682 106.863 79.3844C107.283 79.8684 107.849 80.1104 108.56 80.1104C108.98 80.1104 109.335 80.0136 109.626 79.82C109.949 79.6264 110.256 79.3199 110.547 78.9004L111.08 79.2392C110.822 80.0136 110.321 80.6589 109.578 81.1752C108.867 81.6591 108.01 81.9011 107.009 81.9011Z"
      fill="black"
    />
    <path
      d="M129.135 59.1539L125.79 55.9597H133.013L131.025 63.4614C132.383 60.6866 133.966 58.5893 135.776 57.1696C137.618 55.7499 139.621 55.0401 141.786 55.0401C144.145 55.0401 145.987 55.8467 147.312 57.46C148.637 59.041 149.299 61.2351 149.299 64.0422C149.299 67.0429 148.54 69.9306 147.021 72.7055C145.502 75.448 143.515 77.6743 141.059 79.3844C138.603 81.0622 136.05 81.9011 133.4 81.9011C132.108 81.9011 130.896 81.6914 129.765 81.272C128.666 80.8525 127.729 80.2879 126.954 79.578L123.851 91H120.652L129.135 59.1539ZM127.39 77.8357C128.036 78.481 128.828 78.9972 129.765 79.3844C130.734 79.7716 131.752 79.9652 132.819 79.9652C134.952 79.9652 137.02 79.207 139.023 77.6905C141.059 76.174 142.707 74.1735 143.967 71.6891C145.228 69.2047 145.858 66.6234 145.858 63.9454C145.858 61.8481 145.438 60.2348 144.597 59.1055C143.757 57.944 142.529 57.3632 140.914 57.3632C138.361 57.3632 136.002 58.7022 133.837 61.3803C131.672 64.0583 129.975 67.8011 128.747 72.6087L127.39 77.8357Z"
      fill="black"
    />
    <path
      d="M170.477 81.3204V78.8329H172.969V76.3455H175.46V61.3871H172.969V58.8997H170.477V56.4123H157.987V58.8997H155.496V61.3871H153.005V76.3455H155.496V78.8329H157.987V81.3204H170.477ZM160.479 76.3455V73.8581H157.987V63.8745H160.479V61.3871H167.969V63.8745H170.477V73.8581H167.969V76.3455H160.479Z"
      fill="black"
    />
    <path
      d="M182.467 81.9011C181.239 81.9011 180.269 81.5139 179.558 80.7396C178.848 79.9652 178.492 78.8359 178.492 77.3517C178.492 76.2869 178.686 74.964 179.074 73.383L185.811 48.3611L182.128 45.0216H189.883L182.273 73.8186C181.885 75.4319 181.691 76.5612 181.691 77.2065C181.691 78.1422 181.901 78.8682 182.321 79.3844C182.741 79.8684 183.307 80.1104 184.018 80.1104C184.438 80.1104 184.793 80.0136 185.084 79.82C185.407 79.6264 185.714 79.3199 186.005 78.9004L186.538 79.2392C186.28 80.0136 185.779 80.6589 185.036 81.1752C184.325 81.6591 183.469 81.9011 182.467 81.9011Z"
      fill="black"
    />
    <path
      d="M196.285 47.7804C196.285 47.1028 196.511 46.5381 196.963 46.0864C197.448 45.6347 198.014 45.4088 198.66 45.4088C199.306 45.4088 199.856 45.6347 200.308 46.0864C200.76 46.5381 200.987 47.1028 200.987 47.7804C200.987 48.4579 200.76 49.0226 200.308 49.4743C199.888 49.926 199.339 50.1519 198.66 50.1519C197.981 50.1519 197.416 49.926 196.963 49.4743C196.511 49.0226 196.285 48.4579 196.285 47.7804ZM194.443 81.9011C193.215 81.9011 192.229 81.5139 191.486 80.7396C190.775 79.9652 190.42 78.8359 190.42 77.3517C190.42 76.416 190.63 75.0931 191.05 73.383L194.879 59.2023L191.534 55.9597H198.999L194.2 73.8186C193.877 75.206 193.716 76.3353 193.716 77.2065C193.716 79.1424 194.41 80.1104 195.8 80.1104C196.608 80.1104 197.27 79.7071 197.787 78.9004L198.321 79.2392C198.062 80.0136 197.594 80.6589 196.915 81.1752C196.236 81.6591 195.412 81.9011 194.443 81.9011Z"
      fill="black"
    />
    <path
      d="M210.725 81.8156C216.082 81.8156 220.149 78.7946 220.149 74.3375C220.149 70.1775 217.024 68.5927 214.147 67.6518L211.171 66.6613C209.237 66.0175 208.146 65.027 208.146 63.4423C208.146 61.2137 210.08 59.6289 212.808 59.6289H213.255C215.933 59.6289 218.413 61.2137 218.462 64.1356H222.728C222.629 58.8861 218.363 56.0632 213.304 56.0632C207.65 56.0632 204.029 59.5794 204.029 63.8384C204.029 67.1565 206.311 69.088 209.931 70.2765L212.759 71.168C214.693 71.8118 215.933 72.9013 215.933 74.4365C215.933 76.7146 213.899 78.2499 210.973 78.2499H210.477C207.551 78.2499 205.12 76.8632 204.922 73.7432H200.656C200.855 78.7946 204.624 81.8156 210.725 81.8156Z"
      fill="black"
    />
    <path
      d="M226.176 81.3204L228.904 68.4442C230.144 62.4518 233.417 59.6289 237.088 59.6289H237.534C241.056 59.6289 242.544 62.6004 241.701 66.6118L238.526 81.3204H242.742L245.867 66.6118C247.355 59.827 243.337 56.0632 238.526 56.0632C235.352 56.0632 232.574 57.4994 230.739 60.0251L233.517 46.6537H229.301L221.96 81.3204H226.176Z"
      fill="black"
    />
  </svg>
);

export default WSHeroTextMobile;
