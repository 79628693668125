import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BlankAppbar from "../../../components/blank-appbar/blank-appbar";

interface Props {
  isWalmart: boolean;
}

const ColorJourneyTitle = ({ isWalmart = false }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    mb={{ xs: 2, md: 3 }}
  >
    <BlankAppbar isWalmartComponent={isWalmart} />
    <Box mb={1.25}>
      <Typography
        variant="h1"
        fontSize={{ xs: "30px", md: "48px" }}
        lineHeight={{ xs: "41px", md: "42px" }}
      >
        Create your color
      </Typography>
    </Box>
    <Box>
      <Typography
        maxWidth={{ xs: "222px", sm: "100%" }}
        fontFamily="ABC Favorit Lining"
        fontSize={{ xs: "17px", md: "24px" }}
        lineHeight={{ xs: "22px", md: "32px" }}
        fontWeight="300px"
        textAlign="center"
      >
        Choose how to create a custom polish color
      </Typography>
    </Box>
  </Box>
);

export default ColorJourneyTitle;
