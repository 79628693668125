import { useCallback, useEffect, useState } from "react";
import ProductService from "../../product/services/product-service";
import { PolishTypes, ProductInterface } from "../interfaces/product.interface";
import * as Sentry from "@sentry/react";
import { convertHexToHsv } from "../../../utils/convertHexToHsv";

export const useGetCartCustomPolishColor = (item: ProductInterface) => {
  const [hue, setHue] = useState(0);
  const [sat, setSat] = useState(0);
  const [val, setVal] = useState(99);

  const getColorCallBack = useCallback(() => {
    if (
      item.polishType === PolishTypes.ShopifyPolish ||
      item.polishType === PolishTypes.UpsellPolish ||
      item.polishType === PolishTypes.FullCollectionProduct
    ) {
      return;
    } else {
      if (item.key.split("")[0] === "#") {
        if (item.key.includes("-")) {
          const hex = item.key.split("-")[0];
          const hsv = convertHexToHsv(hex);
          setHue(hsv.h);
          setSat(hsv.s);
          setVal(hsv.v);
          return;
        } else {
          const hsv = convertHexToHsv(item.key);
          setHue(hsv.h);
          setSat(hsv.s);
          setVal(hsv.v);
          return;
        }
      } else {
        ProductService.getColor(item.key)
          .then((res: any) => {
            setHue(res.data.AssignedHue);
            setSat(res.data.AssignedSaturation);
            setVal(res.data.AssignedValue);
          })
          .catch((e) => Sentry.captureException(e));
      }
    }
  }, [item.polishType, item.key]);

  useEffect(() => {
    getColorCallBack();
  }, [getColorCallBack]);

  return { hue, sat, val };
};
