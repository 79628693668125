import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import BlankFooter from "../../components/blank-footer/blank-footer";
import { useGetAllCollections } from "./hooks/useGetAllCollections";
import Collection from "./components/collection";
import { useCheckMobileScreen } from "../../utils/hooks/useCheckMobileScreen";
import CollectionsPageSkeletonGrid from "./components/skeletons/collections-page-skeleton-grid";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";
import SEO from "../../components/seo";

interface Props {
  isWalmart?: boolean;
}
const CollectionsPage = ({ isWalmart = false }: Props) => {
  const { collectionsData, isLoading } = useGetAllCollections();
  const { isMobile } = useCheckMobileScreen();
  usePixelServiceOnLoad();
  return (
    <Box bgcolor="secondary.main">
      <SEO
        title="Shop our collections | Blank Beauty"
        urlEndpoint="collections"
        description="Long-lasting, chip-resistant nail polish colors by Blank Beauty. 13-free and full coverage that lasts. Want something unique? Create your own nail polish."
        type="website"
      />
      <BlankAppbar isWalmartComponent={isWalmart} />
      <Box pt={{ xs: 14, sm: 17 }} pb={{ sm: 3 }}>
        <Typography align="center" variant="h3">
          Collections
        </Typography>
      </Box>
      <Box m="35px auto 35px auto" maxWidth="1400px">
        {isLoading ? (
          <CollectionsPageSkeletonGrid isMobile={isMobile} />
        ) : (
          <Grid
            container
            justifyContent="center"
            spacing={{ xs: 5, md: 6 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {collectionsData &&
              collectionsData.map((col) => (
                <Grid key={col.collectionId} item xs={12} sm={6} md={4} lg={3}>
                  <Collection collection={col} />
                </Grid>
              ))}
          </Grid>
        )}
      </Box>
      <BlankFooter />
    </Box>
  );
};
export default CollectionsPage;
