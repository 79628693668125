import { graphqlClientRequest } from "../../../services/graphQl/graphQl-common";
import { UP_SELLS_QUERY } from "../../../services/graphQl/queries/index";
import { UpSellInterface } from "../interfaces/up-sell-interface";

const fetchUpSells = async (productId: string) => {
  const data = {
    query: UP_SELLS_QUERY,
    variables: {
      id: productId,
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) {
    throw res.data.errors[0].message;
  }
  const item = res.data.data.product;
  const upSellProd: UpSellInterface = {
    productId: item.id,
    title: item.title,
    description: item.description,
    merchId: item.variants.edges[0].node.id,
    quantity: 0,
    price: item.variants.edges[0].node.price.amount,
    image: item.metafield.value,
  };

  return upSellProd;
};

const UpSellService = {
  fetchUpSells,
};

export default UpSellService;
