export const NEXT_PAGE_COLLECTION_QUERY = `
query 	queryCollection($handle: String!, $sortKey: ProductCollectionSortKeys!, $reverse:Boolean! $after: String $filters: [ProductFilter!]){
  collection (handle:$handle) {
    id
    title
    description
    products (
      first:20
      after: $after 
      sortKey:$sortKey 
      reverse:$reverse
      filters:$filters) { 
			
      edges {
				
				cursor
        node {
					 id
          title
          seo { title description }
          tags
          handle
					metafield(namespace: "custom_fields" key: "image") { 
						value
					}
					variants(first: 2) {
      edges {
				
        cursor
        node {
          id
          title
          
				
          price {
            amount
            currencyCode
          }
        }
      }
    }

        }
      }
			pageInfo { 
				hasNextPage
				hasPreviousPage
			}
    }
  }
}`;
