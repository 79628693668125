import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button, Container } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ROOT } from "./CONSTANTS";
import pageNotFoundStyles from "./page-not-found.styles";
import BlankAppbar from "../components/blank-appbar/blank-appbar";

interface Props {
  isWalmart?: boolean;
}
const PageNotFound = ({ isWalmart = false }: Props) => (
  <Box sx={pageNotFoundStyles.pageStyles}>
    <BlankAppbar isWalmartComponent={isWalmart} />
    <Container sx={pageNotFoundStyles.notFoundContainerStyles}>
      <Typography variant="h5" fontWeight="bold" fontFamily="Roboto Mono">
        404
      </Typography>
      <Typography my={2} variant="h6" textAlign="center">
        Does the fact we're called Blank make this blank 404 page a little less
        awkward?
      </Typography>

      <Link to={ROOT} style={{ textDecoration: "none" }}>
        <Button
          sx={{ color: "white" }}
          size="medium"
          startIcon={<ArrowBackIosNewIcon fontSize="medium" />}
        >
          Get back to color
        </Button>
      </Link>
    </Container>
  </Box>
);

export default PageNotFound;
