import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  title: string;
  text: string;
}
const MakeItYoursInfoBox = ({ title, text }: Props) => (
  <Box
    p="30px"
    mb={{ xs: 3, md: 0 }}
    borderRadius="20px"
    display="flex"
    flexDirection="column"
    alignItems="center"
    width={{ xs: "212px", md: "307px" }}
    sx={{ backgroundColor: "#E7E7E7" }}
  >
    <Box mb={2}>
      <Typography
        fontFamily="ABC Favorit Light"
        fontSize="18px"
        lineHeight="20px"
      >
        {title}
      </Typography>
    </Box>
    <Box>
      <Typography
        fontFamily="ABC Favorit Light"
        fontStyle="italic"
        fontSize={{ xs: "14px", md: "16px" }}
        lineHeight="17px"
        textAlign="center"
      >
        {text}
      </Typography>
    </Box>
  </Box>
);

export default MakeItYoursInfoBox;
