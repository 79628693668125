import React from "react";
import Grid from "@mui/material/Grid";
import CollectionSkeletonGridCard from "./collections-page-skeleton-card";

interface Props {
  isMobile: boolean;
}
const CollectionsPageSkeletonGrid = ({ isMobile }: Props) => (
  <Grid
    container
    justifyContent="center"
    spacing={{ xs: 5, md: 6 }}
    columns={{ xs: 4, sm: 8, md: 12 }}
  >
    {Array.from(new Array(5)).map((_, idx) => (
      <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
        <CollectionSkeletonGridCard isMobile={isMobile} />
      </Grid>
    ))}
  </Grid>
);
export default CollectionsPageSkeletonGrid;
