import React, { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import FilterIcon from "../filter-icon.component";
import FilterByCollectionFilter from "./filter-by-collection-filter";
import FilterByShadeFilter from "./filter-by-shade-filter";

interface MobileFilterProps {
  colorCount: number;
  tags: string[];
  title: string;
  collectionFilterExpanded: boolean;
  setCollectionFilterExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  shadeFilterExpanded: boolean;
  handleShadeFilterBarToggle: () => void;
  handleCollectionFilterBarToggle: () => void;
}

const MobileFilterBar = ({
  colorCount,
  tags,
  title,
  collectionFilterExpanded,
  setCollectionFilterExpanded,
  shadeFilterExpanded,
  handleCollectionFilterBarToggle,
  handleShadeFilterBarToggle,
}: MobileFilterProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleSetVisible = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box
      width="100%"
      display={{ xs: "flex", sm: "none" }}
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      mt={2}
      mb={4}
      px={2}
    >
      <Box width="100%" display="flex" alignItems="flex-start">
        <Box onClick={handleSetVisible} mr={1}>
          <FilterIcon />
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ cursor: "pointer" }}
        >
          <Box width="100%" onClick={handleSetVisible}>
            <Typography>Filter and sort</Typography>
          </Box>
        </Box>
        <Collapse
          in={isVisible}
          sx={{
            backgroundColor: "#d0d0d0",
            zIndex: 4,
            position: "absolute",
            top: "25px",
          }}
        >
          <Box
            p={1.5}
            display="flex"
            flexDirection="column"
            sx={{ backgroundColor: "#d0d0d0", zIndex: 5 }}
          >
            <Box>
              <FilterByCollectionFilter
                isMobile
                expanded={collectionFilterExpanded}
                setExpanded={setCollectionFilterExpanded}
                handleFilterBarToggle={handleCollectionFilterBarToggle}
                setIsVisible={setIsVisible}
              />
            </Box>
            <Box>
              <FilterByShadeFilter
                isMobile
                colorCount={colorCount}
                tags={tags}
                title={title}
                expanded={shadeFilterExpanded}
                handleFilterBarToggle={handleShadeFilterBarToggle}
                setIsVisible={setIsVisible}
              />
            </Box>
          </Box>
        </Collapse>
      </Box>

      <Box
        width="100%"
        display={{ xs: "flex", sm: "none" }}
        justifyContent="flex-end"
      >
        <Typography>{colorCount} products</Typography>
      </Box>
    </Box>
  );
};
export default MobileFilterBar;
