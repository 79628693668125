import React from "react";
import ProgressiveImage from "../../../../components/progressive-image";

interface Props {
  img: string;
  title: string;
}

const MobileHeroImage = ({ img, title }: Props) => (
  <ProgressiveImage
    src={img}
    placeholderSrc="https://via.placeholder.com/375x450?text="
    alt={title}
    screen="mobile"
    maxHeight="450px"
  />
);

export default MobileHeroImage;
