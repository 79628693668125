import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BuyInBulkInterface } from "../interfaces/buy-in-bulk.interface";
import BlankLabsBtn from "../../go-to-blank-labs-btn";

interface Props {
  bibData: BuyInBulkInterface;
}
const BibInfoBlock = ({ bibData }: Props) => (
  <Box width={{ xs: "100%", md: "375px" }}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems={{ xs: "center", md: "flex-start" }}
      justifyContent="center"
      width={{ xs: "320px", md: "375px" }}
      m={{ xs: "10px auto 0 auto", md: "30px auto 0 auto" }}
    >
      <Box width="100%" mb={1.75}>
        <Typography
          textAlign={{ xs: "center", md: "left" }}
          fontFamily="Nib Pro"
          fontSize="38px"
          lineHeight="38px"
        >
          {bibData.bibTitle}
        </Typography>
      </Box>
      <Box width="100%" pr={2} maxWidth="324px">
        <Typography
          textAlign={{ xs: "center", md: "left" }}
          fontFamily="ABC Favorit Book"
          fontStyle="italic"
          fontSize="16px"
          lineHeight="21px"
        >
          {bibData.bibText}
        </Typography>
      </Box>
      <Box
        display={{ xs: "none", md: "block" }}
        mt={4.5}
        width="100%"
        maxWidth={{ md: "248px" }}
      >
        <BlankLabsBtn
          btnColor="black"
          textColor="white"
          btnText="get in touch"
        />
      </Box>
    </Box>
  </Box>
);
export default BibInfoBlock;
