import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Hero from "./hero/hero.component";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import BlankFooter from "../../components/blank-footer/blank-footer";
import { InfoBannerMobile } from "./info-banner/info-banner";
import HowItWorks from "./how-it-works/how-it-works";
import MatchGuaranteeBanner from "./match-guarantee-banner/match-guarantee-banner";
import CreatingYourColors from "./creating-your-colors/creating-your-colors";
import AboutYourPolish from "./about-your-polish/about-your-polish";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import TrendingShades from "./trending-shades/trending-shades";
import SEO from "../../components/seo";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";

const LandingPage = () => {
  usePixelServiceOnLoad();
  useEffect(() => {
    document.body.style.background = "#E7E7E7";

    return () => {
      document.body.style.background = "white";
    };
  });

  return (
    <Box
      component="main"
      id="MainContent"
      role="main"
      sx={{ backgroundColor: "white" }}
      maxWidth="2000px"
      margin="0 auto"
    >
      <SEO
        title="Create your own custom color of nail polish | Blank Beauty"
        urlEndpoint=""
        description="Create your own nail polish color or choose from our range of high gloss, 13-free and incredibly long-lasting shades. Our nail colors are made in the USA."
        type="website"
      />
      <BlankAppbar />
      <ScrollToTopOnMount />
      <Hero />
      <InfoBannerMobile />
      <HowItWorks />
      <MatchGuaranteeBanner />
      <CreatingYourColors />
      <AboutYourPolish />
      <TrendingShades />

      <BlankFooter />
    </Box>
  );
};

export default LandingPage;
