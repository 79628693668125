import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  title: string;
  topMargin: number;
}
const ShopAllTitle = ({ title, topMargin }: Props) => (
  <Box
    mt={{ md: topMargin }}
    pt={{ xs: 14, md: 9 }}
    display="flex"
    flexDirection="column"
    bgcolor="secondary.main"
  >
    <Box mb={{ xs: 2, md: 3 }}>
      <Typography align="center" variant="h3">
        {title}
      </Typography>
    </Box>
    <Box
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      px={{ xs: 2, md: 0 }}
    >
      <Typography variant="body1" fontStyle="italic" fontWeight="350">
        13-free & vegan
      </Typography>
      <li
        style={{
          paddingLeft: "1em",
          fontFamily: "ABC Favorit Book",
          fontStyle: "italic",
          fontSize: "16px",
        }}
      >
        7+ days of wear
      </li>

      <li
        style={{
          paddingLeft: "1em",
          fontFamily: "ABC Favorit Book",
          fontStyle: "italic",
          fontSize: "16px",
        }}
      >
        Made to order
      </li>
    </Box>
  </Box>
);

export default React.memo(ShopAllTitle);
