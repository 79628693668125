import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IPoweredByBlankProduct } from "../interfaces/powered-by-blank.interface";
import { ProductImage, ProductImageWithBottle } from "./product-image";

interface Props {
  ppbProducts: IPoweredByBlankProduct[];
}
const PPbProducts = ({ ppbProducts }: Props) => (
  <Box
    display="flex"
    overflow="scroll"
    pb={{ xs: 3, md: 5 }}
    pl={{ xs: 2.5, md: 0 }}
    mb={{ xs: 4, md: 0 }}
    sx={{
      "::-webkit-scrollbar": {
        height: "4px",
        width: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        background: "rgba(0,0,0,.5)",
      },
    }}
  >
    {ppbProducts.map((product, idx) => (
      <Box
        key={idx}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mr={3}
      >
        {product.hasBottle ? (
          <ProductImageWithBottle image={product.image} />
        ) : (
          <ProductImage image={product.image} />
        )}

        <Box m="0 auto">
          <Typography
            fontFamily="ABC Favorit Light"
            fontSize="12px"
            lineHeight="22px"
          >
            {" "}
            {product.caption}{" "}
          </Typography>
        </Box>
      </Box>
    ))}
  </Box>
);
export default PPbProducts;
