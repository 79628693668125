import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "../../../../utils/hooks/redux-hooks";
import {
  fetchAllProductsFirstPage,
  getCollectionProductsCount,
} from "../../state/collection-actions";
import { SortState } from "../../interfaces/sort-state";
import { getProcessedValue } from "../../utils/get-processed-filter-value";

interface SortByPropsDetails {
  sortState: SortState;
  setSortState: (state: SortState) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SortByDetails = ({
  sortState,
  setSortState,
  setIsVisible,
}: SortByPropsDetails) => {
  const [active, setActive] = useState<string>("Trending");
  const dispatch = useAppDispatch();
  const sortByOptions: string[] = [
    "Trending",
    "Best selling",
    "Alphabetically, A-Z",
    "Alphabetically, Z-A",
    // "Price, low to high",
    // "Price, high to low",
  ];

  const handleSort = (option: string) => {
    const processedSortKey = getProcessedValue(option);
    setActive(option);
    if (option.includes("Z-A") || option.includes("high to low")) {
      const dispatchedValue: SortState = {
        sortKey: processedSortKey,
        reverse: true,
        minPrice: sortState.minPrice,
        maxPrice: sortState.maxPrice,
        available: sortState.available,
      };
      setIsVisible(false);
      setSortState({ ...sortState, sortKey: option, reverse: true });
      dispatch(getCollectionProductsCount(dispatchedValue));
      dispatch(fetchAllProductsFirstPage(dispatchedValue));
    } else {
      const dispatchedValue: SortState = {
        sortKey: processedSortKey,
        reverse: false,
        minPrice: sortState.minPrice,
        maxPrice: sortState.maxPrice,
        available: sortState.available,
      };
      setIsVisible(false);
      setSortState({ ...sortState, sortKey: option, reverse: false });
      dispatch(getCollectionProductsCount(dispatchedValue));
      dispatch(fetchAllProductsFirstPage(dispatchedValue));
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="200px"
      px={1}
      sx={{
        backgroundColor: "#d0d0d0",
        borderRadius: "4px",
      }}
    >
      {sortByOptions.map((option) => (
        <Box
          key={option}
          onClick={() => handleSort(option)}
          mt={1}
          pb={1}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            sx={{ textDecoration: active === option ? "underline" : "none" }}
          >
            {option}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
export default SortByDetails;
