import React from "react";
import Box from "@mui/material/Box";
import heroDesk from "../../../../../assets/wholesaleAssets/wholesaleHeroDesk.webp";
import heroMob from "../../../../../assets/wholesaleAssets/wholesaleHeroMobile.webp";
import WSHeroCTA from "./ws-hero-cta";
import { useSetMobileViewHeight } from "../../../../../utils/hooks/useSetMobileViewHeight";

const WholeSaleHero = () => {
  const { height } = useSetMobileViewHeight();
  return (
    <Box
      height={{ xs: height, sm: "650px" }}
      maxHeight={{ xs: "790px", md: "870px", lg: "1000px" }}
      overflow="hidden"
    >
      <Box
        role="img"
        aria-label="polish bottles image"
        display="flex"
        height={{ xs: "600px", md: "925px", lg: "1025px", xl: "1050px" }}
        maxHeight={{ xs: "725px", md: "885px", lg: "1025px", xl: "1038px" }}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "start" }}
        alignItems={{ xs: "end", sm: "center" }}
        sx={{
          backgroundImage: { xs: `url(${heroMob})`, sm: `url(${heroDesk})` },
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionX: { sm: "center" },
          height: "100%",
          width: "100%",
          objectFit: "cover",
          overflow: "hidden",
        }}
      >
        <WSHeroCTA />
      </Box>
    </Box>
  );
};
export default WholeSaleHero;
