import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { fetchTrendingProducts } from "./landing-actions";
import { CollectionProductInterface } from "../../shop-all/interfaces/collection-interfaces";
import { RequestState } from "../../../services/types/requestState.type";

interface LandingState {
  appBarColor: string;
  trendingProducts: CollectionProductInterface[];
  status: RequestState | undefined;
}

const initialState: LandingState = {
  appBarColor: "white",
  trendingProducts: [],
  status: undefined,
};

const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setAppBarColor(state, action: PayloadAction<string>) {
      state.appBarColor = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTrendingProducts.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        fetchTrendingProducts.fulfilled,
        (state, action: PayloadAction<CollectionProductInterface[]>) => {
          state.status = "fulfilled";
          state.trendingProducts = action.payload;
        }
      )
      .addCase(fetchTrendingProducts.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});
export const selectAppBarColor = (state: RootState) =>
  state.landing.appBarColor;
export const { setAppBarColor } = landingSlice.actions;

export default landingSlice.reducer;
