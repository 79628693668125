import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SlideRoundlet from "./components/slide-roundlet";
import GraphicDirection from "./components/graphic-directions";
import rainbow from "./assets/image2.png";
import hands from "./assets/image1.png";
import girl from "./assets/image3.png";
import Comment from "./components/comment";

import { useNavigate } from "react-router-dom";
import { CREATE_YOUR_COLOR } from "../../../routing/CONSTANTS";

// interface Props {

// }
const ChooseYourColor = () => {
  const navigate = useNavigate();

  const handleNavigateTowalmartShopAll = () => {
    navigate("/walmart/shop-all");
  };

  const handleNavigateToWalmartCreateYourColor = () => {
    navigate(`/walmart${CREATE_YOUR_COLOR}`);
  };

  const handleNavigateToWalmartCollections = () => {
    navigate(`/Walmart/libraryList`);
  };

  return (
    <Box
      height={{ xs: "100%", sm: "60vh", md: "60vh" }}
      minHeight={{ xs: "100vh", sm: "20rem", md: "20rem" }}
      display="flex"
      justifyContent={{ xs: "space-around", sm: "space-between" }}
      flexWrap={{ xs: "wrap", sm: "nowrap" }}
      alignItems="center"
      // flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "white",
        overflowX: "auto",
      }}
    >
      <Box
        pt={{ xs: 2, md: 3 }}
        pb={{ xs: 2, md: 3 }}
        px={{ xs: 0, sm: 1.5 }}
        mr={{ xs: 0, md: 2.5 }}
        mb={{ xs: 2, md: 0 }}
        height={{ xs: "20%", sm: "30vw", md: "65%" }}
        minHeight={"16rem"}
        marginLeft={"2rem"}
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        alignItems={{ xs: "center", sm: "flex-start" }}
        justifyContent={{ xs: "center", sm: "flex-start" }}
        sx={{ backgroundColor: "white", aspectRatio: { xs: "inherit", sm: 1 } }}
      >
        <Box my={1} pt={{ xs: 2, sm: 0 }} pb={{ xs: 0, sm: "1rem" }}>
          <Typography
            textAlign={{ xs: "center", sm: "left" }}
            variant="h6"
            fontSize="32px"
            lineHeight="38px"
          >
            Ways to choose your color
          </Typography>
        </Box>
        <Box
          mb={2}
          mt={1}
          display={{ xs: "none", sm: "block" }}
          sx={{ width: "90%", fontStyle: "italic", lineHeight: "10px" }}
        >
          <Typography textAlign="left">
            Create a custom shade or pick from one of our collections.
          </Typography>
        </Box>
        <Box p={{ xs: 0, md: 1, xl: 2 }} mb={2}>
          {/* <CreateYourColorImage img={slide.image} /> */}
        </Box>
      </Box>

      <Box
        onClick={handleNavigateToWalmartCreateYourColor}
        pt={{ xs: 2, md: 3 }}
        pb={{ xs: 2, md: 3 }}
        px={1.5}
        mr={{ xs: 2, sm: 2.5 }}
        ml={{ xs: 2, sm: 0 }}
        mb={{ xs: 2, md: 0 }}
        height={{ xs: "50%", sm: "30vw", md: "65%" }}
        minHeight={"17rem"}
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#FDC01F",
          aspectRatio: { xs: "inherit", sm: 1 },
          cursor: "pointer",
        }}
      >
        <Box my={1}>
          <Box display={"flex"} alignItems="center">
            <SlideRoundlet slideNumber={1} bg="white" />
            <GraphicDirection direction="Create custom colors" color="black" />
          </Box>
        </Box>

        <Box
          p={{ xs: 0, md: 1, xl: 2 }}
          mb={0}
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <img src={rainbow} alt="rainbow" style={{ width: "50%" }} />
        </Box>
        <Box p={{ xs: 0, md: 1, xl: 2 }} mb={2}>
          <Comment
            comment="You pick the color. Made to order especially for you"
            color="black"
          />
        </Box>
      </Box>

      <Box
        pt={{ xs: 2, md: 3 }}
        pb={{ xs: 2, md: 3 }}
        px={1.5}
        mr={{ xs: 2, sm: 2.5 }}
        ml={{ xs: 2, sm: 0 }}
        mb={{ xs: 2, md: 0 }}
        onClick={handleNavigateToWalmartCollections}
        height={{ xs: "50%", sm: "30vw", md: "65%" }}
        minHeight={"17rem"}
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#0071DC",
          aspectRatio: { xs: "inherit", sm: 1 },
          cursor: "pointer",
        }}
      >
        <Box display={"flex"} alignItems="center">
          <SlideRoundlet slideNumber={2} bg="white" />
          <GraphicDirection direction="Shop collections" color="white" />
        </Box>

        <Box
          p={{ xs: 0, md: 1, xl: 2 }}
          mb={0}
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <img src={hands} alt="hands" style={{ width: "50%" }} />
        </Box>
        <Box p={{ xs: 0, md: 1, xl: 2 }} mb={2}>
          <Comment
            comment="Pick from limited edition color collections"
            color="white"
          />
        </Box>
      </Box>

      <Box
        pt={{ xs: 2, md: 3 }}
        pb={{ xs: 2, md: 3 }}
        px={1.5}
        mr={{ xs: 2, sm: 2.5 }}
        ml={{ xs: 2, sm: 0 }}
        mb={{ xs: 2, md: 0 }}
        height={{ xs: "50%", sm: "30vw", md: "65%" }}
        borderRadius="20px"
        minHeight={"17rem"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#224F95",
          aspectRatio: { xs: "inherit", sm: 1 },
          cursor: "pointer",
        }}
        onClick={handleNavigateTowalmartShopAll}
      >
        <Box display={"flex"} alignItems="center">
          <SlideRoundlet slideNumber={3} bg="white" />
          <GraphicDirection direction="Shop trending shades" color="white" />
        </Box>

        <Box
          p={{ xs: 0, md: 1, xl: 2 }}
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <img src={girl} alt="girl" style={{ width: "50%" }} />
        </Box>
        <Box p={{ xs: 0, md: 1, xl: 2 }} mb={2}>
          <Comment
            comment="Any color. Any shade. Millions of options and counting"
            color="white"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseYourColor;
