import { FormEvent, useState } from "react";
import {
  profanityList,
  profanityListCompounds,
} from "../CONSTANTS/profanity-list";

export const useProductNamerInput = (name: string) => {
  const [errors, setErrors] = useState<string>("");
  const [showInput, setShowInput] = useState(false);

  const handleClick = () => {
    setShowInput(!showInput);
  };

  const profanityCheck = (text: string): boolean => {
    if (text === "ass") return true;

    const compoundSearch = profanityListCompounds.find((word) =>
      text.toLowerCase().includes(word)
    );

    if (compoundSearch) return true;

    const singleSearch = profanityList.find((word) =>
      text.toLowerCase().includes(word)
    );

    if (singleSearch) return true;

    return false;
  };

  const validateProductName = (): string => {
    let error = "";

    if (profanityCheck(name)) {
      error = "Hey, let's keep it PG!";
      setErrors(error);
    } else {
      setErrors("");
    }
    return error;
  };

  const submitForm = (e: FormEvent): boolean => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return false;
    }
    return true;
  };

  const validateInput = (): boolean => {
    const error = validateProductName();
    const isValid = error === "" ? true : false;
    return isValid;
  };

  return {
    handleClick,
    showInput,
    errors,
    submitForm,
  };
};
