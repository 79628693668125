import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BuyInBulkInfoCardInterface } from "../interfaces/buy-in-bulk.interface";

interface Props {
  bibData: BuyInBulkInfoCardInterface;
}
const BibInfoCard = ({ bibData }: Props) => (
  <Box
    height="386px"
    width="250px"
    display="flex"
    flexDirection="column"
    px={2}
    py={2.5}
    mr={{ xs: 5, md: 2 }}
    alignItems="center"
    justifyContent="flex-start"
    borderRadius="22px"
    sx={{ backgroundColor: "#FFFFFF" }}
  >
    <Box mt={1} mb={2}>
      <Typography
        textAlign="center"
        fontFamily="ABC Favorit Light"
        fontSize="18px"
        lineHeight="20px"
      >
        {bibData.cardTitle}
      </Typography>
    </Box>
    <Box component="img" src={bibData.cardImg} />
    <Box mt={2} maxWidth="195px">
      <Typography
        textAlign="center"
        fontFamily="ABC Favorit Light"
        fontStyle="italic"
        fontSize="16px"
        lineHeight="19px"
      >
        {bibData.cardText}
      </Typography>
    </Box>
  </Box>
);
export default BibInfoCard;
