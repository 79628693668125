import { SINGLE_COLLECTION_QUERY } from "../../../services/graphQl/queries/single-collection";
import { graphqlClientRequest } from "../../../services/graphQl/graphQl-common";
import {
  CollectionInterface,
  CollectionProductInterface,
  VariantInterface,
} from "../interfaces/collection-interfaces";
import { NEXT_PAGE_COLLECTION_QUERY } from "../../../services/graphQl/queries/next-page-collection";
import { PRODUCT_COUNT_QUERY } from "../../../services/graphQl/queries/query-product-count";
import { SortState } from "../interfaces/sort-state";
import { FETCH_ALL_COLLECTIONS } from "../../../services/graphQl/queries/fetch-all-collections";

const SHOP_ALL_HANDLE = "shop-all";

const mapTags = (item: any): string[] => {
  const productTags: string[] = [];
  item.forEach((tag: string) => {
    if (tag !== "polish") {
      productTags.push(tag);
    }
  });
  return productTags;
};

const mapVariants = (item: any): VariantInterface[] => {
  const prodVariants: VariantInterface[] = [];
  item.forEach((variant: any) => {
    const prodTitle =
      variant.node.title === "Default Title"
        ? variant.node.price.amount === "11.0"
          ? "Gel"
          : "Lacquer"
        : variant.node.title;
    const newVariant: VariantInterface = {
      variantId: variant.node.id,
      price: variant.node.price.amount,
      title: prodTitle,
    };
    prodVariants.push(newVariant);
  });
  return prodVariants;
};

const mapProducts = (product: []): CollectionProductInterface[] => {
  let products: CollectionProductInterface[] = [];

  product.forEach((item: any) => {
    const productTags = mapTags(item.node.tags);
    const productVariants = mapVariants(item.node.variants.edges);

    const newProducts: CollectionProductInterface = {
      productId: item.node.id,
      merchId: item.node.id,
      title: item.node.title,
      description: item.node.description,
      seoTitle: item.node.seo.title,
      seoDescription: item.node.seo.description,
      handle: item.node.handle,
      image: item.node.metafield ? item.node.metafield.value : "",
      variants: productVariants,
      cursor: item.cursor,
      isRandomImg: false,
      tags: productTags,
    };
    products.push(newProducts);
  });
  return products;
};

const mapAllCollectionsProducts = (
  product: []
): CollectionProductInterface[] => {
  let products: CollectionProductInterface[] = [];

  product.forEach((item: any) => {
    const productTags = mapTags(item.node.tags);
    const productVariants = mapVariants(item.node.variants.edges);

    const newProducts: CollectionProductInterface = {
      productId: item.id,
      merchId: item.node.id,
      title: item.node.title,
      description: item.node.description,
      seoTitle: item.node.seo.title,
      seoDescription: item.node.seo.description,
      handle: item.node.handle,
      whiteText: item.node.metafields[0]
        ? item.node.metafields[0].value === "true"
          ? true
          : false
        : false,
      image: item.node.metafields[1] ? item.node.metafields[1].value : "",
      variants: productVariants,
      cursor: item.cursor,
      isRandomImg: false,
      tags: productTags,
    };
    products.push(newProducts);
  });
  return products;
};

const fetchSingleCollection = async (handle: string) => {
  const data = {
    query: SINGLE_COLLECTION_QUERY,
    variables: {
      handle: handle,
      sortKey: "BEST_SELLING",
      reverse: false,
      filters: {
        price: {
          min: 0,
          max: 100,
        },
        available: true,
      },
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors || !res.data.data.collection) {
    throw res.data.errors[0].message;
  }

  const collTitle = res.data.data.collection.title;
  const collId = res.data.data.collection.id;
  const description = res.data.data.collection.description;
  const image =
    res.data.data.collection.image && res.data.data.collection.image.url;
  const mobileImg =
    res.data.data.collection.metafield &&
    res.data.data.collection.metafield.value;
  const prod: [] = res.data.data.collection.products.edges;

  const products = mapProducts(prod);

  let collection: CollectionInterface = {
    collectionId: collId,
    collectionName: collTitle,
    collectionDescription: description,
    collectionImage: !image ? "" : image,
    collectionMobileImg: mobileImg ? mobileImg : "",
    collectionProducts: products,
    hasNextPage: false,
    cursor: products.at(-1)!.cursor,
  };
  return collection;
};

const getAllProductsCollections = async (sortState: SortState) => {
  const data = {
    query: SINGLE_COLLECTION_QUERY,
    variables: {
      handle: SHOP_ALL_HANDLE,
      sortKey: sortState.sortKey,
      reverse: sortState.reverse,
      filters: [
        {
          price: {
            min: sortState.minPrice,
            max: sortState.maxPrice,
          },
        },
        { available: sortState.available },
      ],
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));

  if (res.data.errors) {
    throw res.data.errors[0].message;
  }

  const collTitle = res.data.data.collection.title;
  const collId = res.data.data.collection.id;
  const fullDescription = res.data.data.collection.description.split(".");
  const description = fullDescription[0];
  const prod: [] = res.data.data.collection.products.edges;
  const pageInfo = res.data.data.collection.products.pageInfo.hasNextPage;

  const products = mapProducts(prod);

  let collection: CollectionInterface = {
    collectionId: collId,
    collectionName: collTitle,
    collectionDescription: description,
    collectionImage: "",
    collectionProducts: products,
    hasNextPage: pageInfo,
    cursor: products.at(-1)!.cursor,
  };
  return collection;
};

const selectCollections = (handle: string): boolean => {
  const selectedCollections = [
    "_pro-gel-kit",
    "_nudes",
    "all",
    "_gel-products",
    "feeling-tough",
    "polish",
    "protect-perfect",
    "sun-soaked-shades",
    "shop-all",
    "maya-x-blank",
    "maya",
    "syd",
    "berries",
    "_pastels",
    "halloween-collection",
    "professional-gels",
    "faith",
    "mimi",
    "kambi",
    "halloween",
    "nevada-x-blank",
  ];

  if (selectedCollections.includes(handle.toLowerCase())) return false;
  return true;
};

const getAllCollectionsWithProducts = async () => {
  const data = { query: FETCH_ALL_COLLECTIONS };

  const res = await graphqlClientRequest.then((res) => res.post(data));

  if (res.data.errors) {
    throw res.data.errors[0].message;
  }
  const cols: [] = res.data.data.collections.edges;

  const collections: CollectionInterface[] = [];

  cols.forEach((col: any) => {
    if (selectCollections(col.node.handle)) {
      const title = col.node.title;
      const id = col.node.id;
      const fullDescription = col.node.description;
      const image = col.node.image ? col.node.url : "";
      const mobileImg = col.node.metafield ? col.node.metafield.value : "";
      const handle = col.node.handle;
      const prod: [] = col.node.products.edges;
      const products = mapAllCollectionsProducts(prod);

      const collection: CollectionInterface = {
        collectionId: id,
        collectionHandle: handle,
        collectionName: title,
        collectionDescription: fullDescription,
        collectionImage: image,
        collectionMobileImg: mobileImg,
        collectionProducts: products,
        hasNextPage: false,
        cursor: "",
      };

      collections.push(collection);
    }
  });
  return collections;
};

const getAllProductsNextPage = async (after: string, sortState: SortState) => {
  const data = {
    query: NEXT_PAGE_COLLECTION_QUERY,
    variables: {
      handle: SHOP_ALL_HANDLE,
      sortKey: sortState.sortKey,
      after: after,
      reverse: sortState.reverse,
      filters: [
        {
          price: {
            min: sortState.minPrice,
            max: sortState.maxPrice,
          },
        },
        { available: sortState.available },
      ],
    },
  };

  const res = await graphqlClientRequest.then((res) => res.post(data));

  if (res.data.errors) {
    throw res.data.errors[0].message;
  }

  const collTitle = res.data.data.collection.title;
  const collId = res.data.data.collection.id;
  const fullDescription = res.data.data.collection.description.split(".");
  const description = fullDescription[0];
  const prod: [] = res.data.data.collection.products.edges;
  const pageInfo = res.data.data.collection.products.pageInfo.hasNextPage;

  const products = mapProducts(prod);

  let collection: CollectionInterface = {
    collectionId: collId,
    collectionName: collTitle,
    collectionDescription: description,
    collectionImage: "",
    collectionProducts: products,
    hasNextPage: pageInfo,
    cursor: products.at(-1)!.cursor,
  };
  return collection;
};

const getCollectionProductCount = async (
  sortState: SortState,
  after?: string
) => {
  const data = {
    query: PRODUCT_COUNT_QUERY,
    variables: {
      handle: SHOP_ALL_HANDLE,
      sortKey: sortState.sortKey,
      reverse: sortState.reverse,
      after: after,
      filters: [
        {
          price: {
            min: sortState.minPrice,
            max: sortState.maxPrice,
          },
        },
        { available: sortState.available },
      ],
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) {
    throw res.data.errors[0].message;
  }

  const currCount: number = res.data.data.collection.products.edges.length;
  const hasNextPage: boolean =
    res.data.data.collection.products.pageInfo.hasNextPage;
  const finalCursor: string =
    res.data.data.collection.products.edges.at(-1).cursor;

  return { currCount, hasNextPage, finalCursor };
};

const CollectionService = {
  getAllProductsCollections,
  getAllProductsNextPage,
  fetchSingleCollection,
  getCollectionProductCount,
  getAllCollectionsWithProducts,
};
export default CollectionService;
