import {
  IPoweredByBlank,
  IPoweredByBlankProduct,
} from "./powered-by-blank.interface";
import storeDitch from "../../../../../../assets/wholesaleAssets/powered-by-blank-images/shoreDitch.webp";
import cheetos from "../../../../../../assets/wholesaleAssets/powered-by-blank-images/cheetos.webp";
import tigers from "../../../../../../assets/wholesaleAssets/powered-by-blank-images/tigers.webp";
import stax from "../../../../../../assets/wholesaleAssets/powered-by-blank-images/stax.webp";
import cheeto2 from "../../../../../../assets/wholesaleAssets/powered-by-blank-images/cheetoImg2.png";
import cheeto3 from "../../../../../../assets/wholesaleAssets/powered-by-blank-images/cheetoImg3.png";

export const poweredByBlankCompanies: IPoweredByBlank[] = [];

const cheetosProducts: IPoweredByBlankProduct[] = [
  {
    caption: "Brand Manager's nails",
    image: "",
    hasBottle: true,
  },
  {
    caption: "Brand Manager's nails",
    image: cheeto2,
    hasBottle: true,
  },
  {
    caption: "Brand Manager's nails",
    image: cheeto3,
    hasBottle: false,
  },
  {
    caption: "Brand Manager's nails",
    image: cheeto3,
    hasBottle: false,
  },
];
const cheetosPBB: IPoweredByBlank = {
  logo: cheetos,
  title: "Cheetos fingers but not as you know it.",
  subtitle: "6 delicious new custom colors.",
  text: `Cheetos came to us with an idea of Lorem ipsum dolor sit amet consectetur 
  adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit 
  amet sapien fringilla, mattis.`,
  products: cheetosProducts,
};

const storeDitchPBB: IPoweredByBlank = {
  logo: storeDitch,
  title: "StoreDitch fingers but not as you know it.",
  subtitle: "6 delicious new custom colors.",
  text: `Cheetos came to us with an idea of Lorem ipsum dolor sit amet consectetur 
  adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit 
  amet sapien fringilla, mattis.`,
  products: cheetosProducts,
};

const tigersPBB: IPoweredByBlank = {
  logo: tigers,
  title: "Tigers fingers but not as you know it.",
  subtitle: "6 delicious new custom colors.",
  text: `Cheetos came to us with an idea of Lorem ipsum dolor sit amet consectetur 
  adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit 
  amet sapien fringilla, mattis.`,
  products: cheetosProducts,
};

const staxPBB: IPoweredByBlank = {
  logo: stax,
  title: "Stax fingers but not as you know it.",
  subtitle: "6 delicious new custom colors.",
  text: `Cheetos came to us with an idea of Lorem ipsum dolor sit amet consectetur 
  adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit 
  amet sapien fringilla, mattis.`,
  products: cheetosProducts,
};

export const poweredByBlankData: IPoweredByBlank[] = [
  cheetosPBB,
  storeDitchPBB,
  tigersPBB,
  staxPBB,
];
