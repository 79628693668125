import React from "react";
import ProgressiveImage from "../../../../components/progressive-image";

interface Props {
  img: string;
  title: string;
}

const DeskTopHeroImage = ({ img, title }: Props) => (
  <ProgressiveImage
    src={img}
    placeholderSrc="https://via.placeholder.com/828x1000?text="
    alt={title}
    screen="desktop"
    maxHeight="844px"
  />
);

export default DeskTopHeroImage;
