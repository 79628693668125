import React, { FormEvent, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch } from "../../../../utils/hooks/redux-hooks";
import { renameCustomCartItem } from "../../state/cart-actions";
import { ProductInterface } from "../../interfaces/product.interface";
import CustomPolishNamerInput from "./custom-polish-namer-input";
import { useProductNamerInput } from "../../../product/hooks/useProductNamerInput";

interface Props {
  item: ProductInterface;
}
const CartCustomPolishNamer = ({ item }: Props) => {
  const [name, setName] = useState<string>(item.value);
  const { submitForm, errors, showInput, handleClick } =
    useProductNamerInput(name);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleOnClick = (e: FormEvent) => {
    const validate = submitForm(e);
    if (validate === false) return;
    const cartId = localStorage.getItem("cartId");
    const updatedItem: ProductInterface = { ...item, value: name };
    if (cartId != null) {
      const data = {
        cartId,
        lineId: item.lineId,
        updatedItem: updatedItem,
      };
      dispatch(renameCustomCartItem(data));
    }
    handleClick();
  };
  return (
    <>
      {!showInput ? (
        <Box
          width="100%"
          pr={{ sm: "15px" }}
          display="flex"
          justifyContent="space-between"
          alignItems="start"
        >
          <Box
            sx={{
              overflowWrap: "break-word",
            }}
            width="100%"
          >
            <Typography
              variant="h6"
              fontSize={{ xs: "18px", sm: "22px", md: "24px" }}
              lineHeight={{ xs: "20px", sm: "24px", md: "26px" }}
              sx={{
                maxWidth: { xs: "147px", sm: "300px", md: "215px" },
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                lineClamp: 2,
                WebkitBoxOrient: "vertical",
                pl: "2px",
              }}
            >
              {name}
            </Typography>
          </Box>

          <Box>
            <IconButton onClick={handleClick} sx={{ pl: 0 }}>
              <ModeEditOutlinedIcon htmlColor="black" />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box width="100%" display="flex" flexDirection="column">
          <CustomPolishNamerInput
            defaultName={name}
            handleChange={handleChange}
            handleClick={handleOnClick}
          />
          <Typography
            variant="caption"
            lineHeight={{ sx: "13px", md: "24px" }}
            color="red"
          >
            {errors}
          </Typography>
        </Box>
      )}
    </>
  );
};
export default CartCustomPolishNamer;
