export const getProcessedValue = (option: string): string => {
  switch (option) {
    case "Best selling":
      return "BEST_SELLING";
    case "Alphabetically, A-Z":
      return "TITLE";
    case "Alphabetically, Z-A":
      return "TITLE";
    case "Price, low to high":
      return "PRICE";
    case "Price, high to low":
      return "PRICE";
    case "Trending":
      return "COLLECTION_DEFAULT";
    default:
      return "COLLECTION_DEFAULT";
  }
};
