import { useLocation } from "react-router-dom";

export function useKioskHook() {

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    // Get a specific query parameter by name
    const isKiosk = queryParams.get('isKiosk');
 

    return !!Number(isKiosk) || location.pathname.includes('/kiosk')
}
