import { useEffect, useState } from "react";

export const useSetMobileViewHeight = () => {
  const [height, setHeight] = useState(`${window.innerHeight}px`);

  const calcVh = () => {
    const size = `${window.innerHeight}px`;
    setHeight(size);
  };
  useEffect(() => {
    window.addEventListener("resize", calcVh);

    return () => {
      window.removeEventListener("resize", calcVh);
    };
  }, []);

  return { height };
};
