import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBlankColor } from "./color-picker-actions";
import { RootState } from "../../../store";
import { RequestState } from "../../../services/types/requestState.type";
import { IColorData, THsv } from "../interface/color-data.interface";
import { rgb2hsv } from "../../../utils/color-conversions";
const colorsys = require("colorsys");

interface BlankColorState {
  fullColorData: IColorData;
  isDisplayed: boolean;
  instructionsDisplayed: boolean;
  status: RequestState | undefined;
  error: string;
}
const initialColorData: IColorData = {
  hsvColor: { h: 0, s: 0, v: 0 },
  hexColor: "#FFFFFF00",
  rgbColor: "transparent",
  blankColorId: null,
};

const initialState: BlankColorState = {
  fullColorData: initialColorData,
  isDisplayed: false,
  instructionsDisplayed: true,
  status: undefined,
  error: "",
};

const colorPickerSlice = createSlice({
  name: "color",
  initialState,
  reducers: {
    setIsDisplayed: {
      reducer(state, action: PayloadAction<boolean>) {
        state.isDisplayed = action.payload;
      },
      prepare(newDisplay: boolean) {
        return {
          payload: newDisplay,
        };
      },
    },
    setFullColorData: {
      reducer(state, action: PayloadAction<IColorData>) {
        state.fullColorData = action.payload;
        if (state.fullColorData.rgbColor !== "transparent")
          state.isDisplayed = true;
      },
      prepare(rgbString: string) {
        const colorData: IColorData = setColorDataFromRgbValues(rgbString);

        return {
          payload: colorData,
        };
      },
    },
    resetDisplayColor(state) {
      state.fullColorData = initialColorData;
      state.isDisplayed = false;
      state.instructionsDisplayed = true;
    },
    setInstructionsDisplayed(state, action: PayloadAction<boolean>) {
      state.instructionsDisplayed = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBlankColor.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchBlankColor.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.fullColorData.blankColorId = action.payload.id;
        state.fullColorData.hsvColor = {
          h: action.payload.hue,
          s: action.payload.sat,
          v: action.payload.val,
        };
      })
      .addCase(fetchBlankColor.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

const setColorDataFromRgbValues = (rgb: string): IColorData => {
  const rgbValues = extractRGBValues(rgb);
  const hexColor = colorsys.rgbToHex({
    r: rgbValues[0],
    g: rgbValues[1],
    b: rgbValues[2],
  });
  const hsvColor: THsv = convertRGB(rgbValues);
  const colorData: IColorData = {
    hsvColor,
    hexColor,
    rgbColor: rgb,
    blankColorId: null,
  };
  return colorData;
};

const extractRGBValues = (rgb: string): number[] => {
  const rgbId = rgb.split("(")[1].split(")");
  const r = parseInt(rgbId[0].split(",")[0].trim());
  const g = parseInt(rgbId[0].split(",")[1].trim());
  const b = parseInt(rgbId[0].split(",")[2].trim());

  return [r, g, b];
};

const convertRGB = (rgbValues: number[]): THsv => {
  const r = rgbValues[0];
  const g = rgbValues[1];
  const b = rgbValues[2];

  var hsv = rgb2hsv(r, g, b);
  var h = roundValue(hsv.h);
  var s = roundValue(hsv.s);
  var v = roundValue(hsv.v);
  if (v === 0) v = 10;

  return { h, s, v };
};

const roundValue = (num: number): number => {
  const rounded = Math.floor(num / 10) * 10;
  return rounded;
};

export const {
  resetDisplayColor,
  setIsDisplayed,
  setInstructionsDisplayed,
  setFullColorData,
} = colorPickerSlice.actions;

export const selectIsDisplayed = (state: RootState) =>
  state.colorPicker.isDisplayed;
export const selectInstructionsDisplayed = (state: RootState) =>
  state.colorPicker.instructionsDisplayed;
export const selectColorData = (state: RootState) =>
  state.colorPicker.fullColorData;
export default colorPickerSlice.reducer;
