import axios, { AxiosError, AxiosResponse } from "axios";
import http from "../../../services/http/http-common";
import { ServerError } from "../../../services/types/serverError.type";
import { BlankColorInterface } from "../interface/blank-color-interface";
import { BlankDatabaseInterface } from "../interface/database.interface";

const COLOR_URL = "/bbmExpress/api/webColors/webSearch";
const DB_URL = "bbmExpress/api/webColors/fullDbWeb";

const fetchColor = async (hue: number, sat: number, val: number) => {
  try {
    const res = await http.get(COLOR_URL, {
      params: { hue: hue, sat: sat, val: val },
    });

    const color = res.data;
    const newColor: BlankColorInterface = {
      id: color.idColor,
      hue: color.AssignedHue,
      sat: color.AssignedSaturation,
      val: color.AssignedValue,
      colorGroup: color.ColorGroup,
    };
    return newColor;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw new Error(serverError.response.data.errorMessage);
      }
    }
    throw new Error(`Color fetching error: ${err}`);
  }
};

const fetchFullDB = async () => {
  const res = await http.get(DB_URL);
  try {
    const mappedDB = mapDb(res);
    return mappedDB;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw new Error(serverError.response.data.errorMessage);
      }
    }
    throw new Error(`Error fetching database: ${err}`);
  }
};

const colorPickerService = {
  fetchColor,
  fetchFullDB,
};

const mapDb = (response: AxiosResponse<any, any>): BlankDatabaseInterface[] => {
  const dBList: BlankDatabaseInterface[] = [];

  response.data.forEach((item: any) => {
    const newDBItem: BlankDatabaseInterface = {
      hue: item.AssignedHue,
      sat: item.AssignedSaturation,
      val: item.AssignedValue,
      reroute: item.RerouteColor,
    };
    dBList.push(newDBItem);
  });
  return dBList;
};

export default colorPickerService;
