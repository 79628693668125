import React from "react";
import Box from "@mui/material/Box";
import { CollectionProductInterface } from "../../../shop-all/interfaces/collection-interfaces";
import CustomProductDescription from "./custom-product-description";
import "./product-description.css";

interface ProductDescriptionProps {
  isCustomPolish: boolean;
  product?: CollectionProductInterface;
}

const ProductDescription = ({
  isCustomPolish,
  product,
}: ProductDescriptionProps) => {
  const createMarkup = () => {
    if (product !== undefined)
      return {
        __html: product.description,
      };
  };

  return (
    <Box mt={4} mb={3} display="flex" flexDirection="column">
      {isCustomPolish ? (
        <CustomProductDescription />
      ) : (
        <div
          id="description-with-link"
          className="description-markup"
          dangerouslySetInnerHTML={createMarkup()}
        />
      )}
    </Box>
  );
};

export default ProductDescription;
