import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface Props {
  title: string;
}
const ShopifyPolishTitle = ({ title }: Props) => (
  <Box
    sx={{
      overflowWrap: "break-word",
    }}
    width="100%"
  >
    <Typography
      variant="h6"
      fontSize={{ xs: "18px", sm: "22px", md: "24px" }}
      lineHeight={{ xs: "20px", sm: "24px", md: "26px" }}
      sx={{
        maxWidth: { xs: "180px", sm: "300px", md: "230px" },
        pr: "10px",
      }}
    >
      {title}
    </Typography>
  </Box>
);

export default React.memo(ShopifyPolishTitle);
