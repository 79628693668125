import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../utils/hooks/redux-hooks";
import { selectProductCount } from "../state/collection-slice";

interface Props {
  collectionLength: number;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

const ShowMoreProducts = ({
  collectionLength,
  hasNextPage,
  fetchNextPage,
}: Props) => {
  const PRODUCT_COUNT = useAppSelector(selectProductCount);
  return (
    <Box
      mt={collectionLength <= 20 ? "-50px" : 2}
      mb={6}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Typography
          fontFamily="ABC Favorit Light"
          fontSize="16px"
          lineHeight="21.44px"
        >
          Showing {collectionLength} of {PRODUCT_COUNT} products
        </Typography>
      </Box>
      <Box mt={1} width={{ xs: "215px", md: "204px" }}>
        <Button
          disabled={!hasNextPage}
          onClick={fetchNextPage}
          fullWidth
          variant="contained"
          sx={{
            pt: { xs: "10px", md: "12px" },
            pb: { xs: "10px", md: "12px" },
            fontSize: "20px",
            lineHeight: "22px",
            color: "black",
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
        >
          Show more
        </Button>
      </Box>
    </Box>
  );
};
export default ShowMoreProducts;
