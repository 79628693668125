import cyc1 from "../../../../assets/creatingYourColorsAssets/cyc1.png";
import cyc2 from "../../../../assets/creatingYourColorsAssets/cyc2.png";
import cyc3 from "../../../../assets/creatingYourColorsAssets/cyc3.png";

export interface CreateYourColorInterface {
  slideColor: string;
  title: string;
  subtitle: string;
  image: string;
  altText: string;
  caption: string;
  cutoff: number;
  contentHeight: string;
}

export const createYourColorSlideData: CreateYourColorInterface[] = [
  {
    slideColor: "#F172CB",
    title: "Meet Huey!",
    subtitle: "Our super cute, super precise color matching robot.",
    image: cyc1,
    altText: "color matching device",
    caption: `Huey's ground-breaking color-matching tech means we can always guarantee an exact color match or your money back. `,
    cutoff: 6,
    contentHeight: "80px",
  },
  {
    slideColor: "#5B8CFF",
    title: "Made for you",
    subtitle: "Every bottle is made to order for fresh polish every time.",
    image: cyc2,
    altText: "polish bottle",
    caption: `We really believe that made-to-order is the future of sustainable beauty. 
    It cuts down on waste and ensures you get a product that hasn't been sitting 
    in a dusty old warehouse for months on end. `,
    cutoff: 10,
    contentHeight: "120px",
  },
  {
    slideColor: "#E3BD69",
    title: "Your color is calling",
    subtitle: "Any color. Any shade. Millions of options and counting!",
    image: cyc3,
    altText: "holding hands with painted fingernails",
    caption: `When we say you can create any color, we really mean any color. And that's just the beginning!
              Stay tuned for a variety of products and other secret launches.`,
    cutoff: 12,
    contentHeight: "100px",
  },
];
