import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  fetchAllProductsFirstPage,
  fetchAllProductsSecondPage,
  fetchCollectionPageCollections,
  fetchOpenEndorseCollection,
  getCollectionProductsCount,
} from "./collection-actions";
import {
  CollectionInterface,
  initialCollectionState,
} from "../interfaces/collection-interfaces";
import { SortState } from "../interfaces/sort-state";

type ResponseState = "pending" | "fulfilled" | "rejected";
interface CollectionState {
  shopAllCollection: CollectionInterface;
  ShopAllCollectionCount: number;
  collections: CollectionInterface[];
  openEndorseCollection: CollectionInterface;
  sortState: SortState;
  shopAllProductsStatus: ResponseState | undefined;
  collectionsStatus: ResponseState | undefined;
  openEndorseStatus: ResponseState | undefined;
}

const initialSortState: SortState = {
  sortKey: "Trending",
  reverse: false,
  minPrice: 0,
  maxPrice: 49.0,
  available: true,
};
const initialOpenEndorseState: CollectionInterface = {
  collectionId: "",
  collectionName: "",
  collectionDescription: "",
  collectionImage: "",
  collectionProducts: [],
  hasNextPage: false,
  cursor: "",
};

const initialState: CollectionState = {
  shopAllCollection: initialCollectionState,
  ShopAllCollectionCount: 0,
  collections: [],
  openEndorseCollection: initialOpenEndorseState,
  sortState: initialSortState,
  shopAllProductsStatus: undefined,
  collectionsStatus: undefined,
  openEndorseStatus: undefined,
};

const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setSortingState(state, action: PayloadAction<SortState>) {
      state.sortState = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllProductsFirstPage.pending, (state) => {
        state.shopAllProductsStatus = "pending";
      })
      .addCase(
        fetchAllProductsFirstPage.fulfilled,
        (state, action: PayloadAction<CollectionInterface>) => {
          state.shopAllProductsStatus = "fulfilled";
          state.shopAllCollection = action.payload;
        }
      )
      .addCase(fetchAllProductsFirstPage.rejected, (state, action) => {
        state.shopAllProductsStatus = "rejected";
      })
      .addCase(fetchAllProductsSecondPage.pending, (state) => {
        state.shopAllProductsStatus = "pending";
      })
      .addCase(
        fetchAllProductsSecondPage.fulfilled,
        (state, action: PayloadAction<CollectionInterface>) => {
          state.shopAllProductsStatus = "fulfilled";
          state.shopAllCollection.hasNextPage = action.payload.hasNextPage;
          state.shopAllCollection.cursor = action.payload.cursor;
          state.shopAllCollection.collectionProducts.push(
            ...action.payload.collectionProducts
          );
        }
      )
      .addCase(fetchAllProductsSecondPage.rejected, (state, action) => {
        state.shopAllProductsStatus = "rejected";
      })
      .addCase(fetchCollectionPageCollections.pending, (state) => {
        state.collectionsStatus = "pending";
      })
      .addCase(
        fetchCollectionPageCollections.fulfilled,
        (state, action: PayloadAction<CollectionInterface[]>) => {
          state.collectionsStatus = "fulfilled";
          state.collections = action.payload;
        }
      )
      .addCase(fetchCollectionPageCollections.rejected, (state, action) => {
        state.collectionsStatus = "rejected";
      })
      .addCase(
        getCollectionProductsCount.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.shopAllProductsStatus = "fulfilled";
          state.ShopAllCollectionCount = action.payload;
        }
      )
      .addCase(fetchOpenEndorseCollection.pending, (state) => {
        state.openEndorseStatus = "pending";
      })
      .addCase(fetchOpenEndorseCollection.fulfilled, (state, action) => {
        state.openEndorseStatus = "fulfilled";
        state.openEndorseCollection = action.payload;
      })
      .addCase(fetchOpenEndorseCollection.rejected, (state) => {
        state.openEndorseStatus = "rejected";
      });
  },
});

export const selectSortState = (state: RootState) =>
  state.collections.sortState;
export const selectProductCount = (state: RootState) =>
  state.collections.ShopAllCollectionCount;

export const { setSortingState } = collectionSlice.actions;

export default collectionSlice.reducer;
