import React from "react";
import Box from "@mui/material/Box";
import HeroEndorserInfo from "./hero-endorser-info";
import ProgressiveImage from "../../../components/progressive-image";

interface Props {
  mobileImg: string;
  text: string;
  title: string;
  bgColor: string;
}
const MobileHero = ({ title, text, bgColor, mobileImg }: Props) => (
  <Box display={{ xs: "flex", sm: "none" }} flexDirection="column">
    <ProgressiveImage
      src={mobileImg}
      placeholderSrc="https://via.placeholder.com/400x400?text="
      alt={title}
      screen="mobile"
      maxHeight="400px"
    />
    <HeroEndorserInfo title={title} text={text} bgColor={bgColor} />
  </Box>
);
export default MobileHero;
