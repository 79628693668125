import React from "react";
import bottleRenderWebp from "../../assets/blankBottleRender.webp";
import bottleRenderPng from "../../assets/blankBottleRender.png";
import ImgWithPngFallback from "../img-with-png-fallback";

interface IcoBottleProps {
  imgHeight?: string;
  imgSrc?: string
  imgSrcFallback?: string
}

const IcoBottle = ({ imgHeight, imgSrc, imgSrcFallback }: IcoBottleProps) => {


  return (
    <ImgWithPngFallback
      src={!!imgSrc ? imgSrc : bottleRenderWebp}
      fallback={!!imgSrcFallback ? imgSrcFallback : bottleRenderPng}
      position="relative"
      alt="blank polish bottle"
      height={imgHeight != null ? imgHeight : "370px"}
      sx={{
        zIndex: 3,
      }}
    />
  );
};
export default IcoBottle;
