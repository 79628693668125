import React from "react";
import Box from "@mui/material/Box";
import MakeItYoursTitle from "./components/make-it-your-title";
import MakeItYoursDataDesktop from "./components/make-it-yours-data/make-it-yours-desktop";
import MakeItYoursDataMobile from "./components/make-it-yours-data/make-it-yours-mobile";

const MakeItYourOwn = () => (
  <Box py={{ xs: 8, sm: 10 }} width="100%" sx={{ backgroundColor: "white" }}>
    <Box
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent={{ xs: "center", md: "center" }}
      alignItems={{ xs: "center" }}
    >
      <MakeItYoursTitle />
      <MakeItYoursDataDesktop />
      <MakeItYoursDataMobile />
    </Box>
  </Box>
);
export default MakeItYourOwn;
