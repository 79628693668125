import React, { useEffect } from "react";
import ConfirmButton from "./confirm-button.component";
import "../color-picker.styles.css";
import Box from "@mui/material/Box";

import { useKioskHook } from "../../../utils/hooks/useKioskHook";
interface ColorPickerProps {
  displayColor?: string;
  isDisplayed: boolean;
  colorId?: string
}


const ColorPickerBar = ({ displayColor, isDisplayed, colorId }: ColorPickerProps) => {


  const isKiosk = useKioskHook()
  useEffect(() => {


  }, [displayColor, isDisplayed]);
  return (

    <Box marginBottom={isKiosk ? {xs:'4rem', lg:'auto'} : undefined} className={!isKiosk ? 'confirm-container' : 'kiosk-confirm-container'}>

      {
        !isKiosk && <Box border={'2px solid black'} height={'70px'} width={'70px'} borderRadius={'50%'} sx={{ backgroundColor: displayColor }}>

        </Box>
      }



      <ConfirmButton isDisplayed={isDisplayed} color={displayColor} colorId={colorId} />
    </Box>



  )
};

export default ColorPickerBar;
