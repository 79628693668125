import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Twitter from "@mui/icons-material/Twitter";
import { LinkedIn } from "@mui/icons-material";

const InquiriesFormText = () => {
  const text = `We are a small business and we're open to a range 
                of collaborations. Just give us a shout here 
                or on Twitter & Linkedin.`;

  const navigateToSocial = (social: string) => {
    if (social === "twitter") {
      window.open("https://mobile.twitter.com/_blankbeauty");
    } else {
      window.open("https://www.linkedin.com/company/blankbeauty");
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      height="100%"
      alignItems={{ xs: "center", md: "flex-start" }}
      justifyContent="center"
      maxWidth={{ xs: "330px", md: "445px" }}
      mb={{ xs: 5, md: 0 }}
      mr={{ md: 4 }}
    >
      <Box>
        <Typography
          variant="h6"
          fontSize={{ xs: "35px", md: "48px" }}
          lineHeight={{ xs: "38px", md: "58px" }}
        >
          Ready to order?
        </Typography>
      </Box>
      <Box my={1.5}>
        <Typography
          fontFamily="ABC Favorit Light"
          fontSize="18px"
          lineHeight="25px"
        >
          {text}
        </Typography>
      </Box>
      <Box display="flex">
        <Box
          sx={{
            cursor: "pointer",
            "&:hover": {
              "& .MuiSvgIcon-root": {
                color: "#1B93E3",
              },
            },
          }}
          onClick={() => navigateToSocial("twitter")}
          mr={4}
        >
          <Twitter fontSize="large" />
        </Box>
        <Box
          sx={{
            cursor: "pointer",
            "&:hover": {
              "& .MuiSvgIcon-root": {
                color: "#0B5EBA",
              },
            },
          }}
          onClick={() => navigateToSocial("linkedIn")}
        >
          <LinkedIn fontSize="large" />
        </Box>
      </Box>
    </Box>
  );
};
export default InquiriesFormText;
