import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

interface Props {
  isMobile: boolean;
}

const CollectionsPageSkeletonGridCard = ({ isMobile }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minHeight={{ sm: "367px", md: "397px" }}
  >
    <Skeleton
      animation="wave"
      variant="rectangular"
      height={isMobile ? "210px" : "230px"}
      width={isMobile ? "290px" : "430px"}
      sx={{ borderRadius: "15px" }}
    />
    <Skeleton animation="wave" sx={{ my: 2 }} variant="text" width="135px" />
    <Skeleton animation="wave" variant="text" width="75px" />
  </Box>
);

export default CollectionsPageSkeletonGridCard;
