import { NeedToKnowInterface } from "./need-to-know.interface";

export const needToKnowData: NeedToKnowInterface[] = [
  {
    title: "Costs/Profits/ ROI",
    info: "",
    dropdownColor: "white",
  },
  {
    title: "Turnaround time",
    info: "",
    dropdownColor: "white",
  },
  {
    title: "Minimal orders",
    info: "",
    dropdownColor: "white",
  },
  {
    title: "Get to know more about our polish",
    info: "",
    dropdownColor: "#C6EA00",
  },
];
