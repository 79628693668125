import React from "react";
import { Box, Typography } from "@mui/material";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import { useAppSelector } from "../../utils/hooks/redux-hooks";
import { selectSortState } from "./state/collection-slice";
import ProtectAndPerfect from "./components/protect-and-perfect";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import ShopAllTitle from "./components/shop-all-title";
import ShopAllGrid from "./components/shop-all-grid";
import ShowMoreProducts from "./components/show-more-products";
import ShopAllFilterBar from "./components/filter/shop-all-filter-bar";
import BlankFooter from "../../components/blank-footer/blank-footer";
import { useGetShopAllProducts } from "./hooks/useGetShopAllProducts";
import SEO from "../../components/seo";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";
//import CustomerReviews from "../reviews/components/reviews";

interface Props {
  isWalmart?: boolean;
}
const ShopAllPage = ({ isWalmart = false }: Props) => {
  const sortState = useAppSelector(selectSortState);
  const { fetchSecondPage, shopAllData, isLoadingShopAll } =
    useGetShopAllProducts(sortState);
  usePixelServiceOnLoad();

  return (
    <Box width="100%" bgcolor="secondary.main">
      <SEO
        title="Shop our range of nail polish colors | Blank Beauty"
        urlEndpoint="shop-all"
        description="Long-lasting, chip-resistant nail polish colors by Blank Beauty. 13-free and full coverage that lasts. Want something unique? Create your own nail polish."
        type="website"
      />
      <BlankAppbar isWalmartComponent={isWalmart} />
      <ScrollToTopOnMount />
      <Box display="none" visibility="hidden">
        <Typography component="h1">
          {" "}
          Shop Nail Polish Colors by Blank
        </Typography>
        <Typography component="h2">
          Blank's own range of nail colors to suit your style
        </Typography>
      </Box>
      <ShopAllTitle title="Explore what's trending" topMargin={8} />
      <ShopAllFilterBar />
      <ShopAllGrid
        allProductsCollection={shopAllData}
        isLoading={isLoadingShopAll}
      />
      <ShowMoreProducts
        collectionLength={shopAllData.collectionProducts.length}
        hasNextPage={shopAllData.hasNextPage}
        fetchNextPage={fetchSecondPage}
      />
      {/* <CustomerReviews /> */}
      <Box mt={10} mb={5}>
        <ProtectAndPerfect landing={false} />
      </Box>
      <BlankFooter />
    </Box>
  );
};
export default ShopAllPage;
