import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CollectionInterface } from "../../shop-all/interfaces/collection-interfaces";
import { useNavigate } from "react-router-dom";
import { COLLECTIONS, WALMART } from "../../../routing/CONSTANTS";
import CollectionStack from "./collection-arrangments/arrangment-arrays";
import { useAppSelector } from "../../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../walmart/state/walmart-slice";

interface Props {
  collection: CollectionInterface;
}

const Collection = ({ collection }: Props) => {
  const navigate = useNavigate();
  const isWalmart = useAppSelector(selectIsWalmartPath);

  const navigateToCollectionProductPage = () => {
    if (isWalmart) {
      navigate(`${WALMART}${COLLECTIONS}/${collection.collectionHandle}`);
    } else {
      navigate(`${COLLECTIONS}/${collection.collectionHandle}`);
    }
  };
  return (
    <Box
      onClick={navigateToCollectionProductPage}
      width="100%"
      minHeight={{ sm: "367px", md: "397px" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="end"
      mb={{ xs: 1, sm: 4, md: 0 }}
      sx={{ cursor: "pointer" }}
    >
      <CollectionStack collection={collection} />
      <Box textAlign="center">
        <Typography
          fontFamily="ABC Favorit Book"
          fontSize="24px"
          fontWeight="500"
          sx={{ textDecoration: "underline" }}
        >
          {collection.collectionName}
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="h6" fontSize={{ xs: "16px", md: "18px" }}>
          {collection.collectionProducts.length} shades
        </Typography>
      </Box>
    </Box>
  );
};
export default Collection;
