import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BlankLabsBtn from "../../go-to-blank-labs-btn";
import GradientInstagramIcon from "./grandient-instagram-icon";

const GetInTouchBottomBar = () => {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <Box
      width="100%"
      maxWidth={{ sm: "800px", md: "1150px" }}
      pl={{ sm: 2, md: 0 }}
      mt={8}
      display="flex"
      justifyContent={{ xs: "center", sm: "flex-start" }}
    >
      <Box width="248px" mr={{ sm: 5 }}>
        <BlankLabsBtn
          btnColor="black"
          textColor="white"
          btnText="get in touch"
        />
      </Box>
      <Box
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        display={{ xs: "none", sm: "flex" }}
        alignItems="center"
        mb={0.75}
        sx={{
          cursor: "pointer",
        }}
      >
        <GradientInstagramIcon hover={hover} />
        <Box ml={1}>
          <Typography
            fontFamily="ABC Favorit Book"
            fontStyle="italic"
            fontSize="16px"
            lineHeight="21px"
          >
            Visit our profile to find out more
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default GetInTouchBottomBar;
