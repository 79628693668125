export const CREATE_CART_MUTATION = `
mutation CreateCart($cartInput: CartInput){
    cartCreate(input: $cartInput){
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                    amount
                    currencyCode
                  }
                  product {
                    title 
                    description
                  }		
                }
              }
              quantity
              estimatedCost{
                subtotalAmount{
                amount
                }
              }
              attributes{
                key 
                value
              }
            }
          }
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;
