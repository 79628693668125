export const ADD_CART_ITEM_MUTATION = `
mutation cartLineAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      id
      lines(first: 20) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id 
                price {
                  amount
                  currencyCode
                }
                product {
                  title 
                  description
                }		
              }
             
            }
            quantity
            estimatedCost{
              subtotalAmount{
                amount
              }
            }
            attributes{
              key
              value
            }
          }
        }
      }
       estimatedCost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
    }
    }
}
`;
