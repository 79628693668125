import React from "react";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  COLLECTIONS,
  CREATE_YOUR_COLOR,
  SHOP_ALL,
  WALMART,
} from "../../../routing/CONSTANTS";
import walogo from "../../../assets/walmartlogo.png";

interface NavigationLinksProps {
  closeDrawer: () => void;
  hideLinks?: boolean,
  isWalmart: boolean;
}
const NavigationLinks = ({ closeDrawer, isWalmart, hideLinks }: NavigationLinksProps) => {

  return (
    <Box
      component="nav"
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={{ xs: "start", md: "end" }}
    >
      {isWalmart && (
        <>
          <a
            rel="noopener noreferrer"
            href="https://www.walmart.com/cp/beauty/1085666?povid=GlobalNav_rWeb_Beauty_Beauty_ShopAll_051123"
            target="_blank"
            style={{
              textDecoration: "none",
              lineHeight: 0,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{
                backgroundColor: "transparent",
                color: "white",
                borderColor: "white",
                alignItems: "center",
                fontWeight: "bold",
                margin: 0,
                justifyContent: "center",
                paddingRight: "15px",
              }}
            // startIcon={
            //   <img
            //     src={walogo}
            //     alt="walmart logo"
            //     style={{
            //       height: "100%",
            //       maxHeight: "30px",
            //       margin: "0",
            //       padding: 0,
            //     }}
            //   />
            // }
            >
              <img
                src={walogo}
                alt="walmart logo"
                style={{
                  height: "100%",
                  maxHeight: "25px",
                  margin: "0",
                  padding: 0,
                }}
              />
              Back to Walmart.com
            </Button>
          </a>
        </>
      )}
      {!isWalmart && !hideLinks && (
        <>
          <Box
            mr={{ xs: 0, md: "45px" }}
            mb={{ xs: "15px", md: 0 }}
            onClick={() => {
              closeDrawer();
            }}
          >
            <Link
              style={{
                fontFamily: "ABC Favorit Book",
                fontSize: "20px",
                color: "black",
                textDecoration: "none",
                lineHeight: 0,
              }}
              to={isWalmart ? `${WALMART}${SHOP_ALL}` : SHOP_ALL}
            >
              shop all
            </Link>
          </Box>
          <Box
            mr={{ xs: 0, md: "45px" }}
            mb={{ xs: "15px", md: 0 }}
            onClick={() => {
              closeDrawer();
            }}
          >
            <Link
              style={{
                fontFamily: "ABC Favorit Book",
                fontSize: "20px",
                color: "black",
                textDecoration: "none",
                lineHeight: 0,
              }}
              to={isWalmart ? `${WALMART}${COLLECTIONS}` : COLLECTIONS}
            >
              collections
            </Link>
          </Box>
          <Box
            mr={{ xs: 0, md: "45px" }}
            onClick={() => {
              closeDrawer();
            }}
          >
            <Link
              style={{
                fontFamily: "ABC Favorit Book",
                fontSize: "20px",
                color: "black",
                textDecoration: "none",
                lineHeight: 0,
              }}
              to={
                isWalmart ? `${WALMART}${CREATE_YOUR_COLOR}` : CREATE_YOUR_COLOR
              }
            >
              create a color+
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NavigationLinks;
