import React from "react";
import Box from "@mui/material/Box";
import MobileHero from "./hero-mobile";
import DeskTopHero from "./desktop-hero";

interface Props {
  title: string;
  quote: string;
  backgroundColor: string;
  images: string[];
}
const OpenEndorseHero = ({ title, images, quote, backgroundColor }: Props) => {
  return (
    <Box width="100%" height="100%" maxHeight="975px" position="relative">
      <DeskTopHero
        desktopImg={images[0]}
        title={title}
        text={quote}
        bgColor={backgroundColor}
      />

      <MobileHero
        mobileImg={images[1]}
        title={title}
        text={quote}
        bgColor={backgroundColor}
      />
    </Box>
  );
};
export default OpenEndorseHero;
