import React from "react";
import Box from "@mui/material/Box";
import { CollectionInterface } from "../../../shop-all/interfaces/collection-interfaces";
import ElementContainer from "./element-container";

type Props = {
  collection: CollectionInterface;
};

const FiveItemColTwo = ({ collection }: Props) => {
  const XSOne = 25;
  const SMOne = 38;
  const xsSize = 110;
  const smSize = 120;
  return (
    <Box
      mb={3}
      width={{ xs: "380px", sm: "100%" }}
      height={{ xs: "250px", sm: "315px" }}
      position="relative"
      data-testid="five-item-two"
    >
      <ElementContainer
        leftXs={XSOne}
        leftSm={SMOne}
        rotation="25deg"
        product={collection.collectionProducts[0]}
      />
      <ElementContainer
        leftXs={XSOne + xsSize}
        leftSm={SMOne + smSize}
        rotation="-8deg"
        product={collection.collectionProducts[1]}
      />
      <ElementContainer
        leftXs={XSOne + (xsSize * 2 + 10)}
        leftSm={SMOne + (smSize * 2 + 15)}
        product={collection.collectionProducts[2]}
      />
      <ElementContainer
        leftXs={XSOne}
        leftSm={SMOne}
        bottomXs={xsSize}
        bottomSm={smSize}
        rotation="10deg"
        product={collection.collectionProducts[3]}
      />
      <ElementContainer
        leftXs={XSOne + xsSize + 60}
        leftSm={SMOne + smSize + 70}
        bottomXs={xsSize - 15}
        bottomSm={smSize - 20}
        product={collection.collectionProducts[4]}
      />
    </Box>
  );
};

export default FiveItemColTwo;
