import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { formatPhoneNumber } from "../../../../../../utils/phone-number-formatter";
import ContactService from "../../../../../about/services/contact-service";

interface ErrorState {
  email: string;
  phone: string;
  name: string;
  file: string;
}

export interface InquiriesState {
  email: string;
  phoneNumber: string;
  name: string;
  companyName: string;
  uploadedFile: File | undefined;
  message: string;
}

const initialErrorState: ErrorState = {
  email: "",
  phone: "",
  name: "",
  file: "",
};

const initialInquiryState: InquiriesState = {
  email: "",
  phoneNumber: "",
  name: "",
  companyName: "",
  uploadedFile: undefined,
  message: "",
};

export const useInquiryFormControls = () => {
  const [state, setState] = useState<InquiriesState>(initialInquiryState);

  const [errors, setErrors] = useState<ErrorState>(initialErrorState);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onFileSelectError = (fileError: string) => {
    setErrors({
      ...errors,
      file: fileError,
    });
  };
  const onFileSelectSuccess = (file: File) => {
    setState({
      ...state,
      uploadedFile: file,
    });
  };

  const clearFile = () => {
    setState({
      ...state,
      uploadedFile: undefined,
    });
  };

  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (file.size > 1e6) {
        onFileSelectError("File size cannot exceed more than 1MB");
      } else {
        onFileSelectError("");
        onFileSelectSuccess(file);
      }
    }
  };

  const clearForm = () => {
    setState(initialInquiryState);
  };

  const isEmail = (email: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const validateEmail = () => {
    let error = "";
    if (!isEmail(state.email)) {
      error = "Invalid Email";
    }
    if (state.email === "Enter your email address here") {
      error = "This field is required";
    }
    setErrors({
      ...errors,
      email: error,
    });
  };

  const handleInputAction = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };

  const handleEmailOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      email: "",
    });
    setState({
      ...state,
      email: e.target.value,
    });
  };

  const handlePhoneOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setErrors({
      ...errors,
      phone: "",
    });
    setState({
      ...state,
      phoneNumber: formattedNumber,
    });
  };

  const handleNameOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      name: "",
    });
    setState({
      ...state,
      name: e.target.value,
    });
  };

  const handleCompanyNameOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      companyName: e.target.value,
    });
  };
  const handleMessageOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      message: e.target.value,
    });
  };

  const validatePhoneNumber = () => {
    let error = "";
    if (state.phoneNumber.length > 0 && state.phoneNumber.length < 13) {
      error = "Invalid number";
    }
    setErrors({
      ...errors,
      phone: error,
    });
  };

  const validateName = () => {
    let error = "";
    if (state.name === "Enter name here") {
      errors.name = "This field is required";
      error = "This field is required";
    }
    setErrors({
      ...errors,
      name: error,
    });
  };

  const validateForm = (): boolean => {
    validateEmail();
    validateName();
    validatePhoneNumber();

    const isValid = Object.values(errors).every((x) => x === "");
    return isValid;
  };

  const submitForm = async (
    e: FormEvent,
    setIsOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>,
    setIsMessageSuccess: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      try {
        await ContactService.sendInquiryFormEmail(state);
        setIsOpenSnackbar(true);
        setIsMessageSuccess(true);
        clearForm();
      } catch (e) {
        setIsOpenSnackbar(true);
        setIsMessageSuccess(false);
        Sentry.captureException(e);
      }
    }
  };

  return {
    state,
    errors,
    handleEmailOnChange,
    handleNameOnChange,
    handlePhoneOnChange,
    handleCompanyNameOnChange,
    handleMessageOnChange,
    clearFile,
    hiddenFileInput,
    handleInputAction,
    handleFileInput,
    validateForm,
    submitForm,
  };
};
