import React, { ChangeEvent, FormEvent, useState } from "react";
import Box from "@mui/material/Box";
import AboutUsSubmitButton from "../../../../../about/components/about-components/form/about-us-submit-button";
import UploadButton from "../../../../../about/components/about-components/form/upload-button";
import ContactSnackBar from "../../../../../about/components/contact-snackbar.component";
import { useInquiryFormControls } from "../hooks/useInquiryFormControls";
import InquiriesTextField from "./inquiries-text-field";
import InquiriesUploadedFileComponent from "./inquiries-uploaded-file";

const InquiriesInput = () => {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);
  const [isMessageSuccess, setIsMessageSuccess] = useState<boolean>(false);
  const {
    state,
    errors,
    handleEmailOnChange,
    handleNameOnChange,
    handlePhoneOnChange,
    handleCompanyNameOnChange,
    handleMessageOnChange,
    clearFile,
    handleInputAction,
    handleFileInput,
    hiddenFileInput,
    validateForm,
    submitForm,
  } = useInquiryFormControls();

  const submit = async (e: FormEvent) => {
    await submitForm(e, setIsOpenSnackbar, setIsMessageSuccess);
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    handleFileInput(e);
  };

  return (
    <Box
      component="form"
      onSubmit={submit}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "335px", sm: "535px", md: "435px" },
        ml: { xs: 0, md: 4 },
      }}
    >
      <InquiriesTextField
        required
        label="Full name"
        name="name"
        placeholder="Enter name here"
        value={state.name}
        handleChange={handleNameOnChange}
        error={errors.name.length > 0}
        helperText={errors.name}
        multiline={false}
      />
      <InquiriesTextField
        required={false}
        label="Company Name"
        name="company name"
        placeholder="Enter company name here"
        value={state.companyName}
        handleChange={handleCompanyNameOnChange}
        error={false}
        helperText={""}
        multiline={false}
      />
      <InquiriesTextField
        required
        label="Email address"
        name="email"
        placeholder="Enter your email address here"
        value={state.email}
        handleChange={handleEmailOnChange}
        error={errors.email.length > 0}
        helperText={errors.email}
        multiline={false}
      />
      <InquiriesTextField
        required={false}
        label="Phone"
        name="phoneNumber"
        placeholder="Enter phone number here"
        value={state.phoneNumber}
        handleChange={handlePhoneOnChange}
        error={errors.phone.length > 0}
        helperText={errors.phone}
        multiline={false}
      />
      <InquiriesTextField
        required={false}
        label="Message"
        name="message"
        placeholder="Enter message here"
        value={state.message}
        handleChange={handleMessageOnChange}
        error={false}
        helperText={""}
        multiline
      />

      <Box display="flex" flexDirection="column">
        {state.uploadedFile && (
          <InquiriesUploadedFileComponent
            clearFile={clearFile}
            file={state.uploadedFile}
          />
        )}
      </Box>
      <UploadButton
        hiddenFileInput={hiddenFileInput}
        handleInputAction={handleInputAction}
        uploadFile={uploadFile}
        error={errors.file}
      />
      <AboutUsSubmitButton validateForm={validateForm} />
      <ContactSnackBar
        isMessageSuccess={isMessageSuccess}
        isOpenSnackbar={isOpenSnackbar}
        setIsOpenSnackbar={setIsOpenSnackbar}
      />
    </Box>
  );
};
export default InquiriesInput;
