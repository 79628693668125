import { hotjar } from "react-hotjar";
import { ProductInterface } from "../../pages/cart/interfaces/product.interface";

const ADD_TO_CART = "AddToCart";
const GA_ADD_TO_CART = "add_to_cart";
const GTM_INITIATE_CHECKOUT = "checkout_hit";
const COMPLETE_REGISTRATION = "CompleteRegistration";
const CONTACT = "Contact";
const INITIATE_CHECKOUT = "InitiateCheckout";

type WindowWithDataLayer = Window & {
  gtag: Function;
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

const gtmTracker = (event: any) => {
  window.dataLayer.push(event);
};

type Platform = "facebook" | "google";
const getClientId = (platform: Platform): string => {
  if (platform === "google") {
    let cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)_ga\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    return cookieValue.slice(6);
  } else {
    let cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)_fbp\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    return cookieValue;
  }
};

const fireHotjarEvent = (event: string) => {
  if (hotjar.initialized()) {
    hotjar.event(event);
  }
};
const pixelsInit = () => {
  if (process.env.NODE_ENV === "production") {
    hotjar.initialize(3189123, 6);
  }
};

const pixelPageView = (locationPath: string) => {
  if (process.env.NODE_ENV === "production") {
    if (hotjar.initialized()) {
      hotjar.stateChange(locationPath);
    }
  }
};

const pixelAddToCart = (
  key: string,
  price: number,
  value: string,
  variantId: string,
  category: string = "shop-all",
  subtotal: number
) => {
  const objectDataGTM = {
    event: GA_ADD_TO_CART,
    eventModel: {
      productTitle: value,
      productItemId: key,
      productVariantId: variantId,
      productCategories: category,
      productPrice: price,
      subTotal: `${subtotal + 9}`,
    },
  };

  if (process.env.NODE_ENV === "production") {
    fireHotjarEvent(ADD_TO_CART);
    gtmTracker(objectDataGTM);
  }
};

const pixelCompleteRegistration = (registrationName: string) => {
  if (process.env.NODE_ENV === "production") {
    fireHotjarEvent(COMPLETE_REGISTRATION);
  }
};

const pixelContact = () => {
  if (process.env.NODE_ENV === "production") {
    fireHotjarEvent(CONTACT);
  }
};

const getProductIdsForCheckout = (products: ProductInterface[]): string[] => {
  const gtmProductIds: string[] = [];

  products.forEach(async (prod) => gtmProductIds.push(prod.productId));

  return gtmProductIds;
};

const pixelInitiateCheckout = async (
  products: ProductInterface[],
  isWalmart: boolean
) => {
  const totalPrice = products.reduce(
    (curr, next) =>
      parseFloat(next.price.toString()) + parseFloat(curr.toString()),
    0
  );
  const gtmProductIds: string[] = getProductIdsForCheckout(products);
  const gtmItems = products.map((prod) => {
    const items = [];
    items.push(prod.key);
    return items;
  });
  const objectGTM = {
    event: GTM_INITIATE_CHECKOUT,
    eventModel: {
      subTotal: `${totalPrice}`,
      cartCount: products.reduce((curr, next) => next.quantity + curr, 0),
      productIds: gtmProductIds,
      items: gtmItems,
    },
    isWalmartPurchase: isWalmart,
  };

  if (process.env.NODE_ENV === "production") {
    fireHotjarEvent(INITIATE_CHECKOUT);
    gtmTracker(objectGTM);
  }
};

const pixelViewContent = (key: string, price: number) => {
  if (process.env.NODE_ENV === "production") {
    fireHotjarEvent(key);
  }
};

const pixelModalView = (modal: string) => {
  if (process.env.NODE_ENV === "production") {
    fireHotjarEvent(modal);
  }
};

const PixelService = {
  pixelsInit,
  pixelPageView,
  pixelAddToCart,
  pixelCompleteRegistration,
  pixelContact,
  pixelInitiateCheckout,
  pixelViewContent,
  fireHotjarEvent,
  pixelModalView,
  getClientId,
};

export default PixelService;
