import React, { useState } from "react";
import Box from "@mui/material/Box";
import CreateYourColorSlide from "./components/create-your-color-slide";
import CreateYourColorsTitle from "./components/creating-your-colors-title";
import { createYourColorSlideData } from "./CONSTANTS/create-ur-color";

const CreatingYourColors = () => {
  const [activeSlide, setActiveSlide] = useState<string>("");
  const switchSlide = (slideTitle: string) => setActiveSlide(slideTitle);
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#E7E7E7",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pt: 3,
        pb: { xs: 2, md: 0 },
      }}
    >
      <CreateYourColorsTitle />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems={{ xs: "center", md: "flex-start" }}
        width="100%"
        px={{ xs: 2, md: 0 }}
        height={{ md: "585px" }}
      >
        {createYourColorSlideData.map((slide) => (
          <CreateYourColorSlide
            key={slide.title}
            slide={slide}
            activeSlide={activeSlide}
            switchSlide={switchSlide}
          />
        ))}
      </Box>
    </Box>
  );
};
export default CreatingYourColors;
