export const hsv2rgb = (hue: number, sat: number, val: number): number[] => {
  const s = sat / 100;
  const v = val / 100;

  let f = (n: number, k = (n + hue / 60) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return [f(5) * 100, f(3) * 100, f(1) * 100];
};


export const hsvToHex = (hue: number, sat: number, val: number): string => {
  const rgbPercentValues = hsv2rgb(hue, sat, val)


  const rgbValues = rgbPercentValues.map((singleValue) => Math.round((singleValue / 100) * 255))

  
  const r = Math.min(255, Math.max(0, rgbValues[0]))
  const g = Math.min(255, Math.max(0, rgbValues[1]));
  const b = Math.min(255, Math.max(0, rgbValues[2]));

  // Convert each channel to its hexadecimal representation
  const rHex = r.toString(16).padStart(2, '0')
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');



  let hexString = `#${rHex}${gHex}${bHex}`

  if (hexString.includes('.')) {
    const splitString = hexString.split(".")
    hexString = splitString[0]

  }


  return hexString
}

export const rgb2hsv = (
  r: number,
  g: number,
  b: number
): { h: number; s: number; v: number } => {
  let rabs,
    gabs,
    babs,
    rr,
    gg,
    bb,
    h = 0,
    s,
    v: number,
    diff: number,
    diffc,
    percentRoundFn;
  rabs = r / 255;
  gabs = g / 255;
  babs = b / 255;
  v = Math.max(rabs, gabs, babs);
  diff = v - Math.min(rabs, gabs, babs);
  diffc = (c: number) => (v - c) / 6 / diff + 1 / 2;
  percentRoundFn = (num: number) => Math.round(num * 100) / 100;
  if (diff === 0) {
    h = s = 0;
  } else {
    s = diff / v;
    rr = diffc(rabs);
    gg = diffc(gabs);
    bb = diffc(babs);

    if (rabs === v) {
      h = bb - gg;
    } else if (gabs === v) {
      h = 1 / 3 + rr - bb;
    } else if (babs === v) {
      h = 2 / 3 + gg - rr;
    }
    if (h < 0) {
      h += 1;
    } else if (h > 1) {
      h -= 1;
    }
  }
  return {
    h: Math.round(h * 360),
    s: percentRoundFn(s * 100),
    v: percentRoundFn(v * 100),
  };
};
