import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { HowItWorksInterface } from "../../../landing/how-it-works/CONSTANTS/info-graphics";
import SlideRoundlet from "../../../landing/how-it-works/components/slide-roundlet";
import HowItWorksVideoGraphic from "../../../../components/scrolling-videos/how-it-works-video-graphic";
import ImgWithPngFallback from "../../../../components/img-with-png-fallback";
import { useAppSelector } from "../../../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../../walmart/state/walmart-slice";

interface Props {
  graphic: HowItWorksInterface;
  idx: number;
  activeIdx: number;
  setActiveIdx: (value: React.SetStateAction<number>) => void;
}
const HIWCreateGraphic = ({ graphic, idx, activeIdx, setActiveIdx }: Props) => {
  const isWalmart = useAppSelector(selectIsWalmartPath);
  return (
    <Box
      width={{ xs: "250px", sm: "320px", md: "315px" }}
      mr={3}
      sx={{
        transform: {
          xs: "translateX(140px)",
          sm: "translateX(0)",
          md: "none",
        },
        "&:last-child": { mr: 0 },
      }}
      display="flex"
      flexDirection="column"
    >
      <Box mb={{ xs: 0.75, sm: 1.5 }}>
        <Box
          width="100%"
          position="relative"
          maxHeight={{ md: "349px" }}
          borderRadius="10px"
          sx={{ zIndex: 6, overflow: "hidden" }}
        >
          <HowItWorksVideoGraphic
            idx={idx}
            id={graphic.id}
            video={graphic.video}
            poster={graphic.poster}
            isVisible
            minHeight={{ xs: "315.18px", sm: "420px", md: "350.45px" }}
            activeIdx={activeIdx}
            setActiveIdx={setActiveIdx}
          />
          <Box
            width="100%"
            height={{ xs: "315.18px", sm: "420px", md: "350.45px" }}
            position="absolute"
            top="0"
            sx={{ zIndex: 5 }}
            overflow="hidden"
            borderRadius="10px"
          >
            <ImgWithPngFallback
              src={graphic.poster}
              fallback={graphic.fallbackPoster}
              height="100%"
              width="100%"
              borderRadius="10px"
              alt={graphic.altText}

            />
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Box mr={2}>
          <SlideRoundlet
            bg={isWalmart ? "walmart.walmartYellow" : "white"}
            slideNumber={graphic.slideNumber}
          />
        </Box>
        <Box>
          <Typography
            textAlign="left"
            fontFamily="ABC Favorit Book"
            fontSize={{ xs: "12.5px", sm: "16px" }}
            lineHeight={{ xs: "16px", sm: "22px" }}
            fontWeight={300}
            sx={{
              maxWidth: { xs: "230px", sm: "350px", md: "237px" },
            }}
          >
            {graphic.directions}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default HIWCreateGraphic;
