import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface Props {
  clearFile: () => void;
  file: File;
}

const InquiriesUploadedFileComponent = ({ clearFile, file }: Props) => {
  const handleRemoveFile = () => {
    clearFile();
  };
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      mt={1}
      mb={1}
    >
      <Box display="flex" width="100%">
        <Box mr={1}>
          <AttachFileIcon htmlColor="black" />
        </Box>
        <Box width="100%">
          <Typography
            sx={{
              maxWidth: "274px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            fontFamily="ABC Favorit Light"
          >
            {file.name}
          </Typography>
        </Box>
      </Box>
      <Box>
        <IconButton onClick={handleRemoveFile}>
          <CancelIcon htmlColor="black" />
        </IconButton>
      </Box>
    </Box>
  );
};
export default InquiriesUploadedFileComponent;
