import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  title: string;
  text: string;
}
const NeedToKnowText = ({ title, text }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box mb={2}>
        <Typography
          fontFamily="Nib Pro"
          fontSize={{ xs: "30px", md: "35px" }}
          lineHeight="29px"
          color="white"
        >
          {title}
        </Typography>
      </Box>
      <Box maxWidth="266px">
        <Typography
          fontFamily="ABC Favorit Light"
          fontStyle="italic"
          fontSize="16px"
          lineHeight="18px"
          color="white"
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
export default NeedToKnowText;
