import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface CardBadgeProps {
  tags: string[];
}
const ShopifyCardBadge = ({ tags }: CardBadgeProps) => {
  const getProductBadge = (): string | false => {
    let badgeTag: string | false = false;
    if (tags.length > 0) {
      tags.forEach((tag) => {
        if (tag.includes("badge")) badgeTag = tag.split(":")[1];
      });
    }

    return badgeTag;
  };
  const tag = getProductBadge();
  return (
    <Box
      display={tag !== false ? "inline-block" : "none"}
      width={{ xs: "75px", sm: "95px", md: "115px" }}
      position="absolute"
      top={{ xs: "-2px", sm: "16px", md: "6px" }}
      right={{ xs: "106px", sm: "60%", md: "55%" }}
      sx={{
        backgroundColor: "#c6c6c6e6",
        p: { xs: "0 15px 0 0", md: "0 25px 0 11px" },
        zIndex: 2,
      }}
      data-testid="badge-container"
    >
      <Typography
        fontFamily="ABC Favorit Book"
        fontSize={{ xs: "11px", sm: "14px", md: "16px" }}
        lineHeight={{ xs: "23px", sm: "32px", md: "34px" }}
        fontStyle="italic"
      >
        {tag !== false ? tag : ""}
      </Typography>
    </Box>
  );
};
export default React.memo(ShopifyCardBadge);
