import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ModalTitle = () => (
  <Box py={{ xs: 3, sm: 4.5 }}>
    <Typography
      variant="h3"
      fontWeight="400"
      fontSize={{ xs: "26px", sm: "40px" }}
      lineHeight="33px"
    >
      Our 100% color-match promise
    </Typography>
  </Box>
);

export default React.memo(ModalTitle);
