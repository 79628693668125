import React from "react";
import { BlankColorInterface } from "../../pages/color_picker/interface/blank-color-interface";
import { CollectionProductInterface } from "../../pages/shop-all/interfaces/collection-interfaces";
import CustomPolishProductCard from "./custom-product-card";
import ShopifyPolishProductCard from "./shopify-product-card";

interface ProductCardContainerProps {
  productType: "shopify" | "custom";
  shopifyProduct?: CollectionProductInterface;
  customProduct?: BlankColorInterface;
  landing?: boolean;
}
const ProductCardContainer = ({
  shopifyProduct,
  customProduct,
  productType,
  landing,
}: ProductCardContainerProps) => (
  <>
    {productType === "shopify" ? (
      <ShopifyPolishProductCard landing={landing!} product={shopifyProduct!} />
    ) : (
      <CustomPolishProductCard color={customProduct!} />
    )}
  </>
);

export default ProductCardContainer;
