import React from "react";

const HeroTextDesktop = () => (
  <svg
    width="34vw"
    height="100%"
    viewBox="0 0 549 197"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1242 103.45C9.54831 103.45 6.46698 102.727 3.88019 101.28C1.2934 99.91 0 98.2353 0 96.2561C0 95.1904 0.380411 94.315 1.14123 93.6299C1.90205 92.9448 2.89112 92.6023 4.10844 92.6023C5.78224 92.6023 7.64626 93.3635 9.70047 94.8859C12.3633 96.6367 15.0643 97.5122 17.8032 97.5122C21.6073 97.5122 25.5636 96.18 29.672 93.5157C33.7805 90.8515 37.6226 87.1215 41.1985 82.3258C44.8504 77.6063 47.9318 72.1636 50.4425 65.9977C47.247 70.2605 43.519 73.5718 39.2584 75.9316C34.9978 78.2152 30.547 79.3571 25.906 79.3571C20.6563 79.3571 16.5479 77.9108 13.5807 75.0181C10.6895 72.0494 9.24398 67.9007 9.24398 62.5722C9.24398 58.3094 10.4613 53.1711 12.8959 47.1575L22.7105 23.2933H11.4123V18.1551H33.2099L20.7704 47.3859C19.1727 51.0397 18.0695 53.9324 17.4609 56.0638C16.8522 58.1952 16.5479 60.2124 16.5479 62.1155C16.5479 70.2605 20.5422 74.333 28.5308 74.333C33.5522 74.333 38.1552 72.8486 42.3397 69.8799C46.6003 66.835 49.9479 62.7244 52.3826 57.5482C54.8933 52.2957 56.1486 46.5485 56.1486 40.3065C56.1486 36.4243 55.578 33.2272 54.4368 30.7152C53.2955 28.127 51.3935 25.8434 48.7306 23.8642C47.6655 23.0269 46.9046 22.3037 46.4481 21.6947C46.0677 21.0858 45.8775 20.3626 45.8775 19.5253C45.8775 17.1655 47.1709 15.9856 49.7577 15.9856C52.0402 15.9856 54.0944 16.9752 55.9204 18.9544C57.7463 20.8574 59.1539 23.5597 60.1429 27.0613C61.2081 30.5629 61.7407 34.5213 61.7407 38.9363C61.7407 48.8322 59.306 58.8042 54.4368 68.8523C49.5675 78.9003 43.3288 87.1596 35.7206 93.6299C28.1884 100.176 20.6563 103.45 13.1242 103.45Z"
      fill="black"
    />
    <path
      d="M113.735 77.9869H82.8335V71.8202H76.6701V65.6536H70.5066V28.5692H76.6701V22.4025H82.8335V16.2358H113.735V22.4025H119.899V28.5692H126.062V65.6536H119.899V71.8202H113.735V77.9869ZM88.9969 65.6536H107.529V59.4869H113.735V34.7358H107.529V28.5692H88.9969V34.7358H82.8335V59.4869H88.9969V65.6536Z"
      fill="black"
    />
    <path
      d="M148.756 79.3571C144.191 79.3571 140.653 77.9488 138.143 75.1323C135.708 72.2397 134.491 68.1672 134.491 62.9147C134.491 59.4131 135.289 55.3787 136.887 50.8114C138.485 46.1679 141.718 38.5557 146.588 27.9748L147.843 25.2344L140.539 18.1551H159.027L153.892 29.1166C149.479 38.708 146.359 46.0918 144.533 51.2681C142.707 56.3683 141.795 60.4408 141.795 63.4857C141.795 66.7589 142.631 69.347 144.305 71.2501C146.055 73.077 148.414 73.9905 151.381 73.9905C157.467 73.9905 163.25 69.8799 168.728 61.6587C174.282 53.3615 179.151 41.6006 183.335 26.3762L183.564 25.1202L176.374 18.1551H192.579L181.281 60.2885C180.596 62.6483 180.254 64.97 180.254 67.2537C180.254 69.3851 180.634 71.0217 181.395 72.1636C182.156 73.3054 183.259 73.8763 184.705 73.8763C185.618 73.8763 186.417 73.6479 187.101 73.1912C187.862 72.7345 188.585 72.0113 189.27 71.0217L190.069 71.821C189.536 73.8002 188.509 75.3607 186.987 76.5025C185.542 77.5682 183.754 78.1011 181.624 78.1011C178.961 78.1011 176.906 77.1876 175.461 75.3607C174.015 73.4576 173.293 70.8314 173.293 67.4821C173.293 65.0462 173.673 62.42 174.434 59.6034C167.358 72.7725 158.799 79.3571 148.756 79.3571Z"
      fill="black"
    />
    <path
      d="M268.378 77.9869H237.477V71.8202H231.313V65.6536H225.15V28.5692H231.313V22.4025H237.477V16.2358H268.378V22.4025H274.542V28.5692H280.705V40.9447H268.378V34.7358H262.173V28.5692H243.64V34.7358H237.477V59.4869H243.64V65.6536H262.173V59.4869H268.378V53.278H280.705V65.6536H274.542V71.8202H268.378V77.9869Z"
      fill="black"
    />
    <path
      d="M299.388 25.6911L291.627 18.1551H308.289L301.214 45.3306C304.789 36.3482 308.632 29.5734 312.74 25.006C316.924 20.3626 321.071 18.0409 325.179 18.0409C326.853 18.0409 328.185 18.4215 329.174 19.1827C330.163 19.9439 330.657 20.9716 330.657 22.2657C330.657 23.5597 330.125 24.5874 329.06 25.3486C327.994 26.1098 326.549 26.4904 324.723 26.4904C313.082 26.4904 303.04 41.7909 294.594 72.3919L293.111 77.9869H285.465L299.388 25.6911Z"
      fill="black"
    />
    <path
      d="M351.085 79.3571C344.086 79.3571 338.608 77.454 334.652 73.6479C330.695 69.8418 328.717 64.5514 328.717 57.7765C328.717 51.0017 330.581 44.3791 334.309 37.9087C338.113 31.4383 342.983 26.1859 348.917 22.1515C354.928 18.0409 361.09 15.9856 367.405 15.9856C372.35 15.9856 376.269 17.2797 379.16 19.8678C382.127 22.456 383.611 25.9576 383.611 30.3726C383.611 37.452 379.692 42.8186 371.856 46.4724C364.019 50.1263 352.493 51.9532 337.276 51.9532C336.972 54.2369 336.82 55.8735 336.82 56.8631C336.82 62.1916 338.342 66.3783 341.385 69.4232C344.504 72.468 348.803 73.9905 354.281 73.9905C362.726 73.9905 369.84 70.4128 375.622 63.2573L376.877 64.0566C371.019 74.2569 362.422 79.3571 351.085 79.3571ZM337.847 48.1852C351.009 48.1852 360.824 46.7008 367.291 43.732C373.758 40.7633 376.991 36.234 376.991 30.1443C376.991 26.9471 376.116 24.5112 374.367 22.8366C372.693 21.1619 370.258 20.3245 367.063 20.3245C363.106 20.3245 359.112 21.5806 355.08 24.0926C351.047 26.6046 347.471 30.0301 344.352 34.369C341.309 38.6319 339.14 43.2372 337.847 48.1852Z"
      fill="black"
    />
    <path
      d="M445.324 73.077C444.716 75.0562 443.575 76.6167 441.901 77.7585C440.303 78.8242 438.363 79.3571 436.08 79.3571C433.113 79.3571 430.869 78.4817 429.347 76.7309C427.902 74.9801 427.179 72.43 427.179 69.0806C427.179 65.7313 427.902 61.849 429.347 57.434C425.543 64.5894 421.282 70.0321 416.565 73.7621C411.924 77.4921 406.979 79.3571 401.729 79.3571C396.708 79.3571 392.828 77.7966 390.089 74.6756C387.426 71.5546 386.094 67.1014 386.094 61.3162C386.094 54.2368 388.073 47.1575 392.029 40.0782C396.061 32.9988 401.197 27.2136 407.436 22.7224C413.674 18.2312 419.913 15.9856 426.152 15.9856C428.967 15.9856 431.439 16.5184 433.57 17.5842C435.7 18.5737 437.64 20.2104 439.39 22.494L447.493 16.0998L435.966 60.2885C435.053 63.1051 434.597 65.8074 434.597 68.3955C434.597 70.4508 435.015 72.0494 435.852 73.1912C436.765 74.2569 438.021 74.7898 439.618 74.7898C440.607 74.7898 441.444 74.5995 442.129 74.2188C442.814 73.7621 443.46 73.077 444.069 72.1636L445.324 73.077ZM394.197 61.4304C394.197 69.8038 397.583 73.9905 404.354 73.9905C410.973 73.9905 417.022 70.1844 422.5 62.5722C428.054 54.8839 432.733 43.8082 436.537 29.345L437.564 25.577C436.651 23.9023 435.472 22.6843 434.026 21.9231C432.657 21.0858 430.831 20.6671 428.548 20.6671C423.146 20.6671 417.745 22.7224 412.343 26.833C407.017 30.9436 402.642 36.196 399.219 42.5902C395.871 48.9083 394.197 55.1884 394.197 61.4304Z"
      fill="black"
    />
    <path
      d="M469.448 79.3571C465.72 79.3571 462.791 78.2152 460.661 75.9316C458.53 73.6479 457.465 70.6031 457.465 66.797C457.465 64.6656 457.846 62.0774 458.606 59.0325L468.421 22.8366H456.666L457.922 18.1551H469.562L478.92 0H482.002L477.208 18.1551H492.387L491.246 22.8366H476.067L466.253 59.2609C465.492 61.9252 465.111 64.2469 465.111 66.2261C465.111 68.5097 465.758 70.3747 467.052 71.821C468.345 73.2673 470.209 73.9905 472.644 73.9905C474.85 73.9905 476.98 73.3815 479.034 72.1636C481.165 70.9456 483.143 69.2709 484.969 67.1395L486.11 68.053C484.436 71.4785 482.116 74.2188 479.149 76.2741C476.257 78.3294 473.024 79.3571 469.448 79.3571Z"
      fill="black"
    />
    <path
      d="M516.475 79.3571C509.475 79.3571 503.997 77.454 500.041 73.6479C496.085 69.8418 494.107 64.5514 494.107 57.7765C494.107 51.0017 495.971 44.3791 499.699 37.9087C503.503 31.4383 508.372 26.1859 514.307 22.1515C520.317 18.0409 526.48 15.9856 532.794 15.9856C537.74 15.9856 541.658 17.2797 544.549 19.8678C547.516 22.456 549 25.9576 549 30.3726C549 37.452 545.082 42.8186 537.245 46.4724C529.409 50.1263 517.882 51.9532 502.666 51.9532C502.362 54.2369 502.21 55.8735 502.21 56.8631C502.21 62.1916 503.731 66.3783 506.774 69.4232C509.894 72.468 514.192 73.9905 519.67 73.9905C528.115 73.9905 535.229 70.4128 541.011 63.2573L542.267 64.0566C536.408 74.2569 527.811 79.3571 516.475 79.3571ZM503.237 48.1852C516.399 48.1852 526.213 46.7008 532.68 43.732C539.147 40.7633 542.381 36.234 542.381 30.1443C542.381 26.9471 541.506 24.5112 539.756 22.8366C538.082 21.1619 535.648 20.3245 532.452 20.3245C528.496 20.3245 524.502 21.5806 520.469 24.0926C516.437 26.6046 512.861 30.0301 509.742 34.369C506.698 38.6319 504.53 43.2372 503.237 48.1852Z"
      fill="black"
    />
    <path
      d="M21.9117 197C18.1836 197 15.2545 195.858 13.1242 193.575C10.9939 191.291 9.92872 188.246 9.92872 184.44C9.92872 182.308 10.3091 179.72 11.07 176.675L20.8846 140.479H9.12986L10.3852 135.798H22.0258L31.3839 117.643H34.4652L29.672 135.798H44.8504L43.7092 140.479H28.5308L18.7162 176.904C17.9554 179.568 17.575 181.89 17.575 183.869C17.575 186.153 18.2217 188.018 19.5151 189.464C20.8085 190.91 22.6725 191.633 25.1071 191.633C27.3135 191.633 29.4438 191.024 31.498 189.806C33.6283 188.589 35.6064 186.914 37.4324 184.782L38.5736 185.696C36.8998 189.121 34.5793 191.862 31.6121 193.917C28.721 195.972 25.4875 197 21.9117 197Z"
      fill="black"
    />
    <path
      d="M51.7101 195.63L58.3673 164.143C61.3933 149.49 69.3819 142.587 78.3388 142.587H79.4282C88.022 142.587 91.6532 149.853 89.5955 159.662L81.849 195.63H92.1374L99.7629 159.662C103.394 143.071 93.5898 133.867 81.849 133.867C74.1024 133.867 67.3242 137.379 62.8457 143.556L69.624 110.858H59.3356L41.4217 195.63H51.7101Z"
      fill="black"
    />
    <path
      d="M125.898 197C118.899 197 113.421 195.097 109.465 191.291C105.508 187.485 103.53 182.194 103.53 175.419C103.53 168.645 105.394 162.022 109.122 155.552C112.926 149.081 117.796 143.829 123.73 139.794C129.741 135.684 135.903 133.629 142.218 133.629C147.163 133.629 151.082 134.923 153.973 137.511C156.94 140.099 158.424 143.6 158.424 148.016C158.424 155.095 154.505 160.461 146.669 164.115C138.832 167.769 127.306 169.596 112.09 169.596C111.785 171.88 111.633 173.516 111.633 174.506C111.633 179.835 113.155 184.021 116.198 187.066C119.317 190.111 123.616 191.633 129.094 191.633C137.539 191.633 144.653 188.056 150.435 180.9L151.69 181.699C145.832 191.9 137.235 197 125.898 197ZM112.66 165.828C125.822 165.828 135.637 164.344 142.104 161.375C148.571 158.406 151.804 153.877 151.804 147.787C151.804 144.59 150.929 142.154 149.18 140.479C147.506 138.805 145.071 137.967 141.876 137.967C137.919 137.967 133.925 139.223 129.893 141.736C125.86 144.248 122.285 147.673 119.165 152.012C116.122 156.275 113.954 160.88 112.66 165.828Z"
      fill="black"
    />
    <path
      d="M213.271 196.841C226.464 196.841 237.721 189.09 240.505 175.89H230.216C228.159 183.035 222.228 188.121 214.36 188.121H213.271C202.861 188.121 196.688 180.976 196.688 169.835C196.688 154.091 205.766 142.587 217.749 142.587H218.839C226.706 142.587 232.637 146.462 232.637 154.818H242.926C242.926 142.102 233 133.867 219.202 133.867C201.288 133.867 186.4 147.794 186.4 170.561C186.4 187.637 197.899 196.841 213.271 196.841Z"
      fill="black"
    />
    <path
      d="M271.485 197C264.485 197 259.008 194.831 255.051 190.492C251.095 186.153 249.117 180.177 249.117 172.565C249.117 165.562 250.715 159.091 253.91 153.154C257.182 147.14 261.556 142.383 267.034 138.881C272.512 135.379 278.485 133.629 284.952 133.629C291.951 133.629 297.391 135.722 301.271 139.909C305.227 144.095 307.206 149.995 307.206 157.607C307.206 164.61 305.57 171.157 302.298 177.246C299.103 183.26 294.766 188.056 289.288 191.633C283.81 195.211 277.876 197 271.485 197ZM257.22 173.821C257.22 179.911 258.475 184.592 260.986 187.865C263.496 191.062 267.072 192.661 271.713 192.661C276.506 192.661 280.995 191.024 285.18 187.751C289.44 184.478 292.826 180.101 295.337 174.62C297.847 169.063 299.103 163.126 299.103 156.808C299.103 150.718 297.885 146.074 295.451 142.877C293.016 139.604 289.478 137.967 284.837 137.967C280.044 137.967 275.517 139.604 271.257 142.877C266.996 146.151 263.572 150.566 260.986 156.123C258.475 161.603 257.22 167.503 257.22 173.821Z"
      fill="black"
    />
    <path
      d="M323.139 197C320.248 197 317.966 196.087 316.292 194.26C314.618 192.433 313.781 189.768 313.781 186.267C313.781 183.755 314.238 180.634 315.151 176.904L331.014 117.871L322.34 109.993H340.6L322.683 177.931C321.77 181.738 321.313 184.402 321.313 185.924C321.313 188.132 321.808 189.845 322.797 191.062C323.786 192.204 325.117 192.775 326.791 192.775C327.78 192.775 328.617 192.547 329.302 192.09C330.063 191.633 330.785 190.91 331.47 189.921L332.725 190.72C332.117 192.547 330.938 194.069 329.188 195.287C327.514 196.429 325.498 197 323.139 197Z"
      fill="black"
    />
    <path
      d="M367.154 196.841C385.068 196.841 399.108 181.582 399.108 161.358C399.108 145.009 389.425 133.867 372.721 133.867C354.808 133.867 340.767 149.247 340.767 169.35C340.767 185.699 350.45 196.841 367.154 196.841ZM367.033 188.121C356.744 188.121 351.055 179.644 351.055 168.987C351.055 152.154 361.707 142.587 371.632 142.587H372.721C383.01 142.587 388.82 150.943 388.82 161.721C388.82 178.554 378.047 188.121 368.122 188.121H367.033Z"
      fill="black"
    />
    <path
      d="M414.273 143.334L406.513 135.798H423.175L416.099 162.974C419.675 153.991 423.517 147.216 427.626 142.649C431.81 138.006 435.957 135.684 440.065 135.684C441.739 135.684 443.07 136.064 444.059 136.826C445.048 137.587 445.543 138.615 445.543 139.909C445.543 141.203 445.01 142.23 443.945 142.992C442.88 143.753 441.434 144.133 439.608 144.133C427.968 144.133 417.925 159.434 409.48 190.035L407.996 195.63H400.35L414.273 143.334Z"
      fill="black"
    />
  </svg>
);

export default HeroTextDesktop;
