import React from "react";
import Box from "@mui/material/Box";
import { NeedToKnowInterface } from "./interfaces/need-to-know.interface";
import NeedToKnowDropDown from "./components/need-to-know-dropdown";
import NeedToKnowText from "./components/need-to-know-text";

interface Props {
  needToKnowData: NeedToKnowInterface[];
}
const AllYouNeedToKnow = ({ needToKnowData }: Props) => (
  <Box
    width="100%"
    py={{ xs: 3, md: 5 }}
    px={{ xs: 2 }}
    sx={{ backgroundColor: "black" }}
  >
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      alignItems="stretch"
      justifyContent="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width={{ xs: "100%", sm: "unset" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        mr={{ sm: 8 }}
        mb={{ xs: 3, sm: 0 }}
      >
        <Box mb={3} mt={{ sm: 3 }}>
          <NeedToKnowText
            title="Quick Turnaround"
            text="Lorem ipsum dolor sit amet consectetur adipiscing elit"
          />
        </Box>
        <NeedToKnowText
          title="No Minimum Order"
          text="Lorem ipsum dolor sit amet consectetur adipiscing elit"
        />
      </Box>
      <Box
        width={{ xs: "100%", sm: "unset" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {needToKnowData.map((data) => (
          <NeedToKnowDropDown key={data.title} needToKnowData={data} />
        ))}
      </Box>
    </Box>
  </Box>
);

export default AllYouNeedToKnow;
