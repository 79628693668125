export const UPDATE_CART_ATTRIBUTES = `
mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
  checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
    checkout {
     id
     note
		 customAttributes	{key value}
    }
   
  }
}
`;
