export const CART_QUERY = `query cartQuery($cartId: ID!) {
    cart(id: $cartId) {
      id
      createdAt
      updatedAt
  
      lines(first: 150) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                price {
                  amount
                  currencyCode
                }
                product {
                  id
                  title 
                  description
                  seo { title description }
                }			
              }
            }
            estimatedCost{
							subtotalAmount{amount}
						}
            attributes {
              key
              value
            }
          }
        }
      }
      attributes {
        key
        value
      }
      estimatedCost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
      buyerIdentity {
        email
        phone
        customer {
          id
        }
        countryCode
      }
    } 
  }
  `;
