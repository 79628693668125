import React from "react";

const TikTokIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9999 12.0121C25.0044 18.6256 19.6056 23.9981 12.9436 24.0101C6.3005 24.0224 0.873945 18.6349 0.872071 12.0262C0.870197 5.37566 6.26718 -0.0026921 12.9402 1.01095e-06C19.607 0.00269412 24.9955 5.36986 25.0001 12.0121H24.9999ZM19.484 7.94593C19.4607 7.93412 19.4548 7.92935 19.4484 7.92811C19.3905 7.91734 19.3326 7.90532 19.2743 7.89745C18.3776 7.77813 17.6243 7.37499 16.981 6.75309C16.4857 6.27433 16.098 5.71748 15.7816 5.10987C15.7447 5.03923 15.7045 5.01251 15.6225 5.01292C14.88 5.01644 14.1376 5.01499 13.3952 5.01499C13.361 5.01499 13.3271 5.01499 13.2794 5.01499V5.16663C13.2794 8.22518 13.2798 11.2839 13.2794 14.3425C13.2792 15.6174 12.2036 16.6211 10.9251 16.5421C9.76517 16.4705 8.8572 15.5231 8.84242 14.3692C8.82764 13.2099 9.71562 12.2319 10.8768 12.1439C11.1874 12.1203 11.5007 12.1341 11.8128 12.1308C11.8493 12.1304 11.8859 12.1308 11.923 12.1308V9.68671C11.3758 9.69314 10.8349 9.65833 10.298 9.74886C7.77081 10.1746 6.04753 12.5093 6.4454 15.0282C6.72377 16.7901 7.72542 18.0403 9.39665 18.6757C11.0718 19.3125 12.6567 19.0411 14.0479 17.9102C15.1901 16.9817 15.7347 15.7452 15.738 14.2783C15.7422 12.4936 15.7391 10.7086 15.7391 8.92394C15.7391 8.87878 15.7391 8.83362 15.7391 8.76256C16.6585 9.68651 17.7732 10.1149 19.0205 10.285C19.1761 9.5011 19.3299 8.72424 19.4842 7.94613L19.484 7.94593Z"
      fill="black"
    />
  </svg>
);

export default TikTokIcon;
