import React from "react";
import Box from "@mui/material/Box";
import IcoBottle from "./ico-polish-bottle";
import CustomProductBackground from "../product-backgrounds/custom-product-background";
import "../../pages/product/product.css";
import { useInternalCanvasTestHook } from "../../utils/hooks/useInternalCanvasTestHook";
interface CustomBottleProps {
  hue: number;
  sat: number;
  val: number;
}
const CustomProductBottleImage = ({ hue, sat, val }: CustomBottleProps) => {
  const isInternalCanvasTestHook = useInternalCanvasTestHook()

  return (
    <>
      <Box
        sx={{
          position: { sm: "absolute" },
          width: "100%",
          top: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <Box className="custom-background-container" width="100%">
          <CustomProductBackground
            isProdPage
            isCart={false}
            rotate
            hsv={[hue, sat, val]}
          />
        </Box>
      </Box>
      <Box
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        m="0 auto"
      >
        <Box
          height="100%"
          position="absolute"
          top={{ xs: "-36px", sm: "-80px", md: "-100px" }}
        >
          <Box
            className="custom-ico-bottle-container"
            height={{
              xs: "220px",
              sm: "430px",
              md: "487px",
              lg: "560px",
              xl: "620px",
            }}
          >
            {!isInternalCanvasTestHook && <IcoBottle imgHeight="100%" />}
          </Box>
        </Box>
      </Box>
    </>
  )
};

export default React.memo(CustomProductBottleImage);
