import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FaqInterface } from "../CONSTANTS/faqs";

interface FaqProps {
  faq: FaqInterface;
}

const FAQ = ({ faq }: FaqProps) => {
  return (
    <Box key={faq.question} display="flex" flexDirection="column" mb={2}>
      <Box display="flex" alignItems="start">
        <Typography mr={2} variant="subtitle1">
          Q:
        </Typography>
        <Typography alignSelf="center" variant="subtitle1">
          {faq.question}
        </Typography>
      </Box>
      <Box display="flex" alignItems="start">
        <Typography mr={2} variant="subtitle1">
          A:
        </Typography>
        <Typography variant="body1">{faq.answer}</Typography>
      </Box>
    </Box>
  );
};

export default FAQ;
