import React from "react";
import Box from "@mui/material/Box";
import { Shape1, Shape2, Shape3, Shape4 } from "./random-circles.component";
import { keyframes } from "@mui/material";

interface CustomProductBgProps {
  hsv: number[];
  rotate: boolean;
  isCart: boolean;
  isProdPage: boolean;
}
const CustomProductBackground = ({
  hsv,
  rotate,
  isCart,
  isProdPage,
}: CustomProductBgProps) => {
  const maxHeight = "100%";
  const shapeArr = [
    <Shape1
      isProdPage={isProdPage}
      hsv={hsv}
      maxHeight={maxHeight}
      isCartShape={isCart}
    />,
    <Shape2
      isProdPage={isProdPage}
      hsv={hsv}
      maxHeight={maxHeight}
      isCartShape={isCart}
    />,
    <Shape3
      isProdPage={isProdPage}
      hsv={hsv}
      maxHeight={maxHeight}
      isCartShape={isCart}
    />,
    <Shape4
      isProdPage={isProdPage}
      hsv={hsv}
      maxHeight={maxHeight}
      isCartShape={isCart}
    />,
  ];
  const randomShape = shapeArr[Math.floor(Math.random() * shapeArr.length)];

  const spin = keyframes`
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(360deg); 
  }
`;

  return (
    <Box
      sx={{
        animation: rotate ? `${spin} 4s ease` : "none",
        zIndex: 1,
      }}
    >
      {randomShape}
    </Box>
  );
};
export default React.memo(CustomProductBackground);
