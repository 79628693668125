import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { ColorLibraryColorsInterface } from "../interfaces/color-library-colors-interface";
import { fetchLibraryColors } from "./library-colors-actions";
import { RequestState } from "../../../../services/types/requestState.type";

export interface LibraryColorsState {
  colorLibraryByName: Record<string, ColorLibraryColorsInterface>;
  currentTag: string;
  statusByName: Record<string, RequestState | undefined>;
}

const initialState: LibraryColorsState = {
  colorLibraryByName: {} as Record<string, ColorLibraryColorsInterface>,
  currentTag: "",
  statusByName: {} as Record<string, RequestState | undefined>,
};

const libraryColorsSlice = createSlice({
  name: "libraryColors",
  initialState,
  reducers: {
    setNewTag(state, action: PayloadAction<string>) {
      state.currentTag = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLibraryColors.pending, (state, action) => {
        state.statusByName[action.meta.arg] = "pending";
      })
      .addCase(fetchLibraryColors.fulfilled, (state, action) => {
        state.statusByName[action.meta.arg] = "fulfilled";
        state.colorLibraryByName[action.meta.arg] = action.payload;
      })
      .addCase(fetchLibraryColors.rejected, (state, action) => {
        state.statusByName[action.meta.arg] = "rejected";
      });
  },
});

export const { setNewTag } = libraryColorsSlice.actions;
export const selectStatusByName = (state: RootState, collection: string) =>
  state.libraryColors.statusByName[collection];
export const selectColorLibraryByName = (
  state: RootState,
  collection: string
) => state.libraryColors.colorLibraryByName[collection];

export default libraryColorsSlice.reducer;
