export const COLLECTION_AS_PRODUCT_QUERY = `
query queryCollectionAsProduct($handle: String!){
  product(handle: $handle) {
    id
		handle
    title
		description
		descriptionHtml
		compareAtPriceRange { maxVariantPrice  {amount} }
 		media(first: 2) { edges { node {previewImage {url}}} }
    variants(first: 1) {
      edges {
        cursor
        node {
          id
          title
          price {
            amount
          }
        }
      }
    }
  }
}
`;
