import React, { ChangeEvent } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import UploadIcon from "./upload-icon";

interface UploadBtnProps {
  hiddenFileInput: React.RefObject<HTMLInputElement>;
  error: string;
  handleInputAction: () => void;
  uploadFile: (e: ChangeEvent<HTMLInputElement>) => void;
}
const UploadButton = ({
  hiddenFileInput,
  handleInputAction,
  uploadFile,
  error,
}: UploadBtnProps) => {
  let iconColor = "black";
  return (
    <Box width="100%">
      {error.length ? (
        <span
          style={{
            fontFamily: "ABC Favorit Light",
            color: "red",
            fontSize: "14px",
          }}
        >
          {error}
        </span>
      ) : null}
      <Button
        fullWidth
        onClick={handleInputAction}
        variant="contained"
        startIcon={<UploadIcon />}
        sx={{
          backgroundColor: "white",
          color: "black",
          fontFamily: "ABC Favorit Book",
          fontSize: "20px",
          lineHeight: "15px",
          pt: "12.5px",
          pb: "12.5px",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
            iconColor,
            ".upload-icon": {
              stroke: "white",
            },
          },
        }}
      >
        {" "}
        Upload a file
      </Button>

      <input
        onChange={uploadFile}
        ref={hiddenFileInput}
        type="file"
        style={{ display: "none" }}
      />
    </Box>
  );
};
export default UploadButton;
