import React, { forwardRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../utils/hooks/redux-hooks";
import {
  selectCartCount,
  selectCartItems,
  selectCartTotal,
} from "./state/cart-slice";

import CartDrawerItem from "./components/cart-item/cart-drawer-item";
import { useNavigate } from "react-router-dom";
import { SHOP_ALL } from "../../routing/CONSTANTS";
import AddMoreItems from "./components/add-more-items";
import UpSellMobile from "./components/upsells/upSell-mobile-container";
import UpSellDesktop from "./components/upsells/upSell-desk-container";
import CartTop from "./components/cart-top";
import DiscountTags from "./components/discount-tags";
import CartBottom from "./components/cart-bottom";
import useCartActions from "./hooks/useCartActions";
import { useGetUpSellProductsQuery } from "./hooks/useGetUpSellProductsQuery";
import { useCheckDiscountsQuery } from "./hooks/useCheckDiscountsQuery";
import { UpSellInterface } from "./interfaces/up-sell-interface";
import PixelService from "../../services/pixels/pixel-service";

interface Props {
  setDropDownIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dropDownIsOpen: boolean;
  upSellBarIsOpen: boolean;
}

const CartDrawer = forwardRef(
  ({ setDropDownIsOpen, dropDownIsOpen, upSellBarIsOpen }: Props, ref) => {
    const cartCount = useAppSelector(selectCartCount);
    const cartItems = useAppSelector(selectCartItems);
    const cartTotal = useAppSelector(selectCartTotal);
    const navigate = useNavigate();
    const { addUpSellToCart, closeCart, handleShopifyCheckout } =
      useCartActions();
    const { upSellData, cartUpSellList } = useGetUpSellProductsQuery();
    const { discountData } = useCheckDiscountsQuery();

    const handleNavigateShopAll = () => {
      navigate(SHOP_ALL);
      setDropDownIsOpen(false);
    };

    const addUpSell = (item: UpSellInterface) => {
      addUpSellToCart(item);
      PixelService.pixelAddToCart(
        item.productId,
        9.0,
        item.title,
        item.merchId,
        "shop-all",
        cartTotal
      );
    };

    const handleCheckout = () => {
      handleShopifyCheckout(cartItems, discountData);
    };

    useEffect(() => {
      if (dropDownIsOpen) document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    });
    return (
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 999,
          display: "block",
          opacity: dropDownIsOpen ? 1 : 0,
          visibility: dropDownIsOpen ? "visible" : "hidden",
          transition: "all .75s ease-in-out",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#E7E7E7",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0.75,
            zIndex: 998,
            display: "block",
          }}
        />
        <Box ref={dropDownIsOpen ? ref : undefined} height="100%">
          <Box
            id="cart-container"
            sx={{
              float: "right",
              width: "calc(100% - 60px)",
              maxWidth: "598px",
              height: "100%",
              position: "relative",
              left: "auto",
              right: 0,
              top: 0,
              zIndex: 999,
              opacity: dropDownIsOpen ? 1 : 0,
              visibility: dropDownIsOpen ? "visible" : "hidden",
              backgroundColor: "white",
              overFlowY: "hidden",
              borderLeft: "2px solid black",
              transform: dropDownIsOpen ? "translateX(0)" : "translateX(100%)",
              transition: "all .5s ease-in",
              display: "block",
            }}
          >
            <CartTop closeModal={closeCart} cartCount={cartCount} />

            <Box
              height={{ xs: "calc(100% - 50px)", md: "calc(100% - 50px)" }}
              pb={{ xs: "137px", md: "230px" }}
            >
              <Box
                sx={{
                  width: "100%",
                  overflowY: "scroll",
                  height: "100%",
                  pb: "20px",
                  alignContent: "flex-start",
                }}
              >
                <DiscountTags />

                <Box
                  width="100%"
                  p={{ xs: "0 21px", md: "20px 45px" }}
                  mt={{ xs: "15px", md: "20px" }}
                >
                  {cartItems.length > 0 &&
                    cartItems.map((item) => (
                      <CartDrawerItem key={item.lineId} item={item} />
                    ))}

                  <AddMoreItems
                    cartTotal={cartTotal}
                    handleNavigateShopAll={handleNavigateShopAll}
                  />
                </Box>
                <UpSellMobile
                  addUpSellToCart={addUpSell}
                  upSellItems={upSellData}
                  cartUpSellItems={cartUpSellList}
                />
              </Box>
              <CartBottom
                cartTotal={cartTotal}
                cartCount={cartCount}
                handleCheckout={handleCheckout}
              />
            </Box>
          </Box>

          {upSellData.length !== 0 && dropDownIsOpen && (
            <UpSellDesktop
              isShown={upSellBarIsOpen}
              addUpSellToCart={addUpSell}
              upSellItems={upSellData}
              cartUpSellItems={cartUpSellList}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export default CartDrawer;
