import React from "react";
import Box from "@mui/material/Box";

const AddMoreCloudPlusSymbol = () => (
  <Box
    fontSize={{ xs: "22px", md: "28px" }}
    lineHeight={{ xs: "25px", md: "29px" }}
    position="absolute"
    top="50%"
    left="50%"
    color="black"
    sx={{
      transform: {
        xs: "translate(-50%,-67%)",
        md: "translate(-50%,-60%)",
      },
      cursor: "pointer",
    }}
  >
    +
  </Box>
);

export default AddMoreCloudPlusSymbol;
