const pageStyles = {
  overflow: "hidden",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const notFoundContainerStyles = {
  background: "white",
  padding: "20px",
  height: "50%",
  width: { xs: "90%", sm: "80%", md: "50%" },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const blueDesktopStyles = {
  display: { xs: "none", md: "block" },
  overflow: "hidden",
  position: "absolute",
  bottom: "0",
  right: "0",
  zIndex: -1,
};

const blueMobileStyles = {
  display: { xs: "block", md: "none" },
  overflow: "hidden",
  position: "absolute",
  bottom: "0",
  right: "0",
  zIndex: -1,
};

const redDesktopStyles = {
  display: { xs: "none", md: "block" },
  overflow: "hidden",
  position: "absolute",
  top: "0",
  left: "0",
  zIndex: -1,
};

const redMobileStyles = {
  display: { xs: "block", md: "none" },
  overflow: "hidden",
  position: "absolute",
  top: "0",
  right: "0",
  zIndex: -1,
};
const purpleMobileStyles = {
  display: { xs: "block", md: "none" },
  overflow: "hidden",
  position: "absolute",
  top: "0",
  left: "0",
  zIndex: -1,
};

const pageNotFoundStyles = {
  pageStyles,
  notFoundContainerStyles,
  blueDesktopStyles,
  blueMobileStyles,
  redDesktopStyles,
  redMobileStyles,
  purpleMobileStyles,
};

export default pageNotFoundStyles;
