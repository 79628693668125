import React from "react";
import Box from "@mui/material/Box";
import BibInfoBlock from "./bib-info-block";
import { BuyInBulkInterface } from "../interfaces/buy-in-bulk.interface";
import BibInfoCard from "./bib-info-card";
import HorizontalScrollContainer from "../../../horizontal-scroll-container";

interface Props {
  bibData: BuyInBulkInterface;
}
const BuyInBulkMobile = ({ bibData }: Props) => (
  <Box
    display={{ xs: "flex", md: "none" }}
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    width="100%"
    mb={2.5}
  >
    <BibInfoBlock bibData={bibData} />
    <Box mt={2} width="100%" position="relative" overflow="hidden">
      <HorizontalScrollContainer>
        {bibData.bibInfoCards.map((info) => (
          <BibInfoCard key={info.cardTitle} bibData={info} />
        ))}
      </HorizontalScrollContainer>
    </Box>
  </Box>
);
export default BuyInBulkMobile;
