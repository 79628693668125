import { CollectionProductInterface } from "../interfaces/collection-interfaces";
import img1 from "../../../assets/shopAllAssets/shopAllImg1.webp";
import img2 from "../../../assets/shopAllAssets/shopAllImg2.webp";
import img3 from "../../../assets/shopAllAssets/shopAllImg3.webp";
import img4 from "../../../assets/shopAllAssets/shopAllImg4.webp";
import img5 from "../../../assets/shopAllAssets/shopAllImg5.webp";

export const randoImages: CollectionProductInterface[] = [
  {
    productId: "",
    merchId: "",
    handle: "",
    title: "img1",
    seoTitle: "",
    seoDescription: "",
    description: "hand holding polish",
    variants: [],
    cursor: "",
    image: img1,
    isRandomImg: true,
    tags: [],
  },
  {
    productId: "",
    merchId: "",
    handle: "",
    title: "img2",
    seoTitle: "",
    seoDescription: "",
    description: "polish bottles",
    variants: [],
    cursor: "",
    image: img2,
    isRandomImg: true,
    tags: [],
  },
  {
    productId: "",
    merchId: "",
    handle: "",
    title: "img3",
    seoTitle: "",
    seoDescription: "",
    description: "hand with polishes",
    variants: [],
    cursor: "",
    image: img3,
    isRandomImg: true,
    tags: [],
  },
  {
    productId: "",
    merchId: "",
    handle: "",
    title: "img4",
    seoTitle: "",
    seoDescription: "",
    description: "polish bottles",
    variants: [],
    cursor: "",
    image: img4,
    isRandomImg: true,
    tags: [],
  },
  {
    productId: "",
    merchId: "",
    handle: "",
    title: "img5",
    description: "spilled polish bottles",
    seoTitle: "",
    seoDescription: "",
    variants: [],
    cursor: "",
    image: img5,
    isRandomImg: true,
    tags: [],
  },
];
