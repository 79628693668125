import React from "react";
import { Box, Typography } from "@mui/material";
import { AboutYourPolishInterface } from "../CONSTANTS/about-your-polish";

interface Props {
  infoItem: AboutYourPolishInterface;
}
const AboutSlide = ({ infoItem }: Props) => (
  <Box
    width="100%"
    display="flex"
    flexDirection="column"
    px={{ xs: 1, md: 2 }}
    mb={{ xs: 0.5, md: 1.5 }}
    sx={{ cursor: "pointer" }}
  >
    <Box mb={{ xs: 1.5, md: 2 }}>
      <Typography
        textAlign="left"
        variant="body1"
        fontStyle="italic"
        fontSize={{ xs: "16px", md: "24px" }}
        lineHeight={{ xs: "24px", md: "32px" }}
      >
        {infoItem.slideTitle}
      </Typography>
    </Box>
    <Box mb={1.5} pr={{ xs: 0.25, md: 3 }}>
      <Typography
        textAlign="left"
        variant="body1"
        fontSize={{ xs: "14px", md: "20px" }}
        lineHeight={{ xs: "20px", md: "28px" }}
      >
        {infoItem.slideDesc}
      </Typography>
    </Box>
  </Box>
);

export default AboutSlide;
