import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReturnInstructionsInterface } from "../../interface/return-instructions-interface";

interface Props {
  instruction: ReturnInstructionsInterface;
}
const Instruction = ({ instruction }: Props) => (
  <Box display="flex" alignItems="center" mb={2} pr={{ xs: 0, sm: 2 }}>
    <Box
      width="26.44px"
      height="25px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      sx={{ backgroundColor: "black" }}
    >
      <Typography
        variant="body2"
        fontSize="16px"
        lineHeight="20px"
        fontWeight="medium"
        color="white"
        sx={{ mt: "2px" }}
      >
        {instruction.step}
      </Typography>
    </Box>
    <Box
      width="100%"
      maxWidth={{ xs: "240px", sm: "85%", md: "100%" }}
      ml={1.5}
    >
      <Typography
        variant="body1"
        fontSize={{ xs: "13px", sm: "16px" }}
        lineHeight={{ xs: "18px", sm: "20px" }}
      >
        {instruction.instruction}
      </Typography>
    </Box>
  </Box>
);

export default Instruction;
