import { graphqlClientRequest } from "../../../services/graphQl/graphQl-common";
import {
  CART_QUERY,
  CHECKOUT_QUERY,
  POLISH_PRICE_QUERY,
} from "../../../services/graphQl/queries/index";
import {
  ADD_CART_ITEM_MUTATION,
  APPLY_SHIPPING_DISCOUNT_MUTATION,
  CREATE_CART_MUTATION,
  REMOVE_CART_ITEM_MUTATION,
  UPDATE_CART_ITEM_MUTATION,
  CART_NOTE_MUTATION,
} from "../../../services/graphQl/mutations/index";

import { PolishTypes, ProductInterface } from "../interfaces/product.interface";
import { AddToCartInterface } from "../interfaces/add-to-cart.interface";
import {
  BASE_COAT_MERCH_ID,
  CUSTOM_POLISH_MERCH_ID,
  PEARL_TOP_COAT_MERCH_ID,
  TOP_COAT_MERCH_ID,
} from "../CONSTANTS/merch-ids";
import { DiscountInterface } from "../interfaces/discount.interface";
import { CREATE_CHECKOUT_MUTATION } from "../../../services/graphQl/mutations/create-checkout.mutation";
import { APPLY_DISCOUNT_CODES } from "../../../services/graphQl/mutations/apply-discount-codes.mutation";
import { UPDATE_CART_ATTRIBUTES } from "../../../services/graphQl/mutations/update-cart-attributes";

const getAttributes = (
  item: AddToCartInterface | ProductInterface
): { key: string; value: string | undefined }[] => {
  const attributes =
    item.merchId === CUSTOM_POLISH_MERCH_ID
      ? [
          { key: "id", value: item.key },
          { key: "title", value: item.value },
        ]
      : item.key.toLowerCase().includes("collection")
      ? [
          { key: "title", value: item.key },
          { key: "products", value: item.value },
          { key: "img", value: item.img },
        ]
      : [
          { key: "title", value: item.key },
          { key: "product", value: item.value },
          { key: "img", value: item.img !== undefined ? item.img : "" },
        ];

  return attributes;
};
const checkCustom = (title: string, merchId: string): PolishTypes => {
  if (merchId === CUSTOM_POLISH_MERCH_ID) {
    return PolishTypes.CustomPolish;
  } else if (
    merchId === PEARL_TOP_COAT_MERCH_ID ||
    merchId === BASE_COAT_MERCH_ID ||
    merchId === TOP_COAT_MERCH_ID
  ) {
    return PolishTypes.UpsellPolish;
  } else if (title.toLowerCase().includes("collection")) {
    return PolishTypes.FullCollectionProduct;
  } else {
    return PolishTypes.ShopifyPolish;
  }
};

const cartMapper = (item: any): ProductInterface => {
  const customTitle = item.node.merchandise.product.title;
  const attrs = item.node.attributes;
  const productImg = attrs.length > 2 && attrs[2].value;
  const merchId = item.node.merchandise.id;
  const polishType = checkCustom(customTitle, merchId);
  const productId = item.node.merchandise.product.id;

  return {
    lineId: item.node.id,
    quantity: item.node.quantity,
    price: item.node.merchandise.price.amount,
    key: item.node.attributes[0].value,
    value: item.node.attributes[1].value,
    merchId: item.node.merchandise.id,
    polishType: polishType,
    img: productImg,
    productId: productId,
  };
};

const checkout = async (cartId: string) => {
  const data = {
    query: CHECKOUT_QUERY,
    variables: {
      cartId: cartId,
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0];

  const url = res.data.data.cart.checkoutUrl;
  return url;
};

interface CheckoutItemsInterface {
  variantId: string;
  quantity: number;
  customAttributes: {
    key: string;
    value: string | undefined;
  }[];
}

const updateCartAttributes = async (clientId: string, checkoutId: string) => {
  const updateAttrs = {
    customAttributes: [{ key: "clientID", value: clientId }],
  };

  const data = {
    query: UPDATE_CART_ATTRIBUTES,
    variables: {
      checkoutId: checkoutId,
      input: updateAttrs,
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;
};

const createCheckout = async (cartItems: ProductInterface[]) => {
  const cartLineItems: CheckoutItemsInterface[] = [];
  cartItems.forEach((item) => {
    const checkoutData: CheckoutItemsInterface = {
      variantId: item.merchId,
      quantity: item.quantity,
      customAttributes: getAttributes(item),
    };
    cartLineItems.push(checkoutData);
  });
  const data = {
    query: CREATE_CHECKOUT_MUTATION,
    variables: {
      input: {
        lineItems: cartLineItems,
      },
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;

  const checkoutId = res.data.data.checkoutCreate.checkout.id;
  const nonDiscountURl = res.data.data.checkoutCreate.checkout.webUrl;

  return { checkoutId, nonDiscountURl };
};

const applyDiscounts = async (checkoutId: string, discount: string) => {
  const data = {
    query: APPLY_DISCOUNT_CODES,
    variables: {
      checkoutId: checkoutId,
      discountCode: discount,
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;

  return res;
};

const checkForDiscounts = async (
  attnDiscount: string | null,
  cartId: string,
  cartTotal: number,
  rejectWithValue: any
) => {
  const regularDiscounts = cartTotal >= 20 ? ["SHIPFOR20"] : [];
  const attnDiscounts = cartTotal >= 9 && attnDiscount ? [attnDiscount] : [];
  const data = {
    query: APPLY_SHIPPING_DISCOUNT_MUTATION,
    variables: {
      cartId: cartId,
      discountCodes: [...attnDiscounts, ...regularDiscounts],
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) {
    return rejectWithValue(res.data.errors[0].message);
  }
  const codes: any[] = res.data.data.cartDiscountCodesUpdate.cart.discountCodes;
  const discountCodes: DiscountInterface[] = [];
  codes.forEach((code) => {
    const discountCode: DiscountInterface = {
      discount: code.code,
      bannerTitle:
        code.code === "SHIPFOR20"
          ? "FREE DELIVERY applied"
          : `${code.code} DISCOUNT applied`,
      applicable: code.applicable,
    };
    if (code.applicable) discountCodes.push(discountCode);
  });

  return discountCodes;
};

const fetchCustomPolishPrice = async () => {
  const data = {
    query: POLISH_PRICE_QUERY,
  };
  const polishPrice = sessionStorage.getItem("polishPrice");

  if (polishPrice !== null) {
    return Math.trunc(parseInt(polishPrice));
  } else {
    const res = await graphqlClientRequest.then((res) => res.post(data));
    if (res.data.errors) {
      return 9;
    }
    const mappedPolish =
      res.data.data.product.variants.edges[0].node.price.amount;
    const asNum = parseInt(mappedPolish);
    sessionStorage.setItem("polishPrice", mappedPolish);
    return Math.trunc(asNum);
  }
};

const fetchCart = async (cartId: string) => {
  const data = {
    query: CART_QUERY,
    variables: {
      cartId: cartId,
    },
  };

  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;

  let products: ProductInterface[] = [];
  if (res.data.data.cart !== null) {
    const prod: [] = res.data.data.cart.lines.edges;
    prod.forEach((item: any) => {
      const newProduct: ProductInterface = cartMapper(item);
      products.push(newProduct);
    });
  } else {
    localStorage.removeItem("cartId");
  }
  return products;
};

const createCart = async (product: AddToCartInterface) => {
  const attributes = getAttributes(product);
  const data = {
    query: CREATE_CART_MUTATION,
    variables: {
      cartInput: {
        lines: {
          merchandiseId: product.merchId,
          quantity: product.quantity,
          attributes: attributes,
        },
      },
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;

  let products: ProductInterface[] = [];
  const prod: [] = res.data.data.cartCreate.cart.lines.edges;
  const cartID = res.data.data.cartCreate.cart.id;
  localStorage.setItem("cartId", cartID);
  prod.forEach((item: any) => {
    const newProduct: ProductInterface = cartMapper(item);
    products.push(newProduct);
  });
  return products;
};

const addCartItem = async (cartId: string, product: AddToCartInterface) => {
  const attributes = getAttributes(product);
  const data = {
    query: ADD_CART_ITEM_MUTATION,
    variables: {
      cartId: cartId,
      lines: [
        {
          merchandiseId: product.merchId,
          quantity: product.quantity,
          attributes: attributes,
        },
      ],
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;

  const prod: [] = res.data.data.cartLinesAdd.cart.lines.edges;

  const shopifyProduct = prod.find(
    (item: any) => item.node.attributes[0].value === product.key
  );
  const newProduct = cartMapper(shopifyProduct);
  return newProduct;
};

const removeCartItem = async (cartId: string, lineId: string) => {
  const data = {
    query: REMOVE_CART_ITEM_MUTATION,
    variables: {
      cartId: cartId,
      lineIds: [lineId],
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;

  return res;
};

const renameCustomCartItem = async (
  cartId: string,
  lineId: string,
  updatedItem: ProductInterface
) => {
  const attributes = getAttributes(updatedItem);
  const data = {
    query: UPDATE_CART_ITEM_MUTATION,
    variables: {
      cartId: cartId,
      lines: {
        id: lineId,
        attributes: attributes,
      },
    },
  };

  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;

  const prod: [] = res.data.data.cartLinesUpdate.cart.lines.edges;

  const shopifyProduct = prod.find(
    (item: any) => item.node.attributes[0].value === updatedItem.key
  );

  const newProduct = cartMapper(shopifyProduct);

  return newProduct;
};

const updateCartItem = async (
  cartId: string,
  lineId: string,
  updatedItem: ProductInterface
) => {
  const attributes = getAttributes(updatedItem);
  const data = {
    query: UPDATE_CART_ITEM_MUTATION,
    variables: {
      cartId: cartId,
      lines: [
        {
          id: lineId,
          quantity: updatedItem.quantity,
          attributes: attributes,
        },
      ],
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;

  const prod: [] = res.data.data.cartLinesUpdate.cart.lines.edges;

  const shopifyProduct = prod.find(
    (item: any) => item.node.id === updatedItem.lineId
  );

  const newProduct = cartMapper(shopifyProduct);

  return newProduct;
};

const addCartNote = async (cartId: string, note: string) => {
  const data = {
    query: CART_NOTE_MUTATION,
    variables: {
      cartId: cartId,
      note: note,
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;
  return res;
};

const addCheckoutNote = async (checkoutId: string, note: string) => {
  const input = { note };
  const data = {
    query: UPDATE_CART_ATTRIBUTES,
    variables: {
      checkoutId: checkoutId,
      input: input,
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) throw res.data.errors[0].message;
  return res;
};

const CartService = {
  checkout,
  createCheckout,
  addCheckoutNote,
  checkForDiscounts,
  updateCartAttributes,
  applyDiscounts,
  fetchCart,
  fetchCustomPolishPrice,
  createCart,
  addCartItem,
  removeCartItem,
  updateCartItem,
  renameCustomCartItem,
  addCartNote,
};

export default CartService;
