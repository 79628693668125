import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  direction: string;
  color: string;
}
const GraphicDirection = ({ direction, color }: Props) => (
  <Typography
    textAlign="left"
    fontFamily="ABC Favorit Book"
    fontSize="20px"
    lineHeight="26px"
    fontWeight={300}
    color={color}
    sx={{
      maxWidth: { xs: "230px", md: "260px", xl: "275px" },
    }}
  >
    {direction}
  </Typography>
);
export default GraphicDirection;
