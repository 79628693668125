import React from "react";
import Box from "@mui/material/Box";
import { infoData } from "./CONSTANTS/info";
import MobileEdgeBlur from "./components/mobile-edge-blur";
import InfoComponent from "./components/info-component";

export const InfoBannerDesktop = () => (
  <Box
    width="100%"
    sx={{ backgroundColor: "#171717" }}
    display={{ xs: "none", lg: "flex" }}
    justifyContent="center"
    alignItems="center"
    py={4}
  >
    <Box
      width="100%"
      maxWidth={{ lg: "1100px", xl: "1200px" }}
      display="flex"
      justifyContent="center"
      px={{ lg: 0 }}
    >
      {infoData.map((item) => (
        <InfoComponent key={item.title} item={item} />
      ))}
    </Box>
  </Box>
);

export const InfoBannerMobile = () => (
  <Box width="100%" position="relative">
    <Box
      width="100%"
      sx={{
        backgroundColor: "#171717",
        overflowX: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
        cursor: "pointer",
      }}
      display={{ xs: "block", lg: "none" }}
      py={5}
      pl={{ xs: 4, sm: 6 }}
    >
      <Box width="1100px" display="flex">
        {infoData.map((item) => (
          <InfoComponent key={item.title} item={item} />
        ))}
      </Box>
    </Box>
    <MobileEdgeBlur />
  </Box>
);
