import React from "react";
import Box from "@mui/material/Box";
import { getFillImage } from "../../utils/get-upSell-background-image";

interface UpSellBackgroundProps {
  title: string;
}

const UpSellBottleBackground = ({ title }: UpSellBackgroundProps) => (
  <Box
    component="img"
    alt="circular background"
    src={getFillImage(title)}
    width="100%"
    height="100%"
    sx={{ objectFit: "contain" }}
  />
);

export default UpSellBottleBackground;
