import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CreateYourColorInterface } from "../CONSTANTS/create-ur-color";
import { ReadLess, ReadMore } from "./expand-panel-read-more-or-less";
import "../creating-your-colors.css";

interface Props {
  slide: CreateYourColorInterface;
  activeSlide: string;
  switchSlide: (slideTitle: string) => void;
}
const ExpandPanel = ({ activeSlide, switchSlide, slide }: Props) => {
  const handleCloseSlide = () => {
    if (activeSlide === slide.title) {
      switchSlide("");
    }
  };
  let captPt1 = slide.caption.split(" ").slice(0, slide.cutoff).join(" ");
  const captP2 = ` ${slide.caption.split(" ").slice(slide.cutoff).join(" ")}`;
  const smallerTextBoxHeight = `${
    parseInt(slide.contentHeight.slice(0, -2)) - 20
  }px`;

  const xsTextBoxHeight =
    activeSlide === slide.title
      ? slide.contentHeight === "120px"
        ? "121px"
        : slide.contentHeight
      : "42px";
  const smallTextBoxHeight =
    activeSlide === slide.title
      ? slide.contentHeight === "120px"
        ? "70px"
        : smallerTextBoxHeight
      : "25px";
  return (
    <Box
      className="expand-panel-container"
      position="relative"
      height={{
        xs: xsTextBoxHeight,
        sm: smallTextBoxHeight,
        md: activeSlide === slide.title ? slide.contentHeight : "40px",
      }}
      pr="5px"
      width={{
        sm: "570px",
        md: "294px",
      }}
      sx={{
        overflow: "hidden",
        transition: "all 0.5s ease-out",
      }}
    >
      <Typography
        textAlign={{ xs: "left", sm: "center", md: "left" }}
        variant="body1"
        fontSize={{
          sm: "14px",
        }}
        lineHeight="20px"
      >
        {captPt1}
        {activeSlide !== slide.title ? (
          <ReadMore
            switchSlide={() => switchSlide(slide.title)}
            title={slide.title}
          />
        ) : (
          <ReadLess
            captP2={captP2}
            activeSlide={activeSlide}
            title={slide.title}
            handleCloseSlide={handleCloseSlide}
          />
        )}
      </Typography>
    </Box>
  );
};

export default ExpandPanel;
