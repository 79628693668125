import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const AboutYourPolishTitle = () => (
  <Box
    width="100%"
    display="flex"
    justifyContent="center"
    mb={{ xs: 3.5, sm: 7 }}
  >
    <Box
      width="100%"
      display={{ xs: "flex", sm: "none" }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box mb={0.75}>
        <Typography fontFamily="Nib Pro" fontSize="38px" lineHeight="38px">
          Creating the
        </Typography>
      </Box>
      <Box>
        <Typography fontFamily="Nib Pro" fontSize="38px" lineHeight="38px">
          perfect polish
        </Typography>
      </Box>
    </Box>
    <Box
      width="100%"
      display={{ xs: "none", sm: "flex" }}
      justifyContent="center"
    >
      <Box>
        <Typography fontFamily="Nib Pro" fontSize="48px" lineHeight="38px">
          Creating the perfect polish
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default React.memo(AboutYourPolishTitle);
