import React from "react";
import Box from "@mui/material/Box";
import MakeItYoursBottle from "./make-it-yours-bottle";
import MakeItYoursInfoBox from "./make-it-yours-info-box";

const MakeItYoursDataMobile = () => (
  <Box
    display={{ xs: "flex", md: "none" }}
    justifyContent="center"
    alignItems="center"
    width="100%"
    mt={3}
  >
    <MakeItYoursBottle />
    <Box display="flex" flexDirection="column" ml={3}>
      <MakeItYoursInfoBox
        title="Any color"
        text="Create a custom range of nail polish colors - we have 10000 shades available."
      />

      <MakeItYoursInfoBox
        title="Your label"
        text="Provide your design of label and we will stick it on each bottle of the nail polish."
      />
    </Box>
  </Box>
);

export default MakeItYoursDataMobile;
