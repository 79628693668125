import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CONTACT, SHOP_POLICIES, WALMART } from "../../../routing/CONSTANTS";
import { useAppSelector } from "../../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../../pages/walmart/state/walmart-slice";

interface FooterMenuProps {
  linkNavigate: (to: string) => void;
}
const FooterMenu = ({ linkNavigate }: FooterMenuProps) => {
  const isWalmart = useAppSelector(selectIsWalmartPath);
  return (
    <Box
      sx={{
        float: "left",
        width: "100%",
        mt: "7px",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <Box
        pr={1.25}
        onClick={() => linkNavigate(`${isWalmart ? WALMART : ""}${CONTACT}`)}
        data-testid="contact-link-container"
        sx={{
          position: "relative",
          cursor: "pointer",
          "&::after": {
            position: "absolute",
            top: 0,
            right: "-2px",
            content: "'|'",
            width: "auto",
            height: "auto",
            fontSize: "16px",
            lineHeight: "22px",
            letterSpacing: 0,
            color: "black",
          },
        }}
      >
        <Typography variant="body1">Contact</Typography>
      </Box>
      <Box
        px={1.25}
        onClick={() =>
          linkNavigate(
            `${isWalmart ? WALMART : ""}${SHOP_POLICIES}/terms-of-service`
          )
        }
        data-testid="terms-link-container"
        sx={{
          position: "relative",
          cursor: "pointer",
          "&::after": {
            position: "absolute",
            top: 0,
            right: "-2px",
            content: "'|'",
            width: "auto",
            height: "auto",
            fontSize: "16px",
            lineHeight: "22px",
            letterSpacing: 0,
            color: "black",
          },
        }}
      >
        <Typography variant="body1">Terms</Typography>
      </Box>
      <Box
        pl={1.25}
        sx={{ cursor: "pointer" }}
        onClick={() =>
          linkNavigate(
            `${isWalmart ? WALMART : ""}${SHOP_POLICIES}/privacy-policy`
          )
        }
        data-testid="privacy-link-container"
      >
        <Typography variant="body1">Privacy</Typography>
      </Box>
    </Box>
  );
};
export default FooterMenu;
