import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

interface SkeletonProps {
  size: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
}
const ProductSkeleton = ({ size }: SkeletonProps) => (
  <Box width={size} height={size}>
    <Skeleton animation="wave" variant="circular" height="100%" width="100%" />
  </Box>
);

export default ProductSkeleton;
