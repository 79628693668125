import { useInternalCanvasTestHook } from "../../utils/hooks/useInternalCanvasTestHook";
import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ProductDetails from "./components/product-details.component";
import CustomProductBottleImage from "../../components/ico-bottle-images/custom-product-bottle-image";
import CartService from "../cart/services/cart-service";
import ProductSkeleton from "./components/product-skeleton";
import SEO from "../../components/seo";
import PageNotFound from "../../routing/page-not-found";
import { useLocation } from "react-router-dom";
import { useGetProductCustomPolishColor } from "./hooks/useGetProductCustomPolishColor";
import { trackViewedProduct } from "../../components/klaviyo";

import "./product.css";

interface LocationState {
  polishName: string;
  colorName?: string;
}
interface Props {
  color: string;
}
const CustomProductPage = ({ color }: Props) => {


  const [customPolishPrice, setCustomPolishPrice] = useState<number>(0);
  const [isLoadingPrice, setIsLoadingPrice] = useState<boolean>(true);
  const { hue, sat, val, isLoading, isSuccess } =
    useGetProductCustomPolishColor(color);
  const location = useLocation();
  const state = location.state as LocationState;
  const isInternalTest = useInternalCanvasTestHook()
  const getPolishName = useCallback(() => {
    if (!state) {
      return `Custom ${isInternalTest ? 'Shade' :'Polish'}`;
    } else if (state && state.colorName && state.colorName === "Hex value") {
      return `Custom ${isInternalTest ? 'Shade' : 'Polish'}`;

    } else {
      const { polishName } = state;
      return polishName;
    }
  }, [state,isInternalTest]);

  const [name, setName] = useState<string>(getPolishName());
  const colorData = [hue, sat, val];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  useEffect(() => {

    setName(getPolishName());
    trackViewedProduct({ value: getPolishName(), price: customPolishPrice,productId:color })

  }, [color, getPolishName,customPolishPrice]);

  useEffect(() => {

    CartService.fetchCustomPolishPrice().then((res) => {

      setCustomPolishPrice(res)
      setIsLoadingPrice(false)
    }
    );
  }, []);
  useEffect(() => {

   
  }, []);



  const errorDisplay = <PageNotFound />;

  if (!isSuccess) return <PageNotFound />;
  const successDisplay = (
    <Box py={{ sm: "4rem", md: "3rem" }} bgcolor="secondary.main">
      <SEO
        title="Create Your Custom Nail Polish Color"
        description="Custom Nail Polish. Long-lasting, chip-resistant, high gloss finish. Easy to apply and full coverage that lasts for days. Made to order in the USA."
        type="website"
        urlEndpoint={`/products/${color}`}
      />
      <Box
        className="product-page-container"
        p={{
          sm: 3,
        }}
        display="flex"
        justifyContent="center"
        flexDirection={{ xs: "column", sm: "row" }}
        position="relative"
        maxWidth="1400px"
        m="0 auto"
      >
        <Box
          display="flex"
          justifyContent="center"
          mt={{
            xs: "40px",
            sm: "75px",
          }}
          mb={{ xs: "12px", sm: 0 }}
          width={{ sm: "55%", md: "41%" }}
          position="relative"
        >
          {isLoading ? (
            <ProductSkeleton
              size={{
                xs: "287px",
                sm: "367px",
                md: "437px",
                lg: "477px",
                xl: "554px",
              }}
            />
          ) : (
            <CustomProductBottleImage
              hue={colorData[0]}
              sat={colorData[1]}
              val={colorData[2]}
            />
          )}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          width={{ xs: "100%", sm: "50%", md: "30%" }}
          mt={{ xs: "10px", sm: 0 }}
          ml={{ sm: "70px", md: "100px" }}
        >
          <ProductDetails
            name={name}
            polishPrice={customPolishPrice}
            handleChange={handleChange}
            customPolish
            variants={[]}
            colorData={{
              hue: colorData[0],
              sat: colorData[1],
              val: colorData[2]
            }}

            colorId={color}
            isLoading={isLoading}
            isLoadingPrice={isLoadingPrice}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {isLoading && <Box></Box>}
      {isSuccess && successDisplay}
      {!isSuccess && errorDisplay}
    </>
  );
};

export default React.memo(CustomProductPage);
