export const PRODUCT_COUNT_QUERY = `

query 	queryCollectionProductCount(
	$handle: String!
	$after: String
	$sortKey: ProductCollectionSortKeys! 
	$filters: [ProductFilter!]
	$reverse:Boolean! 
){
  collection (handle:$handle) {
    title
    products (
			first: 50 
			after: $after
			sortKey:$sortKey 
      reverse:$reverse
      filters:$filters
		) { 
      edges {
				cursor
				
				node {
					title
				}
       
      }
			pageInfo {  
				hasNextPage

			}
    }
  }
}
`;
