export const POLISH_PRICE_QUERY = `
{
  product(handle: "custom-standard-polish") {
    id
    title
    description
    variants(first: 3) {
      edges {
        cursor
        node {
          id
          title
          quantityAvailable
          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
}

`;
