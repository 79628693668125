import React, { FormEvent } from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface Props {
  defaultName: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick: (e: FormEvent) => void;
}
const CustomPolishNamerInput = ({
  defaultName,
  handleChange,
  handleClick,
}: Props) => (
  <TextField
    variant="standard"
    defaultValue={defaultName}
    onChange={handleChange}
    sx={{
      width: "100%",
      maxWidth: { xs: "147px", sm: "320px", md: "215px" },
    }}
    inputProps={{
      style: {
        padding: "1px 0 5px",
      },
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClick}
            edge="end"
          >
            <CheckCircleOutlineIcon htmlColor="black" />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export default CustomPolishNamerInput;
