import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import UpSellCard from "./upSellCard";
import { useGetUpSellProductsQuery } from "../../cart/hooks/useGetUpSellProductsQuery";

interface PAndPProps {
  landing: boolean;
}
const ProtectAndPerfect = ({ landing }: PAndPProps) => {
  const { upSellData } = useGetUpSellProductsQuery();


  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      width="100%"
      bgcolor={landing ? "transparent" : "secondary.main"}
      px={{ xs: 0.25, sm: 1, md: 2 }}
    >
      <Box
        width={{ xs: "100%", md: "85%", lg: "80%", xl: "70%" }}
        p={{ xs: "35px 5px 27px", md: "50px 20px" }}
        borderRadius="20px"
        sx={{ backgroundColor: "#dedede" }}
      >
        <Box width="100%" textAlign="center" mb={2} px={3}>
          <Typography
            variant="h3"
            fontSize={{ xs: "38px", sm: "42px", md: "48px" }}
            lineHeight={{ xs: "38px", sm: "42px", md: "48px" }}
          >
            Protect and strengthen
          </Typography>
        </Box>
        <Box mb={{ xs: 8, md: 11 }} width="100%" px={{ xs: 3, md: 0 }}>
          <Typography
            variant="h4"
            textAlign="center"
            fontSize={{ xs: "20px", sm: "22px", md: "24px" }}
            lineHeight={{ xs: "27px", sm: "22px", md: "32px" }}
          >
            Say no to chipping and peeling with our base and top coats.
          </Typography>
        </Box>
        <Box
          p={{ xs: 0, md: "20px 0 0 0" }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignContent="center"
          rowGap={{ md: '8rem', xs: '4rem', sm: '5rem' }}
        >
          {upSellData.map((item) => (
            <UpSellCard key={item.merchId} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default ProtectAndPerfect;
