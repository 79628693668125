import React from "react";
import Box from "@mui/material/Box";
import desktopPhone from "../../../assets/openEndorseAssets/endorserPhoneDesk.png";
import mobilePhone from "../../../assets/openEndorseAssets/endorserPhonMobile.png";

const EndorserPhone = () => (
  <Box
    width="100%"
    height={{ xs: "100%", sm: "694px" }}
    position="absolute"
    display="flex"
    flexDirection="column"
    justifyContent={{ xs: "flex-end", md: "center" }}
    alignItems="center"
    top="0"
    left={{ xs: "50px", sm: "33px", md: "75px" }}
    mt={{ md: "-23px" }}
  >
    <PhoneDesktop />
    <PhoneMobile />
  </Box>
);

const PhoneDesktop = () => (
  <Box
    display={{ xs: "none", md: "block" }}
    component="img"
    height="900px"
    sx={{ objectFit: "cover" }}
    src={desktopPhone}
  />
);

const PhoneMobile = () => (
  <Box
    display={{ xs: "block", md: "none" }}
    component="img"
    height={{ xs: "430px", sm: "700px" }}
    sx={{ objectFit: "cover" }}
    src={mobilePhone}
  />
);

export default EndorserPhone;
