import { IconButton } from "@mui/material";
import React from "react";
import { SocialsInterface } from "../interfaces/socials.interface";

interface SocialLinkProps {
  socials: SocialsInterface;
}

const SocialLink = ({ socials }: SocialLinkProps) => {
  const handleSocialsNavigation = () => {
    window.location.replace(socials.link);
  };
  return (
    <IconButton
      aria-label={`${socials.name} link`}
      sx={{ pl: 0 }}
      onClick={handleSocialsNavigation}
    >
      {socials.icon}
    </IconButton>
  );
};
export default SocialLink;
