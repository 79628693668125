import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import { useCheckMobileScreen } from "../../../utils/hooks/useCheckMobileScreen";
import { ImageContext } from "../state/image.context";
import "../color-picker.styles.css";
import { useKioskHook } from "../../../utils/hooks/useKioskHook";
const Canvas = () => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const zoomCanvas = useRef<HTMLCanvasElement>(null);
  const [isCanvas, setIsCanvas] = useState<boolean>(false);
  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const [canvasHeight, setCanvasHeight] = useState<number>(0);

  const { drawCanvas, url, getPixelData, magX, magY } =
    useContext(ImageContext);

  const { isMobile } = useCheckMobileScreen();
  const size = isMobile ? "mobile" : "desktop";

  const isKiosk = useKioskHook();

  const getColorCallback = useCallback(() => {
    getPixelData(canvas, zoomCanvas, size, isKiosk);
  }, [canvas, getPixelData, size, isKiosk]);

  useEffect(() => {
    const initCanvas = () => {
      drawCanvas(canvas, url, size, isKiosk);
      setIsCanvas(true);
    };
    initCanvas();
  }, [url, drawCanvas, size, isKiosk]);

  useEffect(() => {
    if (canvas.current) {
      // Delay the measurement to ensure styles are applied
      setTimeout(() => {
        window.requestAnimationFrame(() => {
          setCanvasWidth(canvas.current!.offsetWidth);
          setCanvasHeight(canvas.current!.offsetHeight);
        });
      }, 100); // 100ms delay
    }
  }, [canvas, canvasWidth, canvasHeight, url]);

  useEffect(() => {
    if (isCanvas) {
      getColorCallback();
    }
    return () => window.removeEventListener("pointermove", getColorCallback);
  }, [isCanvas, getColorCallback]);

  return (
    <>
      <CanvasContainer size={size} canvas={canvas} isMobile={isMobile} />
      <MagnifierContainer
        zoomCanvas={zoomCanvas}
        magX={magX}
        magY={magY}
        size={size}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
      />
    </>
  );
};

const CanvasContainer = ({
  canvas,
  isMobile,
  size,
}: {
  canvas: React.RefObject<HTMLCanvasElement>;
  isMobile: boolean;
  size: "desktop" | "mobile";
}) => (
  <canvas
    ref={canvas}
    id={'primaryCanvas'}
    className={`disable-text-selection ${isMobile ? "" : "canvas"}`}
    style={{
      margin: (() => {
        if (size === "mobile") {
          return "auto";
        }
        return 0;
      })(),
    }}
  />
);

const MagnifierContainer = ({
  zoomCanvas,
  magX,
  magY,
  size,
  canvasWidth,
  canvasHeight,
}: {
  zoomCanvas: React.RefObject<HTMLCanvasElement>;
  magX: number;
  magY: number;
  size: "desktop" | "mobile";
  canvasWidth: number;
  canvasHeight: number;
}) => {
  const isKiosk = useKioskHook();

  const position = isKiosk ? "relative" : "absolute";

  return (
    <canvas
      ref={zoomCanvas}
      height={125}
      width={125}
      id={"zoomCanvas"}
      style={{
        width: 125,
        height: 125,
        position: position,
        top: (() => {
          if (isKiosk) {
            return magY - canvasHeight;
          }
          if (magY === 0) {
            return magY;
          }

          return magY;
        })(),
        left: (() => {
          return magX;
        })(),
        borderRadius: "50%",
        border: "3px solid black",
        pointerEvents: "none",
      }}
    />
  );
};

export default Canvas;
