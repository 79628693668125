import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LoaderSkeleton from "./components/loader-skeleton";
import ColorLibraryCard from "./components/color-library-card.component";
import BlankAppbar from "../../../components/blank-appbar/blank-appbar";
import { useGetBlankLibraryListQuery } from "./hooks/useGetBlankLibraryListQuery";
import { usePixelServiceOnLoad } from "../../../services/pixels/usePixelService";

interface Props {
  isWalmart?: boolean;
}
const ColorLibraryListPage = ({ isWalmart = false }: Props) => {
  const { data, isLoading } = useGetBlankLibraryListQuery();
  usePixelServiceOnLoad();
  return (
    <Box bgcolor="secondary.main" p={2} height={{ md: "100vh" }}>
      <BlankAppbar isWalmartComponent={isWalmart} />
      <Box py="1rem" pt={{ xs: 11, md: 10 }}>
        <Typography align="center" variant="h3">
          All palettes
        </Typography>
      </Box>
      <Box mt={3} m="24px auto 0 auto" maxWidth="1400px">
        <Grid
          container
          justifyContent="center"
          spacing={{ xs: 5, md: 6 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {isLoading &&
            Array.from(new Array(9)).map((_, idx) => (
              <Grid key={idx} item xs={12} sm={4} md={4}>
                <LoaderSkeleton />
              </Grid>
            ))}

          {data.map((item) => (
            <Grid key={item.title} item xs={12} sm={4} md={4}>
              <ColorLibraryCard key={item.title} cardItem={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ColorLibraryListPage;
