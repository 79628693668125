export const FETCH_PRODUCT_ID_BY_HANDLE_QUERY = `
  query queryProductByHandle($handle: String!){
  product(handle: $handle) {
    id
		handle
    title
    variants(first: 3) {
      edges {
        cursor
        node {
          id
          title
          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
}
`;
