import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandedResults from "./expanded-results";
import { Collapse } from "@mui/material";

interface Props {
  isWalmartComponent?: boolean;
}

const GetTheBestResults = ({ isWalmartComponent }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpandInfo = () => {
    setIsExpanded(true);
  };
  const handleCloseInfo = () => {
    setIsExpanded(false);
  };
  return (
    <Box
      mt={{ xs: 5, sm: 6, md: 3 }}
      mx="auto"
      px={{ xs: 2, sm: 4, md: 0 }}
      width="100%"
      maxWidth="885px"
      display="flex"
      justifyContent="center"
    >
      <Box
        onClick={isExpanded ? handleCloseInfo : handleExpandInfo}
        width="100%"
        display="flex"
        flexDirection="column"
        borderRadius="5px"
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.25)",
          cursor: "pointer",
        }}
      >
        <Box
          p="17.5px 20px"
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography
              fontFamily="ABC Favorit Book"
              fontSize="20px"
              lineHeight="22px"
              color={isWalmartComponent ? "white" : "black"}
            >
              How to get the best results
            </Typography>
          </Box>
          <Box>
            {isExpanded ? (
              <IconButton aria-label="close panel" onClick={handleCloseInfo}>
                <RemoveIcon
                  fontSize="large"
                  htmlColor={isWalmartComponent ? "white" : "black"}
                />
              </IconButton>
            ) : (
              <IconButton aria-label="open panel" onClick={handleExpandInfo}>
                <AddIcon
                  fontSize="large"
                  htmlColor={isWalmartComponent ? "white" : "black"}
                />
              </IconButton>
            )}
          </Box>
        </Box>
        <Collapse in={isExpanded}>
          <ExpandedResults isWalmartComponent={isWalmartComponent} />
        </Collapse>
      </Box>
    </Box>
  );
};
export default GetTheBestResults;
