import React from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../pages/walmart/state/walmart-slice";

interface PhotosIconProps {
  iconColor: "black" | "white";
}
const BlankPhotosIcon = ({ iconColor }: PhotosIconProps) => {
  const isWalmart = useAppSelector(selectIsWalmartPath);
  const walmartBlue = "#041E42";

  const iconColorConfig:{
    black: 'black',
    white:'black' | 'white'
  } = {
    black:'black',
    white: isWalmart ? 'black':'white'
  }

  return (
    <Box
      className="photos-icon-ring"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={0.2}
      mr={1}
      border={`2px solid ${iconColorConfig[iconColor]}`}
      borderRadius="50%"
      height="48px"
      width="48px"
    >
      <Box position="absolute" top="11px">
        <svg
          width="31"
          height="26"
          viewBox="0 0 31 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="photos-icon-inner-box"
            d="M28 1.26001H3.44897V22.6095H28V1.26001Z"
            fill={isWalmart ? walmartBlue : 'white'}
            stroke={isWalmart ? walmartBlue : 'white'}
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            className="photos-icon-ring"
            d="M18.7184 11.0723C20.3878 11.0723 21.7412 9.71899 21.7412 8.04958C21.7412 6.38017 20.3878 5.02686 18.7184 5.02686C17.049 5.02686 15.6957 6.38017 15.6957 8.04958C15.6957 9.71899 17.049 11.0723 18.7184 11.0723Z"
            stroke={isWalmart ? '#FDC01F' : 'black'}
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            className="photos-icon-ring"
            d="M1 24.8268L10.0951 12L18.2505 19.9252L21.9886 16.229L30 25"
            stroke={isWalmart ? '#FDC01F' : 'black'}
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </Box>
    </Box>
  )
};
export default BlankPhotosIcon;
