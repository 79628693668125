import React, { useState } from "react";
import Box from "@mui/material/Box";
import { NeedToKnowInterface } from "../interfaces/need-to-know.interface";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

interface Props {
  needToKnowData: NeedToKnowInterface;
}
const NeedToKnowDropDown = ({ needToKnowData }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpandInfo = () => {
    setIsExpanded(true);
  };
  const handleCloseInfo = () => {
    setIsExpanded(false);
  };
  return (
    <Box
      onClick={isExpanded ? handleCloseInfo : handleExpandInfo}
      width={{ xs: "100%", md: "775px" }}
      display="flex"
      flexDirection="column"
      borderRadius="5px"
      mb={1.25}
      sx={{
        backgroundColor: needToKnowData.dropdownColor,
        cursor: "pointer",
      }}
    >
      <Box
        p="11px 20px"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography
            fontFamily="ABC Favorit Book"
            fontStyle="italic"
            fontSize={{ xs: "14px", sm: "20px" }}
            lineHeight="20px"
          >
            {needToKnowData.title}
          </Typography>
        </Box>
        <Box>
          {isExpanded ? (
            <IconButton aria-label="close panel" onClick={handleCloseInfo}>
              <RemoveIcon fontSize="large" htmlColor="black" />
            </IconButton>
          ) : (
            <IconButton aria-label="open panel" onClick={handleExpandInfo}>
              <AddIcon fontSize="large" htmlColor="black" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default NeedToKnowDropDown;
