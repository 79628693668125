export const CUSTOM_POLISH_MERCH_ID =
  "gid://shopify/ProductVariant/41533697786042";

export const BASE_COAT_PRODUCT_ID = "gid://shopify/Product/7185306190010";
export const MATT_TOP_COAT_PRODUCT_ID = "gid://shopify/Product/7462714605754";
export const BASE_COAT_MERCH_ID = "gid://shopify/ProductVariant/41784127619258";

export const TOP_COAT_MERCH_ID = "gid://shopify/ProductVariant/41784127750330";
export const TOP_COAT_PRODUCT_ID = "gid://shopify/Product/7185306288314";
export const CUTICLE_OIL_PRODUCT_ID = "gid://shopify/Product/7617435369658";
export const CUTICLE_OIL_MECH_ID = "gid://shopify/ProductVariant/43268230054074";
export const MATT_TOP_COAT_MERCH_ID ="gid://shopify/ProductVariant/42718841635002"

export const PEARL_TOP_COAT_PRODUCT_ID = "gid://shopify/Product/7185306124474";
export const PEARL_TOP_COAT_MERCH_ID =
  "gid://shopify/ProductVariant/41784127553722";
