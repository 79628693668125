import React from "react";
import Box from "@mui/material/Box";
import HeroEndorserInfo from "./hero-endorser-info";
import ProgressiveImage from "../../../components/progressive-image";

interface Props {
  title: string;
  text: string;
  desktopImg: string;
  bgColor: string;
}
const DeskTopHero = ({ title, text, desktopImg, bgColor }: Props) => (
  <Box display={{ xs: "none", sm: "flex" }} flexDirection="column">
    <ProgressiveImage
      src={desktopImg}
      placeholderSrc="https://via.placeholder.com/828x625?text="
      alt={title}
      screen="desktop"
      maxHeight="625px"
    />
    <HeroEndorserInfo title={title} text={text} bgColor={bgColor} />
  </Box>
);
export default DeskTopHero;
