import { createTheme, Shadows } from "@mui/material/styles";

export const blankTheme = createTheme({
  palette: {
    primary: {
      main: "rgba(25,25,26,0.96)",
    },
    secondary: {
      main: "#e7e7e7",
      light: "white",
    },
    walmart: {
      main: "white",
      walmartBlue: "#0071DC",
      walmartYellow: "#FDC01F",
    },
    ctaButton: {
      main: "rgba(25,25,26,0.96)",
    },
    badge: {
      main: "#9F37DC",
      contrastText: "white",
    },
  },
  shadows: Array(25).fill("none") as Shadows,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1700,
    },
  },
  typography: {
    allVariants: {
      color: "inherit",
    },
    h1: {
      fontFamily: "Nib Pro",
    },
    h2: {
      fontFamily: "ABC Favorit Book",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Nib Pro",
      color: "inherit",
    },
    h4: {
      fontFamily: "ABC Favorit Lining",
    },
    h5: {
      fontFamily: "ABC Favorit",
    },
    h6: {
      fontFamily: "Nib Pro",
    },
    subtitle1: {
      fontFamily: "ABC Favorit",
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: "ABC Favorit",
    },
    body1: {
      fontFamily: "ABC Favorit Book",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "ABC Favorit",
    },
    button: {
      fontFamily: "ABC Favorit Book",
      fontSize: "1rem",
      textTransform: "none",
    },
    caption: {
      fontFamily: "ABC Favorit",
    },
    overline: {
      fontFamily: "ABC Favorit",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        disableElevation: true,
        root: {
          backgroundColor: "#000000",
          borderRadius: 35,
          border: "2px solid black",
          lineHeight: "22px",
          transition: "all 0.3s ease-in",
          "&:hover": {
            backgroundColor: "white",
            color: "black",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          ":focus": {
            border: "0px transparent",
            outline: "none",
          },
          ":focus-within": {
            border: "0px transparent",
          },
          ":focus-visible": {
            border: "0px transparent",
            outline: "none",
          },
          forcedColorAdjust: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ":focus": {
            notchedOutline: {
              border: "none",
            },
          },
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    ctaButton: Palette["primary"];
    badge: Palette["primary"];
    walmart: Palette["primary"];
  }
  interface PaletteOptions {
    ctaButton?: PaletteOptions["primary"];
    badge?: PaletteOptions["primary"];
    walmart?: PaletteOptions["primary"];
  }
  interface SimplePaletteColorOptions {
    walmartBlue?: string;
    walmartYellow?: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    ctaButton: true;
    badge: true;
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides {
    badge: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}
