import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  direction: string;
  walmart?: boolean;
}
const GraphicDirection = ({ direction, walmart }: Props) => (
  <Typography
    textAlign="left"
    fontFamily="ABC Favorit Book"
    fontSize="20px"
    lineHeight="26px"
    fontWeight={300}
    color={walmart ? "white" : "black"}
    sx={{
      maxWidth: { xs: "230px", md: "260px", xl: "275px" },
    }}
  >
    {direction}
  </Typography>
);
export default GraphicDirection;
