import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  slideNumber: number;
  bg: "black" | "white" | "walmart.walmartYellow";
}
const SlideRoundlet = ({ slideNumber, bg }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    width="30px"
    height="30px"
    borderRadius="50%"
    sx={{ backgroundColor: bg }}
  >
    <Typography
      variant="body2"
      fontSize={{ xs: "16px", sm: "20px" }}
      lineHeight="0"
      color={bg === "black" ? "white" : "black"}
      fontWeight="350"
      sx={{ mt: "2px" }}
    >
      {slideNumber}
    </Typography>
  </Box>
);

export default SlideRoundlet;
