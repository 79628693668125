export const SINGLE_COLLECTION_QUERY = `
query 	queryCollection($handle: String!, $sortKey: ProductCollectionSortKeys!, $reverse:Boolean!, $filters: [ProductFilter!] ){
  collection (handle:$handle) {
    id
    title
    description
    handle
    image {url}
    metafield(namespace:"custom_fields" key:"mob_image") {
      value
   }
    products (
      first:20 
      sortKey:$sortKey 
      reverse:$reverse
      filters:$filters
      ) { 
			
      edges {
				
				cursor
        node {
					 id
           handle
          title
          seo { title description }
          tags
					metafield(namespace: "custom_fields" key: "image") { 
						value
					}
					variants(first: 2) {
      edges {
				
        cursor
        node {
          id
          title
          
				
          price {
            amount
            currencyCode
          }
        }
      }
    }
#           images (first:1) {
#             edges {
#               node {
#                 id
#                 transformedSrc
#                 width
#                 height
#               }
#             }
#           }

        }
      }
			pageInfo { 
				hasNextPage
				hasPreviousPage
			}
    }
  }
}`;
