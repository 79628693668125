import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CreateYourColorsTitle = () => {
  return (
    <>
      <TitleDesktop />
      <TitleMobile />
    </>
  );
};

export default React.memo(CreateYourColorsTitle);

const TitleDesktop = () => (
  <Box
    width={{ xs: "100%", md: "40%" }}
    display={{ xs: "none", sm: "flex" }}
    flexDirection="column"
    mt={4}
    mb={6}
  >
    <Box px={2} mb={0.25}>
      <Typography
        textAlign="center"
        variant="h3"
        fontSize={{ xs: "38px", md: "38px" }}
        lineHeight="38px"
      >
        Custom color you say?
      </Typography>
    </Box>
    <Box>
      <Typography
        textAlign="center"
        variant="h3"
        fontSize={{ xs: "28px", sm: "38px" }}
        lineHeight="38px"
      >
        Here's how it works:
      </Typography>
    </Box>
  </Box>
);

const TitleMobile = () => (
  <Box display={{ xs: "flex", sm: "none" }} flexDirection="column" my={4}>
    <Box mb={0.25}>
      <Typography
        textAlign="center"
        variant="h3"
        fontSize="38px"
        lineHeight="38px"
      >
        Custom color,
      </Typography>
    </Box>
    <Box mb={0.25}>
      <Typography
        textAlign="center"
        variant="h3"
        fontSize="38px"
        lineHeight="38px"
      >
        you say? Here's
      </Typography>
    </Box>
    <Box mb={0.25}>
      <Typography
        textAlign="center"
        variant="h3"
        fontSize="38px"
        lineHeight="38px"
      >
        how it works:
      </Typography>
    </Box>
  </Box>
);
