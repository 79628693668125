export const APPLY_SHIPPING_DISCOUNT_MUTATION = `
mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!] ) {
  cartDiscountCodesUpdate(cartId: $cartId, discountCodes:$discountCodes) {
    cart {
      id
			discountCodes{
				applicable
				code
			}
     
    }
  }
}
`;
