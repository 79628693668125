import { ReturnInstructionsInterface } from "../interface/return-instructions-interface";

export const returnInstructions: ReturnInstructionsInterface[] = [
  {
    step: 1,
    instruction: "Pack up the unwanted products",
  },
  {
    step: 2,
    instruction:
      "Send us a picture of a failed match - and we will send you a prepaid shipping label in return!",
  },
  {
    step: 3,
    instruction:
      "Print the the shipping label and stick it onto the return parcel",
  },
  {
    step: 4,
    instruction:
      "Once we receive the products, we will refund 100% of the product cost!",
  },
];
