import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import PixelService from "../../../services/pixels/pixel-service";
import {
  CollectionProductInterface,
  VariantInterface,
} from "../../shop-all/interfaces/collection-interfaces";
import { initialProductState } from "../CONSTANTS/initial-product-state";
import ProductService from "../services/product-service";

export const useGetShopifyProduct = (param: string) => {
  const [product, setProduct] =
    useState<CollectionProductInterface>(initialProductState);
  const [activeVariant, setActiveVariant] = useState<VariantInterface>(
    product.variants[0]
  );
  const [isSuccess, setIsSuccess] = useState<boolean>(true);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const product = await ProductService.fetchProduct(param);
        if (product) {
          setProduct(product);
          const variant = product.variants[0];
          setActiveVariant(variant);
          PixelService.pixelViewContent(
            product.handle,
            parseInt(variant.price)
          );
        }
      } catch (err) {
        setIsSuccess(false);
        Sentry.captureException(err);
      }
    };
    getProduct();
  }, [param]);

  return { product, activeVariant, setActiveVariant, isSuccess };
};
