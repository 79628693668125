import React from "react";
import Box from "@mui/material/Box";
import InquiriesFormText from "./components/Inquiries-form-text";
import InquiriesInput from "./components/inquiries-input";

const Inquiries = () => (
  <Box
    width="100%"
    py={7}
    sx={{ backgroundColor: "white" }}
    display="flex"
    justifyContent="center"
    alignItems={{ xs: "center", md: "flex-start" }}
    flexDirection={{ xs: "column", md: "row" }}
  >
    <InquiriesFormText />
    <InquiriesInput />
  </Box>
);
export default Inquiries;
