import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LibraryColorListInterface } from "../interfaces/library-color-list.interface";
import ColorLibraryListService from "../services/color-library-list-service";
import * as Sentry from "@sentry/react";

export const fetchBlankLibraries = createAsyncThunk(
  "library/colorList",
  async (_, { rejectWithValue }) => {
    try {
      const pink = ColorLibraryListService.getBlankLibraries("Pink");
      const purple = ColorLibraryListService.getBlankLibraries("Purple");
      const red = ColorLibraryListService.getBlankLibraries("Red");
      const blue = ColorLibraryListService.getBlankLibraries("Blue");
      const green = ColorLibraryListService.getBlankLibraries("Green");
      const yellow = ColorLibraryListService.getBlankLibraries("Yellow");
      const orange = ColorLibraryListService.getBlankLibraries("Orange");
      const nude = ColorLibraryListService.getBlankLibraries("Nude");
      const grey = ColorLibraryListService.getBlankLibraries("Grey");
      const libraryList: LibraryColorListInterface[] = [];
      await axios
        .all([pink, purple, red, blue, green, yellow, orange, nude, grey])
        .then(
          axios.spread((...res) => {
            const pinkLib = res[0];
            libraryList.push(pinkLib);
            const purpleLib = res[1];
            libraryList.push(purpleLib);
            const redLib = res[2];
            libraryList.push(redLib);
            const blueLib = res[3];
            libraryList.push(blueLib);
            const greenLib = res[4];
            libraryList.push(greenLib);
            const yellowLib = res[5];
            libraryList.push(yellowLib);
            const orangeLib = res[6];
            libraryList.push(orangeLib);
            const nudeLib = res[7];
            libraryList.push(nudeLib);
            const greyLib = res[8];
            libraryList.push(greyLib);
          })
        );
      return libraryList;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);
