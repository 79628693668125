import React from "react";
import Box from "@mui/material/Box";
import HeroTextDesktop from "./hero-text-desktop";
import HeroTextMobile from "./hero-text-mobile";

const HeroText = () => (
  <>
    <Box width="100%" display={{ xs: "none", sm: "block" }}>
      <HeroTextDesktop />
    </Box>
    <Box width="100%" maxWidth="225px" display={{ xs: "block", sm: "none" }}>
      <HeroTextMobile />
    </Box>
  </>
);

export default HeroText;
