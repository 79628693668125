import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HeroText from "./hero-text/hero-text";
import { useNavigate } from "react-router-dom";
import { CREATE_YOUR_COLOR } from "../../../../routing/CONSTANTS";

interface Props {
  handleNavigateToAllCollections: () => void;
  isWalmart: boolean | undefined;
}
const YourInspo = ({ handleNavigateToAllCollections, isWalmart }: Props) => {
  const navigate = useNavigate();

  const handleNavigateToCreateYourColor = () => {
    navigate(CREATE_YOUR_COLOR);
  };

  const handleNavigateToWalmartCreateYourColor = () => {
    navigate(`/walmart${CREATE_YOUR_COLOR}`);
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      justifyContent={{ xs: "center" }}
      px={{ md: 3 }}
      mt={{ xs: "21px", sm: 0 }}
    >
      <Box
        display="flex"
        justifyContent={{ xs: "center" }}
        width="100%"
        mb={{ xs: 2, sm: 5 }}
        px={{ xs: 4, sm: 0 }}
      >
        <HeroText />
      </Box>

      <Box
        width="100%"
        maxWidth={{ xs: "250px", sm: "450px", xl: "500px" }}
        mb={{ xs: 2, sm: 5 }}
      >
        {!isWalmart && (
          <Typography
            variant="h4"
            fontSize={{ xs: "18px", sm: "2.502vw", lg: "32px" }}
            display={{ xs: "none", sm: "block" }}
            lineHeight={{ xs: "24px", sm: "43px" }}
            textAlign={{ xs: "center", sm: "left" }}
          >
            Create your own custom nail polish shades in 3 easy steps!
          </Typography>
        )}
      </Box>

      <Box width="100%" maxWidth={{ xs: "275px", sm: "290px" }} zIndex={10}>
        <Button
          onClick={
            isWalmart
              ? handleNavigateToWalmartCreateYourColor
              : handleNavigateToCreateYourColor
          }
          fullWidth
          variant="contained"
          sx={{
            pt: { xs: "9.5px", sm: "10.5px" },
            pb: { xs: "9.5px", sm: "10.5px" },
          }}
        >
          create your own polish+
        </Button>
      </Box>
    </Box>
  );
};
export default YourInspo;
