import { createAsyncThunk } from "@reduxjs/toolkit";
import { BlankDatabaseInterface } from "../interface/database.interface";
import colorPickerService from "../services/blank-color-service";
import * as Sentry from "@sentry/react";
import { RequestState } from "../../../services/types/requestState.type";
import { THsv } from "../interface/color-data.interface";

export const fetchBlankColor = createAsyncThunk(
  "color/fetchColor",
  async (hsv: THsv, { rejectWithValue }) => {
    const { h, s, v } = hsv;
    try {
      const response = await colorPickerService.fetchColor(h, s, v);
      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

export const fetchFullDatabase = createAsyncThunk<
  BlankDatabaseInterface[],
  void,
  { state: { colorPicker: { dbStatus: RequestState | undefined } } }
>(
  "color/colorDB",
  async (_, { rejectWithValue }) => {
    try {
      const response = await colorPickerService.fetchFullDB();
      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => {
      const { colorPicker } = getState();
      const fetchStatus = colorPicker.dbStatus;
      if (fetchStatus === "fulfilled" || fetchStatus === "pending") {
        return false;
      }
    },
  }
);
