import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
  icon: JSX.Element;
  buttonText: string;
  navFunction: () => void;
}

const PalettesJourneyButton = ({ icon, buttonText, navFunction }: Props) => (
  <Box
    onClick={navFunction}
    width="335px"
    pl={1}
    display="flex"
    justifyContent="start"
    alignItems="center"
    sx={{ cursor: "pointer" }}
  >
    <Box
      mt={0.25}
      display="flex"
      justifyContent="center"
      alignItems="center"
      mr={1}
    >
      {icon}
    </Box>
    <Box mb={0.5}>
      <Typography sx={{ textDecoration: "underline" }}>{buttonText}</Typography>
    </Box>
  </Box>
);
export default PalettesJourneyButton;
