import React from "react";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";

type Props = {
  src: string;
  fallback: string;
  [rest: string]: any;
  sx?: SxProps | undefined;
};

const ImgWithPngFallback = ({
  src,
  fallback,
  alt,
  sx = { objectFit: "cover" },
  ...rest
}: Props) => {
  return (
    <Box component="picture">
      <Box component="source" srcSet={src} type="image/webp" />
      <Box component="img" alt={alt} src={fallback}  sx={sx} {...rest} />
    </Box>
  );
};

export default ImgWithPngFallback;
