import React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { CREATE_YOUR_COLOR } from "../../../../routing/CONSTANTS";
import CreateAColorButton from "./create-your-color-btn";
import CreateYourColorCloud from "./create-your-color-cloud";

const CreateYourColor = () => {
  const navigate = useNavigate();
  const handleColorCreateNavigate = () => {
    navigate(CREATE_YOUR_COLOR);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        pl: { xs: 0.25, md: 0 },
        pr: { xs: 0.25, md: 0 },
        pt: { xs: "17px", md: "5%" },
        pb: { xs: "39px", md: "10%" },
        textAlign: "center",
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        alignItems: "space-between",
      }}
      onClick={handleColorCreateNavigate}
    >
      <Box position="relative" width="100%">
        <Box
          width="100%"
          left="0"
          bottom="0"
          textAlign="center"
          sx={{ zIndex: 1 }}
          display="flex"
          justifyContent="center"
        >
          <Box
            maxWidth={{ xs: "260px", sm: "240px", md: "260px", lg: "300px" }}
            width="100%"
            height="100%"
          >
            <CreateYourColorCloud width="100%" height="100%" />
          </Box>
        </Box>
      </Box>
      <CreateAColorButton
        handleColorCreateNavigate={handleColorCreateNavigate}
      />
    </Box>
  );
};
export default CreateYourColor;
