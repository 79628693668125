import React from "react";
import Box from "@mui/material/Box";
import IcoBottle from "../../../../../../components/ico-bottle-images/ico-polish-bottle";

interface Props {
  image: string;
}
export const ProductImage = ({ image }: Props) => (
  <>
    {image === "" ? (
      <Box
        width={{ xs: "158px", sm: "200px" }}
        height="240px"
        sx={{ objectFit: "cover", backgroundColor: "#EEE817" }}
        borderRadius="20px"
        mb={1}
      />
    ) : (
      <Box
        width={{ xs: "158px", sm: "200px" }}
        height="240px"
        sx={{ objectFit: "cover", backgroundColor: "#EEE817" }}
        component="img"
        borderRadius="20px"
        src={image}
        mb={1}
      />
    )}
  </>
);

export const ProductImageWithBottle = ({ image }: Props) => (
  <>
    {image === "" ? (
      <Box position="relative">
        <Box
          width={{ xs: "158px", sm: "200px" }}
          height="240px"
          sx={{ objectFit: "cover", backgroundColor: "#EEE817" }}
          borderRadius="20px"
          mb={1}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <IcoBottle imgHeight="175px" />
        </Box>
      </Box>
    ) : (
      <Box position="relative">
        <Box
          width={{ xs: "158px", sm: "200px" }}
          height="240px"
          sx={{ objectFit: "cover", backgroundColor: "#EEE817" }}
          component="img"
          borderRadius="20px"
          src={image}
          mb={1}
        />
        <Box
          position="absolute"
          height="100%"
          width="100%"
          top="0"
          left="0"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <IcoBottle imgHeight="175px" />
        </Box>
      </Box>
    )}
  </>
);
