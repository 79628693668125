import palette from "../../../../assets/infoBannerAssets/artistPalette.png";
import flag from "../../../../assets/infoBannerAssets/flag.png";
import robot from "../../../../assets/infoBannerAssets/robot.png";
import umbrella from "../../../../assets/infoBannerAssets/umbrella.png";

export interface InfoInterface {
  icon: string;
  title: string;
  description: string;
  altText: string;
}

export const infoData: InfoInterface[] = [
  {
    icon: umbrella,
    title: "Color match promise",
    description: "So confident it's an exact match or your money back.",
    altText: "umbrella",
  },
  {
    icon: robot,
    title: "1st class tech",
    description:
      "We offer you a fully customizable experience, for just $9 a polish.",
    altText: "robot",
  },
  {
    icon: palette,
    title: "Any shade. Ever.",
    description:
      "The largest library of nail polish shades in the world. Millions and counting.",
    altText: "painter's palette",
  },
  {
    icon: flag,
    title: "Made to order",
    description: "Made to order, with love, in the good ol' USA.",
    altText: "american flag",
  },
];
