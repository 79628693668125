import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { blankTheme } from "./themes/theme";
import { ImageProvider } from "./pages/color_picker/state/image.context";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "./assets/fonts/favorit/ABCFavorit-BookItalic.otf";
import "./assets/fonts/favorit/ABCFavorit-Book.otf";
import "./assets/fonts/favorit/ABCFavorit-Medium.otf";
import "./assets/fonts/favorit/ABCFavoritLining-LightItalic.otf";
import "./assets/fonts/nib/nib-italic-pro.ttf";
import "./assets/fonts/nib/NeueBit-Bold.otf";
import "./index.css";

Sentry.init({
  dsn: "https://e2ab224f9cde4020a04e8e8f88ffd950@o1303198.ingest.sentry.io/4504794858389504",
  debug: false,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["localhost", "www.blankbeauty.com", /^\//],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.3 : 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={blankTheme}>
        <CssBaseline enableColorScheme />
        <Router>
          <Provider store={store()}>
            <ImageProvider>
              <App />
            </ImageProvider>
          </Provider>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
