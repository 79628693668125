import React, { useEffect, useContext, useState } from "react";
import Canvas from "./components/canvas";
import ColorPickerBar from "./components/color-picker-bar.component";
import Box from "@mui/material/Box";
import { useAppSelector, useAppDispatch } from "../../utils/hooks/redux-hooks";
import {
  selectIsDisplayed,
  setInstructionsDisplayed,
  selectInstructionsDisplayed,
  selectColorData,
} from "./state/color-picker-slice";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ColorPickerSnackBar from "./components/color-picker-snackbar.component";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";
import SEO from "../../components/seo";
import "./color-picker.styles.css";
import BlankFooter from "../../components/blank-footer/blank-footer";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import { ImageContext } from "./state/image.context";
import ColorLibraryColorsService from "../color-library/color-library-color-page/services/color-library-color-service";
import { useLocation } from "react-router-dom";
import { useKioskHook } from "../../utils/hooks/useKioskHook";
import InstructionOverlay from "./components/instructions-overlay.component";

const ColorPickerContainer = () => {
  const dispatch = useAppDispatch();
  const colorData = useAppSelector(selectColorData);
  const isDisplayed = useAppSelector(selectIsDisplayed);
  const ele = window.document.getElementById("attentive_overlay");
  const instructionsDisplayed = useAppSelector(selectInstructionsDisplayed);
  const { url } = useContext(ImageContext);

  const [recommendedColors, setRecommendedColors] = useState<
    { id: string; hex: string }[]
  >([]);
  const [selectedRecommendedColor, setSelectedRecommendedColor] = useState<{
    id: string;
    hex: string;
  }>();

  const isKiosk = useKioskHook();
  const location = useLocation();
  const handleClosePopup = () => {
    dispatch(setInstructionsDisplayed(false));
  };

  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  usePixelServiceOnLoad();
  useEffect(() => {
    setRecommendedColors([]);
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const getRecommendationsTimeout = setTimeout(async () => {
      if (!colorData.hexColor) {
        return;
      }

      const recommendations =
        await ColorLibraryColorsService.getColorRecommendations(
          colorData.hexColor
        );

      const matchedRecommendations: Array<string> = [];
      const newRecommenededColors: { id: string; hex: string }[] = [
        ...new Set(
          recommendations.map(
            (singleRecommendedColor): { hex: string; id: string } => {
              return {
                hex: singleRecommendedColor.hex,
                id: String(singleRecommendedColor.id),
              };
            }
          ).filter((recommendation) => {
            const key = recommendation.hex;

            if (!matchedRecommendations.includes(key)) {
              matchedRecommendations.push(key);
              return true;
            }
            return false

          })
        ),
      ];

      setRecommendedColors(newRecommenededColors);
    }, 700);

    return () => clearTimeout(getRecommendationsTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorData.hexColor]);

  useEffect(() => {
    setRecommendedColors([]);
  }, [url, location.pathname]);

  useEffect(() => {
    if (ele) ele.style.display = "none";
    return () => {
      if (ele) ele.style.display = "unset";
    };
  }, [ele]);

  useEffect(() => {
    dispatch(setInstructionsDisplayed(true));
  }, [dispatch]);

  return (
    <Box
      pb={{ md: 2 }}
      height={!isKiosk ? "100vh" : undefined}
      bgcolor="secondary.main"
      display="flex"
      flexDirection="column"
    >
      <SEO
        title="Create Your Custom Nail Polish Color | Blank Beauty"
        urlEndpoint="colorPicker"
        description="Long-lasting, chip-resistant nail polish colors by Blank Beauty. 13-free and full coverage that lasts. Want something unique? Create your own nail polish."
        type="website"
      />

      {isKiosk && <BlankAppbar />}

      <Box marginBottom='4rem' marginTop={isKiosk ? "7rem" : undefined} width={{ xs: "100%" }}>
        <ColorPickerSnackBar />
      </Box>

      {!isKiosk && (
        <Box
          flexDirection="column"
          display="block"
          justifyContent="center"
          className={"color-picker-canvas-parent"}
          position="relative"
          minHeight={{ xs: '626px', "sm": '536px', lg: '915px', }}
        >
          <Canvas />

          <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="start"
            marginTop={"20px"}
          >
            {instructionsDisplayed && (
              <Box position="absolute" top="-145px">
                <InstructionOverlay handleClosePopup={handleClosePopup} />
              </Box>
            )}
            <ColorPickerBar
              displayColor={colorData.hexColor}
              isDisplayed={isDisplayed}
            />
          </Box>
        </Box>
      )}

      {isKiosk && (
        <Box
          marginBottom="3rem"
          gap={{ xs: "1rem", lg: "0" }}
          flexDirection={{ xs: "column", lg: "row" }}
          padding={{ xs: ".5rem", md: "1rem" }}
          display="flex"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={{ lg: "50%", xs: "100%" }}
            height="304px"
          >
            <Box
              component="p"
              sx={{
                fontFamily: "Nib Pro",
                fontSize: "4rem",
                fontStyle: "italic",
                marginBottom: "1rem",
              }}
            >
              Pick a color
            </Box>
            <Box textAlign={'center'} fontWeight="bold" marginBottom="2rem" component="p">
              Click on an area of the image for color inspiration. Then select your perfect shade!
            </Box>

            <Box
              width="100%"
              marginBottom="2rem"
              className="suggested-colors-container"
              minHeight={'80px'}
              visibility={url && colorData.hexColor !== "#FFFFFF00" ? 'visible' : 'hidden'}
            >
              {recommendedColors.map((recommendedColor, index) => (
                <Box
                  className={
                    selectedRecommendedColor &&
                      selectedRecommendedColor.hex === recommendedColor.hex
                      ? "active-color-grid-item"
                      : ""
                  }
                  key={index}
                >
                  <Box
                    onClick={() => {
                      setSelectedRecommendedColor(recommendedColor);
                    }}
                    sx={{
                      backgroundColor: `#${recommendedColor.hex}`,
                      cursor: "pointer",
                    }}
                    className="color-grid-item"
                  ></Box>
                </Box>
              ))}
            </Box>
            {/* {/* )} */}

            {selectedRecommendedColor && !isSafari() && (
              <ColorPickerBar
                displayColor={`#${selectedRecommendedColor.hex}`}
                isDisplayed={isDisplayed}
                colorId={selectedRecommendedColor.id}
              />
            )}
            {isSafari() && (
              <ColorPickerBar
                displayColor={selectedRecommendedColor ? `#${selectedRecommendedColor.hex}` : undefined}
                isDisplayed={isDisplayed}
                colorId={selectedRecommendedColor ? selectedRecommendedColor.id : undefined}
              />
            )}
          </Box>
          <Box
            // overflow="hidden"
            borderRadius={{ md: "15px" }}
            minHeight={{ xs: "350px", lg: "540px" }}
            className="color-picker-canvas-parent"
            padding={{ ...!url && { xs: "0", md: "1rem" } }}
            width={{ lg: "50%", xs: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {!url ? (
              <Box
                display="flex"
                flexDirection="column"
                className=""
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={{
                  backgroundColor: "#F1F0EF",
                  borderRadius: "40px",
                  boxShadow:
                    "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                }}
                minHeight={{ xs: "400px", lg: "500px" }}
              >
                <DoNotDisturbIcon fontSize="large" />
                <Box component="p" marginTop=".5rem">
                  Please select an image
                </Box>
              </Box>
            ) : (
              <Canvas />
            )}
          </Box>
        </Box>
      )}

      {isKiosk && <BlankFooter />}
    </Box>
  );
};

export default ColorPickerContainer;
