import { useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { RootState } from "../../../store";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../utils/hooks/redux-hooks";
import { initialProductState } from "../../product/CONSTANTS/initial-product-state";
import ProductService from "../../product/services/product-service";
import { CollectionProductInterface } from "../interfaces/collection-interfaces";
import { fetchOpenEndorseCollection } from "../state/collection-actions";

export const useGetOpenEndorseCollection = (urlParam: string) => {
  const dispatch = useAppDispatch();
  const rendered = useRef<boolean>(true);
  const [product, setProduct] =
    useState<CollectionProductInterface>(initialProductState);

  const openEndorseData = useAppSelector(
    (state: RootState) => state.collections.openEndorseCollection
  );

  const status = useAppSelector(
    (state: RootState) => state.collections.openEndorseStatus
  );

  const isUninitialized = status === undefined;
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  useEffect(() => {
    if (rendered.current) {
      if (status === undefined && urlParam) {
        const productHandle = `${urlParam}s-collection`;
        dispatch(fetchOpenEndorseCollection(urlParam));
        ProductService.fetchCollectionAsProduct(productHandle)
          .then((res) => {
            if (res) setProduct(res);
          })
          .catch((err) => Sentry.captureException(err));
      }
    }
    return () => {
      rendered.current = false;
    };
  }, [urlParam, dispatch, status]);

  return {
    openEndorseData,
    product,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
};
