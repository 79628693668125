import { useState } from "react";
import { convertHexToHsv } from "../../../utils/convertHexToHsv";
import colorPickerService from "../../color_picker/services/blank-color-service";

export const useGetCreatedColorDbId = () => {
  const [isColorLoading, setIsColorLoading] = useState<boolean>(true);
  const [isColorSuccess, setIsColorSuccess] = useState<boolean>(true);

  const roundValue = (num: number): number => {
    const rounded = Math.floor(num / 10) * 10;
    return rounded;
  };

  const getDbID = async (hexColor: string) => {
    const hexValue = `${hexColor.split("-")[1]}`;
    const hsv = convertHexToHsv(hexValue);

    const h = roundValue(hsv.h);
    const s = roundValue(hsv.s);
    const v = roundValue(hsv.v);

    try {
      const product = await colorPickerService.fetchColor(h, s, v);
      return product.id;
    } catch (err) {
      setIsColorSuccess(false);
    } finally {
      setIsColorLoading(false);
    }
  };

  return { getDbID, isColorLoading, isColorSuccess };
};
