import React from "react";
import * as Sentry from "@sentry/react";
import {
  COLOR_PICKER,
  LIBRARY_LIST,
  LIBRARY_COLOR,
  FAQ,
  CONTACT,
  ABOUT,
  PRODUCT,
  HELP,
  SHOP_ALL,
  KIOSK,
  CREATE_YOUR_COLOR,
  COLLECTIONS,
  SHOP_POLICIES,
  WHOLESALE,
  WALMART,
  NEVADA,
  RECEIPT,
  FORM_SUBMISSION_CONFIRMATION,
  CANVAS_V1,
  CANVAS_KIOSK_V1
} from "./CONSTANTS";
import { Routes, Route } from "react-router-dom";
import HomePageContainer from "../pages/home/home.container.component";
import ColorPickerContainer from "../pages/color_picker/color-picker.container.component";
import ColorLibraryListPage from "../pages/color-library/color-library-list-page/color-library-list-page.component";
import ColorLibraryColorPage from "../pages/color-library/color-library-color-page/color-library-color-page.component";
import PageNotFound from "./page-not-found";
import FormSubmissionConfirmation from "../pages/about/form-submission-confirmation-page.component";
import FaqPage from "../pages/about/faq-page.component";
import ContactUs from "../pages/about/contact-us-page.component";
import HelpPage from "../pages/about/help-page.component";
import AboutUs from "../pages/about/about-us-page.component";
import LandingPage from "../pages/landing/landing-page";
import NevadaPage from "../pages/nevada/nevada-page";
import ProductPageContainer from "../pages/product/product-page.container";
import ShopAllPage from "../pages/shop-all/shop-all";
import OpenEndorse from "../pages/open-endorse/open-endorse";
import ShopPolicies from "../pages/about/shop-policies";
import CollectionsPage from "../pages/collections/collections-page";
import CollectionProductPage from "../pages/collections/collection-product-page";
import WholeSaleLanding from "../pages/wholesale/wholesale_landing";
import WalmartLandingPage from "../pages/walmart/walmart-landing-page";
import { ReceiptPageContainer } from "../pages/receipt/receipt.container.component";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter = () => (
  <SentryRoutes>
    <Route path="*" element={<PageNotFound />} />
    <Route index element={<LandingPage />} />
    <Route path={NEVADA} element={<NevadaPage />} />
    <Route path=":endorser" element={<OpenEndorse />} />
    <Route path={COLLECTIONS}>
      <Route index element={<CollectionsPage />} />
      <Route path=":collection" element={<CollectionProductPage />} />
    </Route>
    <Route path={PRODUCT}>
      <Route path=":color" element={<ProductPageContainer />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
    <Route path={CREATE_YOUR_COLOR} element={<HomePageContainer />} />
    <Route path={RECEIPT} element={<ReceiptPageContainer />} />
    <Route path={KIOSK} element={<HomePageContainer />} />
    <Route path={SHOP_ALL} element={<ShopAllPage />} />
    <Route path={LIBRARY_LIST} element={<ColorLibraryListPage />} />
    <Route path={LIBRARY_COLOR} element={<ColorLibraryColorPage />} />
    <Route path={WHOLESALE} element={<WholeSaleLanding />} />
    <Route path={ABOUT} element={<AboutUs />} />
    <Route path={FAQ} element={<FaqPage />} />
    <Route path={CONTACT} element={<ContactUs />} />
    <Route path={HELP} element={<HelpPage />} />
    <Route path={SHOP_POLICIES}>
      <Route path=":policy" element={<ShopPolicies />} />
    </Route>
    <Route path={COLOR_PICKER} element={<ColorPickerContainer />} />
    <Route path={CANVAS_V1} element={<ColorPickerContainer />} />
    <Route path={CANVAS_KIOSK_V1} element={<ColorPickerContainer />} />
    
    <Route path={FORM_SUBMISSION_CONFIRMATION} element={<FormSubmissionConfirmation />} />

    <Route path={WALMART}>
      <Route path="*" element={<PageNotFound isWalmart />} />
      <Route index element={<WalmartLandingPage />} />
      <Route path=":endorser" element={<OpenEndorse isWalmart />} />
      <Route
        path={`${WALMART}${SHOP_ALL}`}
        element={<ShopAllPage isWalmart />}
      />
      <Route
        path={`${WALMART}${LIBRARY_LIST}`}
        element={<ColorLibraryListPage isWalmart />}
      />
      <Route
        path={`${WALMART}${LIBRARY_COLOR}`}
        element={<ColorLibraryColorPage isWalmart />}
      />
      <Route path={`${WALMART}${ABOUT}`} element={<AboutUs isWalmart />} />
      <Route path={`${WALMART}${FAQ}`} element={<FaqPage isWalmart />} />
      <Route path={`${WALMART}${CONTACT}`} element={<ContactUs isWalmart />} />
      <Route path={`${WALMART}${HELP}`} element={<HelpPage isWalmart />} />
      <Route path={`${WALMART}${SHOP_POLICIES}`}>
        <Route path=":policy" element={<ShopPolicies isWalmart />} />
      </Route>
      <Route path={`${WALMART}${COLLECTIONS}`}>
        <Route index element={<CollectionsPage isWalmart />} />
        <Route
          path=":collection"
          element={<CollectionProductPage isWalmart />}
        />
      </Route>
      <Route
        path={`${WALMART}${CREATE_YOUR_COLOR}`}
        element={<HomePageContainer isWalmart />}
      />
      <Route
        path={`${WALMART}${COLOR_PICKER}`}
        element={<ColorPickerContainer />}
      />
      <Route path={`${WALMART}${PRODUCT}`}>
        <Route path=":color" element={<ProductPageContainer isWalmart />} />
        <Route path="*" element={<PageNotFound isWalmart />} />
      </Route>
    </Route>
  </SentryRoutes>
);

export default AppRouter;
