import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  comment: string;
  color: string;
}
const Comment = ({ comment, color }: Props) => (
  <Typography
    textAlign="center"
    fontFamily="ABC Favorit Book"
    fontSize="16px"
    lineHeight="20px"
    fontWeight={300}
    color={color}
    sx={{
      maxWidth: { xs: "230px", md: "260px", xl: "275px" },
      fontStyle: "italic",
    }}
  >
    {comment}
  </Typography>
);
export default Comment;
