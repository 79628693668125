import * as Sentry from "@sentry/react";
import { graphqlClientRequest } from "../../../services/graphQl/graphQl-common";
import { SHOP_POLICIES_QUERY } from "../../../services/graphQl/queries/shop-policies";
import ShopPolicyInterface from "../interfaces/shop-policy.interface";

const fetchShopPolicy = async (policy: string) => {
  const data = {
    query: SHOP_POLICIES_QUERY,
  };

  const res = await graphqlClientRequest.then((res) => res.post(data));
  if (res.data.errors) {
    Sentry.captureException(res.data.errors[0]);
  }
  const shopData = res.data.data.shop;
  const policies = [
    shopData.privacyPolicy,
    shopData.termsOfService,
    shopData.refundPolicy,
  ];

  const neededPolicy = policies.filter((pol) => pol.handle === policy);

  const displayedPolicy: ShopPolicyInterface = {
    title: neededPolicy[0].title,
    body: neededPolicy[0].body,
  };

  return displayedPolicy;
};

const PolicyService = {
  fetchShopPolicy,
};

export default PolicyService;
