import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { ABOUT, CONTACT, FAQ, WALMART } from "../../../routing/CONSTANTS";
import { socialsData } from "../CONSTANTS/socials-data";
import SocialLink from "./social-link.component";
import NavigationLinks from "./navigation-links";

interface MobileDrawerProps {
  closeDrawer: () => void;
  appbarColor: string;
  isDrawerVisible: boolean;
  isWalmartComponent: boolean;
}

const MobileDrawer = ({
  closeDrawer,
  appbarColor,
  isDrawerVisible,
  isWalmartComponent,
}: MobileDrawerProps) => {
  const navigate = useNavigate();

  const linkNavigate = (destination: string, isWalmart: boolean) => {
    if (isWalmart) {
      const navString = `${WALMART}${destination}`;
      navigate(navString);
    } else {
      navigate(destination);
    }
    closeDrawer();
  };

  return (
    <Box
      sx={{
        float: "left",
        width: "100%",
        backgroundColor: { xs: appbarColor, md: "transparent" },
        position: { xs: "fixed", md: "unset" },
        opacity: { xs: !isDrawerVisible ? 0 : 1, md: 1 },
        visibility: {
          xs: !isDrawerVisible ? "hidden" : "visible",
          md: "visible",
        },
        top: 0,
        left: 0,
        height: { xs: "100vh", md: "25px" },
        padding: { xs: "110px 61px 90px", md: 0 },
        transition: "all .5s ease-in",
        display: { xs: "block", md: "none" },
      }}
    >
      <NavigationLinks
        isWalmart={isWalmartComponent}
        closeDrawer={closeDrawer}
      />

      <Box display={{ xs: "block", md: "none" }}>
        <Box position="fixed" left="0" bottom="15vh" width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            maxWidth="265px"
            m="0 auto"
          >
            {socialsData.map((icon) => (
              <SocialLink key={icon.id} socials={icon} />
            ))}
          </Box>
          <Box mt={1} pr={1} display="flex" justifyContent="center">
            <Box
              borderRight="1px solid black"
              pr={1.75}
              onClick={() => linkNavigate(CONTACT, isWalmartComponent)}
            >
              <Typography variant="body1">Contact</Typography>
            </Box>
            <Box
              borderRight="1px solid black"
              px={1.75}
              onClick={() => linkNavigate(ABOUT, isWalmartComponent)}
            >
              <Typography variant="body1">About us</Typography>
            </Box>
            <Box
              pl={1.75}
              onClick={() => linkNavigate(FAQ, isWalmartComponent)}
            >
              <Typography variant="body1">FAQ</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default MobileDrawer;
