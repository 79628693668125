import React from "react";

interface Props {
  img: string;
}

const CreateYourColorImage = ({ img }: Props) => (
  <svg
    width="253"
    height="254"
    viewBox="0 0 253 254"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id={img}
        patternUnits="userSpaceOnUse"
        width="100%"
        height="100%"
      >
        <image
          xlinkHref={img}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
        />
      </pattern>
    </defs>
    <path
      d="M251.5 127C251.5 142.987 234.707 155.527 228.944 169.427C222.962 183.846 225.751 204.524 214.893 215.393C204.036 226.251 183.347 223.473 168.927 229.444C155.027 235.207 142.487 252 126.5 252C110.513 252 97.9731 235.207 84.0726 229.444C69.6536 223.461 48.9758 226.251 38.1067 215.393C27.2492 204.536 30.027 183.846 24.0565 169.427C18.2935 155.527 1.5 142.987 1.5 127C1.5 111.013 18.2935 98.473 24.0565 84.5726C30.0385 70.1535 27.2492 49.4758 38.1067 38.6067C48.9643 27.7492 69.6536 30.527 84.0726 24.5565C97.9731 18.7935 110.513 2 126.5 2C142.487 2 155.027 18.7935 168.927 24.5565C183.347 30.5385 204.024 27.7492 214.893 38.6067C225.751 49.4643 222.973 70.1535 228.944 84.5726C234.914 98.9917 251.5 111.013 251.5 127V127Z"
      fill={`url(#${img})`}
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </svg>
);

export default CreateYourColorImage;
