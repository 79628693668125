import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

interface Props {
  handleColorCreateNavigate: () => void;
}
const CreateAColorButton = ({ handleColorCreateNavigate }: Props) => (
  <Box
    width="100%"
    m="0 auto"
    maxWidth={{ xs: "167px", sm: "220px", md: "225px", lg: "250px" }}
    px={0.25}
  >
    <Button
      fullWidth
      onClick={handleColorCreateNavigate}
      sx={{
        pt: { xs: "7px", sm: "10.5px" },
        pb: { xs: "7px", sm: "10.5px" },
        fontSize: { xs: ".9em", sm: "20px" },
        backgroundColor: "#C6EA00",
        color: "black",
        "&:hover": {
          backgroundColor: "white",
        },
      }}
      variant="contained"
    >
      create a color+
    </Button>
  </Box>
);

export default CreateAColorButton;
