import greenBottle from "../../../../assets/aboutYourPolishAssets/aboutYourPolishGreen.webp";
import greenBottlePng from "../../../../assets/aboutYourPolishAssets/aboutYourPolishGreen.png";
import purpleBottle from "../../../../assets/aboutYourPolishAssets/aboutYourPolishPurple.webp";
import purpleBottlePng from "../../../../assets/aboutYourPolishAssets/aboutYourPolishPurple.png";
import blueBottle from "../../../../assets/aboutYourPolishAssets/aboutYourPolishBlue.webp";
import blueBottlePng from "../../../../assets/aboutYourPolishAssets/aboutYourPolishBlue.png";

export interface AboutYourPolishInterface {
  stepTitle: string;
  slideTitle: string;
  slideDesc: string;
  bottle: string;
  backupBottle: string;
  color: string;
  alt: string;
}

export const aboutYourPolishSlideData: AboutYourPolishInterface[] = [
  {
    stepTitle: "No mark-ups or middlemen.",
    slideTitle: "Same ingredients as your top shelf polish, half the price.",
    slideDesc: `Pssssst! We'll let you in on a little secret. The majority of 
    nail polish - whether it's cheap and cheerful or super expensive - is usually 
    all made in the same factory. We're just cutting out the middle man by making our 
    own and shipping straight to you. Pretty neat, huh?`,
    bottle: greenBottle,
    backupBottle: greenBottlePng,
    color: "#C6EA00",
    alt: "green blank bottle",
  },
  {
    stepTitle: "Loved by Nail Pros",
    slideTitle: "Loved by Nail Pros",
    slideDesc: `Long-lasting, 7-day shine. Our polish is formulated to be ultra-long lasting 
    and chip resistant, which has made it a firm favorite amongst nail techs and manicurists.`,
    bottle: purpleBottle,
    backupBottle: purpleBottlePng,
    color: "#9F37DC",
    alt: "purple blank bottle",
  },
  {
    stepTitle: "Planet, pet and people friendly",
    slideTitle: "Planet, pet and people friendly",
    slideDesc: `No nasty ingredients - ever. We're “13-free” which means that we don't use any 
    of the 13 harmful chemicals (like Formaldehyde) that commonly appear in nail polish. `,
    bottle: blueBottle,
    backupBottle: blueBottlePng,
    color: "#6C97F3",
    alt: "blue blank bottle",
  },
];
