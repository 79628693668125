import React from "react";
import Box from "@mui/material/Box";
import Shades from "./components/shades";
import ShopAllButton from "./components/shop-all-button";
import TrendingTitle from "./components/trending-title";
import ProtectAndPerfect from "../../shop-all/components/protect-and-perfect";

interface Props {
  isWalmartComponent?: boolean;
}

const TrendingShades = ({ isWalmartComponent }: Props) => (
  <Box
    p={{ xs: "60px 8px", md: "60px 0 75px 0" }}
    width="100%"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    sx={{ backgroundColor: "white" }}
  >
    <TrendingTitle />
    <Shades />
    <ShopAllButton isWalmartComponent={isWalmartComponent} />

    <ProtectAndPerfect landing />
  </Box>
);

export default TrendingShades;
