import Button from "@mui/material/Button";
import React, { ChangeEvent, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR_PICKER, WALMART } from "../../../../routing/CONSTANTS";
import { useCheckMobileScreen } from "../../../../utils/hooks/useCheckMobileScreen";
import { ImageContext } from "../../../color_picker/state/image.context";
import { useAppSelector } from "../../../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../../walmart/state/walmart-slice";

import { useKioskHook } from "../../../../utils/hooks/useKioskHook";

interface Props {
  lightIcon: JSX.Element;
  darkIcon: JSX.Element;
  buttonText: string;
  hiddenFileInput?: React.RefObject<HTMLInputElement> | null;
  navFunction: () => void;
}

const PhotoPickerJourneyButton = ({
  lightIcon,
  darkIcon,
  buttonText,
  navFunction,
  hiddenFileInput,
}: Props) => {
  const { fileUpload } = useContext(ImageContext);
  const { isMobile } = useCheckMobileScreen();
  const navigate = useNavigate();
  const isKiosk = useKioskHook()

  const isWalmart = useAppSelector(selectIsWalmartPath);
  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    fileUpload(e);
    let navPath = isWalmart ? `${WALMART}${COLOR_PICKER}` : COLOR_PICKER;
    if (isKiosk) {
      navPath = `${navPath}?isKiosk=1`

    }

    navigate(navPath, {
      state: { camera: false },
    });
  };

  const walmartBlue = "#041E42";



  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={isMobile ? lightIcon : darkIcon}
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "335px",
          py: 1,
          mb: 1,
          mt: 0.5,
          color: { xs: isWalmart ? "black" : "white" },
          backgroundColor: {
            xs: isWalmart ? "#FDC01F" : "black",
          },
          border: `2px solid ${isWalmart ? "#FDC01F" : "black"}`,
          "&:hover": {
            border: `2px solid ${isWalmart ? "#FDC01F" : "black"}`,
            "& .photos-icon-ring": {
              border: `2px solid ${isWalmart ? "#FDC01F" : "black"}`,
              stroke: "white",
            },
            "& .photos-icon-inner-box": {
              fill: isWalmart ? walmartBlue : "black",
              stroke: isWalmart ? walmartBlue : "black",
            },
          },
        }}
        onClick={navFunction}
      >
        {buttonText}
      </Button>
      <input
        accept="image/*"
        onChange={uploadFile}
        ref={hiddenFileInput}
        type="file"
        style={{ display: "none" }}
      />
    </>
  );
};

export default PhotoPickerJourneyButton;
