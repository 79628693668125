import Box from "@mui/material/Box";
import React from "react";
import { AboutYourPolishInterface } from "../CONSTANTS/about-your-polish";
import SliderDot from "./about-slider-dot";

interface Props {
  activeIdx: number;
  polishSlideData: AboutYourPolishInterface[];
  setActiveIdx: React.Dispatch<React.SetStateAction<number>>;
}
const SliderDots = ({ activeIdx, polishSlideData, setActiveIdx }: Props) => (
  <Box display="flex" justifyContent="center">
    {polishSlideData.map((slide, idx) => (
      <SliderDot
        key={slide.stepTitle}
        activeIdx={activeIdx}
        idx={idx}
        setActiveIdx={setActiveIdx}
      />
    ))}
  </Box>
);

export default SliderDots;
