import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { UpSellInterface } from "../../interfaces/up-sell-interface";
import UpSellItemDesktop from "./upSell-item-desk";

interface Props {
  isShown: boolean;
  upSellItems: UpSellInterface[];
  cartUpSellItems: UpSellInterface[];
  addUpSellToCart: (item: UpSellInterface) => void;
}
const UpSellDesktop = ({
  isShown,
  upSellItems,
  cartUpSellItems,
  addUpSellToCart,
}: Props) => (
  <Box
    position="relative"
    width="100%"
    maxWidth="331px"
    minHeight="100vh"
    height="100%"
    borderLeft="2px solid black"
    sx={{
      zIndex: 998,
      overflowY: "auto",
      float: "right",
      backgroundColor: "#ededed",
      opacity: isShown && cartUpSellItems.length > 0 ? 1 : 0,
      visibility: isShown && cartUpSellItems.length > 0 ? "visible" : "hidden",
      transform:
        isShown && cartUpSellItems.length > 0
          ? "translateX(0)"
          : "translateX(100%)",
      transition: "all .4s ease-in",
    }}
  >
    <Box>
      <Box p="35px 25px 0">
        <Typography
          variant="h6"
          fontSize="28px"
          lineHeight={1.07}
          textAlign="center"
        >
          Protect & strengthen
        </Typography>
      </Box>
      <Box width="100%" p="60px 45px 45px 45px">
        {upSellItems.map((item) => (
          <UpSellItemDesktop
            key={item.title}
            item={item}
            cartUpSells={cartUpSellItems}
            addUpSellToCart={addUpSellToCart}
          />
        ))}
      </Box>
    </Box>
  </Box>
);

export default UpSellDesktop;
