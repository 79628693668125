import React, { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { BlankColorInterface } from "../../pages/color_picker/interface/blank-color-interface";
import IcoBottle from "../ico-bottle-images/ico-polish-bottle";
import { CUSTOM_POLISH_MERCH_ID } from "../../pages/cart/CONSTANTS/merch-ids";
import CustomProductBackground from "../product-backgrounds/custom-product-background";
import AddToCartButton from "../add-to-cart-button";
import useCartActions from "../../pages/cart/hooks/useCartActions";
import { AddToCartInterface } from "../../pages/cart/interfaces/add-to-cart.interface";
import CartService from "../../pages/cart/services/cart-service";
import PixelService from "../../services/pixels/pixel-service";
import { useAppSelector } from "../../utils/hooks/redux-hooks";
import { selectCartTotal } from "../../pages/cart/state/cart-slice";
import ProductService from "../../pages/product/services/product-service";
import { selectIsWalmartPath } from "../../pages/walmart/state/walmart-slice";
import { PRODUCT, WALMART } from "../../routing/CONSTANTS";
import { useKioskHook } from "../../utils/hooks/useKioskHook";


interface CustomPolishProductCardProps {
  color: BlankColorInterface;
}

const CustomPolishProductCard = ({ color }: CustomPolishProductCardProps) => {
  const [customPolishPrice, setCustomPolishPrice] = useState<number>(0);

  const { createOrAddToCart } = useCartActions();
  const navigate = useNavigate();
  const subTotal = useAppSelector(selectCartTotal);
  const isWalmart = useAppSelector(selectIsWalmartPath);

  const h = color.hue;
  const s = color.sat;
  const v = color.val;



  const isKiosk = useKioskHook()

  
  const navigateToColorNamer = () => {
    let navPath = isWalmart
      ? `${WALMART}${PRODUCT}/${color.id}`
      : `${PRODUCT}/${color.id}`;

      if(isKiosk){
        navPath = `${navPath}?isKiosk=1`

      }
    navigate(navPath, {
      state: { polishName: "Custom Polish" },
    });
  };

  const createCartOrAddNewCartItem = () => {
    const itemToAdd: AddToCartInterface = {
      merchId: CUSTOM_POLISH_MERCH_ID,
      key: `${color.id}`,
      value: "Custom Polish",
      quantity: 1,
      img: "",
    };

    createOrAddToCart(itemToAdd, 1);
    ProductService.fetchProductIdByHandle("custom-standard-polish").then(
      (res) => {
        PixelService.pixelAddToCart(
          res,
          9.0,
          color.id.toString(),
          CUSTOM_POLISH_MERCH_ID,
          "db-color",
          subTotal
        );
      }
    );
  };

  const getPolishPrice = () => {
    CartService.fetchCustomPolishPrice().then((res) =>
      setCustomPolishPrice(res)
    );
  };

  getPolishPrice();

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "350px",
        px: { xs: 0.25, md: "10%" },
        py: { xs: 3, md: "10%" },

        textAlign: "center",
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        data-testid="product-card"
        onClick={navigateToColorNamer}
        sx={{ cursor: "pointer" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        width="100%"
      >
        <Box
          width="100%"
          maxWidth={{ xs: "170px", sm: "220px", md: "260px" }}
          left="0"
          bottom="0"
          textAlign="center"
          sx={{ zIndex: 1 }}
        >
          <CustomProductBackground
            isProdPage={false}
            isCart={false}
            hsv={[h, s, v]}
            rotate={false}
          />
        </Box>

        <Box
          top="0"
          display="flex"
          justifyContent="center"
          pb={{ xs: "20px", md: "35px" }}
          maxWidth="100px"
          m="0 auto"
          sx={{ zIndex: 2 }}
        >
          <Box
            position="absolute"
            top={{ xs: "-40px", md: "-70px" }}
            height={{ xs: "95%", md: "100%" }}
            width="100%"
          >
            <IcoBottle imgHeight="100%" />
          </Box>
        </Box>
      </Box>

      <Box
        onClick={navigateToColorNamer}
        sx={{ cursor: "pointer" }}
        textAlign="center"
        mt={{ xs: "-6px", md: "-20px" }}
        mb={0.5}
      >
        <Typography
          fontSize={{ xs: "16px", md: "20px" }}
          fontFamily="ABC Favorit"
          sx={{
            textDecoration: "underline",
          }}
        >
          {color.id}
        </Typography>
      </Box>
      <Box textAlign="center" mb={1}>
        <Typography>15ml</Typography>
      </Box>
      <AddToCartButton
        btnStyle="gridBtn"
        isLoading={false}
        polishPrice={customPolishPrice}
        createOrAddToCart={createCartOrAddNewCartItem}
      />
    </Box>
  );
};
export default React.memo(CustomPolishProductCard);
