import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WSHeroTextDesktop from "./ws-hero-text-desktop";
import WSHeroTextMobile from "./ws-hero-text-mobile";

const WSHeroCTA = () => (
  <Box
    p={{ sm: "0 0 0 75px", md: "0 0 0 150px" }}
    mb={{ xs: 5, sm: 0 }}
    height="100%"
    width="100%"
    maxWidth="1400px"
    display="flex"
    flexDirection="column"
    alignItems={{ xs: "center", sm: "flex-start" }}
    justifyContent={{ xs: "flex-end", sm: "center" }}
  >
    <Box
      display="flex"
      flexDirection="column"
      width={{ xs: "100%", sm: "unset" }}
      alignItems={{ xs: "center", sm: "flex-start" }}
    >
      <Box width="100%" display={{ xs: "none", sm: "block" }}>
        <WSHeroTextDesktop />
      </Box>
      <Box m="0 auto" display={{ xs: "block", sm: "none" }}>
        <WSHeroTextMobile />
      </Box>
      <Box
        width="100%"
        maxWidth={{ xs: "250px", sm: "450px", xl: "500px" }}
        mb={{ xs: 2, sm: 3 }}
      >
        <Typography
          variant="h4"
          fontSize={{ xs: "18px", sm: "2.502vw", lg: "32px" }}
          lineHeight={{ xs: "24px", sm: "43px" }}
          textAlign={{ xs: "center", sm: "left" }}
        >
          No minimum order quantity. Custom labels. From $9.
        </Typography>
      </Box>
      <Box width="100%" maxWidth={{ xs: "320px", sm: "290px" }}>
        <Button
          // onClick={handleNavigateToAllCollections}
          fullWidth
          variant="contained"
          sx={{
            pt: { xs: "9.5px", sm: "10.5px" },
            pb: { xs: "9.5px", sm: "10.5px" },
          }}
        >
          submit your inquiry
        </Button>
      </Box>
    </Box>
  </Box>
);

export default WSHeroCTA;
