import axios, { AxiosError, AxiosResponse } from "axios";
import http from "../../../../services/http/http-common";
import { ServerError } from "../../../../services/types/serverError.type";
import { BlankColorInterface } from "../../../color_picker/interface/blank-color-interface";
import { LibraryColorListInterface } from "../interfaces/library-color-list.interface";

const BLANK_LIBRARY_URL = "/bbmExpress/api/webLibrary/icoLibs/colors";

const getBlankLibraries = async (collection: string) => {
  const upperCasedName =
    collection === "Grey" ? "GRAYSCALE" : collection.toLocaleUpperCase();
  try {
    const response = await http.get(BLANK_LIBRARY_URL, {
      params: { name: upperCasedName },
    });
    const countResponse = await http.get<number>(`${BLANK_LIBRARY_URL}/count`, {
      params: { name: upperCasedName },
    });

    const blankColorLibrary: LibraryColorListInterface = mapBlankColors(
      collection,
      response,
      countResponse
    );

    return blankColorLibrary;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw new Error(serverError.response.data.errorMessage);
      }
    }
    throw new Error(`Error fetching blank libraries: ${err}`);
  }
};

const mapBlankColors = (
  collection: string,
  response: AxiosResponse<any, any>,
  countRes: AxiosResponse<any, any>
): LibraryColorListInterface => {
  var colorListArr: BlankColorInterface[] = [];

  response.data.forEach((item: any) => {
    const newIcoColor: BlankColorInterface = {
      id: item.idColor,
      hue: item.AssignedHue,
      sat: item.AssignedSaturation,
      val: item.AssignedValue,
    };
    colorListArr.push(newIcoColor);
  });
  const count = countRes.data[0]["COUNT(*)"];

  const blankColorList: LibraryColorListInterface = {
    title: collection,
    colorList: colorListArr,
    libraryCount: count,
  };

  return blankColorList;
};

const ColorLibraryListService = {
  getBlankLibraries,
};

export default ColorLibraryListService;
