import { createAsyncThunk } from "@reduxjs/toolkit";
// import PixelService from "../../../services/pixels/pixel-service";
import { AddToCartInterface } from "../interfaces/add-to-cart.interface";
import { DiscountInterface } from "../interfaces/discount.interface";
import { ProductInterface } from "../interfaces/product.interface";
import CartService from "../services/cart-service";
import * as Sentry from "@sentry/react";
import { RequestState } from "../../../services/types/requestState.type";
import { trackAddedToCart } from "../../../components/klaviyo";

export const fetchCart = createAsyncThunk(
  "cart/userCart",
  async (cartId: string, { rejectWithValue }) => {
    try {
      const response = await CartService.fetchCart(cartId);
      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

export const fetchCheckout = createAsyncThunk(
  "cart/checkout",
  async (cartId: string, { rejectWithValue }) => {
    try {
      const response = await CartService.checkout(cartId);
      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

interface ThrownError {
  errorMessage: string;
}
interface DiscountAttributes {
  cartTotal: number;
  attnDiscount: string | null;
}
export const checkForDiscounts = createAsyncThunk<
  DiscountInterface[],
  DiscountAttributes,
  {
    state: { cart: { discountStatus: RequestState | undefined } };
    rejectValue: ThrownError;
  }
>(
  "cart/checkDiscounts",
  async (
    { cartTotal, attnDiscount }: DiscountAttributes,
    { rejectWithValue }
  ) => {
    const cartId = localStorage.getItem("cartId");

    if (cartId) {
      try {
        const response = await CartService.checkForDiscounts(
          attnDiscount,
          cartId,
          cartTotal,
          rejectWithValue
        );
        return response;
      } catch (err) {
        Sentry.captureException(err);
        return rejectWithValue(err as ThrownError);
      }
    } else {
      return;
    }
  },
  {
    condition: (_, { getState }) => {
      const { cart } = getState();
      const fetchStatus = cart.discountStatus;
      if (fetchStatus === "pending") {
        return false;
      }
    },
  }
);

export const createCart = createAsyncThunk(
  "cart/newCart",
  async (product: AddToCartInterface, { rejectWithValue }) => {
    try {
      const response = await CartService.createCart(product);
      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

export const addCartItem = createAsyncThunk(
  "cart/newItem",
  async (
    {
      cartItems,
      product,
    }: {
      cartItems: Array<ProductInterface>;
      product: AddToCartInterface;
    },
    { rejectWithValue }
  ) => {
    const cartId = localStorage.getItem("cartId");
    const itemToAdd = cartItems.find(
      (cartItem) => cartItem.key.toLowerCase() === product.key.toLowerCase()
    );

    try {
      let response: ProductInterface

      if (itemToAdd) {

        const updatedItem: ProductInterface = {
          ...itemToAdd,
          quantity: itemToAdd.quantity + product.quantity,
          value: product.value,
        };
        response = await CartService.updateCartItem(
          cartId!,
          itemToAdd.lineId,
          updatedItem
        );

      }

      else {

        response = await CartService.addCartItem(cartId!, product);

      }
      trackAddedToCart(response)
      return response;


    }
    catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

export const removeCartItem = createAsyncThunk(
  "cart/removeItem",
  async (
    {
      cartItems,
      itemToRemove,
    }: {
      cartItems: Array<ProductInterface>;
      itemToRemove: ProductInterface;
    },
    { rejectWithValue }
  ) => {
    const cartId = localStorage.getItem("cartId");
    const removedItem = cartItems.find(
      (cartItem) => cartItem.lineId === itemToRemove.lineId
    );

    if (removedItem!.quantity > 1) {
      const updatedItem: ProductInterface = {
        ...itemToRemove,
        quantity: itemToRemove.quantity - 1,
      };
      try {
        await CartService.updateCartItem(
          cartId!,
          itemToRemove.lineId,
          updatedItem
        );
        return updatedItem;
      } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(e);
      }
    } else {
      try {
        await CartService.removeCartItem(cartId!, itemToRemove.lineId);
        return itemToRemove;
      } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(e);
      }
    }
  }
);

export const deleteCartItem = createAsyncThunk(
  "cart/deleteItem",
  async (
    { itemToRemove }: { itemToRemove: ProductInterface },
    { rejectWithValue }
  ) => {
    const cartId = localStorage.getItem("cartId");
    try {
      await CartService.removeCartItem(cartId!, itemToRemove.lineId);
      return itemToRemove;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

export const updateCartItem = createAsyncThunk(
  "cart/updateItem",
  async (
    {
      cartId,
      lineId,
      updatedItem,
    }: {
      cartId: string;
      lineId: string;
      updatedItem: ProductInterface;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await CartService.updateCartItem(
        cartId,
        lineId,
        updatedItem
      );

      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  }
);

export const renameCustomCartItem = createAsyncThunk(
  "cart/renameCustomItem",
  async (
    {
      cartId,
      lineId,
      updatedItem,
    }: {
      cartId: string;
      lineId: string;
      updatedItem: ProductInterface;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await CartService.renameCustomCartItem(
        cartId,
        lineId,
        updatedItem
      );

      return response;
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(e);
    }
  }
);

export const shopifyCheckout = createAsyncThunk(
  "cart/shopifyCheckout",
  async (
    {
      cartItems,
      discountData,
    }: {
      cartItems: ProductInterface[];
      discountData: DiscountInterface[];
    },
    { rejectWithValue }
  ) => {
    try {
      const createCheckoutResponse = await CartService.createCheckout(
        cartItems
      );
      const { checkoutId, nonDiscountURl } = createCheckoutResponse;

      // if (process.env.NODE_ENV === "production") {
      //   try {
      //     const gAClientId = PixelService.getClientId("google");
      //     const fBClientId = PixelService.getClientId("facebook");
      //     const clientId = `${gAClientId}|${fBClientId}`;

      //     await CartService.addCheckoutNote(checkoutId, clientId);
      //   } catch (e) {
      //     Sentry.captureException(e);
      //     return rejectWithValue(e);
      //   }
      // }


      let checkoutUrl = nonDiscountURl;
      try {
        for (const discount of discountData) {
          const discountResponse = await CartService.applyDiscounts(
            checkoutId,
            discount.discount
          );
          checkoutUrl =
            discountResponse.data.data.checkoutDiscountCodeApplyV2.checkout
              .webUrl;
        }
      } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(e);
      }
      // console.log(checkoutUrl, "_self")
      window.open(checkoutUrl, "_self");
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(e);
    }
  }
);


export const shopifyCheckoutWalmart = createAsyncThunk(
  "cart/shopifyCheckout",
  async (
    {
      cartItems,
      discountData,
    }: {
      cartItems: ProductInterface[];
      discountData: DiscountInterface[];
    },
    { rejectWithValue }
  ) => {
    try {
      const createCheckoutResponse = await CartService.createCheckout(
        cartItems
      );
      const { checkoutId, nonDiscountURl } = createCheckoutResponse;

      // if (process.env.NODE_ENV === "production") {
      //   try {
      //     const gAClientId = PixelService.getClientId("google");
      //     const fBClientId = PixelService.getClientId("facebook");
      //     const clientId = `${gAClientId}|${fBClientId}`;

      //     await CartService.addCheckoutNote(checkoutId, clientId);
      //   } catch (e) {
      //     Sentry.captureException(e);
      //     return rejectWithValue(e);
      //   }
      // }
      const utm = '?utm_source=walmart&utm_medium=banner'

      let checkoutUrl = `${nonDiscountURl}${utm}`;
      try {
        for (const discount of discountData) {
          const discountResponse = await CartService.applyDiscounts(
            checkoutId,
            discount.discount
          );
          checkoutUrl =
            `${discountResponse.data.data.checkoutDiscountCodeApplyV2.checkout
              .webUrl}${utm}`;
        }
      } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(e);
      }
      console.log(checkoutUrl, "_self")
      // window.open(checkoutUrl, "_self");
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(e);
    }
  }
);
