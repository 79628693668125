import React from "react";
import Box from "@mui/material/Box";
import { CollectionInterface } from "../../../shop-all/interfaces/collection-interfaces";
import ElementContainer from "./element-container";

type Props = {
  collection: CollectionInterface;
};

const SixItemColOne = ({ collection }: Props) => {
  const XSOne = 25;
  const SMOne = 38;
  const xsSize = 110;
  const smSize = 120;
  return (
    <Box
      mb={3}
      width={{ xs: "380px", sm: "100%" }}
      height={{ xs: "340px", sm: "315px" }}
      position="relative"
      data-testid="six-item-one"
    >
      <ElementContainer
        leftXs={XSOne}
        leftSm={SMOne}
        product={collection.collectionProducts[0]}
      />
      <ElementContainer
        leftXs={XSOne + xsSize}
        leftSm={SMOne + smSize}
        rotation="40deg"
        product={collection.collectionProducts[1]}
      />
      <ElementContainer
        leftXs={XSOne + xsSize * 2}
        leftSm={SMOne + smSize * 2}
        product={collection.collectionProducts[2]}
      />
      <ElementContainer
        leftXs={XSOne}
        leftSm={SMOne}
        bottomXs={xsSize}
        bottomSm={smSize}
        rotation="-20deg"
        product={collection.collectionProducts[3]}
      />
      <ElementContainer
        leftXs={XSOne + xsSize}
        leftSm={SMOne + smSize}
        bottomXs={xsSize}
        bottomSm={smSize}
        product={collection.collectionProducts[4]}
      />
      <ElementContainer
        leftXs={XSOne + 50}
        leftSm={SMOne + 60}
        bottomXs={xsSize * 2 - 15}
        bottomSm={smSize * 2 - 20}
        rotation="45deg"
        product={collection.collectionProducts[5]}
      />
    </Box>
  );
};

export default SixItemColOne;
