import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { SHOP_POLICIES } from "../../../routing/CONSTANTS";

const ReturnPolicy = () => {
  const link = (
    <Link
      style={{ color: "rgba(0, 0, 0, 0.85)" }}
      to={`${SHOP_POLICIES}/refund-policy`}
    >
      terms+conditions
    </Link>
  );
  return (
    <Typography component="span" fontFamily="ABC Favorit Book">
      Not your color? No worries, see our {link} for further info on returns.
    </Typography>
  );
};

export default ReturnPolicy;
