import React from "react";
import SyncLoader from "react-spinners/SyncLoader";

const ButtonLoadingAnimation = ({ isLoading }: { isLoading: boolean }) => (
  <SyncLoader
    color="#000"
    size={15}
    margin={3}
    loading={isLoading}
    speedMultiplier={0.75}
  />
);

export default ButtonLoadingAnimation;
