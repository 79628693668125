import { CollectionProductInterface } from "../../shop-all/interfaces/collection-interfaces";

export const initialProductState: CollectionProductInterface = {
  productId: "",
  merchId: "",
  handle: "",
  title: "",
  description: "",
  seoTitle: "",
  seoDescription: "",
  variants: [
    {
      variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTY1Mjg3OTE5NjM0Ng==",
      price: "9.0",
      title: "lacquer",
    },
    {
      variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTY1Mjg3OTIyOTExNA==",
      price: "11.0",
      title: "gel",
    },
  ],
  image: "",
  cursor: "",
  isRandomImg: false,
  tags: [],
};
