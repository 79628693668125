import React from "react";
import Box from "@mui/material/Box";
import bottleRender from "../../../../assets/blankBottleRender.webp";
import bottleRenderPng from "../../../../assets/blankBottleRender.png";
import { PolishTypes } from "../../interfaces/product.interface";
import ImgWithPngFallback from "../../../../components/img-with-png-fallback";

interface Props {
  getBottleBackground: () => JSX.Element;
  polishType: PolishTypes;
  imgSrc?: string
  imgSrcFallback?: string
}
const CartDrawerImage = ({ getBottleBackground, polishType, imgSrc, imgSrcFallback }: Props) => (
  <Box
    position="relative"
    width={{ xs: "68px", sm: "100px", md: "133px" }}
    mt={{ xs: "20px", md: "5px" }}
  >
    <Box position="absolute" top={{ xs: "0" }} left="0">
      <Box component="figure">{getBottleBackground()}</Box>
    </Box>
    {polishType !== PolishTypes.FullCollectionProduct ? (
      <Box
        maxWidth={{ xs: "70%", sm: "70%", md: "68%" }}
        m="0 auto"
        sx={{
          transform: {
            xs: "translateY(-20%)",
            sm: "translateY(-25%)",
            md: "translateY(-18%)",
          },
        }}
      >
        <Box component="figure">
          <ImgWithPngFallback
            src={!!imgSrc ? imgSrc : bottleRender}
            fallback={!!imgSrcFallback ? imgSrcFallback : bottleRenderPng}
            alt={'cart itme'}

            width="100%"
            height="100%"
            maxHeight="155px"
            sx={{ objectFit: "contain" }}
          />
        </Box>
      </Box>
    ) : (
      <Box
        height="100%"
        minHeight={{ xs: "103.38px", sm: "142.53px", md: "162.5px" }}
        width="100%"
      />
    )}
  </Box>
);

export default React.memo(CartDrawerImage);
