import React, { useState } from "react";
import { Box } from "@mui/material";
import MatchPromiseModal from "./match-promise-modal/match-promise-modal";
import GuaranteePolish from "./components/guarantee-polish-img";
import GuaranteeText from "./components/match-guarantee-text";

const MatchGuaranteeBanner = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: { xs: "column", sm: "row" },
        px: { xs: 2, sm: 0 },
        py: { xs: 5, sm: 7 },
      }}
    >
      <Box
        width="100%"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <GuaranteePolish />
        <GuaranteeText setIsVisible={setIsVisible} />
      </Box>
      {isVisible && (
        <MatchPromiseModal isVisible={isVisible} setIsVisible={setIsVisible} />
      )}
    </Box>
  );
};
export default MatchGuaranteeBanner;
