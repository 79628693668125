import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FooterSocial from "./components/footer-social";
import FooterMenu from "./components/footer-menu";
import NewsletterForm from "./components/news-letter-form";
import { useNavigate } from "react-router-dom";
import {
  ColorCallingDesk,
  ColorCallingMobile,
} from "./components/color-calling";

const BlankFooter = () => {
  const navigate = useNavigate();
  const linkNavigate = (to: string) => {
    navigate(to);
  };
  return (
    <Box borderTop="2px solid black" width="100%">
      <Box
        sx={{
          backgroundColor: "white",
          margin: "0 auto",
          padding: { xs: "25px 15px", sm: "30px 20px", md: "30px 40px" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "50%" },
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ maxWidth: "375px" }}>
              <NewsletterForm />

              <Box
                display="flex"
                sx={{
                  flexFlow: "row wrap",
                  alignItems: "center",
                  width: "100%",
                  mt: { xs: "32px", sm: "37px" },
                }}
              >
                <FavoriteBorderIcon fontSize="small" />
                <Box
                  component="span"
                  fontSize="16px"
                  lineHeight="22px"
                  fontFamily="Nib Pro"
                  ml={1}
                >
                  from Memphis, TN
                </Box>
              </Box>
              <FooterSocial />
              <Box>
                <FooterMenu linkNavigate={linkNavigate} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "50%" },
              mt: { xs: "24px", sm: 0 },
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <Box sx={{ maxWidth: "430px" }}>
              <Box
                component="figure"
                width={{ xs: "335", sm: "410px", md: "420px" }}
                height={{ sm: "190px" }}
              >
                <Box width="100%" display={{ xs: "none", sm: "block" }}>
                  <ColorCallingDesk />
                </Box>
                <Box width="100%" display={{ xs: "block", sm: "none" }}>
                  <ColorCallingMobile />
                </Box>
              </Box>
              <Box
                width="100%"
                display="flex"
                sx={{
                  flexFlow: "row wrap",
                  mt: {
                    xs: "4px",
                    sm: 0,
                  },
                  pl: 0,
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  fontSize="14px"
                  lineHeight="22px"
                  letterSpacing={0}
                  component="em"
                >
                  © {new Date().getFullYear()}, blankbeauty
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BlankFooter;
