import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import ShopifyProductPage from "./shopify-product-page";
import CustomProductPage from "./custom-product-page.component";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import BlankFooter from "../../components/blank-footer/blank-footer";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";

interface Props {
  isWalmart?: boolean;
}
const ProductPageContainer = ({ isWalmart = false }: Props) => {
  const { color } = useParams();
  const checkParam = useCallback(() => {
    if (color === "38104" || color === "901") {
      return parseInt("shopifyNum");
    } else if (color!.includes("custom-")) {
      return parseInt("1");
    } else {
      return parseInt(color!);
    }
  }, [color]);

  usePixelServiceOnLoad();
  return (
    <Box>
      <BlankAppbar isWalmartComponent={isWalmart} />
      <Box pt={{ xs: 9, sm: 12, md: 13 }} bgcolor="secondary.main">
        <ScrollToTopOnMount />
        {isNaN(checkParam()) ? (
          <ShopifyProductPage param={color!} />
        ) : (
          <CustomProductPage color={color!} />
        )}
      </Box>
      <BlankFooter />
    </Box>
  );
};
export default ProductPageContainer;
