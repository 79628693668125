import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IPoweredByBlank } from "../interfaces/powered-by-blank.interface";

interface Props {
  company: IPoweredByBlank;
}
const CompanyTextInfo = ({ company }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    maxWidth="345px"
    mr={{ sm: 5 }}
    pl={{ xs: 2.5, md: 0 }}
  >
    <Box>
      <Typography
        fontFamily="Nib Pro"
        textAlign="left"
        fontSize={{ xs: "35px", sm: "42px", md: "48px" }}
        lineHeight={{ xs: "38px", sm: "50px", md: "55px" }}
      >
        {company.title}
      </Typography>
    </Box>
    <Box width="100%" my={3} mx={0}>
      <Typography
        textAlign="left"
        fontFamily="ABC Favorit Light"
        fontSize={{ xs: "18px", sm: "20px" }}
        lineHeight={{ xs: "20px", sm: "27px" }}
      >
        {company.subtitle}
      </Typography>
    </Box>
    <Box>
      <Typography
        textAlign="left"
        fontFamily="ABC Favorit Light"
        fontSize="16px"
        lineHeight="21px"
      >
        {company.text}
      </Typography>
    </Box>
  </Box>
);
export default CompanyTextInfo;
