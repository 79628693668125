import React from "react";

interface Props {
  height: string;
  width: string;
}
const AddMoreCloud = ({ height, width }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 154 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M151.681 88.5984C152.994 80.1112 150.174 71.9454 144.727 66.1301C145.791 63.8654 146.555 61.415 146.954 58.8145C149.182 44.369 139.465 30.8452 125.165 28.2804C123.023 17.5285 114.385 8.74836 102.933 6.97662C97.3362 6.11218 91.8817 7.04806 87.1484 9.34133C83.3289 5.71925 78.4099 3.18308 72.8127 2.31864C61.3541 0.54689 50.4666 6.31221 45.1835 15.9211C30.9264 14.2708 17.8043 24.1868 15.5911 38.4966C15.1842 41.147 15.1771 43.7618 15.5269 46.2837C8.68029 50.2058 3.61852 57.0928 2.31917 65.4943C1.01982 73.8886 3.76844 81.9687 9.09436 87.7768C8.02347 90.0558 7.25243 92.5134 6.84549 95.1282C4.63944 109.431 14.1418 122.833 28.2063 125.584C30.2981 136.4 38.9581 145.244 50.4667 147.023C56.0639 147.888 61.5183 146.952 66.2516 144.658C70.0712 148.281 74.9902 150.817 80.5874 151.681C92.0888 153.46 103.005 147.645 108.266 137.979C122.659 139.829 135.981 129.877 138.209 115.446C138.601 112.881 138.616 110.352 138.302 107.902C145.227 103.994 150.367 97.0714 151.673 88.5984H151.681Z"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </svg>
);

export default AddMoreCloud;
