import React from "react";
import Box from "@mui/material/Box";
import { socialsData } from "../../blank-appbar/CONSTANTS/socials-data";
import SocialLink from "../../blank-appbar/components/social-link.component";

const FooterSocial = () => {
  return (
    <Box
      data-testid="footer-social-container"
      sx={{
        float: "left",
        maxWidth: "208px",
        minWidth: "208px",
        mt: "7px",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-between",
      }}
    >
      {socialsData.map((icon) => (
        <SocialLink key={icon.id} socials={icon} />
      ))}
    </Box>
  );
};
export default FooterSocial;
