import React from "react";
import Box from "@mui/material/Box";
import { useCheckDiscountsQuery } from "../hooks/useCheckDiscountsQuery";
import DiscountTag from "./discount-tag";

const DiscountTags = () => {
  const { discountData } = useCheckDiscountsQuery();
  const isVisible = discountData && discountData.length;
  return (
    <Box
      width="100%"
      mt={{ md: "5px" }}
      mb={{ sm: "30px", md: 0 }}
      p={{
        xs: isVisible ? "15px 21px 0" : "0 21px 0",
        md: isVisible ? "15px 45.5px 0" : "0 45.5px 0",
      }}
      sx={{
        opacity: isVisible ? 1 : 0,
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        {discountData.map((tag) => (
          <DiscountTag key={tag.discount} tag={tag} />
        ))}
      </Box>
    </Box>
  );
};
export default DiscountTags;
