import React from "react";
import Box from "@mui/material/Box";
import { CollectionInterface } from "../../../shop-all/interfaces/collection-interfaces";
import ElementContainer from "./element-container";

type Props = {
  collection: CollectionInterface;
};

const SixItemColTwo = ({ collection }: Props) => {
  const XSOne = 25;
  const SMOne = 38;
  const xsSize = 110;
  const smSize = 120;
  return (
    <Box
      mb={3}
      width={{ xs: "380px", sm: "100%" }}
      height={{ xs: "340px", sm: "315px" }}
      position="relative"
      data-testid="six-item-two"
    >
      <ElementContainer
        leftXs={XSOne}
        leftSm={SMOne}
        rotation="-30deg"
        product={collection.collectionProducts[0]}
      />
      <ElementContainer
        leftXs={XSOne + xsSize}
        leftSm={SMOne + smSize}
        rotation="40deg"
        product={collection.collectionProducts[1]}
      />
      <ElementContainer
        leftXs={XSOne + xsSize * 2}
        leftSm={SMOne + smSize * 2}
        product={collection.collectionProducts[2]}
      />
      <ElementContainer
        leftXs={XSOne + 35}
        leftSm={SMOne + 45}
        bottomXs={xsSize - 10}
        bottomSm={smSize - 15}
        rotation="-20deg"
        product={collection.collectionProducts[3]}
      />
      <ElementContainer
        leftXs={XSOne + xsSize + 40}
        leftSm={SMOne + smSize + 50}
        bottomXs={xsSize - 10}
        bottomSm={smSize - 20}
        product={collection.collectionProducts[4]}
      />
      <ElementContainer
        leftXs={XSOne + 90}
        leftSm={SMOne + 115}
        bottomXs={xsSize * 2 - 20}
        bottomSm={smSize * 2 - 35}
        rotation="45deg"
        product={collection.collectionProducts[5]}
      />
    </Box>
  );
};

export default SixItemColTwo;
