import React from "react";
import Box from "@mui/material/Box";
import { selectIsWalmartPath } from "../../pages/walmart/state/walmart-slice";
import { useAppSelector } from "../../utils/hooks/redux-hooks";

interface Props {
  disabled: boolean;
}
const BlankCameraIcon = ({ disabled }: Props) => {
  const isWalmart = useAppSelector(selectIsWalmartPath);

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={0.2}
      mr={1}
      className={'camera-box'}
      border="2px solid white"

      borderRadius="50%"
      height="48px"
      width="48px"

    >
      <Box position="absolute" top="11px">
        <svg
          width="26"
          height="23"
          viewBox="0 0 26 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={'camera-icon'}
            d="M25.6796 22.3002V3.27912H19.7697L16.6066 0H8.69867L5.79912 3.27912H0V22.3002H25.6796Z"
            fill="white"
          />
          <path
            className="camera-icon-center"
            d="M12.6527 17.3974C15.2731 17.3974 17.3974 15.2731 17.3974 12.6526C17.3974 10.0322 15.2731 7.9079 12.6527 7.9079C10.0322 7.9079 7.90796 10.0322 7.90796 12.6526C7.90796 15.2731 10.0322 17.3974 12.6527 17.3974Z"
            stroke={((): string => {

              if (disabled) {

                return 'rgba(0, 0, 0, 0.26)'


              }
              if (isWalmart) {

                return "#0071DC"

              }

              return "#040404"



            })()

            }
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </Box>
    </Box>
  )
};
export default BlankCameraIcon;
