import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

interface ShoppingBagProps {
  color: string;
  fill: string;
  cartCount: number;
}

const BlankShoppingBag = ({ color, fill, cartCount }: ShoppingBagProps) => (
  <Box position="relative" height="24px" width="17px">
    <svg
      width="17"
      height="24"
      viewBox="0 0 17 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9317 7.24786H1.06799V22.9222H15.9317V7.24786Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        fill={cartCount === 0 ? fill : "black"}
      />
      <path
        d="M13.1037 7.23777V5.69653C13.1037 3.14291 11.0426 1.07281 8.50015 1.07281C5.95767 1.07281 3.89661 3.14291 3.89661 5.69653V7.29821"
        stroke={color}
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
    {cartCount !== 0 && (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        height="100%"
        width="100%"
        top="4px"
        left="0"
      >
        <Typography
          color="white"
          textAlign="center"
          variant="caption"
          fontSize="13px"
        >
          {cartCount}
        </Typography>
      </Box>
    )}
  </Box>
);

export default React.memo(BlankShoppingBag);
