export const productInfoP1 = "Made in the USA";
export const productInfoP2 = "10-free, vegan formula";
export const productInfoP3 = "High gloss finish";
export const productInfoP4 =
  "Note: We at Blank Beauty aim to provide accurate swatches of all our products, but due to different electronic devices and monitor settings, color may differ slightly.";
export const ACCORDION_TITLE_INGREDIENTS = "Ingredients";
export const ACCORDION_DETAILS_INGREDIENTS =
  "Our polish is formulated without formaldehyde, parabens, sulfates and has no animal derived ingredients";
export const ACCORDION_TITLE_SHIPPING = "Shipping";
export const ACCORDION_DETAILS_SHIPPING =
  "All orders are processed within 1 to 2 business days (excluding weekends and holidays) after receiving your order confirmation email. You will receive another notification when your order has shipped.";
export const ACCORDION_TITLE_RETURN_POLICY = "Return policy";
