import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Marquee from "react-fast-marquee";
import { useGetHeaderEntry } from "../../hooks/useGetHeaderEntry";

const BlankMarquee = () => {

  const { blankHeaders } = useGetHeaderEntry()


  return (
    <Marquee
      data-testid="marquee-container"
      style={{
        backgroundColor: "white",
        padding: "5px 0",
        width: "100%",
        overflowY: "hidden",
      }}
      pauseOnHover
    >
      {blankHeaders.map((blankHeader, index) => {

        return <MarqueeText key={index} text={blankHeader} />

      })}
    </Marquee>
  );
};

export default BlankMarquee;

const MarqueeText = ({ text }: { text: string }) => {

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mr="50px">
      <Typography mr="50px" fontFamily="Nib Pro" fontSize={{ xs: "16px", sm: "18px" }}>
        {text}
      </Typography>
    </Box>
  );
};
