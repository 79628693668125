import React from "react";
import Box from "@mui/material/Box";

const TapButton = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    mx={0.4}
    height={{ xs: "18px", sm: "28px" }}
    width={{ xs: "18px", sm: "28px" }}
    borderRadius="50%"
    border="2px solid black"
    fontSize={{ xs: "16px", sm: "20px" }}
    fontFamily="ABC Favorit Book"
    fontWeight="300"
  >
    +
  </Box>
);

export default TapButton;
