import React from "react";

const BlankLogo = () => (
  <svg
    width="95"
    height="31"
    viewBox="0 0 95 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   
  >
    <path d="M27.7202 24.7221V0H31.8431V24.7221H27.7202Z" fill="black" />
    <path
      d="M41.1371 25.0732C40.0605 25.0732 39.0757 24.8542 38.1791 24.4197C37.2825 23.9852 36.5695 23.3734 36.047 22.5843C35.5211 21.7953 35.2563 20.895 35.2563 19.8834C35.2563 18.6355 35.5599 17.6065 36.1706 16.7931C36.7813 15.9797 37.5755 15.3679 38.5568 14.9577C39.5381 14.5476 40.6253 14.2938 41.8219 14.2L46.5202 13.8106V13.317C46.5202 12.6566 46.3896 12.0795 46.1249 11.5859C45.8601 11.0923 45.486 10.7099 44.9953 10.4388C44.5047 10.1677 43.9258 10.0321 43.2551 10.0321H42.9692C42.0832 10.0321 41.3419 10.2546 40.7453 10.703C40.1488 11.1514 39.8134 11.7145 39.7393 12.3993H35.6164C35.7611 10.7273 36.513 9.3612 37.8755 8.30099C39.2381 7.24077 40.9818 6.7124 43.1104 6.7124C44.6388 6.7124 45.966 6.98354 47.0921 7.52581C48.2146 8.06809 49.0688 8.82588 49.6548 9.80267C50.2407 10.7795 50.5337 11.9161 50.5337 13.2093V20.8359C50.5337 21.6354 50.5443 22.2611 50.569 22.706C50.569 23.0362 50.5796 23.3734 50.6043 23.7141C50.629 24.0547 50.6643 24.3919 50.7102 24.7222H46.8379C46.8132 24.5344 46.7885 24.3328 46.7673 24.1208C46.7426 23.9087 46.7179 23.6724 46.6967 23.4151C46.672 23.2031 46.6614 22.998 46.6614 22.7964V22.2854C46.1814 23.1092 45.4542 23.7801 44.4729 24.2981C43.4916 24.816 42.3797 25.0767 41.1371 25.0767V25.0732ZM42.1432 21.7536C42.8597 21.7536 43.5516 21.5832 44.2223 21.2426C44.893 20.9019 45.4401 20.4292 45.8707 19.8313C46.3014 19.2299 46.5167 18.5764 46.5167 17.8707V16.8105L42.3585 17.1998C41.5219 17.2694 40.8089 17.5127 40.2229 17.9229C39.6369 18.3331 39.344 18.9066 39.344 19.6366C39.344 20.2484 39.584 20.7559 40.0605 21.1557C40.5371 21.5554 41.1371 21.757 41.8537 21.757H42.1396L42.1432 21.7536Z"
      fill="black"
    />
    <path
      d="M54.5156 24.7222V7.06349H58.6385V9.60801C59.2139 8.71464 59.9728 8.00552 60.9153 7.48758C61.8578 6.96964 62.9521 6.7124 64.1981 6.7124C65.5854 6.7124 66.7502 6.98354 67.6962 7.52581C68.6387 8.06809 69.3518 8.82588 69.8318 9.80267C70.3084 10.7795 70.5484 11.9161 70.5484 13.2093V24.7222H66.4255V13.8106C66.4255 13.0111 66.3055 12.3263 66.0654 11.7632C65.8254 11.2001 65.4724 10.769 65.0065 10.4736C64.5405 10.1781 63.9475 10.0321 63.2309 10.0321H62.945C62.179 10.0321 61.4695 10.2372 60.8094 10.6508C60.1529 11.0645 59.6269 11.6381 59.2316 12.3819C58.8362 13.1224 58.6385 13.9532 58.6385 14.8708V24.7256H54.5156V24.7222Z"
      fill="black"
    />
    <path
      d="M4.81494 24.7326V0.0626221H15.8388C17.3178 0.0626221 18.6168 0.340711 19.7393 0.889938C20.8618 1.44264 21.7372 2.20739 22.3691 3.1807C23.0009 4.15749 23.3186 5.26637 23.3186 6.51082C23.3186 7.92212 22.9374 9.1179 22.175 10.1051C21.4125 11.0923 20.41 11.798 19.1675 12.2186C20.5512 12.4758 21.6772 13.1224 22.5491 14.1583C23.421 15.1907 23.8552 16.5081 23.8552 18.1071C23.8552 19.3759 23.5198 20.5161 22.8527 21.5276C22.1855 22.5392 21.2642 23.3248 20.0958 23.8879C18.9275 24.451 17.6249 24.7326 16.1953 24.7326H4.81494ZM15.1928 10.3902C16.4106 10.3902 17.3531 10.1016 18.0203 9.52808C18.6874 8.95105 19.0228 8.14807 19.0228 7.11218C19.0228 6.0763 18.6874 5.24551 18.0203 4.6789C17.3531 4.11577 16.4106 3.83421 15.1928 3.83421H9.11082V10.3902H15.1964H15.1928ZM15.4787 20.9645C16.7424 20.9645 17.7379 20.6656 18.4686 20.0642C19.1957 19.4663 19.5593 18.6147 19.5593 17.5092C19.5593 16.4038 19.1957 15.5244 18.4686 14.9369C17.7414 14.3495 16.746 14.054 15.4787 14.054H9.11082V20.961H15.4823L15.4787 20.9645Z"
      fill="black"
    />
    <path
      d="M74.2832 24.7326V0.0626221H78.3991V14.1235L85.4871 7.10871H90.676L83.5527 14.1235L91.0325 24.7326H86.0942L80.6547 16.9774L78.3991 19.1639V24.7326H74.2832Z"
      fill="black"
    />
    <path
      d="M93.2773 21.3573C94.2268 21.3573 94.9999 22.1151 94.9963 23.0536C94.9963 23.9887 94.2233 24.75 93.2737 24.7465C92.3242 24.7465 91.5547 23.9852 91.5547 23.0502C91.5547 22.1151 92.3242 21.3573 93.2737 21.3573H93.2773ZM93.2773 21.7014C92.5219 21.7014 91.9077 22.3063 91.9077 23.0502C91.9077 23.7941 92.5219 24.4024 93.2773 24.4024C94.0327 24.4024 94.6469 23.7975 94.6469 23.0536C94.6469 22.3098 94.0327 21.7014 93.2773 21.7014Z"
      fill="black"
    />
    <path
      d="M92.5254 22.1255C92.5254 22.1255 92.5324 22.1255 92.536 22.1255C92.7795 22.1255 93.0231 22.1255 93.2667 22.1255C93.3832 22.1255 93.5032 22.1395 93.6161 22.1777C93.6903 22.202 93.7573 22.2368 93.8103 22.2959C93.8668 22.3584 93.8985 22.4314 93.9091 22.5114C93.9232 22.6018 93.9197 22.6922 93.895 22.7791C93.8597 22.8972 93.782 22.9772 93.6726 23.0328C93.6408 23.0467 93.6091 23.0606 93.5773 23.0711C93.6161 23.0815 93.655 23.0989 93.6903 23.1232C93.7573 23.1684 93.8032 23.231 93.842 23.3005C93.895 23.3943 93.9409 23.4917 93.9903 23.5855C94.0397 23.6863 94.0891 23.7837 94.1421 23.8845L94.1491 23.8984C94.1491 23.8984 94.1386 23.8984 94.135 23.8984C93.9797 23.8984 93.8244 23.8984 93.6691 23.8984C93.6585 23.8984 93.6514 23.8984 93.6479 23.8845C93.5738 23.735 93.4996 23.582 93.422 23.4326C93.4043 23.3978 93.3832 23.3665 93.362 23.3352C93.3196 23.2727 93.2596 23.2379 93.182 23.231C93.1184 23.224 93.0549 23.2275 92.9878 23.224V23.8949H92.5254V22.1221V22.1255ZM92.9878 22.9112C93.069 22.9112 93.1467 22.9112 93.2243 22.9112C93.2526 22.9112 93.2808 22.9042 93.309 22.8972C93.3796 22.8799 93.4255 22.8382 93.4467 22.7686C93.4608 22.713 93.4608 22.6539 93.4467 22.5983C93.429 22.5357 93.3937 22.494 93.3302 22.4766C93.302 22.4697 93.2737 22.4627 93.2455 22.4593C93.1608 22.4593 93.0761 22.4593 92.9949 22.4593H92.9913V22.9146L92.9878 22.9112Z"
      fill="black"
    />
  </svg>
);

export default BlankLogo;
