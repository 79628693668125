import React from "react";
import Box from "@mui/material/Box";
import { IPoweredByBlank } from "../interfaces/powered-by-blank.interface";
import CompanyTextInfo from "./company-text-info";
import PPbProducts from "./ppb-products";

interface Props {
  poweredByBlankCompany: IPoweredByBlank;
}
const PoweredByBlankCompany = ({ poweredByBlankCompany }: Props) => (
  <Box
    maxWidth={{ xs: "375px", sm: "800px", md: "1150px" }}
    overflow="hidden"
    display="flex"
    flexDirection={{ xs: "column-reverse", sm: "row" }}
    mt={{ xs: 6, md: 6 }}
  >
    <CompanyTextInfo company={poweredByBlankCompany} />
    <PPbProducts ppbProducts={poweredByBlankCompany.products} />
  </Box>
);
export default PoweredByBlankCompany;
