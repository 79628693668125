import { useEffect, useRef } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../utils/hooks/redux-hooks";
import { RootState } from "../../../store";
import {
  fetchAllProductsFirstPage,
  fetchAllProductsSecondPage,
  getCollectionProductsCount,
} from "../state/collection-actions";
import { SortState } from "../interfaces/sort-state";
import { getProcessedValue } from "../utils/get-processed-filter-value";
import PixelService from "../../../services/pixels/pixel-service";

export const useGetShopAllProducts = (sortState: SortState) => {
  const dispatch = useAppDispatch();
  const rendered = useRef<boolean>(true);

  const shopAllData = useAppSelector(
    (state: RootState) => state.collections.shopAllCollection
  );

  const status = useAppSelector(
    (state: RootState) => state.collections.shopAllProductsStatus
  );

  const isUninitialized = status === undefined;
  const isLoadingShopAll = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const fetchSecondPage = () => {
    const processedValue = getProcessedValue(sortState.sortKey);
    const lastCursor = shopAllData.cursor;
    const dispatchValue: SortState = {
      sortKey: processedValue,
      reverse: sortState.reverse,
      minPrice: sortState.minPrice,
      maxPrice: sortState.maxPrice,
      available: sortState.available,
    };
    dispatch(
      fetchAllProductsSecondPage({
        cursor: lastCursor,
        sortState: dispatchValue,
      })
    );
    PixelService.fireHotjarEvent("Show more");
  };

  useEffect(() => {
    const fetchFirstPage = () => {
      const processedValue = getProcessedValue(sortState.sortKey);
      const dispatchValue: SortState = {
        sortKey: processedValue,
        reverse: sortState.reverse,
        minPrice: sortState.minPrice,
        maxPrice: sortState.maxPrice,
        available: sortState.available,
      };
      dispatch(getCollectionProductsCount(dispatchValue));
      dispatch(fetchAllProductsFirstPage(dispatchValue));
    };
    if (rendered.current) {
      if (!shopAllData.collectionProducts.length) {
        fetchFirstPage();
      }
    }
    return () => {
      rendered.current = false;
    };
  }, [sortState, shopAllData.collectionProducts.length, dispatch]);
  return {
    fetchSecondPage,
    shopAllData,
    isUninitialized,
    isLoadingShopAll,
    isError,
    isSuccess,
  };
};
