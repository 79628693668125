import { randoImages } from "../CONSTANTS/rando-images";
import { CollectionProductInterface } from "../interfaces/collection-interfaces";

export const useSpliceShopAllGridImages = (
  collectionProducts: CollectionProductInterface[]
) => {
  let gridProducts: CollectionProductInterface[] = Object.assign(
    [],
    collectionProducts
  );

  gridProducts.splice(4, 0, randoImages[0]);

  gridProducts.splice(13, 0, randoImages[1]);
  gridProducts.splice(19, 0, randoImages[2]);
  gridProducts.splice(30, 0, randoImages[3]);
  gridProducts.splice(36, 0, randoImages[4]);

  return { gridProducts };
};
