import { useEffect, useRef } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../utils/hooks/redux-hooks";
import { RootState } from "../../../../store";
import { fetchBlankLibraries } from "../state/library-list-actions";

export const useGetBlankLibraryListQuery = () => {
  const dispatch = useAppDispatch();
  const rendered = useRef<boolean>(true);

  const data = useAppSelector(
    (state: RootState) => state.libraryList.libraryList
  );
  const status = useAppSelector((state: RootState) => state.libraryList.status);

  const isUninitialized = status === undefined;
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  useEffect(() => {
    if (rendered.current) {
      if (!data.length && status === undefined) {
        dispatch(fetchBlankLibraries());
      }
    }
    return () => {
      rendered.current = false;
    };
  }, [data.length, dispatch, status]);

  return { data, isUninitialized, isLoading, isError, isSuccess };
};
