export const FETCH_PRODUCT_BY_HANDLE_QUERY = `
  query queryProductByHandle($handle: String!){
  product(handle: $handle) {
    id
		handle
    title
    seo { title description}
		metafield(namespace: "custom_fields" key: "image") { 
						value
					}
    hex: metafield(namespace: "custom_fields", key: "hex") {
    value
}
		tags
    description
    descriptionHtml
    variants(first: 3) {
      edges {
        cursor
        node {
          id
          title
          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
}
`;
