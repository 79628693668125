import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CollectionProductInterface } from "../../shop-all/interfaces/collection-interfaces";
import AddToCartButton from "../../../components/add-to-cart-button";
import { AddToCartInterface } from "../../cart/interfaces/add-to-cart.interface";
import useCartActions from "../../cart/hooks/useCartActions";

interface Props {
  buyAndSaveText: string;
  product: CollectionProductInterface;
  products: CollectionProductInterface[];
}
const OpenEndorseBuyAndSave = ({
  products,
  buyAndSaveText,
  product,
}: Props) => {
  const { createOrAddToCart } = useCartActions();
  const savingsPrice = parseInt(product.variants[0].price);
  const originalPrice = parseInt(product.variants[1].price);
  const savingsText = `${
    buyAndSaveText.length && buyAndSaveText.split("$")[0]
  } $${savingsPrice} (save $${originalPrice - savingsPrice})`;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addCollectionToCart = async () => {
    let productValue = "";

    products.forEach((product, idx) => {
      if (idx !== products.length - 1) {
        productValue += `${product.title} / `;
      } else {
        productValue += `${product.title}`;
      }
    });
    setIsLoading(true);
    const itemToAdd: AddToCartInterface = {
      key: product.title,
      value: productValue,
      quantity: 1,
      merchId: product.merchId,
      img: product.image,
    };

    createOrAddToCart(itemToAdd, 1);
    setIsLoading(false);
  };

  return (
    <Box
      py={6}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box px="10px" mb={1}>
        <Typography
          textAlign="center"
          component="p"
          fontSize={{ xs: "22px", sm: "24px" }}
        >
          {savingsText}
        </Typography>
      </Box>
      <Box width="230.68px">
        <AddToCartButton
          isLoading={isLoading}
          polishPrice={savingsPrice}
          btnStyle="collectionPageBtn"
          createOrAddToCart={addCollectionToCart}
        />
      </Box>
    </Box>
  );
};
export default OpenEndorseBuyAndSave;
