import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CollectionProductInterface } from "../../shop-all/interfaces/collection-interfaces";
import LandingService from "../services/landing-service";
import * as Sentry from "@sentry/react";
import { RequestState } from "../../../services/types/requestState.type";

export const fetchTrendingProducts = createAsyncThunk<
  CollectionProductInterface[],
  void,
  { state: { landing: { status: RequestState | undefined } } }
>(
  "landing/trending",
  async (_, { rejectWithValue }) => {
    const trendingProducts: CollectionProductInterface[] = [];

    const req1 = LandingService.fetchTrendingShades("the-ozarks");
    const req2 = LandingService.fetchTrendingShades("spark");
    const req3 = LandingService.fetchTrendingShades("made-in-memphis");
    const req4 = LandingService.fetchTrendingShades("kelsey");

    await axios
      .all([req1, req2, req3, req4])
      .then(
        axios.spread((...res) => {
          const res1 = res[0];
          if (res1 !== null) trendingProducts.push(res1);
          const res2 = res[1];
          if (res2 !== null) trendingProducts.push(res2);
          const res3 = res[2];
          if (res3 !== null) trendingProducts.push(res3);
          const res4 = res[3];
          if (res4 !== null) trendingProducts.push(res4);
        })
      )
      .catch((err) => {
        Sentry.captureException(err);
        rejectWithValue(err);
      });

    return trendingProducts;
  },
  {
    condition: (_, { getState }) => {
      const { landing } = getState();
      const fetchStatus = landing.status;
      if (fetchStatus === "fulfilled") {
        return false;
      }
    },
  }
);
