import React, { useState } from "react";
import Box from "@mui/material/Box";
import { infoGraphics } from "../../../landing/how-it-works/CONSTANTS/info-graphics";
import HIWCreateGraphic from "./hiw-create-graphic";

const HowItWorksCreateGraphics = () => {
  const [activeIdx, setActiveIdx] = useState<number>(0);

  return (
    <Box
      pt={{ xs: 1, sm: 5 }}
      mt={{ xs: 3, md: 0 }}
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        overflow: { xs: "auto", sm: "auto", md: "visible" },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box maxWidth="900px">
        <Box
          width={{ xs: "1100px", sm: "1200px", md: "100%" }}
          pl={{ xs: 1, sm: 1, md: 0 }}
          minHeight={{ sm: "375px", md: "452px" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {infoGraphics.map((graphic, idx) => (
            <HIWCreateGraphic
              key={graphic.slideNumber}
              graphic={graphic}
              idx={idx}
              activeIdx={activeIdx}
              setActiveIdx={setActiveIdx}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default HowItWorksCreateGraphics;
