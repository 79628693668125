import React from "react";
import { Box, Typography } from "@mui/material";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
// import AboutUsTitle from "./components/about-components/about-us-title";
// import JobMountainDesktop from "./components/about-components/job-mountain-desktop";
// import JobMountainMobile from "./components/about-components/job-mountain-mobile";
// import DevIntroTitle from "./components/about-components/dev-intro-title";
// import EmployeeGrid from "./components/about-components/employee-data/employee-grid";
// import BlankFooter from "../../components/blank-footer/blank-footer";
// import AboutUsForm from "./components/about-components/form/about-us-form";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";
import SEO from "../../components/seo";

interface Props {
  isWalmart?: boolean;
}
const AboutUs = ({ isWalmart = false }: Props) => {
  usePixelServiceOnLoad();
  const text = `_blank beauty is a modern cosmetics start-up 
  that creates devices that capture the process 
  of creating custom nail polish from start to finish. 
  We're changing the way people take care of their nails 
  by empowering the experts they trust with the tools they need.`;
  return (
    <Box
      bgcolor="secondary.main"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="start"
    >
      <SEO
        title="About Us | Blank Beauty"
        urlEndpoint="about"
        description="Long-lasting, chip-resistant nail polish colors by Blank Beauty. 13-free and full coverage that lasts. Want something unique? Create your own nail polish."
        type="website"
      />
      <BlankAppbar isWalmartComponent={isWalmart} />
      <Box px={{ xs: 3, md: 10 }} display="flex" flexDirection="column">
        <Box mb={5}>
          <Typography variant="h3">About Us</Typography>
        </Box>
        <Box
          sx={{
            maxWidth: 175,
            py: 6,
            mb: 5,
            borderRadius: "5px",
            boxShadow: "5px 10px 27px -7px #23232348",
          }}
        >
          <Typography variant="h2" textAlign="center">
            [ _b ]
          </Typography>
        </Box>
        <Box sx={{ maxWidth: { xs: "100%", md: "50%" } }}>
          <Typography variant="body1" lineHeight={2}>
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
    // <Box
    //   bgcolor="secondary.main"
    //   display="flex"
    //   flexDirection="column"
    //   justifyContent="start"
    // >
    //   <BlankAppbar position={false} float={false} />
    //   <AboutUsTitle />
    //   <Box py={6} display={{ xs: "none", md: "flex" }} justifyContent="center">
    //     <JobMountainDesktop />
    //   </Box>
    //   <Box py={5} display={{ xs: "flex", md: "none" }} justifyContent="center">
    //     <JobMountainMobile />
    //   </Box>
    //   <DevIntroTitle />
    //   <EmployeeGrid />
    //   <AboutUsForm />
    //   <BlankFooter />
    // </Box>
  );
};
export default AboutUs;
