import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as Sentry from "@sentry/react";
import {
  BASE_COAT_PRODUCT_ID,
  PEARL_TOP_COAT_PRODUCT_ID,
  TOP_COAT_PRODUCT_ID,
  MATT_TOP_COAT_PRODUCT_ID,
  CUTICLE_OIL_PRODUCT_ID
} from "../CONSTANTS/merch-ids";
import { UpSellInterface } from "../interfaces/up-sell-interface";
import UpSellService from "../services/upSell-service";
import { RequestState } from "../../../services/types/requestState.type";

export const fetchUpSells = createAsyncThunk<
  UpSellInterface[],
  void,
  { state: { upSells: { status: RequestState | undefined } } }
>(
  "cart/upSells",
  async (_, { rejectWithValue }) => {
    const upSellsArr: UpSellInterface[] = [];
    const upSellRequestOne = UpSellService.fetchUpSells(
      PEARL_TOP_COAT_PRODUCT_ID
    );
    const upSellRequestTwo = UpSellService.fetchUpSells(TOP_COAT_PRODUCT_ID);
    const upSellRequestThree = UpSellService.fetchUpSells(BASE_COAT_PRODUCT_ID);
    const upSellRequestFour = UpSellService.fetchUpSells(MATT_TOP_COAT_PRODUCT_ID);
    const upSellRequestFive = UpSellService.fetchUpSells(CUTICLE_OIL_PRODUCT_ID);
    if (
      upSellRequestOne !== null &&
      upSellRequestTwo !== null &&
      upSellRequestFour !== null &&
      upSellRequestThree !== null &&
      upSellRequestFive !== null
    ) {
      await axios
        .all([upSellRequestFive, upSellRequestOne, upSellRequestTwo, upSellRequestThree, upSellRequestFour])
        .then(
          axios.spread((...res) => {

            const resOne = res[0];
            if (resOne !== null) upSellsArr.push(resOne);
            const resTwo = res[1];
            if (resTwo !== null) upSellsArr.push(resTwo);
            const resThree = res[2];
            if (resThree !== null) upSellsArr.push(resThree);
            const resFour = res[3];
            if (resFour !== null) upSellsArr.push(resFour);
            const resFive = res[4];
            if (resFive !== null) upSellsArr.push(resFive);
          })
        )
        .catch((err) => {
          Sentry.captureException(err);
          return rejectWithValue(err);
        });
    }

    return upSellsArr;
  },
  {
    condition: (_, { getState }) => {
      const { upSells } = getState();
      const fetchStatus = upSells.status;
      if (fetchStatus === "fulfilled" || fetchStatus === "pending") {
        return false;
      }
    },
  }
);
