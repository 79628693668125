import React, { ReactNode, SyntheticEvent } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface AccordionProps {
  panelTitle: string;
  panelDetails: ReactNode;
  position: string;
}
const FilterAccordion = ({
  panelTitle,
  panelDetails,
  position,
}: AccordionProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (e: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expanded === panelTitle}
      onChange={handleChange(panelTitle)}
      sx={{
        backgroundColor: "transparent",
        position: position,
        top: { xs: "-16px", md: "-23px" },
        zIndex: 9,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          justifyContent: "flex-start",
          width: "185px",
          px: 0,
          my: { xs: 0, md: 0 },
          minHeight: { xs: 0, md: "48px" },
          "& .MuiAccordionSummary-content": {
            flexGrow: "initial",
            my: { xs: "5px", md: "12px" },
          },
        }}
      >
        <Typography variant="body1" color="black">
          {panelTitle}
        </Typography>
      </AccordionSummary>
      {panelDetails}
    </Accordion>
  );
};
export default FilterAccordion;
