import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";
import SEO from "../../components/seo";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
// import Hero from "../landing/hero/hero.component";
import Hero from "./hero/hero.component";
import HowItWorks from "../landing/how-it-works/how-it-works";
import PopularShades from "./popular/popular-shades";
import MatchGuaranteeBanner from "../landing/match-guarantee-banner/match-guarantee-banner";
import CreatingYourColors from "../landing/creating-your-colors/creating-your-colors";
import AboutYourPolish from "../landing/about-your-polish/about-your-polish";
import TrendingShades from "../landing/trending-shades/trending-shades";
import BlankFooter from "../../components/blank-footer/blank-footer";
import ChooseYourColor from "./ways-to-choose-your-color/ChooseYourColor";
import { InfoBannerDesktop, InfoBannerMobile } from "./info-banner/info-banner";
const WalmartLandingPage = () => {
  usePixelServiceOnLoad();
  useEffect(() => {
    document.body.style.background = "#E7E7E7";

    return () => {
      document.body.style.background = "white";
    };
  });

  return (
    <Box
      component="main"
      id="MainContent"
      role="main"
      sx={{ backgroundColor: "#E7E7E7" }}
      maxWidth="2000px"
      margin="0 auto"
    >
      <SEO
        title="Create your own custom color of nail polish | Blank Beauty"
        urlEndpoint="walmart"
        description="Create your own nail polish color or choose from our range of high gloss, 13-free and incredibly long-lasting shades. Our nail colors are made in the USA."
        type="website"
      />
      <BlankAppbar color="walmart.walmartYellow" isWalmartComponent />
      <ScrollToTopOnMount />
      <Hero isWalmartComponent />
      <ChooseYourColor />

      <HowItWorks isWalmartComponent={true} />
      <InfoBannerMobile />
      <InfoBannerDesktop />
      {/* <MatchGuaranteeBanner /> */}
      {/* <CreatingYourColors />
      <AboutYourPolish />
      <TrendingShades /> */}
      <PopularShades />
      <BlankFooter />
    </Box>
  );
};

export default WalmartLandingPage;
