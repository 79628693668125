import React from "react";
import Box from "@mui/material/Box";
import { IPoweredByBlank } from "../interfaces/powered-by-blank.interface";

interface Props {
  company: IPoweredByBlank;
  idx: number;
  changeIndex: (idx: number) => void;
  activeIdx: number;
}

const CompanyLogo = ({ company, idx, activeIdx, changeIndex }: Props) => (
  <Box
    mr={{ xs: 1.75, sm: 3.5 }}
    display="flex"
    position="relative"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="90px"
    sx={{ pointer: "cursor" }}
    onClick={() => changeIndex(idx)}
  >
    <Box
      m="0 auto"
      component="img"
      src={company.logo}
      width={{ xs: "70px", sm: "115px" }}
      sx={{ objectFit: "cover", cursor: "pointer" }}
    />
    {idx === activeIdx && (
      <Box
        position="absolute"
        bottom="0"
        height="3px"
        width={{ xs: "50px", sm: "115px" }}
        sx={{ backgroundColor: "black", cursor: "pointer" }}
      />
    )}
  </Box>
);

export default CompanyLogo;
