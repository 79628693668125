import React from "react";
import Box from "@mui/material/Box";
import { BuyInBulkInterface } from "../interfaces/buy-in-bulk.interface";
import BibInfoBlock from "./bib-info-block";
import BibInfoCard from "./bib-info-card";

interface Props {
  bibData: BuyInBulkInterface;
}
const BuyInBulkDesktop = ({ bibData }: Props) => {
  const getBibRow = (): JSX.Element[] => {
    const elementArr = [<BibInfoBlock key="info-block" bibData={bibData} />];

    bibData.bibInfoCards.forEach((card) => {
      const element = <BibInfoCard key={card.cardTitle} bibData={card} />;
      elementArr.push(element);
    });
    return elementArr;
  };

  return (
    <Box
      display={{ xs: "none", md: "flex" }}
      alignItems="center"
      justifyContent="center"
      width="100%"
      mb={9}
    >
      {getBibRow()}
    </Box>
  );
};
export default BuyInBulkDesktop;
