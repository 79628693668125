import React, { useState } from "react";
import { Box } from "@mui/material";
import { aboutYourPolishSlideData } from "../CONSTANTS/about-your-polish";
import AboutSteps from "./about-steps";
import AboutSlides from "./about-slides";

const AboutYourPolishInfo = () => {
  const [activeIdx, setActiveIdx] = useState<number>(0);
  return (
    <Box
      pt={{ xs: 2, sm: 0 }}
      pb={{ xs: 2, sm: 4 }}
      px={{ xs: 0, sm: 3 }}
      width="100%"
      sx={{ backgroundColor: "#E7E7E7" }}
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="center"
      alignItems="center"
    >
      <AboutSteps
        infoItems={aboutYourPolishSlideData}
        activeIdx={activeIdx}
        setActiveIdx={setActiveIdx}
      />

      <AboutSlides
        activeIdx={activeIdx}
        setActiveIdx={setActiveIdx}
        polishSlideData={aboutYourPolishSlideData}
      />
    </Box>
  );
};
export default AboutYourPolishInfo;
