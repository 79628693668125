import React from "react";
import Box from "@mui/material/Box";
import SEO from "../../components/seo";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import WholeSaleHero from "./components/landing_components/hero/wholesale_hero";
import BlankFooter from "../../components/blank-footer/blank-footer";
import MakeItYourOwn from "./components/landing_components/make-it-your-own/make-it-your-own";
import BuyInBulk from "./components/landing_components/buying-in-bulk/buy-in-bulk";
import { bibTestData } from "./components/landing_components/buying-in-bulk/interfaces/buy-in-bulk-test-data";
import AllYouNeedToKnow from "./components/landing_components/all-you-need-to-know/all-you-need-to-know";
import { needToKnowData } from "./components/landing_components/all-you-need-to-know/interfaces/need-to-know-data";
import Inquiries from "./components/landing_components/inquiries/inquiries";
import PoweredByBlank from "./components/landing_components/powered-by-blank/powered-by-blank";
import { poweredByBlankData } from "./components/landing_components/powered-by-blank/interfaces/powered-by-blank-data";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";

const WholeSaleLanding = () => {
  usePixelServiceOnLoad();
  return (
    <Box
      sx={{
        backgroundColor: "rgb(228, 228, 228)",
      }}
      maxWidth="2000px"
      margin="0 auto"
    >
      <SEO
        title="Create your own custom color of nail polish | Blank Beauty"
        urlEndpoint="wholesale"
        description="Create your own nail polish color or choose from our range of high gloss, 13-free and incredibly long-lasting shades. Our nail colors are made in the USA."
        type="website"
      />
      <BlankAppbar color="transparent" />
      <WholeSaleHero />
      <MakeItYourOwn />
      <AllYouNeedToKnow needToKnowData={needToKnowData} />
      <BuyInBulk bibData={bibTestData} />
      <PoweredByBlank poweredByBlankData={poweredByBlankData} />
      <Inquiries />
      <BlankFooter />
    </Box>
  );
};
export default WholeSaleLanding;
