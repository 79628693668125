import React from "react";
import Box from "@mui/material/Box";
import { CollectionProductInterface } from "../interfaces/collection-interfaces";

interface Props {
  product: CollectionProductInterface;
}
const RandomShopAllImage = ({ product }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="flex-start"
    alignItems="center"
    pt={0}
    width="100%"
    height="100%"
    borderRadius="20px"
    position="relative"
  >
    <Box
      position="absolute"
      top={{ xs: "-38px", md: "-50px" }}
      borderRadius="20px"
      component="img"
      alt={product.description}
      width="100%"
      height={{ xs: "100%", md: "105%" }}
      maxWidth={{ md: "300px" }}
      px={0.25}
      sx={{ objectFit: "cover" }}
      src={product.image}
    />
  </Box>
);
export default RandomShopAllImage;
