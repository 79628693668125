import React from "react";
import Box from "@mui/material/Box";
import ProductDetails from "./components/product-details.component";
import ShopifyBottleImage from "../../components/ico-bottle-images/shopify-bottle-image";
import ProductSkeleton from "./components/product-skeleton";
import { getFillImage } from "../../utils/get-upSell-background-image";
import { Typography } from "@mui/material";
import SEO from "../../components/seo";
import PageNotFound from "../../routing/page-not-found";
import "./product.css";

import { isUpsellProduct } from "../../utils/is-upsell-product";
import { capitalize } from "../../utils/capitalize-string";
import { useGetShopifyProduct } from "./hooks/useGetShopifyProduct";
import { trackViewedProduct } from "../../components/klaviyo";
import { useEffect } from "react";

interface Props {
  param: string;
}

const ShopifyProductPage = ({ param }: Props) => {

  const { product, activeVariant, isSuccess } =
    useGetShopifyProduct(param);

  useEffect(() => {

    trackViewedProduct({
      value: product.title,
      productId: product.productId,
      img: product.image, price: parseInt(product.variants[0].price)
    })
  }, [product])





  return (
    <>
      {!isSuccess ? (
        <PageNotFound />
      ) : (
        <Box className='shopify-product-page' bgcolor="secondary.main" py={{ sm: "4rem", md: "3rem" }}>
          <SEO
            title={product.seoTitle}
            description={product.seoDescription}
            type="website"
            urlEndpoint={`products/${product.handle}`}
          />
          <Box display="none" visibility="hidden">
            <Typography component="h1">
              {" "}
              {`${product.title} Nail Color by Blank`}{" "}
            </Typography>
          </Box>
          <Box
            className="product-page-container"
            p={{
              sm: "35px 20px",
            }}
            display="flex"
            justifyContent="center"
            flexDirection={{ xs: "column", sm: "row" }}
            position="relative"
            maxWidth="1400px"
            m="0 auto"
          >
            <Box
              display="flex"
              justifyContent="center"
              mt={{ xs: "40px", sm: "75px", md: "50px" }}
              m={{ xs: "40px auto 0 auto" }}
              width={{ sm: "55%", md: "45%" }}
              maxWidth={{ xs: "270px", sm: "375px", md: "550px" }}
              position="relative"
            >
              {!product.image ? (
                <ProductSkeleton
                  size={{ xs: "300px", sm: "367px", md: "400px", lg: "450px" }}
                />
              ) : (
                <ShopifyBottleImage
                  productTitle={product.title}
                  img={
                    isUpsellProduct(product)
                      ? getFillImage(product.title)
                      : product.image
                  }
                  isCart={product.title.includes("Anti-peel") ? true : false}
                />
                // is Cart is that because the images that are pulled need to be fixed, but I haven't.
                // without leaving this, the anti-peel upSell's filter doesn't work
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              width={{ xs: "100%", sm: "50%", md: "35%" }}
              mt={{ xs: "10px", sm: 0 }}
              ml={{ sm: "50px", md: "100px" }}
            >
              <ProductDetails
                name={
                  isUpsellProduct(product)
                    ? capitalize(product.title)
                    : product.title
                }
                colorData={product.hex!}
                product={product}
                polishPrice={parseInt(activeVariant.price.split(".")[0])}
                customPolish={false}
                isLoadingPrice={!activeVariant}
                activeVariant={activeVariant}
                variants={product.variants}
                isLoading={!product.image}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default ShopifyProductPage;
