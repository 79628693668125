import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const TrendingTitle = () => (
  <Box
    width="100%"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    mb={{ xs: 0, sm: 5 }}
  >
    <Box mb={{ xs: 1, sm: 2 }}>
      <Typography
        align="center"
        variant="h3"
        fontSize={{ xs: "38px", sm: "48px" }}
        lineHeight={{ xs: "38px", sm: "48px" }}
      >
        Need some inspo?
      </Typography>
    </Box>
    <Box mb={5} px={{ xs: 5, sm: 0 }}>
      <Typography
        align="center"
        variant="h4"
        fontSize={{ xs: "20px", sm: "24px" }}
        lineHeight={{ xs: "27px", sm: "32px" }}
      >
        We've got you covered with our trending shades
      </Typography>
    </Box>
    <Box
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      px={{ xs: 2, sm: 0 }}
      mb={{ xs: 3 }}
    >
    
      <li
        style={{
          paddingLeft: "1em",
          fontFamily: "ABC Favorit Book",
          fontStyle: "italic",
          fontSize: "16px",
          listStyleType: 'none',

        }}
      >
        13-free & vegan
      </li>
      <li
        style={{
          paddingLeft: "1em",
          fontFamily: "ABC Favorit Book",
          fontStyle: "italic",
          fontSize: "16px",
        }}
      >
        7+ days of wear
      </li>

      <li
        style={{
          paddingLeft: "1em",
          fontFamily: "ABC Favorit Book",
          fontStyle: "italic",
          fontSize: "16px",
        }}
      >
        Made to order
      </li>
    </Box>
  </Box>
);
export default React.memo(TrendingTitle);
