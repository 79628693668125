import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { CreateYourColorInterface } from "../CONSTANTS/create-ur-color";
import ExpandPanel from "./cyc-expand-panel";
import CreateYourColorImage from "./create-your-color-img";

interface Props {
  slide: CreateYourColorInterface;
  activeSlide: string;
  switchSlide: (slideTitle: string) => void;
}
const CreateYourColorSlide = ({ slide, activeSlide, switchSlide }: Props) => (
  <Box
    pt={{ xs: 2, md: 3 }}
    pb={{ xs: 2, md: 3 }}
    px={1.5}
    mr={{ xs: 0, md: 2.5 }}
    mb={{ xs: 2, md: 0 }}
    width={{ xs: "100%", md: "100%" }}
    maxWidth={{ xs: "361px", sm: "700px", md: "361px" }}
    borderRadius="20px"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    sx={{ backgroundColor: slide.slideColor }}
  >
    <Box my={1}>
      <Typography
        textAlign="center"
        variant="h6"
        fontSize="32px"
        lineHeight="38px"
      >
        {slide.title}
      </Typography>
    </Box>
    <Box mb={2} px={1.5}>
      <Typography
        textAlign="center"
        variant="h4"
        fontSize={{ xs: "20px", sm: "22px" }}
        lineHeight="32px"
      >
        {slide.subtitle}
      </Typography>
    </Box>
    <Box p={{ xs: 0, md: 1, xl: 2 }} mb={2}>
      <CreateYourColorImage img={slide.image} />
    </Box>
    <ExpandPanel
      activeSlide={activeSlide}
      switchSlide={switchSlide}
      slide={slide}
    />
  </Box>
);

export default React.memo(CreateYourColorSlide);
