import Box from "@mui/material/Box";
import React from "react";
import WalmartLogo from "./walmart-logo";
import blanklogo from "../assets/blank_short.png";
import { Link } from "react-router-dom";

const BlankXWalmartLogo = () => (
  <Link to="/walmart">
    <Box display="flex" alignItems="center" justifyContent="center">
      <img
        src={blanklogo}
        alt="blanklogo"
        style={{ height: "100%", maxHeight: "1.5rem" }}
      />
      <Box
        component="span"
        mx={1.25}
        sx={{
          borderLeft: "1px solid white",
        }}
        height="25px"
      />
      <WalmartLogo />
    </Box>
  </Link>
);
export default BlankXWalmartLogo;

const BlankWhiteLogo = () => (
  <svg
    width="59"
    height="18"
    viewBox="0 0 59 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 17.0191V0.000183105H7.48586C8.49169 0.000183105 9.37288 0.187106 10.1384 0.569854C10.9039 0.952602 11.4914 1.47777 11.9275 2.15425C12.3548 2.83074 12.5684 3.59623 12.5684 4.45074C12.5684 5.42096 12.3102 6.24876 11.794 6.93415C11.2777 7.61063 10.5923 8.10019 9.75564 8.39393C10.6992 8.57195 11.4647 9.01701 12.0521 9.7291C12.6396 10.4412 12.9422 11.3491 12.9422 12.4528C12.9422 13.3251 12.7108 14.1173 12.2658 14.8116C11.8118 15.5059 11.1887 16.0489 10.3965 16.4405C9.60434 16.8322 8.7231 17.028 7.74398 17.028H0.0089098L0 17.0191ZM7.04972 7.12107C7.87752 7.12107 8.51838 6.92525 8.97233 6.5247C9.42629 6.12415 9.65774 5.57228 9.65774 4.86019C9.65774 4.1481 9.42629 3.56953 8.97233 3.18678C8.51838 2.80403 7.87752 2.59931 7.04972 2.59931H2.91959V7.12107H7.04972ZM7.24552 14.4111C8.10002 14.4111 8.78543 14.2064 9.27499 13.788C9.77346 13.3786 10.0137 12.7911 10.0137 12.0256C10.0137 11.2601 9.76456 10.6548 9.27499 10.2543C8.77653 9.84481 8.10002 9.64899 7.24552 9.64899H2.91959V14.4111H7.24552Z"
      fill="white"
    />
    <path
      d="M15.319 17.0191V0.000183105H18.1139V17.0191H15.319Z"
      fill="white"
    />
    <path
      d="M24.3353 17.2595C23.6054 17.2595 22.9379 17.1082 22.3326 16.8145C21.7273 16.5118 21.2466 16.0935 20.8906 15.5505C20.5346 15.0075 20.3565 14.3845 20.3565 13.6902C20.3565 12.8268 20.5612 12.1236 20.9796 11.5628C21.389 11.002 21.932 10.5837 22.5996 10.2988C23.2672 10.014 23.9971 9.84489 24.816 9.77368L28.0026 9.50664V9.1684C28.0026 8.71444 27.9136 8.31389 27.7356 7.97565C27.5575 7.63741 27.2994 7.37038 26.9701 7.18345C26.6407 6.99653 26.2491 6.90752 25.7951 6.90752H25.5993C25.0029 6.90752 24.4955 7.05884 24.095 7.37038C23.6855 7.68192 23.463 8.06466 23.4185 8.53642H20.6236C20.7215 7.38818 21.2288 6.44466 22.1545 5.71477C23.0803 4.98488 24.2641 4.61993 25.7061 4.61993C26.7475 4.61993 27.6465 4.80686 28.4031 5.1807C29.1686 5.55455 29.7472 6.07972 30.1389 6.7473C30.5394 7.42378 30.7352 8.19818 30.7352 9.09719V14.3488C30.7352 14.9007 30.7352 15.328 30.7619 15.6395C30.7619 15.862 30.7619 16.0935 30.7886 16.3338C30.8064 16.5652 30.8332 16.7967 30.8599 17.0281H28.234C28.2162 16.8946 28.1984 16.761 28.1895 16.6186C28.1806 16.4762 28.1539 16.3071 28.145 16.1291C28.1272 15.9867 28.1183 15.8442 28.1183 15.7018V15.3458C27.7979 15.9154 27.2994 16.3783 26.6318 16.7343C25.9642 17.0904 25.2165 17.2684 24.3709 17.2684L24.3353 17.2595ZM25.0118 14.9719C25.5014 14.9719 25.9643 14.8562 26.4182 14.6159C26.8722 14.3845 27.246 14.0551 27.5397 13.6457C27.8334 13.2362 27.9759 12.7823 27.9759 12.2927V11.5628L25.1542 11.8298C24.5845 11.8743 24.1039 12.0435 23.7122 12.3283C23.3117 12.6131 23.1159 13.0048 23.1159 13.5032C23.1159 13.9216 23.2761 14.2776 23.6054 14.5447C23.9259 14.8206 24.3353 14.9541 24.8249 14.9541H25.0207L25.0118 14.9719Z"
      fill="white"
    />
    <path
      d="M33.5217 17.0192V4.86026H36.3166V6.61378C36.7083 5.99961 37.2245 5.51004 37.8565 5.154C38.4885 4.79796 39.2362 4.61993 40.0818 4.61993C41.0253 4.61993 41.8086 4.80686 42.4495 5.1807C43.0903 5.55455 43.571 6.07972 43.9003 6.7473C44.2208 7.42378 44.3899 8.19818 44.3899 9.09719V17.0192H41.595V9.50664C41.595 8.95477 41.5149 8.48302 41.3546 8.10027C41.1944 7.71752 40.9541 7.41488 40.6336 7.21016C40.3221 7.00543 39.9127 6.90752 39.432 6.90752H39.2362C38.7199 6.90752 38.2392 7.04994 37.7942 7.33477C37.3491 7.61961 36.9931 8.01126 36.726 8.52752C36.459 9.03488 36.3255 9.61346 36.3255 10.2454V17.0281H33.5306L33.5217 17.0192Z"
      fill="white"
    />
    <path
      d="M47.185 17.0191V0.000183105H49.98V9.70239L54.7954 4.86019H58.3203L53.4781 9.70239L58.5606 17.0191H55.2049L51.5109 11.6695L49.98 13.1738V17.0191H47.185Z"
      fill="white"
    />
  </svg>
);

const BlankWLogo = () => (
  <svg
    width="59"
    height="18"
    viewBox="0 0 59 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 17.0191V0.000183105H7.48586C8.49169 0.000183105 9.37288 0.187106 10.1384 0.569854C10.9039 0.952602 11.4914 1.47777 11.9275 2.15425C12.3548 2.83074 12.5684 3.59623 12.5684 4.45074C12.5684 5.42096 12.3102 6.24876 11.794 6.93415C11.2777 7.61063 10.5923 8.10019 9.75564 8.39393C10.6992 8.57195 11.4647 9.01701 12.0521 9.7291C12.6396 10.4412 12.9422 11.3491 12.9422 12.4528C12.9422 13.3251 12.7108 14.1173 12.2658 14.8116C11.8118 15.5059 11.1887 16.0489 10.3965 16.4405C9.60434 16.8322 8.7231 17.028 7.74398 17.028H0.0089098L0 17.0191ZM7.04972 7.12107C7.87752 7.12107 8.51838 6.92525 8.97233 6.5247C9.42629 6.12415 9.65774 5.57228 9.65774 4.86019C9.65774 4.1481 9.42629 3.56953 8.97233 3.18678C8.51838 2.80403 7.87752 2.59931 7.04972 2.59931H2.91959V7.12107H7.04972ZM7.24552 14.4111C8.10002 14.4111 8.78543 14.2064 9.27499 13.788C9.77346 13.3786 10.0137 12.7911 10.0137 12.0256C10.0137 11.2601 9.76456 10.6548 9.27499 10.2543C8.77653 9.84481 8.10002 9.64899 7.24552 9.64899H2.91959V14.4111H7.24552Z"
      fill="black"
    />
    <path
      d="M15.319 17.0191V0.000183105H18.1139V17.0191H15.319Z"
      fill="black"
    />
    <path
      d="M24.3353 17.2595C23.6054 17.2595 22.9379 17.1082 22.3326 16.8145C21.7273 16.5118 21.2466 16.0935 20.8906 15.5505C20.5346 15.0075 20.3565 14.3845 20.3565 13.6902C20.3565 12.8268 20.5612 12.1236 20.9796 11.5628C21.389 11.002 21.932 10.5837 22.5996 10.2988C23.2672 10.014 23.9971 9.84489 24.816 9.77368L28.0026 9.50664V9.1684C28.0026 8.71444 27.9136 8.31389 27.7356 7.97565C27.5575 7.63741 27.2994 7.37038 26.9701 7.18345C26.6407 6.99653 26.2491 6.90752 25.7951 6.90752H25.5993C25.0029 6.90752 24.4955 7.05884 24.095 7.37038C23.6855 7.68192 23.463 8.06466 23.4185 8.53642H20.6236C20.7215 7.38818 21.2288 6.44466 22.1545 5.71477C23.0803 4.98488 24.2641 4.61993 25.7061 4.61993C26.7475 4.61993 27.6465 4.80686 28.4031 5.1807C29.1686 5.55455 29.7472 6.07972 30.1389 6.7473C30.5394 7.42378 30.7352 8.19818 30.7352 9.09719V14.3488C30.7352 14.9007 30.7352 15.328 30.7619 15.6395C30.7619 15.862 30.7619 16.0935 30.7886 16.3338C30.8064 16.5652 30.8332 16.7967 30.8599 17.0281H28.234C28.2162 16.8946 28.1984 16.761 28.1895 16.6186C28.1806 16.4762 28.1539 16.3071 28.145 16.1291C28.1272 15.9867 28.1183 15.8442 28.1183 15.7018V15.3458C27.7979 15.9154 27.2994 16.3783 26.6318 16.7343C25.9642 17.0904 25.2165 17.2684 24.3709 17.2684L24.3353 17.2595ZM25.0118 14.9719C25.5014 14.9719 25.9643 14.8562 26.4182 14.6159C26.8722 14.3845 27.246 14.0551 27.5397 13.6457C27.8334 13.2362 27.9759 12.7823 27.9759 12.2927V11.5628L25.1542 11.8298C24.5845 11.8743 24.1039 12.0435 23.7122 12.3283C23.3117 12.6131 23.1159 13.0048 23.1159 13.5032C23.1159 13.9216 23.2761 14.2776 23.6054 14.5447C23.9259 14.8206 24.3353 14.9541 24.8249 14.9541H25.0207L25.0118 14.9719Z"
      fill="black"
    />
    <path
      d="M33.5217 17.0192V4.86026H36.3166V6.61378C36.7083 5.99961 37.2245 5.51004 37.8565 5.154C38.4885 4.79796 39.2362 4.61993 40.0818 4.61993C41.0253 4.61993 41.8086 4.80686 42.4495 5.1807C43.0903 5.55455 43.571 6.07972 43.9003 6.7473C44.2208 7.42378 44.3899 8.19818 44.3899 9.09719V17.0192H41.595V9.50664C41.595 8.95477 41.5149 8.48302 41.3546 8.10027C41.1944 7.71752 40.9541 7.41488 40.6336 7.21016C40.3221 7.00543 39.9127 6.90752 39.432 6.90752H39.2362C38.7199 6.90752 38.2392 7.04994 37.7942 7.33477C37.3491 7.61961 36.9931 8.01126 36.726 8.52752C36.459 9.03488 36.3255 9.61346 36.3255 10.2454V17.0281H33.5306L33.5217 17.0192Z"
      fill="black"
    />
    <path
      d="M47.185 17.0191V0.000183105H49.98V9.70239L54.7954 4.86019H58.3203L53.4781 9.70239L58.5606 17.0191H55.2049L51.5109 11.6695L49.98 13.1738V17.0191H47.185Z"
      fill="black"
    />
  </svg>
);
