import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DiscountInterface } from "../interfaces/discount.interface";

interface Props {
  tag: DiscountInterface;
}
const DiscountTag = ({ tag }: Props) => {
  return (
    <Box
      display="inline-block"
      sx={{
        backgroundColor: "#c6ea00",
        mr: "6px",
        mb: "2px",
        p: "5px 6px 4px",
      }}
    >
      <Typography fontSize={14} lineHeight={1} fontStyle="italic">
        {tag.bannerTitle}
      </Typography>
    </Box>
  );
};
export default DiscountTag;
