import React, { Suspense } from "react";
import { Box } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { CREATE_YOUR_COLOR } from "../../../routing/CONSTANTS";

import YourInspo from "./components/your-inspo";

import { useSetMobileViewHeight } from "../../../utils/hooks/useSetMobileViewHeight";
import "./hero.css";

// assets //

import heroImagePng from "../../../assets/heroAssets/heroBottles.png";
import heroImageMobilePng from "../../../assets/heroAssets/heroBottlesMobile.png";

const MyComponent = React.lazy(() => import("./components/videoPhone"));

interface Props {
  isWalmartComponent?: boolean;
  walmartColor?: string;
}
const Hero = ({ isWalmartComponent, walmartColor }: Props) => {
  const navigate = useNavigate();
  const { height } = useSetMobileViewHeight();

  const handleNavigateToAllCollections = () => {
    navigate(CREATE_YOUR_COLOR);
  };

  return (
    <Box
      width="100%"
      height="100vh"
      minHeight={{ xs: "840px", md: "100vh", lg: "100vh" }}
      sx={{
        background: isWalmartComponent
          ? walmartColor
          : "linear-gradient(90deg, rgba(218,218,218,1) 0%, rgba(226,226,226,1) 100%);",
      }}
      display="flex"
      flexDirection="column"
    >
      <Box
        paddingBottom={{
          xs: "3rem",
          lg: "initial",
        }}
        aria-label="polish bottles image"
        minHeight={{ xs: "840px", md: "100vh", lg: "100vh" }}
        sx={{
          background: isWalmartComponent ? walmartColor : null,
          backgroundImage: {
            xs: `url(${heroImageMobilePng})`,
            sm: `url(${heroImagePng})`,
          },
          display: "flex",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100%",
          columnGap: { xs: "1rem" },
          width: "100%",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: { xs: "center", lg: "space-between" },

          objectFit: "cover",
          overflow: "hidden",
        }}
      >
        <Box
          className="your-inspo-container"
          // position={{ xs: "relative", md: "initial" }}
          // bottom={{ xs: "10%", md: "0" }}
          width={{ xs: "100%", sm: "50%", md: "48%" }}
          height={{
            xs: "30%",
            md: "100%",
          }}
          display={{ xs: "flex" }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <YourInspo
            isWalmart={isWalmartComponent}
            handleNavigateToAllCollections={handleNavigateToAllCollections}
          />
        </Box>

        <Box
          className="hero-phone-main-container"
          height={{
            xs: "620px",
            md: "500px",
            lg: "950px",
          }}
          width="100%"
          display="flex"
          justifyContent={{ xs: "center", sm: "flex-end" }}
          alignItems={{ xs: "center" }}
          marginTop={{
            xs: "5rem",
            sm: "5rem",
            md: "8rem",
            lg: "-2rem",
            xl: "8rem",
          }}
        >
          <Suspense
            fallback={<div style={{ height: "100vh" }}>loading ...</div>}
          >
            <MyComponent />
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
