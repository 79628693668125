import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PoweredByBlankCompany from "./components/powered-by-blank-company";
import { IPoweredByBlank } from "./interfaces/powered-by-blank.interface";
import GetInTouchBottomBar from "./components/get-in-touch-bottom-bar";
import CompanyLogo from "./components/company-logos-bar";

interface Props {
  poweredByBlankData: IPoweredByBlank[];
}
const PoweredByBlank = ({ poweredByBlankData }: Props) => {
  const [activeIdx, setActiveIdx] = useState<number>(0);

  const changeIndex = (idx: number) => {
    setActiveIdx(idx);
  };

  return (
    <Box
      width="100%"
      sx={{ backgroundColor: "#D9D9D9" }}
      py={{ xs: 5, sm: 7 }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box>
        <Typography
          fontFamily="ABC Favorit Light"
          fontStyle="italic"
          fontSize={{ xs: "30px", md: "38px" }}
          lineHeight="38px"
        >
          Powered by Blank
        </Typography>
      </Box>
      <Box
        mt={2}
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {poweredByBlankData.map((company, idx) => (
          <CompanyLogo
            key={company.title}
            idx={idx}
            company={company}
            activeIdx={activeIdx}
            changeIndex={changeIndex}
          />
        ))}
      </Box>
      <PoweredByBlankCompany
        poweredByBlankCompany={poweredByBlankData[activeIdx]}
      />
      <GetInTouchBottomBar />
    </Box>
  );
};
export default PoweredByBlank;
