import { Box, Button } from "@mui/material";
import React from "react";

interface SubmitBtnProps {
  validateForm: () => void;
}
const AboutUsSubmitButton = ({ validateForm }: SubmitBtnProps) => {
  return (
    <Box mt={3.5} width="100%" display="flex" justifyContent="right">
      <Box width="97px" height="50px">
        <Button
          type="submit"
          fullWidth
          onClick={validateForm}
          variant="contained"
          sx={{
            fontSize: "20px",
            lineHeight: "22px",
            "&:hover": {
              backgroundColor: "white",
              color: "black",
            },
          }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};
export default AboutUsSubmitButton;
