import React from "react";
import { Box } from "@mui/material";
import { SortState } from "../../interfaces/sort-state";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../utils/hooks/redux-hooks";
import {
  selectProductCount,
  selectSortState,
  setSortingState,
} from "../../state/collection-slice";
import SortByAndCount from "./sort-by-and-count";

//TODO: make a custom hook for filtering

const ShopAllFilterBar = () => {
  const dispatch = useAppDispatch();
  const sortState = useAppSelector(selectSortState);
  const setSortState = (state: SortState) => {
    dispatch(setSortingState(state));
  };
  const PRODUCT_COUNT = useAppSelector(selectProductCount);

  return (
    <Box width="100%" px={{ xs: 2, md: 0 }} pt={{ xs: 3, md: 5 }}>
      <Box
        width="100%"
        maxWidth="1400px"
        m="0 auto"
        display="flex"
        justifyContent={{ xs: "space-between", sm: "flex-end" }}
        alignItems="center"
        position="relative"
        px={{ sm: 3 }}
      >
        <SortByAndCount
          sortState={sortState}
          setSortState={setSortState}
          setIsVisible={() => {}}
          productCount={PRODUCT_COUNT}
        />
      </Box>
    </Box>
  );
};
export default ShopAllFilterBar;
