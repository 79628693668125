import React from "react";
import { Box } from "@mui/material";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import BlankFooter from "../../components/blank-footer/blank-footer";
import SEO from "../../components/seo";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { ROOT, WALMART } from "../../routing/CONSTANTS";

interface Props {
  isWalmart?: boolean;
}
const FormSubmissionConfirmation = ({ isWalmart = false }: Props) => {
  return (
    <Box m="auto" height="100vh" width="100%" bgcolor="secondary.main">
      <SEO
        title="Thank you for reaching out"
        urlEndpoint="form-submission-confirmation"
        description="Contact form acknowledgement page"
        type="website"
      />
      <BlankAppbar isWalmartComponent={isWalmart} />
      <ScrollToTopOnMount />
      <Box
        bgcolor="secondary.main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={10}
        px={{ xs: 1, md: 2 }}
        minHeight={'100vh'}
        mx="auto"
        width={{ xs: "100%", md: "75%", lg: "60%" }}
      >
        <Typography textAlign={'center'} variant="h3" mb={2} mt={5}>
          Thank you for reaching out! Our team will get back to you shortly!
        </Typography>

        <Link
          style={{
            marginTop:'2rem',
            fontFamily: "ABC Favorit Book",
            fontSize: "20px",
            color: "black",
            textDecoration: "underline",
            lineHeight: 0,
          }}
          to={isWalmart ? `${WALMART}${ROOT}` : ROOT}
        >
          Return Home
        </Link>
       
      </Box>
      <BlankFooter />
    </Box>
  );
};

export default FormSubmissionConfirmation;
