import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CreateYourColor from "./create-your-color-card/create-your-color";
import { CollectionInterface } from "../interfaces/collection-interfaces";
import ProductCardContainer from "../../../components/product-card/product-card-container";
import ProductCardSkeleton from "../../../components/product-card/product-card-Skeleton";
import RandomShopAllImage from "./random-shop-all-image";
import { useSpliceShopAllGridImages } from "../hooks/useSpliceShopAllGridImages";

interface ShopAllGridProps {
  allProductsCollection: CollectionInterface;
  isLoading: boolean;
}
const ShopAllGrid = ({
  allProductsCollection,
  isLoading,
}: ShopAllGridProps) => {
  const { gridProducts } = useSpliceShopAllGridImages(
    allProductsCollection.collectionProducts
  );

  return (
    <Box
      px={{ xs: 1, md: 0 }}
      margin="0 auto"
      pt={{ xs: "25px", md: "60px" }}
      bgcolor="secondary.main"
      width="100%"
      maxWidth={{ xs: "100%", md: "1400px" }}
    >
      <Box mt={5}>
        <Grid
          container
          rowSpacing={{ xs: 5, md: 10 }}
          justifyContent="center"
          spacing={{ xs: 1, md: 3 }}
        >
          <Grid item xs={6} sm={4} md={3} paddingLeft={0}>
            <CreateYourColor />
          </Grid>
          {isLoading &&
            Array.from(new Array(16)).map((_, idx) => (
              <Grid key={idx} item xs={6} sm={4} md={3}>
                <ProductCardSkeleton />
              </Grid>
            ))}
          {gridProducts.map((product) => (
            <Grid key={product.title} item xs={6} sm={4} md={3}>
              {product.isRandomImg === true ? (
                <RandomShopAllImage product={product} />
              ) : (
                <ProductCardContainer
                  productType="shopify"
                  shopifyProduct={product}
                  landing={false}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
export default ShopAllGrid;
