import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useObserver } from "../../../../utils/hooks/useObserver";
import { infoGraphics } from "../CONSTANTS/info-graphics";
import HowItWorksInfoGraphic from "./how-it-works-graphic";

interface Props {
  isWalmartComponent?: boolean;
}

const HowItWorksInfoGraphics = ({ isWalmartComponent }: Props) => {
  const [activeIdx, setActiveIdx] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const entry = useObserver(containerRef, {
    threshold: 0.5,
  });
  const isVisible = entry && !!entry.isIntersecting;

  return (
    <Box
      ref={containerRef}
      mt={{ xs: 2, sm: 4, md: 2 }}
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        overflow: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box maxWidth="885px">
        <Box
          id="scroll-container"
          width={{ xs: "1200px", sm: "1400px", md: "100%" }}
          pl={{ xs: 3, sm: 0 }}
          minHeight={{ md: "500px" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {infoGraphics.map((graphic, idx) => (
            <HowItWorksInfoGraphic
              key={graphic.slideNumber}
              graphic={graphic}
              activeIdx={activeIdx}
              setActiveIdx={setActiveIdx}
              isVisible={isVisible}
              idx={idx}
              isWalmartComponent={isWalmartComponent}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default HowItWorksInfoGraphics;
