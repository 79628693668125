import React, { FormEvent, useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddRemoveItems from "./add-remove-items.component";
import {
  ACCORDION_DETAILS_INGREDIENTS,
  ACCORDION_TITLE_INGREDIENTS,
  ACCORDION_TITLE_SHIPPING,
  ACCORDION_DETAILS_SHIPPING,
  ACCORDION_TITLE_RETURN_POLICY,
} from "../CONSTANTS/product-info";
import ProductDetailsAccordion from "./product-details-accordion.component";
import ProductNamer from "./product-namer.component";
import { useLocation } from "react-router-dom";

import {
  CollectionProductInterface,
  VariantInterface,
} from "../../shop-all/interfaces/collection-interfaces";
import AddToCartButton from "../../../components/add-to-cart-button";
import useCartActions from "../../cart/hooks/useCartActions";
import {
  CUSTOM_POLISH_MERCH_ID,
  PEARL_TOP_COAT_MERCH_ID,
  TOP_COAT_MERCH_ID,
} from "../../cart/CONSTANTS/merch-ids";
import { getProductMaterial } from "../../../utils/product-material";
import { AddToCartInterface } from "../../cart/interfaces/add-to-cart.interface";
import ProductDescription from "./product-description/product-description";
import ReturnPolicy from "./return-policy";
import NavigateToCustomColorsButton from "./create-your-own-color-button";
import { useProductNamerInput } from "../hooks/useProductNamerInput";
import { isUpsellProduct } from "../../../utils/is-upsell-product";
import { capitalize } from "../../../utils/capitalize-string";
import Skeleton from "@mui/material/Skeleton";
import { useGetCreatedColorDbId } from "../hooks/useGetCustomColorHSV";
import PixelService from "../../../services/pixels/pixel-service";
import { useAppSelector } from "../../../utils/hooks/redux-hooks";
import { selectCartTotal } from "../../cart/state/cart-slice";
import ProductService from "../services/product-service";
import { hsvToHex } from "../../../utils/color-conversions";
import { useKioskHook } from "../../../utils/hooks/useKioskHook";

interface Props {
  name: string;
  polishPrice: number;
  colorData:
    | {
        hue: number;
        sat: number;
        val: number;
      }
    | string;
  customPolish: boolean;
  variants: VariantInterface[];
  colorId?: string;
  product?: CollectionProductInterface;
  setActiveVariant?: React.Dispatch<React.SetStateAction<VariantInterface>>;
  activeVariant?: VariantInterface;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  isLoadingPrice:boolean;
}

const ProductDetails = ({
  name,
  polishPrice,
  handleChange,
  customPolish,
  colorData,
  activeVariant,
  colorId,
  product,
  isLoading,
  isLoadingPrice,
}: Props) => {
  const { createOrAddToCart } = useCartActions();
  const { getDbID } = useGetCreatedColorDbId();
  const { errors, submitForm } = useProductNamerInput(name ? name : "");
  const [purchaseQuantity, setPurchaseQuantity] = useState<number>(1);
  const cartSubTotal = useAppSelector(selectCartTotal);
  const [kioskCode, setKioskCode] = useState<string>();
  const isKiosk = useKioskHook();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // Get a specific query parameter by name
  const queryColorId = queryParams.get("colorId");

  useEffect(() => {
    if (queryColorId) {
      setKioskCode(queryColorId);

      return;
    }
    if (colorId && !colorId.includes("custom")) {
      setKioskCode(colorId);
      return;
    }
  }, [colorId, queryColorId]);
  const colorHex: string = useMemo(() => {
    if (!colorData) {
      return "";
    }

    if (typeof colorData == "string") {
      return colorData;
    } else {
      const hsvData = colorData as { hue: number; sat: number; val: number };
      return hsvToHex(hsvData.hue, hsvData.sat, hsvData.val);
    }
  }, [colorData]);

  const removeItm = () => {
    if (purchaseQuantity > 1) {
      setPurchaseQuantity((prev) => prev - 1);
    }
  };
  const addItm = () => {
    setPurchaseQuantity((prev) => prev + 1);
  };

  const getMaterialUpsellAndShopifyProducts = (): string => {
    if (isUpsellProduct(product)) {
      if (
        product!.merchId === TOP_COAT_MERCH_ID ||
        product!.merchId === PEARL_TOP_COAT_MERCH_ID
      ) {
        return "Top coat / 15ml";
      } else {
        return "Base coat / 15ml";
      }
    } else {
      const shopifyProductMaterial = getProductMaterial(activeVariant!);
      return `${shopifyProductMaterial} / 15ml`;
    }
  };

  const sanitizeHex = (param: string): string => {
    const value = param.split("-")[1];
    const hex = `#${value}`;
    return hex;
  };

  const createOrAddCart = async (e: FormEvent) => {
    if (customPolish) {
      const validate = submitForm(e);
      if (validate === false) return;
      const id = colorId!.includes("custom-") ? await getDbID(colorId!) : null;
      const itemToAdd: AddToCartInterface = {
        merchId: CUSTOM_POLISH_MERCH_ID,
        key: colorId!.includes("custom-")
          ? `${sanitizeHex(colorId!)}-${id}`
          : colorId!,
        value: name.length === 0 ? "Custom Polish" : name,
        quantity: purchaseQuantity,
      };
      createOrAddToCart(itemToAdd, purchaseQuantity);
      ProductService.fetchProductIdByHandle("custom standard polish").then(
        (res) => {
          PixelService.pixelAddToCart(
            res,
            9,
            colorId!,
            CUSTOM_POLISH_MERCH_ID,
            "db-color",
            cartSubTotal
          );
        }
      );
    } else {
      const material = getMaterialUpsellAndShopifyProducts();
      const key = isUpsellProduct(product)
        ? capitalize(product!.title)
        : product!.title;
      const itemToAdd: AddToCartInterface = {
        merchId: activeVariant!.variantId,
        key: key,
        value: material,
        quantity: purchaseQuantity,
        img: product!.image,
      };
      createOrAddToCart(itemToAdd, purchaseQuantity);
      ProductService.fetchProductIdByHandle(key).then((res) => {
        PixelService.pixelAddToCart(
          res,
          9,
          key,
          activeVariant!.variantId,
          "shop-all",
          cartSubTotal
        );
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      width="100%"
      maxWidth="420px"
    >
      <Box>
        <Typography
          variant="h3"
          fontSize={{ xs: "30px", sm: "48px" }}
          lineHeight="49px"
        >
          {name}
        </Typography>
      </Box>

      {customPolish && (
        <Box width="100%" display="flex" flexDirection="column">
          <ProductNamer handleChange={handleChange!} name={name} />
          <Typography variant="caption" color="red">
            {errors}
          </Typography>
        </Box>
      )}

      {isLoading ? (
        <Skeleton width="143px" height="24px" sx={{ mt: "10px" }} />
      ) : (
        <Box
          display="flex"
          mb={"1rem"}
          alignItems="end"
          mt={{ xs: "10px", sm: 2 }}
        >
             <Box>
              <Typography lineHeight={1.2} variant="h6">
                {!isLoadingPrice && `$${polishPrice}.00`}
              </Typography>
            </Box> 
          
          <Box ml={2}>
            <Typography fontSize={14}>Size - 15ml</Typography>
          </Box>
        </Box>
      )}
      {isKiosk && kioskCode && (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          sx={{
            backgroundColor: "white",
            borderRadius: "100px",
            minHeight: "7rem",
            padding: ".2rem ",
            border: "2px solid black",
          }}
        >
          <Box
            display="flex"
            alignItems="left"
            justifyContent="center"
            flexDirection="column"
            padding="1rem 2rem"
            sx={{
              width: "60%",

              ...(queryColorId &&
                queryColorId.length >= 5 && {
                  width: "50%",
                  padding: "1rem 3rem",
                }),
            }}
          >
            {queryColorId && queryColorId.length >= 5 && (
              <Box
                component="p"
                sx={{ fontStyle: "italic", fontFamily: "Nib Pro" }}
              >
                Kiosk code
              </Box>
            )}

            <Box
              component="p"
              sx={{
                textDecoration: "italics",
                fontFamily: "Nib Pro",

                fontWeight: "1rem",

                ...(queryColorId &&
                  queryColorId.length >= 5 && {
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                    fontFamily: "ABC Favorit Book",
                  }),
              }}
            >
              {queryColorId && queryColorId.length >= 5
                ? `#${kioskCode}`
                : "Sorry, we don't have this color in stock 😭"}
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: colorHex,
              width: "40%",
              borderRadius: "100px",
              minHeight: "4rem",
              border: "2px solid black",
              ...(queryColorId &&
                queryColorId.length >= 5 && {
                  width: "50%",
                }),
            }}
          ></Box>
        </Box>
      )}
      {!isKiosk && (
        <>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <AddRemoveItems
              addItem={addItm}
              removeItem={removeItm}
              quantity={purchaseQuantity}
            />
            {colorId && !colorId.includes("custom") && (
              <Box display="flex" alignItems="center" columnGap={".5rem"}>
                <Box
                  component="p"
                  fontWeight={"bold"}
                  textTransform={"uppercase"}
                >
                  #{colorId}
                </Box>
                <Box
                  display="inline-block"
                  sx={{
                    backgroundColor: colorHex,
                    borderRadius: "50%",
                    height: "2rem",
                    width: "2rem",
                    border: "2px solid black",
                  }}
                ></Box>
              </Box>
            )}
          </Box>

          <AddToCartButton
            isLoading={false}
            btnStyle="productPageBtn"
            polishPrice={0}
            createOrAddToCart={createOrAddCart}
          />
          <NavigateToCustomColorsButton />
        </>
      )}

      <ProductDescription
        isCustomPolish={customPolish}
        product={product !== undefined ? product : undefined}
      />

      <Box borderTop="1px solid black">
        <ProductDetailsAccordion
          panelTitle={ACCORDION_TITLE_INGREDIENTS}
          panelDetails={ACCORDION_DETAILS_INGREDIENTS}
        />
        <ProductDetailsAccordion
          panelTitle={ACCORDION_TITLE_SHIPPING}
          panelDetails={ACCORDION_DETAILS_SHIPPING}
        />
        <ProductDetailsAccordion
          panelTitle={ACCORDION_TITLE_RETURN_POLICY}
          panelDetails={<ReturnPolicy />}
        />
      </Box>
    </Box>
  );
};

export default ProductDetails;
