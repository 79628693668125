import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import FilterBarItem from "./filter-bar-item.component";
import { useNavigate, useParams } from "react-router-dom";

interface FilterByCollectionProps {
  expanded: boolean;
  handleFilterBarToggle: () => void;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}
const FilterByCollectionFilter = ({
  expanded,
  handleFilterBarToggle,
  setExpanded,
  isMobile,
  setIsVisible,
}: FilterByCollectionProps) => {
  const { collection } = useParams();
  const navigate = useNavigate();
  const [active, setActive] = useState<string>(collection!);

  const handleFilter = (collection: string) => {
    setExpanded(false);
    if (setIsVisible) setIsVisible(false);
    navigate(`/libraryList/${collection}`);
    setActive(collection);
  };
  return (
    <Box width={{ xs: "100%", sm: "375px" }}>
      <Box position="relative">
        <Box
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleFilterBarToggle}
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Box display="flex" alignItems="center">
            <Typography mr={1}>Collections:</Typography>
            <Typography ml={isMobile ? 0 : 1} mr={1}>
              {collection ? collection : ""}
            </Typography>
            {!expanded ? (
              <ExpandMoreIcon htmlColor="black" />
            ) : (
              <ExpandLessIcon htmlColor="black" />
            )}
          </Box>
          <Collapse
            in={expanded}
            sx={{
              position: isMobile ? "unset" : "absolute",
              width: { xs: "195px", md: "275px" },
              top: "35px",
              left: "0",
              backgroundColor: "#d0d0d0",
              zIndex: 4,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              py={isMobile ? 1 : 2}
              px={isMobile ? 0 : 2}
            >
              {collectionsArr.map((collection, idx) => (
                <FilterBarItem
                  key={collection}
                  item={collection}
                  active={active}
                  handleFilter={handleFilter}
                  isCollection
                />
              ))}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};
export default FilterByCollectionFilter;

const collectionsArr: string[] = [
  "Pink",
  "Purple",
  "Red",
  "Blue",
  "Green",
  "Yellow",
  "Orange",
  "Nude",
  "Grey",
];
