import React from "react";
import Box from "@mui/material/Box";
import { useLocation, useParams } from "react-router-dom";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import BlankFooter from "../../components/blank-footer/blank-footer";
import PageNotFound from "../../routing/page-not-found";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import { useGetCollectionProducts } from "./hooks/useGetCollectionProducts";
import CollectionProductHero from "./components/collection-product-hero";
import CollectionProductTitle from "./components/collection-product-title";
import CollectionProductGrid from "./components/collection-product-grid";
import CollectionProductBuyCollection from "./components/collection-product-buy-collection";
import CollectionProductBottomCTA from "./components/collection-product-bottom-cta";
import Skeleton from "@mui/material/Skeleton";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";
import SEO from "../../components/seo";

interface Props {
  isWalmart?: boolean;
}
const CollectionProductPage = ({ isWalmart = false }: Props) => {
  const { collection } = useParams();
  const { collectionProduct, product, isLoading, isError } =
    useGetCollectionProducts(collection!);

  usePixelServiceOnLoad();

  const { pathname } = useLocation();
  const getSeoTitle = (): string => {
    if (collectionProduct.collectionName.toLowerCase().includes("Collection")) {
      return collectionProduct.collectionName;
    } else {
      return `${collectionProduct.collectionName} collection`;
    }
  };

  const loadingDisplay = (
    <>
      <BlankAppbar color="white" isWalmartComponent={isWalmart} />
      <Skeleton
        width="100%"
        height="1000px"
        variant="rectangular"
        animation="wave"
      />
      <BlankFooter />
    </>
  );
  const errorDisplay = (
    <>
      <BlankAppbar color="white" isWalmartComponent={isWalmart} />
      <PageNotFound />
      <BlankFooter />
    </>
  );

  const seoTitle = getSeoTitle();

  if (isLoading) return loadingDisplay;
  if (isError) return errorDisplay;
  const successDisplay = (
    <Box width="100%" sx={{ backgroundColor: "secondary.main" }}>
      <SEO
        title={seoTitle}
        urlEndpoint={pathname}
        description={collectionProduct.collectionDescription}
        type="website"
      />
      <ScrollToTopOnMount />
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CollectionProductHero
          title={collectionProduct.collectionName}
          images={
            collectionProduct
              ? [
                  collectionProduct.collectionImage,
                  collectionProduct.collectionMobileImg
                    ? collectionProduct.collectionMobileImg!
                    : collectionProduct.collectionImage,
                ]
              : ["", ""]
          }
        />
        <Box width="100%" sx={{ backgroundColor: "secondary.main" }}>
          <CollectionProductTitle
            subTitle={collectionProduct.collectionDescription}
          />
          <CollectionProductGrid
            products={
              collectionProduct ? collectionProduct.collectionProducts : []
            }
          />
          <CollectionProductBuyCollection
            product={product}
            products={
              collectionProduct ? collectionProduct.collectionProducts : []
            }
          />

          <CollectionProductBottomCTA />
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <BlankAppbar color="white" isWalmartComponent={isWalmart} />
      {successDisplay}
      <BlankFooter />
    </>
  );
};
export default CollectionProductPage;
