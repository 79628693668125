import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MatchPromiseModal from "../../match-guarantee-banner/match-promise-modal/match-promise-modal";
import { InfoInterface } from "../CONSTANTS/info";

interface InfoProps {
  item: InfoInterface;
}
const InfoComponent = ({ item }: InfoProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const handleShowModal = () => {
    setIsVisible(true);
  };
  return (
    <Box
      maxWidth={{ xs: "275px", sm: "300px", md: "400px" }}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      position="relative"
      mr={1}
    >
      <Box display="flex" mb={0.5}>
        <Box
          mr={1}
          component="img"
          width="26px"
          height="26px"
          src={item.icon}
          alt={item.altText}
          sx={{ objectFit: "scale-down" }}
        />
        <Box>
          <Typography
            color="white"
            fontFamily="Nib Pro"
            fontSize="23px"
            lineHeight="29px"
          >
            {item.title}
          </Typography>
        </Box>
      </Box>
      <Box pr={{ xs: 3.25, md: 1 }}>
        <Typography
          color="white"
          fontFamily="ABC Favorit Book"
          fontSize={{ xs: "15px", md: "15px" }}
          lineHeight={{ xs: "21px", md: "21px" }}
          fontWeight={300}
        >
          {item.description}
          {item.title === "Color match promise" && (
            <Box
              onClick={handleShowModal}
              fontSize={{ xs: "13px", md: "15px" }}
              lineHeight={{ xs: "17px", md: "20px" }}
              fontWeight={300}
              color="white"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              component="span"
              ml={0.75}
            >
              Read more
            </Box>
          )}
        </Typography>
      </Box>
      {isVisible && (
        <MatchPromiseModal isVisible={isVisible} setIsVisible={setIsVisible} />
      )}
    </Box>
  );
};
export default React.memo(InfoComponent);
