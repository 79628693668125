import React from "react";
import Box from "@mui/material/Box";
// import ColorStack from "../../open-endorse/components/color-stack";
import CollectionProductMakeItYours from "./collection-product-make-it-yours";
import nevadaCTAImg from "../../../assets/nevada/PhoneDesktop.png"
import nevadaCTAMobileImg from "../../../assets/nevada/PhoneMobile.png"
const CollectionProductBottomCTA = () => (
  <Box
    // pt={{ xs: 0, md: 15 }}
    width="100%"
    sx={{ backgroundColor: "white" }}
    position={'relative'}
    display='flex'
    flexDirection={{ xs: "column", md: "row" }}
    justifyContent="flex-end"
  >

    <CollectionProductMakeItYours />
    <Box width={{ xs: "100%", md: "60%" }}>


      <Box display="block" height="auto" width="100%"  component="picture">
        <Box media="(min-width: 769px)" component="source" srcSet={nevadaCTAImg} type="image/png" />
        <Box
          sx={{
            transform: {
              xs: "translateY(0.44rem);",
            },
          }}
        height="auto" width="100%" component="img" src={nevadaCTAMobileImg} />
      </Box>
    </Box>
  </Box>
);

export default CollectionProductBottomCTA;
