import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  bgColor: string;
  title: string;
  text: string;
}
const HeroEndorserInfo = ({ bgColor, title, text }: Props) => (
  <Box
    width="100%"
    py={{ xs: 4, md: 4 }}
    sx={{ backgroundColor: bgColor }}
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Box mt={{ md: 4 }}>
      <Typography
        fontFamily="Nib Pro"
        fontSize={{ xs: "38px", md: "48px" }}
        lineHeight="38px"
      >
        {title}
      </Typography>
    </Box>
    <Box my={{ xs: 2, md: 4 }} maxWidth={{ xs: "288px", md: "624px" }}>
      <Typography
        textAlign="center"
        fontFamily="ABC Favorit Book"
        fontSize={{ xs: "30px", md: "42px" }}
        lineHeight={{ xs: "32px", md: "52px" }}
      >
        "{text}"
      </Typography>
    </Box>
  </Box>
);
export default HeroEndorserInfo;
