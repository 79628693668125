import React from "react";
import Box from "@mui/material/Box";
import { AboutYourPolishInterface } from "../CONSTANTS/about-your-polish";
import Typography from "@mui/material/Typography";

interface Props {
  infoItem: AboutYourPolishInterface;
  index: number;
  activeIdx: number;
  setActiveIdx: React.Dispatch<React.SetStateAction<number>>;
}
const AboutStep = ({ infoItem, activeIdx, index, setActiveIdx }: Props) => {
  const handleChangeActiveIdx = () => {
    setActiveIdx(index);
  };
  return (
    <Box
      onClick={handleChangeActiveIdx}
      width="100%"
      display="flex"
      justifyContent="flex-start"
      mb={{ xs: 2, md: 3.5 }}
      alignItems="center"
      sx={{
        cursor: "pointer",
      }}
    >
      <Box mr={{ xs: 1.25, sm: 2.25 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mx={0.75}
          height={{ xs: "60px", md: "80px" }}
          width={{ xs: "60px", md: "79px" }}
          borderRadius="50%"
          border="3px solid black"
          fontFamily="ABC Favorit Book"
          fontSize={{ xs: "20px", md: "32px" }}
          sx={{
            backgroundColor:
              activeIdx === index ? infoItem.color : "transparent",
          }}
        >
          +
        </Box>
      </Box>
      <Box
        minWidth={{ xs: "160px", lg: "200px" }}
        maxWidth={{ xs: "165px", lg: "215px" }}
      >
        <Typography
          fontFamily="Nib Pro"
          fontSize={{ xs: "20px", md: "28px" }}
          lineHeight={{ xs: "24px", md: "40px" }}
          sx={{ textDecoration: activeIdx === index ? "underline" : "none" }}
        >
          {infoItem.stepTitle}
        </Typography>
      </Box>
    </Box>
  );
};
export default AboutStep;
