import React from "react";

const UploadIcon = () => {
  return (
    <svg
      className="upload-icon"
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 17.8942C12 18.4465 12.4477 18.8942 13 18.8942C13.5523 18.8942 14 18.4465 14 17.8942L12 17.8942ZM13.7071 1.19865C13.3166 0.808128 12.6834 0.808128 12.2929 1.19865L5.92893 7.56261C5.53841 7.95314 5.53841 8.5863 5.92893 8.97683C6.31946 9.36735 6.95262 9.36735 7.34315 8.97683L13 3.31997L18.6569 8.97683C19.0474 9.36735 19.6805 9.36735 20.0711 8.97683C20.4616 8.5863 20.4616 7.95314 20.0711 7.56261L13.7071 1.19865ZM14 17.8942L14 1.90576L12 1.90576L12 17.8942L14 17.8942Z"
        fill="black"
      />
      <path
        d="M1 7.99989V21.9999H25V7.99989"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default UploadIcon;
