import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { UpSellInterface } from "../../interfaces/up-sell-interface";
import IcoBottle from "../../../../components/ico-bottle-images/ico-polish-bottle";
import {
  getFillImage,
  getFillImageBackup,
} from "../../../../utils/get-upSell-background-image";
import ImgWithPngFallback from "../../../../components/img-with-png-fallback";
import cuticleOilWebp from "../../../../assets/cuticleOilBottleRender.webp";
import cuticleOilPng from "../../../../assets/cuticleOilBottleRender.png";
interface Props {
  item: UpSellInterface;
  addUpSellToCart: (item: UpSellInterface) => void;
  cartUpSells: UpSellInterface[];
}
const UpSellItemDesktop = ({ item, addUpSellToCart, cartUpSells }: Props) => {
  const productPrice = item.price.toString().split(".")[0];

  const itemFind = cartUpSells.find((product) => item.title === product.title);
  const isInUpSellArr = itemFind !== undefined;

  

  return (
    <Box
      width="100%"
      height={isInUpSellArr ? "215px" : "0px"}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mb={isInUpSellArr ? "55px" : "0px"}
      position="relative"
      sx={{
        opacity: isInUpSellArr ? 1 : 0,
        transition: "all .4s ease-in",
      }}
    >
      <ImgWithPngFallback
        src={getFillImage(item.title, "mobile")}
        fallback={getFillImageBackup(item.title, "mobile")}
        height="125px"
        alt={'upsell item'}
        sx={{
          objectFit: "contain",
        }}
      />
      <Box
        position="absolute"
        top="-35px"
        left="0"
        width="100%"
        display="flex"
        justifyContent="center"
      >
        <Box display="block">
          <IcoBottle imgSrc={item.title.toLowerCase().includes('cuticle') ? cuticleOilWebp : undefined}
            imgSrcFallback={item.title.toLowerCase().includes('cuticle') ? cuticleOilPng : undefined} imgHeight="147px" />
        </Box>
      </Box>

      <Box textAlign="center" mt={1} mb={2}>
        <Typography variant="h6" fontSize="18px" lineHeight="22px">
          {item.title}
        </Typography>
      </Box>
      <Box width="100%" maxWidth="220px" px={1}>
        <Button
          onClick={() => addUpSellToCart(item)}
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "black",
            pt: "7px",
            pb: "7px",
            fontSize: "20px",
            lineHeight: "22px",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
        >
          add to cart - ${productPrice}
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(UpSellItemDesktop);
