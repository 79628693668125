import { useAppSelector } from "../../../utils/hooks/redux-hooks";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { selectIsWalmartPath } from "../../walmart/state/walmart-slice";
import { WALMART } from "../../../routing/CONSTANTS";
import { useLocation } from "react-router-dom";


export const useGetColorByHSVQuery = () => {
  const navigate = useNavigate();

  const isWalmart = useAppSelector(selectIsWalmartPath);
  const status = useAppSelector((state: RootState) => state.colorPicker.status);
  const isUninitialized = status === undefined;
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";




  const location = useLocation();

  const fetchColor = async (hex?: string, isKiosk?: boolean, colorId?: string, isInternalCanvasTest?: boolean) => {




    if (hex && status !== "pending" && status !== "rejected") {
      const sanitizedHexValue = hex.split("#")[1];
      let navPath = `${isWalmart ? WALMART : ""}/products/custom-${sanitizedHexValue}?`

      if (location.pathname.includes('/canvasv1')) {

      }

      if (isKiosk || !!colorId) {
        navPath = `${navPath}isKiosk=${Number(isKiosk)}&colorId=${colorId}`;

      }
      if (isInternalCanvasTest) {
        navPath = `${navPath}&isInternalCanvasTest=${Number(isInternalCanvasTest)}`;


      }

      navigate(navPath
        ,
        {
          state: { colorName: "Hex value", polishName: "Custom Polish" },
        }
      );
    }
  };

  return { fetchColor, isUninitialized, isLoading, isError, isSuccess };
};
