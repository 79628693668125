export interface HeroInterface {
  color: string;
  heroBg: string;
}

export const heroItems: HeroInterface[] = [
  {
    color: "red",
    heroBg: "#fc0050",
  },
  {
    color: "blue",
    heroBg: "#00C0F4",
  },
  {
    color: "green",
    heroBg: "#C6EA00",
  },
  {
    color: "orange",
    heroBg: "#FF8B20",
  },
  {
    color: "purple",
    heroBg: "#C35CFF",
  },
  {
    color: "white",
    heroBg: "white",
  },
];

export const heroColors: string[] = [
  "red",
  "blue",
  "green",
  "purple",
  "orange",
];
