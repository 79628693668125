import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import HomePage from "./home.view.component";
import { LIBRARY_LIST, WALMART } from "../../routing/CONSTANTS";
import { useAppDispatch } from "../../utils/hooks/redux-hooks";
import { resetDisplayColor } from "../color_picker/state/color-picker-slice";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import SEO from "../../components/seo";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";
import BlankFooter from "../../components/blank-footer/blank-footer";
import { useKioskHook } from "../../utils/hooks/useKioskHook";

interface Props {
  isWalmart?: boolean;
}
const HomePageContainer = ({ isWalmart }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const hiddenCameraInput = useRef<HTMLInputElement>(null);

  const isKiosk = useKioskHook();
  const handleInputAction = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
    dispatch(resetDisplayColor());
  };

  const handleCameraAction = () => {
    if (hiddenCameraInput.current != null) {
      hiddenCameraInput.current.click();
    }
    dispatch(resetDisplayColor());
  };

  const handleLibraryNavigate = () => {
    let navPath = isWalmart ? `${WALMART}${LIBRARY_LIST}` : LIBRARY_LIST;

    if (isKiosk) {
      navPath = `${navPath}?isKiosk=1`;
    }

    navigate(navPath);
  };
  usePixelServiceOnLoad();
  useEffect(() => {
    document.body.style.backgroundColor = isWalmart ? "#fffff" : "#E5E5E5";
  }, [isWalmart]);

  return (
    <Box bgcolor={isWalmart ? "white" : "secondary.main"}>
      <SEO
        title="Create Your Custom Nail Polish Color | Blank Beauty"
        description="Custom Nail Polish. Long-lasting, chip-resistant, high gloss finish. Easy to apply and full coverage that lasts for days. Made to order in the USA."
        type="website"
        urlEndpoint="create-your-color"
      />
      <BlankAppbar isWalmartComponent={isWalmart} />
      <Box display="none" visibility="hidden">
        <Typography component="h1">
          Create your custom nail color by Blank
        </Typography>
        <Typography component="h2">
          Upload a photo and select a color
        </Typography>
      </Box>
      <Box
        sx={{
          overflow: "auto",
          width: "100%",
          pt: 8,
          pb: 3,
          m: "0 auto",
        }}
      >
        <HomePage
          hiddenFileInput={hiddenFileInput}
          hiddenCameraInput={hiddenCameraInput}
          handleInputAction={handleInputAction}
          handleCameraAction={handleCameraAction}
          handleLibraryNavigate={handleLibraryNavigate}
          isKiosk={isKiosk}
          isWalmart={isWalmart || false}
        />
        <BlankFooter />
      </Box>
    </Box>
  );
};

export default HomePageContainer;
