import React from "react";
import Box from "@mui/material/Box";
import ColorStack from "./color-stack";
import AdditionContent from "./addition-content";

interface Props {
  title: string;
  subtitle: string;
}
const CustomerAddition = ({ title, subtitle }: Props) => (
  <Box
    width="100%"
    pt={{ md: 15 }}
    sx={{ backgroundColor: "white" }}
    overflow="hidden"
  >
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="flex-end"
      position="relative"
    >
      <Box position={{ md: "absolute" }} top="-100px" left="10%">
        <AdditionContent title={title} subtitle={subtitle} />
      </Box>
      <ColorStack />
    </Box>
  </Box>
);
export default CustomerAddition;
