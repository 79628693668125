import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import RemoveItemIcon from "../../../components/icons/remove-item-icon";
import AddItemIcon from "../../../components/icons/add-item-icon";

interface Props {
  removeItem: () => void;
  addItem: () => void;
  quantity: number;
}

const AddRemoveItems = ({ removeItem, addItem, quantity }: Props) => (
  <Box
    display="flex"
    flexWrap="nowrap"
    alignItems="center"
    justifyContent="center"
    mb={2}
  >
    <IconButton aria-label="Remove item" onClick={removeItem} sx={{ minWidth: 0, pl: 0 }}>
      <RemoveItemIcon />
    </IconButton>
    <Box mx={1.5}>
      <Typography variant="body1" fontSize="20px">
        {quantity}
      </Typography>
    </Box>
    <IconButton aria-label="Add item" onClick={addItem}>
      <AddItemIcon />
    </IconButton>
  </Box>
);

export default React.memo(AddRemoveItems);
