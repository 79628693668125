import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick: () => void;
}

const ColorNamingInput = ({ handleChange, handleClick }: Props) => (
  <FormControl fullWidth sx={{ py: 1 }} variant="outlined">
    <OutlinedInput
      fullWidth
      onChange={handleChange}
      inputProps={{ maxLength: 25 }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClick}
            edge="end"
          >
            <CheckCircleOutlineIcon htmlColor="black" />
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>
);
export default ColorNamingInput;
