import React from "react";

const HeroTextMobile = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 236 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.11781 44.6356C4.58716 44.6356 3.2682 44.3236 2.16092 43.6996C1.05364 43.1084 0.5 42.3858 0.5 41.5318C0.5 41.072 0.662835 40.6943 0.988506 40.3987C1.31418 40.1031 1.73755 39.9553 2.25862 39.9553C2.9751 39.9553 3.77299 40.2837 4.6523 40.9406C5.79214 41.6961 6.94827 42.0738 8.12069 42.0738C9.74904 42.0738 11.4425 41.499 13.2011 40.3494C14.9598 39.1999 16.6044 37.5905 18.135 35.5213C19.6983 33.4849 21.0172 31.1366 22.0919 28.4762C20.7241 30.3154 19.1283 31.7442 17.3046 32.7624C15.4808 33.7477 13.5757 34.2404 11.5891 34.2404C9.34195 34.2404 7.58333 33.6163 6.31322 32.3682C5.07567 31.0873 4.45689 29.2973 4.45689 26.9982C4.45689 25.1589 4.97797 22.9419 6.02011 20.3472L10.2213 10.0504H5.38506V7.83341H14.7155L9.3908 20.4457C8.70689 22.0222 8.23467 23.2703 7.97413 24.19C7.7136 25.1096 7.58333 25.98 7.58333 26.8011C7.58333 30.3154 9.2931 32.0726 12.7126 32.0726C14.8621 32.0726 16.8324 31.4322 18.6236 30.1512C20.4473 28.8374 21.8803 27.0638 22.9224 24.8304C23.9971 22.5642 24.5345 20.0844 24.5345 17.3911C24.5345 15.7161 24.2902 14.3366 23.8017 13.2527C23.3132 12.136 22.499 11.1507 21.3592 10.2967C20.9032 9.93545 20.5776 9.62343 20.3822 9.36067C20.2193 9.09792 20.1379 8.7859 20.1379 8.42461C20.1379 7.40643 20.6916 6.89734 21.7988 6.89734C22.7759 6.89734 23.6552 7.32432 24.4368 8.17827C25.2184 8.99939 25.8209 10.1654 26.2442 11.6762C26.7002 13.1871 26.9282 14.895 26.9282 16.7999C26.9282 21.0697 25.886 25.3724 23.8017 29.7078C21.7174 34.0433 19.0469 37.6069 15.7902 40.3987C12.5661 43.2233 9.34195 44.6356 6.11781 44.6356Z"
      fill="black"
    />
    <path
      d="M49.1844 33.6492H35.957V30.9884H33.3187V28.3277H30.6804V12.3268H33.3187V9.66605H35.957V7.00531H49.1844V9.66605H51.8227V12.3268H54.461V28.3277H51.8227V30.9884H49.1844V33.6492ZM38.5952 28.3277H46.5281V25.6669H49.1844V14.9875H46.5281V12.3268H38.5952V14.9875H35.957V25.6669H38.5952V28.3277Z"
      fill="black"
    />
    <path
      d="M64.1752 34.2404C62.2211 34.2404 60.7068 33.6327 59.6321 32.4175C58.5899 31.1694 58.0688 29.4122 58.0688 27.146C58.0688 25.6351 58.4108 23.8944 59.0947 21.9237C59.7786 19.9202 61.1627 16.6357 63.247 12.0703L63.7844 10.8879L60.6579 7.83341H68.5717L66.3734 12.563C64.4845 16.7014 63.1493 19.8873 62.3677 22.1208C61.5861 24.3213 61.1953 26.0785 61.1953 27.3923C61.1953 28.8046 61.5535 29.9213 62.27 30.7424C63.019 31.5307 64.0286 31.9248 65.2987 31.9248C67.9041 31.9248 70.3792 30.1512 72.724 26.604C75.1014 23.024 77.1857 17.9495 78.9769 11.3806L79.0746 10.8387L75.997 7.83341H82.9338L78.0976 26.0128C77.8045 27.031 77.6579 28.0328 77.6579 29.0181C77.6579 29.9377 77.8207 30.6439 78.1464 31.1366C78.4721 31.6292 78.9443 31.8756 79.5631 31.8756C79.9539 31.8756 80.2958 31.777 80.589 31.58C80.9146 31.3829 81.224 31.0709 81.5171 30.6439L81.8591 30.9888C81.6311 31.8427 81.1914 32.516 80.5401 33.0087C79.9213 33.4685 79.156 33.6984 78.2441 33.6984C77.1043 33.6984 76.225 33.3043 75.6062 32.516C74.9874 31.6949 74.678 30.5618 74.678 29.1166C74.678 28.0656 74.8409 26.9325 75.1665 25.7172C72.1378 31.3993 68.474 34.2404 64.1752 34.2404Z"
      fill="black"
    />
    <path
      d="M115.38 33.6492H102.152V30.9884H99.5139V28.3277H96.8756V12.3268H99.5139V9.66605H102.152V7.00531H115.38V9.66605H118.018V12.3268H120.656V17.6665H115.38V14.9875H112.723V12.3268H104.79V14.9875H102.152V25.6669H104.79V28.3277H112.723V25.6669H115.38V22.988H120.656V28.3277H118.018V30.9884H115.38V33.6492Z"
      fill="black"
    />
    <path
      d="M128.653 11.085L125.331 7.83341H132.463L129.435 19.5589C130.965 15.6832 132.61 12.7601 134.369 10.7894C136.16 8.7859 137.935 7.78414 139.693 7.78414C140.41 7.78414 140.98 7.94836 141.403 8.2768C141.827 8.60525 142.038 9.04865 142.038 9.60701C142.038 10.1654 141.81 10.6088 141.354 10.9372C140.898 11.2657 140.28 11.4299 139.498 11.4299C134.515 11.4299 130.216 18.0316 126.601 31.2351L125.966 33.6492H122.693L128.653 11.085Z"
      fill="black"
    />
    <path
      d="M150.782 34.2404C147.786 34.2404 145.441 33.4193 143.748 31.777C142.054 30.1348 141.208 27.8521 141.208 24.929C141.208 22.0058 142.006 19.1483 143.601 16.3565C145.23 13.5648 147.314 11.2985 149.854 9.55774C152.427 7.78414 155.065 6.89734 157.768 6.89734C159.885 6.89734 161.562 7.4557 162.8 8.57241C164.07 9.68912 164.705 11.2 164.705 13.1049C164.705 16.1595 163.028 18.475 159.673 20.0515C156.319 21.6281 151.385 22.4164 144.872 22.4164C144.741 23.4017 144.676 24.1078 144.676 24.5348C144.676 26.8339 145.327 28.6404 146.63 29.9542C147.965 31.2679 149.805 31.9248 152.15 31.9248C155.765 31.9248 158.81 30.3811 161.285 27.2938L161.823 27.6386C159.315 32.0398 155.635 34.2404 150.782 34.2404ZM145.116 20.7906C150.75 20.7906 154.951 20.1501 157.719 18.8692C160.487 17.5882 161.872 15.634 161.872 13.0064C161.872 11.6269 161.497 10.5759 160.748 9.85334C160.031 9.13076 158.989 8.76948 157.622 8.76948C155.928 8.76948 154.218 9.31141 152.492 10.3953C150.766 11.4791 149.236 12.9571 147.9 14.8293C146.598 16.6686 145.669 18.6557 145.116 20.7906Z"
      fill="black"
    />
    <path
      d="M191.122 31.5307C190.861 32.3847 190.373 33.058 189.656 33.5506C188.972 34.0105 188.142 34.2404 187.165 34.2404C185.895 34.2404 184.934 33.8627 184.282 33.1072C183.664 32.3518 183.354 31.2515 183.354 29.8064C183.354 28.3612 183.664 26.6861 184.282 24.7812C182.654 27.8685 180.83 30.2169 178.811 31.8263C176.825 33.4357 174.708 34.2404 172.461 34.2404C170.311 34.2404 168.65 33.5671 167.478 32.2204C166.338 30.8738 165.768 28.9524 165.768 26.4562C165.768 23.4017 166.615 20.3472 168.308 17.2926C170.034 14.2381 172.233 11.7419 174.903 9.80407C177.574 7.86625 180.244 6.89734 182.915 6.89734C184.12 6.89734 185.178 7.12725 186.09 7.58707C187.002 8.01405 187.832 8.72021 188.581 9.70554L192.05 6.9466L187.116 26.0128C186.725 27.2281 186.53 28.394 186.53 29.5108C186.53 30.3976 186.709 31.0873 187.067 31.58C187.458 32.0398 187.995 32.2697 188.679 32.2697C189.102 32.2697 189.461 32.1876 189.754 32.0234C190.047 31.8263 190.324 31.5307 190.584 31.1366L191.122 31.5307ZM169.237 26.5055C169.237 30.1184 170.686 31.9248 173.584 31.9248C176.418 31.9248 179.007 30.2826 181.351 26.9982C183.729 23.6809 185.732 18.902 187.36 12.6615L187.8 11.0357C187.409 10.3132 186.904 9.78765 186.285 9.45921C185.699 9.09792 184.918 8.91727 183.941 8.91727C181.628 8.91727 179.316 9.80408 177.004 11.5777C174.724 13.3513 172.851 15.6175 171.386 18.3765C169.953 21.1026 169.237 23.8122 169.237 26.5055Z"
      fill="black"
    />
    <path
      d="M201.448 34.2404C199.852 34.2404 198.598 33.7477 197.686 32.7624C196.774 31.777 196.318 30.4633 196.318 28.821C196.318 27.9014 196.481 26.7847 196.807 25.4709L201.008 9.85334H195.976L196.514 7.83341H201.497L205.502 0H206.821L204.77 7.83341H211.267L210.778 9.85334H204.281L200.08 25.5694C199.754 26.719 199.591 27.7207 199.591 28.5747C199.591 29.56 199.868 30.3647 200.422 30.9888C200.976 31.6128 201.773 31.9248 202.816 31.9248C203.76 31.9248 204.672 31.6621 205.551 31.1366C206.463 30.611 207.31 29.8885 208.091 28.9688L208.58 29.363C207.863 30.841 206.87 32.0234 205.6 32.9102C204.362 33.797 202.978 34.2404 201.448 34.2404Z"
      fill="black"
    />
    <path
      d="M221.578 34.2404C218.581 34.2404 216.237 33.4193 214.543 31.777C212.85 30.1348 212.003 27.8521 212.003 24.929C212.003 22.0058 212.801 19.1483 214.397 16.3565C216.025 13.5648 218.109 11.2985 220.649 9.55774C223.222 7.78414 225.86 6.89734 228.563 6.89734C230.68 6.89734 232.357 7.4557 233.595 8.57241C234.865 9.68912 235.5 11.2 235.5 13.1049C235.5 16.1595 233.823 18.475 230.468 20.0515C227.114 21.6281 222.18 22.4164 215.667 22.4164C215.536 23.4017 215.471 24.1078 215.471 24.5348C215.471 26.8339 216.123 28.6404 217.425 29.9542C218.761 31.2679 220.601 31.9248 222.945 31.9248C226.56 31.9248 229.605 30.3811 232.08 27.2938L232.618 27.6386C230.11 32.0398 226.43 34.2404 221.578 34.2404ZM215.911 20.7906C221.545 20.7906 225.746 20.1501 228.514 18.8692C231.283 17.5882 232.667 15.634 232.667 13.0064C232.667 11.6269 232.292 10.5759 231.543 9.85334C230.827 9.13076 229.784 8.76948 228.417 8.76948C226.723 8.76948 225.013 9.31141 223.287 10.3953C221.561 11.4791 220.031 12.9571 218.695 14.8293C217.393 16.6686 216.465 18.6557 215.911 20.7906Z"
      fill="black"
    />
    <path
      d="M28.9458 85C27.35 85 26.0961 84.5073 25.1843 83.522C24.2724 82.5367 23.8165 81.2229 23.8165 79.5807C23.8165 78.661 23.9793 77.5443 24.305 76.2305L28.5061 60.613H23.4745L24.0119 58.593H28.9946L33.0004 50.7596H34.3193L32.2676 58.593H38.7647L38.2762 60.613H31.7791L27.5779 76.3291C27.2523 77.4786 27.0894 78.4804 27.0894 79.3343C27.0894 80.3197 27.3663 81.1244 27.9199 81.7484C28.4735 82.3724 29.2714 82.6845 30.3136 82.6845C31.258 82.6845 32.1699 82.4217 33.0492 81.8962C33.9611 81.3707 34.8078 80.6481 35.5894 79.7285L36.0779 80.1226C35.3615 81.6006 34.3682 82.783 33.0981 83.6698C31.8605 84.5566 30.4764 85 28.9458 85Z"
      fill="black"
    />
    <path
      d="M41.701 84.4088L44.5506 70.8231C45.8459 64.5006 49.2654 61.5222 53.0995 61.5222H53.5658C57.2444 61.5222 58.7987 64.6573 57.9179 68.8898L54.602 84.4088H59.0059L62.2701 68.8898C63.8244 61.7312 59.6277 57.76 54.602 57.76C51.2861 57.76 48.3847 59.2753 46.4676 61.9402L49.3691 47.832H44.9651L37.2971 84.4088H41.701Z"
      fill="black"
    />
    <path
      d="M73.4574 85C70.4613 85 68.1164 84.1789 66.4229 82.5367C64.7294 80.8944 63.8827 78.6117 63.8827 75.6886C63.8827 72.7654 64.6806 69.908 66.2764 67.1162C67.9047 64.3244 69.989 62.0581 72.5293 60.3174C75.1021 58.5438 77.74 57.657 80.4431 57.657C82.5599 57.657 84.2371 58.2153 85.4747 59.332C86.7448 60.4488 87.3798 61.9596 87.3798 63.8646C87.3798 66.9191 85.7026 69.2346 82.3482 70.8112C78.9938 72.3877 74.0599 73.176 67.5465 73.176C67.4162 74.1613 67.3511 74.8675 67.3511 75.2945C67.3511 77.5936 68.0024 79.4 69.3051 80.7138C70.6404 82.0276 72.4804 82.6845 74.8252 82.6845C78.4402 82.6845 81.4852 81.1408 83.9603 78.0534L84.4977 78.3983C81.99 82.7994 78.3099 85 73.4574 85ZM67.7908 71.5502C73.4249 71.5502 77.626 70.9097 80.3942 69.6288C83.1624 68.3478 84.5465 66.3936 84.5465 63.7661C84.5465 62.3866 84.172 61.3356 83.4229 60.613C82.7065 59.8904 81.6643 59.5291 80.2965 59.5291C78.603 59.5291 76.8932 60.071 75.1672 61.1549C73.4411 62.2388 71.9105 63.7168 70.5752 65.5889C69.2726 67.4282 68.3444 69.4153 67.7908 71.5502Z"
      fill="black"
    />
    <path
      d="M110.857 84.9313C116.505 84.9313 121.323 81.5872 122.515 75.8916H118.111C117.23 78.9745 114.691 81.1691 111.324 81.1691H110.857C106.402 81.1691 103.759 78.0862 103.759 73.279C103.759 66.4862 107.645 61.5222 112.774 61.5222H113.241C116.608 61.5222 119.147 63.1943 119.147 66.7997H123.551C123.551 61.3132 119.303 57.76 113.396 57.76C105.728 57.76 99.3552 63.769 99.3552 73.5925C99.3552 80.9601 104.277 84.9313 110.857 84.9313Z"
      fill="black"
    />
    <path
      d="M135.776 85C132.78 85 130.435 84.0639 128.741 82.1918C127.048 80.3197 126.201 77.7414 126.201 74.4569C126.201 71.4352 126.885 68.6434 128.253 66.0816C129.653 63.4869 131.526 61.4341 133.871 59.9232C136.216 58.4124 138.772 57.657 141.54 57.657C144.536 57.657 146.865 58.5602 148.526 60.3666C150.219 62.1731 151.066 64.7185 151.066 68.003C151.066 71.0247 150.366 73.8493 148.966 76.4769C147.598 79.0716 145.741 81.1408 143.397 82.6845C141.052 84.2282 138.512 85 135.776 85ZM129.67 74.9989C129.67 77.6264 130.207 79.6463 131.282 81.0587C132.356 82.4381 133.887 83.1279 135.874 83.1279C137.925 83.1279 139.847 82.4217 141.638 81.0094C143.462 79.5971 144.911 77.7085 145.986 75.3437C147.06 72.9461 147.598 70.3842 147.598 67.6581C147.598 65.0306 147.077 63.027 146.035 61.6476C144.992 60.2353 143.478 59.5291 141.491 59.5291C139.44 59.5291 137.502 60.2353 135.678 61.6476C133.854 63.0599 132.389 64.9649 131.282 67.3625C130.207 69.7273 129.67 72.2728 129.67 74.9989Z"
      fill="black"
    />
    <path
      d="M157.886 85C156.649 85 155.672 84.6059 154.955 83.8176C154.239 83.0293 153.881 81.8798 153.881 80.3689C153.881 79.2851 154.076 77.9384 154.467 76.3291L161.257 50.8582L157.545 47.4588H165.361L157.691 76.7725C157.3 78.4147 157.105 79.5642 157.105 80.2211C157.105 81.1736 157.317 81.9126 157.74 82.4381C158.163 82.9308 158.733 83.1771 159.45 83.1771C159.873 83.1771 160.231 83.0786 160.524 82.8815C160.85 82.6845 161.159 82.3724 161.453 81.9455L161.99 82.2903C161.729 83.0786 161.225 83.7355 160.476 84.261C159.759 84.7537 158.896 85 157.886 85Z"
      fill="black"
    />
    <path
      d="M176.727 84.9313C184.395 84.9313 190.405 78.3475 190.405 69.6213C190.405 62.5672 186.26 57.76 179.11 57.76C171.442 57.76 165.432 64.3961 165.432 73.07C165.432 80.1241 169.577 84.9313 176.727 84.9313ZM176.675 81.1691C172.271 81.1691 169.836 77.5115 169.836 72.9132C169.836 65.6501 174.395 61.5222 178.644 61.5222H179.11C183.514 61.5222 186.001 65.1276 186.001 69.7781C186.001 77.0412 181.39 81.1691 177.141 81.1691H176.675Z"
      fill="black"
    />
    <path
      d="M196.896 61.8446L193.575 58.593H200.707L197.678 70.3185C199.209 66.4429 200.853 63.5197 202.612 61.549C204.403 59.5455 206.178 58.5438 207.937 58.5438C208.653 58.5438 209.223 58.708 209.646 59.0364C210.07 59.3649 210.282 59.8083 210.282 60.3666C210.282 60.925 210.054 61.3684 209.598 61.6968C209.142 62.0253 208.523 62.1895 207.741 62.1895C202.759 62.1895 198.46 68.7913 194.845 81.9947L194.21 84.4088H190.937L196.896 61.8446Z"
      fill="black"
    />
  </svg>
);
export default HeroTextMobile;
