import { useEffect, useRef } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../utils/hooks/redux-hooks";
import { RootState } from "../../../store";
import { fetchTrendingProducts } from "../state/landing-actions";

export const useGetTrendingShadesQuery = () => {
  const dispatch = useAppDispatch();
  const rendered = useRef<boolean>(true);

  const data = useAppSelector(
    (state: RootState) => state.landing.trendingProducts
  );

  const status = useAppSelector((state: RootState) => state.landing.status);

  const isUninitialized = status === undefined;
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  useEffect(() => {
    if (rendered.current) {
      if (!data.length && status === undefined) {
        dispatch(fetchTrendingProducts());
      }
    }
    return () => {
      rendered.current = false;
    };
  }, [data.length, dispatch, status]);

  return { data, isUninitialized, isLoading, isError, isSuccess };
};
