import React from "react";
import Box from "@mui/material/Box";

interface Props {
  activeIdx: number;
  setActiveIdx: React.Dispatch<React.SetStateAction<number>>;
  idx: number;
}
const SliderDot = ({ activeIdx, idx, setActiveIdx }: Props) => (
  <Box
    onClick={() => setActiveIdx(idx)}
    height={{ xs: "13px", md: "20px" }}
    width={{ xs: "13px", md: "20px" }}
    borderRadius="50%"
    border="2px solid black"
    sx={{
      backgroundColor: activeIdx === idx ? "black" : "transparent",
      cursor: "pointer",
    }}
    mr={1}
  />
);

export default SliderDot;
