import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HeroImage from "./collection-hero/collection-hero-image";

interface Props {
  title: string;
  images: string[];
}

const CollectionProductHero = ({ title, images }: Props) => (
  <Box height="100%" width="100%" maxHeight="835px" position="relative">
    <HeroImage images={images} title={title} />
    <Box
      position="absolute"
      width="100%"
      height="100%"
      top="0"
      left="0"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-end"
      pb={{ xs: 2, md: 5 }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{
          backgroundColor: "#00000052",
        }}
        py={4}
      >
        <Typography
          textAlign="center"
          color="white"
          variant="h1"
          fontSize={{ xs: "36px", sm: "64px", md: "96px" }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  </Box>
);
export default CollectionProductHero;
