import React from "react";
import Box from "@mui/material/Box";
import { AboutYourPolishInterface } from "../CONSTANTS/about-your-polish";
import AboutCarousel from "./about-carousel";
import SliderDots from "./about-slider-dots";

interface Props {
  activeIdx: number;
  setActiveIdx: React.Dispatch<React.SetStateAction<number>>;
  polishSlideData: AboutYourPolishInterface[];
}
const AboutSlides = ({ activeIdx, polishSlideData, setActiveIdx }: Props) => (
  <Box
    width={{ xs: "100%", sm: "48%", md: "47%" }}
    sx={{ backgroundColor: "#E7E7E7" }}
    px={{ xs: 2, md: 0 }}
  >
    <Box
      width="100%"
      maxWidth="496px"
      maxHeight="399px"
      p={{ xs: "30px 24px 18px 24px", sm: "45px 24px 18px 24px" }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.369)",
        borderRadius: "20px",
      }}
    >
      <AboutCarousel
        activeIdx={activeIdx}
        infoItems={polishSlideData}
        setActiveIdx={setActiveIdx}
      />
      <SliderDots
        activeIdx={activeIdx}
        polishSlideData={polishSlideData}
        setActiveIdx={setActiveIdx}
      />
    </Box>
  </Box>
);

export default AboutSlides;
