import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReturnInstructions from "./return-instructions";

const ModalContent = () => {
  const text1 = `We're pretty darn confident with our color matching tech.
  So much so that if your shade, created with our Blank create color+ functionality, 
  really & truly isn't right, then we'll refund you 100% of the product cost if you return it to us.  Contact us at hello@blankbeauty.com
  if you have any questions about your order.`;
  const text2 = `At Blank, we really don't want to contribute to creating more waste. 
  We will donate the returned polish to children's hospitals and local schools.`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      maxHeight={{ xs: "259px", sm: "275px" }}
      sx={{ overflowY: "scroll" }}
    >
      <Box>
        <Typography
          variant="h4"
          fontSize={{ xs: "18px", sm: "20px" }}
          lineHeight={{ xs: "24px", sm: "27px" }}
        >
          What does the color-match promise mean?
        </Typography>
      </Box>
      <Box pr={{ xs: 2, md: 4 }} py={{ xs: 2, md: 2.5 }}>
        <Typography
          fontSize={{ xs: "13.5px", sm: "16px" }}
          lineHeight={{ xs: "18px", sm: "20px" }}
          variant="body1"
        >
          {text1}
        </Typography>
      </Box>
      <Box>
        <Typography
          fontSize={{ xs: "18px", sm: "20px" }}
          lineHeight={{ xs: "24px", sm: "27px" }}
          variant="h4"
        >
          How to get a 100% product cost refund?
        </Typography>
      </Box>
      <ReturnInstructions />
      <Box>
        <Typography
          fontSize={{ xs: "18px", sm: "20px" }}
          lineHeight={{ xs: "24px", sm: "27px" }}
          variant="h4"
        >
          What will happen to the returned polish?
        </Typography>
      </Box>
      <Box pr={{ xs: 2, sm: 4 }} py={{ xs: 2, sm: 2.5 }}>
        <Typography
          fontSize={{ xs: "13.5px", sm: "16px" }}
          lineHeight={{ xs: "18px", sm: "20px" }}
          variant="body1"
        >
          {text2}
        </Typography>
      </Box>
    </Box>
  );
};
export default ModalContent;
