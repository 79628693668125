import howVid1 from "../../../../assets/hiwAssets/howVid1.mp4";
import vid1Poster from "../../../../assets/hiwAssets/howVid1Poster.webp";
import vid1PosterPng from "../../../../assets/hiwAssets/howVid1Poster.jpg";
import howVid2 from "../../../../assets/hiwAssets/howVid2.mp4";
import vid2Poster from "../../../../assets/hiwAssets/howVid2Poster.webp";
import vid2PosterPng from "../../../../assets/hiwAssets/howVid2Poster.jpg";
import howVid3 from "../../../../assets/hiwAssets/howVid3.mp4";
import vid3Poster from "../../../../assets/hiwAssets/howVid3Poster.webp";
import vid3PosterPng from "../../../../assets/hiwAssets/howVid3Poster.png";
export interface HowItWorksInterface {
  slideNumber: number;
  directions: string;
  video: string;
  id: string;
  poster: string;
  fallbackPoster: string;
  altText: string;
}

export const infoGraphics: HowItWorksInterface[] = [
  {
    slideNumber: 1,
    directions: "Take a photo or upload your own",
    video: howVid1,
    id: "hiw_video_1",
    poster: vid1Poster,
    fallbackPoster: vid1PosterPng,
    altText: "happy dog",
  },
  {
    slideNumber: 2,
    directions: "Sample an exact color from your image",
    video: howVid2,
    id: "hiw_video_2",
    poster: vid2Poster,
    fallbackPoster: vid2PosterPng,
    altText: "woman's face",
  },
  {
    slideNumber: 3,
    directions: "Name the polish and add it to your cart",
    video: howVid3,
    id: "hiw_video_3",
    poster: vid3Poster,
    fallbackPoster: vid3PosterPng,
    altText: "hand holding polish bottle",
  },
];
