import React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { CREATE_YOUR_COLOR } from "../../../routing/CONSTANTS";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../product.css";

const NavigateToCustomColorsButton = () => {
  const navigate = useNavigate();
  const navToCustomColorPage = () => {
    navigate(CREATE_YOUR_COLOR);
  };
  return (
    <Box
      className="create-your-own-color-btn"
      m="20px auto 0 auto"
      width="100%"
      maxWidth={{ xs: "100%" }}
    >
      <Button
        onClick={navToCustomColorPage}
        fullWidth
        variant="contained"
        aria-label="Not quite right?"
        sx={{
          pt: "10.5px",
          pb: "10.5px",
          fontSize: {
            sm: "16px",
          },
          backgroundColor: "white",
          color: "black",
          borderRadius: "40px",
        }}
      >
        Not quite right?
        <Box ml="6px" component="span">
          <Typography variant="body2" fontSize={{ xs: "14px", sm: "16px" }}>
            {" "}
            Create your own color+
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default NavigateToCustomColorsButton;
