import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LibraryColorListInterface } from "../interfaces/library-color-list.interface";
import {
  Shape1,
  Shape2,
  Shape3,
  Shape4,
} from "../../../../components/product-backgrounds/random-circles.component";
import { useAppSelector } from "../../../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../../walmart/state/walmart-slice";
import { WALMART } from "../../../../routing/CONSTANTS";
import { useKioskHook } from "../../../../utils/hooks/useKioskHook";

interface CardProps {
  cardItem: LibraryColorListInterface;
}

const ColorLibraryCard = ({ cardItem }: CardProps) => {
  const isWalmart = useAppSelector(selectIsWalmartPath);
  
 
  const isKiosk = useKioskHook()

  let linkPath = isWalmart
    ? `${WALMART}/libraryList/${cardItem.title}`
    : `/libraryList/${cardItem.title}`;

    if(isKiosk){
      linkPath = `${linkPath}?isKiosk=1`

    }

    
  return (
    <Link style={{ textDecoration: "none", color: "black" }} to={linkPath}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width="100%"
          maxWidth="400px"
          flexWrap="wrap"
          display="flex"
          justifyContent="center"
          mb={1}
        >
          {cardItem.colorList.map((item) => {
            const shapeArr = [
              <Shape1
                isCartShape={false}
                hsv={[item.hue, item.sat, item.val]}
              />,
              <Shape2
                isCartShape={false}
                hsv={[item.hue, item.sat, item.val]}
              />,
              <Shape4
                isCartShape={false}
                hsv={[item.hue, item.sat, item.val]}
              />,
              <Shape3
                isCartShape={false}
                hsv={[item.hue, item.sat, item.val]}
              />,
            ];
            const randomShape =
              shapeArr[Math.floor(Math.random() * shapeArr.length)];
            return (
              <Box component="span" width="50px" height="50px" key={item.id}>
                {randomShape}
              </Box>
            );
          })}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mb={1}
        >
          <Typography variant="h4">{cardItem.title + "s"}</Typography>
        </Box>

        <Box mt={1} width={{ xs: "70%", sm: "50%" }}>
          <Button fullWidth variant="contained">
            See more
          </Button>
        </Box>
      </Box>
    </Link>
  );
};

export default ColorLibraryCard;
