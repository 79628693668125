import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const ColorLibrarySkeltonCard = ({ isMobile }: { isMobile: boolean }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Skeleton
      animation="wave"
      variant="rectangular"
      height={isMobile ? "180px" : "350px"}
      width={isMobile ? "175px" : "300px"}
      sx={{ borderRadius: "15px" }}
    />
    <Skeleton animation="wave" sx={{ my: 2 }} variant="text" width="50px" />
    <Skeleton
      animation="wave"
      variant="rectangular"
      width={isMobile ? "167px" : "220px"}
      height={isMobile ? "40px" : "47px"}
      sx={{ borderRadius: "35px" }}
    />
  </Box>
);
export default ColorLibrarySkeltonCard;
