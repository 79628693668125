import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CloudText = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    flexWrap="wrap"
    textAlign="center"
    width="100%"
    height="100%"
    px={{ xs: 2.5, md: 3 }}
  >
    <Box mb={{ xs: 0.75, sm: 1, md: 1.5, xl: 3 }} mt={{ xl: 2 }}>
      <Typography
        variant="h6"
        fontSize={{ xs: "6vw", sm: "4vw", md: "2rem", lg: "2.25rem" }}
        lineHeight={{ xs: "28px", sm: "30px", md: "36px", xl: "38px" }}
      >
        your color is calling
      </Typography>
    </Box>
    <Box px={{ xl: 3 }}>
      <Typography
        variant="body1"
        fontSize={{
          xs: "14px",
          sm: "18px",
          md: "1.15rem",
          lg: "1.25rem",
          xl: "1.3rem",
        }}
        lineHeight={{ xs: "16px", sm: "20px", md: "24px" }}
      >
        create your own color polish
      </Typography>
    </Box>
  </Box>
);
export default CloudText;
