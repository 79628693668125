export interface VariantInterface {
  variantId: string;
  price: string;
  title: string;
}

export interface CollectionProductInterface {
  merchId: string;
  handle: string;
  productId: string;
  title: string;
  description: string;
  variants: VariantInterface[];
  seoTitle: string;
  seoDescription: string;
  image: string;
  cursor: string;
  hex?:string;
  isRandomImg: boolean;
  tags: string[];
  whiteText?: boolean;
}
export interface CollectionInterface {
  collectionId: string;
  collectionName: string;
  collectionHandle?: string;
  collectionDescription: string;
  collectionImage: string;
  collectionMobileImg?: string;
  collectionProducts: CollectionProductInterface[];
  hasNextPage: boolean;
  cursor: string;
}
export const initialCollectionState: CollectionInterface = {
  collectionId: "",
  collectionName: "",
  collectionDescription: "",
  collectionImage: "",
  collectionProducts: [],
  hasNextPage: false,
  cursor: "",
};
