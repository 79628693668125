import { useEffect } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../utils/hooks/redux-hooks";
import { RootState } from "../../../store";
import { checkForDiscounts } from "../state/cart-actions";
import {
  selectAttentiveDiscount,
  selectCartCount,
  selectCartTotal,
} from "../state/cart-slice";

export const useCheckDiscountsQuery = () => {
  const dispatch = useAppDispatch();
  const cartCount = useAppSelector(selectCartCount);
  const cartTotal = useAppSelector(selectCartTotal);

  const discountData = useAppSelector(
    (state: RootState) => state.cart.discounts
  );
  const attnDiscount = useAppSelector(selectAttentiveDiscount);
  const selectApplicableDiscounts = (state: RootState) => {
    const discountArr: string[] = [];
    state.cart.discounts &&
      state.cart.discounts.forEach((discount) => {
        discountArr.push(discount.discount);
      });
    return discountArr;
  };

  const applicableDiscounts = useAppSelector(selectApplicableDiscounts);

  useEffect(() => {
    const cartId = localStorage.getItem("cartId");
    if (cartId) {
      dispatch(
        checkForDiscounts({ cartTotal: cartTotal, attnDiscount: attnDiscount })
      );
    }
  }, [cartCount, dispatch, attnDiscount, cartTotal]);

  return { discountData, applicableDiscounts };
};
