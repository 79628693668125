import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ShippingCost from "./cart-bottom-shipping-cost";

interface Props {
  cartCount: number;
  cartTotal: number;
  handleCheckout: () => void;
}
const CartBottom = ({ cartCount, cartTotal, handleCheckout }: Props) => (
  <Box
    width="100%"
    borderTop="2px solid black"
    p={{ xs: "21px 21px 10px", md: "20px 45px 10px" }}
    position="absolute"
    bottom="0"
    left="0"
    sx={{ backgroundColor: "white", zIndex: 1000 }}
  >
    <Box width="100%">
      <Box borderTop="none" pt={0} width="100%" mt={0}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ flexFlow: "row wrap" }}
          mt={{ xs: 0, md: "2px" }}
        >
          <Typography
            variant="h5"
            fontSize={{ xs: "18px", md: "22px" }}
            lineHeight={1}
            fontWeight={500}
          >
            Subtotal
          </Typography>
          <Typography
            variant="h5"
            fontSize={{ xs: "18px", md: "22px" }}
            lineHeight={1}
            fontWeight={500}
          >
            ${cartTotal}.00
          </Typography>
        </Box>
        <ShippingCost cartCount={cartCount} />
      </Box>
    </Box>
    <Box width="100%" mt="9px">
      <Button
        onClick={handleCheckout}
        variant="contained"
        disabled={cartCount < 1}
        fullWidth
        sx={{
          fontSize: "20px",
          py: { xs: "10.5px", md: "10.5px" },
          border:
            cartCount < 1 ? "2px solid rgba(0, 0, 0, 0.02)" : "2px solid black",
        }}
      >
        Checkout
      </Button>
      <Typography
        width="100%"
        fontSize={{ xs: "12px", md: "14px" }}
        lineHeight={{ xs: "16px", md: "19px" }}
        textAlign="center"
        sx={{ m: "7px 0 10px" }}
        component="p"
      >
        {" "}
        Exact shipping & taxes calculate at checkout
      </Typography>
    </Box>
  </Box>
);
export default React.memo(CartBottom);
