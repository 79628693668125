import React from "react";
import DeskTopHeroImage from "./desktop-hero-image";
import MobileHeroImage from "./mobile-hero-image";

interface HeroProps {
  images: string[];
  title: string;
}
const HeroImage = ({ images, title }: HeroProps) => {
  return (
    <>
      <DeskTopHeroImage img={images[0]} title={title} />
      <MobileHeroImage img={images[1]} title={title} />
    </>
  );
};

export default HeroImage;
