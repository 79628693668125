import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import FilterAccordion from "./filter-accordian";
import SortByDetails from "./sort-by-details";
import { SortState } from "../../interfaces/sort-state";
import FilterIcon from "./filter-icon";

interface SortByProps {
  productCount: number;
  sortState: SortState;
  setSortState: (state: SortState) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const SortByAndCount = ({
  productCount,
  sortState,
  setSortState,
  setIsVisible,
}: SortByProps) => (
  <Box
    width={{ xs: "100%", sm: "363px" }}
    display="flex"
    alignItems="center"
    justifyContent="space-between"
  >
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
    >
      <Box
        display={{ xs: "none", sm: "flex" }}
        flexDirection="column"
        alignItems="flex-end"
        mr={1}
      >
        <FilterIcon />
      </Box>
      <Box display="flex" alignItems="center">
        <Box mr={2} minWidth="60px">
          <Typography variant="body1">Sort by:</Typography>
        </Box>

        <Box
          position="relative"
          sx={{
            width: { xs: "165px", sm: "170px" },
            zIndex: 5,
          }}
        >
          <FilterAccordion
            position="absolute"
            panelTitle={sortState.sortKey}
            panelDetails={SortByDetails({
              sortState,
              setSortState,
              setIsVisible,
            })}
          />
        </Box>
      </Box>
      <Box width="100%" minWidth="100px">
        <Typography textAlign="right">{productCount} products</Typography>
      </Box>
    </Box>
  </Box>
);

export default SortByAndCount;
