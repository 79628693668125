import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface Props {
  btnColor: string;
  textColor: string;
  btnText: string;
}
const BlankLabsBtn = ({ btnColor, textColor, btnText }: Props) => {
  const hoverColorTxt: string = textColor === "white" ? "black" : "white";
  const hoverColorBtn: string = btnColor === "white" ? "black" : "white";
  return (
    <Button
      fullWidth
      sx={{
        mr: 1,
        color: textColor,
        backgroundColor: btnColor,
        "&:hover": {
          backgroundColor: hoverColorBtn,
          "& .btn-p": {
            color: hoverColorTxt,
          },
          "& .btn-arrow": {
            color: hoverColorTxt,
          },
        },
        py: { xs: "5.5px", sm: "8px" },
      }}
    >
      <Typography
        className="btn-p"
        fontSize="18px"
        lineHeight="25px"
        color={textColor}
      >
        {btnText}
      </Typography>
    </Button>
  );
};
export default BlankLabsBtn;
