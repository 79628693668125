import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { CREATE_YOUR_COLOR } from "../../../routing/CONSTANTS";
import MakeItYoursText from "./make-it-yours-text";

const CollectionProductMakeItYours = () => {
  const navigate = useNavigate();

  const navigateCustomColorCreatorPage = () => {
    navigate(CREATE_YOUR_COLOR);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={{xs:"center", md:"flex-start"}}
      position={{ md: 'absolute' }}
      left="10%"
      top="50%"
      mb={{xs:"1rem",md:"0px"}}
      sx={{
        transform: {
          md: "translateY(-50%);",
        },
      }}

      justifyContent="center"
      height={{ xs: "100%", md: "550px" }}
      pt={{ xs: 6, md: 0 }}
      zIndex={1}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems={{ xs: "center", md: "flex-start" }}
        mb={3}
        maxWidth={{ sm: "500px" }}
        width="100%"
      >
        <MakeItYoursText />
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection={{ xs: "column-reverse", md: "column" }}
        alignItems={{ xs: "center", lg: "flex-start" }}
      >
        <Box
          mb={{ sm: 5 }}
          mt={{ xs: 3, sm: 0 }}
          maxWidth={{ xs: "300px", sm: "500px" }}
        >
          <Typography
            variant="h4"
            fontSize={{ xs: "20px", sm: "28px", md: "32px" }}
            textAlign={{ xs: "center", sm: "left" }}
            lineHeight={{ xs: "32px", sm: "40px", md: "44px" }}
          >
            create your own custom shade for just $9
          </Typography>
        </Box>
        <Box width="100%" maxWidth={{ xs: "260px", sm: "350px" }}>
          <Button
            onClick={navigateCustomColorCreatorPage}
            fullWidth
            sx={{
              pt: { xs: "10px", sm: "12px" },
              pb: { xs: "10px", sm: "12px" },
              fontSize: { xs: "16px", sm: "20px" },
              backgroundColor: "white",
              color: "black",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
          >
            create your own
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default CollectionProductMakeItYours;
