import React from "react";

const FacebookIcon = () => (
  <svg
    width="13"
    height="24"
    viewBox="0 0 13 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16077 23.3295V12.6933H11.7549L12.2952 8.54409H8.17251V5.8909C8.17251 4.68703 8.51313 3.86887 10.2397 3.86887H12.4478V0.163755C12.0485 0.117003 10.7448 0.00012207 9.21786 0.00012207C6.03485 0.00012207 3.8502 1.92865 3.8502 5.48182V8.54409H0.256104V12.6933H3.8502V23.3412H8.14902L8.16077 23.3295Z"
      fill="black"
    />
  </svg>
);
export default FacebookIcon;
