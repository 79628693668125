import React, { useEffect, useRef } from "react";

import Slider from "react-slick";
import { AboutYourPolishInterface } from "../CONSTANTS/about-your-polish";
import AboutSlide from "./about-slide";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Props {
  activeIdx: number;
  setActiveIdx: React.Dispatch<React.SetStateAction<number>>;
  infoItems: AboutYourPolishInterface[];
}
const AboutCarousel = ({ activeIdx, infoItems, setActiveIdx }: Props) => {
  const sliderRef = useRef<Slider>(null);
  const sliderSettings = {
    draggable: true,
    focusOnSelect: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,

    afterChange: (index: number) => setActiveIdx(index),
  };

  useEffect(() => {
    const sliderElement = sliderRef.current;

    if (sliderElement) {
      sliderElement.slickGoTo(activeIdx);
    }
  }, [activeIdx]);

  return (
    <Slider ref={sliderRef} {...sliderSettings}>
      {infoItems.map((item) => (
        <AboutSlide key={item.stepTitle} infoItem={item} />
      ))}
    </Slider>
  );
};
export default AboutCarousel;
