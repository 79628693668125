import React, { ChangeEvent, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { COLOR_PICKER, WALMART } from "../../../../routing/CONSTANTS";
import { useCheckMobileScreen } from "../../../../utils/hooks/useCheckMobileScreen";
import { ImageContext } from "../../../color_picker/state/image.context";
import { useAppSelector } from "../../../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../../walmart/state/walmart-slice";
import { useKioskHook } from "../../../../utils/hooks/useKioskHook";

interface Props {
  icon: JSX.Element;
  buttonText: string;
  hiddenFileInput?: React.RefObject<HTMLInputElement> | null;
  navFunction: () => void;
}

const CameraJourneyButton = ({
  icon,
  buttonText,
  navFunction,
  hiddenFileInput,
}: Props) => {
  const { isMobile } = useCheckMobileScreen();
  const { fileUpload } = useContext(ImageContext);
  const navigate = useNavigate();
  const isWalmart = useAppSelector(selectIsWalmartPath);
  const isKiosk = useKioskHook()

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    fileUpload(e);
    let navPath = isWalmart ? `${WALMART}${COLOR_PICKER}` : COLOR_PICKER;

    if (isKiosk) {
      navPath = `${navPath}?isKiosk=1`

    }
    navigate(navPath, {
      state: { camera: true },
    });
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        ml={{ md: 4 }}
        sx={{
          "&:hover": {
            "button:not([disabled])": {
              "& .camera-icon": {
                fill: 'black',
              },
              "& .camera-icon-center": {
                stroke: 'white',
              },
              "& .camera-box": {
                borderColor: 'black',
              },

            },
            borderColor: "black",


          },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={icon}
          sx={{
            display: "flex",
            justifyContent: "start",
            width: "335px",
            py: { sm: 1 },
            mb: 1,
            border: !isMobile
              ? {
                xs: "4px solid rgba(0, 0, 0, 0.005)",
                sm: "2px solid rgba(0, 0, 0, 0.005)",
              }
              : {
                xs: `4px solid ${isWalmart ? "walmart.walmartBlue" : "black"
                  }`,
                sm: `2px solid ${isWalmart ? "walmart.walmartBlue" : "black"
                  }`,
              },
            backgroundColor: isWalmart ? "walmart.walmartBlue" : "black,",

          }}
          onClick={navFunction}
          disabled={!isMobile}
        >
          {buttonText}
        </Button>
        <Box display={{ xs: "none", md: "block" }}>
          <Typography
            fontSize={12}
            sx={{ color: (theme) => theme.palette.text.disabled }}
          >
            To use your camera open this page on a mobile device.
          </Typography>
        </Box>
      </Box>
      <input
        accept="image/*"
        onChange={uploadFile}
        ref={hiddenFileInput}
        type="file"
        capture="environment"
        style={{ display: "none" }}
      />
    </>
  );
};

export default CameraJourneyButton;
