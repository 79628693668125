import { VariantInterface } from "../pages/shop-all/interfaces/collection-interfaces";

export const getProductMaterial = (item: VariantInterface): string => {
  const prodTitle =
    item.title === "Default Title"
      ? item.price === "11.0"
        ? "Gel"
        : "Lacquer"
      : item.title;

  return prodTitle;
};
