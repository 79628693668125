import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  PolishTypes,
  ProductInterface,
} from "../../interfaces/product.interface";
import CartCustomPolishNamer from "./cart-custom-polish-namer";
import ItemButton from "./cart-item-button";
import ShopifyPolishTitle from "./shopify-polish-title";
import useCartActions from "../../hooks/useCartActions";
import {
  BASE_COAT_MERCH_ID,
  CUSTOM_POLISH_MERCH_ID,
  PEARL_TOP_COAT_MERCH_ID,
  TOP_COAT_MERCH_ID,
} from "../../CONSTANTS/merch-ids";
import { AddToCartInterface } from "../../interfaces/add-to-cart.interface";

interface Props {
  item: ProductInterface;
}
const CartItemDetails = ({ item }: Props) => {
  
  const { removeItemFromCart, deleteItemFromCart, addItemToCart } =
    useCartActions();

  const itemMaterial =
    item.merchId === CUSTOM_POLISH_MERCH_ID ||
    item.merchId === TOP_COAT_MERCH_ID ||
    item.merchId === BASE_COAT_MERCH_ID ||
    item.merchId === PEARL_TOP_COAT_MERCH_ID
      ? "Lacquer"
      : item.key.toLowerCase().includes("collection")
      ? "Lacquer"
      : item.value.split("/")[0];

  const addToCart = () => {
    const itemToAdd: AddToCartInterface = {
      key: item.key,
      value: item.value,
      quantity: 1,
      merchId: item.merchId,
    };
    addItemToCart(itemToAdd);
  };

  const remove = () => {
    removeItemFromCart(item);
  };

  return (
    <Box
      width={{ xs: "calc(100% - 60px)", md: "calc(100% - 150px)" }}
      pl={{ xs: "21px", md: "37px" }}
    >
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          {item.polishType === PolishTypes.ShopifyPolish ||
          item.polishType === PolishTypes.UpsellPolish ||
          item.polishType === PolishTypes.FullCollectionProduct ? (
            <ShopifyPolishTitle title={item.key} />
          ) : (
            <CartCustomPolishNamer item={item} />
          )}
          <Box
            width="100%"
            fontFamily="ABC Favorit Book"
            fontSize="14px"
            mt="10px"
          >
            <em>Material: </em>
            {itemMaterial}
          </Box>
        </Box>
        <Typography
          component="em"
          variant="body1"
          fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
          lineHeight={{ xs: "19px", sm: "20px", md: "22px" }}
          fontStyle="normal"
          sx={{ width: "auto" }}
        >
          ${item.price * item.quantity}.00
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          mt={{ xs: "10px", md: "25px" }}
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            maxWidth={{ xs: "98px", sm: "108px", md: "118px" }}
          >
            <ItemButton symbol="-" onClick={remove} />
            <Box width="38px">
              <Typography
                variant="body1"
                fontSize="20px"
                lineHeight="27px"
                textAlign="center"
              >
                {item.quantity}
              </Typography>
            </Box>
            <ItemButton symbol="+" onClick={addToCart} />
          </Box>
        </Box>
        <Box
          mt={{ xs: "10px", md: "25px", width: "100%" }}
          onClick={() => deleteItemFromCart(item)}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            textAlign="right"
            color="disabled"
            fontSize="14px"
            lineHeight="19px"
            fontFamily="ABC Favorit Book"
            sx={{ textDecoration: "underline" }}
          >
            Remove
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CartItemDetails);
