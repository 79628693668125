export const ROOT = "/";
export const SHOP_ALL = "/shop-all";
export const HOME = "/home";
export const COLOR_PICKER = "/colorPicker";
export const CANVAS_V1 = "/canvasv1";
export const CANVAS_KIOSK_V1 = "/canvasv1/kiosk";
export const LIBRARY_LIST = "/libraryList";
export const LIBRARY_COLOR = "/libraryList/:collection";
export const PRODUCT = "/products";
export const CART = "/cart";
export const FAQ = "/faq";
export const CONTACT = "/contact";
export const ABOUT = "/about";
export const RECEIPT = "/receipt";
export const TERMS = "/terms";
export const PRIVACY_POLICY = "/privacy-policy";
export const NEVADA = "/nevada";
export const CREATE_YOUR_COLOR = "/create-your-color";
export const HELP = "/help";
export const KIOSK = "/kiosk";
export const SHOP_POLICIES = "/policies";
export const OPEN_ENDORSE = "/open-endorse";
export const COLLECTIONS = "/collections";
export const FORM_SUBMISSION_CONFIRMATION = "/form-submission-confirmation";
export const WHOLESALE = "/wholesale";
export const WALMART = "/Walmart";
export const WALMART_EXTERNAL = "www.walmart.com";
