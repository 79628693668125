import React from "react";
import Box from "@mui/material/Box";
import AboutYourPolishInfo from "./components/about-your-polish-info";
import AboutYourPolishTitle from "./components/about-your-polish-title";

const AboutYourPolish = () => (
  <Box
    py={{ xs: 2, sm: 7 }}
    width="100%"
    sx={{ backgroundColor: "#E7E7E7" }}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <AboutYourPolishTitle />
    <AboutYourPolishInfo />
  </Box>
);
export default AboutYourPolish;
