import { useLocation } from "react-router-dom";

export function useInternalCanvasTestHook() {

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    // Get a specific query parameter by name
    const isInternalCanvasTest = queryParams.get('isInternalCanvasTest');
 

    return !!Number(isInternalCanvasTest) || location.pathname.includes('/canvasv1')
}
