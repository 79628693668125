import React from "react";
import { Box, keyframes } from "@mui/material";

interface Props {
  productImg: string;
  rotate?: boolean;
  isCart: boolean;
}

const CollectionAsProductBackground = ({
  productImg,
  rotate,
  isCart,
}: Props) => {
  const spin = keyframes`
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(360deg); 
  }
`;

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
      height="100%"
      width="103%"
      sx={{
        verticalAlign: "bottom",
        animation: rotate !== undefined ? `${spin} 4s ease` : "none",
        filter: {
          xs: !isCart
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCart
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
        WebkitFilter: {
          xs: !isCart
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCart
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 141 141"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <pattern
            id={productImg}
            patternUnits="userSpaceOnUse"
            width="100%"
            height="100%"
          >
            <image
              xlinkHref={productImg}
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMid slice"
            />
          </pattern>
        </defs>
        <path
          d="M139.25 70.4985C139.25 79.2592 130.047 86.1313 126.889 93.7487C123.611 101.65 125.14 112.982 119.19 118.938C113.24 124.888 101.902 123.366 94.0003 126.638C86.3828 129.796 79.5107 138.999 70.75 138.999C61.9893 138.999 55.1172 129.796 47.4998 126.638C39.5981 123.359 28.2667 124.888 22.3105 118.938C16.3606 112.988 17.8828 101.65 14.611 93.7487C11.4528 86.1313 2.25 79.2592 2.25 70.4985C2.25 61.7379 11.4528 54.8658 14.611 47.2483C17.8891 39.3467 16.3606 28.0153 22.3105 22.059C28.2604 16.1091 39.5981 17.6313 47.4998 14.3595C55.1172 11.2013 61.9893 1.99854 70.75 1.99854C79.5107 1.99854 86.3828 11.2013 94.0003 14.3595C101.902 17.6376 113.233 16.1091 119.19 22.059C125.14 28.009 123.617 39.3467 126.889 47.2483C130.161 55.15 139.25 61.7379 139.25 70.4985Z"
          fill={`url(#${productImg})`}
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </Box>
  );
};
export default CollectionAsProductBackground;
