import React from "react";
import Box from "@mui/material/Box";
import AnimatedCloudButton from "./animated-cloud-btn";
import CloudText from "./cloud-text";

interface Props {
  height: string;
  width: string;
}
const CreateYourColorCloud = ({ height, width }: Props) => (
  <Box position="relative" height={height} width={width}>
    <AnimatedCloudButton />
    <Box position="relative" height={height} width={width}>
      <svg viewBox="0 0 234 234" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M232.506 134.939C234.538 121.812 230.176 109.182 221.751 100.188C223.396 96.6852 224.578 92.8951 225.196 88.8731C228.641 66.5308 213.613 45.6139 191.495 41.6471C188.183 25.0174 174.822 11.4375 157.11 8.69717C148.453 7.36017 140.017 8.80766 132.696 12.3546C126.789 6.75244 119.181 2.82983 110.524 1.49283C92.801 -1.24748 75.9617 7.66956 67.7906 22.5313C45.7394 19.9788 25.444 35.3157 22.021 57.448C21.3916 61.5474 21.3805 65.5916 21.9216 69.4921C11.3322 75.5583 3.50332 86.2101 1.49365 99.2045C-0.516017 112.188 3.73519 124.685 11.9726 133.668C10.3163 137.193 9.12376 140.994 8.49435 145.038C5.08234 167.16 19.7794 187.889 41.5324 192.143C44.7677 208.872 58.1618 222.551 75.9618 225.303C84.6188 226.64 93.0549 225.192 100.376 221.645C106.283 227.247 113.891 231.17 122.548 232.507C140.337 235.258 157.221 226.264 165.359 211.314C187.62 214.176 208.224 198.784 211.669 176.463C212.277 172.496 212.299 168.585 211.813 164.795C222.524 158.751 230.474 148.044 232.495 134.939H232.506Z"
          fill="white"
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
      <Box
        position="absolute"
        top="0"
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <CloudText />
      </Box>
    </Box>
  </Box>
);
export default CreateYourColorCloud;
