import React from "react";
import Box from "@mui/material/Box";
import bottle from "../../../../../../../assets/wholesaleAssets/wholeSaleMIYBottle.webp";

const MakeItYoursBottle = () => (
  <Box
    height={{ xs: "315px", sm: "400px" }}
    width={{ xs: "115px", sm: "150px" }}
    mx={{ md: 5 }}
  >
    <Box component="img" src={bottle} height="100%" width="100%" />
  </Box>
);
export default MakeItYoursBottle;
