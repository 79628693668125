import React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface SnackBarProps {
  isOpenSnackbar: boolean;
  isMessageSuccess: boolean;
  setIsOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  message?: string;
  errorMessage?: string;
  cb?: Function;
}

const ContactSnackBar = ({
  isOpenSnackbar,
  setIsOpenSnackbar,
  isMessageSuccess,
  message = "Message sent!",
  errorMessage = "There was an error sending your message",
  cb = () => {},
}: SnackBarProps) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpenSnackbar(false);
    cb();
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        {isMessageSuccess ? (
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        )}
      </Snackbar>
    </Stack>
  );
};
export default ContactSnackBar;
