import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import FAQ from "./components/faq.component";
import { faqs } from "./CONSTANTS/faqs";
import SEO from "../../components/seo";
import BlankFooter from "../../components/blank-footer/blank-footer";

interface Props {
  isWalmart?: boolean;
}
const FaqPage = ({ isWalmart = false }: Props) => {
  useEffect(() => {
    window.document.body.style.overflow = "scroll";
    return () => {
      window.document.body.style.overflow = "hidden";
    };
  }, []);
  return (
    <Box bgcolor="secondary.main">
      <SEO
        title="FAQ | Blank Beauty"
        urlEndpoint="faq"
        description="Long-lasting, chip-resistant nail polish colors by Blank Beauty. 13-free and full coverage that lasts. Want something unique? Create your own nail polish."
        type="website"
      />
      <BlankAppbar isWalmartComponent={isWalmart} />
      <ScrollToTopOnMount />
      <Box
        bgcolor="secondary.main"
        pt={{ xs: 13, sm: 15 }}
        pb={10}
        px={2}
        mx="auto"
        width={{ xs: "95%", md: "60%" }}
      >
        <Typography sx={{ mb: 5 }} variant="h3" textAlign="start">
          FAQ
        </Typography>
        {faqs.map((faq) => (
          <FAQ key={faq.question} faq={faq} />
        ))}
      </Box>
      <BlankFooter />
    </Box>
  );
};

export default FaqPage;
