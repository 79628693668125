import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

type Props = {
  src: string;
  placeholderSrc: string;
  alt: string;
  screen: "desktop" | "mobile";
  maxHeight: string;
};

const ProgressiveImage = ({
  src,
  alt,
  placeholderSrc,
  screen,
  maxHeight,
}: Props) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  const customClass =
    placeholderSrc && imgSrc === placeholderSrc ? "loading" : "loaded";

  return (
    <Box
      display={{
        xs: screen === "mobile" ? "block" : "none",
        sm: screen === "desktop" ? "block" : "none",
      }}
      className={`image ${customClass}`}
      component="img"
      height={{ xs: "600px", sm: "850px" }}
      width="100%"
      maxHeight={maxHeight}
      alt={alt || ""}
      src={imgSrc}
      sx={{
        objectFit: "cover",
        filter: customClass === "loading" ? "blur(10px)" : "blur(0px)",
        clipPath: customClass === "loading" ? "inset(0)" : "none",
        transition: customClass === "loaded" ? "filter 0.5s linear" : "none",
      }}
    />
  );
};

export default ProgressiveImage;
