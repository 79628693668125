import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import BlankFooter from "../../components/blank-footer/blank-footer";
import PageNotFound from "../../routing/page-not-found";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import { useGetCollectionProducts } from "./hooks/useGetCollectionProducts";
import CollectionProductGrid from "./components/collection-product-grid";
import CollectionProductBuyCollection from "./components/collection-product-buy-collection";
import Skeleton from "@mui/material/Skeleton";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";
import SEO from "../../components/seo";
import CollectionProductBottomCTA from "./components/collection-product-bottom-cta";

import nevadaContentImg from "../../assets/nevada/nevada-content-desktop.png"
import nevadaContentMobileImg from "../../assets/nevada/nevada-content-mobile.png"
import "./nevada-page.css"
interface Props {
  isWalmart?: boolean;
}
const CollectionProductPage = ({ isWalmart = false }: Props) => {
  const { collectionProduct, product, isLoading, isError } =
    useGetCollectionProducts('nevada-x-blank');
    
  usePixelServiceOnLoad();

  const { pathname } = useLocation();


  const loadingDisplay = (
    <>
      <BlankAppbar shoppingCartColor="black" hideLinks={true} />
      <Skeleton
        width="100%"
        height="1000px"
        variant="rectangular"
        animation="wave"
      />
      <BlankFooter />
    </>
  );
  const errorDisplay = (
    <>
      <BlankAppbar shoppingCartColor="black" hideLinks={true} />
      <PageNotFound />
      <BlankFooter />
    </>
  );


  if (isLoading) return loadingDisplay;
  if (isError) return errorDisplay;
  const successDisplay = (
    <Box  width="100%" >
      <SEO
        title={"Nevada Collection"}
        urlEndpoint={pathname}
        description="Nevada Collection"
        type="website"
      />
      <ScrollToTopOnMount />
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingTop="6rem"
      >
        <Box width="100%" height={{ xs: "15rem", md: "35rem" }} id="nevadaHero"></Box>
        <Box width="100%" sx={{ backgroundColor: "#E5E5E5" }} paddingY={{ xs: "3rem", md: "5rem" }}>
          <Typography
            textAlign="center"
            variant="h3"
            fontSize={{ xs: "32px", md: "38px" }}
            lineHeight="38px"
            marginBottom="32px"
          >
            @Nevaaadaa x Blank

          </Typography>

      
          <Box  textAlign="center" component="p"
            fontSize={{ xs: "32px", md: "38px" }}>“No one gets me or my style… except <span style={{fontWeight:'bold',textDecoration:'underline'}} >Blank</span>”</Box>
          <Box component="p" textAlign="center">-Veronika Kostei</Box>
        </Box>
        <Box sx={{ backgroundColor: "#E5E5E5" }} paddingX={{ xs: "0.3rem", md: "1rem" }} width="100%" >
       
          <Box component="picture">
            <Box media="(min-width: 768px)" component="source" srcSet={nevadaContentImg} type="image/png" />
            <Box height="auto" width="100%" component="img" src={nevadaContentMobileImg} />
          </Box>

        </Box>
        <Box width="100%" paddingTop={{ xs: "2rem", md: "5rem" }} paddingBottom="" sx={{ backgroundColor: "secondary.main" }}>
          <Typography
            textAlign="center"
            variant="h3"
            fontSize={{ xs: "32px", md: "38px" }}
            lineHeight="38px"
            marginBottom={{ xs: "3rem", md: "6rem" }}
          >
            Nevaaadaa x Blank

          </Typography>
          <CollectionProductGrid
            products={
              collectionProduct ? collectionProduct.collectionProducts : []
            }
          />
          <CollectionProductBuyCollection
            product={product}
            products={
              collectionProduct ? collectionProduct.collectionProducts : []
            }
          />
          <CollectionProductBottomCTA/>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <BlankAppbar id="nevadaPage" shoppingCartColor="black" hideLinks={true} />
      {successDisplay}
      <BlankFooter />
    </>
  );
};
export default CollectionProductPage;
