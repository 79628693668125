import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { CREATE_YOUR_COLOR } from "../../../routing/CONSTANTS";

interface Props {
  subtitle: string;
  title: string;
}
const AdditionContent = ({ subtitle, title }: Props) => {
  const navigate = useNavigate();
  const makeIt = title.split(" ").slice(0, 2).join(" ");
  const yoursAnd = title.split(" ").slice(2).join(" ");

  const navigateCustomColorCreatorPage = () => {
    navigate(CREATE_YOUR_COLOR);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      height={{ xs: "100%", md: "550px" }}
      pt={{ xs: 6, md: 0 }}
      mb={{ xs: 3, md: 0 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems={{ xs: "center", md: "flex-start" }}
        mb={3}
        maxWidth={{ md: "500px" }}
        width="100%"
      >
        <Typography
          variant="h2"
          component="h3"
          fontSize={{ xs: "42px", sm: "52px", md: "58px" }}
          textAlign={{ xs: "center", sm: "left" }}
        >
          {makeIt}
        </Typography>
        <Typography
          variant="h2"
          component="h3"
          fontSize={{ xs: "42px", sm: "52px", md: "58px" }}
          textAlign={{ xs: "center", md: "left" }}
        >
          {yoursAnd}
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection={{ xs: "column-reverse", md: "column" }}
        alignItems={{ xs: "center", md: "flex-start" }}
      >
        <Box
          mb={{ md: 5 }}
          mt={{ xs: 3, md: 0 }}
          maxWidth={{ xs: "300px", sm: "425px", md: "500px" }}
        >
          <Typography
            variant="h4"
            fontSize={{ xs: "20px", sm: "28px", md: "32px" }}
            textAlign={{ xs: "center", md: "left" }}
            lineHeight={{ xs: "32px", sm: "40px", md: "44px" }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box width="100%" maxWidth={{ xs: "260px", sm: "300px", md: "350px" }}>
          <Button
            onClick={navigateCustomColorCreatorPage}
            fullWidth
            sx={{
              pt: { xs: "10px", sm: "12px" },
              pb: { xs: "10px", sm: "12px" },
              fontSize: { xs: "16px", sm: "20px" },
              backgroundColor: "white",
              color: "black",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
          >
            customize
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default AdditionContent;
