
export const FETCH_META_ENTRY_BY_HANDLE = `
  query getMetaObjectByHandle($handle: MetaobjectHandleInput!) {
    metaobject(handle: $handle) {
      id
      handle
      display: field(key: "display") {
        value
      }
    }
  }
`;