import React from "react";
import Box from "@mui/material/Box";
import { HowItWorksInterface } from "../CONSTANTS/info-graphics";
import SlideRoundlet from "./slide-roundlet";
import HowItWorksVideoGraphic from "../../../../components/scrolling-videos/how-it-works-video-graphic";
import GraphicDirection from "./graphic-directions";
import ImgWithPngFallback from "../../../../components/img-with-png-fallback";

interface Props {
  graphic: HowItWorksInterface;
  isVisible: boolean | undefined;
  idx: number;
  activeIdx: number;
  setActiveIdx: (value: React.SetStateAction<number>) => void;
  isWalmartComponent?: boolean;
}
const HowItWorksInfoGraphic = ({
  graphic,
  activeIdx,
  setActiveIdx,
  isVisible,
  idx,
  isWalmartComponent,
}: Props) => (
  <Box
    id={`scroll-box-${idx}`}
    width={{ xs: "270px", sm: "350px", md: "360px" }}
    mr={3}
    sx={{
      transform: {
        xs: "translateX(125px)",
        sm: "translateX(70px)",
        md: "none",
      },
      "&:last-child": {
        mr: { md: 0 },
      },
    }}
    pt={{ xs: 1.75, md: 0 }}
    display="flex"
    flexDirection="column"
    position="relative"
  >
    <Box width="100%" mb={2}>
      <Box
        width="100%"
        position="relative"
        maxHeight={{ md: "359px" }}
        borderRadius="10px"
        sx={{ zIndex: 6, overflow: "hidden" }}
      >
        <HowItWorksVideoGraphic
          video={graphic.video}
          id={graphic.id}
          idx={idx}
          isVisible={isVisible}
          activeIdx={activeIdx}
          setActiveIdx={setActiveIdx}
          poster=""
          minHeight={{ xs: "341.63px", sm: "445.45px", md: "361.45px" }}
        />
        <Box
          width="100%"
          height={{ xs: "341.63px", sm: "445.45px", md: "361.45px" }}
          position="absolute"
          top="0"
          sx={{ zIndex: 5 }}
          overflow="hidden"
          borderRadius="10px"
        >
          <ImgWithPngFallback
            src={graphic.poster}
            fallback={graphic.fallbackPoster}
            height="100%"
            width="100%"
            borderRadius="10px"
            alt={graphic.altText}
          />
        </Box>
      </Box>
    </Box>
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
    >
      <Box mr={2}>
        <SlideRoundlet
          bg={isWalmartComponent ? "white" : "black"}
          slideNumber={graphic.slideNumber}
        />
      </Box>
      <GraphicDirection
        direction={graphic.directions}
        walmart={isWalmartComponent}
      />
    </Box>
  </Box>
);

export default HowItWorksInfoGraphic;
