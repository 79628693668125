export const UPDATE_CART_ITEM_MUTATION = `
mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        lines(first: 20) {
          edges {
            node {
              id
              quantity
              estimatedCost{
                subtotalAmount{
                  amount
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                    amount
                    currencyCode
                  }
                  product {
                    title 
                    description
                  }		
                }
              }
            attributes {
              key
              value
            }
            }
          }
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;
