import React from "react";
import Box from "@mui/material/Box";
import { CollectionProductInterface } from "../../../shop-all/interfaces/collection-interfaces";
import CollectionElement from "./collection-element";

type Props = {
  product: CollectionProductInterface;
  leftXs: number;
  leftSm: number;
  bottomXs?: number;
  bottomSm?: number;
  rotation?: string;
};

const ElementContainer = ({
  product,
  leftSm,
  leftXs,
  bottomSm,
  bottomXs,
  rotation = "0deg",
}: Props) => {
  return (
    <Box
      position="absolute"
      bottom={
        !bottomXs && !bottomSm
          ? "0"
          : { xs: `${bottomXs}px`, sm: `${bottomSm}px` }
      }
      left={{ xs: `${leftXs}px`, sm: `${leftSm}px` }}
    >
      <CollectionElement item={product} rotation={rotation} />
    </Box>
  );
};

export default ElementContainer;
