import React, { ChangeEvent, FormEvent, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import http from "../../../services/http/http-common";
import PixelService from "../../../services/pixels/pixel-service";
import { TextField } from "@mui/material";

const NewsletterForm = () => {
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<{ email: string }>({ email: "" });
  const [postSuccess, setPostSuccess] = useState<boolean>(false);
  const isEmail = (email: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const klaviyoPost = () => {
    const data = {
      email: email,
    };
    try {
      http.post("/klaviyoFooterForm", data);
      PixelService.pixelCompleteRegistration("Klaviyo footer form");
    } catch (err) {
      console.log("Error registering with Klaviyo: ", err);
    }
  };

  const validateAndSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors: { email: string } = { email: "" };
    if (!isEmail(email)) {
      errors.email = "Invalid Email";
    }
    setErrors(errors);

    if (errors.email.length === 0) {
      setPostSuccess(true);
      setEmail("");
      klaviyoPost();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Box
      data-testid="news-letter-form-container"
      sx={{ width: { xs: "330px", md: "350px" } }}
    >
      <FormControl
        variant="outlined"
        sx={{
          "&:hover fieldset": {
            border: "0px transparent",
          },
        }}
      >
        <FormLabel
          sx={{
            fontFamily: "Nib Pro",
            mb: 1,
            fontSize: { xs: "15px", md: "18" },
            lineHeight: "22px",
            px: { xs: 1, md: 0 },
          }}
        >
          {!postSuccess
            ? "Let's keep in touch. Sign up for a FREE polish"
            : "Fantastic stuff! Please check your email for confirmation"}
        </FormLabel>
        <Box
          borderRadius="60px"
          border="2px solid black"
          maxHeight="49px"
          width="335px"
        >
          <TextField
            variant="standard"
            fullWidth
            value={email}
            onChange={handleOnChange}
            error={errors.email.length > 0}
            inputProps={{
              style: {
                padding: "8px 10px 8px 25px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="send email for discount"
                    onClick={validateAndSubmit}
                    type="submit"
                  >
                    <ArrowForwardIcon htmlColor="black" />
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            sx={{
              borderRadius: "60px",
              input: {
                fontSize: "20px",
                color: "black",
                lineHeight: "22px",
                maxWidth: "240px",
                "&::placeholder": {
                  opacity: 1,
                },
              },
            }}
            id="form-input"
            placeholder="enter email here"
          />
        </Box>
        {errors.email.length
          ? Object.entries(errors).map(([key, error]) => (
              <span
                key={`${key}: ${error}`}
                style={{
                  fontWeight: "regular",
                  color: "red",
                }}
              >
                {error}
              </span>
            ))
          : null}
      </FormControl>
    </Box>
  );
};
export default NewsletterForm;
