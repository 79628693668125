import { heroColors, heroItems } from "../pages/landing/CONSTANTS";

export const getRandomDrawerColor = (): string => {
  const randomBG = sessionStorage.getItem("appBgColor");
  if (!randomBG) {
    const randomBG = heroColors[Math.floor(Math.random() * heroColors.length)];
    const bgColor = heroItems.find((item) => item.color === randomBG);
    if (bgColor) {
      sessionStorage.setItem("appBgColor", bgColor.heroBg);
      return bgColor.heroBg;
    } else {
      return "secondary.main";
    }
  } else {
    if (randomBG) {
      return randomBG;
    } else {
      return "secondary.main";
    }
  }
};
