import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { UpSellInterface } from "../../interfaces/up-sell-interface";
import UpSellItemMobile from "./upSell-item-mobile";

interface Props {
  upSellItems: UpSellInterface[];
  cartUpSellItems: UpSellInterface[];
  addUpSellToCart: (item: UpSellInterface) => void;
}
const UpSellMobile = ({
  upSellItems,
  addUpSellToCart,
  cartUpSellItems,
}: Props) => {


  return (
    <Box
      display={{
        xs: "block",
        md: "none",
      }}
      width="100%"
      mb="50px"
      p={{ xs: "0 8.5px", sm: "35px" }}
      sx={{
        opacity: cartUpSellItems.length ? 1 : 0,
        transition: "all .25s ease-out",
      }}
    >
      <Box
        width="100%"
        p={{ xs: "10px", sm: "20px" }}
        sx={{ borderRadius: "20px", backgroundColor: "#e7e7e7" }}
      >
        <Typography
          sx={{ mb: "40px" }}
          variant="h6"
          fontSize={{ xs: "16px", sm: "24px" }}
          lineHeight="36px"
          textAlign="center"
        >
          Protect & strengthen
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignContent="center"
        >
          {upSellItems.map((item) => (
            <UpSellItemMobile
              key={item.title}
              addUpSellToCart={addUpSellToCart}
              item={item}
              cartUpSells={cartUpSellItems}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
};
export default UpSellMobile;
