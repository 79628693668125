import React from "react";

const BlankPalettesIcon = () => (
  <svg
    width="55"
    height="47"
    viewBox="0 0 64 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.83815 25.0479C9.83815 38.3292 20.6048 49.0958 33.8861 49.0958C47.1673 49.0958 57.934 38.3292 57.934 25.0479C57.934 11.7666 47.1673 1.00002 33.8861 1.00002C20.6048 1.00002 9.83815 11.7666 9.83815 25.0479Z"
      fill="none"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M1.00002 25.0479C1.00002 38.3292 11.7666 49.0958 25.0479 49.0958C38.3292 49.0958 49.0958 38.3292 49.0958 25.0479C49.0958 11.7666 38.3292 1.00002 25.0479 1.00002C11.7666 1.00002 1.00002 11.7666 1.00002 25.0479Z"
      fill="#E5E5E5"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M25.0449 33.4651V16.6307"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M33.4594 25.0452H16.625"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);
export default BlankPalettesIcon;
