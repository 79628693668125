export const profanityList = [
  "arse",
  "asshole",
  "bastard",
  "bitch",
  "fuck",
  "whore",
  "slut",
  "cunt",
  "hoe",
  "dildo",
  "piss",
  "tits",
  "titties",
  "rape",
  "rapist",
  "shit",
  "dyke",
  "fag",
  "faggot",
  "dick",
  "pussy",
  "goddamn",
  "cock",
  "penis",
  "vagina",
  "nigger",
  "kike",
  "chink",
  "nigga",
  "anal",
  "sex",
  "beastality",
  "biatch",
  "anal",
  "anus",
  "boner",
  "boob",
  "buttplug",
  "clitoris",
  "fellate",
  "fellatio",
  "fudgepacker",
  "Goddamn",
  "homo",
  "jizz",
  "muff",
  "queer",
  "prick",
  "pube",
  "twat",
  "vagina",
  "smegma",
  "autoerotic",
  "b!tch",
  "b00bs",
  "b17ch",
  "b1tch",
  "ballbag",
  "balls",
  "ballsack",
  "breasts",
  "bukkake",
  "butthole",
  "buttmunch",
  "buttplug",
  "camgirl",
  "camslut",
  "camwhore",
  "carpetmuncher",
  "choad",
  "circlejerk",
  "cl1t",
  "creampie",
  "cum",
  "gangbang",
  "gay",
  "jack-off",
  "labia",
  "retard",
  "rimjob",
  "scrotum",
  "semen",
  "strapon",
  "testicle",
  "homoerotic",
];

export const profanityListCompounds: string[] = [
  "white power",
  "strap on",
  "jack off",
  "gang bang",
  "carpet muncher",
  "ball sucking",
  "God damn",
  "fudge packer",
  "auto erotic",
  "ball sack",
  "camel toe",
  "butt plug",
];
