export const UP_SELLS_QUERY = `
query getProductById($id: ID!){
  product(id: $id) {
    id
    title
    description
    seo { title description }
    featuredImage {
      url
    }
    metafield(namespace: "custom_fields" key: "image") { 
      value
    }
    variants(first: 3) {
      edges {
        cursor
        node {
          id
          title

          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
}
`;
