export const FETCH_ALL_COLLECTIONS = `
query queryAllCollectionsWithProducts {
  collections(first: 50) {
    edges {
      cursor
      node {
        id
        title
        handle
        description
        image {url}
				metafield(namespace:"custom_fields" key:"mob_image") {
					 id
					 namespace
					 type
					 value
				}
				products(first:7) {
					edges {
						cursor
						node {
							id
							handle
							title
							seo {
								title
								description
							}
							tags
					priceRange{maxVariantPrice {amount} }
					metafield(namespace: "custom_fields" key: "image") { 
					 namespace
					 value
					}
				metafields(identifiers: [
					{namespace: "custom", key: "enable_white_text"},
					{namespace: "custom_fields", key: "image"}
				]) {
				 value
				}
				variants(first: 2) {
					
      edges {
				
        cursor
        node {
          id
          title
				
				
          price {
            amount
            currencyCode
          }
        }
      }
    }
						}
					}
				}
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`;
