import React from "react";
import { Box } from "@mui/material";
import {
  PolishTypes,
  ProductInterface,
} from "../../interfaces/product.interface";
import UpSellBottleBackground from "../../../../components/product-backgrounds/upsell-bottle-background";
import CartDrawerImage from "./cart-drawer-image";
import CartItemDetails from "./cart-item-details";
import CustomProductBackground from "../../../../components/product-backgrounds/custom-product-background";
import ShopifyProductBottleBackground from "../../../../components/product-backgrounds/shopify-product-background";
import CollectionAsProductBackground from "../../../../components/product-backgrounds/collection-as-product-background";
import { useNavigate } from "react-router-dom";
import { PRODUCT, WALMART } from "../../../../routing/CONSTANTS";
import useCartActions from "../../hooks/useCartActions";
import { useGetCartCustomPolishColor } from "../../hooks/useGetCartCustomPolishColor";
import { useAppSelector } from "../../../../utils/hooks/redux-hooks";
import { selectIsWalmartPath } from "../../../walmart/state/walmart-slice";
import cuticleOil from "../../../../assets/cuticleOilBottleRender.webp";
import cuticleOilPng from "../../../../assets/cuticleOilBottleRender.png";
interface Props {
  item: ProductInterface;
}
const CartDrawerItem = ({ item }: Props) => {
  const { closeCart } = useCartActions();
  const { hue, sat, val } = useGetCartCustomPolishColor(item);
  const navigate = useNavigate();
  const isWalmart = useAppSelector(selectIsWalmartPath);


  const navigateToProductPage = () => {
    const walmartNav = isWalmart ? WALMART : "";
    if (
      item.polishType === PolishTypes.ShopifyPolish ||
      item.polishType === PolishTypes.UpsellPolish
    ) {
      const handle = item.key.split(" ").join("-");
      navigate(`${walmartNav}${PRODUCT}/${handle}`);
    } else if (item.polishType === PolishTypes.CustomPolish) {
      if (isNaN(parseInt(item.key))) {
        let hexParam: string;
        if (item.key.includes("-")) {
          const sanitizedHex = item.key.split("-")[0].split("#")[1];
          hexParam = `custom-${sanitizedHex}`;
        } else {
          const sanitizedHex = item.key.slice(1);
          hexParam = `custom-${sanitizedHex}`;
        }
        navigate(`${walmartNav}${PRODUCT}/${hexParam}`, {
          state: { polishName: item.value },
        });
      } else {
        navigate(`${walmartNav}${PRODUCT}/${item.key}`, {
          state: { polishName: item.value },
        });
      }
    } else {
      const handle = item.key.split("'")[0];
      navigate(`${walmartNav}/${handle}`);
    }

    closeCart();
  };

  const getBottleBackground = () => {
    if (item.polishType === PolishTypes.ShopifyPolish) {
      return <ShopifyProductBottleBackground productImg={item.img!} isCart />;
    } else if (item.polishType === PolishTypes.UpsellPolish) {
      return <UpSellBottleBackground title={item.key} />;
    } else if (item.polishType === PolishTypes.FullCollectionProduct) {
      return <CollectionAsProductBackground productImg={item.img!} isCart />;
    } else {
      return (
        <CustomProductBackground
          isProdPage={false}
          hsv={[hue, sat, val]}
          rotate={false}
          isCart
        />
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        mb: { xs: "15px", sm: "20px", md: "50px" },
        position: "relative",
        flexWrap: "nowrap",
        justifyContent: "flex=start",
        alignItems: { xs: "flex-start", sm: "stretch" },
        pb: { xs: "10px", sm: 0, md: 0 },
        borderBottom: { xs: "2px solid rgba(0,0,0,.05)", md: "none" },
      }}
      data-testid="cart-item"
    >
      <Box onClick={navigateToProductPage} sx={{ cursor: "pointer" }}>
        <CartDrawerImage
          getBottleBackground={getBottleBackground}
          polishType={item.polishType}
          imgSrc={item.key.toLowerCase().includes('cuticle') ? cuticleOil : undefined}
          imgSrcFallback={item.key.toLowerCase().includes('cuticle') ? cuticleOilPng : undefined}

        />
      </Box>
      <CartItemDetails item={item} />
    </Box>
  );
};
export default React.memo(CartDrawerItem);
