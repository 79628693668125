import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CollectionProductInterface } from "../../shop-all/interfaces/collection-interfaces";
import AddToCartButton from "../../../components/add-to-cart-button";
import useCartActions from "../../cart/hooks/useCartActions";
import { AddToCartInterface } from "../../cart/interfaces/add-to-cart.interface";
import { log } from "console";

interface Props {
  product: CollectionProductInterface;
  products: CollectionProductInterface[];
}

const CollectionProductBuyCollection = ({ products, product }: Props) => {
  
  const { createOrAddToCart } = useCartActions();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const regularPrice = parseInt(product.variants[1].price);
  const reducedPrice = parseInt(product.variants[0].price);

  const addCollectionToCart = async () => {
    let productValue = "";

    products.forEach((product, idx) => {
      if (idx !== products.length - 1) {
        productValue += `${product.title} / `;
      } else {
        productValue += `${product.title}`;
      }
    });
    setIsLoading(true);
    const itemToAdd: AddToCartInterface = {
      key: product.title,
      value: productValue,
      quantity: 1,
      merchId: product.merchId,
      img: product.image,
    };
    createOrAddToCart(itemToAdd, 1);
    setIsLoading(false);
  };
  return (
    <Box
      py={6}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box px="10px" mb={1}>
        <Typography
          textAlign="center"
          component="p"
          fontSize={{ xs: "22px", sm: "24px" }}
        >
          {product.variants[0].price !== "0.0" &&
          product.variants[1].price !== "0.0"
            ? `Grab the whole collection for just $${reducedPrice} (save $${
                regularPrice - reducedPrice
              })`
            : `Grab the whole collection for $${reducedPrice}`}
        </Typography>
      </Box>
      <Box width={{ xs: "260px", md: "350px" }}>
        <AddToCartButton
          isLoading={isLoading}
          polishPrice={parseInt(product.variants[0].price)}
          btnStyle="collectionPageBtn"
          createOrAddToCart={addCollectionToCart}
        />
      </Box>
    </Box>
  );
};
export default CollectionProductBuyCollection;
