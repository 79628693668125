import React from "react";
import { Helmet } from "react-helmet-async";

interface SEOProps {
  title: string;
  description: string;
  type: string;
  urlEndpoint: string;
}
const SEO = ({ title, description, type, urlEndpoint }: SEOProps) => {

  return (
    <Helmet>
      {/*  Standard metadata tags  */}
      <title>{title}</title>
      <meta name="description" content={description} data-rh="true" />
      <link rel="canonical" href={`${window.location.origin}/${urlEndpoint}`} />
      {/* End standard metadata tags  */}
      {/* Facebook tags  */}

      <meta property="og:url" content={`www.blankbeauty.com/${urlEndpoint}`} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/*  End Facebook tags  */}
      {/*  Twitter tags  */}
      <meta name="twitter:creator" content="Blank Beauty" />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="facebook-domain-verification" content="zdsreog0vdaz4veu7tkl0ed5tbx3r0" />
      {/* End Twitter tags  */}
    </Helmet>
  );
};
export default SEO;
