import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { returnInstructions } from "../../CONSTANTS/return-instructions";
import Instruction from "./instruction";

const ReturnInstructions = () => (
  <Box py={{ xs: 2, sm: 2.5 }} display="flex" flexDirection="column">
    <Box mb={1.5}>
      <Typography
        variant="body1"
        fontSize={{ xs: "14px", sm: "16px" }}
        lineHeight={{ xs: "16px", sm: "20px" }}
      >
        It couldn't be easier! Simply:
      </Typography>
    </Box>
    <Box>
      {returnInstructions.map((instruction) => (
        <Instruction key={instruction.step} instruction={instruction} />
      ))}
    </Box>
  </Box>
);

export default ReturnInstructions;
