import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ProductCardContainer from "../../../../components/product-card/product-card-container";
import { useGetTrendingShadesQuery } from "../../hooks/useGetTrendingShadesQuery";

const Shades = () => {
  const { data } = useGetTrendingShadesQuery();
  return (
    <Box mt={{ xs: 3, sm: 5 }} maxWidth={{ sm: "700px", md: "1200px" }}>
      <Grid
        container
        justifyContent="center"
        rowSpacing={{ xs: 2, sm: 5 }}
        spacing={{ xs: 1, sm: 5 }}
      >
        {data.map((product) => (
          <Grid key={product.title} item xs={6} md={3}>
            <ProductCardContainer
              productType="shopify"
              landing
              shopifyProduct={product}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Shades;
