import { graphqlClientRequest } from "../../../services/graphQl/graphQl-common";
import { COLLECTION_AS_PRODUCT_QUERY } from "../../../services/graphQl/queries/fetch-collection-as-product";
import { FETCH_PRODUCT_BY_HANDLE_QUERY } from "../../../services/graphQl/queries/fetch-product-by-handle";
import { FETCH_PRODUCT_ID_BY_HANDLE_QUERY } from "../../../services/graphQl/queries/fetch-product-id-by-handle";
import http from "../../../services/http/http-common";
import {
  CollectionProductInterface,
  VariantInterface,
} from "../../shop-all/interfaces/collection-interfaces";

const mapTags = (item: any): string[] => {
  const productTags: string[] = [];
  item.forEach((tag: string) => {
    if (tag.includes("badge")) {
      const glimmerTag = tag.split(":")[1];
      productTags.push(glimmerTag);
    } else {
      productTags.push(tag);
    }
  });
  return productTags;
};

const getColor = (colorId: string) => {
  try {
    const color = http.get(`/bbmExpress/api/webColors/webSearch/${colorId}`);
    return color;
  } catch (e) {
    throw new Error(`Color fetch error: ${e}`);
  }
};

const fetchCollectionAsProduct = async (handle: string) => {
  const data = {
    query: COLLECTION_AS_PRODUCT_QUERY,
    variables: {
      handle: handle,
    },
  };
  try {
    const res = await graphqlClientRequest.then((res) => res.post(data));
    const variantResponse: any[] = res.data.data.product.variants.edges;
    const productResponse = res.data.data.product;
    
    const productId = productResponse.id;
    const productHex = productResponse.hex?.value ?? "#FFF";
    const title = productResponse.title;
    const description = productResponse.description;
    const prodHandle = productResponse.handle;
    const image = productResponse.media.edges[0].node.previewImage.url;
    const productPrices: VariantInterface[] = [
      {
        variantId: variantResponse[0].node.id,
        title: "Reduced price",
        price: variantResponse[0].node.price.amount,
      },
      {
        variantId: "OriginalPriceVariant",
        title: "Original Price",
        price: productResponse.compareAtPriceRange.maxVariantPrice.amount,
      },
    ];

    const newProduct: CollectionProductInterface = {
      productId: productId,
      merchId: productPrices[0].variantId,
      title,
      description,
      seoTitle: "",
      seoDescription: "",
      handle: prodHandle,
      image,
      hex:productHex,
      variants: productPrices,
      cursor: "",
      isRandomImg: false,
      tags: [],
    };
    return newProduct;
  } catch (err) {
    throw new Error(`Fetch Collection as product error: ${err}`);
  }
};

const fetchProduct = async (handle: string) => {
  const data = {
    query: FETCH_PRODUCT_BY_HANDLE_QUERY,
    variables: {
      handle: handle,
    },
  };
  try {
    const res = await graphqlClientRequest.then((res) => res.post(data));
    
    const variantResponse: [] = res.data.data.product.variants.edges;
    const productResponse = res.data.data.product;
    const productId = productResponse.id;
    const merchId = productResponse.variants.edges[0].node.id;
    const title = productResponse.title;
    const seo = {
      title: productResponse.seo.title,
      description: productResponse.seo.description,
    };
    const description = productResponse.descriptionHtml;
    const prodHandle = productResponse.handle;
    const productHex = productResponse.hex.value;
    const image = productResponse.metafield.value;
    const variants: VariantInterface[] = [];
    variantResponse.forEach((item: any) => {
      const prodTitle =
        item.node.title === "Default Title"
          ? item.node.price.amount === "11.0"
            ? "Gel"
            : "Lacquer"
          : item.node.title;
      const newVariant: VariantInterface = {
        variantId: item.node.id,
        price: item.node.price.amount,
        title: prodTitle,
      };
      variants.push(newVariant);
    });

    const productTags = mapTags(productResponse.tags);

    const newProduct: CollectionProductInterface = {
      productId,
      merchId,
      title,
      description,
      seoTitle: seo["title"],
      seoDescription: seo["description"],
      handle: prodHandle,
      image,
      variants,
      cursor: "",
      hex: productHex,
      isRandomImg: false,
      tags: productTags,
    };
    return newProduct;
  } catch (e) {
    throw new Error(`fetch product error: ${e}`);
  }
};
const fetchProductIdByHandle = async (name: string): Promise<string> => {
  const handle = getHandleFromName(name);
  const data = {
    query: FETCH_PRODUCT_ID_BY_HANDLE_QUERY,
    variables: {
      handle: handle,
    },
  };
  try {
    const res = await graphqlClientRequest.then((res) => res.post(data));
    const productResponse = res.data.data.product;
    const productId = productResponse.id;

    return productId;
  } catch (e) {
    throw new Error(`fetch product id error: ${e}`);
  }
};

const getHandleFromName = (name: string) => {
  const handle = name.split(" ").join("-");
  return handle;
};

const ProductService = {
  getColor,
  fetchProduct,
  fetchCollectionAsProduct,
  fetchProductIdByHandle,
};

export default ProductService;
