export const CART_NOTE_MUTATION = `
mutation cartNoteUpdate($cartId: ID!, $note: String ) {
  cartNoteUpdate(cartId: $cartId, note: $note) {
    cart {
        id
			note
      createdAt
      updatedAt
      
    }
    userErrors {
      field
      message
    }
  }
}
`;
