import axios, { AxiosError, AxiosResponse } from "axios";
import { BlankColorInterface } from "../../../color_picker/interface/blank-color-interface";
import http from "../../../../services/http/http-common";
import { ColorLibraryColorsInterface, ColorLibraryRecommendationInterface } from "../interfaces/color-library-colors-interface";
import { ServerError } from "../../../../services/types/serverError.type";

const LIBRARY_COLORS_URL = "/bbmExpress/api/webLibrary";

const COLOR_RECOMMENDATIONS_URL = "/bbmExpress/api/blankKiosk/hexSearch?kn=1&limit=10"

const getLibraryColors = async (collection: string) => {
  const upperCasedName =
    collection === "Grey" ? "GRAYSCALE" : collection.toLocaleUpperCase();

  try {
    const response = await http.get<ColorLibraryColorsInterface>(
      LIBRARY_COLORS_URL,
      {
        params: { name: upperCasedName },
      }
    );

    const library = mapLibraryColors(response);
    return library;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw serverError.response.data;
      }
    }
    throw new Error(`Error fetching blank library colors: ${err}`);
  }
};



const getColorRecommendations = async (hexCode: string, delta: number = 10): Promise<ColorLibraryRecommendationInterface[]> => {


  try {
    hexCode = hexCode.replace('#','')

    const url = `${COLOR_RECOMMENDATIONS_URL}&hex=${hexCode}`

    
    const response = await http.get<ColorLibraryRecommendationInterface[]>(
      url

    );


    /**
     * Each iteration searches with 10 delta, 
     * if results are not found it tries 2 more times
     */
    if (delta >= 30) {

      return []

    }

    if (response.data.length < 1) {

      return getColorRecommendations(hexCode, delta + 10)
    }

    const filteredData = response.data.filter((singleResponse)=>{
      return !!singleResponse

    })

    return filteredData


  } catch (err) {

    if (axios.isAxiosError(err) && err.response) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        throw serverError.response.data;
      }
    }
    throw new Error(`Error fetching blank library colors recommendation: ${err}`);
  }
};





const mapLibraryColors = (
  response: AxiosResponse<any, any>
): ColorLibraryColorsInterface => {
  const colorList: BlankColorInterface[] = [];
  response.data.data.forEach((item: any) => {
    const newColor: BlankColorInterface = {
      id: item.idColor,
      hue: item.AssignedHue,
      sat: item.AssignedSaturation,
      val: item.AssignedValue,
      colorGroup: item.ColorGroup,
      mainTag: item.MainIndex,
      minorTag: item.SubIndex,
    };
    colorList.push(newColor);
  });

  let tags: string[] = response.data.tags;
  const tagSet: Set<string> = new Set();
  tags.forEach((tag) => {
    const trimmed = tag.trim();
    tagSet.add(trimmed);
  });

  const library: ColorLibraryColorsInterface = {
    blankColors: colorList,
    tags: Array.from(tagSet),
  };

  return library;
};

const ColorLibraryColorsService = {
  getLibraryColors,
  getColorRecommendations
};

export default ColorLibraryColorsService;
