import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDisplayColor } from "../../../../../utils/hooks/useDisplayColor";

interface FilterBarItemProps {
  item: string;
  active: string;
  handleFilter: (item: string) => void;
  isCollection: boolean;
}

const FilterBarItem = ({
  item,
  handleFilter,
  isCollection,
  active,
}: FilterBarItemProps) => {
  const { getFilterCircles } = useDisplayColor();

  return (
    <Box display="flex" alignItems="center" onClick={() => handleFilter(item)}>
      {isCollection && (
        <Box
          height={{ xs: "12px", sm: "16px" }}
          width={{ xs: "12px", sm: "16px" }}
          borderRadius="50%"
          sx={{ backgroundColor: getFilterCircles(item) }}
          mr={2}
        />
      )}
      <Box>
        <Typography
          sx={{ textDecoration: active === item ? "underline" : "none" }}
        >
          {item}
        </Typography>
      </Box>
    </Box>
  );
};
export default React.memo(FilterBarItem);
