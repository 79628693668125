import { useState, useEffect } from "react";
import PixelService from "../../../services/pixels/pixel-service";
import { convertHexToHsv } from "../../../utils/convertHexToHsv";
import ProductService from "../services/product-service";

export const useGetProductCustomPolishColor = (color: string) => {
  const [hue, setHue] = useState<number>(0);
  const [sat, setSat] = useState<number>(0);
  const [val, setVal] = useState<number>(99);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);

  useEffect(() => {
    if (color.includes("custom-")) {
      const value = color.split("-")[1];
      const hex = `#${value}`;
      const hsv = convertHexToHsv(hex);
      setHue(hsv.h);
      setSat(hsv.s);
      setVal(hsv.v);

      setIsLoading(false);
      setIsSuccess(true);
    } else {
      const getColor = async () => {
        try {
          const product = await ProductService.getColor(color);
          setHue(product.data.AssignedHue);
          setSat(product.data.AssignedSaturation);
          setVal(product.data.AssignedValue);

          PixelService.pixelViewContent(color, 9.0);
        } catch (err) {
          setIsSuccess(false);
        } finally {
          setIsLoading(false);
        }
      };
      getColor();
    }
    window.scrollTo(0, 0);
  }, [color]);

  return { hue, sat, val, isLoading, isSuccess };
};
