import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  cartCount: number;
}
const ShippingCost = ({ cartCount }: Props) => (
  <Box
    mt={1.5}
    display="flex"
    justifyContent="space-between"
    alignItems="flex-end"
  >
    <Typography fontFamily="ABC Favorit Book" fontSize="14px" lineHeight="19px">
      Shipping
    </Typography>
    <Box>
      <Box
        fontFamily="Roboto"
        fontSize="14px"
        lineHeight="16px"
        fontWeight={300}
        fontStyle="italic"
        sx={{ textDecorationLine: "line-through" }}
      >
        {cartCount > 2 ? "estimated $5.90" : ""}
      </Box>
      <Box
        fontFamily="Roboto"
        fontSize="14px"
        lineHeight="16.41px"
        fontWeight="lighter"
        fontStyle="italic"
      >
        {cartCount < 3 ? "estimated $5.90" : "FREE"}
      </Box>
    </Box>
  </Box>
);

export default ShippingCost;
