import React, { FormEvent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ContactSnackBar from "./contact-snackbar.component";
import { useFormControls } from "../hooks/use-help-controls";
import { useNavigate } from "react-router-dom";
import { FORM_SUBMISSION_CONFIRMATION } from "../../../routing/CONSTANTS";
const ContactForm = ({ displayStoreLocation }: { displayStoreLocation?: boolean }) => {
  const { errors, state, phone, handleSubmit, handleChange } =
    useFormControls({
      doNotValidate: displayStoreLocation ? [] : ["storeLocation"],
    });

  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);
  const [isMessageSuccess, setIsMessageSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const submit = (e: FormEvent) => {
    handleSubmit(e, setIsOpenSnackbar, setIsMessageSuccess);
  };

  useEffect(() => {

    if(isMessageSuccess) {

      navigate(`${FORM_SUBMISSION_CONFIRMATION}?source=${displayStoreLocation?'help':'contact' }`);

    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isMessageSuccess])

  return (
    <Box
      mt={3}
      width="100%"
      component="form"
      onSubmit={submit}
      display="flex"
      flexDirection="column"
    >
      <Box
        width="100%"
        px={3}
        mb={{ xs: 1, md: 2 }}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 1, md: 0 } }}
          fullWidth
          variant="outlined"
          required
          label="Full Name"
          name="name"
          value={state.name}
          onChange={handleChange}
          error={errors.nameError.length > 0}
          helperText={errors.nameError}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Phone Number"
          name="phoneNumber"
          value={phone}
          onChange={handleChange}
          error={errors.phoneError !== ""}
          helperText={errors.phoneError}
        />
      </Box>
      <Box
        width="100%"
        px={3}
        mb={{ xs: 1, md: 2 }}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 1, md: 0 } }}
          fullWidth
          variant="outlined"
          required
          label="Email"
          name="email"
          value={state.email}
          onChange={handleChange}
          error={errors.emailError.length > 0}
          helperText={errors.emailError}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Order Number"
          name="orderNumber"
          value={state.orderNumber}
          onChange={handleChange}
        />
      </Box>
      {!!displayStoreLocation && <Box
        width="100%"
        px={3}
        mb={{ xs: 1, md: 2 }}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
      >

        <TextField
          fullWidth
          required
          variant="outlined"
          label="Store Location"
          name="storeLocation"
          value={state.storeLocation}
          onChange={handleChange}
          error={errors.storeLocationError.length > 0}
          helperText={errors.storeLocationError}
        />
      </Box>}

      <Box width="100%" px={3} mb={2}>
        <TextField
          fullWidth
          variant="outlined"
          multiline
          rows={7}
          required
          label="Comments/Questions"
          name="comments"
          value={state.comments}
          onChange={handleChange}
          error={errors.commentError.length > 0}
          helperText={errors.commentError}
        />
      </Box>
      <Box
        mx="auto"
        px={{ xs: 3, md: 2 }}
        mt={1}
        width={{ xs: "100%", sm: "75%", lg: "50%" }}
      >
        <Button type="submit" fullWidth variant="contained" sx={{ py: 1 }}>
          Submit
        </Button>
      </Box>
      <ContactSnackBar
        isMessageSuccess={isMessageSuccess}
        isOpenSnackbar={isOpenSnackbar}
        setIsOpenSnackbar={setIsOpenSnackbar}
      />
    </Box>
  );
};
export default ContactForm;
