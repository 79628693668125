export type Color = `rgb(${number},${number},${number})`;

export enum PolishTypes {
  UpsellPolish,
  CustomPolish,
  ShopifyPolish,
  FullCollectionProduct,
}

export interface ProductInterface {
  lineId: string;
  quantity: number;
  price: number;
  key: string;
  value: string;
  merchId: string;
  polishType: PolishTypes;
  productId: string;
  img?: string;
}
