import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import FilterBar from "./components/filter/filter-bar.component";
import BlankAppbar from "../../../components/blank-appbar/blank-appbar";
import { ScrollToTopOnMount } from "../../../routing/scroll-to-top";
import ShopAllTitle from "../../shop-all/components/shop-all-title";
import ColorLibraryGrid from "./components/color-library-grid";
import { useGetBlankLibraryColorsQuery } from "./hooks/useGetBlankLibraryColorsQuery";
import { usePixelServiceOnLoad } from "../../../services/pixels/usePixelService";

interface Props {
  isWalmart?: boolean;
}
const ColorLibraryColorPage = ({ isWalmart = false }: Props) => {
  const { collection } = useParams();
  const { data, filteredData, displayColor, currentTag, isLoading } =
    useGetBlankLibraryColorsQuery(collection);

  usePixelServiceOnLoad();

  useEffect(() => {
    document.body.style.background = "#E5E5E5";
  }, []);

  const displayTag = (): string => {
    const col = collection!.toUpperCase();
    const display = currentTag.toUpperCase() === col ? "ALL" : currentTag;

    return display.toUpperCase();
  };

  return (
    <Box height="100%">
      <BlankAppbar isWalmartComponent={isWalmart} />
      <ScrollToTopOnMount />
      <Box m="0 auto" width="100%" maxWidth="1400px">
        <ShopAllTitle title={collection ? collection : ""} topMargin={8} />
        <FilterBar
          colorCount={filteredData ? filteredData.length : 0}
          tags={data ? data.tags : []}
          title={`${displayTag()}`}
        />
        <ColorLibraryGrid
          displayColor={displayColor}
          filteredLibraryColors={filteredData ? filteredData : []}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default ColorLibraryColorPage;
