import React from "react";
import Box from "@mui/material/Box";
import { AboutYourPolishInterface } from "../CONSTANTS/about-your-polish";
import { Typography } from "@mui/material";
import AboutStep from "./about-step";
import TapButton from "./about-steps-tap-btn";
import ImgWithPngFallback from "../../../../components/img-with-png-fallback";

interface Props {
  infoItems: AboutYourPolishInterface[];
  activeIdx: number;
  setActiveIdx: React.Dispatch<React.SetStateAction<number>>;
}
const AboutSteps = ({ infoItems, activeIdx, setActiveIdx }: Props) => (
  <Box
    width={{ xs: "109%", sm: "54%", md: "45%" }}
    display="flex"
    justifyContent="center"
    mb={{ xs: 2, sm: 0 }}
    mr={{ sm: 1, md: 3, lg: 2 }}
    sx={{
      transform: {
        xs: "translateX(-6%)",
        sm: "none",
      },
    }}
  >
    <Box
      component="figure"
      width={{ xs: "130px", sm: "142px", md: "170px" }}
      height={{ xs: "342px", sm: "361px", md: "444px" }}
    >
      <ImgWithPngFallback
        src={infoItems[activeIdx].bottle}
        fallback={infoItems[activeIdx].backupBottle}
        alt={infoItems[activeIdx].alt}
        width="100%"
        height="100%"
      />
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      ml={{ xs: 2, sm: 1.5, md: 3 }}
    >
      <Box mb={2.75} width="100%" display="flex" alignItems="center">
        <Typography fontFamily="ABC Favorit Book" fontSize="18px">
          Tap
        </Typography>
        <TapButton />
        <Typography fontFamily="ABC Favorit Book" fontSize="18px">
          to know more
        </Typography>
      </Box>
      <Box>
        {infoItems.map((infoItem, index) => (
          <AboutStep
            key={infoItem.stepTitle}
            infoItem={infoItem}
            activeIdx={activeIdx}
            setActiveIdx={setActiveIdx}
            index={index}
          />
        ))}
      </Box>
    </Box>
  </Box>
);

export default AboutSteps;
