export interface FaqInterface {
  question: string;
  answer: string;
}

export const faqs: FaqInterface[] = [
  {
    question: "How can I contact _blank beauty?",
    answer:
      "Email us at hello@bleebeauty.com or DM our Instagram @_blankbeauty and we would be happy to help!",
  },
  {
    question: "Where do you ship?",
    answer:
      "We currently ship to the contiguous United States and Canada. We are unable to ship to Alaska, Hawaii, or Puerto Rico due to shipping restrictions with nail polish, which is classified as a potentially hazardous material.",
  },
  {
    question: "Is there a limit on how many custom shades I can order?",
    answer:
      "There is no limit to your creativity! Create as many custom shades as you would like.",
  },
  {
    question: "What is your refund policy?",
    answer:
      "We are happy to take orders back for a full refund of the product(s) within 30 days of receiving them. Please reach out to us with any questions about your order.",
  },
  {
    question: "Can I modify an order after I've placed it?",
    answer:
      "Due to the fact that each polish is custom and made to order, you cannot modify the order after you have placed it.",
  },
  {
    question: "Can I cancel my order?",
    answer:
      "We are sorry to hear you would like to cancel your order! Please email customer service at hello@bleebeauty.com. Please note we ship orders as quickly as possible and cannot guarantee cancellation.",
  },
  {
    question: "What ingredients are in your products?",
    answer:
      "At _blank beauty we are all about creating the cleanest formulation possible without compromising on performance. That's why we formulate to be “10-free” which means we are free of the ten harmful chemicals commonly found in nail polishes including formaldehyde, toluene, DBP, camphor, formaldehyde resin, xylene and don't contain parabens, fragrances, phthalates, and animal ingredients. Our formula is also vegan and cruelty free!",
  },
  {
    question: "Can I purchase a shade that is not custom?",
    answer: "Yes! We have an entire library of shades to choose from.",
  },
];
