import React from "react";
import Box from "@mui/material/Box";
import ModalTitle from "./modal-title";
import ModalContent from "./modal-content";
import CloseModalFloat from "./close-modal-float";
import ModalImage from "./modal-image";
import CloseModalButton from "./close-modal-btn";

interface Props {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Modal = ({ setIsVisible }: Props) => {
  const handleCloseModal = () => {
    setIsVisible(false);
  };
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="30px"
      position="relative"
      sx={{
        backgroundColor: "white",
        maxWidth: "856px",
        maxHeight: { xs: "525px", sm: "600px" },
      }}
    >
      <ModalImage />
      <CloseModalFloat handleCloseModal={handleCloseModal} />

      <Box
        width="100%"
        px={{ xs: 2, sm: 6, md: 10 }}
        display="flex"
        flexDirection="column"
      >
        <ModalTitle />
        <ModalContent />
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        py={{ xs: 2, sm: 3 }}
      >
        <CloseModalButton handleCloseModal={handleCloseModal} />
      </Box>
    </Box>
  );
};

export default Modal;
