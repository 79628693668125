import React from "react";
import Box from "@mui/material/Box";
import ColorStack from "../../open-endorse/components/color-stack";
import CollectionProductMakeItYours from "./collection-product-make-it-yours";

const CollectionProductBottomCTA = () => (
  <Box
    pt={{ xs: 0, md: 15 }}
    width="100%"
    sx={{ backgroundColor: "white" }}
    overflow="hidden"
  >
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="flex-end"
      position="relative"
    >
      <Box position={{ sm: "absolute" }} top="0" left="10%">
        <CollectionProductMakeItYours />
      </Box>
      <ColorStack />
    </Box>
  </Box>
);

export default CollectionProductBottomCTA;
