import React from "react";
import Box from "@mui/material/Box";
import IcoBottle from "./ico-polish-bottle";
import ShopifyProductBottleBackground from "../product-backgrounds/shopify-product-background";
import "../../pages/product/product.css";
import cuticleOilWebp from "../../assets/cuticleOilBottleRender.webp";
import cuticleOilPng from "../../assets/cuticleOilBottleRender.png";
interface ShopifyBottleImageProps {
  img: string;
  isCart: boolean;
  productTitle?:string;
}
const ShopifyBottleImage = ({ img, isCart, productTitle }: ShopifyBottleImageProps) => {
  return (
    <>
      <Box
        sx={{
          position: { sm: "absolute" },
          width: "100%",
          top: { xs: 0, md: "20px", lg: "-20px" },
          bottom: 0,
          left: 0,
        }}
      >
        <Box className="shopify-background-container" width="100%">
          <ShopifyProductBottleBackground
            rotate
            productImg={img}
            isCart={isCart}
          />
        </Box>
      </Box>
      <Box
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        m="0 auto"
      >
        <Box
          height="100%"
          position="absolute"
          top={{ xs: "-36px", sm: "-80px", md: "-100px" }}
        >
          <Box
            className="ico-bottle-container"
            height={{
              sm: "430px",
              md: "500px",
              lg: "570px",
            }}
          >
            <IcoBottle
            
              imgSrc={productTitle?.toLowerCase().includes('cuticle') ? cuticleOilWebp : undefined}
              imgSrcFallback={productTitle?.toLowerCase().includes('cuticle') ? cuticleOilPng : undefined}
            imgHeight="100%" />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ShopifyBottleImage;
