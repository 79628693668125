import React from "react";
import Instagram from "@mui/icons-material/Instagram";

interface Props {
  hover: boolean;
}

const GradientInstagramIcon = ({ hover }: Props) => (
  <>
    {hover ? (
      <>
        <svg width={0} height={0}>
          <linearGradient id="linearColors" gradientTransform="rotate(70)">
            <stop offset="0%" stopColor="rgba(130,12,239,1)" />
            <stop offset="44%" stopColor="rgba(239,3,184,1)" />
            <stop offset="90%" stopColor="rgba(242,14,81,1)" />
            <stop offset="97%" stopColor="rgba(241,174,2,1)" />
          </linearGradient>
        </svg>
        <Instagram fontSize="large" sx={{ fill: "url(#linearColors)" }} />
      </>
    ) : (
      <Instagram htmlColor="black" fontSize="large" />
    )}
  </>
);
export default GradientInstagramIcon;
