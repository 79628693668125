export const APPLY_DISCOUNT_CODES = `
mutation checkoutDiscountCodeApplyV2($checkoutId: ID!, $discountCode: String!) {
  checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
    checkout {
    	id, 
			webUrl,
		   discountApplications(first:5){edges{node{targetSelection allocationMethod value{ __typename } targetType}}}
			lineItems(first:5) {
				edges {
					node {
						title
						quantity
            customAttributes{key value}
					}
				}
			}
    }
   
  }
}
`;
