import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import ProductCardContainer from "../../../components/product-card/product-card-container";
import { CollectionProductInterface } from "../../shop-all/interfaces/collection-interfaces";

interface Props {
  products: CollectionProductInterface[];
}
const OpenEndorseGrid = ({ products }: Props) => (
  <Box mt={{ xs: 3, sm: 5 }} maxWidth="1400px" m="0 auto">
    <Grid
      container
      justifyContent="center"
      rowSpacing={{ xs: 2, sm: 5 }}
      columnGap={{ sm: 3 }}
      spacing={{ xs: 1, sm: 1 }}
    >
      {products.map((product) => (
        <Grid key={product.title} item xs={6} sm={4} md={3}>
          <ProductCardContainer
            productType="shopify"
            landing
            shopifyProduct={product}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
);
export default OpenEndorseGrid;
