import React from "react";
import Box from "@mui/material/Box";
import { keyframes } from "@mui/material";

interface ShopifyProductBackgroundProps {
  productImg: string;
  rotate?: boolean;
  maxWidth?: { xs: string; sm: string; md: string };
  isCart: boolean;
}
const ShopifyProductBottleBackground = ({
  productImg,
  rotate,
  maxWidth,
  isCart,
}: ShopifyProductBackgroundProps) => {
  const spin = keyframes`
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(360deg); 
  }
`;
  return (
    <Box
      component="img"
      alt="circular background"
      src={productImg}
      maxWidth={
        maxWidth !== undefined
          ? //? { xs: "160px", sm: "225px", md: "260px" }
            maxWidth
          : undefined
      }
      width="100%"
      height="100%"
      sx={{
        objectFit: "cover",
        animation: rotate !== undefined ? `${spin} 4s ease` : "none",
        verticalAlign: "bottom",
        filter: {
          xs: !isCart
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCart
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
        WebkitFilter: {
          xs: !isCart
            ? "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)"
            : "none",
          sm: !isCart
            ? "drop-shadow(0 0 0 black) drop-shadow(0 1px 0 black) drop-shadow(1px 1px 1px black) drop-shadow(-1px -1px 0 black)"
            : "none",
          md: "none",
        },
      }}
    />
  );
};
export default ShopifyProductBottleBackground;
