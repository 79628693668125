import { useEffect, useRef } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../utils/hooks/redux-hooks";

import { fetchCollectionPageCollections } from "../../shop-all/state/collection-actions";
import { RootState } from "../../../store";

export const useGetAllCollections = () => {
  const dispatch = useAppDispatch();
  const rendered = useRef<boolean>(true);

  const collectionsData = useAppSelector(
    (state: RootState) => state.collections.collections
  );

  const status = useAppSelector(
    (state: RootState) => state.collections.collectionsStatus
  );

  const isUninitialized = status === undefined;
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  useEffect(() => {
    if (rendered.current) {
      if (!collectionsData.length && status === undefined) {
        dispatch(fetchCollectionPageCollections());
      }
    }
    return () => {
      rendered.current = false;
    };
  }, [collectionsData.length, dispatch, status]);

  return {
    collectionsData,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
};
