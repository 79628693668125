import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../utils/hooks/redux-hooks";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CONTACT, ROOT, WALMART } from "../../routing/CONSTANTS";
import ShoppingCartBadge from "../shopping-cart-icon";
import { debounce } from "../../utils/debounce";
import { selectAppBarColor } from "../../pages/landing/state/landing-slice";
import BlankLogo from "../logo";
import MobileDrawer from "./components/mobile-drawer";
import "./header.css";
import NavigationLinks from "./components/navigation-links";
import BlankMarquee from "../blank-marquee/blank-marquee";
import BlankXWalmartLogo from "../walmart-x-blank-logo";
import { log } from "console";
interface Props {
  color?: string;
  shoppingCartColor?: string;
  isWalmartComponent?: boolean;
  hideLinks?: boolean;
  id?: string
}

const BlankAppbar = ({ color, isWalmartComponent = false, hideLinks, shoppingCartColor = "white", id }: Props) => {
  const appbarColor = useAppSelector(selectAppBarColor);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [displayWalmartBar, setDisplayWalmartBar] = useState<boolean>(false);
  const [previousUrlWasWalmart, setPreviousUrlWasWalmart] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const openDrawer = () => {
    document.body.classList.toggle("nav-open");
    setIsDrawerVisible(!isDrawerVisible);
  };
  const closeDrawer = () => {
    document.body.classList.remove("nav-open");
    setIsDrawerVisible(false);
  };

  const navigateToRoot = () => {
    if (displayWalmartBar) {
      navigate(WALMART);
    } else {
      navigate(ROOT);
    }
  };

  const getAppBarColor = (): string => {
    if (displayWalmartBar) {
      return "#0071DC";
    }
    if (color) {
      return color;
    }
    if (isDrawerVisible) {
      return appbarColor;
    }
    return "#e7e7e7";
  };


  const initiateGoogleAnalytics = (source: "Walmart" | "Blank") => {

    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any) {
      window.dataLayer.push(arguments);
    }

    gtag("js", new Date());

    // Send custom data to Google Analytics
    gtag("config", "G-CEGJJHLQWQ", {
      customerSource: source,
    });
    gtag("config", "G-4DZV5V2TYV", {
      customerSource: source,
    });
  }



  useEffect(() => {



    const previousLocations = localStorage.getItem("previousLocations");


    if (!!previousLocations) {

      const parsedPreviousLocation: string[] = JSON.parse(previousLocations)

      if (parsedPreviousLocation.length && parsedPreviousLocation.length > 0) {

        if (parsedPreviousLocation[parsedPreviousLocation.length - 1].includes('/walmart')) {
          initiateGoogleAnalytics('Walmart')
          setPreviousUrlWasWalmart(true)

        }
        else {
          initiateGoogleAnalytics('Blank')
          setPreviousUrlWasWalmart(false)

        }
        // add the last 3 previous locations and the current location to local storage
        localStorage.setItem("previousLocations", JSON.stringify([...parsedPreviousLocation.slice(-3), location.pathname]))
      }

      else {
        initiateGoogleAnalytics('Blank')
        localStorage.setItem("previousLocations", JSON.stringify([location.pathname]))

      }


    }
    else {
      localStorage.setItem("previousLocations", JSON.stringify([location.pathname]))
      initiateGoogleAnalytics('Blank')


    }


  }, [location.pathname]);

  useEffect(() => {

    if (isWalmartComponent || previousUrlWasWalmart) {
      setDisplayWalmartBar(true);

    }
    else {
      setDisplayWalmartBar(false);

    }

  }, [isWalmartComponent, previousUrlWasWalmart])


  const handleScroll = debounce(() => {


    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 25) ||
      currentScrollPos < 10
    );

    setPrevScrollPos(currentScrollPos);
  }, 80);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <Box
      component="header"
      id={id}
      className=""
      sx={{
        backgroundColor: getAppBarColor(),
        width: "100%",
        position: "fixed",
        top: visible ? 0 : "-105px",
        left: 0,
        zIndex: 88,
        height: { xs: "50px", md: "60px" },
        transition: "all .5s ease-in-out",
        "& .overlay": {
          "--gradient-width": "50px !important",
          "&::before, &::after": {
            background: isDrawerVisible
              ? getAppBarColor
              : "rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)",
          },
        },
      }}
    >
      <BlankMarquee />

      <Box
        m="0 auto"
        p={{ xs: "11px 20px", md: "0 20px" }}
        height={{ xs: "60px", md: "70px" }}
        borderBottom="2px solid white"
        sx={{
          backgroundColor: getAppBarColor(),
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
        }}
      >
        <Box
          width="100%"
          display="flex"
          maxWidth="1400px"
          margin="0 auto"
          alignItems={{ xs: "center", md: "flex-end" }}
          height={{ xs: "50px", md: "60px" }}
          sx={{
            backgroundColor: getAppBarColor(),
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            width={{ xs: "calc(50% - 95px)", md: "calc(50% - 106px)" }}
            display="flex"
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
            pb="3px"
            position={{ xs: "relative", md: "unset" }}
            sx={{ flexFlow: "row wrap", zIndex: { xs: 1, md: 1 } }}
          >
            {!hideLinks && <Box
              onClick={openDrawer}
              sx={{
                display: { xs: "flex", md: "none" },
                flexFlow: "row wrap",
                alignItems: "center",
                mb: "1px",
                width: "26px",
                height: "16px",
                cursor: "pointer",
                position: "relative",
                zIndex: 9,
              }}
            >
              <Box className="nav-toggle">
                <Box className="icon" />
              </Box>
            </Box>}

            <Box
              sx={{
                width: "100%",
                display: { xs: "none", md: "block" },
              }}
            >
              <NavigationLinks
                isWalmart={displayWalmartBar}
                hideLinks={hideLinks}
                closeDrawer={() => { }}
              />
            </Box>
            <MobileDrawer
              closeDrawer={closeDrawer}
              isDrawerVisible={isDrawerVisible}
              appbarColor={appbarColor}
              isWalmartComponent={displayWalmartBar}
            />
          </Box>
          <Box
            width={{ xs: "190px", md: "212px" }}
            position="relative"
            display="flex"
            justifyContent="center"
            height="31px"
            sx={{ zIndex: { xs: 9, md: 1 } }}
            className={isDrawerVisible ? "logo-wrap" : ""}
          >
            <Box className={!displayWalmartBar ? "blankLogo" : ""} sx={{ cursor: "pointer" }} onClick={navigateToRoot}>
              {displayWalmartBar ? <BlankXWalmartLogo /> : <BlankLogo />}
            </Box>
          </Box>
          <Box
            width={{ xs: "calc(50% - 95px)", md: "calc(50% - 106px)" }}
            display="flex"
            justifyContent="flex-end"
            pb="3px"
            mb={isDrawerVisible ? "0" : "2px"}
            sx={{ zIndex: { xs: 9, md: 1 } }}
          >
            {!displayWalmartBar && !hideLinks && <ContactUsLink />}

            <Box position="relative">
              <ShoppingCartBadge color={shoppingCartColor} fill={getAppBarColor()} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BlankAppbar;

const ContactUsLink = () => {
  return (
    <Box display={{ xs: "none", md: "block" }} mr={3} mb="-3px">
      <Link
        style={{
          fontFamily: "ABC Favorit Book",
          fontSize: "20px",
          color: "black",
          textDecoration: "none",
        }}
        to={CONTACT}
      >
        contact us
      </Link>
    </Box>
  );
};
