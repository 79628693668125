import React from "react";
import Box from "@mui/material/Box";
import modalImg from "../../../../../assets/guanranteeAssets/matchPromiseModal.webp";
import modalImgMobile from "../../../../../assets/guanranteeAssets/matchPromiseMobile.webp";

const ModalImage = () => (
  <>
    <Box
      component="img"
      display={{ xs: "none", sm: "block" }}
      width="100%"
      borderRadius="30px 30px 0 0"
      src={modalImg}
    />

    <Box
      display={{ xs: "block", sm: "none" }}
      width="100%"
      borderRadius="30px 30px 0 0"
      overflow="hidden"
    >
      <Box
        component="img"
        width="100%"
        maxHeight="100px"
        src={modalImgMobile}
        sx={{ objectFit: "cover" }}
      />
    </Box>
  </>
);

export default React.memo(ModalImage);
