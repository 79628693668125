import React from "react";
import { Box } from "@mui/material";
import ContactForm from "./components/contact-form.component";
import ContactInfo from "./components/contact-info.component";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import BlankFooter from "../../components/blank-footer/blank-footer";
import SEO from "../../components/seo";

interface Props {
  isWalmart?: boolean;
}
const HelpPage = ({ isWalmart = false }: Props) => {
  return (
    <Box m="auto" height="100vh" width="100%" bgcolor="secondary.main">
      <SEO
        title="Contact Us | Blank Beauty"
        urlEndpoint="contact"
        description="Long-lasting, chip-resistant nail polish colors by Blank Beauty. 13-free and full coverage that lasts. Want something unique? Create your own nail polish."
        type="website"
      />
      <BlankAppbar isWalmartComponent={isWalmart} />
      <ScrollToTopOnMount />
      <Box
        bgcolor="secondary.main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={10}
        px={{ xs: 1, md: 2 }}
        mx="auto"
        width={{ xs: "100%", md: "75%", lg: "60%" }}
      >
        <ContactInfo title='How can we help?' subHeader="Blank beauty is here to help! We will get back to you quickly to handle all returns and/or other questions with your order!" email='support@blankbeauty.com' />
        <ContactForm displayStoreLocation />
      </Box>
      <BlankFooter />
    </Box>
  );
};

export default HelpPage;
