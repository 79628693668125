import { BuyInBulkInterface } from "./buy-in-bulk.interface";
import bib1 from "../../../../../../assets/wholesaleAssets/wholeSaleBib1.webp";
import bib2 from "../../../../../../assets/wholesaleAssets/wholeSaleBib2.webp";
import bib3 from "../../../../../../assets/wholesaleAssets/wholeSaleBib3.webp";

export const bibTestData: BuyInBulkInterface = {
  bibTitle: "Work with us",
  bibText: `Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi.`,
  bibInfoCards: [
    {
      cardTitle: "Brands of any size",
      cardText: `We have no minimum order policy - so no matter if you're a big organization 
      or just a small local business looking for a fun way to promote their name - we're here to help.`,
      cardImg: bib1,
    },
    {
      cardTitle: "Nail Salons",
      cardText: `We provide professional nail polishes and nail gels to nail polish salons with a 
      custom branded label - our polish, your brand.`,
      cardImg: bib2,
    },
    {
      cardTitle: "Events",
      cardText: `Whether you host a company anniversary party or looking for a custom gift 
      for your wedding guests - a custom nail polish might be the ideal fit!`,
      cardImg: bib3,
    },
  ],
};
