import React from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UpSellInterface } from "../../cart/interfaces/up-sell-interface";
import IcoBottle from "../../../components/ico-bottle-images/ico-polish-bottle";
import useCartActions from "../../cart/hooks/useCartActions";
import { useNavigate } from "react-router-dom";
import {
  getFillImage,
  getFillImageBackup,
} from "../../../utils/get-upSell-background-image";
import PixelService from "../../../services/pixels/pixel-service";
import ImgWithPngFallback from "../../../components/img-with-png-fallback";
import { useAppSelector } from "../../../utils/hooks/redux-hooks";
import { selectCartTotal } from "../../cart/state/cart-slice";
import { selectIsWalmartPath } from "../../walmart/state/walmart-slice";
import { PRODUCT, WALMART } from "../../../routing/CONSTANTS";
import cuticleOilWebp from "../../../assets/cuticleOilBottleRender.webp";
import cuticleOilPng from "../../../assets/cuticleOilBottleRender.png";
interface Props {
  item: UpSellInterface;
}

const UpSellCard = ({ item }: Props) => {
  const navigate = useNavigate();
  const productPrice = item.price.toString().split(".")[0];
  const { addUpSellToCart, openAndCloseCart } = useCartActions();
  const cartSubTotal = useAppSelector(selectCartTotal);
  const isWalmart = useAppSelector(selectIsWalmartPath);

  const addUpSell = () => {
    addUpSellToCart(item);
    PixelService.pixelAddToCart(
      item.productId,
      9.0,
      item.title,
      item.merchId,
      "shop-all",
      cartSubTotal
    );
    openAndCloseCart();
  };

  const createHandle = (title: string) => {
    const handle = title.toLowerCase().split(" ").join("-");
    return handle;
  };

  const handleNavigateToProductPage = (title: string) => {
    const productTitle = createHandle(title);
    const navPath = `${isWalmart ? WALMART : ""}${PRODUCT}/${productTitle}`;
    navigate(navPath);
  };

  return (
    <Box
      width={{ xs: "50%", sm: "38%", md: "25%" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mr={{ xs: 0, md: 8 }}
      mb={{ xs: 5, md: 0 }}
      position="relative"
      sx={{
        // "&:last-child": {
        //   mr: { md: 0 },
        //   mt: { xs: 3, md: 0 },
        //   mb: { xs: 1, md: 0 },
        // },
        cursor: "pointer",
      }}
    >
      <ImgWithPngFallback
        onClick={() => handleNavigateToProductPage(item.title)}
        src={getFillImage(item.title)}
        fallback={getFillImageBackup(item.title)}
        height={{ xs: "155px", sm: "245px" }}
        sx={{
          objectFit: "contain",
        }}
        alt="random shape background"
      />
      <Box
        onClick={() => handleNavigateToProductPage(item.title)}
        position="absolute"
        top={{ xs: "-40px", sm: "-43px", md: "-71px" }}
        left={{ xs: "0", md: "0" }}
        width="100%"
        display="flex"
        justifyContent="center"
      >
        <Box display={{ xs: "none", md: "block" }}>
          <IcoBottle imgSrc={item.title.toLowerCase().includes('cuticle') ? cuticleOilWebp : undefined}
            imgSrcFallback={item.title.toLowerCase().includes('cuticle') ? cuticleOilPng : undefined} imgHeight="289px" />
        </Box>
        <Box display={{ xs: "block", sm: "none", md: "none" }}>
          <IcoBottle imgSrc={item.title.toLowerCase().includes('cuticle') ? cuticleOilWebp : undefined}
            imgSrcFallback={item.title.toLowerCase().includes('cuticle') ? cuticleOilPng : undefined} imgHeight="180px" />
        </Box>
        <Box display={{ xs: "none", sm: "block", md: "none" }}>
          <IcoBottle imgSrc={item.title.toLowerCase().includes('cuticle') ? cuticleOilWebp : undefined}
            imgSrcFallback={item.title.toLowerCase().includes('cuticle') ? cuticleOilPng : undefined} imgHeight="210px" />
        </Box>
      </Box>

      <Box
        onClick={() => handleNavigateToProductPage(item.title)}
        textAlign="center"
        mt={1}
        mb={2}
      >
        <Typography
          fontSize={{ xs: "16px", md: "20px" }}
          fontFamily="ABC Favorit Book"
          sx={{
            textDecoration: "underline",
          }}
        >
          {item.title}
        </Typography>
      </Box>
      <Box width="100%" maxWidth="220px" px={1}>
        <Button
          onClick={addUpSell}
          fullWidth
          variant="contained"
          sx={{
            pt: { xs: "6px", sm: "10.5px" },
            pb: { xs: "6px", sm: "10.5px" },
            fontSize: { xs: "16px", md: "20px" },
          }}
        >
          add to cart - ${productPrice}
        </Button>
      </Box>
    </Box>
  );
};
export default UpSellCard;
