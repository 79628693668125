import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CollectionProductInterface } from "../../../shop-all/interfaces/collection-interfaces";
import { Helmet } from "react-helmet-async";

interface Props {
  item: CollectionProductInterface;
  rotation?: string;
}

const CollectionElement = ({ item, rotation = "0deg" }: Props) => (
  
  <Box
    height={{ xs: "110px", sm: "120px" }}
    width={{ xs: "110px", sm: "120px" }}
    sx={{
      cursor: "pointer",
      transform: `rotate(${rotation})`,
    }}
  >

    <Helmet>
      <link rel="preload" fetchpriority="high" as="image" href={item.image} type="image/webp"></link>
    </Helmet>
    <Box component="figure" position="relative">
      <Box
        component="img"
        src={item.image}
        height="100%"
        width="100%"
        alt="collection image"
        sx={{
          objectFit: "contain",
          verticalAlign: "bottom",
          filter:
            "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)",
          WebkitFilter:
            "drop-shadow(1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(-1px -1px 0 black)",
        }}
      />
      <Box
        height="100%"
        width="100%"
        px="5px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        position="absolute"
        top="0"
        left="0"
        sx={{ flexFlow: "row wrap" }}
      >
        <Typography
          sx={{
            lineHeight: "14px",
            fontSize: "16px",
            fontFamily: "ABC Favorit Book",
            color: item.whiteText
              ? item.whiteText === true
                ? "white"
                : "black"
              : "black",
          }}
        >
          {item.title}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default CollectionElement;
