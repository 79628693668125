import { createSlice } from "@reduxjs/toolkit";
import { RequestState } from "../../../../services/types/requestState.type";
import { LibraryColorListInterface } from "../interfaces/library-color-list.interface";
import { fetchBlankLibraries } from "./library-list-actions";

interface LibraryListState {
  libraryList: LibraryColorListInterface[];
  status: RequestState | undefined;
}

const initialState: LibraryListState = {
  libraryList: [],
  status: undefined,
};

const libraryListSlice = createSlice({
  name: "libraryList",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBlankLibraries.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchBlankLibraries.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.libraryList = action.payload;
      })
      .addCase(fetchBlankLibraries.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export default libraryListSlice.reducer;
