import React, { ChangeEvent, FormEvent, useState } from "react";
import * as Sentry from "@sentry/react";
import PixelService from "../../../services/pixels/pixel-service";
import { FormStateInterface } from "../interfaces/form-state.interface";
import ContactService from "../services/contact-service";


export const initialFormState: FormStateInterface = {
  name: "",
  orderNumber: "",
  email: "",
  comments: "",
  storeLocation: "",
};

interface Options {
  doNotValidate?: (keyof typeof initialFormState)[];
}

export const useFormControls = (options?: Options) => {
  const [state, setState] = useState<FormStateInterface>(initialFormState);
  const [phone, setPhone] = useState<string>("");
  const [errors, setErrors] = useState({
    emailError: "",
    commentError: "",
    phoneError: "",
    nameError: "",
    storeLocationError: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (e.target.name === "phoneNumber") {
      setPhone((prevState) => normalizeInput(value, prevState));
      validatePhone(phone);
    } else {
      setState({
        ...state,
        [e.target.name]: value,
      });

      validate(state);
    }
  };

  const validatePhone = (phoneNumber: string) => {
    let temp: any = { ...errors };
    temp.phoneError =
      phoneNumber.length >= 13
        ? ""
        : "Invalid format. Use area code and number";

    setErrors({
      ...temp,
    });
  };

  const skipValidation = (name: keyof typeof initialFormState) => {
    if (options?.doNotValidate) {
      return options?.doNotValidate.includes(name);
    }
    return false;
  }

  const validate = (fieldValues: FormStateInterface) => {
    let temp: any = { ...errors };

    if ("email" in fieldValues && !skipValidation("email")) {
      temp.emailError = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.emailError = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid";
    }

    if ("comments" in fieldValues && !skipValidation("comments"))
      temp.commentError = fieldValues.comments ? "" : "This field is required.";

    if ("name" in fieldValues && !skipValidation("name"))
      temp.nameError = fieldValues.name ? "" : "This field is required";

    if ("storeLocation" in fieldValues && !skipValidation("storeLocation"))
      temp.storeLocationError = fieldValues.storeLocation ? "" : "This field is required";

    setErrors({
      ...temp,
    });
  };
  const normalizeInput = (value: string, previousValue: string): string => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleSubmit = (
    e: FormEvent,
    setIsOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>,
    setIsMessageSuccess: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    e.preventDefault();


    if (formIsValid()) {
      try {
        const data = {
          fromName: state.name,
          email: state.email,
          orderNumber: state.orderNumber,
          message: state.comments,
          phoneNumber: phone,
          storeLocation: state.storeLocation
        };


        ContactService.sendHelpEmail(data);
        PixelService.pixelContact();
        setIsOpenSnackbar(true);
        setIsMessageSuccess(true);
      } catch (e) {
        setIsOpenSnackbar(true);
        setIsMessageSuccess(false);
        Sentry.captureException(e);
      }
    }
    clearForm();
  };

  const clearForm = () => {
    setState(initialFormState);
    setPhone("");
  };

  const formIsValid: any = () => {

    const isValid = Object.values(errors).every((x) => x === "");

    return isValid;
  };

  return {
    errors,
    state,
    phone,
    handleSubmit,
    handleChange,
    formIsValid,
  };
};
