import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";

interface WalmartState {
  isWalmartPath: boolean;
}

const initialState: WalmartState = {
  isWalmartPath: false,
};

const walmartSlice = createSlice({
  name: "walmart",
  initialState,
  reducers: {
    setIsWalmart(state, action: PayloadAction<boolean>) {
      state.isWalmartPath = action.payload;
    },
  },
});

export const selectIsWalmartPath = (state: RootState) =>
  state.walmart.isWalmartPath;

export const { setIsWalmart } = walmartSlice.actions;
export default walmartSlice.reducer;
