import React from "react";
import Box from "@mui/material/Box";

interface Props {
  symbol: string;
  onClick: () => void;
}
const ItemButton = ({ symbol, onClick }: Props) => (
  <Box
    onClick={onClick}
    component="span"
    width={{ xs: "25px", md: "40px" }}
    height={{ xs: "25px", md: "40px" }}
    sx={{
      cursor: "pointer",
      border: "2px solid black",
      borderRadius: "20px",
      textAlign: "center",
      fontFamily: "ABC Favorit",
      fontSize: { xs: "18px", md: "22px" },
      lineHeight: { xs: "22px", md: "36px" },
      color: "black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {symbol}
  </Box>
);
export default ItemButton;
