import { ProductInterface } from "../pages/cart/interfaces/product.interface";

export function trackViewedProduct(product: Omit<ProductInterface, 'quantity' | 'lineId' | 'merchId' | 'polishType' | 'key'>) {
    let klaviyo = window.klaviyo || [];
    let item = {
        name: product.value,
        productID: product.productId,
        imageURL: product.img,
        price: product.price,
    };
    klaviyo.push(['track', 'Hydrogen Viewed Product', item]);
    klaviyo.push(['trackViewedItem', item]);


}

export function trackAddedToCart(product: ProductInterface) {
    let klaviyo = window.klaviyo || []
    let item = {
        name: product.value,
        productID: product.productId,
        imageURL: product.img,
        price: product.price,
        quantity: product.quantity,
    };
    klaviyo.push(['track', 'Hydrogen Added To Cart', item])
}
