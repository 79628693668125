import React, { FormEvent } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonLoadingAnimation from "./button-loading-animation";

type ButtonStyle = "productPageBtn" | "collectionPageBtn" | "gridBtn";
interface AddToCartBtnProps {
  createOrAddToCart: (e: FormEvent) => void;
  polishPrice: number;
  btnStyle: ButtonStyle;
  isLoading: boolean;
}
const AddToCartButton = ({
  polishPrice,
  createOrAddToCart,
  btnStyle,
  isLoading,
}: AddToCartBtnProps) => {
  const getBtnMaxWidth = () => {
    switch (btnStyle) {
      case "gridBtn":
        return { xs: "167px", sm: "200px", md: "220px" };
      case "productPageBtn":
        return { xs: "100%" };
      case "collectionPageBtn":
        return { xs: "260px", sm: "350px" };
    }
  };
  const getPadding = () => {
    switch (btnStyle) {
      case "gridBtn":
        return { xs: "7px", sm: "10.5px" };
      case "productPageBtn":
        return "10.5px";
      case "collectionPageBtn":
        return { xs: "10px", sm: "12px" };
    }
  };
  return (
    <Box m="0 auto" width="100%" maxWidth={getBtnMaxWidth()}>
      <Button
        onClick={createOrAddToCart}
        fullWidth
        variant="contained"
        aria-label={btnStyle === "productPageBtn" ?
          "Add to cart"
          :
          `Add to cart - $${polishPrice}`
        }
        sx={{
          py: getPadding(),
          fontSize: { xs: ".9em", sm: "20px" },
          backgroundColor: isLoading ? "white" : "black",
          borderRadius:
            btnStyle === "gridBtn" || btnStyle === "collectionPageBtn"
              ? "35px"
              : "40px",
        }}
      >
        {isLoading ? (
          <ButtonLoadingAnimation isLoading={isLoading} />
        ) : btnStyle === "productPageBtn" ? (
          "Add to cart"
        ) : (
          `Add to cart - $${polishPrice}`
        )}
      </Button>
    </Box>
  );
};

export default AddToCartButton;
