import React from "react";
import { Box, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import BlankFooter from "../../components/blank-footer/blank-footer";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import { useGetOpenEndorseCollection } from "../shop-all/hooks/useGetOpenEndorseCollection";
import CustomerAddition from "./components/customer-addition";
import OpenEndorseBuyAndSave from "./components/o-e-buy-and-save";
import OpenEndorseGrid from "./components/o-e-grid";
import OpenEndorseHero from "./components/o-e-hero";
import OpenEndorseProductsTitle from "./components/o-e-title";
import PageNotFound from "../../routing/page-not-found";
import { usePixelServiceOnLoad } from "../../services/pixels/usePixelService";

interface Props {
  isWalmart?: boolean;
}
const OpenEndorse = ({ isWalmart = false }: Props) => {
  const { endorser } = useParams();
  usePixelServiceOnLoad();

  const { openEndorseData, product, isLoading, isError, isUninitialized } =
    useGetOpenEndorseCollection(endorser!);

  const openEndorseText: string[] = openEndorseData.collectionDescription
    ? openEndorseData.collectionDescription.split("|")
    : [];
  const getEndorseStrings = (idx: number) => {
    return openEndorseText[idx] ? openEndorseText[idx].trim() : "";
  };
  const listItems: string[] = openEndorseText[4]
    ? openEndorseText[4].split(",")
    : [];

  if (isError) return <PageNotFound />;

  const successDisplay = (
    <Box width="100%" sx={{ backgroundColor: "secondary.main" }}>
      <ScrollToTopOnMount />
      <BlankAppbar color="transparent" isWalmartComponent={isWalmart} />
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="hidden"
      >
        <OpenEndorseHero
          backgroundColor={getEndorseStrings(8)}
          title={getEndorseStrings(0)}
          quote={getEndorseStrings(1)}
          images={
            openEndorseData
              ? [
                  openEndorseData.collectionImage,
                  openEndorseData.collectionMobileImg!,
                ]
              : ["", ""]
          }
        />
        {!isLoading || !isUninitialized ? (
          <Box
            width="100%"
            sx={{
              backgroundColor: "secondary.main",
            }}
          >
            <OpenEndorseProductsTitle
              title={getEndorseStrings(2)}
              subTitle={getEndorseStrings(3)}
              listItems={listItems}
            />
            <OpenEndorseGrid
              products={
                openEndorseData ? openEndorseData.collectionProducts : []
              }
            />
            <OpenEndorseBuyAndSave
              product={product}
              products={
                openEndorseData ? openEndorseData.collectionProducts : []
              }
              buyAndSaveText={getEndorseStrings(5)}
            />
            <CustomerAddition
              title={getEndorseStrings(6)}
              subtitle={getEndorseStrings(7)}
            />
          </Box>
        ) : (
          <Skeleton width="100%" height="100vh" variant="rectangular" />
        )}
      </Box>
      <BlankFooter />
    </Box>
  );

  return successDisplay;
};
export default OpenEndorse;
