import React from "react";
import Box from "@mui/material/Box";
import ColorNamingInput from "./color-name-input.component";
import Typography from "@mui/material/Typography";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { IconButton } from "@mui/material";
import { useProductNamerInput } from "../hooks/useProductNamerInput";
import { useInternalCanvasTestHook } from "../../../utils/hooks/useInternalCanvasTestHook";
interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}


const ProductNamer = ({ handleChange, name }: Props) => {
  const isInternalTest = useInternalCanvasTestHook()
  const { showInput, handleClick } = useProductNamerInput(name);
  return (
    <Box width="100%" mt={1.5}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="start"
      >
        {showInput ? (
          <ColorNamingInput
            handleClick={handleClick}
            handleChange={handleChange}
          />
        ) : (
          <Box display="flex" justifyContent="start" alignItems="center">
            <Box>
              <IconButton aria-label="Edit product name" onClick={handleClick} sx={{ pl: 0 }}>
                <ModeEditOutlinedIcon htmlColor="black" />
              </IconButton>
            </Box>
            <Box
              onClick={handleClick}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography fontSize={16} variant="h4">
                  {`Tap to name your ${isInternalTest ? 'shade' : 'polish'} ${isInternalTest ? '' :'💅' }`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ProductNamer;
