import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

const ContactInfo  = ({title,email,subHeader}:{title:string,subHeader?:string,email:string}) => {
  return (
    <>
      <Typography textAlign={'center'}  variant="h3" mb={2} mt={5}>
        {title}
      </Typography>
      {subHeader && <Typography textAlign={'center'} variant="h6" mb={2}>
        {subHeader}
      </Typography>}
      
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center", md: "baseline" }}
      >
        <Typography variant="body2">{email}</Typography>
      </Box>
    </>
  );
};
export default ContactInfo;
