import React, { ChangeEvent, useContext, useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useLocation } from "react-router-dom";
import { COLOR_PICKER, CREATE_YOUR_COLOR } from "../../../routing/CONSTANTS";
import { useAppDispatch } from "../../../utils/hooks/redux-hooks";
import { resetDisplayColor } from "../state/color-picker-slice";
import { ImageContext } from "../state/image.context";
import "../color-picker.styles.css";
import { useKioskHook } from "../../../utils/hooks/useKioskHook";
import { useInternalCanvasTestHook } from "../../../utils/hooks/useInternalCanvasTestHook";
import { CANVAS_KIOSK_V1, CANVAS_V1 } from "../../../routing/CONSTANTS";


interface LocationState {
  camera: string;
}

const ColorPickerSnackBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const { camera } = location.state || { camera: false };

  const hiddenCameraInput = useRef<HTMLInputElement>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { fileUpload } = useContext(ImageContext);
  const isKiosk = useKioskHook()
  const isInternalCanvasTest = useInternalCanvasTestHook()

  const handleHomeNavigation = () => {

    let navPath = CREATE_YOUR_COLOR

    if (isKiosk) {
      navPath = `${navPath}?isKiosk=1`

    }
    navigate(navPath);
  };
  const handleCameraAction = () => {
    if (hiddenCameraInput.current != null) {
      hiddenCameraInput.current.click();
    }

    dispatch(resetDisplayColor());
  };

  const handleInputAction = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
    dispatch(resetDisplayColor());
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    fileUpload(e);

    const internalTestUrl = isKiosk ? CANVAS_KIOSK_V1 : CANVAS_V1

    let navPath = isInternalCanvasTest ? internalTestUrl : COLOR_PICKER
    if (isKiosk && !isInternalCanvasTest) {
      navPath = `${navPath}?isKiosk=1`

    }

    navigate(navPath, {
      state: { camera: camera },
    });
  };

  return (
    <Box bgcolor="secondary.main" width="100%" m="0 auto" px={{ xs: 1, sm: 4 }}>
      <Box
        py={{ xs: 1.25, md: 2 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <IconButton aria-label="Close Modal" onClick={handleHomeNavigation}>
            <CloseIcon htmlColor="black" />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          pr={1}
          onClick={camera ? handleCameraAction : handleInputAction}
          sx={{
            cursor: "pointer",
          }}
          className="disable-text-selection"
        >
          <ArrowBackIosIcon sx={{ fontSize: 15 }} />
          <Typography variant="body1" sx={{ ml: 0.5, fontWeight: 600 }}>
            {camera ? "retake photo" : "repick photo"}
          </Typography>{" "}
        </Box>
        {camera ? (
          <input
            accept="image/*"
            onChange={uploadFile}
            ref={hiddenCameraInput}
            type="file"
            capture="environment"
            style={{ display: "none" }}
          />
        ) : (
          <input
            accept="image/*"
            onChange={uploadFile}
            ref={hiddenFileInput}
            type="file"
            style={{ display: "none" }}
          />
        )}
      </Box>
    </Box>
  );
};
export default ColorPickerSnackBar;
