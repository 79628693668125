import { useEffect } from "react";

const useExternalScripts = ({ url }: { url: string }) => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", url);
    if (head) head.appendChild(script);

    return () => {
      if (head) head.removeChild(script);
    };
  }, [url]);
};
export default useExternalScripts;
