import React, { useEffect } from "react";
import { Box } from "@mui/material";

interface VideoProps {
  video: string;
  autoPlay: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
  id?: string;
  onEnded?: React.ReactEventHandler<HTMLVideoElement> | undefined;
  poster: string;
  height: { xs?: string; sm?: string; md?: string; xl?: string };
  width: { xs?: string; sm?: string; md?: string; xl?: string };
}
export const AutoPlayVideo = ({
  video,
  autoPlay,
  id,
  videoRef,
  onEnded,
  poster,
  width,
  height,
}: VideoProps) => {
  useEffect(() => {
    const current = videoRef.current;
    if (current) current.defaultMuted = true;
  }, [videoRef]);

  const handleCanPlay = () => {
    if (autoPlay) {
      const current = videoRef.current;
      if (current) {
        if (current.readyState === 4) {
          current.play();
        }
      }
    }
  };

  return (
    <Box
      id={id}
      className="auto-play-video"
      component="video"
      width={width}
      height={height}
      sx={{
        position: "relative",
        left: 0,
        top: 0,
        opacity: 1,
        objectFit: "cover",
        zIndex: 6,
      }}
      borderRadius=".5rem"
      ref={videoRef}
      loop={autoPlay}
      autoPlay={autoPlay}
      onEnded={onEnded}
      onLoadedData={handleCanPlay}
      muted
      playsInline
      preload="auto"
      src={video}
    />
  );
};
