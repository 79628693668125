import React, { useState } from "react";
import DesktopFilterBar from "./desktop-filter-bar";
import MobileFilterBar from "./mobile-filter-bar";

interface FilterBarProps {
  colorCount: number;
  tags: string[];
  title: string;
}

const FilterBar = ({ colorCount, tags, title }: FilterBarProps) => {
  const [collectionFilterExpanded, setCollectionFilterExpanded] =
    useState<boolean>(false);
  const [shadeFilterExpanded, setShadeFilterExpanded] =
    useState<boolean>(false);
  const handleShadeFilterBarToggle = () => {
    setShadeFilterExpanded(!shadeFilterExpanded);
    if (shadeFilterExpanded) setCollectionFilterExpanded(false);
  };
  const handleCollectionFilterBarToggle = () => {
    setCollectionFilterExpanded(!collectionFilterExpanded);
    if (collectionFilterExpanded) setShadeFilterExpanded(false);
  };
  return (
    <>
      <DesktopFilterBar
        colorCount={colorCount}
        tags={tags}
        title={title}
        collectionFilterExpanded={collectionFilterExpanded}
        setCollectionFilterExpanded={setCollectionFilterExpanded}
        shadeFilterExpanded={shadeFilterExpanded}
        handleCollectionFilterBarToggle={handleCollectionFilterBarToggle}
        handleShadeFilterBarToggle={handleShadeFilterBarToggle}
      />
      <MobileFilterBar
        colorCount={colorCount}
        tags={tags}
        title={title}
        collectionFilterExpanded={collectionFilterExpanded}
        setCollectionFilterExpanded={setCollectionFilterExpanded}
        shadeFilterExpanded={shadeFilterExpanded}
        handleCollectionFilterBarToggle={handleCollectionFilterBarToggle}
        handleShadeFilterBarToggle={handleShadeFilterBarToggle}
      />
    </>
  );
};
export default FilterBar;
