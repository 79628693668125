import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { UpSellInterface } from "../../interfaces/up-sell-interface";
import UpSellBottleBackground from "../../../../components/product-backgrounds/upsell-bottle-background";
import bottleRender from "../../../../assets/blankBottleRender.webp";
import bottleRenderPng from "../../../../assets/blankBottleRender.png";
import cuticleOilBottleRenderPng from "../../../../assets/cuticleOilBottleRender.png";
import cuticleOilBottleRender from "../../../../assets/cuticleOilBottleRender.webp";
import ImgWithPngFallback from "../../../../components/img-with-png-fallback";

interface Props {
  item: UpSellInterface;
  cartUpSells: UpSellInterface[];
  addUpSellToCart: (item: UpSellInterface) => void;
}
const UpSellItemMobile = ({ item, cartUpSells, addUpSellToCart }: Props) => {
  const itemFind = cartUpSells.find((product) => item.title === product.title);
  const isInUpSellArr = itemFind !== undefined;
  return (
    <Box
      width={{
        xs: isInUpSellArr ? "50%" : "0px",
        sm: isInUpSellArr ? "45%" : "0px",
      }}
      height={isInUpSellArr ? "100%" : "0px"}
      mb={{
        xs: cartUpSells.length > 2 ? "50px" : "15px",
        sm: cartUpSells.length > 2 ? "60px" : "20px",
      }}
      mt={{ sm: "20px" }}
      sx={{
        opacity: isInUpSellArr ? 1 : 0,
        transition: "opacity .5s ease-in",
        "&:last-child": {
          mb: {
            xs: cartUpSells.length > 2 ? "20px" : "10px",
            sm: "20px",
          },
        },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        mt={{ xs: "25px", sm: "30px" }}
      >
        <Box
          width="100%"
          maxWidth={{ xs: "80px", sm: "110px" }}
          m={{ xs: "30px 0 40px", sm: "30px 0 65px" }}
          position="relative"
        >
          <Box
            position="absolute"
            top={{ xs: "-50px", sm: "-55px" }}
            left="0"
            width="100%"
          >
            <Box component="figure">
              <UpSellBottleBackground title={item.title} />
            </Box>
            <Box
              position="absolute"
              top={{ xs: "-32px", sm: "-45px" }}
              left={{ xs: "10px", sm: "15px" }}
              width={{ xs: "75%" }}
              sx={{ zIndex: 10 }}
            >
              <Box component="figure">
                <ImgWithPngFallback
                  alt={'upsell item'}

                  src={item.title.toLowerCase().includes('cuticle') ? cuticleOilBottleRender : bottleRender}
                  fallback={item.title.toLowerCase().includes('cuticle') ? cuticleOilBottleRenderPng : bottleRenderPng}
                  width="100%"
                  height="100%"
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          p={{ xs: "0 20%", sm: "2px 0" }}
          width="100%"
          display="flex"
          justifyContent="center"
          sx={{
            flexFlow: "row nowrap",
            justifyContent: "center",
            opacity: isInUpSellArr ? 1 : 0,
            transition: "all .1s ease-in",
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            letterSpacing={0}
            fontSize={isInUpSellArr ? "16px" : "0px"}
            lineHeight="16px"
          >
            {item.title}
          </Typography>
        </Box>
      </Box>

      <Box
        m="8px auto 0 auto"
        width={isInUpSellArr ? "100%" : "0px"}
        maxWidth="113px"
        overflow="hidden"
        px={0.5}
      >
        <Button
          onClick={() => addUpSellToCart(item)}
          fullWidth
          sx={{
            lineHeight: "16.25px",
            pt: "4.88px",
            pb: "4.88px",
            backgroundColor: "white",
            color: "black",
            opacity: isInUpSellArr ? 1 : 0,
            transition: "all .1s ease-in",
          }}
        >
          add - $9
        </Button>
      </Box>
    </Box>
  );
};
export default UpSellItemMobile;
