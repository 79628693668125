import { Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import headImage from "../../assets/receiptHead.png";
import headSetImage from "../../assets/Headset.svg";
import receiptLogo from "../../assets/ReceiptLogo.png";
import SEO from "../../components/seo";
import { CONTACT, ROOT } from "../../routing/CONSTANTS";
import { useState, useEffect } from "react";
import moment from 'moment';


export const ReceiptPageContainer = () => {


  const [dateTime, setDateTime] = useState<string>()
  const [selectedLocation, setSelectedLocation] = useState<string>()

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const storeLocationMap: { key: "franklin" | "collierville", location: string }[] = [
    {
      key: 'franklin', location: "Franklin, Tennessee",
    },
    {
      key: 'collierville', location: "Collierville, Tennessee",
    }
  ]


  const content = [
    { label: "Time", value: dateTime },
    { label: "Branch Name", value: selectedLocation },

  ];


  useEffect(() => {


    setDateTime(moment().format('hh:mma [on] MMMM Do'))

    // Get Location
    if (queryParams.get('storeLocation')) {
      const matchingLocation = storeLocationMap.find((singleLocation) => singleLocation.key === queryParams.get('storeLocation'))

      if (matchingLocation) {
        setSelectedLocation(matchingLocation.location)

      }

    }

  }, [])




  return (
    <Box>
      <SEO
        title="Create Your Custom Nail Polish Color | Blank Beauty"
        description="Custom Nail Polish. Long-lasting, chip-resistant, high gloss finish. Easy to apply and full coverage that lasts for days. Made to order in the USA."
        type="website"
        urlEndpoint="create-your-color"
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100dvh"
        overflow="auto"
        sx={{ backgroundColor: "#E7E7E7" }}
      >
        <Box
          sx={{ backgroundColor: "white" }}
          overflow="hidden"
          display="flex"
          flexDirection="column"
          border="1px solid #eee"
          width="min(440px, 100vw)"
          borderRadius="24px"
          maxHeight="100dvh"
          position="relative"
        >
          <Link to={ROOT} style={{
            position: "absolute",
            top: "16px",
            right: "16px",
          }}>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_b_207_554)">
                <rect
                  width="40"
                  height="40"
                  rx="20"
                  fill="white"
                  fill-opacity="0.8"
                />
                <path
                  d="M27.5459 25.954C27.7572 26.1654 27.876 26.452 27.876 26.7509C27.876 27.0498 27.7572 27.3364 27.5459 27.5478C27.3346 27.7591 27.0479 27.8779 26.749 27.8779C26.4501 27.8779 26.1635 27.7591 25.9521 27.5478L20 21.5937L14.0459 27.5459C13.8346 27.7573 13.5479 27.876 13.249 27.876C12.9501 27.876 12.6635 27.7573 12.4521 27.5459C12.2408 27.3346 12.1221 27.0479 12.1221 26.749C12.1221 26.4502 12.2408 26.1635 12.4521 25.9522L18.4062 20L12.454 14.0459C12.2427 13.8346 12.1239 13.5479 12.1239 13.249C12.1239 12.9502 12.2427 12.6635 12.454 12.4522C12.6654 12.2408 12.952 12.1221 13.2509 12.1221C13.5498 12.1221 13.8364 12.2408 14.0478 12.4522L20 18.4062L25.954 12.4512C26.1654 12.2399 26.452 12.1212 26.7509 12.1212C27.0498 12.1212 27.3364 12.2399 27.5478 12.4512C27.7591 12.6626 27.8778 12.9492 27.8778 13.2481C27.8778 13.547 27.7591 13.8336 27.5478 14.045L21.5937 20L27.5459 25.954Z"
                  fill="black"
                />
                <path
                  d="M27.5459 25.954C27.7572 26.1654 27.876 26.452 27.876 26.7509C27.876 27.0498 27.7572 27.3364 27.5459 27.5478C27.3346 27.7591 27.0479 27.8779 26.749 27.8779C26.4501 27.8779 26.1635 27.7591 25.9521 27.5478L20 21.5937L14.0459 27.5459C13.8346 27.7573 13.5479 27.876 13.249 27.876C12.9501 27.876 12.6635 27.7573 12.4521 27.5459C12.2408 27.3346 12.1221 27.0479 12.1221 26.749C12.1221 26.4502 12.2408 26.1635 12.4521 25.9522L18.4062 20L12.454 14.0459C12.2427 13.8346 12.1239 13.5479 12.1239 13.249C12.1239 12.9502 12.2427 12.6635 12.454 12.4522C12.6654 12.2408 12.952 12.1221 13.2509 12.1221C13.5498 12.1221 13.8364 12.2408 14.0478 12.4522L20 18.4062L25.954 12.4512C26.1654 12.2399 26.452 12.1212 26.7509 12.1212C27.0498 12.1212 27.3364 12.2399 27.5478 12.4512C27.7591 12.6626 27.8778 12.9492 27.8778 13.2481C27.8778 13.547 27.7591 13.8336 27.5478 14.045L21.5937 20L27.5459 25.954Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M27.5459 25.954C27.7572 26.1654 27.876 26.452 27.876 26.7509C27.876 27.0498 27.7572 27.3364 27.5459 27.5478C27.3346 27.7591 27.0479 27.8779 26.749 27.8779C26.4501 27.8779 26.1635 27.7591 25.9521 27.5478L20 21.5937L14.0459 27.5459C13.8346 27.7573 13.5479 27.876 13.249 27.876C12.9501 27.876 12.6635 27.7573 12.4521 27.5459C12.2408 27.3346 12.1221 27.0479 12.1221 26.749C12.1221 26.4502 12.2408 26.1635 12.4521 25.9522L18.4062 20L12.454 14.0459C12.2427 13.8346 12.1239 13.5479 12.1239 13.249C12.1239 12.9502 12.2427 12.6635 12.454 12.4522C12.6654 12.2408 12.952 12.1221 13.2509 12.1221C13.5498 12.1221 13.8364 12.2408 14.0478 12.4522L20 18.4062L25.954 12.4512C26.1654 12.2399 26.452 12.1212 26.7509 12.1212C27.0498 12.1212 27.3364 12.2399 27.5478 12.4512C27.7591 12.6626 27.8778 12.9492 27.8778 13.2481C27.8778 13.547 27.7591 13.8336 27.5478 14.045L21.5937 20L27.5459 25.954Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_207_554"
                  x="-8"
                  y="-8"
                  width="56"
                  height="56"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_207_554"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_207_554"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </Link>
          <Box
            sx={{
              backgroundColor: "#aaa",
              backgroundImage: `url(${headImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "280px",
            }}
            height="280px"
          />
          <Box flex="1" position="relative" sx={{ isolation: "isolate" }}>
            <Box p="40px">
              <Box
                component="h1"
                fontSize="32px"
                fontStyle="italic"
                lineHeight="120%"
                fontFamily="Nib Pro"
                textAlign="center"
              >
                Receipt
              </Box>
              <Box
                m="32px 0"
                height="1px"
                sx={{ backgroundColor: "rgb(0,0,0)", opacity: "0.2" }}
              />
              <Box
                display="flex"
                flexDirection="column"
                rowGap="26px"
                component="ul"
              >
                {content.map((item) => (
                  <Box
                    key={item.label}
                    component="li"
                    display="flex"
                    columnGap="16px"
                    justifyContent="space-between"
                  >
                    <Box
                      component="span"
                      fontSize="18px"
                      fontWeight="350"
                      lineHeight="150%"
                      letterSpacing="-0.36px"
                      color="#737373"
                    >
                      {item.label}
                    </Box>
                    <Box
                      textAlign="right"
                      component="span"
                      fontSize="18px"
                      fontFamily="Arial"
                      fontWeight="500"
                      lineHeight="150%"
                      letterSpacing="-0.36px"
                      color="#000"
                    >
                      {item.value}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '2rem',
              alignItems: 'center',

              rowGap: '1.5rem'
            }}>
              <Link

                to={CONTACT}
                target="_blank"
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '2px solid black',
                  color: 'black',
                  width: '250px',
                  borderRadius: '25px',
                  columnGap: '1rem'
                }}>
                <Box component='img' src={headSetImage} />
                <Box sx={{

                  fontSize: '1rem',
                }} component="p">
                  Need help, contact us
                </Box>
              </Link>
              <Box component="p" sx={{ color: 'rgba(163, 163, 163, 1)', textAlign: 'center' }}>
                Please screenshot this receipt for your reference
              </Box>
            </Box>
            <Box
              overflow="hidden"
              position="absolute"
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              left="0"
              top="0"
              width="100%"
              height="100%"
              zIndex="-1"
            >
              <svg
                width="100%"
                height="311"
                viewBox="0 0 364 311"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M323.777 40.0579C332.357 83.3505 252.82 108.817 270.872 133.69C288.924 158.562 340.624 136.866 394.881 186.049C449.132 235.222 412.487 305.922 360.289 310.187C294.975 315.518 304.95 241.193 279.244 226.621C246.419 208.024 181.537 255.609 153.525 218.747C119.164 173.538 228.371 122.102 202.654 98.789C180.324 78.5458 162.485 135.211 108.806 159.793C57.662 183.227 11.8644 145.135 2.58449 101.627C-10.0233 42.5029 41.8809 -11.503 114.546 11.065C152.691 22.9092 181.017 46.0515 214.168 25.2683C260.17 -3.57345 312.738 -15.6694 323.777 40.0579Z"
                  fill="#0071DC"
                  fill-opacity="0.06"
                />
              </svg>
            </Box>
          </Box>

          <Box src={receiptLogo} component="img" />
        </Box>
      </Box>
    </Box>
  );
};
