import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import ProductCardContainer from "../../../../components/product-card/product-card-container";
import { useCheckMobileScreen } from "../../../../utils/hooks/useCheckMobileScreen";
import { BlankColorInterface } from "../../../color_picker/interface/blank-color-interface";
import ColorLibrarySkeletonGrid from "./skeletons/color-library-skeleton-grid";

interface Props {
  displayColor: string;
  filteredLibraryColors: BlankColorInterface[];
  isLoading: boolean;
}
const ColorLibraryGrid = ({ filteredLibraryColors, isLoading }: Props) => {
  const { isMobile } = useCheckMobileScreen();
  return (
    <Box
      px={{ xs: 1, md: 3 }}
      margin="auto"
      pt={{ md: 8 }}
      bgcolor="secondary.main"
    >
      <Box>
        {isLoading ? (
          <ColorLibrarySkeletonGrid isMobile={isMobile} />
        ) : (
          <Grid
            container
            justifyContent="center"
            spacing={{ xs: 1, md: 3 }}
            rowSpacing={{ xs: 5, md: 10 }}
          >
            {filteredLibraryColors.map((color, idx) => (
              <Grid key={color.id} item xs={6} sm={4} md={3}>
                <ProductCardContainer
                  productType="custom"
                  customProduct={color}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};
export default ColorLibraryGrid;
