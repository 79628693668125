import React from "react";
import Box from "@mui/material/Box";
import MakeItYoursBottle from "./make-it-yours-bottle";
import MakeItYoursInfoBox from "./make-it-yours-info-box";

const MakeItYoursDataDesktop = () => (
  <Box
    display={{ xs: "none", md: "flex" }}
    justifyContent="center"
    alignItems="center"
  >
    <MakeItYoursInfoBox
      title="Any color"
      text="Create a custom range of nail polish colors - we have 10000 shades available."
    />
    <MakeItYoursBottle />
    <MakeItYoursInfoBox
      title="Your label"
      text="Provide your design of label and we will stick it on each bottle of the nail polish."
    />
  </Box>
);

export default MakeItYoursDataDesktop;
