import React from "react";
import { Box, Button, Typography } from "@mui/material";
// import Header from "./components/header";
import HowItWorksInfoGraphics from "./components/how-it-works-graphics";
import GetTheBestResults from "./components/get-the-best-results";
import { useNavigate } from "react-router-dom";
import { CREATE_YOUR_COLOR } from "../../../routing/CONSTANTS";

interface Props {
  isWalmartComponent?: boolean;
}

const HowItWorks = ({ isWalmartComponent }: Props) => {
  const navigate = useNavigate();

  const handleNavigateToCreateYourColor = () => {
    navigate(CREATE_YOUR_COLOR);
  };

  const handleNavigateToWalmartCreateYourColor = () => {
    navigate(`/walmart${CREATE_YOUR_COLOR}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: isWalmartComponent ? "#0071DC" : "#B3E400",
        overflow: "auto",
        width: "100%",
        py: 8,
        m: "0 auto",
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ color: isWalmartComponent ? "#FFFFFF" : "inherit" }}
      >
        {/* <MobileHeader /> */}
        <Box
          mb={1}
          width="100%"
          display={{ xs: "flex", sm: "none" }}
          flexDirection="column"
          alignItems="center"
          px={2}
          sx={{ color: isWalmartComponent ? "#FFFFFF" : "inherit" }}
        >
          <Typography
            textAlign="center"
            variant="h3"
            fontSize="38px"
            lineHeight="38px"
            style={{
              color: isWalmartComponent ? "#FFFFFF !important" : "inherit",
            }}
          >
            Pick a color.
          </Typography>
          <Typography
            textAlign="center"
            variant="h3"
            fontSize="38px"
            lineHeight="38px"
          >
            Any color.
          </Typography>
        </Box>
        <Box mb={{ sm: 3, md: 1 }} display={{ xs: "none", sm: "block" }}>
          <Typography
            textAlign="center"
            variant="h3"
            fontSize="38px"
            lineHeight="38px"
          >
            Pick a color. Any color.
          </Typography>
        </Box>

        {/* <DesktopHeader /> */}
        {!isWalmartComponent && (
          <Box>
            <Typography
              textAlign="center"
              variant="h4"
              fontSize={{ xs: "20px", sm: "24px" }}
              lineHeight={{ xs: "27px", sm: "32px" }}
              px={{ xs: 5, sm: 0 }}
            >
              Create a custom shade from any photo!
            </Typography>
          </Box>
        )}
        {isWalmartComponent && (
          <>
            <Box>
              <Typography
                textAlign="center"
                px={{ xs: 5, sm: 0 }}
                sx={{ fontStyle: "italic" }}
              >
                In eligible Walmart locations and online.
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <HowItWorksInfoGraphics isWalmartComponent={isWalmartComponent} />
      <GetTheBestResults isWalmartComponent={isWalmartComponent} />
      <Box
        mt={{ xs: 3, sm: 5 }}
        width="100%"
        display="flex"
        justifyContent="center"
      >
        <Box width="265px">
          <Button
            fullWidth
            onClick={
              isWalmartComponent
                ? handleNavigateToWalmartCreateYourColor
                : handleNavigateToCreateYourColor
            }
            variant="contained"
            sx={{
              pt: { xs: "9.5px", sm: "10.5px" },
              pb: { xs: "9.5px", sm: "10.5px" },
              fontSize: { xs: "16px", sm: "20px" },
              backgroundColor: isWalmartComponent ? "white" : "black",
              color: isWalmartComponent ? "black" : "white",
              "&:hover": {
                backgroundColor: isWalmartComponent ? "black" : "white",
                color: isWalmartComponent ? "white" : "black",
              },
            }}
          >
            {isWalmartComponent
              ? "Create your color+"
              : " create yours now - $9"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default HowItWorks;
