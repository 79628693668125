import { useEffect } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../utils/hooks/redux-hooks";
import { RootState } from "../../../../store";
import { fetchLibraryColors } from "../state/library-colors-actions";
import {
  selectColorLibraryByName,
  selectStatusByName,
  setNewTag,
} from "../../color-library-color-page/state/library-colors-slice";
import { useDisplayColor } from "../../../../utils/hooks/useDisplayColor";

export const useGetBlankLibraryColorsQuery = (
  collection: string | undefined
) => {
  const dispatch = useAppDispatch();
  const { displayColor, getColorHex } = useDisplayColor();

  const data = useAppSelector((state: RootState) =>
    selectColorLibraryByName(state, collection!)
  );

  const currentTag = useAppSelector(
    (state: RootState) => state.libraryColors.currentTag
  );
  const fTag: string =
    currentTag.toUpperCase() === "GREY"
      ? "GRAYSCALE"
      : currentTag.toUpperCase();
  const filteredData =
    data &&
    data.blankColors.filter(
      (color) =>
        color.mainTag === fTag ||
        color.minorTag === fTag ||
        color.colorGroup === fTag
    );

  const status = useAppSelector((state: RootState) =>
    selectStatusByName(state, collection!)
  );

  const isUninitialized = status === undefined;
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  useEffect(() => {
    if (collection !== undefined) {
      dispatch(fetchLibraryColors(collection));
      dispatch(setNewTag(collection));
      getColorHex(collection);
    }
  }, [collection, dispatch, getColorHex]);

  return {
    data,
    filteredData,
    currentTag,
    displayColor,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
};
