import React from "react";
import Box from "@mui/material/Box";
import FilterByCollectionFilter from "./filter-by-collection-filter";
import FilterByShadeFilter from "./filter-by-shade-filter";

interface DesktopFilterProps {
  colorCount: number;
  tags: string[];
  title: string;
  collectionFilterExpanded: boolean;
  setCollectionFilterExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  shadeFilterExpanded: boolean;
  handleShadeFilterBarToggle: () => void;
  handleCollectionFilterBarToggle: () => void;
}

const DesktopFilterBar = ({
  colorCount,
  tags,
  title,
  collectionFilterExpanded,
  setCollectionFilterExpanded,
  shadeFilterExpanded,
  handleCollectionFilterBarToggle,
  handleShadeFilterBarToggle,
}: DesktopFilterProps) => {
  return (
    <Box
      px={3}
      py={6}
      width="100%"
      display={{ xs: "none", sm: "block" }}
      bgcolor="secondary.main"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FilterByCollectionFilter
          isMobile={false}
          expanded={collectionFilterExpanded}
          setExpanded={setCollectionFilterExpanded}
          handleFilterBarToggle={handleCollectionFilterBarToggle}
        />
        <FilterByShadeFilter
          isMobile={false}
          colorCount={colorCount}
          tags={tags}
          title={title}
          expanded={shadeFilterExpanded}
          handleFilterBarToggle={handleShadeFilterBarToggle}
        />
      </Box>
    </Box>
  );
};
export default DesktopFilterBar;
