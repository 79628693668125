import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
  isWalmartComponent?: boolean;
}

const ExpandedResults = ({ isWalmartComponent }: Props) => {
  const title1 = "When taking a picture:";
  const text1 = "Make sure you're taking the picture in natural lighting";
  const title2 = "When sampling a color:";
  const text2 = "Make sure your screen's brightness is set to a maximum";
  const text2b =
    "Make sure any color filters (e.g. night-time settings) are turned off";
  return (
    <Box
      sx={{
        pl: { xs: 2 },
        pb: 2,
        display: "flex",
        maxWidth: { xs: "260px", sm: "100%" },
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography
          variant="body2"
          fontSize={{ xs: "13px", sm: "16px" }}
          lineHeight={{ xs: "18px", sm: "22px" }}
          color={isWalmartComponent ? "white" : "black"}
        >
          {title1}
        </Typography>
      </Box>
      <Box mb={1.5}>
        <Typography
          variant="body1"
          fontSize={{ xs: "13px", sm: "16px" }}
          lineHeight={{ xs: "18px", sm: "22px" }}
          color={isWalmartComponent ? "white" : "black"}
        >
          {text1}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          fontSize={{ xs: "13px", sm: "16px" }}
          lineHeight={{ xs: "18px", sm: "22px" }}
          color={isWalmartComponent ? "white" : "black"}
        >
          {title2}
        </Typography>
      </Box>
      <Box mb={1.5}>
        <Typography
          fontSize={{ xs: "13px", sm: "16px" }}
          lineHeight={{ xs: "18px", sm: "22px" }}
          color={isWalmartComponent ? "white" : "black"}
        >
          {text2}
        </Typography>
      </Box>
      <Box>
        <Typography
          fontSize={{ xs: "13px", sm: "16px" }}
          lineHeight={{ xs: "18px", sm: "22px" }}
          color={isWalmartComponent ? "white" : "black"}
        >
          {text2b}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(ExpandedResults);
