import React from "react";
import Box from "@mui/material/Box";

const AnimatedCloudButton = () => (
  <Box
    width={{ xs: "55px", md: "64px", lg: "75px", xl: "80px" }}
    height={{ xs: "55px", md: "64px", lg: "75px", xl: "80px" }}
    position="absolute"
    bottom="0"
    left="0"
    sx={{
      zIndex: 2,
      transition: "all 0.5s ease-in-out",
      "&:hover": {
        transform: "scale(1.1) rotate(180deg)",
      },
    }}
  >
    <svg viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.6394 67.2788C52.218 67.2788 67.2788 52.2179 67.2788 33.6394C67.2788 15.0609 52.218 0 33.6394 0C15.0609 0 0 15.0609 0 33.6394C0 52.2179 15.0609 67.2788 33.6394 67.2788Z"
        fill="#F45557"
      />
      <path
        d="M66.2788 33.6394C66.2788 51.6657 51.6657 66.2788 33.6394 66.2788C15.6132 66.2788 1 51.6657 1 33.6394C1 15.6132 15.6132 1 33.6394 1C51.6657 1 66.2788 15.6132 66.2788 33.6394Z"
        fill="#C6EA00"
        stroke="black"
        strokeWidth="2"
      />

      <path d="M27 33.4346L39.3132 33.4346" stroke="black" strokeWidth="3" />
      <line
        x1="32.948"
        y1="39.5911"
        x2="32.948"
        y2="27.2778"
        stroke="black"
        strokeWidth="3"
      />
    </svg>
  </Box>
);
export default AnimatedCloudButton;
