import { graphqlClientRequest } from "../../../services/graphQl/graphQl-common";
import { FETCH_PRODUCT_BY_HANDLE_QUERY } from "../../../services/graphQl/queries/fetch-product-by-handle";
import {
  CollectionProductInterface,
  VariantInterface,
} from "../../shop-all/interfaces/collection-interfaces";

const mapTags = (item: any): string[] => {
  const productTags: string[] = [];
  item.forEach((tag: string) => {
    productTags.push(tag);
  });
  return productTags;
};

const mapVariants = (item: any): VariantInterface[] => {
  const prodVariants: VariantInterface[] = [];
  item.forEach((variant: any) => {
    const prodTitle =
      variant.node.title === "Default Title"
        ? variant.node.price.amount === "11.0"
          ? "Gel"
          : "Lacquer"
        : variant.node.title;
    const newVariant: VariantInterface = {
      variantId: variant.node.id,
      price: variant.node.price.amount,
      title: prodTitle,
    };
    prodVariants.push(newVariant);
  });
  return prodVariants;
};

const fetchTrendingShades = async (handle: string) => {
  const data = {
    query: FETCH_PRODUCT_BY_HANDLE_QUERY,
    variables: {
      handle: handle,
    },
  };
  const res = await graphqlClientRequest.then((res) => res.post(data));

  if (res.data.errors) {
    throw res.data.errors[0].message;
  }
  const product = res.data.data.product;
  const productId = product.id;
  const productVariants = mapVariants(product.variants.edges);
  const productTags = mapTags(product.tags);
  const seo = {
    title: product.seo.title,
    description: product.seo.description,
  };

  const newProduct: CollectionProductInterface = {
    productId,
    merchId: product.id,
    title: product.title,
    seoTitle: seo["title"],
    seoDescription: seo["description"],
    handle: product.handle,
    description: product.description,
    image: product.metafield.value,
    cursor: "",
    variants: productVariants,
    isRandomImg: false,
    tags: productTags,
  };

  return newProduct;
};

const LandingService = {
  fetchTrendingShades,
};

export default LandingService;
