import React from "react";
import Button from "@mui/material/Button";

interface Props {
  handleCloseModal: () => void;
}
const CloseModalButton = ({ handleCloseModal }: Props) => (
  <Button
    onClick={handleCloseModal}
    variant="text"
    sx={{
      border: "none",
      color: "black",
      backgroundColor: "white",
      fontFamily: "ABC Favorit Book",
      textDecoration: "underline",
      fontSize: { xs: "15px", sm: "18px" },
    }}
  >
    close
  </Button>
);

export default CloseModalButton;
