import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const GuaranteeText = ({ setIsVisible }: Props) => {
  const guaranteeDescText =
    "We're pretty darn confident with our color matching tech. So much so that if your shade really & truly isn't right, then we'll refund you 100% of the product cost if you return it to us! ";

  const handleOpenModal = () => {
    setIsVisible(true);
  };
  return (
    <Box
      width="100%"
      maxWidth="405px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={{ xs: "center", sm: "flex-start" }}
      ml={{ xs: 0, sm: 2 }}
    >
      <Box maxWidth="330px" mb={2}>
        <Typography
          textAlign={{ xs: "center", sm: "left" }}
          variant="h4"
          fontSize={{ xs: "20px", sm: "24px" }}
          lineHeight={{ xs: "27px", sm: "32px" }}
        >
          100% color match guarantee
        </Typography>
      </Box>
      <Box width="100%" maxWidth={{ xs: "300px", sm: "405px" }}>
        <Typography
          textAlign="left"
          fontSize={{ xs: "14px", sm: "16px" }}
          lineHeight="20px"
        >
          {guaranteeDescText}
          <Box
            onClick={handleOpenModal}
            component="span"
            fontSize={{ xs: "14px", sm: "16px" }}
            lineHeight="20px"
            fontFamily="ABC Favorit Book"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Tell me more
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};
export default GuaranteeText;
