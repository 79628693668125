import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

interface Props {
  error?: boolean;
  helperText?: string | false;
  placeholder: string;
  required: boolean;
  label: string;
  name: string;
  value: string;
  multiline: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const InquiriesTextField = ({
  error,
  helperText,
  placeholder,
  required,
  label,
  name,
  value,
  multiline,
  handleChange,
}: Props) => (
  <Box mb={3.5}>
    <TextField
      inputProps={{
        style: {
          padding: "14.5px 14px",
        },
      }}
      required={required}
      fullWidth
      variant="outlined"
      multiline={multiline}
      rows={multiline ? 4 : 1}
      placeholder={placeholder}
      label={label}
      name={name}
      value={value}
      error={error}
      helperText={helperText ? helperText : false}
      onChange={handleChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "black",
          borderWidth: "2px",
        },
      }}
      InputProps={{
        style: {
          color: value.includes("Enter") ? "#7C7C7C" : "black",
          fontFamily: "ABC Favorit Light",
          fontStyle: value.includes("Enter") ? "italic" : "normal",
          fontSize: "15px",
          lineHeight: "22px",
          padding: "5px 0px",
        },
      }}
      InputLabelProps={{ shrink: true }}
    />
  </Box>
);
export default InquiriesTextField;
