import React from "react";
import { CollectionInterface } from "../../../shop-all/interfaces/collection-interfaces";
import FiveItemColOne from "./five-item-col-one";
import FiveItemColThree from "./five-item-col-three";
import FiveItemColTwo from "./five-item-col-two";
import SixItemColOne from "./six-item-col-one";
import SixItemColTwo from "./six-item-col-two";

type Props = {
  collection: CollectionInterface;
};

const CollectionStack = ({ collection }: Props) => {
  const sixItemArray = [
    <SixItemColOne collection={collection} />,
    <SixItemColTwo collection={collection} />,
  ];
  const fiveItemArray = [
    <FiveItemColOne collection={collection} />,
    <FiveItemColTwo collection={collection} />,
    <FiveItemColThree collection={collection} />,
  ];

  const randomSixStack =
    sixItemArray[Math.floor(Math.random() * sixItemArray.length)];
  const randomFiveStack =
    fiveItemArray[Math.floor(Math.random() * fiveItemArray.length)];

  const getStack = () => {
    if (collection.collectionProducts.length === 6) {
      return randomSixStack;
    } else {
      return randomFiveStack;
    }
  };

  return getStack();
};

export default React.memo(CollectionStack);
