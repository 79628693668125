import { createAsyncThunk } from "@reduxjs/toolkit";
import { ColorLibraryColorsInterface } from "../interfaces/color-library-colors-interface";
import ColorLibraryColorsService from "../services/color-library-color-service";
import * as Sentry from "@sentry/react";
import { RequestState } from "../../../../services/types/requestState.type";

export const fetchLibraryColors = createAsyncThunk<
  ColorLibraryColorsInterface,
  string,
  {
    state: {
      libraryColors: { statusByName: Record<string, RequestState | undefined> };
    };
  }
>(
  "library/listColors",
  async (collection: string, { rejectWithValue }) => {
    try {
      const response = await ColorLibraryColorsService.getLibraryColors(
        collection
      );

      return response;
    } catch (err) {
      Sentry.captureException(err);
      return rejectWithValue(err);
    }
  },
  {
    condition: (collection, { getState }) => {
      const { libraryColors } = getState();
      const fetchStatus = libraryColors.statusByName[collection];
      if (fetchStatus === "fulfilled" || fetchStatus === "pending") {
        return false;
      }
    },
  }
);
