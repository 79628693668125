import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestState } from "../../../services/types/requestState.type";
import { RootState } from "../../../store";
import { UpSellInterface } from "../interfaces/up-sell-interface";
import { fetchUpSells } from "../state/upSell-actions";

interface UpSellState {
  upSellItems: UpSellInterface[];
  isUpSellBarShown: boolean;
  status: RequestState | undefined;
}

const initialState: UpSellState = {
  upSellItems: [],
  isUpSellBarShown: false,
  status: undefined,
};

const upSellSlice = createSlice({
  name: "upSells",
  initialState,
  reducers: {
    setIsUpSellBarShown(state, action: PayloadAction<boolean>) {
      state.isUpSellBarShown = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUpSells.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchUpSells.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.upSellItems = action.payload;
      })
      .addCase(fetchUpSells.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

export const selectIsUpsellBarShown = (state: RootState) =>
  state.upSells.isUpSellBarShown;

export const { setIsUpSellBarShown } = upSellSlice.actions;
export default upSellSlice.reducer;
