import React, { useEffect, useState } from "react";
import ShopPolicyInterface from "./interfaces/shop-policy.interface";
import { Box, Typography } from "@mui/material";
import PolicyService from "./services/policy-service";
import { useParams } from "react-router-dom";
import BlankAppbar from "../../components/blank-appbar/blank-appbar";
import BlankFooter from "../../components/blank-footer/blank-footer";
import PixelService from "../../services/pixels/pixel-service";
import { ScrollToTopOnMount } from "../../routing/scroll-to-top";
import SEO from "../../components/seo";
import "./shop-policies.css";

interface Props {
  isWalmart?: boolean;
}
const initialPolicy: ShopPolicyInterface = { title: "", body: "" };

const ShopPolicies = ({ isWalmart = false }: Props) => {
  const [shopPolicy, setShopPolicy] =
    useState<ShopPolicyInterface>(initialPolicy);
  const { policy } = useParams();

  const createMarkup = () => {
    return {
      __html: shopPolicy.body,
    };
  };

  useEffect(() => {
    PolicyService.fetchShopPolicy(policy!).then((res) => setShopPolicy(res));
    PixelService.pixelPageView(`/policies/${policy}`);
  }, [policy]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <SEO
        title="Terms and policies | Blank Beauty"
        urlEndpoint={`/policies/${policy}`}
        description="Long-lasting, chip-resistant nail polish colors by Blank Beauty. 13-free and full coverage that lasts. Want something unique? Create your own nail polish."
        type="website"
      />
      <BlankAppbar color="white" isWalmartComponent={isWalmart} />
      <ScrollToTopOnMount />
      <Box mt={15} mb={3} width="100%" maxWidth="1400px">
        <Typography
          variant="h1"
          textAlign="center"
          fontSize={{ xs: "32px", sm: "52px" }}
        >
          {shopPolicy.title}
        </Typography>
      </Box>
      <Box
        className="policy-markup"
        width={{ xs: "100%", sm: "65%" }}
        px={{ xs: 3, sm: 2 }}
        mb={4}
      >
        <Typography dangerouslySetInnerHTML={createMarkup()}></Typography>
      </Box>
      <BlankFooter />
    </Box>
  );
};
export default ShopPolicies;
