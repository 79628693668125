import React from "react";

import { SocialsInterface } from "../interfaces/socials.interface";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "../../icons/facebook-icon";
import TikTokIcon from "../../icons/tik-tok-icon";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

export const socialsData: SocialsInterface[] = [
  {
    id: 1,
    icon: <InstagramIcon htmlColor="black" />,
    link: "https://www.instagram.com/_blankbeauty/",
    name: "instagram",
  },
  {
    id: 2,
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/theblankbeauty",
    name: "facebook",
  },
  {
    id: 3,
    icon: <TwitterIcon htmlColor="black" fontSize="medium" />,
    link: "https://twitter.com/_blankbeauty",
    name: "twitter",
  },
  {
    id: 4,
    icon: <YouTubeIcon htmlColor="black" fontSize="large" />,
    link: "https://www.youtube.com/channel/UCaWrEvs0ErvylHrP2Ed4uxQ",
    name: "youtube",
  },
  {
    id: 5,
    icon: <TikTokIcon />,
    link: "https://www.tiktok.com/@_blankbeauty",
    name: "tiktok",
  },
];
