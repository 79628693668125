import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const ProductCardSkeleton = () => (
  <Box
    sx={{
      width: "100%",
      px: { xs: 0.25, md: "10%" },
      py: { xs: 3, md: "10%" },
      mb: { xs: 2, md: 0 },

      textAlign: "center",
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
      width="100%"
    >
      <Box
        width={{ xs: "150px", sm: "210px", md: "240px" }}
        height={{ xs: "150px", sm: "210px", md: "240px" }}
        left="0"
        bottom="0"
        textAlign="center"
        sx={{ zIndex: 1 }}
      >
        <Skeleton
          animation="wave"
          variant="circular"
          width="100%"
          height="100%"
        />
      </Box>
    </Box>

    <Box
      textAlign="center"
      mb={0.5}
      height="40px"
      width={{ xs: "163px", sm: "100px", md: "140px" }}
    >
      <Skeleton
        height="100%"
        width="100%"
        animation="wave"
        variant="text"
        sx={{ fontSize: "1rem" }}
      />
    </Box>
    <Box textAlign="center" mb={1} height="40px" width="40px">
      <Skeleton
        height="100%"
        width="100%"
        animation="wave"
        variant="text"
        sx={{ fontSize: "1rem" }}
      />
    </Box>
    <Box>
      <Skeleton
        height="100%"
        width="100%"
        animation="wave"
        variant="rectangular"
      />
    </Box>
  </Box>
);

export default ProductCardSkeleton;
