import React from "react";
import Box from "@mui/material/Box";
import EndorserPhone from "./endorse-phone";

const ColorStack = () => (
  <Box
    height={{ xs: "450px", sm: "694px" }}
    width={{ xs: "575px", sm: "902px" }}
    sx={{
      transform: {
        xs: "translateX(-150px)",
        sm: "translateX(-70px)",
        md: "unset",
      },
    }}
    position="relative"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 902 694"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2560_11952)">
        <path
          d="M258.896 676.203C264.694 671.257 267.531 664.126 267.237 657.064C269.26 656.157 271.198 654.956 272.973 653.436C282.84 645.018 284.135 630.283 275.996 620.268C280.786 611.795 280.057 600.889 273.376 593.052C270.112 589.231 265.896 586.696 261.384 585.494C260.911 580.844 259.067 576.286 255.803 572.456C249.114 564.62 238.456 562.186 229.332 565.589C220.69 556.217 206.117 555.271 196.335 563.62C194.529 565.162 193.009 566.922 191.777 568.821C184.901 567.488 177.499 569.185 171.763 574.084C166.027 578.975 163.19 585.998 163.415 592.99C161.376 593.897 159.43 595.106 157.64 596.633C147.865 604.974 146.501 619.493 154.369 629.5C149.516 637.988 150.214 648.956 156.927 656.824C160.19 660.645 164.407 663.18 168.918 664.382C169.391 669.032 171.236 673.59 174.499 677.42C181.212 685.28 191.924 687.706 201.071 684.249C209.683 693.837 224.418 694.868 234.278 686.45C236.03 684.954 237.502 683.256 238.711 681.427C245.633 682.815 253.106 681.14 258.896 676.195V676.203Z"
          fill="#F8E983"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M401.94 641.36C401.343 645.243 396.847 648.072 395.553 651.623C394.22 655.289 395.824 660.335 393.848 663.591C391.832 666.901 386.592 667.792 384.003 670.614C381.391 673.459 380.942 678.745 377.841 681.016C374.74 683.288 369.563 682.125 366.067 683.753C362.594 685.373 360.168 690.093 356.401 691.016C352.703 691.923 348.37 688.861 344.471 689.031C340.689 689.186 336.635 692.62 332.759 692.024C328.876 691.427 326.046 686.931 322.496 685.636C318.83 684.303 313.784 685.908 310.528 683.931C307.218 681.916 306.327 676.676 303.505 674.087C300.661 671.474 295.374 671.025 293.103 667.924C290.832 664.823 291.994 659.645 290.367 656.15C288.739 652.654 284.026 650.251 283.104 646.483C282.197 642.786 285.259 638.453 285.088 634.554C284.933 630.771 281.499 626.717 282.096 622.841C282.693 618.966 287.189 616.129 288.483 612.578C289.816 608.912 288.212 603.866 290.188 600.61C292.204 597.3 297.444 596.409 300.033 593.587C302.645 590.742 303.094 585.456 306.195 583.185C309.296 580.913 314.473 582.076 317.969 580.448C321.442 578.828 323.868 574.108 327.635 573.185C331.333 572.278 335.666 575.34 339.565 575.17C343.347 575.015 347.401 571.581 351.277 572.178C355.16 572.774 357.99 577.27 361.54 578.565C365.206 579.898 370.252 578.293 373.508 580.27C376.818 582.285 377.709 587.525 380.531 590.114C383.352 592.703 388.662 593.176 390.933 596.277C393.204 599.378 392.042 604.556 393.669 608.051C395.289 611.524 400.01 613.95 400.932 617.718C401.839 621.415 398.777 625.748 398.948 629.647C399.103 633.43 402.537 637.484 401.94 641.36Z"
          fill="#EF7D42"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M942.389 159.999C943.63 163.727 940.924 168.293 941.405 172.052C941.893 175.92 945.637 179.68 945.358 183.478C945.079 187.346 940.831 190.532 939.816 194.222C938.793 197.942 940.808 202.857 939.087 206.291C937.366 209.732 932.227 211.058 929.863 214.112C927.514 217.135 927.514 222.453 924.584 224.995C921.709 227.491 916.461 226.755 913.066 228.677C909.779 230.545 907.741 235.452 904.012 236.692C900.284 237.932 895.718 235.227 891.959 235.708C888.091 236.196 884.331 239.94 880.533 239.661C876.665 239.382 873.48 235.134 869.79 234.119C866.069 233.096 861.155 235.111 857.721 233.39C854.279 231.669 852.954 226.53 849.9 224.166C846.877 221.817 841.559 221.817 839.017 218.887C836.521 216.011 837.257 210.763 835.335 207.368C833.467 204.082 828.56 202.043 827.32 198.314C826.08 194.586 828.785 190.02 828.305 186.261C827.816 182.393 824.072 178.633 824.351 174.835C824.63 170.967 828.878 167.781 829.894 164.092C830.917 160.371 828.901 155.456 830.622 152.023C832.343 148.581 837.482 147.255 839.846 144.201C842.195 141.178 842.195 135.861 845.125 133.318C848.055 130.776 853.248 131.559 856.644 129.636C859.93 127.768 861.969 122.861 865.697 121.621C869.426 120.381 873.991 123.086 877.751 122.606C881.619 122.117 885.378 118.373 889.176 118.652C893.044 118.931 896.23 123.179 899.92 124.195C903.64 125.218 908.555 123.202 911.988 124.923C915.43 126.644 916.756 131.783 919.81 134.148C922.864 136.512 928.15 136.496 930.693 139.426C933.188 142.302 932.452 147.55 934.374 150.945C936.243 154.232 941.149 156.27 942.389 159.999Z"
          fill="#B3E400"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M847.21 203.291L845.071 195.408L845.986 194.935L847.869 201.896L852.07 199.733L852.318 200.656L847.21 203.283V203.291Z"
          fill="black"
        />
        <path
          d="M854.807 199.508C854.528 199.648 854.241 199.726 853.954 199.733C853.659 199.733 853.388 199.671 853.132 199.524C852.876 199.377 852.675 199.152 852.52 198.857C852.349 198.532 852.287 198.191 852.326 197.842C852.365 197.493 852.504 197.152 852.752 196.834C853 196.516 853.334 196.253 853.768 196.036L855.326 195.222L855.264 195.005C855.186 194.726 855.062 194.524 854.876 194.4C854.69 194.276 854.481 194.214 854.249 194.222C854.016 194.222 853.791 194.292 853.574 194.4L853.512 194.431C853.218 194.586 852.993 194.796 852.845 195.059C852.698 195.323 852.659 195.594 852.737 195.873L851.877 196.315C851.714 195.826 851.753 195.338 852.001 194.842C852.249 194.346 852.659 193.951 853.241 193.648C853.621 193.455 854.008 193.354 854.403 193.354C854.799 193.354 855.155 193.478 855.465 193.726C855.775 193.974 856.008 194.361 856.148 194.889L856.713 196.997C856.783 197.245 856.837 197.447 856.892 197.609C856.923 197.718 856.946 197.819 856.977 197.919C857 198.02 857.031 198.129 857.062 198.229L856.248 198.648C856.233 198.578 856.217 198.509 856.202 198.439C856.186 198.369 856.171 198.299 856.14 198.237C856.124 198.167 856.101 198.105 856.086 198.043C856.07 197.981 856.047 197.912 856.031 197.85C855.969 198.253 855.83 198.586 855.62 198.857C855.403 199.129 855.132 199.353 854.814 199.516L854.807 199.508ZM854.667 198.656C854.9 198.54 855.101 198.361 855.279 198.113C855.458 197.873 855.582 197.602 855.644 197.292C855.713 196.989 855.698 196.687 855.613 196.392L855.512 195.997L854.14 196.702C853.737 196.904 853.466 197.16 853.311 197.47C853.155 197.772 853.155 198.074 853.311 198.377C853.427 198.609 853.605 198.749 853.83 198.819C854.055 198.881 854.295 198.85 854.551 198.718L854.651 198.664L854.667 198.656Z"
          fill="black"
        />
        <path
          d="M858.209 197.632L857.938 196.656L860.015 190.989L860.132 191.16L856.954 192.795L856.729 191.974L860.667 189.951L860.93 190.92L858.853 196.609L858.721 196.431L862.108 194.687L862.333 195.509L858.202 197.632H858.209Z"
          fill="black"
        />
        <path
          d="M865.852 193.834C865.426 194.051 865 194.152 864.573 194.129C864.147 194.106 863.752 193.966 863.387 193.71C863.023 193.455 862.721 193.082 862.473 192.602C862.186 192.044 862.046 191.486 862.054 190.92C862.054 190.354 862.201 189.835 862.481 189.37C862.76 188.904 863.147 188.548 863.651 188.284C864.031 188.091 864.411 187.99 864.783 187.997C865.162 187.997 865.511 188.114 865.837 188.331C866.162 188.548 866.426 188.866 866.635 189.292C866.682 189.377 866.728 189.501 866.775 189.641C866.821 189.788 866.868 189.935 866.907 190.098L863.209 191.997C863.356 192.338 863.558 192.625 863.806 192.842C864.054 193.059 864.325 193.191 864.62 193.237C864.914 193.284 865.209 193.238 865.488 193.082L865.589 193.028C865.907 192.865 866.139 192.648 866.286 192.385C866.434 192.121 866.488 191.827 866.449 191.509L867.31 191.067C867.418 191.625 867.356 192.152 867.116 192.633C866.876 193.113 866.449 193.517 865.845 193.827L865.852 193.834ZM865.814 189.788C865.659 189.354 865.418 189.067 865.085 188.935C864.752 188.804 864.387 188.842 863.984 189.044L863.891 189.09C863.511 189.284 863.24 189.579 863.085 189.959C862.922 190.346 862.883 190.773 862.977 191.238L865.821 189.78L865.814 189.788Z"
          fill="black"
        />
        <path
          d="M868.961 192.113L867.426 186.486L868.286 186.044L868.581 187.145C868.581 186.711 868.666 186.331 868.852 186.021C869.03 185.703 869.325 185.447 869.72 185.238L870.108 185.036L870.371 185.967L869.999 186.16C869.55 186.393 869.247 186.742 869.077 187.207C868.906 187.672 868.914 188.261 869.1 188.974L869.829 191.664L868.968 192.106L868.961 192.113Z"
          fill="black"
        />
        <path
          d="M874.929 189.044L873.394 183.416L874.255 182.974L874.48 183.757C874.534 183.393 874.666 183.075 874.867 182.796C875.069 182.517 875.34 182.292 875.665 182.122C876.007 181.951 876.348 181.881 876.696 181.92C877.045 181.959 877.363 182.114 877.642 182.377C877.921 182.641 878.13 183.021 878.27 183.517L879.177 186.858L878.316 187.3L877.417 183.951C877.332 183.649 877.208 183.393 877.053 183.199C876.898 183.005 876.712 182.889 876.495 182.843C876.278 182.796 876.061 182.843 875.828 182.951L875.728 183.005C875.479 183.137 875.278 183.323 875.123 183.563C874.968 183.811 874.883 184.114 874.859 184.47C874.836 184.827 874.883 185.23 874.991 185.664L875.789 188.594L874.929 189.036V189.044Z"
          fill="black"
        />
        <path
          d="M883.13 184.951C882.704 185.168 882.277 185.269 881.851 185.246C881.425 185.222 881.03 185.083 880.665 184.827C880.301 184.571 879.999 184.199 879.75 183.719C879.464 183.16 879.324 182.602 879.332 182.036C879.332 181.471 879.479 180.951 879.758 180.486C880.037 180.021 880.425 179.665 880.929 179.401C881.309 179.207 881.688 179.106 882.06 179.114C882.44 179.114 882.789 179.23 883.115 179.447C883.44 179.665 883.711 179.982 883.913 180.409C883.959 180.502 884.006 180.618 884.053 180.758C884.099 180.905 884.146 181.052 884.184 181.215L880.487 183.114C880.634 183.455 880.836 183.742 881.084 183.959C881.332 184.176 881.603 184.308 881.898 184.354C882.192 184.401 882.487 184.354 882.766 184.199L882.867 184.145C883.184 183.982 883.417 183.765 883.564 183.502C883.711 183.238 883.766 182.943 883.727 182.626L884.587 182.184C884.696 182.742 884.626 183.261 884.394 183.75C884.153 184.23 883.727 184.633 883.122 184.943L883.13 184.951ZM883.091 180.905C882.936 180.471 882.696 180.184 882.363 180.052C882.037 179.92 881.665 179.959 881.262 180.161L881.169 180.207C880.789 180.401 880.518 180.695 880.363 181.075C880.2 181.463 880.169 181.889 880.254 182.362L883.099 180.905H883.091Z"
          fill="black"
        />
        <path
          d="M888.711 182.083C888.254 182.323 887.804 182.432 887.378 182.408C886.952 182.385 886.556 182.246 886.207 181.99C885.859 181.726 885.564 181.37 885.324 180.912C885.045 180.362 884.897 179.804 884.897 179.223C884.897 178.641 885.029 178.122 885.3 177.657C885.572 177.184 885.959 176.827 886.463 176.564C886.921 176.331 887.37 176.215 887.796 176.238C888.223 176.254 888.618 176.393 888.975 176.657C889.331 176.913 889.626 177.277 889.866 177.734C890.153 178.292 890.292 178.858 890.285 179.432C890.285 180.005 890.137 180.533 889.866 180.998C889.595 181.471 889.207 181.835 888.711 182.083ZM888.424 181.308C888.703 181.168 888.928 180.936 889.106 180.626C889.285 180.316 889.37 179.943 889.37 179.525C889.37 179.106 889.246 178.664 889.013 178.207C888.858 177.905 888.657 177.657 888.424 177.471C888.192 177.285 887.936 177.176 887.665 177.137C887.393 177.106 887.114 177.153 886.851 177.292L886.75 177.347C886.463 177.494 886.238 177.727 886.068 178.037C885.897 178.354 885.812 178.719 885.812 179.145C885.812 179.564 885.928 179.998 886.161 180.447C886.316 180.75 886.51 180.998 886.742 181.184C886.975 181.37 887.223 181.478 887.502 181.517C887.781 181.556 888.052 181.502 888.324 181.362L888.424 181.308Z"
          fill="black"
        />
        <path
          d="M892.044 180.246L890.509 174.618L891.37 174.176L891.595 174.959C891.649 174.603 891.781 174.277 891.982 173.998C892.191 173.719 892.455 173.494 892.781 173.324C893.122 173.153 893.463 173.083 893.811 173.122C894.16 173.161 894.478 173.316 894.757 173.58C895.036 173.843 895.246 174.223 895.385 174.719L896.292 178.06L895.432 178.502L894.532 175.153C894.447 174.851 894.323 174.603 894.168 174.401C894.013 174.207 893.827 174.091 893.61 174.045C893.393 173.998 893.176 174.045 892.943 174.153L892.843 174.207C892.595 174.339 892.393 174.525 892.238 174.766C892.083 175.014 891.998 175.316 891.974 175.672C891.951 176.029 891.998 176.432 892.106 176.866L892.905 179.796L892.044 180.238V180.246Z"
          fill="black"
        />
        <path
          d="M901.881 175.184L900.997 171.944L897.052 168.688L898.044 168.177L901.152 170.75L902.199 166.045L903.338 165.464L901.919 171.494L902.795 174.727L901.881 175.2V175.184Z"
          fill="black"
        />
        <path
          d="M960.008 87.3284C958.38 90.9019 953.288 92.4057 951.079 95.4753C948.807 98.6379 948.978 103.94 946.195 106.537C943.358 109.18 938.072 108.622 934.816 110.629C931.53 112.66 929.662 117.629 926.065 118.97C922.46 120.319 917.794 117.792 913.988 118.412C910.206 119.032 906.586 122.916 902.718 122.784C898.912 122.652 895.571 118.536 891.773 117.637C888.091 116.769 883.262 118.97 879.688 117.342C876.115 115.714 874.611 110.622 871.542 108.413C868.379 106.141 863.077 106.312 860.48 103.529C857.837 100.692 858.395 95.4055 856.388 92.1499C854.357 88.8632 849.388 86.9951 848.047 83.3984C846.699 79.794 849.226 75.1276 848.605 71.3216C847.985 67.5388 844.102 63.9189 844.234 60.0509C844.365 56.2449 848.481 52.904 849.381 49.1058C850.249 45.4238 848.047 40.5946 849.675 37.0212C851.303 33.4477 856.396 31.9439 858.605 28.8743C860.814 25.8047 860.705 20.4097 863.488 17.8129C866.325 15.1697 871.612 15.7278 874.867 13.7201C878.154 11.6892 880.022 6.72053 883.618 5.37952C887.223 4.03075 891.889 6.55775 895.695 5.93762C899.478 5.3175 903.098 1.434 906.965 1.56578C910.771 1.69755 914.112 5.8136 917.91 6.71278C921.592 7.58094 926.422 5.37952 929.995 7.00733C933.568 8.63515 935.072 13.7279 938.142 15.9371C941.304 18.2083 946.606 18.0377 949.203 20.8205C951.846 23.6576 951.288 28.9441 953.295 32.1997C955.326 35.4864 960.295 37.3545 961.636 40.9512C962.985 44.5556 960.458 49.222 961.078 53.028C961.698 56.8108 965.581 60.4307 965.45 64.2987C965.318 68.1047 961.202 71.4456 960.303 75.2438C959.435 78.9258 961.636 83.755 960.008 87.3284Z"
          fill="#F01400"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M875.014 57.9968C874.371 57.8185 873.844 57.5162 873.425 57.0976C873.015 56.679 872.743 56.1829 872.619 55.6093C872.495 55.0357 872.518 54.4388 872.689 53.8187C872.929 52.9583 873.325 52.2142 873.859 51.6095C874.402 50.9972 875.045 50.5786 875.789 50.346C876.534 50.1135 877.332 50.1135 878.169 50.346C878.72 50.5011 879.185 50.7569 879.564 51.1134C879.944 51.47 880.208 51.8808 880.355 52.3537C880.502 52.8265 880.502 53.2994 880.371 53.7877L879.378 53.5087C879.487 52.9505 879.402 52.47 879.107 52.0669C878.813 51.6715 878.402 51.3925 877.859 51.2297L877.751 51.1987C877.154 51.0282 876.588 51.0437 876.038 51.2452C875.487 51.439 875.007 51.7801 874.603 52.2607C874.193 52.7413 873.906 53.2994 873.728 53.935C873.58 54.4543 873.542 54.9349 873.619 55.369C873.697 55.8031 873.867 56.1674 874.146 56.462C874.425 56.7565 874.79 56.9658 875.231 57.0899L875.309 57.1131C875.72 57.2294 876.131 57.2449 876.549 57.1751C876.968 57.0976 877.363 56.9348 877.727 56.6868C878.099 56.4387 878.425 56.1054 878.72 55.6946L876.332 55.0357L876.758 54.1985L879.014 54.8342C879.34 54.9272 879.541 55.09 879.626 55.3225C879.712 55.5551 879.673 55.8341 879.502 56.1597L878.146 58.7797L877.286 58.5394L877.898 57.3689C877.665 57.5704 877.394 57.741 877.076 57.8805C876.758 58.02 876.425 58.0975 876.061 58.1208C875.696 58.1441 875.348 58.113 874.999 58.0123L875.014 57.9968Z"
          fill="white"
        />
        <path
          d="M882.138 60.0043C881.642 59.8648 881.239 59.64 880.936 59.3377C880.634 59.0354 880.448 58.6633 880.363 58.237C880.285 57.8106 880.316 57.3456 880.456 56.8417C880.619 56.2448 880.898 55.741 881.293 55.3147C881.688 54.8883 882.138 54.6015 882.657 54.4387C883.177 54.276 883.704 54.276 884.246 54.431C884.742 54.5705 885.145 54.7953 885.448 55.0976C885.75 55.4077 885.944 55.772 886.029 56.2061C886.114 56.6402 886.083 57.1053 885.944 57.6014C885.773 58.1982 885.494 58.7098 885.099 59.1284C884.704 59.547 884.246 59.8338 883.727 59.9888C883.208 60.1439 882.673 60.1516 882.138 59.9966V60.0043ZM882.463 59.2369C882.766 59.3222 883.084 59.3067 883.432 59.1982C883.781 59.0896 884.091 58.8804 884.37 58.5703C884.657 58.2602 884.866 57.8572 885.006 57.3688C885.099 57.0355 885.122 56.7254 885.083 56.4309C885.045 56.1363 884.928 55.8805 884.75 55.6635C884.572 55.4542 884.339 55.3069 884.045 55.2216L883.936 55.1906C883.626 55.1054 883.301 55.1209 882.967 55.2294C882.634 55.3379 882.316 55.555 882.029 55.865C881.743 56.1751 881.533 56.5704 881.394 57.0588C881.301 57.3921 881.277 57.7021 881.324 57.9967C881.37 58.2912 881.479 58.547 881.65 58.7563C881.82 58.9734 882.06 59.1207 882.363 59.2059L882.471 59.2369H882.463Z"
          fill="white"
        />
        <path
          d="M885.828 60.9267L888.541 55.7642L889.471 56.0278L886.758 61.1903L885.82 60.9267H885.828ZM888.951 54.8108L889.494 53.7566L890.54 54.0512L889.99 55.1054L888.944 54.8108H888.951Z"
          fill="white"
        />
        <path
          d="M888.44 61.6632L891.153 56.5007L892.083 56.7643L891.711 57.4929C891.998 57.2681 892.308 57.1209 892.656 57.0511C892.998 56.9891 893.346 57.0046 893.695 57.0976C894.059 57.1984 894.362 57.3844 894.587 57.648C894.819 57.9115 894.943 58.2371 894.966 58.6246C894.99 59.0122 894.889 59.4308 894.649 59.8881L893.036 62.9577L892.106 62.6942L893.726 59.6323C893.866 59.3455 893.951 59.082 893.967 58.8339C893.982 58.5859 893.928 58.3766 893.804 58.1983C893.68 58.02 893.486 57.896 893.238 57.8262L893.129 57.7952C892.858 57.7177 892.587 57.7177 892.308 57.7952C892.029 57.8727 891.757 58.0278 891.494 58.2758C891.23 58.5239 890.99 58.8417 890.773 59.2448L889.362 61.9345L888.424 61.671L888.44 61.6632Z"
          fill="white"
        />
        <path
          d="M895.935 66.3684C895.548 66.2599 895.207 66.0971 894.904 65.8801C894.602 65.663 894.385 65.3995 894.246 65.0894C894.106 64.7793 894.067 64.4383 894.129 64.0585L895.067 64.322C895.044 64.6088 895.129 64.8646 895.338 65.0894C895.54 65.3142 895.827 65.4847 896.191 65.5855L896.292 65.6165C896.788 65.756 897.23 65.725 897.617 65.5157C898.005 65.3064 898.331 64.9499 898.594 64.4538L898.943 63.8182C898.687 63.9964 898.393 64.1127 898.067 64.167C897.741 64.2135 897.408 64.198 897.075 64.0972C896.711 63.9964 896.385 63.8182 896.106 63.5546C895.827 63.2988 895.633 62.9655 895.517 62.5469C895.408 62.1283 895.424 61.6477 895.579 61.0974C895.757 60.4618 896.044 59.9347 896.431 59.5161C896.819 59.0975 897.253 58.8107 897.741 58.6634C898.23 58.5084 898.71 58.5006 899.191 58.6402C899.672 58.7719 900.036 59.0045 900.299 59.3223C900.563 59.6401 900.718 59.9812 900.764 60.3532L901.284 59.3533L902.214 59.6168L899.416 64.9266C899.191 65.3607 898.912 65.7173 898.579 65.9808C898.245 66.2521 897.858 66.4227 897.416 66.5002C896.974 66.5777 896.478 66.5312 895.935 66.3762V66.3684ZM897.524 63.3686C897.904 63.4771 898.269 63.4538 898.617 63.3143C898.958 63.167 899.261 62.9422 899.509 62.6244C899.765 62.3066 899.943 61.95 900.051 61.5547C900.152 61.2059 900.175 60.8881 900.121 60.6013C900.067 60.3145 899.958 60.0819 899.78 59.8804C899.602 59.6866 899.377 59.5471 899.106 59.4696L898.997 59.4386C898.687 59.3533 898.369 59.3688 898.036 59.4851C897.703 59.6013 897.4 59.8261 897.129 60.1439C896.858 60.4618 896.656 60.8493 896.524 61.3144C896.377 61.8415 896.393 62.2834 896.571 62.6399C896.749 62.9965 897.036 63.229 897.424 63.3376L897.524 63.3686Z"
          fill="white"
        />
        <path
          d="M434.62 499.135C434.023 503.018 429.527 505.848 428.233 509.398C426.899 513.064 428.504 518.111 426.527 521.366C424.512 524.676 419.272 525.567 416.683 528.389C414.071 531.234 413.621 536.52 410.521 538.792C407.42 541.063 402.242 539.9 398.746 541.528C395.274 543.148 392.848 547.869 389.081 548.791C385.383 549.698 381.05 546.636 377.151 546.807C373.368 546.962 369.314 550.396 365.439 549.799C361.555 549.202 358.726 544.706 355.176 543.411C351.51 542.078 346.463 543.683 343.208 541.706C339.898 539.691 339.007 534.451 336.185 531.862C333.34 529.249 328.054 528.8 325.783 525.699C323.512 522.599 324.674 517.421 323.047 513.925C321.426 510.452 316.706 508.026 315.783 504.259C314.877 500.561 317.938 496.228 317.768 492.329C317.613 488.546 314.179 484.492 314.776 480.617C315.373 476.733 319.868 473.904 321.163 470.353C322.496 466.687 320.892 461.641 322.868 458.385C324.884 455.075 330.124 454.184 332.712 451.362C335.325 448.518 335.774 443.231 338.875 440.96C341.975 438.689 347.153 439.851 350.649 438.224C354.122 436.603 356.548 431.883 360.315 430.96C364.013 430.053 368.346 433.115 372.245 432.945C376.027 432.79 380.081 429.356 383.957 429.953C387.84 430.55 390.67 435.045 394.22 436.34C397.886 437.673 402.932 436.069 406.188 438.045C409.498 440.061 410.389 445.301 413.211 447.89C416.032 450.479 421.342 450.952 423.613 454.052C425.884 457.153 424.721 462.331 426.349 465.827C427.969 469.299 432.69 471.726 433.612 475.493C434.519 479.19 431.457 483.523 431.628 487.422C431.783 491.205 435.217 495.259 434.62 499.135Z"
          fill="#80A694"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M344.169 509.793L341.177 502.189L342.975 500.995C343.719 500.507 344.394 500.243 345.014 500.22C345.634 500.197 346.161 500.329 346.611 500.623C347.053 500.918 347.44 501.305 347.75 501.778C348.177 502.422 348.463 503.135 348.595 503.918C348.735 504.693 348.673 505.445 348.417 506.158C348.161 506.871 347.688 507.46 347.006 507.917L344.169 509.793ZM346.448 507.158C346.991 506.793 347.355 506.344 347.533 505.801C347.711 505.259 347.742 504.685 347.618 504.08C347.494 503.476 347.254 502.902 346.905 502.367C346.487 501.732 345.983 501.367 345.394 501.259C344.805 501.158 344.169 501.329 343.495 501.778L342.378 502.515L344.688 508.32L346.448 507.158Z"
          fill="black"
        />
        <path
          d="M352.649 504.313C352.277 504.553 351.92 504.677 351.572 504.669C351.215 504.662 350.897 504.538 350.61 504.29C350.324 504.049 350.091 503.701 349.913 503.251L348.626 499.995L349.448 499.453L350.75 502.763C350.913 503.181 351.145 503.468 351.44 503.615C351.742 503.755 352.044 503.732 352.347 503.53L352.44 503.468C352.68 503.305 352.858 503.088 352.982 502.809C353.106 502.53 353.153 502.212 353.137 501.856C353.122 501.499 353.037 501.127 352.882 500.739L351.765 497.918L352.572 497.383L354.711 502.809L353.905 503.344L353.579 502.522C353.579 502.91 353.502 503.251 353.354 503.546C353.207 503.84 352.975 504.096 352.657 504.313H352.649Z"
          fill="black"
        />
        <path
          d="M358.354 500.538C357.99 500.778 357.61 500.91 357.207 500.941C356.804 500.972 356.416 500.879 356.044 500.67C355.672 500.46 355.331 500.135 355.044 499.693C354.68 499.15 354.471 498.585 354.401 498.011C354.331 497.437 354.401 496.902 354.61 496.422C354.819 495.941 355.145 495.554 355.579 495.259C355.951 495.011 356.354 494.879 356.773 494.856C357.191 494.833 357.556 494.926 357.873 495.127L356.618 491.972L357.455 491.422L360.431 499.034L359.633 499.561L359.369 498.895C359.369 499.166 359.276 499.46 359.083 499.778C358.889 500.088 358.649 500.344 358.346 500.546L358.354 500.538ZM358.083 499.716C358.416 499.499 358.641 499.205 358.757 498.833C358.873 498.461 358.897 498.065 358.811 497.639C358.734 497.213 358.563 496.809 358.315 496.43C358.137 496.158 357.92 495.957 357.672 495.817C357.424 495.685 357.168 495.623 356.897 495.631C356.625 495.639 356.377 495.724 356.145 495.879L356.052 495.941C355.765 496.127 355.564 496.399 355.447 496.74C355.331 497.081 355.3 497.461 355.362 497.879C355.424 498.298 355.579 498.693 355.835 499.081C356.122 499.515 356.47 499.802 356.866 499.926C357.261 500.05 357.641 499.995 357.997 499.771L358.083 499.716Z"
          fill="black"
        />
        <path
          d="M364.206 496.67C363.803 496.933 363.392 497.081 362.966 497.104C362.54 497.127 362.137 497.034 361.741 496.817C361.354 496.6 361.005 496.267 360.71 495.817C360.362 495.298 360.16 494.755 360.106 494.189C360.044 493.623 360.129 493.096 360.354 492.608C360.579 492.12 360.927 491.717 361.4 491.407C361.757 491.174 362.121 491.034 362.493 490.996C362.873 490.957 363.23 491.027 363.578 491.205C363.927 491.383 364.23 491.678 364.478 492.073C364.532 492.158 364.594 492.267 364.656 492.407C364.718 492.546 364.78 492.693 364.842 492.848L361.377 495.143C361.563 495.468 361.788 495.724 362.059 495.918C362.33 496.112 362.61 496.212 362.912 496.22C363.214 496.236 363.493 496.151 363.757 495.972L363.85 495.91C364.144 495.716 364.354 495.476 364.47 495.189C364.586 494.91 364.609 494.616 364.54 494.298L365.346 493.763C365.516 494.306 365.509 494.833 365.322 495.337C365.136 495.84 364.757 496.282 364.191 496.662L364.206 496.67ZM363.726 492.655C363.524 492.236 363.253 491.98 362.912 491.887C362.571 491.786 362.206 491.864 361.827 492.12L361.741 492.174C361.385 492.407 361.152 492.724 361.028 493.127C360.912 493.53 360.92 493.957 361.059 494.414L363.726 492.647V492.655Z"
          fill="black"
        />
        <path
          d="M370.307 492.492L366.702 488.035L367.501 487.508L370.593 491.523L370.423 491.577L369.694 486.05L370.578 485.461L373.686 489.469L373.5 489.523L372.787 484.004L373.671 483.415L374.337 489.818L373.399 490.438L370.376 486.484L370.562 486.391L371.252 491.864L370.307 492.484V492.492Z"
          fill="black"
        />
        <path
          d="M376.764 488.221L373.764 480.624L374.57 480.089L375.74 483.012C375.756 482.647 375.849 482.314 376.019 482.012C376.19 481.709 376.43 481.461 376.725 481.26C377.043 481.051 377.376 480.942 377.732 480.942C378.089 480.942 378.422 481.058 378.732 481.291C379.042 481.531 379.291 481.88 379.477 482.353L380.748 485.577L379.942 486.112L378.678 482.88C378.562 482.585 378.415 482.345 378.236 482.175C378.058 482.004 377.857 481.903 377.639 481.888C377.422 481.864 377.205 481.926 376.988 482.074L376.903 482.128C376.663 482.291 376.477 482.5 376.353 482.756C376.229 483.019 376.174 483.329 376.182 483.686C376.198 484.043 376.283 484.438 376.446 484.864L377.562 487.686L376.756 488.221H376.764Z"
          fill="black"
        />
        <path
          d="M384.468 483.252C384.065 483.516 383.655 483.663 383.228 483.686C382.802 483.709 382.391 483.616 382.004 483.399C381.616 483.182 381.267 482.849 380.973 482.399C380.632 481.88 380.43 481.337 380.368 480.772C380.306 480.206 380.391 479.679 380.624 479.19C380.849 478.702 381.197 478.299 381.67 477.989C382.027 477.756 382.391 477.617 382.771 477.578C383.143 477.539 383.507 477.609 383.856 477.787C384.205 477.966 384.507 478.26 384.763 478.655C384.817 478.741 384.879 478.849 384.941 478.989C385.003 479.128 385.065 479.276 385.12 479.431L381.655 481.725C381.841 482.051 382.073 482.306 382.337 482.5C382.608 482.694 382.887 482.795 383.189 482.803C383.492 482.818 383.771 482.733 384.042 482.555L384.135 482.492C384.43 482.299 384.639 482.058 384.755 481.772C384.872 481.485 384.895 481.19 384.825 480.88L385.631 480.345C385.802 480.888 385.794 481.415 385.608 481.927C385.422 482.438 385.042 482.872 384.476 483.252H384.468ZM383.988 479.237C383.786 478.826 383.515 478.562 383.174 478.469C382.833 478.369 382.469 478.446 382.089 478.702L382.004 478.756C381.647 478.989 381.414 479.307 381.29 479.71C381.174 480.113 381.182 480.539 381.321 480.996L383.988 479.229V479.237Z"
          fill="black"
        />
        <path
          d="M387.368 481.198L385.228 475.772L386.034 475.237L386.453 476.299C386.406 475.865 386.453 475.485 386.592 475.152C386.74 474.818 386.995 474.532 387.368 474.284L387.732 474.043L388.096 474.942L387.747 475.175C387.329 475.454 387.057 475.834 386.941 476.314C386.825 476.795 386.895 477.384 387.158 478.066L388.181 480.663L387.375 481.198H387.368Z"
          fill="black"
        />
        <path
          d="M393.057 477.57C392.654 477.834 392.243 477.981 391.817 478.004C391.39 478.028 390.98 477.935 390.592 477.717C390.204 477.5 389.856 477.167 389.561 476.718C389.22 476.198 389.018 475.656 388.956 475.09C388.894 474.524 388.98 473.997 389.212 473.508C389.437 473.02 389.786 472.617 390.259 472.307C390.615 472.074 390.98 471.935 391.359 471.896C391.731 471.857 392.096 471.927 392.445 472.105C392.793 472.284 393.096 472.578 393.351 472.974C393.406 473.059 393.468 473.167 393.53 473.307C393.592 473.446 393.654 473.594 393.708 473.749L390.243 476.043C390.429 476.369 390.662 476.625 390.925 476.818C391.197 477.012 391.476 477.113 391.778 477.121C392.08 477.128 392.359 477.051 392.631 476.873L392.724 476.811C393.018 476.617 393.227 476.376 393.344 476.09C393.46 475.811 393.483 475.516 393.414 475.198L394.22 474.663C394.39 475.206 394.382 475.733 394.196 476.237C394.01 476.741 393.631 477.183 393.065 477.562L393.057 477.57ZM392.576 473.555C392.375 473.136 392.104 472.881 391.762 472.788C391.421 472.687 391.057 472.764 390.677 473.02L390.592 473.074C390.235 473.307 390.003 473.625 389.879 474.028C389.763 474.431 389.77 474.857 389.91 475.315L392.576 473.547V473.555Z"
          fill="black"
        />
        <path
          d="M393.522 470.896L393.274 470.276L393.445 470.16C393.669 470.012 393.817 469.842 393.886 469.656C393.956 469.47 393.956 469.268 393.871 469.059L393.801 468.896L393.204 469.292L392.731 468.059L393.855 467.315L394.375 468.656C394.491 468.943 394.545 469.214 394.53 469.47C394.514 469.726 394.445 469.958 394.313 470.175C394.181 470.392 393.987 470.578 393.731 470.749L393.514 470.896H393.522Z"
          fill="black"
        />
        <path
          d="M399.839 473.082C399.258 473.47 398.7 473.64 398.173 473.586C397.646 473.539 397.212 473.284 396.871 472.811L397.7 472.26C397.917 472.532 398.165 472.671 398.46 472.687C398.754 472.702 399.041 472.617 399.343 472.415L399.436 472.353C399.715 472.167 399.901 471.95 399.987 471.702C400.08 471.454 400.049 471.214 399.901 470.989C399.808 470.842 399.669 470.757 399.491 470.718C399.312 470.687 399.111 470.702 398.886 470.772L398.219 470.95C397.77 471.074 397.382 471.098 397.057 471.028C396.723 470.958 396.46 470.772 396.258 470.47C396.08 470.198 395.987 469.896 395.987 469.571C395.987 469.245 396.08 468.919 396.258 468.609C396.437 468.299 396.708 468.02 397.057 467.795C397.56 467.462 398.08 467.315 398.607 467.361C399.134 467.408 399.56 467.671 399.886 468.152L399.08 468.687C398.894 468.431 398.646 468.284 398.351 468.261C398.057 468.237 397.77 468.307 397.498 468.493L397.405 468.555C397.15 468.726 396.987 468.935 396.909 469.175C396.832 469.416 396.863 469.656 397.01 469.873C397.103 470.02 397.235 470.113 397.405 470.144C397.568 470.175 397.77 470.167 398.002 470.105L398.7 469.919C399.118 469.803 399.498 469.772 399.839 469.826C400.18 469.873 400.467 470.082 400.708 470.446C400.894 470.726 400.979 471.028 400.963 471.353C400.948 471.679 400.847 471.989 400.653 472.291C400.459 472.594 400.196 472.857 399.847 473.082H399.839Z"
          fill="black"
        />
        <path
          d="M360.788 510.964L358.649 505.538L359.455 505.003L359.788 505.84C359.788 505.491 359.866 505.158 360.028 504.825C360.191 504.499 360.431 504.22 360.765 504.003C361.106 503.778 361.462 503.677 361.827 503.701C362.191 503.724 362.516 503.863 362.803 504.135C362.796 503.693 362.889 503.274 363.075 502.879C363.261 502.484 363.532 502.174 363.881 501.941C364.214 501.716 364.563 501.608 364.919 501.608C365.276 501.608 365.602 501.724 365.904 501.964C366.206 502.205 366.454 502.553 366.648 503.018L367.919 506.259L367.121 506.786L365.819 503.491C365.656 503.065 365.416 502.778 365.098 502.646C364.78 502.515 364.462 502.553 364.137 502.77L364.051 502.825C363.826 502.972 363.648 503.181 363.54 503.453C363.423 503.716 363.369 504.018 363.377 504.352C363.377 504.685 363.454 505.018 363.578 505.352L364.764 508.344L363.958 508.879L362.679 505.623C362.509 505.189 362.268 504.902 361.943 504.755C361.625 504.607 361.3 504.646 360.982 504.856L360.889 504.918C360.664 505.065 360.501 505.274 360.385 505.545C360.269 505.817 360.222 506.135 360.238 506.499C360.261 506.855 360.346 507.235 360.493 507.615L361.61 510.437L360.803 510.971L360.788 510.964Z"
          fill="black"
        />
        <path
          d="M369.733 507.476L369.415 506.662L370.617 505.871C370.71 505.809 370.772 505.747 370.818 505.677C370.865 505.607 370.88 505.522 370.88 505.429C370.88 505.336 370.849 505.22 370.795 505.08L370.322 503.871L370.741 504.553L366.454 500.367L367.299 499.809L370.748 503.197L370.392 503.359L370.345 497.794L371.252 497.197L371.307 504.181L371.159 503.406L371.733 504.871C371.865 505.212 371.896 505.514 371.818 505.778C371.741 506.041 371.578 506.259 371.314 506.429L369.733 507.476Z"
          fill="black"
        />
        <path
          d="M378.407 499.429C377.826 499.817 377.267 499.988 376.74 499.933C376.213 499.879 375.779 499.623 375.438 499.158L376.268 498.608C376.485 498.879 376.733 499.019 377.027 499.034C377.322 499.05 377.609 498.964 377.911 498.763L378.004 498.701C378.283 498.515 378.469 498.298 378.554 498.05C378.647 497.802 378.616 497.561 378.469 497.337C378.376 497.189 378.236 497.104 378.058 497.065C377.88 497.034 377.678 497.05 377.454 497.12L376.795 497.298C376.345 497.422 375.958 497.445 375.632 497.375C375.306 497.306 375.035 497.119 374.834 496.817C374.655 496.546 374.562 496.244 374.562 495.918C374.562 495.592 374.648 495.275 374.834 494.957C375.02 494.647 375.283 494.368 375.632 494.143C376.136 493.81 376.655 493.662 377.182 493.709C377.709 493.755 378.136 494.019 378.461 494.499L377.655 495.034C377.461 494.779 377.221 494.631 376.926 494.608C376.632 494.577 376.345 494.655 376.074 494.841L375.981 494.903C375.725 495.073 375.562 495.282 375.485 495.53C375.407 495.779 375.438 496.011 375.578 496.228C375.671 496.375 375.802 496.468 375.973 496.499C376.136 496.538 376.337 496.523 376.57 496.461L377.267 496.275C377.686 496.158 378.066 496.127 378.399 496.182C378.74 496.228 379.027 496.437 379.267 496.802C379.453 497.081 379.539 497.383 379.523 497.709C379.508 498.034 379.407 498.344 379.213 498.647C379.019 498.949 378.756 499.212 378.407 499.437V499.429Z"
          fill="black"
        />
        <path
          d="M382.577 496.67C382.314 496.84 382.042 496.949 381.748 496.98C381.461 497.019 381.174 496.98 380.911 496.856C380.639 496.74 380.414 496.538 380.228 496.259C380.027 495.957 379.926 495.623 379.926 495.275C379.926 494.918 380.027 494.569 380.236 494.228C380.445 493.887 380.756 493.585 381.159 493.321L382.616 492.344L382.531 492.135C382.43 491.872 382.275 491.678 382.081 491.577C381.879 491.469 381.67 491.438 381.438 491.469C381.213 491.5 380.996 491.585 380.787 491.717L380.732 491.755C380.453 491.941 380.252 492.174 380.135 492.453C380.019 492.732 380.011 493.003 380.12 493.275L379.314 493.81C379.097 493.344 379.081 492.848 379.275 492.329C379.469 491.81 379.833 491.368 380.376 491.011C380.732 490.779 381.104 490.631 381.5 490.593C381.895 490.546 382.259 490.631 382.6 490.841C382.941 491.058 383.205 491.414 383.406 491.926L384.205 493.957C384.298 494.197 384.383 494.391 384.445 494.554C384.484 494.654 384.523 494.755 384.561 494.856C384.6 494.957 384.639 495.05 384.678 495.158L383.918 495.662C383.895 495.6 383.872 495.53 383.848 495.461C383.825 495.391 383.802 495.329 383.771 495.267C383.748 495.205 383.717 495.135 383.693 495.081C383.67 495.019 383.639 494.957 383.616 494.895C383.6 495.298 383.5 495.647 383.313 495.941C383.127 496.236 382.887 496.484 382.585 496.685L382.577 496.67ZM382.345 495.84C382.562 495.701 382.74 495.499 382.895 495.236C383.05 494.972 383.143 494.693 383.174 494.383C383.205 494.073 383.166 493.779 383.05 493.492L382.903 493.104L381.616 493.957C381.244 494.205 380.996 494.492 380.88 494.81C380.763 495.127 380.794 495.43 380.98 495.709C381.12 495.926 381.314 496.05 381.546 496.088C381.779 496.127 382.011 496.065 382.252 495.91L382.345 495.848V495.84Z"
          fill="black"
        />
        <path
          d="M389.15 495.182C388.817 495.406 388.468 495.554 388.104 495.639C387.739 495.724 387.398 495.716 387.073 495.623C386.747 495.53 386.461 495.337 386.22 495.034L387.026 494.499C387.228 494.701 387.476 494.802 387.786 494.802C388.088 494.802 388.406 494.686 388.724 494.476L388.809 494.422C389.243 494.135 389.507 493.779 389.6 493.352C389.693 492.926 389.639 492.445 389.437 491.918L389.181 491.236C389.15 491.546 389.049 491.848 388.871 492.128C388.693 492.407 388.46 492.647 388.174 492.841C387.856 493.05 387.507 493.182 387.135 493.22C386.755 493.267 386.375 493.197 385.988 493.011C385.6 492.825 385.251 492.492 384.933 492.019C384.569 491.469 384.352 490.911 384.29 490.345C384.228 489.779 384.29 489.267 384.499 488.794C384.701 488.329 385.011 487.957 385.43 487.678C385.84 487.407 386.251 487.275 386.67 487.283C387.088 487.291 387.445 487.399 387.755 487.608L387.336 486.562L388.143 486.027L390.336 491.616C390.514 492.073 390.6 492.515 390.592 492.941C390.576 493.368 390.46 493.779 390.228 494.158C389.995 494.538 389.646 494.887 389.166 495.205L389.15 495.182ZM387.918 492.011C388.251 491.794 388.468 491.507 388.584 491.151C388.701 490.794 388.724 490.422 388.646 490.019C388.569 489.616 388.422 489.252 388.189 488.911C387.988 488.608 387.763 488.384 387.515 488.236C387.267 488.089 387.011 488.019 386.747 488.027C386.484 488.027 386.236 488.112 385.995 488.267L385.902 488.329C385.639 488.508 385.437 488.763 385.313 489.089C385.182 489.422 385.151 489.794 385.22 490.205C385.282 490.616 385.445 491.027 385.716 491.422C386.019 491.879 386.36 492.159 386.747 492.252C387.135 492.345 387.499 492.283 387.832 492.058L387.918 492.003V492.011Z"
          fill="black"
        />
        <path
          d="M394.041 489.089C393.638 489.353 393.228 489.5 392.801 489.523C392.375 489.546 391.972 489.453 391.577 489.236C391.189 489.019 390.84 488.686 390.546 488.236C390.197 487.717 389.995 487.174 389.941 486.609C389.879 486.043 389.964 485.516 390.197 485.027C390.422 484.539 390.77 484.136 391.243 483.826C391.6 483.593 391.964 483.454 392.344 483.415C392.716 483.376 393.08 483.446 393.429 483.624C393.778 483.802 394.08 484.097 394.328 484.492C394.383 484.578 394.445 484.686 394.507 484.826C394.569 484.965 394.631 485.113 394.693 485.268L391.228 487.562C391.414 487.888 391.646 488.143 391.91 488.337C392.181 488.531 392.46 488.632 392.762 488.639C393.065 488.655 393.344 488.57 393.615 488.391L393.708 488.329C394.003 488.136 394.212 487.895 394.328 487.616C394.445 487.337 394.468 487.035 394.398 486.725L395.204 486.19C395.375 486.733 395.367 487.26 395.181 487.764C394.995 488.267 394.615 488.709 394.049 489.089H394.041ZM393.561 485.074C393.359 484.663 393.088 484.407 392.747 484.306C392.406 484.213 392.042 484.283 391.662 484.539L391.577 484.593C391.22 484.826 390.987 485.144 390.871 485.547C390.755 485.95 390.763 486.376 390.902 486.833L393.569 485.066L393.561 485.074Z"
          fill="black"
        />
        <path
          d="M843.714 260.97C844.877 253.436 842.373 246.188 837.544 241.025C838.49 239.018 839.172 236.84 839.521 234.537C841.505 221.716 832.87 209.709 820.166 207.438C818.267 197.896 810.593 190.106 800.415 188.532C795.446 187.765 790.602 188.594 786.393 190.633C782.998 187.416 778.626 185.168 773.657 184.401C763.48 182.827 753.806 187.943 749.116 196.477C736.451 195.012 724.793 203.81 722.832 216.523C722.467 218.872 722.467 221.197 722.777 223.437C716.7 226.918 712.197 233.026 711.049 240.49C709.895 247.94 712.336 255.118 717.065 260.265C716.111 262.288 715.429 264.474 715.065 266.791C713.104 279.488 721.545 291.379 734.04 293.829C735.9 303.425 743.59 311.277 753.814 312.859C758.782 313.626 763.627 312.797 767.836 310.758C771.231 313.975 775.603 316.223 780.572 316.99C790.788 318.571 800.485 313.409 805.159 304.828C817.941 306.471 829.777 297.635 831.754 284.829C832.103 282.558 832.118 280.31 831.839 278.132C837.994 274.667 842.552 268.52 843.714 260.993V260.97Z"
          fill="#FF004B"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M741.125 255.792L742.822 247.8H745.559C746.086 247.8 746.528 247.885 746.892 248.048C747.256 248.211 747.535 248.443 747.713 248.746C747.892 249.048 747.985 249.405 747.985 249.815C747.985 250.296 747.845 250.73 747.574 251.118C747.303 251.505 746.946 251.777 746.512 251.924C746.884 251.97 747.155 252.118 747.318 252.366C747.481 252.614 747.527 252.963 747.434 253.412L746.923 255.8H745.892L746.403 253.366C746.473 253.056 746.442 252.839 746.31 252.722C746.179 252.606 745.946 252.544 745.613 252.544H742.838L742.14 255.8H741.102L741.125 255.792ZM745.21 251.606C745.535 251.606 745.83 251.544 746.101 251.412C746.372 251.28 746.582 251.094 746.737 250.839C746.892 250.591 746.969 250.288 746.969 249.947C746.969 249.529 746.83 249.219 746.551 249.025C746.272 248.831 745.853 248.73 745.303 248.73H743.66L743.055 251.598H745.21V251.606Z"
          fill="black"
        />
        <path
          d="M751.349 255.908C750.83 255.908 750.388 255.8 750.016 255.59C749.644 255.373 749.357 255.071 749.163 254.683C748.969 254.288 748.868 253.838 748.868 253.319C748.868 252.707 749 252.141 749.264 251.622C749.527 251.11 749.884 250.707 750.341 250.412C750.799 250.118 751.31 249.97 751.868 249.97C752.388 249.97 752.829 250.079 753.201 250.288C753.574 250.498 753.86 250.808 754.054 251.195C754.248 251.591 754.349 252.04 754.349 252.559C754.349 253.18 754.217 253.753 753.946 254.257C753.682 254.769 753.318 255.172 752.853 255.459C752.388 255.745 751.884 255.9 751.326 255.9L751.349 255.908ZM751.45 255.086C751.76 255.086 752.07 254.986 752.372 254.792C752.674 254.598 752.915 254.304 753.108 253.931C753.302 253.552 753.395 253.11 753.395 252.598C753.395 252.257 753.333 251.947 753.209 251.676C753.085 251.404 752.915 251.187 752.682 251.032C752.457 250.877 752.186 250.8 751.884 250.8H751.767C751.45 250.8 751.14 250.901 750.845 251.102C750.55 251.304 750.302 251.591 750.116 251.97C749.923 252.35 749.83 252.784 749.83 253.288C749.83 253.629 749.892 253.939 750.016 254.203C750.14 254.474 750.31 254.691 750.535 254.846C750.76 255.009 751.031 255.086 751.341 255.086H751.457H751.45Z"
          fill="black"
        />
        <path
          d="M757.341 255.908C756.643 255.908 756.085 255.745 755.674 255.412C755.263 255.079 755.039 254.629 755.015 254.048H756.007C756.038 254.389 756.17 254.652 756.403 254.823C756.635 255.001 756.93 255.087 757.286 255.087H757.403C757.736 255.087 758.015 255.009 758.224 254.846C758.441 254.683 758.542 254.474 758.542 254.211C758.542 254.032 758.48 253.885 758.348 253.761C758.217 253.637 758.038 253.536 757.814 253.466L757.162 253.249C756.721 253.102 756.38 252.916 756.147 252.676C755.914 252.436 755.79 252.133 755.79 251.769C755.79 251.443 755.883 251.141 756.062 250.87C756.24 250.598 756.496 250.381 756.821 250.219C757.147 250.056 757.519 249.978 757.938 249.978C758.55 249.978 759.061 250.141 759.472 250.474C759.883 250.808 760.1 251.257 760.108 251.839H759.139C759.123 251.521 758.999 251.265 758.767 251.079C758.534 250.893 758.255 250.8 757.93 250.8H757.814C757.511 250.8 757.255 250.885 757.054 251.048C756.852 251.211 756.752 251.42 756.752 251.684C756.752 251.862 756.814 252.009 756.93 252.133C757.046 252.257 757.224 252.35 757.449 252.428L758.131 252.653C758.542 252.792 758.875 252.97 759.131 253.203C759.387 253.435 759.511 253.761 759.511 254.195C759.511 254.528 759.418 254.831 759.224 255.094C759.03 255.358 758.775 255.559 758.449 255.707C758.124 255.854 757.751 255.924 757.341 255.924V255.908Z"
          fill="black"
        />
        <path
          d="M763.301 255.908C762.821 255.908 762.395 255.8 762.03 255.59C761.658 255.373 761.371 255.071 761.17 254.676C760.968 254.28 760.86 253.815 760.86 253.273C760.86 252.645 760.992 252.087 761.255 251.583C761.519 251.079 761.875 250.684 762.34 250.405C762.798 250.118 763.309 249.978 763.875 249.978C764.301 249.978 764.681 250.064 765.015 250.242C765.348 250.42 765.611 250.676 765.805 251.017C765.999 251.358 766.084 251.769 766.076 252.242C766.076 252.342 766.069 252.467 766.045 252.622C766.022 252.777 765.991 252.932 765.952 253.087H761.798C761.774 253.459 761.821 253.8 761.937 254.11C762.053 254.42 762.232 254.66 762.48 254.831C762.72 255.009 763.007 255.094 763.325 255.094H763.441C763.798 255.094 764.1 255.009 764.356 254.838C764.611 254.668 764.79 254.435 764.906 254.133H765.875C765.712 254.683 765.418 255.118 764.984 255.435C764.549 255.753 763.991 255.916 763.309 255.916L763.301 255.908ZM765.115 252.288C765.177 251.831 765.092 251.467 764.859 251.195C764.627 250.924 764.286 250.792 763.829 250.792H763.728C763.301 250.792 762.929 250.924 762.612 251.195C762.294 251.467 762.061 251.831 761.929 252.288H765.123H765.115Z"
          fill="black"
        />
        <path
          d="M769.045 255.908C768.348 255.908 767.79 255.745 767.379 255.412C766.968 255.079 766.743 254.629 766.72 254.048H767.712C767.743 254.389 767.875 254.652 768.107 254.823C768.34 255.001 768.634 255.087 768.991 255.087H769.107C769.441 255.087 769.72 255.009 769.929 254.846C770.146 254.683 770.247 254.474 770.247 254.211C770.247 254.032 770.185 253.885 770.053 253.761C769.921 253.637 769.743 253.536 769.518 253.466L768.867 253.249C768.425 253.102 768.084 252.916 767.852 252.676C767.619 252.436 767.495 252.133 767.495 251.769C767.495 251.443 767.588 251.141 767.766 250.87C767.945 250.598 768.2 250.381 768.526 250.219C768.851 250.056 769.224 249.978 769.642 249.978C770.254 249.978 770.766 250.141 771.177 250.474C771.588 250.808 771.805 251.257 771.813 251.839H770.844C770.828 251.521 770.704 251.265 770.471 251.079C770.239 250.893 769.96 250.8 769.634 250.8H769.518C769.216 250.8 768.96 250.885 768.758 251.048C768.557 251.211 768.456 251.42 768.456 251.684C768.456 251.862 768.518 252.009 768.634 252.133C768.751 252.257 768.929 252.35 769.154 252.428L769.836 252.653C770.247 252.792 770.58 252.97 770.836 253.203C771.092 253.435 771.216 253.761 771.216 254.195C771.216 254.528 771.123 254.831 770.929 255.094C770.735 255.358 770.479 255.559 770.154 255.707C769.828 255.854 769.456 255.924 769.045 255.924V255.908Z"
          fill="black"
        />
        <path
          d="M776.874 255.908C776.564 255.908 776.277 255.846 776.014 255.714C775.75 255.582 775.541 255.396 775.378 255.148C775.215 254.9 775.138 254.614 775.138 254.272C775.138 253.908 775.231 253.575 775.432 253.28C775.626 252.986 775.905 252.745 776.27 252.575C776.634 252.404 777.06 252.319 777.533 252.327H779.293L779.339 252.102C779.401 251.823 779.378 251.583 779.269 251.381C779.161 251.18 779.006 251.032 778.796 250.932C778.587 250.831 778.362 250.784 778.114 250.784H778.045C777.711 250.784 777.417 250.87 777.169 251.032C776.921 251.203 776.758 251.428 776.704 251.707H775.735C775.812 251.195 776.068 250.777 776.518 250.451C776.967 250.125 777.51 249.963 778.169 249.963C778.595 249.963 778.982 250.056 779.339 250.234C779.696 250.412 779.951 250.684 780.114 251.048C780.277 251.412 780.3 251.862 780.192 252.397L779.734 254.528C779.68 254.776 779.641 254.986 779.61 255.156C779.587 255.265 779.564 255.365 779.541 255.466C779.517 255.567 779.494 255.676 779.471 255.784H778.556C778.572 255.714 778.587 255.644 778.61 255.582C778.626 255.513 778.641 255.443 778.649 255.373C778.665 255.303 778.68 255.241 778.688 255.172C778.696 255.11 778.711 255.04 778.727 254.97C778.494 255.296 778.215 255.536 777.897 255.676C777.579 255.823 777.238 255.893 776.874 255.893V255.908ZM777.138 255.086C777.393 255.086 777.657 255.017 777.928 254.885C778.2 254.753 778.432 254.567 778.634 254.327C778.835 254.086 778.959 253.815 779.021 253.513L779.114 253.11H777.572C777.122 253.11 776.758 253.211 776.487 253.412C776.215 253.614 776.076 253.885 776.076 254.218C776.076 254.474 776.161 254.683 776.339 254.846C776.518 255.009 776.742 255.086 777.021 255.086H777.138Z"
          fill="black"
        />
        <path
          d="M781.153 255.792L782.362 250.087H783.331L783.091 251.203C783.285 250.815 783.54 250.521 783.843 250.327C784.145 250.133 784.525 250.032 784.974 250.032H785.408L785.215 250.978H784.796C784.292 250.978 783.858 251.149 783.494 251.49C783.13 251.831 782.866 252.358 782.703 253.071L782.122 255.8H781.153V255.792Z"
          fill="black"
        />
        <path
          d="M787.897 255.908C787.416 255.908 786.99 255.8 786.625 255.59C786.253 255.373 785.967 255.071 785.765 254.676C785.563 254.28 785.455 253.815 785.455 253.273C785.455 252.645 785.587 252.087 785.85 251.583C786.114 251.079 786.47 250.684 786.935 250.405C787.393 250.118 787.904 249.978 788.47 249.978C788.897 249.978 789.276 250.064 789.61 250.242C789.943 250.42 790.207 250.676 790.4 251.017C790.594 251.358 790.679 251.769 790.672 252.242C790.672 252.342 790.664 252.467 790.641 252.622C790.617 252.777 790.586 252.932 790.548 253.087H786.393C786.37 253.459 786.416 253.8 786.532 254.11C786.649 254.42 786.827 254.66 787.075 254.831C787.315 255.009 787.602 255.094 787.92 255.094H788.036C788.393 255.094 788.695 255.009 788.951 254.838C789.207 254.668 789.385 254.435 789.501 254.133H790.47C790.307 254.683 790.013 255.118 789.579 255.435C789.145 255.753 788.586 255.916 787.904 255.916L787.897 255.908ZM789.71 252.288C789.772 251.831 789.687 251.467 789.455 251.195C789.222 250.924 788.881 250.792 788.424 250.792H788.323C787.897 250.792 787.525 250.924 787.207 251.195C786.889 251.467 786.656 251.831 786.525 252.288H789.718H789.71Z"
          fill="black"
        />
        <path
          d="M794.462 255.792L796.16 247.8H798.896C799.423 247.8 799.865 247.885 800.229 248.048C800.593 248.211 800.872 248.443 801.051 248.746C801.229 249.048 801.322 249.405 801.322 249.815C801.322 250.296 801.182 250.73 800.911 251.118C800.64 251.505 800.283 251.777 799.849 251.924C800.221 251.97 800.493 252.118 800.655 252.366C800.818 252.614 800.865 252.963 800.772 253.412L800.26 255.8H799.229L799.741 253.366C799.81 253.056 799.779 252.839 799.648 252.722C799.516 252.606 799.283 252.544 798.95 252.544H796.175L795.477 255.8H794.439L794.462 255.792ZM798.547 251.606C798.873 251.606 799.167 251.544 799.438 251.412C799.71 251.28 799.919 251.094 800.074 250.839C800.229 250.591 800.307 250.288 800.307 249.947C800.307 249.529 800.167 249.219 799.888 249.025C799.609 248.831 799.19 248.73 798.64 248.73H796.997L796.392 251.598H798.547V251.606Z"
          fill="black"
        />
        <path
          d="M804.655 255.908C804.174 255.908 803.748 255.8 803.384 255.59C803.012 255.373 802.725 255.071 802.523 254.676C802.322 254.28 802.213 253.815 802.213 253.273C802.213 252.645 802.345 252.087 802.609 251.583C802.872 251.079 803.229 250.684 803.694 250.405C804.151 250.118 804.663 249.978 805.229 249.978C805.655 249.978 806.035 250.064 806.368 250.242C806.701 250.42 806.965 250.676 807.159 251.017C807.353 251.358 807.438 251.769 807.43 252.242C807.43 252.342 807.422 252.467 807.399 252.622C807.376 252.777 807.345 252.932 807.306 253.087H803.151C803.128 253.459 803.175 253.8 803.291 254.11C803.407 254.42 803.585 254.66 803.833 254.831C804.074 255.009 804.361 255.094 804.678 255.094H804.795C805.151 255.094 805.453 255.009 805.709 254.838C805.965 254.668 806.143 254.435 806.26 254.133H807.229C807.066 254.683 806.771 255.118 806.337 255.435C805.903 255.753 805.345 255.916 804.663 255.916L804.655 255.908ZM806.469 252.288C806.531 251.831 806.446 251.467 806.213 251.195C805.981 250.924 805.64 250.792 805.182 250.792H805.081C804.655 250.792 804.283 250.924 803.965 251.195C803.647 251.467 803.415 251.831 803.283 252.288H806.477H806.469Z"
          fill="black"
        />
        <path
          d="M810.74 255.908C810.306 255.908 809.911 255.807 809.562 255.614C809.213 255.412 808.934 255.125 808.74 254.745C808.539 254.366 808.445 253.908 808.445 253.373C808.445 252.722 808.577 252.133 808.841 251.614C809.104 251.094 809.453 250.691 809.895 250.405C810.337 250.118 810.817 249.97 811.345 249.97C811.794 249.97 812.197 250.079 812.562 250.288C812.926 250.498 813.182 250.777 813.329 251.125L814.027 247.8H815.034L813.313 255.792H812.352L812.499 255.094C812.344 255.319 812.104 255.513 811.771 255.668C811.438 255.823 811.097 255.9 810.74 255.9V255.908ZM810.965 255.071C811.36 255.071 811.709 254.947 812.019 254.707C812.321 254.466 812.561 254.141 812.724 253.745C812.895 253.35 812.972 252.916 812.972 252.459C812.972 252.133 812.903 251.846 812.771 251.598C812.639 251.35 812.461 251.156 812.228 251.017C812.003 250.877 811.748 250.808 811.461 250.808H811.345C811.003 250.808 810.686 250.916 810.399 251.141C810.112 251.358 809.879 251.66 809.693 252.04C809.507 252.42 809.422 252.846 809.422 253.304C809.422 253.831 809.554 254.249 809.818 254.575C810.081 254.9 810.422 255.063 810.848 255.071H810.949H810.965Z"
          fill="black"
        />
        <path
          d="M61.5615 692.101C94.6962 692.101 121.557 665.24 121.557 632.104C121.557 598.969 94.6962 572.108 61.5615 572.108C28.4268 572.108 1.5658 598.969 1.5658 632.104C1.5658 665.24 28.4268 692.101 61.5615 692.101Z"
          fill="#FF357F"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M20.9752 623.965L24.7346 616.71L27.6492 617.516C28.091 617.64 28.4553 617.818 28.7576 618.051C29.0522 618.291 29.2614 618.57 29.3777 618.888C29.494 619.213 29.494 619.578 29.3777 619.981C29.2382 620.485 28.9591 620.88 28.5483 621.159C28.1375 621.438 27.6647 621.57 27.1298 621.547C27.4399 621.771 27.6492 622.051 27.7654 622.376C27.8817 622.702 27.8894 623.05 27.7887 623.407C27.6802 623.81 27.4709 624.159 27.1686 624.454C26.8663 624.748 26.502 624.942 26.0679 625.043C25.6338 625.143 25.192 625.136 24.7424 625.012L20.9597 623.973L20.9752 623.965ZM24.9129 624.074C25.3315 624.19 25.7191 624.167 26.0911 624.012C26.4554 623.857 26.7112 623.531 26.843 623.043C26.936 622.702 26.905 622.399 26.7422 622.144C26.5795 621.888 26.2927 621.709 25.8818 621.593L23.6572 620.981L22.4015 623.384L24.9129 624.074ZM26.4709 620.787C26.8973 620.903 27.2926 620.88 27.6569 620.702C28.0212 620.531 28.2538 620.237 28.37 619.826C28.4708 619.462 28.4243 619.159 28.2383 618.919C28.0522 618.679 27.7189 618.5 27.2538 618.369L25.2927 617.826L24.0913 620.12L26.4709 620.772V620.787Z"
          fill="black"
        />
        <path
          d="M30.1994 626.624C29.7731 626.508 29.4475 626.314 29.2227 626.043C28.9979 625.771 28.8817 625.446 28.8739 625.074C28.8739 624.694 28.9747 624.299 29.1995 623.864L30.804 620.756L31.7497 621.02L30.1064 624.182C29.8971 624.578 29.8351 624.942 29.9203 625.26C30.0134 625.578 30.2304 625.787 30.5792 625.888L30.6877 625.919C30.9668 625.996 31.2536 625.988 31.5404 625.895C31.8349 625.802 32.1062 625.632 32.362 625.384C32.6178 625.136 32.8426 624.833 33.0364 624.454L34.4316 621.756L35.3696 622.012L32.6876 627.19L31.7497 626.934L32.1527 626.143C31.8582 626.391 31.5559 626.562 31.2303 626.647C30.9048 626.732 30.5637 626.725 30.1916 626.624H30.1994Z"
          fill="black"
        />
        <path
          d="M37.4082 628.608C36.9586 628.484 36.5943 628.267 36.3152 627.942C36.0362 627.616 35.8734 627.283 35.8424 626.918L35.323 627.911L34.3851 627.655L38.1445 620.399L39.0825 620.655L37.6872 623.353C37.9508 623.174 38.2453 623.05 38.5709 622.988C38.8964 622.926 39.2297 622.95 39.5863 623.043C39.9816 623.151 40.3227 623.345 40.594 623.632C40.873 623.911 41.0591 624.26 41.1443 624.671C41.2374 625.081 41.2141 625.531 41.0823 626.027C40.904 626.678 40.6172 627.228 40.2374 627.663C39.8499 628.104 39.4158 628.407 38.9197 628.57C38.4236 628.732 37.9275 628.748 37.4159 628.608H37.4082ZM37.5632 627.794C37.8888 627.88 38.2221 627.872 38.5631 627.748C38.9042 627.632 39.222 627.407 39.501 627.073C39.7801 626.748 39.9894 626.329 40.1289 625.833C40.2607 625.345 40.2529 624.911 40.1056 624.539C39.9506 624.167 39.6638 623.919 39.2452 623.802L39.1445 623.771C38.7569 623.663 38.3771 623.686 38.0128 623.849C37.6485 624.012 37.3384 624.252 37.0826 624.601C36.8191 624.942 36.633 625.337 36.509 625.779C36.4237 626.097 36.4082 626.391 36.4625 626.663C36.5168 626.934 36.633 627.174 36.8036 627.368C36.9818 627.562 37.1989 627.694 37.4624 627.771L37.5632 627.802V627.794Z"
          fill="black"
        />
        <path
          d="M43.9813 630.414C43.5317 630.29 43.1674 630.073 42.8883 629.756C42.6015 629.438 42.4465 629.097 42.4155 628.732L41.8962 629.725L40.9583 629.469L44.7177 622.213L45.6556 622.469L44.2603 625.167C44.5239 624.988 44.8184 624.864 45.144 624.802C45.4696 624.74 45.8106 624.764 46.1594 624.857C46.5547 624.965 46.8958 625.159 47.1671 625.446C47.4462 625.725 47.6322 626.074 47.7175 626.484C47.8105 626.895 47.7872 627.345 47.6554 627.841C47.4772 628.492 47.1904 629.042 46.8105 629.477C46.423 629.918 45.9889 630.213 45.4928 630.383C44.9967 630.546 44.5006 630.562 43.989 630.422L43.9813 630.414ZM44.1363 629.601C44.4619 629.686 44.7952 629.678 45.1362 629.554C45.4773 629.438 45.7951 629.213 46.0742 628.88C46.3532 628.554 46.5625 628.136 46.702 627.639C46.8338 627.151 46.826 626.717 46.671 626.345C46.516 625.973 46.2292 625.725 45.8106 625.609L45.7098 625.578C45.3223 625.469 44.9425 625.492 44.5859 625.655C44.2216 625.81 43.9115 626.058 43.648 626.407C43.3844 626.748 43.1984 627.143 43.0744 627.577C42.9891 627.895 42.9736 628.19 43.0279 628.461C43.0821 628.74 43.1984 628.973 43.3767 629.166C43.555 629.36 43.772 629.492 44.0356 629.57L44.1363 629.601Z"
          fill="black"
        />
        <path
          d="M47.5237 631.275L51.2831 624.02L52.221 624.275L48.4616 631.531L47.5237 631.275Z"
          fill="black"
        />
        <path
          d="M52.7094 632.817C52.2443 632.693 51.8645 632.476 51.57 632.174C51.2677 631.872 51.0739 631.5 50.9809 631.065C50.8878 630.631 50.9111 630.151 51.0584 629.631C51.2212 629.027 51.5002 628.523 51.8878 628.104C52.2753 627.694 52.7249 627.407 53.2443 627.252C53.7636 627.097 54.2985 627.097 54.841 627.252C55.2519 627.368 55.5929 627.546 55.872 627.81C56.151 628.066 56.3293 628.383 56.4223 628.771C56.5153 629.151 56.4921 629.569 56.3603 630.019C56.3371 630.112 56.2906 630.228 56.2285 630.376C56.1665 630.515 56.0968 630.662 56.0193 630.802L52.0118 629.701C51.8878 630.058 51.849 630.399 51.88 630.724C51.911 631.05 52.0195 631.337 52.2133 631.569C52.3994 631.802 52.6474 631.965 52.9575 632.05L53.066 632.081C53.4148 632.174 53.7248 632.174 54.0117 632.081C54.2985 631.988 54.5387 631.802 54.7248 631.546L55.6627 631.802C55.3604 632.29 54.9651 632.624 54.4612 632.817C53.9574 633.011 53.376 633.019 52.7172 632.833L52.7094 632.817ZM55.4224 629.81C55.6007 629.383 55.6162 629.011 55.4612 628.694C55.3061 628.368 55.0116 628.151 54.5698 628.027L54.469 627.996C54.0582 627.88 53.6628 627.911 53.283 628.089C52.9032 628.267 52.5854 628.554 52.3296 628.957L55.4146 629.802L55.4224 629.81Z"
          fill="black"
        />
        <path
          d="M57.4454 636.6C57.0579 636.492 56.709 636.329 56.4145 636.112C56.1122 635.895 55.8952 635.631 55.7479 635.329C55.6006 635.019 55.5618 634.678 55.6239 634.298L56.5618 634.554C56.5385 634.84 56.6315 635.096 56.8331 635.321C57.0346 635.546 57.3292 635.709 57.6935 635.809L57.7942 635.84C58.2903 635.98 58.7322 635.941 59.1197 635.732C59.5073 635.523 59.8329 635.166 60.0964 634.662L60.4375 634.019C60.1817 634.205 59.8949 634.321 59.5693 634.368C59.2437 634.422 58.9104 634.399 58.5771 634.306C58.2128 634.205 57.8873 634.027 57.6082 633.771C57.3292 633.515 57.1276 633.182 57.0114 632.763C56.8951 632.352 56.9183 631.864 57.0656 631.313C57.2439 630.678 57.5229 630.143 57.9028 629.724C58.2826 629.306 58.7167 629.019 59.205 628.864C59.6856 628.709 60.1739 628.701 60.6545 628.833C61.1351 628.965 61.4994 629.19 61.7629 629.507C62.0265 629.825 62.1815 630.166 62.228 630.538L62.7396 629.531L63.6775 629.786L60.9103 635.112C60.6855 635.546 60.4142 635.902 60.0809 636.174C59.7476 636.445 59.3678 636.623 58.9259 636.693C58.4841 636.771 57.988 636.732 57.4377 636.577L57.4454 636.6ZM59.019 633.585C59.3988 633.693 59.7631 633.67 60.1042 633.523C60.4452 633.375 60.7475 633.143 60.9956 632.825C61.2436 632.507 61.4219 632.151 61.5304 631.755C61.6234 631.399 61.6467 631.089 61.6002 630.802C61.5459 630.523 61.4296 630.283 61.2514 630.089C61.0731 629.895 60.8483 629.755 60.577 629.686L60.4685 629.655C60.1584 629.569 59.8406 629.585 59.5073 629.709C59.174 629.833 58.8717 630.05 58.6004 630.376C58.3291 630.693 58.1353 631.089 58.0035 631.546C57.8563 632.073 57.8795 632.515 58.0578 632.872C58.2438 633.228 58.5229 633.453 58.9182 633.561L59.019 633.592V633.585Z"
          fill="black"
        />
        <path
          d="M64.7007 636.12C64.2744 636.003 63.9488 635.81 63.7241 635.538C63.4993 635.267 63.383 634.941 63.3752 634.569C63.3752 634.189 63.476 633.794 63.693 633.36L65.2976 630.252L66.2433 630.515L64.6 633.678C64.3907 634.073 64.3287 634.438 64.4139 634.755C64.5069 635.073 64.724 635.282 65.0728 635.383L65.1813 635.414C65.4604 635.492 65.7472 635.484 66.034 635.391C66.3285 635.298 66.5998 635.127 66.8556 634.879C67.1114 634.631 67.3362 634.329 67.53 633.949L68.9252 631.252L69.8631 631.507L67.1812 636.685L66.2433 636.43L66.6463 635.639C66.3518 635.887 66.0495 636.058 65.7239 636.143C65.3984 636.228 65.0573 636.22 64.693 636.12H64.7007Z"
          fill="black"
        />
        <path
          d="M68.8788 637.15L71.5608 631.972L72.4987 632.228L72.0879 633.027C72.3436 632.802 72.6537 632.639 73.0103 632.538C73.3668 632.445 73.7311 632.445 74.111 632.554C74.5063 632.662 74.8163 632.864 75.0411 633.151C75.2659 633.445 75.3667 633.779 75.3589 634.174C75.6845 633.879 76.0566 633.67 76.4829 633.554C76.9015 633.437 77.32 633.437 77.7231 633.546C78.1107 633.655 78.4207 633.841 78.661 634.104C78.8936 634.368 79.0253 634.693 79.0408 635.081C79.0641 635.468 78.9556 635.879 78.7308 636.337L77.1263 639.422L76.2038 639.166L77.8316 636.011C78.0487 635.608 78.1029 635.236 77.9944 634.91C77.8859 634.585 77.6456 634.368 77.2735 634.267L77.1728 634.236C76.9092 634.166 76.6379 634.174 76.3589 634.259C76.0798 634.344 75.8163 634.507 75.576 634.732C75.3279 634.957 75.1186 635.228 74.9559 635.546L73.4831 638.406L72.5452 638.15L74.1575 635.042C74.3745 634.631 74.4288 634.251 74.328 633.918C74.2272 633.585 73.9869 633.36 73.6149 633.259L73.5064 633.228C73.2506 633.158 72.9793 633.166 72.7002 633.259C72.4212 633.352 72.1499 633.523 71.8863 633.779C71.6305 634.034 71.4057 634.337 71.212 634.709L69.8167 637.406L68.8788 637.15Z"
          fill="black"
        />
        <path
          d="M81.6608 640.67L85.4202 633.414L88.1022 634.151C88.5828 634.282 88.9858 634.484 89.3036 634.763C89.6214 635.034 89.8307 635.36 89.947 635.724C90.0555 636.096 90.0633 636.476 89.947 636.871C89.8152 637.352 89.5827 637.763 89.2649 638.088C88.9393 638.422 88.5517 638.647 88.1099 638.763C87.6603 638.887 87.1797 638.871 86.6682 638.732L84.1567 638.042L82.6529 640.933L81.6608 640.662V640.67ZM86.9007 637.84C87.3658 637.964 87.7844 637.926 88.1564 637.716C88.5362 637.507 88.7843 637.166 88.9161 636.685C89.0246 636.298 88.9781 635.957 88.7688 635.662C88.5672 635.368 88.1874 635.151 87.6448 634.996L85.9628 634.531L84.5753 637.189L86.8852 637.825L86.9007 637.84Z"
          fill="black"
        />
        <path
          d="M88.4819 642.546L91.1639 637.368L92.1018 637.623L89.4198 642.801L88.4819 642.546ZM91.567 636.406L92.1096 635.344L93.156 635.631L92.6134 636.693L91.567 636.406Z"
          fill="black"
        />
        <path
          d="M91.1019 643.266L93.7839 638.088L94.7218 638.344L94.3575 639.073C94.6443 638.84 94.9544 638.693 95.2954 638.623C95.6365 638.553 95.9853 638.569 96.3341 638.67C96.6984 638.771 97.0007 638.957 97.2333 639.22C97.4658 639.484 97.5898 639.809 97.6208 640.197C97.6518 640.584 97.5433 641.003 97.3108 641.46L95.714 644.538L94.7761 644.282L96.3806 641.204C96.5201 640.918 96.5977 640.654 96.6132 640.406C96.6287 640.158 96.5744 639.949 96.4504 639.77C96.3186 639.592 96.1326 639.468 95.8845 639.398L95.776 639.367C95.5047 639.29 95.2257 639.298 94.9544 639.367C94.6831 639.437 94.404 639.608 94.1482 639.856C93.8847 640.104 93.6521 640.429 93.4351 640.825L92.0398 643.522L91.1019 643.266Z"
          fill="black"
        />
        <path
          d="M97.3418 644.98L101.101 637.724L102.039 637.98L99.6595 642.584L103.744 640.817L105.078 641.181L101.621 642.654L102.861 646.491L101.752 646.189L100.737 643.034L99.0471 643.763L98.2875 645.235L97.3495 644.98H97.3418Z"
          fill="black"
        />
        <path
          d="M895.579 359.67C928.713 359.67 955.574 332.808 955.574 299.673C955.574 266.538 928.713 239.677 895.579 239.677C862.444 239.677 835.583 266.538 835.583 299.673C835.583 332.808 862.444 359.67 895.579 359.67Z"
          fill="#63A2C3"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M870.069 303.688L871.767 295.697H874.79C875.247 295.697 875.65 295.774 875.999 295.921C876.348 296.069 876.619 296.286 876.82 296.565C877.022 296.844 877.115 297.193 877.115 297.611C877.115 298.138 876.952 298.588 876.627 298.968C876.301 299.347 875.883 299.596 875.363 299.712C875.72 299.851 875.999 300.061 876.2 300.347C876.394 300.634 876.495 300.96 876.495 301.332C876.495 301.75 876.386 302.138 876.177 302.502C875.96 302.867 875.665 303.153 875.278 303.363C874.89 303.572 874.456 303.681 873.991 303.681H870.061L870.069 303.688ZM873.89 302.75C874.325 302.75 874.697 302.626 875.007 302.378C875.317 302.13 875.472 301.75 875.472 301.247C875.472 300.89 875.355 300.611 875.138 300.409C874.914 300.208 874.588 300.107 874.162 300.107H871.86L871.286 302.758H873.89V302.75ZM874.518 299.169C874.96 299.169 875.332 299.037 875.635 298.774C875.937 298.51 876.092 298.169 876.092 297.743C876.092 297.363 875.968 297.084 875.728 296.906C875.487 296.727 875.115 296.634 874.635 296.634H872.604L872.053 299.169H874.518Z"
          fill="black"
        />
        <path
          d="M879.285 303.805C878.975 303.805 878.689 303.743 878.425 303.611C878.161 303.479 877.952 303.293 877.789 303.045C877.627 302.797 877.549 302.51 877.549 302.169C877.549 301.805 877.642 301.471 877.844 301.177C878.037 300.882 878.316 300.642 878.681 300.471C879.045 300.301 879.471 300.216 879.944 300.223H881.704L881.75 299.999C881.812 299.719 881.789 299.479 881.681 299.278C881.572 299.076 881.417 298.929 881.208 298.828C880.998 298.727 880.774 298.681 880.526 298.681H880.456C880.123 298.681 879.828 298.766 879.58 298.929C879.332 299.099 879.169 299.324 879.115 299.603H878.146C878.223 299.092 878.479 298.673 878.929 298.347C879.378 298.022 879.921 297.859 880.58 297.859C881.006 297.859 881.394 297.952 881.75 298.13C882.107 298.309 882.363 298.58 882.525 298.944C882.688 299.309 882.712 299.758 882.603 300.293L882.146 302.425C882.091 302.673 882.053 302.882 882.022 303.053C881.998 303.161 881.975 303.262 881.952 303.363C881.929 303.463 881.905 303.572 881.882 303.68H880.967C880.983 303.611 880.998 303.541 881.022 303.479C881.037 303.409 881.053 303.339 881.06 303.27C881.076 303.2 881.091 303.138 881.099 303.068C881.107 303.006 881.122 302.936 881.138 302.867C880.905 303.192 880.626 303.432 880.309 303.572C879.991 303.719 879.65 303.789 879.285 303.789V303.805ZM879.549 302.983C879.805 302.983 880.068 302.913 880.34 302.781C880.611 302.65 880.843 302.464 881.045 302.223C881.246 301.983 881.371 301.712 881.433 301.409L881.526 301.006H879.983C879.533 301.006 879.169 301.107 878.898 301.309C878.627 301.51 878.487 301.781 878.487 302.115C878.487 302.37 878.572 302.58 878.751 302.743C878.929 302.905 879.154 302.983 879.433 302.983H879.549Z"
          fill="black"
        />
        <path
          d="M885.502 303.688C885.083 303.688 884.781 303.572 884.595 303.34C884.409 303.107 884.363 302.797 884.456 302.409L885.2 298.813H883.89L884.06 297.983H885.393L885.797 296.131H886.742L886.339 297.983H887.789L887.618 298.813H886.161L885.409 302.371C885.378 302.518 885.409 302.626 885.487 302.704C885.564 302.781 885.68 302.82 885.82 302.82H887.06L886.874 303.673H885.502V303.688Z"
          fill="black"
        />
        <path
          d="M887.967 303.688L889.657 295.697H890.626L889.982 298.782C890.192 298.487 890.455 298.262 890.773 298.1C891.083 297.944 891.416 297.867 891.781 297.867C892.16 297.867 892.502 297.96 892.796 298.162C893.091 298.355 893.308 298.634 893.432 299.006C893.563 299.378 893.571 299.805 893.463 300.301L892.742 303.688H891.773L892.502 300.301C892.571 299.991 892.579 299.712 892.525 299.464C892.47 299.223 892.362 299.03 892.191 298.89C892.021 298.751 891.804 298.681 891.548 298.681H891.447C891.161 298.681 890.889 298.758 890.641 298.906C890.393 299.053 890.176 299.278 889.99 299.588C889.804 299.89 889.664 300.27 889.564 300.72L888.936 303.688H887.967Z"
          fill="black"
        />
        <path
          d="M895.424 303.688L894.873 297.983H895.835L896.199 303.037L896.028 302.991L898.47 297.983H899.532L899.912 303.037L899.726 302.983L902.183 297.983H903.245L900.268 303.688H899.137L898.796 298.72L898.997 298.743L896.555 303.688H895.424Z"
          fill="black"
        />
        <path
          d="M550.728 408.628C583.862 408.628 610.723 381.767 610.723 348.632C610.723 315.496 583.862 288.635 550.728 288.635C517.593 288.635 490.732 315.496 490.732 348.632C490.732 381.767 517.593 408.628 550.728 408.628Z"
          fill="#C5D6C0"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M498.933 351.771L500.631 343.779H501.661L499.964 351.771H498.933Z"
          fill="black"
        />
        <path
          d="M504.607 351.88C504.111 351.88 503.677 351.787 503.297 351.585C502.917 351.391 502.623 351.104 502.406 350.732C502.189 350.36 502.08 349.911 502.08 349.399C502.08 348.709 502.22 348.097 502.491 347.57C502.762 347.043 503.134 346.64 503.607 346.36C504.08 346.081 504.592 345.942 505.165 345.942C505.599 345.942 505.987 346.019 506.328 346.182C506.669 346.345 506.932 346.578 507.118 346.88C507.312 347.182 507.405 347.531 507.405 347.919H506.436C506.436 347.539 506.32 347.252 506.08 347.058C505.84 346.864 505.529 346.764 505.15 346.764H505.033C504.661 346.764 504.32 346.872 504.026 347.097C503.723 347.314 503.491 347.624 503.32 348.019C503.15 348.407 503.065 348.849 503.065 349.337C503.065 349.864 503.204 350.283 503.475 350.593C503.747 350.903 504.126 351.058 504.615 351.058H504.716C505.08 351.058 505.398 350.949 505.661 350.74C505.925 350.531 506.111 350.252 506.212 349.903H507.18C507.049 350.515 506.746 350.996 506.274 351.345C505.801 351.701 505.243 351.872 504.615 351.872L504.607 351.88Z"
          fill="black"
        />
        <path
          d="M510.746 351.88C510.266 351.88 509.839 351.771 509.475 351.562C509.103 351.345 508.816 351.042 508.614 350.647C508.413 350.252 508.304 349.787 508.304 349.244C508.304 348.616 508.436 348.058 508.7 347.554C508.963 347.05 509.32 346.655 509.785 346.376C510.242 346.089 510.754 345.95 511.32 345.95C511.746 345.95 512.126 346.035 512.459 346.213C512.792 346.392 513.056 346.647 513.25 346.988C513.444 347.329 513.529 347.74 513.521 348.213C513.521 348.314 513.513 348.438 513.49 348.593C513.467 348.748 513.436 348.903 513.397 349.058H509.242C509.219 349.43 509.266 349.771 509.382 350.081C509.498 350.391 509.676 350.632 509.924 350.802C510.165 350.98 510.452 351.066 510.769 351.066H510.886C511.242 351.066 511.545 350.98 511.8 350.81C512.056 350.639 512.234 350.407 512.351 350.105H513.32C513.157 350.655 512.862 351.089 512.428 351.407C511.994 351.725 511.436 351.887 510.754 351.887L510.746 351.88ZM512.56 348.26C512.622 347.802 512.537 347.438 512.304 347.167C512.072 346.895 511.731 346.764 511.273 346.764H511.172C510.746 346.764 510.374 346.895 510.056 347.167C509.738 347.438 509.506 347.802 509.374 348.26H512.568H512.56Z"
          fill="black"
        />
        <path
          d="M521.055 351.88C520.389 351.88 519.792 351.74 519.273 351.461C518.753 351.182 518.358 350.794 518.071 350.291C517.792 349.787 517.653 349.205 517.653 348.531C517.653 347.632 517.823 346.81 518.164 346.074C518.505 345.33 519.001 344.74 519.637 344.306C520.273 343.872 521.024 343.655 521.885 343.655C522.427 343.655 522.916 343.756 523.342 343.973C523.776 344.182 524.117 344.485 524.373 344.88C524.629 345.275 524.761 345.733 524.776 346.26H523.745C523.745 345.748 523.559 345.345 523.21 345.043C522.862 344.74 522.396 344.593 521.815 344.593H521.699C521.141 344.593 520.629 344.756 520.172 345.082C519.707 345.407 519.342 345.857 519.071 346.438C518.8 347.019 518.668 347.671 518.668 348.399C518.668 348.926 518.761 349.376 518.939 349.756C519.125 350.136 519.397 350.43 519.761 350.632C520.125 350.841 520.567 350.942 521.086 350.942H521.203C521.544 350.942 521.877 350.88 522.187 350.756C522.497 350.632 522.776 350.446 522.993 350.198C523.218 349.949 523.365 349.639 523.435 349.275H524.466C524.373 349.81 524.172 350.267 523.869 350.655C523.559 351.042 523.164 351.345 522.683 351.554C522.203 351.763 521.652 351.872 521.048 351.872L521.055 351.88Z"
          fill="black"
        />
        <path
          d="M525.218 351.771L526.427 346.066H527.396L527.156 347.182C527.35 346.795 527.605 346.5 527.908 346.306C528.21 346.112 528.59 346.012 529.039 346.012H529.473L529.28 346.957H528.861C528.357 346.957 527.923 347.128 527.559 347.469C527.194 347.81 526.931 348.337 526.768 349.05L526.187 351.779H525.218V351.771Z"
          fill="black"
        />
        <path
          d="M531.969 351.88C531.489 351.88 531.062 351.771 530.698 351.562C530.326 351.345 530.039 351.042 529.838 350.647C529.636 350.252 529.528 349.787 529.528 349.244C529.528 348.616 529.659 348.058 529.923 347.554C530.187 347.05 530.543 346.655 531.008 346.376C531.466 346.089 531.977 345.95 532.543 345.95C532.969 345.95 533.349 346.035 533.682 346.213C534.016 346.392 534.279 346.647 534.473 346.988C534.667 347.329 534.752 347.74 534.744 348.213C534.744 348.314 534.737 348.438 534.713 348.593C534.69 348.748 534.659 348.903 534.62 349.058H530.466C530.442 349.43 530.489 349.771 530.605 350.081C530.721 350.391 530.9 350.632 531.148 350.802C531.388 350.98 531.675 351.066 531.993 351.066H532.109C532.465 351.066 532.768 350.98 533.024 350.81C533.279 350.639 533.458 350.407 533.574 350.105H534.543C534.38 350.655 534.086 351.089 533.651 351.407C533.217 351.725 532.659 351.887 531.977 351.887L531.969 351.88ZM533.783 348.26C533.845 347.802 533.76 347.438 533.527 347.167C533.295 346.895 532.954 346.764 532.496 346.764H532.396C531.969 346.764 531.597 346.895 531.28 347.167C530.962 347.438 530.729 347.802 530.597 348.26H533.791H533.783Z"
          fill="black"
        />
        <path
          d="M537.248 351.88C536.938 351.88 536.651 351.818 536.388 351.686C536.124 351.554 535.915 351.368 535.752 351.12C535.589 350.872 535.512 350.585 535.512 350.244C535.512 349.88 535.605 349.547 535.806 349.252C536 348.957 536.279 348.717 536.643 348.547C537.008 348.376 537.434 348.291 537.907 348.299H539.666L539.713 348.074C539.775 347.795 539.752 347.554 539.643 347.353C539.535 347.151 539.38 347.004 539.17 346.903C538.961 346.802 538.736 346.756 538.488 346.756H538.418C538.085 346.756 537.791 346.841 537.543 347.004C537.295 347.175 537.132 347.399 537.078 347.678H536.109C536.186 347.167 536.442 346.748 536.891 346.423C537.341 346.097 537.884 345.934 538.543 345.934C538.969 345.934 539.356 346.027 539.713 346.206C540.07 346.384 540.325 346.655 540.488 347.02C540.651 347.384 540.674 347.833 540.566 348.368L540.108 350.5C540.054 350.748 540.015 350.957 539.984 351.128C539.961 351.236 539.938 351.337 539.914 351.438C539.891 351.539 539.868 351.647 539.845 351.756H538.93C538.946 351.686 538.961 351.616 538.984 351.554C539 351.484 539.015 351.415 539.023 351.345C539.039 351.275 539.054 351.213 539.062 351.143C539.07 351.081 539.085 351.012 539.101 350.942C538.868 351.267 538.589 351.508 538.271 351.647C537.953 351.794 537.612 351.864 537.248 351.864V351.88ZM537.512 351.058C537.767 351.058 538.031 350.988 538.302 350.857C538.574 350.725 538.806 350.539 539.008 350.298C539.209 350.058 539.333 349.787 539.395 349.485L539.488 349.081H537.946C537.496 349.081 537.132 349.182 536.86 349.384C536.589 349.585 536.45 349.857 536.45 350.19C536.45 350.446 536.535 350.655 536.713 350.818C536.891 350.981 537.116 351.058 537.395 351.058H537.512Z"
          fill="black"
        />
        <path
          d="M541.535 351.771L542.744 346.066H543.713L543.527 346.942C543.721 346.655 543.969 346.415 544.286 346.229C544.604 346.043 544.961 345.95 545.356 345.95C545.751 345.95 546.116 346.058 546.41 346.283C546.705 346.508 546.899 346.802 546.984 347.182C547.216 346.81 547.526 346.508 547.906 346.283C548.286 346.058 548.681 345.95 549.1 345.95C549.519 345.95 549.852 346.043 550.146 346.244C550.441 346.438 550.65 346.717 550.774 347.081C550.898 347.446 550.906 347.88 550.805 348.368L550.077 351.771H549.115L549.844 348.298C549.945 347.849 549.898 347.485 549.705 347.198C549.511 346.911 549.224 346.771 548.836 346.771H548.736C548.464 346.771 548.201 346.849 547.96 347.012C547.72 347.174 547.503 347.391 547.325 347.671C547.147 347.95 547.015 348.267 546.945 348.616L546.286 351.763H545.317L546.046 348.337C546.147 347.88 546.1 347.508 545.914 347.205C545.728 346.911 545.441 346.764 545.054 346.764H544.937C544.674 346.764 544.418 346.849 544.17 347.012C543.922 347.174 543.705 347.415 543.527 347.725C543.348 348.035 543.209 348.391 543.124 348.795L542.496 351.763H541.527L541.535 351.771Z"
          fill="black"
        />
        <path
          d="M557.332 351.88C556.813 351.88 556.371 351.771 555.999 351.562C555.627 351.345 555.34 351.042 555.146 350.655C554.952 350.259 554.851 349.81 554.851 349.291C554.851 348.678 554.983 348.112 555.247 347.593C555.51 347.081 555.867 346.678 556.324 346.384C556.782 346.089 557.293 345.942 557.851 345.942C558.371 345.942 558.812 346.05 559.184 346.26C559.557 346.469 559.843 346.779 560.037 347.167C560.231 347.562 560.332 348.012 560.332 348.531C560.332 349.151 560.2 349.725 559.929 350.228C559.665 350.74 559.301 351.143 558.836 351.43C558.371 351.717 557.867 351.872 557.309 351.872L557.332 351.88ZM557.433 351.058C557.743 351.058 558.053 350.957 558.355 350.763C558.657 350.57 558.898 350.275 559.091 349.903C559.285 349.523 559.378 349.081 559.378 348.57C559.378 348.229 559.316 347.919 559.192 347.647C559.068 347.376 558.898 347.159 558.665 347.004C558.44 346.849 558.169 346.771 557.867 346.771H557.75C557.433 346.771 557.123 346.872 556.828 347.074C556.533 347.275 556.285 347.562 556.099 347.942C555.906 348.322 555.813 348.756 555.813 349.26C555.813 349.601 555.875 349.911 555.999 350.174C556.123 350.446 556.293 350.663 556.518 350.818C556.743 350.98 557.014 351.058 557.324 351.058H557.44H557.433Z"
          fill="black"
        />
        <path
          d="M561.51 351.771L562.549 346.895H561.308L561.479 346.066H562.735L562.983 344.857C563.068 344.5 563.207 344.229 563.401 344.051C563.595 343.872 563.866 343.779 564.207 343.779H565.928L565.742 344.624H564.409C564.254 344.624 564.145 344.663 564.076 344.733C564.006 344.803 563.944 344.927 563.913 345.089L563.703 346.058H564.982L564.812 346.888H563.525L562.487 351.763H561.518L561.51 351.771Z"
          fill="black"
        />
        <path
          d="M571.594 351.88C570.928 351.88 570.331 351.74 569.812 351.461C569.292 351.182 568.897 350.794 568.61 350.291C568.331 349.787 568.192 349.205 568.192 348.531C568.192 347.632 568.362 346.81 568.703 346.074C569.044 345.33 569.54 344.74 570.176 344.306C570.812 343.872 571.563 343.655 572.424 343.655C572.966 343.655 573.455 343.756 573.881 343.973C574.315 344.182 574.656 344.485 574.912 344.88C575.168 345.275 575.3 345.733 575.315 346.26H574.284C574.284 345.748 574.098 345.345 573.749 345.043C573.401 344.74 572.935 344.593 572.354 344.593H572.238C571.68 344.593 571.168 344.756 570.711 345.082C570.246 345.407 569.881 345.857 569.61 346.438C569.339 347.019 569.207 347.671 569.207 348.399C569.207 348.926 569.3 349.376 569.478 349.756C569.664 350.136 569.936 350.43 570.3 350.632C570.664 350.841 571.106 350.942 571.625 350.942H571.742C572.083 350.942 572.416 350.88 572.726 350.756C573.036 350.632 573.315 350.446 573.532 350.198C573.757 349.949 573.904 349.639 573.974 349.275H575.005C574.912 349.81 574.71 350.267 574.408 350.655C574.098 351.042 573.703 351.345 573.222 351.554C572.742 351.763 572.191 351.872 571.587 351.872L571.594 351.88Z"
          fill="black"
        />
        <path
          d="M575.765 351.771L577.454 343.779H578.423L577.78 346.864C577.989 346.57 578.253 346.345 578.571 346.182C578.881 346.027 579.214 345.95 579.578 345.95C579.958 345.95 580.299 346.043 580.594 346.244C580.888 346.438 581.105 346.717 581.229 347.089C581.361 347.461 581.369 347.888 581.26 348.384L580.54 351.771H579.571L580.299 348.384C580.369 348.074 580.377 347.795 580.322 347.547C580.268 347.306 580.16 347.112 579.989 346.973C579.819 346.833 579.602 346.764 579.346 346.764H579.245C578.958 346.764 578.687 346.841 578.439 346.988C578.191 347.136 577.974 347.36 577.788 347.671C577.602 347.973 577.462 348.353 577.361 348.802L576.734 351.771H575.765Z"
          fill="black"
        />
        <path
          d="M584.966 351.88C584.446 351.88 584.004 351.771 583.632 351.562C583.26 351.345 582.973 351.042 582.78 350.655C582.586 350.259 582.485 349.81 582.485 349.291C582.485 348.678 582.617 348.112 582.88 347.593C583.144 347.081 583.501 346.678 583.958 346.384C584.415 346.089 584.927 345.942 585.485 345.942C586.004 345.942 586.446 346.05 586.818 346.26C587.19 346.469 587.477 346.779 587.671 347.167C587.865 347.562 587.965 348.012 587.965 348.531C587.965 349.151 587.834 349.725 587.562 350.228C587.299 350.74 586.934 351.143 586.469 351.43C586.004 351.717 585.5 351.872 584.942 351.872L584.966 351.88ZM585.066 351.058C585.376 351.058 585.686 350.957 585.989 350.763C586.291 350.57 586.531 350.275 586.725 349.903C586.919 349.523 587.012 349.081 587.012 348.57C587.012 348.229 586.95 347.919 586.826 347.647C586.702 347.376 586.531 347.159 586.299 347.004C586.074 346.849 585.803 346.771 585.5 346.771H585.384C585.066 346.771 584.756 346.872 584.462 347.074C584.167 347.275 583.919 347.562 583.733 347.942C583.539 348.322 583.446 348.756 583.446 349.26C583.446 349.601 583.508 349.911 583.632 350.174C583.756 350.446 583.927 350.663 584.152 350.818C584.376 350.98 584.648 351.058 584.958 351.058H585.074H585.066Z"
          fill="black"
        />
        <path
          d="M588.764 351.771L589.973 346.066H590.942L589.733 351.771H588.764ZM590.112 345.035L590.353 343.872H591.438L591.198 345.035H590.112Z"
          fill="black"
        />
        <path
          d="M594.252 351.88C593.756 351.88 593.322 351.787 592.942 351.585C592.562 351.391 592.267 351.104 592.05 350.732C591.833 350.36 591.725 349.911 591.725 349.399C591.725 348.709 591.864 348.097 592.136 347.57C592.407 347.043 592.779 346.64 593.252 346.36C593.725 346.081 594.236 345.942 594.81 345.942C595.244 345.942 595.631 346.019 595.972 346.182C596.314 346.345 596.577 346.578 596.763 346.88C596.957 347.182 597.05 347.531 597.05 347.919H596.081C596.081 347.539 595.965 347.252 595.724 347.058C595.484 346.864 595.174 346.764 594.794 346.764H594.678C594.306 346.764 593.965 346.872 593.67 347.097C593.368 347.314 593.135 347.624 592.965 348.019C592.794 348.407 592.709 348.849 592.709 349.337C592.709 349.864 592.849 350.283 593.12 350.593C593.391 350.903 593.771 351.058 594.259 351.058H594.36C594.725 351.058 595.042 350.949 595.306 350.74C595.569 350.531 595.755 350.252 595.856 349.903H596.825C596.693 350.515 596.391 350.996 595.918 351.345C595.445 351.701 594.887 351.872 594.259 351.872L594.252 351.88Z"
          fill="black"
        />
        <path
          d="M600.399 351.88C599.918 351.88 599.492 351.771 599.127 351.562C598.755 351.345 598.468 351.042 598.267 350.647C598.065 350.252 597.957 349.787 597.957 349.244C597.957 348.616 598.089 348.058 598.352 347.554C598.616 347.05 598.972 346.655 599.437 346.376C599.895 346.089 600.406 345.95 600.972 345.95C601.398 345.95 601.778 346.035 602.112 346.213C602.445 346.392 602.708 346.647 602.902 346.988C603.096 347.329 603.181 347.74 603.174 348.213C603.174 348.314 603.166 348.438 603.143 348.593C603.119 348.748 603.088 348.903 603.05 349.058H598.895C598.872 349.43 598.918 349.771 599.034 350.081C599.151 350.391 599.329 350.632 599.577 350.802C599.817 350.98 600.104 351.066 600.422 351.066H600.538C600.895 351.066 601.197 350.98 601.453 350.81C601.709 350.639 601.887 350.407 602.003 350.105H602.972C602.809 350.655 602.515 351.089 602.081 351.407C601.647 351.725 601.088 351.887 600.406 351.887L600.399 351.88ZM602.212 348.26C602.274 347.802 602.189 347.438 601.957 347.167C601.724 346.895 601.383 346.764 600.926 346.764H600.825C600.399 346.764 600.026 346.895 599.709 347.167C599.391 347.438 599.158 347.802 599.027 348.26H602.22H602.212Z"
          fill="black"
        />
        <path
          d="M703.267 692.101C736.402 692.101 763.263 665.24 763.263 632.104C763.263 598.969 736.402 572.108 703.267 572.108C670.132 572.108 643.271 598.969 643.271 632.104C643.271 665.24 670.132 692.101 703.267 692.101Z"
          fill="#98ABEB"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M703.267 205.926C736.402 205.926 763.263 179.065 763.263 145.93C763.263 112.795 736.402 85.9331 703.267 85.9331C670.132 85.9331 643.271 112.795 643.271 145.93C643.271 179.065 670.132 205.926 703.267 205.926Z"
          fill="#694C4B"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M452.789 642.724C485.924 642.724 512.785 615.862 512.785 582.727C512.785 549.592 485.924 522.73 452.789 522.73C419.654 522.73 392.793 549.592 392.793 582.727C392.793 615.862 419.654 642.724 452.789 642.724Z"
          fill="#FF7C23"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M448.355 553.411C448.324 552.744 448.44 552.147 448.696 551.612C448.952 551.085 449.324 550.667 449.812 550.364C450.301 550.062 450.882 549.899 451.549 549.868C452.448 549.83 453.27 549.969 454.029 550.279C454.781 550.589 455.393 551.054 455.851 551.675C456.308 552.295 456.556 553.031 456.595 553.891C456.618 554.434 456.533 554.922 456.339 555.364C456.145 555.806 455.859 556.163 455.479 556.434C455.091 556.705 454.642 556.86 454.114 556.899L454.068 555.876C454.58 555.845 454.975 555.651 455.262 555.287C455.549 554.922 455.68 554.457 455.649 553.876V553.76C455.618 553.202 455.44 552.705 455.099 552.248C454.758 551.799 454.285 551.45 453.696 551.202C453.099 550.954 452.448 550.853 451.719 550.876C451.192 550.899 450.75 551.008 450.371 551.209C449.999 551.403 449.719 551.69 449.526 552.07C449.332 552.442 449.254 552.891 449.27 553.403V553.519C449.285 553.86 449.363 554.186 449.495 554.496C449.626 554.806 449.828 555.07 450.084 555.279C450.347 555.496 450.657 555.628 451.022 555.682L451.068 556.705C450.533 556.635 450.061 556.457 449.665 556.163C449.262 555.868 448.944 555.488 448.712 555.015C448.479 554.543 448.347 554 448.324 553.395L448.355 553.411Z"
          fill="black"
        />
        <path
          d="M448.603 559.31C448.588 559 448.642 558.705 448.758 558.441C448.875 558.178 449.053 557.953 449.293 557.783C449.533 557.612 449.82 557.519 450.154 557.504C450.518 557.488 450.851 557.573 451.154 557.752C451.456 557.93 451.704 558.201 451.89 558.558C452.076 558.914 452.177 559.333 452.192 559.813L452.277 561.565L452.495 561.604C452.774 561.651 453.014 561.62 453.208 561.503C453.401 561.387 453.541 561.224 453.634 561.015C453.727 560.798 453.766 560.573 453.75 560.333V560.263C453.735 559.93 453.634 559.643 453.456 559.395C453.277 559.155 453.045 559.007 452.766 558.953L452.727 557.984C453.239 558.038 453.665 558.279 454.014 558.713C454.363 559.147 454.549 559.682 454.572 560.341C454.587 560.767 454.518 561.162 454.355 561.519C454.192 561.883 453.929 562.147 453.572 562.325C453.215 562.503 452.766 562.55 452.231 562.457L450.076 562.092C449.82 562.054 449.611 562.015 449.44 561.992C449.332 561.976 449.231 561.953 449.123 561.937C449.022 561.922 448.913 561.899 448.805 561.883L448.766 560.968C448.836 560.984 448.906 560.992 448.975 561.007C449.045 561.023 449.115 561.03 449.185 561.038C449.254 561.054 449.324 561.061 449.386 561.069C449.448 561.077 449.518 561.092 449.588 561.1C449.247 560.875 449.006 560.612 448.844 560.302C448.689 559.992 448.596 559.658 448.58 559.286L448.603 559.31ZM449.433 559.534C449.44 559.79 449.518 560.054 449.665 560.317C449.813 560.581 450.006 560.806 450.254 560.992C450.502 561.178 450.781 561.294 451.084 561.348L451.487 561.426L451.425 559.883C451.409 559.434 451.293 559.077 451.076 558.814C450.867 558.55 450.588 558.426 450.254 558.434C449.999 558.441 449.789 558.542 449.642 558.72C449.487 558.907 449.417 559.131 449.433 559.418V559.534Z"
          fill="black"
        />
        <path
          d="M448.898 563.581L454.649 564.55L454.688 565.519L453.882 565.395C454.184 565.596 454.425 565.844 454.595 566.154C454.766 566.457 454.859 566.798 454.874 567.162C454.89 567.542 454.804 567.883 454.626 568.185C454.448 568.488 454.177 568.705 453.812 568.852C453.456 568.999 453.022 569.03 452.518 568.945L449.099 568.371L449.061 567.402L452.479 567.991C452.797 568.038 453.068 568.03 453.316 567.976C453.556 567.914 453.742 567.798 453.874 567.619C454.006 567.441 454.068 567.224 454.053 566.968V566.852C454.037 566.573 453.952 566.309 453.789 566.069C453.626 565.829 453.394 565.619 453.076 565.449C452.758 565.278 452.378 565.147 451.936 565.069L448.944 564.565L448.906 563.596L448.898 563.581Z"
          fill="black"
        />
        <path
          d="M447.099 569.278L447.96 569.425L448.022 570.859C448.022 570.968 448.045 571.053 448.084 571.131C448.123 571.208 448.177 571.263 448.262 571.309C448.347 571.356 448.456 571.386 448.603 571.417L449.882 571.642H449.084L454.882 570.139L454.928 571.154L450.247 572.355L450.285 571.968L455.076 574.805L455.122 575.89L449.115 572.324L449.859 572.604L448.309 572.34C447.952 572.278 447.673 572.146 447.487 571.945C447.301 571.743 447.2 571.487 447.185 571.177L447.107 569.286L447.099 569.278Z"
          fill="black"
        />
        <path
          d="M449.409 578.533C449.386 578.014 449.479 577.564 449.673 577.185C449.867 576.805 450.161 576.51 450.541 576.293C450.921 576.084 451.378 575.968 451.89 575.944C452.502 575.921 453.076 576.022 453.603 576.27C454.13 576.51 454.549 576.851 454.859 577.301C455.169 577.743 455.339 578.247 455.362 578.812C455.386 579.332 455.293 579.781 455.099 580.161C454.905 580.541 454.611 580.843 454.231 581.053C453.851 581.262 453.401 581.386 452.882 581.409C452.262 581.432 451.688 581.324 451.169 581.084C450.65 580.836 450.231 580.495 449.921 580.045C449.611 579.595 449.44 579.099 449.417 578.541L449.409 578.533ZM450.239 578.603C450.254 578.913 450.363 579.216 450.572 579.51C450.781 579.805 451.084 580.037 451.463 580.208C451.851 580.378 452.293 580.456 452.804 580.433C453.146 580.417 453.456 580.347 453.719 580.216C453.99 580.084 454.192 579.898 454.339 579.665C454.487 579.433 454.556 579.161 454.541 578.859V578.743C454.525 578.425 454.409 578.123 454.192 577.836C453.975 577.549 453.68 577.316 453.293 577.146C452.905 576.968 452.463 576.898 451.967 576.913C451.626 576.929 451.324 576.999 451.06 577.13C450.797 577.262 450.588 577.448 450.44 577.681C450.293 577.913 450.223 578.185 450.231 578.495V578.611L450.239 578.603Z"
          fill="black"
        />
        <path
          d="M449.681 582.332L455.432 583.301L455.471 584.27L454.665 584.146C454.967 584.347 455.207 584.595 455.378 584.905C455.548 585.208 455.641 585.549 455.657 585.913C455.672 586.293 455.587 586.634 455.409 586.936C455.231 587.238 454.959 587.456 454.595 587.603C454.238 587.75 453.804 587.781 453.301 587.696L449.882 587.122L449.843 586.153L453.262 586.742C453.58 586.789 453.851 586.781 454.099 586.727C454.339 586.665 454.525 586.549 454.657 586.37C454.789 586.192 454.851 585.975 454.835 585.719V585.603C454.82 585.324 454.735 585.06 454.572 584.82C454.409 584.58 454.176 584.37 453.859 584.2C453.541 584.029 453.161 583.898 452.719 583.82L449.727 583.316L449.688 582.347L449.681 582.332Z"
          fill="black"
        />
        <path
          d="M450.068 594.161C450.053 593.727 450.13 593.331 450.316 592.975C450.502 592.618 450.774 592.331 451.146 592.114C451.518 591.897 451.967 591.781 452.502 591.758C453.153 591.727 453.75 591.835 454.277 592.075C454.804 592.316 455.223 592.649 455.533 593.075C455.843 593.502 456.006 593.982 456.029 594.509C456.045 594.959 455.959 595.37 455.758 595.734C455.564 596.106 455.293 596.37 454.959 596.54L458.308 597.098L458.347 598.098L450.293 596.711L450.254 595.75L450.96 595.866C450.727 595.726 450.526 595.494 450.355 595.168C450.185 594.843 450.092 594.502 450.076 594.145L450.068 594.161ZM450.913 594.354C450.929 594.75 451.068 595.091 451.324 595.385C451.58 595.68 451.913 595.897 452.316 596.052C452.719 596.207 453.153 596.269 453.611 596.246C453.936 596.23 454.223 596.153 454.463 596.013C454.704 595.866 454.89 595.68 455.021 595.447C455.153 595.215 455.215 594.959 455.2 594.672V594.556C455.184 594.215 455.06 593.905 454.82 593.626C454.587 593.347 454.277 593.122 453.89 592.959C453.502 592.796 453.076 592.727 452.611 592.742C452.084 592.765 451.665 592.913 451.355 593.192C451.045 593.471 450.89 593.82 450.905 594.246V594.347L450.913 594.354Z"
          fill="black"
        />
        <path
          d="M450.363 598.478L456.114 599.447L456.153 600.416L450.402 599.447L450.363 598.478ZM457.145 599.54L458.316 599.734L458.362 600.819L457.192 600.625L457.145 599.54Z"
          fill="black"
        />
        <path
          d="M448.061 603.315C448.045 602.912 448.092 602.532 448.208 602.183C448.324 601.835 448.502 601.54 448.751 601.307C448.999 601.075 449.316 600.935 449.696 600.881L449.735 601.85C449.456 601.912 449.239 602.083 449.092 602.346C448.937 602.61 448.867 602.935 448.882 603.315V603.416C448.906 603.935 449.076 604.346 449.394 604.648C449.712 604.951 450.154 605.152 450.712 605.253L451.425 605.385C451.177 605.199 450.975 604.958 450.82 604.664C450.673 604.369 450.588 604.051 450.572 603.695C450.557 603.315 450.626 602.951 450.781 602.61C450.936 602.261 451.192 601.974 451.557 601.742C451.913 601.509 452.378 601.377 452.952 601.354C453.611 601.323 454.2 601.431 454.719 601.672C455.238 601.904 455.642 602.238 455.936 602.648C456.231 603.059 456.386 603.517 456.409 604.02C456.432 604.517 456.331 604.935 456.107 605.284C455.882 605.633 455.603 605.889 455.269 606.044L456.386 606.23L456.424 607.199L450.51 606.191C450.03 606.113 449.603 605.958 449.247 605.726C448.89 605.493 448.603 605.175 448.394 604.788C448.185 604.393 448.068 603.912 448.045 603.338L448.061 603.315ZM451.409 603.896C451.425 604.292 451.557 604.633 451.797 604.912C452.037 605.191 452.355 605.408 452.735 605.548C453.115 605.687 453.51 605.749 453.921 605.734C454.285 605.718 454.595 605.641 454.851 605.509C455.107 605.369 455.293 605.191 455.424 604.958C455.556 604.726 455.618 604.47 455.603 604.191V604.075C455.587 603.757 455.471 603.455 455.254 603.176C455.037 602.897 454.735 602.679 454.347 602.517C453.959 602.354 453.525 602.292 453.045 602.307C452.494 602.331 452.084 602.486 451.805 602.765C451.526 603.044 451.394 603.393 451.409 603.796V603.896Z"
          fill="black"
        />
        <path
          d="M450.882 610.78L456.634 611.749L456.673 612.718L450.921 611.749L450.882 610.78ZM457.665 611.842L458.835 612.036L458.882 613.121L457.711 612.927L457.665 611.842Z"
          fill="black"
        />
        <path
          d="M451.076 615.438C451.06 615.02 451.161 614.71 451.386 614.516C451.611 614.322 451.921 614.26 452.308 614.338L455.928 614.927L455.874 613.617L456.711 613.756L456.765 615.09L458.626 615.407L458.664 616.353L456.804 616.035L456.866 617.485L456.029 617.345L455.967 615.888L452.378 615.283C452.231 615.26 452.122 615.283 452.045 615.369C451.967 615.454 451.936 615.562 451.936 615.71L451.991 616.95L451.13 616.803L451.076 615.438Z"
          fill="black"
        />
        <path
          d="M186.576 635.802C186.583 634.988 186.793 634.243 187.204 633.585C187.607 632.926 188.203 632.422 188.986 632.065C190.444 631.391 192.009 631.817 192.699 633.251L191.761 633.67C191.335 632.755 190.374 632.461 189.312 632.934L189.211 632.98C188.707 633.205 188.312 633.569 188.025 634.057C187.738 634.546 187.583 635.112 187.568 635.747C187.552 636.391 187.7 637.042 187.994 637.693C188.638 639.119 189.824 639.662 191.242 639.026L191.343 638.98C192.281 638.577 193.002 637.623 192.707 636.546L193.645 636.127C194.048 637.701 193.265 639.142 191.583 639.894C189.762 640.739 187.901 640.065 187.111 638.236C186.746 637.414 186.568 636.608 186.576 635.786V635.802Z"
          fill="black"
        />
        <path
          d="M195.924 631.461C197.358 630.817 198.614 631.391 199.249 632.802C200.04 634.499 199.404 636.437 197.869 637.081C196.451 637.716 195.18 637.15 194.544 635.74C193.754 634.065 194.405 632.104 195.924 631.461ZM197.621 636.306C198.49 635.941 199.017 634.608 198.373 633.236C197.955 632.298 197.118 631.817 196.257 632.205L196.164 632.251C195.296 632.624 194.769 633.949 195.412 635.321C195.831 636.251 196.66 636.747 197.528 636.36L197.621 636.313V636.306Z"
          fill="black"
        />
        <path
          d="M202.815 628.43L203.024 629.376L202.637 629.546C201.714 629.957 201.288 630.879 201.582 632.306L202.171 635.026L201.288 635.422L200.055 629.724L200.931 629.329L201.171 630.453C201.234 629.569 201.598 628.965 202.412 628.6L202.807 628.422L202.815 628.43Z"
          fill="black"
        />
        <path
          d="M208.714 628.368L209.45 631.763L208.559 632.166L207.83 628.74C207.776 628.554 207.729 628.399 207.675 628.283C207.404 627.67 206.877 627.43 206.303 627.686L206.21 627.732C205.427 628.081 205.024 629.011 205.326 630.36L205.97 633.321L205.086 633.716L203.854 628.019L204.737 627.624L204.915 628.422C205.063 627.717 205.52 627.151 206.187 626.849C207.094 626.422 208.125 626.81 208.512 627.724C208.597 627.911 208.667 628.12 208.714 628.36V628.368Z"
          fill="black"
        />
        <path
          d="M218.434 627.74L217.55 628.135L216.015 620.989L213.791 621.988C213.512 622.112 213.45 622.291 213.52 622.624L213.729 623.593L214.892 623.074L215.085 623.903L213.915 624.43L214.954 629.298L214.078 629.693L213.031 624.826L211.899 625.329L211.706 624.5L212.845 623.988L212.589 622.787C212.427 622.058 212.636 621.585 213.248 621.306L216.729 619.748L218.442 627.732L218.434 627.74Z"
          fill="black"
        />
        <path
          d="M220.62 620.384C222.054 619.741 223.309 620.314 223.945 621.725C224.736 623.423 224.1 625.36 222.565 626.004C221.147 626.639 219.876 626.073 219.24 624.663C218.449 622.988 219.1 621.027 220.62 620.384ZM222.325 625.229C223.193 624.864 223.72 623.531 223.077 622.159C222.658 621.221 221.821 620.74 220.961 621.128L220.868 621.175C220 621.547 219.472 622.872 220.116 624.244C220.534 625.174 221.364 625.67 222.232 625.283L222.325 625.236V625.229Z"
          fill="black"
        />
        <path
          d="M231.642 615.562L231.255 621.988L230.224 622.454L228.061 618.407L227.875 623.5L226.844 623.965L224.015 618.981L224.883 618.593L227.108 622.725L227.278 617.516L228.247 617.082L230.487 621.198L230.666 615.997L231.634 615.562H231.642Z"
          fill="black"
        />
        <path
          d="M234.417 614.19C235.533 613.679 236.774 614.035 237.316 615.291C237.409 615.493 237.51 615.849 237.58 616.167L233.789 617.865C233.805 617.903 233.828 617.965 233.867 618.043C234.316 619.043 235.208 619.438 236.029 619.074L236.122 619.027C236.766 618.733 237.099 618.182 237.06 617.547L237.944 617.151C238.107 618.283 237.58 619.268 236.339 619.826C235.037 620.43 233.665 619.888 233.022 618.423C232.231 616.741 232.882 614.849 234.433 614.183L234.417 614.19ZM236.502 615.795C236.487 615.71 236.448 615.617 236.394 615.508C236.091 614.834 235.448 614.617 234.712 614.95L234.619 614.997C234.231 615.167 233.944 615.446 233.766 615.826C233.588 616.206 233.526 616.632 233.595 617.105L236.502 615.803V615.795Z"
          fill="black"
        />
        <path
          d="M241.045 611.284L241.254 612.229L240.866 612.4C239.944 612.811 239.518 613.733 239.812 615.159L240.401 617.88L239.518 618.275L238.285 612.578L239.161 612.183L239.401 613.307C239.463 612.423 239.828 611.818 240.642 611.454L241.037 611.276L241.045 611.284Z"
          fill="black"
        />
        <path
          d="M635.179 572.875C668.314 572.875 695.175 546.013 695.175 512.878C695.175 479.743 668.314 452.882 635.179 452.882C602.044 452.882 575.183 479.743 575.183 512.878C575.183 546.013 602.044 572.875 635.179 572.875Z"
          fill="#A9296C"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M609.444 545.094L604.577 538.528L605.7 537.249L611.964 541.334L611.832 541.435L609.088 533.42L610.22 532.133L615.087 538.699L614.405 539.466L609.785 533.242L609.909 533.141L612.723 541.365L611.832 542.373L605.421 538.218L605.514 538.086L610.119 544.311L609.437 545.078L609.444 545.094Z"
          fill="black"
        />
        <path
          d="M618.211 535.342C617.87 535.73 617.498 535.993 617.087 536.133C616.684 536.272 616.266 536.28 615.847 536.171C615.428 536.055 615.018 535.83 614.63 535.489C614.165 535.079 613.832 534.606 613.622 534.071C613.413 533.536 613.343 533.001 613.429 532.458C613.514 531.916 613.739 531.443 614.111 531.024C614.452 530.637 614.832 530.373 615.235 530.234C615.638 530.094 616.056 530.079 616.483 530.195C616.909 530.304 617.312 530.536 617.7 530.877C618.165 531.288 618.506 531.769 618.707 532.303C618.909 532.838 618.971 533.381 618.886 533.916C618.8 534.458 618.576 534.931 618.203 535.35L618.211 535.342ZM617.661 534.722C617.87 534.489 617.994 534.195 618.048 533.838C618.103 533.482 618.048 533.11 617.886 532.714C617.731 532.319 617.459 531.955 617.08 531.621C616.824 531.397 616.552 531.234 616.266 531.148C615.979 531.063 615.7 531.048 615.436 531.117C615.165 531.187 614.932 531.335 614.731 531.559L614.653 531.645C614.444 531.885 614.312 532.179 614.266 532.536C614.219 532.893 614.274 533.265 614.436 533.66C614.591 534.055 614.863 534.412 615.235 534.745C615.491 534.97 615.762 535.133 616.041 535.218C616.32 535.303 616.599 535.319 616.87 535.257C617.142 535.195 617.374 535.048 617.583 534.815L617.661 534.73V534.722Z"
          fill="black"
        />
        <path
          d="M622.529 530.443C622.188 530.831 621.816 531.094 621.405 531.234C620.994 531.373 620.583 531.381 620.165 531.273C619.746 531.156 619.335 530.931 618.948 530.59C618.483 530.18 618.149 529.707 617.94 529.172C617.731 528.637 617.661 528.102 617.746 527.56C617.831 527.025 618.056 526.544 618.428 526.126C618.769 525.738 619.149 525.474 619.552 525.335C619.955 525.195 620.374 525.18 620.8 525.296C621.227 525.405 621.63 525.637 622.017 525.978C622.482 526.389 622.823 526.87 623.025 527.405C623.234 527.939 623.288 528.482 623.211 529.017C623.126 529.559 622.901 530.032 622.529 530.451V530.443ZM621.978 529.823C622.188 529.59 622.312 529.296 622.366 528.939C622.42 528.583 622.366 528.211 622.211 527.815C622.056 527.42 621.785 527.056 621.405 526.722C621.149 526.498 620.878 526.335 620.591 526.25C620.304 526.164 620.025 526.149 619.761 526.219C619.49 526.288 619.258 526.436 619.056 526.66L618.979 526.746C618.769 526.986 618.638 527.281 618.591 527.637C618.544 527.994 618.599 528.366 618.762 528.761C618.924 529.156 619.188 529.513 619.56 529.846C619.816 530.071 620.087 530.234 620.366 530.319C620.645 530.404 620.924 530.42 621.196 530.358C621.467 530.296 621.699 530.149 621.909 529.916L621.986 529.831L621.978 529.823Z"
          fill="black"
        />
        <path
          d="M626.722 525.699C626.435 526.025 626.102 526.249 625.722 526.381C625.342 526.513 624.947 526.529 624.529 526.42C624.11 526.312 623.707 526.087 623.304 525.738C622.816 525.304 622.459 524.815 622.25 524.281C622.033 523.738 621.963 523.211 622.04 522.692C622.118 522.172 622.327 521.715 622.676 521.32C622.971 520.986 623.319 520.746 623.722 520.622C624.118 520.49 624.498 520.482 624.854 520.599L622.823 517.878L623.49 517.126L628.342 523.707L627.707 524.428L627.28 523.854C627.35 524.118 627.335 524.428 627.234 524.777C627.133 525.126 626.963 525.436 626.722 525.707V525.699ZM626.249 524.978C626.513 524.684 626.652 524.335 626.668 523.947C626.683 523.56 626.606 523.172 626.412 522.777C626.226 522.389 625.955 522.04 625.614 521.738C625.366 521.521 625.11 521.382 624.839 521.32C624.567 521.258 624.304 521.265 624.04 521.343C623.777 521.42 623.56 521.568 623.374 521.777L623.296 521.862C623.071 522.118 622.94 522.428 622.916 522.792C622.893 523.157 622.963 523.529 623.126 523.916C623.296 524.304 623.552 524.645 623.901 524.955C624.296 525.304 624.699 525.482 625.118 525.505C625.536 525.521 625.885 525.374 626.172 525.064L626.242 524.986L626.249 524.978Z"
          fill="black"
        />
        <path
          d="M630.606 524.195L630.086 523.49L631.04 522.413C631.109 522.335 631.156 522.25 631.179 522.172C631.203 522.095 631.203 522.009 631.179 521.916C631.148 521.823 631.094 521.723 631.009 521.606L630.241 520.56L630.823 521.11L625.598 518.188L626.273 517.428L630.482 519.8L630.179 520.056L628.691 514.692L629.412 513.878L631.28 520.614L630.939 519.901L631.877 521.172C632.094 521.467 632.202 521.754 632.195 522.025C632.195 522.296 632.086 522.552 631.877 522.785L630.621 524.203L630.606 524.195Z"
          fill="black"
        />
        <path
          d="M635.481 515.599L630.613 509.033L631.737 507.754L638 511.839L637.869 511.94L635.125 503.925L636.256 502.638L641.124 509.204L640.442 509.971L635.822 503.747L635.946 503.646L638.76 511.87L637.869 512.878L631.458 508.723L631.551 508.592L636.156 514.816L635.474 515.583L635.481 515.599Z"
          fill="black"
        />
        <path
          d="M643.628 506.545C643.419 506.778 643.186 506.948 642.915 507.065C642.644 507.173 642.365 507.212 642.07 507.165C641.775 507.119 641.512 506.987 641.256 506.77C640.985 506.53 640.799 506.235 640.706 505.894C640.613 505.553 640.62 505.189 640.737 504.801C640.853 504.414 641.07 504.042 641.388 503.693L642.543 502.367L642.411 502.189C642.24 501.956 642.047 501.817 641.83 501.763C641.613 501.708 641.396 501.732 641.179 501.825C640.969 501.918 640.775 502.049 640.62 502.235L640.574 502.29C640.349 502.538 640.225 502.817 640.179 503.111C640.132 503.406 640.202 503.677 640.372 503.91L639.729 504.638C639.396 504.243 639.256 503.77 639.303 503.22C639.349 502.67 639.597 502.15 640.024 501.662C640.303 501.344 640.628 501.111 641 500.964C641.365 500.817 641.744 500.801 642.124 500.925C642.504 501.049 642.861 501.321 643.186 501.763L644.481 503.514C644.636 503.724 644.767 503.894 644.868 504.026C644.93 504.111 645 504.197 645.054 504.282C645.116 504.367 645.178 504.452 645.248 504.538L644.643 505.22C644.605 505.166 644.566 505.103 644.527 505.049C644.488 504.995 644.442 504.933 644.395 504.886C644.357 504.832 644.31 504.778 644.271 504.724C644.233 504.669 644.194 504.623 644.147 504.561C644.24 504.956 644.233 505.321 644.132 505.646C644.031 505.979 643.861 506.282 643.62 506.553L643.628 506.545ZM643.186 505.801C643.357 505.607 643.481 505.367 643.558 505.072C643.636 504.778 643.651 504.483 643.605 504.173C643.558 503.863 643.442 503.592 643.248 503.344L643.008 503.011L641.985 504.166C641.69 504.499 641.527 504.84 641.496 505.181C641.465 505.522 641.574 505.801 641.83 506.018C642.023 506.189 642.24 506.266 642.473 506.235C642.706 506.212 642.923 506.088 643.109 505.879L643.186 505.793V505.801Z"
          fill="black"
        />
        <path
          d="M649.589 503.398C649.325 503.7 649.023 503.941 648.697 504.111C648.372 504.282 648.038 504.367 647.697 504.367C647.356 504.367 647.031 504.243 646.721 504.026L647.364 503.297C647.612 503.445 647.883 503.476 648.178 503.39C648.473 503.305 648.744 503.119 649 502.832L649.069 502.755C649.41 502.367 649.573 501.956 649.55 501.515C649.534 501.08 649.356 500.631 649.015 500.166L648.589 499.577C648.635 499.887 648.62 500.197 648.519 500.515C648.426 500.832 648.256 501.119 648.031 501.383C647.775 501.67 647.48 501.879 647.124 502.018C646.767 502.158 646.38 502.189 645.961 502.111C645.542 502.034 645.116 501.801 644.682 501.429C644.186 500.987 643.837 500.507 643.628 499.98C643.419 499.453 643.357 498.933 643.426 498.429C643.504 497.926 643.705 497.484 644.039 497.112C644.364 496.74 644.729 496.507 645.132 496.406C645.535 496.313 645.907 496.321 646.263 496.437L645.581 495.53L646.225 494.802L649.79 499.623C650.085 500.011 650.279 500.422 650.379 500.832C650.48 501.251 650.465 501.67 650.341 502.104C650.217 502.53 649.969 502.956 649.589 503.39V503.398ZM647.573 500.662C647.837 500.367 647.976 500.026 647.992 499.654C648.015 499.282 647.938 498.91 647.759 498.546C647.581 498.181 647.341 497.864 647.031 497.592C646.759 497.352 646.48 497.189 646.201 497.112C645.922 497.034 645.659 497.034 645.403 497.112C645.147 497.189 644.93 497.329 644.744 497.538L644.667 497.623C644.457 497.864 644.333 498.158 644.295 498.515C644.256 498.864 644.326 499.236 644.496 499.615C644.667 500.003 644.93 500.352 645.287 500.67C645.698 501.034 646.101 501.212 646.504 501.205C646.907 501.197 647.232 501.042 647.504 500.739L647.573 500.662Z"
          fill="black"
        />
        <path
          d="M652.728 496.244C652.41 496.6 652.054 496.848 651.643 496.988C651.24 497.119 650.821 497.135 650.387 497.026C649.953 496.918 649.535 496.685 649.131 496.329C648.666 495.918 648.325 495.445 648.124 494.918C647.922 494.391 647.868 493.856 647.953 493.321C648.046 492.786 648.279 492.306 648.651 491.887C648.93 491.569 649.248 491.337 649.604 491.205C649.953 491.073 650.325 491.042 650.705 491.128C651.085 491.213 651.449 491.414 651.806 491.732C651.883 491.794 651.968 491.887 652.061 492.003C652.162 492.12 652.255 492.244 652.356 492.383L649.604 495.499C649.868 495.763 650.155 495.949 650.465 496.065C650.775 496.182 651.077 496.205 651.364 496.135C651.658 496.065 651.906 495.918 652.123 495.678L652.201 495.592C652.441 495.321 652.573 495.042 652.612 494.74C652.651 494.437 652.596 494.151 652.449 493.864L653.092 493.135C653.395 493.616 653.526 494.127 653.48 494.662C653.433 495.197 653.185 495.724 652.728 496.236V496.244ZM651.217 492.492C650.914 492.143 650.589 491.965 650.232 491.965C649.876 491.965 649.55 492.127 649.248 492.469L649.178 492.546C648.899 492.864 648.752 493.236 648.744 493.654C648.736 494.073 648.86 494.484 649.108 494.887L651.224 492.492H651.217Z"
          fill="black"
        />
        <path
          d="M654.984 493.507L651.503 488.825L652.147 488.097L652.643 488.748C652.565 488.391 652.565 488.042 652.658 487.709C652.751 487.368 652.914 487.066 653.154 486.794C653.41 486.508 653.705 486.321 654.038 486.228C654.379 486.135 654.728 486.166 655.084 486.306C655.441 486.445 655.774 486.725 656.084 487.143L658.146 489.926L657.503 490.655L655.449 487.864C655.255 487.608 655.053 487.422 654.836 487.298C654.619 487.174 654.402 487.135 654.185 487.166C653.968 487.205 653.775 487.321 653.604 487.515L653.526 487.6C653.34 487.81 653.224 488.058 653.17 488.345C653.116 488.631 653.147 488.941 653.255 489.283C653.364 489.624 653.55 489.98 653.813 490.345L655.619 492.778L654.976 493.507H654.984Z"
          fill="black"
        />
        <path
          d="M660.464 487.298C660.185 487.608 659.898 487.763 659.604 487.748C659.309 487.732 659.045 487.562 658.813 487.236L656.612 484.298L655.743 485.283L655.232 484.601L656.115 483.601L654.991 482.074L655.619 481.361L656.743 482.888L657.704 481.802L658.216 482.485L657.247 483.578L659.418 486.5C659.503 486.616 659.604 486.67 659.72 486.663C659.836 486.663 659.937 486.601 660.03 486.492L660.851 485.562L661.371 486.267L660.464 487.298Z"
          fill="black"
        />
        <path
          d="M663.332 484.229C663.123 484.461 662.89 484.632 662.619 484.748C662.348 484.857 662.068 484.895 661.774 484.849C661.479 484.802 661.208 484.67 660.96 484.453C660.689 484.213 660.503 483.919 660.41 483.578C660.317 483.236 660.324 482.872 660.441 482.485C660.557 482.097 660.774 481.725 661.092 481.376L662.247 480.051L662.115 479.872C661.944 479.64 661.751 479.5 661.534 479.446C661.317 479.392 661.1 479.415 660.883 479.508C660.673 479.601 660.487 479.733 660.324 479.919L660.278 479.973C660.053 480.221 659.929 480.5 659.883 480.803C659.844 481.105 659.906 481.368 660.076 481.601L659.433 482.33C659.1 481.934 658.96 481.461 659.007 480.911C659.053 480.361 659.293 479.841 659.728 479.353C660.007 479.035 660.332 478.803 660.704 478.655C661.076 478.508 661.448 478.493 661.828 478.617C662.208 478.741 662.565 479.012 662.89 479.454L664.185 481.206C664.34 481.415 664.464 481.585 664.572 481.717C664.634 481.802 664.704 481.888 664.758 481.973C664.82 482.058 664.882 482.143 664.952 482.229L664.347 482.911C664.309 482.857 664.27 482.795 664.231 482.74C664.192 482.678 664.146 482.624 664.099 482.578C664.061 482.523 664.014 482.469 663.975 482.415C663.937 482.361 663.89 482.314 663.851 482.252C663.944 482.647 663.937 483.012 663.836 483.345C663.735 483.678 663.565 483.981 663.324 484.252L663.332 484.229ZM662.89 483.485C663.061 483.291 663.185 483.05 663.262 482.756C663.34 482.461 663.355 482.167 663.309 481.857C663.262 481.547 663.146 481.275 662.952 481.027L662.712 480.694L661.689 481.849C661.394 482.182 661.231 482.523 661.2 482.864C661.169 483.205 661.278 483.485 661.534 483.702C661.727 483.872 661.944 483.95 662.177 483.919C662.41 483.895 662.627 483.771 662.813 483.562L662.89 483.477V483.485Z"
          fill="black"
        />
        <path
          d="M447.022 471.563C447.022 462.765 456.262 455.866 459.432 448.215C462.719 440.286 461.184 428.906 467.16 422.93C473.136 416.953 484.515 418.488 492.445 415.202C500.096 412.031 506.994 402.792 515.792 402.792C524.59 402.792 531.489 412.031 539.139 415.202C547.069 418.488 558.448 416.953 564.424 422.93C570.401 428.906 568.866 440.286 572.152 448.215C575.323 455.866 584.562 462.765 584.562 471.563C584.562 480.361 575.323 487.26 572.152 494.91C568.866 502.84 570.401 514.219 564.424 520.196C558.448 526.172 547.069 524.637 539.139 527.924C531.489 531.094 524.59 540.334 515.792 540.334C506.994 540.334 500.096 531.094 492.445 527.924C484.515 524.637 473.136 526.172 467.16 520.196C461.184 514.219 462.719 502.84 459.432 494.91C456.262 487.26 447.022 480.361 447.022 471.563Z"
          fill="#A627DC"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M568.37 470.904L563.494 478.896H562.169L560.742 470.904H561.735L563.068 478.423L562.781 478.369L567.292 470.904H568.362H568.37ZM566.385 473.005L566.168 473.943H561.51L561.727 473.005H566.385Z"
          fill="black"
        />
        <path
          d="M559.781 470.904L558.084 478.896H557.115L558.812 470.904H559.781Z"
          fill="black"
        />
        <path
          d="M555.712 477.64L555.471 478.803H554.386L554.627 477.64H555.712ZM557.06 470.904L555.851 476.609H554.882L556.092 470.904H557.06Z"
          fill="black"
        />
        <path
          d="M551.65 470.795C552.131 470.795 552.557 470.904 552.921 471.113C553.293 471.33 553.58 471.633 553.782 472.028C553.983 472.423 554.092 472.888 554.092 473.431C554.092 474.059 553.96 474.617 553.696 475.121C553.433 475.625 553.076 476.02 552.611 476.299C552.154 476.586 551.642 476.725 551.076 476.725C550.65 476.725 550.27 476.64 549.937 476.462C549.604 476.283 549.34 476.028 549.146 475.687C548.953 475.345 548.867 474.935 548.875 474.462C548.875 474.361 548.883 474.237 548.906 474.082C548.929 473.927 548.96 473.772 548.999 473.617H553.154C553.177 473.245 553.131 472.904 553.014 472.594C552.898 472.284 552.72 472.043 552.472 471.873C552.231 471.695 551.945 471.609 551.627 471.609H551.51C551.154 471.609 550.852 471.695 550.596 471.865C550.34 472.036 550.162 472.268 550.045 472.57H549.077C549.239 472.02 549.534 471.586 549.968 471.268C550.402 470.95 550.96 470.788 551.642 470.788L551.65 470.795ZM549.836 474.415C549.774 474.873 549.859 475.237 550.092 475.508C550.325 475.78 550.666 475.911 551.123 475.911H551.224C551.65 475.911 552.022 475.78 552.34 475.508C552.658 475.237 552.89 474.873 553.022 474.415H549.828H549.836Z"
          fill="black"
        />
        <path
          d="M548.1 470.904L546.891 476.609H545.922L546.084 475.811C545.875 476.105 545.612 476.33 545.294 476.493C544.984 476.648 544.643 476.725 544.278 476.725C543.914 476.725 543.565 476.632 543.271 476.431C542.976 476.237 542.767 475.958 542.635 475.594C542.503 475.229 542.496 474.795 542.604 474.291L543.325 470.904H544.294L543.565 474.291C543.503 474.602 543.503 474.881 543.55 475.129C543.596 475.377 543.713 475.563 543.883 475.702C544.054 475.842 544.271 475.912 544.526 475.912H544.643C544.922 475.912 545.185 475.834 545.433 475.687C545.681 475.539 545.898 475.307 546.084 475.005C546.271 474.695 546.41 474.322 546.511 473.881L547.139 470.912H548.108L548.1 470.904Z"
          fill="black"
        />
        <path
          d="M537.449 477.64L537.209 478.803H536.124L536.364 477.64H537.449ZM538.798 470.904L537.589 476.609H536.62L537.829 470.904H538.798Z"
          fill="black"
        />
        <path
          d="M536.085 470.904L534.876 476.609H533.907L534.07 475.811C533.861 476.105 533.597 476.33 533.279 476.493C532.969 476.648 532.628 476.725 532.264 476.725C531.899 476.725 531.551 476.632 531.256 476.431C530.962 476.237 530.752 475.958 530.62 475.594C530.489 475.229 530.481 474.795 530.589 474.291L531.31 470.904H532.279L531.551 474.291C531.489 474.602 531.489 474.881 531.535 475.129C531.582 475.377 531.698 475.563 531.868 475.702C532.039 475.842 532.256 475.912 532.512 475.912H532.628C532.907 475.912 533.171 475.834 533.419 475.687C533.667 475.539 533.884 475.307 534.07 475.005C534.256 474.695 534.395 474.322 534.496 473.881L535.124 470.912H536.093L536.085 470.904Z"
          fill="black"
        />
        <path
          d="M528.357 470.904L529.28 476.609H528.287L527.512 471.633L527.737 471.664L524.885 476.609H523.846L527.21 470.904H528.349H528.357Z"
          fill="black"
        />
        <path
          d="M522.373 470.795C522.683 470.795 522.97 470.857 523.234 470.989C523.497 471.121 523.706 471.307 523.869 471.555C524.032 471.803 524.109 472.09 524.109 472.431C524.109 472.795 524.016 473.129 523.815 473.423C523.621 473.718 523.342 473.958 522.978 474.129C522.613 474.299 522.187 474.384 521.714 474.377H519.955L519.908 474.601C519.846 474.88 519.869 475.121 519.978 475.322C520.086 475.524 520.241 475.671 520.451 475.772C520.66 475.873 520.885 475.919 521.133 475.919H521.203C521.536 475.919 521.831 475.834 522.079 475.671C522.327 475.501 522.489 475.276 522.544 474.997H523.513C523.435 475.508 523.179 475.927 522.73 476.252C522.28 476.578 521.737 476.741 521.079 476.741C520.652 476.741 520.265 476.648 519.908 476.47C519.552 476.291 519.296 476.02 519.133 475.656C518.97 475.291 518.947 474.842 519.056 474.307L519.513 472.175C519.567 471.927 519.606 471.718 519.637 471.547C519.66 471.439 519.683 471.338 519.707 471.237C519.73 471.136 519.753 471.028 519.776 470.919H520.691C520.676 470.989 520.66 471.059 520.637 471.121C520.621 471.191 520.606 471.261 520.598 471.33C520.583 471.4 520.567 471.462 520.559 471.532C520.552 471.594 520.536 471.664 520.521 471.733C520.753 471.408 521.032 471.167 521.35 471.028C521.668 470.881 522.009 470.811 522.373 470.811V470.795ZM522.11 471.617C521.854 471.617 521.59 471.687 521.319 471.819C521.048 471.95 520.815 472.136 520.614 472.377C520.412 472.617 520.288 472.888 520.226 473.191L520.133 473.594H521.675C522.125 473.594 522.489 473.493 522.761 473.291C523.032 473.09 523.171 472.819 523.171 472.485C523.171 472.229 523.086 472.02 522.908 471.857C522.73 471.695 522.505 471.617 522.226 471.617H522.11Z"
          fill="black"
        />
        <path
          d="M515.56 470.795C515.994 470.795 516.389 470.896 516.738 471.09C517.087 471.292 517.366 471.578 517.559 471.958C517.761 472.338 517.854 472.795 517.854 473.33C517.854 473.981 517.722 474.57 517.459 475.09C517.195 475.609 516.846 476.012 516.405 476.299C515.963 476.586 515.482 476.733 514.955 476.733C514.505 476.733 514.102 476.625 513.738 476.415C513.374 476.206 513.118 475.927 512.971 475.578L512.273 478.904H511.265L512.986 470.912H513.947L513.8 471.609C513.955 471.385 514.195 471.191 514.529 471.036C514.862 470.881 515.203 470.803 515.56 470.803V470.795ZM515.335 471.633C514.94 471.633 514.591 471.757 514.281 471.997C513.978 472.237 513.738 472.563 513.575 472.958C513.405 473.353 513.327 473.788 513.327 474.245C513.327 474.57 513.397 474.857 513.529 475.105C513.661 475.353 513.839 475.547 514.071 475.687C514.296 475.826 514.552 475.896 514.839 475.896H514.955C515.296 475.896 515.614 475.787 515.901 475.563C516.187 475.346 516.42 475.043 516.606 474.663C516.792 474.284 516.877 473.857 516.877 473.4C516.877 472.873 516.746 472.454 516.482 472.129C516.218 471.803 515.877 471.64 515.451 471.633H515.35H515.335Z"
          fill="black"
        />
        <path
          d="M508.537 470.795C509.017 470.795 509.444 470.904 509.808 471.113C510.18 471.33 510.467 471.633 510.668 472.028C510.87 472.423 510.979 472.888 510.979 473.431C510.979 474.059 510.847 474.617 510.583 475.121C510.32 475.625 509.963 476.02 509.498 476.299C509.041 476.586 508.529 476.725 507.963 476.725C507.537 476.725 507.157 476.64 506.824 476.462C506.49 476.283 506.227 476.028 506.033 475.687C505.839 475.345 505.754 474.935 505.762 474.462C505.762 474.361 505.77 474.237 505.793 474.082C505.816 473.927 505.847 473.772 505.886 473.617H510.041C510.064 473.245 510.017 472.904 509.901 472.594C509.785 472.284 509.607 472.043 509.358 471.873C509.118 471.695 508.831 471.609 508.514 471.609H508.397C508.041 471.609 507.738 471.695 507.483 471.865C507.227 472.036 507.049 472.268 506.932 472.57H505.963C506.126 472.02 506.421 471.586 506.855 471.268C507.289 470.95 507.847 470.788 508.529 470.788L508.537 470.795ZM506.723 474.415C506.661 474.873 506.746 475.237 506.979 475.508C507.211 475.78 507.552 475.911 508.01 475.911H508.111C508.537 475.911 508.909 475.78 509.227 475.508C509.545 475.237 509.777 474.873 509.909 474.415H506.715H506.723Z"
          fill="black"
        />
        <path
          d="M504.987 470.904L503.777 476.609H502.809L503.049 475.493C502.855 475.88 502.599 476.175 502.297 476.369C501.995 476.563 501.615 476.663 501.165 476.663H500.731L500.925 475.718H501.344C501.847 475.718 502.282 475.547 502.646 475.206C503.01 474.865 503.274 474.338 503.436 473.625L504.018 470.896H504.987V470.904Z"
          fill="black"
        />
        <path
          d="M498.096 470.904L496.398 478.896H493.615C493.112 478.896 492.678 478.803 492.298 478.625C491.918 478.446 491.631 478.19 491.422 477.865C491.22 477.539 491.112 477.167 491.112 476.756C491.112 476.253 491.228 475.803 491.445 475.4C491.67 474.997 491.98 474.679 492.383 474.439C492.778 474.206 493.251 474.082 493.778 474.082H496.383L497.065 470.896H498.096V470.904ZM493.794 475.028C493.313 475.028 492.918 475.175 492.616 475.477C492.313 475.78 492.15 476.175 492.15 476.671C492.15 477.074 492.29 477.392 492.561 477.617C492.833 477.842 493.251 477.958 493.817 477.958H495.561L496.189 475.028H493.794Z"
          fill="black"
        />
        <path
          d="M488.895 470.795C489.337 470.795 489.701 470.896 489.988 471.098C490.275 471.299 490.476 471.578 490.585 471.943C490.693 472.307 490.693 472.718 490.592 473.191L489.872 476.617H488.887L489.631 473.136C489.732 472.695 489.693 472.33 489.523 472.043C489.352 471.757 489.081 471.617 488.717 471.617H488.6C488.314 471.617 488.042 471.702 487.786 471.865C487.531 472.036 487.314 472.268 487.128 472.571C486.942 472.873 486.81 473.229 486.724 473.64L486.097 476.609H485.128L486.337 470.904H487.306L487.12 471.772C487.337 471.454 487.585 471.206 487.872 471.043C488.159 470.881 488.5 470.795 488.879 470.795H488.895Z"
          fill="black"
        />
        <path
          d="M484.593 470.904L483.384 476.609H482.415L482.655 475.493C482.461 475.88 482.205 476.175 481.903 476.369C481.601 476.563 481.221 476.663 480.771 476.663H480.337L480.531 475.718H480.95C481.453 475.718 481.888 475.547 482.252 475.206C482.616 474.865 482.88 474.338 483.043 473.625L483.624 470.896H484.593V470.904Z"
          fill="black"
        />
        <path
          d="M480.926 468.625L479.229 476.617H478.26L478.423 475.865C478.229 476.136 477.973 476.353 477.679 476.508C477.376 476.663 477.043 476.733 476.679 476.733C476.237 476.733 475.841 476.625 475.493 476.415C475.144 476.206 474.865 475.904 474.671 475.516C474.477 475.129 474.376 474.687 474.376 474.191C474.376 473.532 474.501 472.943 474.741 472.431C474.981 471.919 475.322 471.524 475.764 471.229C476.206 470.935 476.686 470.795 477.221 470.795C477.663 470.795 478.066 470.904 478.438 471.113C478.81 471.323 479.074 471.617 479.244 471.989L479.973 468.633H480.942L480.926 468.625ZM477.237 471.625C476.903 471.625 476.586 471.741 476.299 471.958C476.004 472.175 475.772 472.478 475.601 472.857C475.431 473.237 475.345 473.663 475.345 474.121C475.345 474.648 475.477 475.074 475.748 475.4C476.02 475.725 476.369 475.888 476.803 475.888H476.903C477.299 475.888 477.648 475.764 477.95 475.516C478.252 475.268 478.477 474.942 478.632 474.547C478.787 474.144 478.865 473.718 478.865 473.253C478.865 472.935 478.803 472.648 478.671 472.408C478.539 472.167 478.361 471.974 478.128 471.834C477.896 471.695 477.64 471.625 477.345 471.617H477.229L477.237 471.625Z"
          fill="black"
        />
        <path
          d="M473.57 470.904L471.873 478.896H470.904L472.601 470.904H473.57Z"
          fill="black"
        />
        <path
          d="M468.152 470.795C468.633 470.795 469.059 470.904 469.423 471.113C469.795 471.33 470.082 471.633 470.284 472.028C470.485 472.423 470.594 472.888 470.594 473.431C470.594 474.059 470.462 474.617 470.199 475.121C469.935 475.625 469.578 476.02 469.113 476.299C468.656 476.586 468.144 476.725 467.579 476.725C467.152 476.725 466.772 476.64 466.439 476.462C466.106 476.283 465.842 476.028 465.648 475.687C465.455 475.345 465.369 474.935 465.377 474.462C465.377 474.361 465.385 474.237 465.408 474.082C465.431 473.927 465.462 473.772 465.501 473.617H469.656C469.679 473.245 469.633 472.904 469.516 472.594C469.4 472.284 469.222 472.043 468.974 471.873C468.734 471.695 468.447 471.609 468.129 471.609H468.013C467.656 471.609 467.354 471.695 467.098 471.865C466.842 472.036 466.664 472.268 466.548 472.57H465.579C465.742 472.02 466.036 471.586 466.47 471.268C466.904 470.95 467.462 470.788 468.144 470.788L468.152 470.795ZM466.338 474.415C466.276 474.873 466.362 475.237 466.594 475.508C466.827 475.78 467.168 475.911 467.625 475.911H467.726C468.152 475.911 468.524 475.78 468.842 475.508C469.16 475.237 469.392 474.873 469.524 474.415H466.331H466.338Z"
          fill="black"
        />
        <path
          d="M876.712 395.691C876.712 404.489 867.472 411.388 864.302 419.039C861.015 426.968 862.55 438.348 856.574 444.324C850.598 450.3 839.218 448.766 831.289 452.052C823.638 455.223 816.739 464.462 807.942 464.462C799.144 464.462 792.245 455.223 784.595 452.052C776.665 448.766 765.286 450.3 759.31 444.324C753.333 438.348 754.868 426.968 751.581 419.039C748.411 411.388 739.171 404.489 739.171 395.691C739.171 386.893 748.411 379.994 751.581 372.344C754.868 364.414 753.333 353.035 759.31 347.058C765.286 341.082 776.665 342.616 784.595 339.33C792.245 336.159 799.144 326.92 807.942 326.92C816.739 326.92 823.638 336.159 831.289 339.33C839.218 342.616 850.598 341.082 856.574 347.058C862.55 353.035 861.015 364.414 864.302 372.344C867.589 380.273 876.712 386.893 876.712 395.691Z"
          fill="#F1B290"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M771.355 415.132L770.092 407.063L771.681 406.458L775.339 412.977L775.177 413L776.456 404.629L778.052 404.016L779.316 412.086L778.355 412.45L777.153 404.799L777.308 404.76L775.991 413.357L774.735 413.838L770.983 407.187L771.13 407.117L772.324 414.768L771.363 415.132H771.355Z"
          fill="black"
        />
        <path
          d="M783.61 410.566C783.161 410.737 782.726 410.791 782.308 410.721C781.889 410.652 781.51 410.473 781.176 410.179C780.843 409.884 780.579 409.481 780.386 408.977C780.161 408.396 780.083 407.822 780.153 407.256C780.215 406.691 780.417 406.194 780.742 405.768C781.068 405.334 781.494 405.024 782.021 404.822C782.416 404.667 782.804 404.613 783.184 404.66C783.556 404.706 783.897 404.853 784.199 405.101C784.502 405.357 784.726 405.698 784.889 406.148C784.92 406.241 784.959 406.365 784.99 406.512C785.021 406.66 785.052 406.822 785.075 406.985L781.192 408.473C781.3 408.83 781.471 409.132 781.688 409.38C781.905 409.628 782.161 409.783 782.455 409.861C782.75 409.938 783.037 409.915 783.339 409.807L783.447 409.768C783.781 409.644 784.036 409.45 784.207 409.202C784.378 408.954 784.471 408.667 784.471 408.349L785.377 408.001C785.424 408.566 785.3 409.078 785.013 409.535C784.719 409.993 784.261 410.342 783.618 410.582L783.61 410.566ZM784.013 406.536C783.905 406.086 783.695 405.776 783.385 405.605C783.075 405.435 782.703 405.435 782.277 405.598L782.184 405.636C781.789 405.791 781.486 406.047 781.285 406.419C781.083 406.791 780.998 407.21 781.037 407.683L784.021 406.543L784.013 406.536Z"
          fill="black"
        />
        <path
          d="M786.881 409.194L785.618 401.125L786.525 400.776L787.788 408.845L786.881 409.194Z"
          fill="black"
        />
        <path
          d="M792.013 407.357C791.532 407.543 791.075 407.605 790.648 407.535C790.222 407.473 789.85 407.287 789.532 406.993C789.207 406.698 788.959 406.311 788.773 405.822C788.556 405.249 788.47 404.675 788.532 404.102C788.594 403.528 788.788 403.024 789.106 402.582C789.431 402.148 789.85 401.83 790.377 401.629C790.858 401.443 791.315 401.389 791.741 401.451C792.168 401.513 792.54 401.699 792.865 401.993C793.191 402.288 793.447 402.675 793.633 403.164C793.857 403.745 793.935 404.326 793.865 404.892C793.795 405.466 793.602 405.97 793.276 406.404C792.951 406.838 792.532 407.156 792.013 407.357ZM791.819 406.551C792.113 406.442 792.361 406.241 792.571 405.946C792.78 405.652 792.904 405.295 792.951 404.877C792.989 404.458 792.92 404.009 792.741 403.536C792.617 403.218 792.454 402.947 792.237 402.737C792.028 402.528 791.788 402.388 791.517 402.319C791.245 402.257 790.974 402.28 790.687 402.388L790.579 402.427C790.284 402.543 790.028 402.745 789.827 403.04C789.625 403.334 789.501 403.691 789.455 404.109C789.408 404.528 789.478 404.97 789.656 405.443C789.78 405.76 789.943 406.024 790.152 406.233C790.362 406.443 790.602 406.582 790.865 406.652C791.129 406.722 791.408 406.698 791.703 406.59L791.811 406.551H791.819Z"
          fill="black"
        />
        <path
          d="M795.516 405.892L794.609 400.133L795.516 399.784L795.648 400.59C795.741 400.234 795.904 399.931 796.144 399.675C796.377 399.42 796.671 399.226 797.012 399.094C797.369 398.962 797.718 398.931 798.059 399.001C798.4 399.079 798.702 399.265 798.95 399.559C799.206 399.854 799.369 400.257 799.446 400.761L799.989 404.187L799.082 404.536L798.555 401.11C798.501 400.792 798.408 400.536 798.276 400.327C798.144 400.117 797.973 399.978 797.764 399.908C797.555 399.838 797.33 399.854 797.09 399.947L796.981 399.986C796.718 400.086 796.501 400.249 796.322 400.474C796.144 400.699 796.02 400.993 795.958 401.35C795.896 401.706 795.896 402.109 795.958 402.551L796.431 405.551L795.524 405.9L795.516 405.892Z"
          fill="black"
        />
        <path
          d="M804.376 402.497L803.113 394.428L805.128 393.66C805.957 393.342 806.678 393.242 807.291 393.35C807.895 393.459 808.391 393.707 808.756 394.094C809.128 394.482 809.414 394.931 809.616 395.466C809.895 396.195 810.011 396.947 809.973 397.738C809.934 398.528 809.717 399.241 809.306 399.885C808.903 400.528 808.314 401.001 807.546 401.288L804.368 402.505L804.376 402.497ZM807.174 400.427C807.787 400.195 808.236 399.83 808.531 399.342C808.825 398.854 808.973 398.296 808.988 397.676C809.004 397.055 808.895 396.451 808.663 395.846C808.391 395.133 807.981 394.668 807.43 394.435C806.88 394.203 806.221 394.234 805.461 394.528L804.213 395.009L805.206 401.179L807.174 400.427Z"
          fill="black"
        />
        <path
          d="M811.817 399.652L810.911 393.893L811.817 393.544L811.996 394.676C812.042 394.242 812.174 393.877 812.383 393.583C812.6 393.288 812.91 393.063 813.329 392.901L813.732 392.746L813.887 393.699L813.492 393.854C813.027 394.032 812.678 394.35 812.461 394.792C812.244 395.242 812.182 395.823 812.29 396.552L812.724 399.303L811.817 399.652Z"
          fill="black"
        />
        <path
          d="M817.305 397.675C817.011 397.784 816.724 397.83 816.43 397.799C816.135 397.776 815.871 397.675 815.639 397.497C815.399 397.327 815.22 397.079 815.104 396.768C814.972 396.427 814.949 396.086 815.019 395.738C815.096 395.389 815.275 395.071 815.554 394.784C815.833 394.49 816.197 394.265 816.654 394.102L818.29 393.466L818.259 393.249C818.212 392.963 818.104 392.753 817.941 392.606C817.778 392.459 817.569 392.381 817.336 392.358C817.104 392.342 816.879 392.373 816.647 392.459L816.585 392.482C816.274 392.606 816.026 392.784 815.856 393.032C815.678 393.28 815.616 393.544 815.662 393.831L814.755 394.179C814.647 393.676 814.74 393.195 815.034 392.73C815.337 392.265 815.786 391.916 816.398 391.684C816.794 391.528 817.197 391.474 817.584 391.521C817.98 391.559 818.321 391.722 818.6 392.001C818.887 392.288 819.065 392.691 819.15 393.234L819.484 395.389C819.522 395.645 819.561 395.854 819.592 396.017C819.608 396.125 819.623 396.234 819.639 396.334C819.654 396.435 819.67 396.544 819.685 396.652L818.832 396.978C818.825 396.908 818.817 396.838 818.809 396.768C818.809 396.699 818.794 396.629 818.77 396.559C818.763 396.489 818.747 396.427 818.739 396.358C818.724 396.296 818.716 396.226 818.708 396.156C818.608 396.544 818.429 396.869 818.189 397.11C817.949 397.358 817.654 397.544 817.313 397.675H817.305ZM817.259 396.815C817.499 396.722 817.724 396.567 817.925 396.342C818.127 396.117 818.282 395.862 818.383 395.567C818.484 395.272 818.507 394.97 818.453 394.668L818.398 394.265L816.957 394.815C816.538 394.978 816.236 395.203 816.05 395.482C815.864 395.769 815.833 396.071 815.957 396.381C816.05 396.621 816.205 396.784 816.43 396.869C816.647 396.955 816.895 396.947 817.158 396.846L817.267 396.807L817.259 396.815Z"
          fill="black"
        />
        <path
          d="M821.266 396.04L820.359 390.281L821.266 389.932L821.406 390.815C821.483 390.482 821.631 390.164 821.863 389.878C822.096 389.591 822.39 389.374 822.762 389.234C823.15 389.087 823.514 389.064 823.863 389.164C824.212 389.265 824.506 389.482 824.723 389.8C824.809 389.366 824.987 388.978 825.258 388.637C825.53 388.288 825.863 388.048 826.25 387.893C826.63 387.746 826.987 387.715 827.336 387.792C827.684 387.87 827.979 388.056 828.219 388.351C828.467 388.645 828.63 389.048 828.715 389.544L829.25 392.986L828.359 393.327L827.801 389.823C827.731 389.366 827.56 389.04 827.281 388.847C827.002 388.645 826.677 388.622 826.32 388.754L826.227 388.792C825.971 388.893 825.762 389.056 825.592 389.296C825.421 389.536 825.305 389.816 825.235 390.141C825.165 390.467 825.165 390.808 825.22 391.164L825.723 394.342L824.816 394.691L824.274 391.234C824.204 390.777 824.026 390.436 823.747 390.226C823.468 390.017 823.142 389.978 822.786 390.118L822.677 390.157C822.429 390.25 822.22 390.42 822.049 390.66C821.879 390.901 821.762 391.203 821.7 391.56C821.638 391.916 821.646 392.296 821.708 392.707L822.181 395.707L821.274 396.056L821.266 396.04Z"
          fill="black"
        />
        <path
          d="M832.49 391.87C832.196 391.978 831.909 392.025 831.614 391.994C831.32 391.97 831.056 391.87 830.824 391.691C830.583 391.521 830.405 391.273 830.289 390.963C830.157 390.622 830.134 390.281 830.204 389.932C830.281 389.583 830.459 389.265 830.739 388.978C831.018 388.684 831.382 388.459 831.832 388.296L833.467 387.66L833.436 387.443C833.389 387.157 833.289 386.94 833.118 386.8C832.948 386.653 832.746 386.575 832.514 386.552C832.281 386.537 832.056 386.568 831.824 386.653L831.762 386.676C831.452 386.792 831.204 386.978 831.033 387.226C830.855 387.474 830.793 387.738 830.839 388.025L829.932 388.374C829.824 387.87 829.917 387.389 830.211 386.924C830.514 386.459 830.963 386.11 831.576 385.878C831.971 385.723 832.366 385.668 832.762 385.707C833.157 385.746 833.498 385.909 833.777 386.188C834.064 386.475 834.242 386.878 834.327 387.42L834.661 389.575C834.7 389.831 834.738 390.04 834.769 390.203C834.785 390.312 834.8 390.42 834.816 390.521C834.831 390.622 834.847 390.73 834.862 390.839L834.01 391.164C834.002 391.094 833.986 391.025 833.986 390.955C833.986 390.885 833.971 390.815 833.948 390.746C833.94 390.676 833.924 390.606 833.917 390.544C833.901 390.482 833.893 390.412 833.886 390.343C833.785 390.73 833.607 391.048 833.366 391.296C833.126 391.544 832.831 391.73 832.49 391.862V391.87ZM832.444 391.009C832.684 390.916 832.909 390.761 833.111 390.536C833.312 390.312 833.467 390.056 833.568 389.761C833.669 389.467 833.692 389.164 833.638 388.862L833.576 388.459L832.134 389.009C831.715 389.172 831.413 389.397 831.227 389.684C831.041 389.97 831.01 390.273 831.134 390.583C831.227 390.823 831.382 390.986 831.607 391.071C831.831 391.156 832.072 391.149 832.335 391.048L832.444 391.009Z"
          fill="black"
        />
        <path
          d="M838.257 389.536C837.87 389.684 837.544 389.684 837.288 389.536C837.033 389.389 836.878 389.11 836.823 388.715L836.234 385.095L835.01 385.56L834.87 384.723L836.118 384.242L835.831 382.374L836.715 382.033L837.002 383.901L838.358 383.382L838.498 384.219L837.133 384.738L837.699 388.335C837.722 388.482 837.784 388.575 837.893 388.622C837.994 388.668 838.118 388.668 838.25 388.614L839.412 388.172L839.544 389.04L838.265 389.529L838.257 389.536Z"
          fill="black"
        />
        <path
          d="M839.412 381.886L839.219 380.715L840.234 380.328L840.428 381.498L839.412 381.886ZM840.56 388.661L839.653 382.901L840.56 382.552L841.466 388.312L840.56 388.661Z"
          fill="black"
        />
        <path
          d="M845.73 386.808C845.265 386.986 844.823 387.048 844.404 387.002C843.986 386.955 843.606 386.8 843.273 386.529C842.939 386.258 842.676 385.878 842.49 385.397C842.242 384.754 842.156 384.134 842.218 383.544C842.288 382.955 842.49 382.444 842.823 382.017C843.156 381.591 843.598 381.273 844.125 381.064C844.528 380.909 844.916 380.847 845.296 380.87C845.668 380.901 846.001 381.025 846.288 381.235C846.575 381.452 846.784 381.738 846.923 382.103L846.017 382.452C845.877 382.095 845.668 381.87 845.373 381.777C845.079 381.684 844.753 381.7 844.397 381.839L844.288 381.878C843.939 382.01 843.668 382.234 843.466 382.552C843.265 382.862 843.156 383.234 843.133 383.661C843.11 384.087 843.187 384.529 843.366 384.986C843.552 385.475 843.831 385.823 844.195 386.009C844.559 386.203 844.97 386.211 845.427 386.04L845.521 386.002C845.862 385.87 846.117 385.661 846.288 385.366C846.458 385.072 846.528 384.746 846.505 384.389L847.412 384.041C847.505 384.653 847.396 385.211 847.079 385.715C846.761 386.211 846.303 386.575 845.714 386.8L845.73 386.808Z"
          fill="black"
        />
        <path
          d="M707.856 241.8C710.189 250.281 703.104 259.381 702.073 267.597C701.004 276.116 705.499 286.682 701.321 294.03C697.143 301.378 685.764 302.913 678.99 308.184C672.455 313.269 668.254 324.005 659.766 326.338C651.286 328.672 642.186 321.587 633.97 320.556C625.451 319.486 614.886 323.982 607.538 319.804C600.189 315.626 598.654 304.247 593.384 297.472C588.299 290.937 577.563 286.736 575.23 278.248C572.897 269.768 579.981 260.668 581.012 252.451C582.082 243.932 577.586 233.367 581.764 226.018C585.942 218.67 597.321 217.135 604.096 211.864C610.871 206.593 614.832 196.043 623.319 193.71C631.799 191.377 640.899 198.462 649.116 199.493C657.635 200.562 668.2 196.067 675.548 200.245C682.896 204.423 684.431 215.802 689.702 222.577C694.787 229.111 705.523 233.313 707.856 241.8Z"
          fill="#F6B7DC"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M611.84 247.506L616.925 241.111L619.42 242.351C619.87 242.576 620.227 242.855 620.482 243.18C620.738 243.506 620.885 243.862 620.924 244.25C620.963 244.638 620.893 245.01 620.707 245.382C620.482 245.831 620.18 246.188 619.8 246.451C619.42 246.715 618.994 246.862 618.537 246.893C618.072 246.924 617.607 246.823 617.126 246.583L614.801 245.42L612.77 247.963L611.847 247.506H611.84ZM617.529 245.738C617.955 245.955 618.382 245.994 618.785 245.862C619.188 245.73 619.513 245.444 619.73 245.002C619.909 244.638 619.932 244.296 619.785 243.971C619.645 243.645 619.32 243.359 618.816 243.103L617.25 242.328L615.382 244.676L617.529 245.746V245.738Z"
          fill="black"
        />
        <path
          d="M619.676 251.544C619.397 251.405 619.172 251.219 618.994 250.986C618.816 250.753 618.715 250.49 618.676 250.195C618.645 249.901 618.7 249.606 618.855 249.304C619.017 248.978 619.25 248.723 619.56 248.544C619.862 248.366 620.219 248.281 620.622 248.289C621.025 248.296 621.436 248.413 621.862 248.637L623.443 249.412L623.583 249.242C623.761 249.017 623.846 248.792 623.846 248.568C623.846 248.343 623.761 248.141 623.622 247.955C623.474 247.777 623.296 247.63 623.079 247.521L623.017 247.49C622.715 247.343 622.42 247.289 622.118 247.327C621.823 247.366 621.575 247.498 621.397 247.723L620.529 247.289C620.823 246.87 621.242 246.606 621.785 246.513C622.327 246.42 622.893 246.513 623.482 246.808C623.862 247.002 624.172 247.25 624.412 247.568C624.645 247.885 624.761 248.242 624.746 248.645C624.73 249.048 624.552 249.459 624.211 249.885L622.854 251.591C622.692 251.792 622.56 251.963 622.459 252.094C622.389 252.18 622.327 252.265 622.257 252.343C622.195 252.42 622.126 252.505 622.056 252.591L621.242 252.18C621.289 252.125 621.335 252.071 621.382 252.017C621.428 251.963 621.475 251.908 621.506 251.854C621.552 251.8 621.591 251.746 621.63 251.691C621.668 251.637 621.707 251.583 621.754 251.529C621.397 251.715 621.04 251.808 620.699 251.792C620.351 251.777 620.017 251.691 619.684 251.529L619.676 251.544ZM620.281 250.924C620.513 251.04 620.777 251.095 621.079 251.102C621.382 251.102 621.676 251.04 621.955 250.916C622.242 250.792 622.474 250.606 622.668 250.358L622.924 250.04L621.544 249.35C621.141 249.149 620.769 249.079 620.436 249.133C620.103 249.195 619.855 249.374 619.707 249.668C619.591 249.901 619.575 250.126 619.661 250.35C619.746 250.575 619.917 250.746 620.165 250.87L620.265 250.924H620.281Z"
          fill="black"
        />
        <path
          d="M623.56 253.35L627.187 248.785L628.055 249.219L627.342 250.11C627.691 249.854 628.048 249.699 628.412 249.661C628.776 249.622 629.156 249.699 629.559 249.901L629.947 250.095L629.35 250.854L628.97 250.668C628.521 250.443 628.055 250.405 627.575 250.544C627.094 250.684 626.629 251.04 626.164 251.606L624.428 253.792L623.56 253.358V253.35Z"
          fill="black"
        />
        <path
          d="M628.73 256.056C628.451 255.916 628.226 255.73 628.048 255.497C627.869 255.265 627.769 255.001 627.73 254.707C627.699 254.412 627.753 254.118 627.908 253.815C628.071 253.49 628.303 253.234 628.614 253.056C628.916 252.877 629.272 252.792 629.676 252.8C630.079 252.808 630.489 252.924 630.916 253.149L632.497 253.924L632.637 253.753C632.815 253.529 632.9 253.304 632.9 253.079C632.9 252.854 632.815 252.653 632.675 252.467C632.528 252.288 632.35 252.141 632.133 252.033L632.071 252.002C631.768 251.854 631.474 251.8 631.172 251.839C630.877 251.878 630.629 252.009 630.451 252.234L629.582 251.8C629.877 251.381 630.296 251.118 630.838 251.025C631.381 250.932 631.947 251.025 632.536 251.319C632.916 251.513 633.226 251.761 633.466 252.079C633.698 252.397 633.815 252.753 633.799 253.157C633.784 253.56 633.605 253.97 633.264 254.397L631.908 256.102C631.745 256.304 631.613 256.474 631.513 256.606C631.443 256.691 631.381 256.776 631.311 256.854C631.249 256.932 631.179 257.017 631.11 257.102L630.296 256.691C630.342 256.637 630.389 256.583 630.435 256.528C630.482 256.474 630.528 256.42 630.559 256.366C630.606 256.311 630.644 256.257 630.683 256.203C630.722 256.149 630.761 256.094 630.807 256.04C630.451 256.226 630.094 256.319 629.753 256.304C629.404 256.296 629.071 256.203 628.738 256.04L628.73 256.056ZM629.334 255.435C629.567 255.552 629.831 255.606 630.133 255.614C630.435 255.614 630.73 255.552 631.009 255.428C631.296 255.304 631.528 255.118 631.722 254.87L631.978 254.552L630.598 253.862C630.195 253.66 629.823 253.591 629.489 253.645C629.156 253.707 628.908 253.885 628.761 254.18C628.645 254.412 628.629 254.637 628.714 254.862C628.8 255.087 628.97 255.257 629.218 255.381L629.319 255.435H629.334Z"
          fill="black"
        />
        <path
          d="M634.83 259.094C634.443 258.9 634.133 258.637 633.908 258.304C633.683 257.97 633.567 257.59 633.559 257.164C633.559 256.738 633.667 256.28 633.908 255.808C634.202 255.218 634.582 254.753 635.047 254.412C635.512 254.063 636.008 253.862 636.536 253.8C637.063 253.738 637.559 253.823 638.024 254.056C638.427 254.257 638.737 254.529 638.969 254.885C639.194 255.234 639.303 255.598 639.287 255.978L641.388 253.319L642.287 253.769L637.179 260.148L636.318 259.722L636.76 259.164C636.52 259.296 636.218 259.366 635.853 259.358C635.489 259.35 635.148 259.265 634.822 259.11L634.83 259.094ZM635.404 258.451C635.76 258.629 636.125 258.676 636.505 258.59C636.884 258.505 637.241 258.327 637.566 258.04C637.892 257.753 638.163 257.412 638.365 257.001C638.512 256.707 638.582 256.42 638.574 256.141C638.566 255.862 638.489 255.606 638.349 255.381C638.21 255.156 638.008 254.978 637.76 254.854L637.659 254.8C637.349 254.645 637.024 254.606 636.66 254.676C636.303 254.746 635.962 254.908 635.629 255.172C635.303 255.436 635.032 255.769 634.822 256.187C634.59 256.66 634.512 257.094 634.605 257.505C634.698 257.916 634.931 258.211 635.311 258.412L635.404 258.459V258.451Z"
          fill="black"
        />
        <path
          d="M638.752 260.916L642.38 256.35L643.248 256.784L639.621 261.35L638.752 260.916ZM642.961 255.49L643.698 254.552L644.667 255.032L643.93 255.97L642.961 255.49Z"
          fill="black"
        />
        <path
          d="M643.093 263.21C642.465 262.9 642.047 262.505 641.822 262.024C641.597 261.544 641.605 261.04 641.837 260.505L642.729 260.947C642.605 261.264 642.605 261.559 642.729 261.815C642.861 262.078 643.085 262.288 643.403 262.443L643.504 262.497C643.806 262.644 644.085 262.698 644.349 262.652C644.612 262.605 644.798 262.458 644.922 262.226C645 262.071 645.008 261.908 644.953 261.737C644.899 261.567 644.775 261.404 644.605 261.241L644.116 260.761C643.783 260.435 643.566 260.109 643.465 259.792C643.364 259.474 643.388 259.148 643.55 258.823C643.698 258.528 643.907 258.303 644.194 258.133C644.473 257.97 644.798 257.885 645.163 257.893C645.527 257.9 645.899 257.993 646.271 258.179C646.814 258.451 647.201 258.83 647.426 259.311C647.651 259.792 647.635 260.296 647.387 260.815L646.519 260.381C646.651 260.086 646.651 259.807 646.519 259.536C646.395 259.265 646.186 259.055 645.891 258.908L645.791 258.854C645.519 258.714 645.256 258.675 645 258.737C644.744 258.792 644.558 258.939 644.45 259.172C644.372 259.327 644.357 259.489 644.411 259.652C644.465 259.815 644.574 259.978 644.744 260.148L645.256 260.66C645.566 260.97 645.775 261.28 645.907 261.598C646.031 261.916 646 262.272 645.806 262.66C645.659 262.962 645.442 263.187 645.155 263.334C644.868 263.481 644.543 263.551 644.186 263.536C643.83 263.52 643.465 263.419 643.101 263.233L643.093 263.21Z"
          fill="black"
        />
        <path
          d="M648.426 265.869C648 265.652 647.666 265.373 647.426 265.016C647.194 264.66 647.07 264.264 647.062 263.815C647.062 263.365 647.17 262.908 647.411 262.419C647.69 261.861 648.054 261.412 648.519 261.086C648.976 260.753 649.48 260.559 650.015 260.512C650.558 260.466 651.077 260.567 651.581 260.815C651.961 261.009 652.263 261.257 652.488 261.559C652.713 261.861 652.829 262.21 652.844 262.605C652.86 262.993 652.759 263.404 652.542 263.822C652.496 263.908 652.434 264.016 652.341 264.148C652.255 264.272 652.155 264.404 652.046 264.528L648.325 262.675C648.139 263.001 648.031 263.326 648 263.652C647.969 263.977 648.023 264.28 648.163 264.543C648.302 264.807 648.519 265.016 648.798 265.156L648.899 265.21C649.217 265.373 649.527 265.427 649.829 265.388C650.131 265.349 650.395 265.218 650.635 265.001L651.503 265.435C651.116 265.853 650.658 266.109 650.124 266.202C649.589 266.295 649.023 266.187 648.411 265.884L648.426 265.869ZM651.666 263.443C651.922 263.063 652.007 262.698 651.922 262.35C651.837 262.001 651.589 261.729 651.178 261.528L651.085 261.481C650.705 261.288 650.31 261.249 649.899 261.342C649.496 261.443 649.124 261.667 648.798 262.009L651.658 263.435L651.666 263.443Z"
          fill="black"
        />
        <path
          d="M654.348 268.69L659.433 262.295L661.929 263.536C662.379 263.76 662.735 264.039 662.991 264.365C663.247 264.691 663.394 265.047 663.433 265.435C663.472 265.822 663.402 266.194 663.216 266.566C662.991 267.016 662.689 267.373 662.309 267.628C661.929 267.892 661.503 268.039 661.045 268.07C660.58 268.101 660.115 268.001 659.635 267.76L657.309 266.597L655.278 269.14L654.356 268.683L654.348 268.69ZM660.038 266.923C660.464 267.14 660.89 267.179 661.293 267.047C661.704 266.915 662.014 266.628 662.239 266.187C662.417 265.822 662.441 265.481 662.293 265.156C662.154 264.83 661.828 264.543 661.324 264.288L659.759 263.505L657.891 265.853L660.038 266.923Z"
          fill="black"
        />
        <path
          d="M660.681 271.853L664.309 267.287L665.177 267.721L661.549 272.287L660.681 271.853ZM664.89 266.427L665.626 265.489L666.595 265.97L665.859 266.908L664.89 266.427Z"
          fill="black"
        />
        <path
          d="M663.115 273.062L666.743 268.497L667.611 268.931L667.115 269.574C667.44 269.403 667.774 269.318 668.122 269.318C668.471 269.318 668.812 269.396 669.138 269.559C669.479 269.729 669.735 269.969 669.913 270.272C670.091 270.574 670.153 270.923 670.107 271.303C670.06 271.682 669.874 272.078 669.556 272.481L667.401 275.194L666.533 274.76L668.696 272.055C668.89 271.799 669.021 271.558 669.084 271.318C669.146 271.078 669.138 270.861 669.045 270.659C668.952 270.458 668.789 270.303 668.556 270.186L668.456 270.14C668.208 270.016 667.936 269.962 667.65 269.985C667.363 270.008 667.068 270.117 666.766 270.31C666.464 270.504 666.169 270.776 665.882 271.124L663.999 273.504L663.13 273.07L663.115 273.062Z"
          fill="black"
        />
        <path
          d="M668.905 275.954L673.99 269.559L674.858 269.993L671.634 274.047L675.982 273.101L677.215 273.713L673.533 274.496L674.013 278.496L672.982 277.984L672.595 274.69L670.797 275.078L669.766 276.372L668.897 275.938L668.905 275.954Z"
          fill="black"
        />
        <path
          d="M641.14 623.678C641.14 632.476 631.9 639.375 628.73 647.026C625.443 654.956 626.978 666.335 621.002 672.311C615.025 678.288 603.646 676.753 595.717 680.04C588.066 683.21 581.167 692.45 572.37 692.45C563.572 692.45 556.673 683.21 549.022 680.04C541.093 676.753 529.714 678.288 523.737 672.311C517.761 666.335 519.296 654.956 516.009 647.026C512.723 639.096 503.599 632.476 503.599 623.678C503.599 614.88 512.839 607.982 516.009 600.331C519.296 592.401 517.761 581.022 523.737 575.045C529.714 569.069 541.093 570.604 549.022 567.317C556.952 564.031 563.572 554.907 572.37 554.907C581.167 554.907 588.066 564.147 595.717 567.317C603.646 570.604 615.025 569.069 621.002 575.045C626.978 581.022 625.443 592.401 628.73 600.331C631.9 607.982 641.14 614.88 641.14 623.678Z"
          fill="#215CD1"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M556.262 627.88L557.96 619.888H560.115C561.006 619.888 561.711 620.051 562.246 620.369C562.773 620.686 563.146 621.097 563.355 621.586C563.564 622.074 563.673 622.609 563.673 623.175C563.673 623.95 563.518 624.702 563.2 625.423C562.882 626.143 562.425 626.733 561.812 627.19C561.2 627.647 560.487 627.872 559.665 627.872H556.262V627.88ZM559.619 626.942C560.27 626.942 560.828 626.764 561.27 626.407C561.719 626.05 562.06 625.593 562.293 625.012C562.525 624.438 562.642 623.833 562.642 623.19C562.642 622.43 562.425 621.841 561.991 621.43C561.556 621.02 560.936 620.818 560.115 620.818H558.781L557.502 626.934H559.611L559.619 626.942Z"
          fill="black"
        />
        <path
          d="M566.92 627.996C566.44 627.996 566.013 627.888 565.649 627.678C565.277 627.461 564.99 627.159 564.789 626.764C564.587 626.368 564.479 625.903 564.479 625.361C564.479 624.733 564.61 624.175 564.874 623.671C565.138 623.167 565.494 622.772 565.959 622.492C566.417 622.206 566.928 622.066 567.494 622.066C567.92 622.066 568.3 622.151 568.633 622.33C568.967 622.508 569.23 622.764 569.424 623.105C569.618 623.446 569.703 623.857 569.695 624.33C569.695 624.43 569.688 624.554 569.664 624.709C569.641 624.864 569.61 625.019 569.571 625.175H565.417C565.393 625.547 565.44 625.888 565.556 626.198C565.672 626.508 565.851 626.748 566.099 626.919C566.339 627.097 566.626 627.182 566.944 627.182H567.06C567.416 627.182 567.719 627.097 567.975 626.926C568.23 626.756 568.409 626.523 568.525 626.221H569.494C569.331 626.771 569.037 627.205 568.602 627.523C568.168 627.841 567.61 628.004 566.928 628.004L566.92 627.996ZM568.734 624.376C568.796 623.919 568.711 623.554 568.478 623.283C568.246 623.012 567.905 622.88 567.447 622.88H567.347C566.92 622.88 566.548 623.012 566.231 623.283C565.913 623.554 565.68 623.919 565.548 624.376H568.742H568.734Z"
          fill="black"
        />
        <path
          d="M570.471 627.88L571.68 622.175H572.649L572.486 622.973C572.695 622.678 572.959 622.454 573.277 622.291C573.587 622.136 573.928 622.058 574.292 622.058C574.656 622.058 575.005 622.151 575.3 622.353C575.594 622.547 575.803 622.826 575.935 623.19C576.067 623.554 576.075 623.988 575.966 624.492L575.245 627.88H574.276L575.005 624.492C575.067 624.182 575.067 623.903 575.021 623.655C574.974 623.407 574.858 623.221 574.687 623.082C574.517 622.942 574.3 622.872 574.044 622.872H573.928C573.649 622.872 573.385 622.95 573.137 623.097C572.889 623.244 572.672 623.477 572.486 623.779C572.3 624.089 572.16 624.461 572.06 624.903L571.432 627.872H570.463L570.471 627.88Z"
          fill="black"
        />
        <path
          d="M576.943 627.88L578.152 622.175H579.121L577.912 627.88H576.943ZM578.292 621.144L578.532 619.981H579.617L579.377 621.144H578.292Z"
          fill="black"
        />
        <path
          d="M579.656 627.88L580.865 622.175H581.834L581.648 623.051C581.842 622.764 582.09 622.523 582.408 622.337C582.725 622.151 583.082 622.058 583.477 622.058C583.873 622.058 584.237 622.167 584.532 622.392C584.826 622.616 585.02 622.911 585.105 623.291C585.338 622.919 585.648 622.616 586.028 622.392C586.407 622.167 586.803 622.058 587.221 622.058C587.64 622.058 587.973 622.151 588.268 622.353C588.562 622.547 588.772 622.826 588.896 623.19C589.02 623.554 589.027 623.988 588.927 624.477L588.198 627.88H587.237L587.965 624.407C588.066 623.957 588.02 623.593 587.826 623.306C587.632 623.02 587.345 622.88 586.958 622.88H586.857C586.586 622.88 586.322 622.958 586.082 623.12C585.842 623.283 585.625 623.5 585.446 623.779C585.268 624.058 585.136 624.376 585.066 624.725L584.408 627.872H583.439L584.167 624.446C584.268 623.988 584.222 623.616 584.036 623.314C583.849 623.02 583.563 622.872 583.175 622.872H583.059C582.795 622.872 582.539 622.958 582.291 623.12C582.043 623.283 581.826 623.523 581.648 623.833C581.47 624.143 581.33 624.5 581.245 624.903L580.617 627.872H579.648L579.656 627.88Z"
          fill="black"
        />
        <path
          d="M693.516 615.524C693.345 615.09 693.299 614.671 693.384 614.253C693.469 613.834 693.64 613.501 693.911 613.253L692.803 613.439L692.446 612.539L700.5 611.16L700.856 612.059L697.864 612.578C698.167 612.679 698.438 612.842 698.686 613.059C698.926 613.284 699.12 613.563 699.252 613.904C699.407 614.284 699.453 614.671 699.391 615.066C699.337 615.454 699.167 615.811 698.895 616.136C698.624 616.462 698.252 616.717 697.771 616.904C697.144 617.152 696.539 617.26 695.958 617.214C695.376 617.175 694.872 617.004 694.438 616.71C694.004 616.415 693.694 616.02 693.5 615.532L693.516 615.524ZM694.252 615.152C694.376 615.462 694.593 615.718 694.896 615.927C695.198 616.128 695.562 616.245 695.996 616.268C696.43 616.291 696.88 616.206 697.368 616.02C697.841 615.834 698.182 615.563 698.384 615.214C698.585 614.865 698.609 614.493 698.454 614.09L698.415 613.997C698.268 613.625 698.019 613.338 697.678 613.144C697.337 612.95 696.95 612.849 696.516 612.849C696.082 612.849 695.655 612.935 695.237 613.105C694.934 613.229 694.686 613.392 694.5 613.601C694.314 613.811 694.198 614.043 694.144 614.299C694.097 614.555 694.121 614.811 694.221 615.066L694.26 615.159L694.252 615.152Z"
          fill="black"
        />
        <path
          d="M696.043 621.857C695.872 621.423 695.826 621.004 695.911 620.586C695.996 620.167 696.167 619.834 696.438 619.586L695.33 619.772L694.973 618.872L703.027 617.493L703.383 618.392L700.391 618.911C700.694 619.012 700.965 619.175 701.213 619.392C701.453 619.617 701.647 619.896 701.779 620.237C701.934 620.617 701.98 621.004 701.918 621.399C701.864 621.787 701.694 622.144 701.422 622.469C701.151 622.795 700.779 623.051 700.298 623.237C699.671 623.485 699.066 623.593 698.485 623.547C697.903 623.508 697.399 623.337 696.965 623.043C696.531 622.748 696.221 622.353 696.027 621.865L696.043 621.857ZM696.779 621.485C696.903 621.795 697.12 622.051 697.423 622.26C697.725 622.461 698.089 622.578 698.523 622.601C698.957 622.624 699.407 622.539 699.895 622.353C700.368 622.167 700.709 621.896 700.911 621.547C701.112 621.198 701.136 620.826 700.981 620.423L700.942 620.33C700.795 619.958 700.546 619.671 700.205 619.477C699.864 619.283 699.477 619.182 699.043 619.182C698.609 619.182 698.182 619.268 697.764 619.438C697.461 619.562 697.213 619.725 697.027 619.934C696.841 620.144 696.725 620.376 696.671 620.632C696.624 620.888 696.647 621.144 696.748 621.399L696.787 621.492L696.779 621.485Z"
          fill="black"
        />
        <path
          d="M695.275 625.105L696.136 624.957L696.671 626.291C696.709 626.391 696.756 626.469 696.818 626.523C696.88 626.577 696.95 626.616 697.051 626.632C697.144 626.647 697.26 626.647 697.407 626.616L698.686 626.399L697.934 626.67L702.903 623.322L703.283 624.267L699.267 626.949L699.174 626.57L704.631 627.663L705.034 628.67L698.182 627.298L698.973 627.314L697.415 627.577C697.058 627.639 696.756 627.608 696.508 627.477C696.26 627.345 696.082 627.143 695.965 626.849L695.26 625.089L695.275 625.105Z"
          fill="black"
        />
        <path
          d="M701.748 636.158C701.577 635.724 701.531 635.306 701.616 634.887C701.701 634.468 701.872 634.135 702.143 633.887L701.035 634.073L700.678 633.174L708.732 631.794L709.088 632.693L706.096 633.213C706.399 633.313 706.67 633.476 706.918 633.693C707.158 633.918 707.352 634.197 707.484 634.538C707.639 634.918 707.685 635.306 707.623 635.701C707.569 636.096 707.399 636.453 707.127 636.771C706.856 637.096 706.484 637.352 706.003 637.538C705.375 637.786 704.771 637.895 704.19 637.848C703.608 637.809 703.104 637.639 702.67 637.344C702.244 637.05 701.926 636.654 701.732 636.174L701.748 636.158ZM702.484 635.786C702.608 636.096 702.825 636.352 703.128 636.554C703.43 636.755 703.794 636.871 704.228 636.895C704.662 636.918 705.112 636.833 705.6 636.647C706.073 636.461 706.414 636.189 706.616 635.84C706.817 635.492 706.841 635.12 706.685 634.717L706.647 634.624C706.499 634.251 706.251 633.965 705.91 633.771C705.569 633.577 705.182 633.476 704.748 633.476C704.314 633.476 703.887 633.562 703.469 633.732C703.166 633.856 702.918 634.019 702.732 634.228C702.546 634.437 702.43 634.67 702.376 634.926C702.329 635.182 702.352 635.437 702.453 635.693L702.492 635.786H702.484Z"
          fill="black"
        />
        <path
          d="M703.205 639.491L711.259 638.112L711.615 639.011L703.562 640.391L703.205 639.491Z"
          fill="black"
        />
        <path
          d="M704.887 644.034C704.724 643.623 704.686 643.243 704.763 642.902C704.841 642.561 705.034 642.274 705.329 642.042C705.624 641.809 706.011 641.654 706.484 641.569L709.933 640.964L710.298 641.879L706.786 642.483C706.337 642.553 706.019 642.724 705.817 642.987C705.616 643.251 705.585 643.553 705.717 643.894L705.755 644.003C705.864 644.274 706.042 644.491 706.29 644.669C706.538 644.848 706.841 644.964 707.189 645.018C707.538 645.072 707.918 645.065 708.337 644.995L711.321 644.476L711.677 645.375L705.926 646.367L705.569 645.468L706.445 645.313C706.065 645.235 705.748 645.088 705.484 644.886C705.221 644.677 705.019 644.398 704.879 644.049L704.887 644.034Z"
          fill="black"
        />
        <path
          d="M707.484 650.53C707.306 650.088 707.251 649.654 707.306 649.227C707.368 648.809 707.546 648.429 707.833 648.088C708.127 647.747 708.523 647.483 709.019 647.282C709.6 647.049 710.174 646.964 710.732 647.018C711.298 647.08 711.794 647.266 712.228 647.584C712.662 647.91 712.987 648.328 713.197 648.855C713.352 649.251 713.414 649.638 713.375 650.01C713.336 650.382 713.197 650.723 712.949 651.026C712.701 651.328 712.36 651.568 711.918 651.731C711.825 651.77 711.708 651.801 711.553 651.84C711.406 651.871 711.251 651.902 711.088 651.925L709.546 648.065C709.189 648.181 708.895 648.351 708.647 648.576C708.399 648.801 708.244 649.057 708.174 649.351C708.104 649.646 708.127 649.941 708.244 650.235L708.282 650.344C708.414 650.677 708.608 650.925 708.856 651.096C709.104 651.266 709.391 651.351 709.709 651.344L710.065 652.243C709.499 652.297 708.98 652.181 708.523 651.894C708.065 651.607 707.709 651.15 707.453 650.514L707.484 650.53ZM711.515 650.871C711.964 650.762 712.267 650.545 712.429 650.227C712.592 649.91 712.592 649.545 712.429 649.119L712.391 649.026C712.236 648.631 711.972 648.336 711.6 648.134C711.228 647.941 710.809 647.863 710.336 647.902L711.522 650.871H711.515Z"
          fill="black"
        />
        <path
          d="M903.291 666.986C897.749 673.823 886.231 673.358 878.952 677.303C871.402 681.396 865.426 691.202 857.023 692.078C848.621 692.954 840.746 684.597 832.514 682.156C824.576 679.799 813.399 682.636 806.562 677.094C799.725 671.552 800.19 660.033 796.245 652.754C792.152 645.204 782.347 639.228 781.471 630.825C780.595 622.423 788.951 614.547 791.392 606.315C793.749 598.377 790.912 587.2 796.454 580.363C801.996 573.526 813.515 573.991 820.794 570.046C828.343 565.953 834.32 556.147 842.722 555.271C851.125 554.395 859 562.752 867.232 565.193C875.169 567.55 886.347 564.713 893.184 570.255C900.02 575.797 899.555 587.316 903.501 594.595C907.593 602.145 917.399 608.121 918.275 616.524C919.151 624.926 910.795 632.802 908.353 641.034C905.997 648.971 908.834 660.149 903.291 666.986Z"
          fill="#E79385"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M831.855 612.493L838.211 607.361L840.373 609.113C840.761 609.431 841.048 609.772 841.226 610.152C841.404 610.524 841.474 610.904 841.428 611.291C841.381 611.679 841.226 612.028 840.97 612.346C840.653 612.733 840.28 613.02 839.854 613.19C839.428 613.361 838.978 613.415 838.521 613.346C838.064 613.276 837.629 613.074 837.211 612.741L835.188 611.105L832.653 613.152L831.855 612.508V612.493ZM837.792 612.005C838.164 612.307 838.567 612.439 838.994 612.4C839.42 612.361 839.792 612.152 840.102 611.764C840.358 611.454 840.451 611.121 840.381 610.772C840.311 610.423 840.056 610.067 839.622 609.718L838.265 608.617L835.932 610.501L837.792 612.012V612.005Z"
          fill="black"
        />
        <path
          d="M837.358 616.95L841.893 613.276L842.645 613.888L838.11 617.562L837.358 616.95ZM842.645 612.563L843.567 611.811L844.412 612.493L843.49 613.245L842.645 612.563Z"
          fill="black"
        />
        <path
          d="M839.467 618.655L844.001 614.981L844.753 615.593L844.125 616.113C844.474 616.02 844.823 616.004 845.164 616.082C845.505 616.159 845.815 616.306 846.102 616.539C846.396 616.779 846.598 617.066 846.706 617.399C846.815 617.733 846.799 618.082 846.668 618.454C846.536 618.818 846.272 619.167 845.877 619.485L843.18 621.663L842.428 621.05L845.133 618.88C845.373 618.678 845.551 618.461 845.668 618.244C845.784 618.027 845.815 617.81 845.768 617.593C845.722 617.376 845.598 617.19 845.396 617.027L845.303 616.958C845.086 616.779 844.831 616.671 844.544 616.632C844.257 616.593 843.947 616.632 843.606 616.756C843.265 616.88 842.916 617.082 842.567 617.361L840.211 619.268L839.459 618.655H839.467Z"
          fill="black"
        />
        <path
          d="M844.489 622.733L850.846 617.601L851.597 618.213L847.574 621.477L852.024 621.5L853.093 622.368L849.334 622.329L848.931 626.337L848.032 625.608L848.365 622.314H846.52L845.234 623.345L844.482 622.733H844.489Z"
          fill="black"
        />
        <path
          d="M852.404 629.29C851.884 628.872 851.512 628.383 851.28 627.849C851.055 627.306 850.985 626.756 851.086 626.182C851.187 625.616 851.442 625.074 851.861 624.546C852.427 623.849 853.078 623.322 853.807 622.957C854.543 622.593 855.295 622.446 856.062 622.516C856.83 622.578 857.551 622.88 858.217 623.423C858.636 623.764 858.946 624.151 859.155 624.585C859.357 625.019 859.434 625.477 859.388 625.942C859.341 626.407 859.155 626.849 858.83 627.267L858.031 626.624C858.349 626.221 858.465 625.794 858.38 625.345C858.295 624.888 858.031 624.484 857.582 624.12L857.496 624.05C857.062 623.702 856.566 623.508 856 623.461C855.434 623.415 854.869 623.539 854.287 623.826C853.714 624.105 853.194 624.531 852.737 625.089C852.404 625.5 852.194 625.903 852.094 626.314C851.993 626.725 852.024 627.128 852.179 627.515C852.334 627.903 852.613 628.259 853.016 628.593L853.101 628.663C853.365 628.88 853.659 629.042 853.985 629.143C854.31 629.244 854.636 629.275 854.969 629.221C855.303 629.166 855.613 629.019 855.892 628.779L856.69 629.422C856.287 629.779 855.838 630.011 855.357 630.12C854.876 630.228 854.38 630.213 853.869 630.073C853.357 629.934 852.869 629.67 852.396 629.29H852.404Z"
          fill="black"
        />
        <path
          d="M855.713 631.825L862.054 626.686L862.806 627.298L860.364 629.29C860.713 629.197 861.062 629.182 861.403 629.259C861.744 629.337 862.054 629.484 862.333 629.709C862.628 629.949 862.829 630.236 862.938 630.577C863.046 630.91 863.031 631.267 862.899 631.639C862.767 632.003 862.504 632.352 862.108 632.67L859.411 634.848L858.659 634.236L861.364 632.065C861.612 631.864 861.79 631.655 861.907 631.43C862.015 631.205 862.054 630.988 862.008 630.771C861.961 630.554 861.837 630.368 861.635 630.205L861.558 630.143C861.333 629.957 861.077 629.848 860.791 629.81C860.504 629.771 860.194 629.81 859.86 629.926C859.527 630.042 859.178 630.252 858.814 630.531L856.458 632.437L855.706 631.825H855.713Z"
          fill="black"
        />
        <path
          d="M862.015 637.081C861.775 636.887 861.589 636.654 861.465 636.391C861.341 636.127 861.294 635.848 861.325 635.554C861.356 635.259 861.48 634.988 861.69 634.724C861.922 634.437 862.201 634.244 862.542 634.135C862.883 634.027 863.248 634.019 863.635 634.12C864.031 634.213 864.411 634.422 864.775 634.724L866.147 635.825L866.317 635.685C866.542 635.507 866.674 635.306 866.72 635.081C866.759 634.864 866.736 634.647 866.635 634.437C866.534 634.228 866.387 634.05 866.201 633.895L866.147 633.848C865.891 633.639 865.604 633.515 865.31 633.492C865.007 633.461 864.744 633.538 864.519 633.717L863.767 633.104C864.147 632.755 864.612 632.593 865.162 632.624C865.713 632.655 866.248 632.872 866.751 633.282C867.085 633.554 867.333 633.864 867.496 634.228C867.658 634.592 867.689 634.965 867.581 635.352C867.472 635.74 867.216 636.104 866.79 636.445L865.093 637.817C864.891 637.98 864.728 638.112 864.597 638.228C864.511 638.298 864.426 638.36 864.348 638.429C864.263 638.491 864.186 638.561 864.1 638.631L863.387 638.057C863.442 638.011 863.496 637.972 863.558 637.933C863.62 637.895 863.666 637.848 863.721 637.802C863.775 637.755 863.829 637.716 863.876 637.67C863.922 637.631 863.976 637.585 864.031 637.538C863.643 637.647 863.279 637.654 862.946 637.569C862.612 637.484 862.302 637.321 862.015 637.096V637.081ZM862.736 636.608C862.938 636.771 863.186 636.887 863.48 636.949C863.775 637.011 864.069 637.019 864.379 636.949C864.682 636.887 864.953 636.755 865.193 636.561L865.519 636.306L864.325 635.337C863.976 635.058 863.635 634.903 863.294 634.887C862.953 634.872 862.682 634.996 862.473 635.251C862.31 635.453 862.248 635.67 862.279 635.902C862.318 636.135 862.442 636.344 862.659 636.523L862.752 636.592L862.736 636.608Z"
          fill="black"
        />
        <path
          d="M865.41 639.693L869.945 636.019L870.697 636.631L866.162 640.305L865.41 639.693ZM870.697 635.306L871.619 634.554L872.464 635.236L871.542 635.988L870.697 635.306Z"
          fill="black"
        />
        <path
          d="M819.119 559.752C823.576 553.566 824.638 545.977 822.677 539.179C824.429 537.815 826.026 536.187 827.39 534.296C834.979 523.769 832.738 509.158 822.452 501.36C825.096 491.996 821.793 481.57 813.437 475.547C809.36 472.609 804.663 471.144 799.989 471.051C798.423 466.648 795.555 462.656 791.478 459.711C783.122 453.688 772.185 453.858 764.131 459.323C753.512 452.269 739.133 454.82 731.614 465.245C730.226 467.176 729.164 469.245 728.428 471.377C721.429 471.718 714.646 475.121 710.236 481.245C705.825 487.36 704.74 494.856 706.616 501.592C704.848 502.964 703.244 504.6 701.872 506.499C694.361 516.917 696.477 531.35 706.507 539.202C703.81 548.597 707.096 559.085 715.483 565.131C719.56 568.069 724.258 569.534 728.932 569.627C730.498 574.03 733.366 578.022 737.443 580.968C745.83 587.014 756.814 586.82 764.875 581.293C775.518 588.564 790.075 586.06 797.656 575.549C799.004 573.681 800.035 571.681 800.772 569.619C807.825 569.325 814.685 565.922 819.135 559.744L819.119 559.752Z"
          fill="#6C97F3"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M722.979 504.135L728.118 497.786L729.033 498.251L724.49 503.856L728.699 506.003L728.095 506.747L722.979 504.142V504.135Z"
          fill="black"
        />
        <path
          d="M730.482 508.088C730.203 507.948 729.978 507.762 729.8 507.522C729.622 507.289 729.521 507.026 729.49 506.731C729.459 506.437 729.521 506.142 729.676 505.848C729.839 505.522 730.079 505.274 730.389 505.096C730.699 504.917 731.048 504.84 731.459 504.848C731.862 504.863 732.273 504.979 732.699 505.204L734.273 505.995L734.412 505.824C734.598 505.6 734.683 505.375 734.676 505.15C734.676 504.925 734.598 504.724 734.459 504.538C734.319 504.352 734.133 504.204 733.924 504.096L733.862 504.065C733.567 503.91 733.265 503.856 732.963 503.887C732.66 503.925 732.42 504.049 732.242 504.274L731.374 503.832C731.676 503.414 732.094 503.158 732.637 503.065C733.18 502.972 733.745 503.08 734.327 503.375C734.707 503.569 735.017 503.825 735.249 504.142C735.482 504.46 735.59 504.824 735.575 505.22C735.552 505.623 735.373 506.034 735.032 506.452L733.66 508.15C733.497 508.351 733.366 508.514 733.265 508.654C733.195 508.739 733.125 508.824 733.063 508.902C732.994 508.979 732.932 509.065 732.862 509.15L732.048 508.739C732.094 508.685 732.141 508.63 732.188 508.576C732.234 508.522 732.281 508.468 732.319 508.413C732.366 508.359 732.405 508.305 732.443 508.251C732.482 508.196 732.529 508.142 732.567 508.088C732.211 508.274 731.854 508.359 731.505 508.344C731.157 508.328 730.823 508.235 730.498 508.072L730.482 508.088ZM731.087 507.475C731.319 507.592 731.583 507.654 731.885 507.654C732.187 507.654 732.482 507.599 732.761 507.475C733.048 507.351 733.288 507.165 733.474 506.925L733.738 506.607L732.366 505.91C731.963 505.708 731.598 505.631 731.265 505.685C730.932 505.739 730.684 505.917 730.536 506.212C730.42 506.445 730.405 506.669 730.482 506.894C730.567 507.119 730.73 507.289 730.986 507.421L731.087 507.475Z"
          fill="black"
        />
        <path
          d="M733.994 509.747L734.629 508.956L740.443 507.328L740.373 507.522L737.187 505.902L737.714 505.235L741.66 507.243L741.032 508.026L735.203 509.669L735.273 509.46L738.668 511.189L738.141 511.855L734.001 509.747H733.994Z"
          fill="black"
        />
        <path
          d="M737.807 513.971L738.358 513.289L739.637 513.94C739.73 513.987 739.823 514.018 739.908 514.018C739.993 514.018 740.071 514.002 740.156 513.948C740.233 513.901 740.319 513.816 740.412 513.7L741.233 512.692L740.854 513.398L742.381 507.607L743.287 508.065L742.039 512.731L741.722 512.506L746.543 509.723L747.512 510.219L741.458 513.708L742.063 513.196L741.071 514.421C740.838 514.708 740.59 514.878 740.326 514.94C740.055 515.002 739.784 514.963 739.505 514.824L737.815 513.963L737.807 513.971Z"
          fill="black"
        />
        <path
          d="M747.186 516.467L752.326 510.119L753.194 510.561L748.055 516.909L747.186 516.467Z"
          fill="black"
        />
        <path
          d="M751.101 518.591C750.822 518.452 750.597 518.265 750.419 518.025C750.24 517.793 750.14 517.529 750.109 517.235C750.078 516.94 750.14 516.645 750.295 516.351C750.458 516.025 750.698 515.777 751.008 515.599C751.318 515.421 751.667 515.343 752.078 515.351C752.481 515.366 752.891 515.483 753.318 515.708L754.891 516.498L755.031 516.328C755.217 516.103 755.302 515.878 755.294 515.653C755.294 515.428 755.217 515.227 755.077 515.041C754.938 514.855 754.76 514.708 754.542 514.599L754.48 514.568C754.186 514.413 753.884 514.359 753.581 514.39C753.279 514.429 753.039 514.553 752.86 514.777L751.992 514.336C752.295 513.917 752.713 513.661 753.256 513.576C753.798 513.483 754.364 513.591 754.946 513.886C755.325 514.08 755.635 514.336 755.868 514.653C756.101 514.971 756.209 515.335 756.186 515.731C756.163 516.134 755.984 516.545 755.643 516.963L754.271 518.661C754.108 518.862 753.977 519.025 753.876 519.165C753.806 519.25 753.736 519.335 753.674 519.413C753.605 519.49 753.543 519.576 753.473 519.661L752.659 519.242C752.705 519.188 752.752 519.134 752.798 519.079C752.845 519.025 752.891 518.971 752.93 518.917C752.977 518.862 753.015 518.808 753.054 518.754C753.093 518.7 753.139 518.645 753.178 518.591C752.822 518.777 752.465 518.862 752.116 518.847C751.767 518.831 751.434 518.738 751.109 518.576L751.101 518.591ZM751.705 517.979C751.938 518.095 752.202 518.157 752.504 518.165C752.806 518.172 753.101 518.11 753.38 517.986C753.667 517.862 753.907 517.676 754.093 517.436L754.356 517.118L752.984 516.421C752.581 516.219 752.217 516.142 751.884 516.196C751.55 516.25 751.302 516.428 751.155 516.723C751.039 516.956 751.023 517.18 751.101 517.405C751.186 517.63 751.349 517.8 751.605 517.932L751.705 517.986V517.979Z"
          fill="black"
        />
        <path
          d="M755.628 523.575C755.271 523.389 754.961 523.165 754.713 522.893C754.465 522.622 754.302 522.32 754.217 521.986C754.139 521.653 754.17 521.312 754.31 520.955L755.178 521.397C755.101 521.669 755.139 521.94 755.294 522.203C755.449 522.467 755.697 522.684 756.038 522.855L756.132 522.901C756.589 523.134 757.031 523.188 757.449 523.064C757.868 522.932 758.255 522.653 758.62 522.211L759.085 521.653C758.798 521.777 758.488 521.839 758.162 521.824C757.829 521.808 757.511 521.723 757.201 521.56C756.86 521.39 756.581 521.149 756.356 520.839C756.132 520.529 756.008 520.165 755.977 519.738C755.946 519.312 756.062 518.839 756.318 518.335C756.62 517.746 757 517.281 757.457 516.948C757.914 516.615 758.403 516.421 758.907 516.367C759.41 516.312 759.891 516.398 760.333 516.63C760.775 516.855 761.092 517.149 761.286 517.514C761.48 517.878 761.565 518.242 761.542 518.615L762.247 517.731L763.108 518.173L759.325 522.831C759.015 523.211 758.674 523.506 758.294 523.707C757.914 523.909 757.504 524.002 757.054 523.986C756.604 523.971 756.132 523.839 755.62 523.575H755.628ZM757.775 520.94C758.131 521.118 758.488 521.173 758.852 521.103C759.217 521.033 759.558 520.862 759.868 520.599C760.178 520.335 760.426 520.025 760.612 519.661C760.775 519.335 760.86 519.025 760.868 518.739C760.868 518.452 760.806 518.196 760.674 517.963C760.534 517.739 760.341 517.56 760.092 517.429L759.992 517.374C759.705 517.227 759.387 517.181 759.038 517.235C758.689 517.289 758.348 517.444 758.023 517.7C757.697 517.956 757.418 518.304 757.201 518.731C756.953 519.219 756.883 519.653 756.992 520.041C757.1 520.428 757.333 520.707 757.69 520.893L757.783 520.94H757.775Z"
          fill="black"
        />
        <path
          d="M763.371 524.847C762.906 524.614 762.565 524.312 762.325 523.955C762.092 523.599 761.976 523.196 761.976 522.761C761.976 522.327 762.1 521.878 762.333 521.413C762.612 520.862 762.984 520.421 763.457 520.079C763.929 519.738 764.425 519.545 764.968 519.49C765.511 519.436 766.03 519.537 766.534 519.793C766.991 520.025 767.34 520.327 767.58 520.684C767.821 521.041 767.929 521.444 767.929 521.878C767.929 522.32 767.805 522.769 767.573 523.227C767.286 523.785 766.914 524.226 766.441 524.56C765.976 524.893 765.464 525.087 764.922 525.141C764.379 525.195 763.86 525.095 763.364 524.839L763.371 524.847ZM763.836 524.164C764.115 524.304 764.433 524.358 764.79 524.319C765.146 524.281 765.495 524.133 765.836 523.885C766.177 523.637 766.464 523.281 766.697 522.831C766.852 522.529 766.937 522.227 766.952 521.924C766.968 521.622 766.914 521.358 766.774 521.11C766.642 520.87 766.441 520.676 766.17 520.537L766.069 520.483C765.782 520.335 765.464 520.289 765.108 520.335C764.751 520.382 764.402 520.529 764.061 520.777C763.72 521.025 763.433 521.374 763.209 521.824C763.053 522.126 762.968 522.428 762.953 522.723C762.937 523.017 762.991 523.289 763.123 523.529C763.247 523.769 763.457 523.963 763.728 524.11L763.829 524.164H763.836Z"
          fill="black"
        />
        <path
          d="M769.193 527.808C768.728 527.575 768.386 527.273 768.146 526.916C767.914 526.56 767.797 526.157 767.797 525.723C767.797 525.289 767.921 524.839 768.154 524.374C768.433 523.823 768.805 523.382 769.278 523.041C769.743 522.7 770.247 522.506 770.789 522.451C771.332 522.397 771.851 522.498 772.355 522.754C772.812 522.986 773.161 523.289 773.402 523.645C773.634 524.002 773.75 524.405 773.75 524.839C773.75 525.273 773.634 525.73 773.394 526.188C773.107 526.746 772.735 527.188 772.262 527.521C771.797 527.854 771.285 528.048 770.743 528.102C770.2 528.157 769.681 528.056 769.185 527.8L769.193 527.808ZM769.658 527.126C769.937 527.265 770.255 527.319 770.611 527.281C770.968 527.242 771.316 527.095 771.658 526.847C771.999 526.599 772.285 526.242 772.518 525.792C772.673 525.49 772.758 525.188 772.774 524.885C772.789 524.583 772.727 524.312 772.595 524.072C772.464 523.831 772.262 523.637 771.991 523.498L771.89 523.444C771.603 523.296 771.285 523.25 770.929 523.296C770.572 523.343 770.223 523.49 769.882 523.738C769.541 523.986 769.255 524.335 769.03 524.785C768.875 525.087 768.789 525.389 768.774 525.684C768.758 525.978 768.813 526.25 768.945 526.49C769.069 526.73 769.278 526.924 769.549 527.071L769.65 527.126H769.658Z"
          fill="black"
        />
        <path
          d="M772.626 529.436L776.293 524.901L777.153 525.343L776.649 525.986C776.975 525.816 777.308 525.738 777.657 525.738C778.006 525.738 778.339 525.823 778.665 525.994C779.006 526.164 779.262 526.405 779.432 526.715C779.603 527.017 779.665 527.366 779.618 527.753C779.572 528.141 779.378 528.529 779.06 528.932L776.882 531.629L776.014 531.187L778.207 528.498C778.401 528.25 778.533 528.001 778.603 527.761C778.673 527.521 778.657 527.304 778.564 527.102C778.471 526.901 778.316 526.746 778.083 526.629L777.983 526.575C777.735 526.443 777.463 526.397 777.177 526.412C776.89 526.436 776.595 526.536 776.285 526.73C775.975 526.924 775.681 527.188 775.394 527.536L773.487 529.893L772.619 529.451L772.626 529.436Z"
          fill="black"
        />
        <path
          d="M781.075 533.738L786.215 527.389L788.912 528.761C789.315 528.97 789.641 529.219 789.889 529.505C790.129 529.8 790.276 530.11 790.331 530.451C790.385 530.792 790.307 531.149 790.121 531.521C789.881 531.986 789.532 532.319 789.075 532.505C788.618 532.691 788.129 532.722 787.61 532.598C787.866 532.885 788.021 533.195 788.067 533.544C788.114 533.885 788.052 534.226 787.881 534.559C787.687 534.931 787.416 535.234 787.067 535.459C786.711 535.683 786.315 535.807 785.874 535.815C785.432 535.823 784.998 535.73 784.579 535.513L781.083 533.73L781.075 533.738ZM784.905 534.637C785.292 534.831 785.68 534.893 786.067 534.815C786.455 534.738 786.765 534.466 786.998 534.017C787.16 533.699 787.184 533.397 787.075 533.118C786.967 532.838 786.718 532.598 786.339 532.404L784.285 531.358L782.571 533.459L784.889 534.637H784.905ZM787.091 531.73C787.486 531.932 787.873 531.986 788.269 531.885C788.656 531.792 788.951 531.552 789.145 531.172C789.315 530.831 789.338 530.529 789.199 530.257C789.059 529.986 788.78 529.738 788.346 529.521L786.532 528.598L784.897 530.606L787.091 531.722V531.73Z"
          fill="black"
        />
        <path
          d="M787.742 537.133L792.881 530.784L793.749 531.226L788.61 537.575L787.742 537.133Z"
          fill="black"
        />
        <path
          d="M791.997 539.435C791.602 539.233 791.323 538.978 791.16 538.667C790.997 538.357 790.943 538.016 791.013 537.644C791.082 537.272 791.269 536.9 791.571 536.528L793.764 533.8L794.64 534.249L792.4 537.016C792.113 537.365 791.982 537.706 792.005 538.04C792.028 538.373 792.199 538.621 792.524 538.784L792.625 538.838C792.881 538.97 793.16 539.016 793.462 538.985C793.764 538.954 794.067 538.838 794.369 538.652C794.671 538.466 794.95 538.202 795.214 537.877L797.121 535.52L797.989 535.962L794.323 540.497L793.462 540.055L794.02 539.365C793.687 539.551 793.354 539.66 793.02 539.675C792.687 539.691 792.346 539.613 792.013 539.443L791.997 539.435Z"
          fill="black"
        />
        <path
          d="M798.237 542.605C797.811 542.388 797.477 542.101 797.245 541.745C797.012 541.388 796.896 540.985 796.888 540.543C796.888 540.094 797.005 539.636 797.253 539.156C797.539 538.598 797.911 538.156 798.369 537.83C798.834 537.505 799.33 537.319 799.873 537.272C800.415 537.226 800.935 537.334 801.438 537.59C801.818 537.784 802.12 538.032 802.337 538.342C802.554 538.652 802.671 539.001 802.686 539.388C802.702 539.776 802.601 540.187 802.376 540.598C802.33 540.683 802.26 540.791 802.175 540.915C802.082 541.039 801.989 541.163 801.88 541.287L798.175 539.404C797.981 539.729 797.873 540.047 797.842 540.381C797.811 540.714 797.857 541.008 797.997 541.272C798.136 541.536 798.346 541.745 798.632 541.892L798.733 541.946C799.051 542.109 799.361 542.171 799.663 542.132C799.966 542.094 800.237 541.97 800.469 541.753L801.338 542.194C800.95 542.613 800.485 542.861 799.95 542.946C799.415 543.032 798.849 542.923 798.237 542.613V542.605ZM801.5 540.202C801.764 539.822 801.849 539.458 801.764 539.117C801.679 538.768 801.438 538.497 801.028 538.288L800.935 538.241C800.555 538.047 800.159 538.001 799.756 538.094C799.353 538.187 798.981 538.412 798.648 538.753L801.493 540.202H801.5Z"
          fill="black"
        />
        <path
          d="M313.202 488.384C312.412 480.803 308.148 474.431 302.164 470.664C302.567 468.478 302.668 466.207 302.428 463.881C301.087 450.975 289.685 441.557 276.825 442.588C272.562 433.836 263.159 428.255 252.92 429.317C247.92 429.836 243.44 431.875 239.89 434.914C235.789 432.666 230.991 431.596 225.991 432.115C215.744 433.177 207.698 440.588 205.326 450.029C192.707 451.835 183.669 463.308 184.994 476.09C185.242 478.454 185.832 480.702 186.7 482.787C181.708 487.702 178.91 494.755 179.685 502.259C180.468 509.755 184.646 516.072 190.529 519.855C190.126 522.048 190.017 524.343 190.258 526.676C191.583 539.451 202.776 548.814 215.481 548C219.721 556.814 229.154 562.449 239.44 561.38C244.44 560.86 248.92 558.822 252.47 555.783C256.571 558.031 261.369 559.101 266.368 558.581C276.647 557.512 284.716 550.055 287.057 540.567C299.839 538.908 309.04 527.358 307.699 514.46C307.458 512.173 306.9 509.995 306.079 507.964C311.148 503.049 314.001 495.941 313.21 488.368L313.202 488.384Z"
          fill="#577A59"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M222.689 507.553L222.302 499.391L227.852 497.933L227.898 498.887L223.34 500.088L223.449 502.623L227.356 501.6L227.394 502.553L223.495 503.576L223.619 506.344L228.301 505.111L228.34 506.065L222.674 507.553H222.689Z"
          fill="black"
        />
        <path
          d="M229.681 505.716L229.402 499.887L230.34 499.639L230.386 500.453C230.518 500.112 230.712 499.825 230.976 499.592C231.239 499.36 231.541 499.205 231.898 499.112C232.262 499.019 232.619 499.019 232.952 499.135C233.285 499.251 233.557 499.468 233.782 499.786C233.999 500.104 234.123 500.523 234.146 501.034L234.309 504.491L233.371 504.739L233.216 501.274C233.192 500.957 233.13 500.685 233.022 500.461C232.913 500.236 232.758 500.081 232.557 499.995C232.355 499.91 232.13 499.895 231.882 499.964L231.774 499.995C231.503 500.065 231.262 500.205 231.061 500.414C230.859 500.623 230.704 500.895 230.603 501.243C230.503 501.585 230.456 501.988 230.479 502.437L230.627 505.468L229.689 505.716H229.681Z"
          fill="black"
        />
        <path
          d="M238.649 503.476C238.169 503.6 237.727 503.615 237.308 503.53C236.898 503.437 236.533 503.236 236.231 502.933C235.929 502.623 235.712 502.22 235.58 501.724C235.402 501.057 235.378 500.429 235.51 499.856C235.642 499.274 235.898 498.794 236.278 498.399C236.657 498.011 237.122 497.74 237.681 497.592C238.099 497.484 238.494 497.461 238.859 497.53C239.223 497.6 239.541 497.755 239.804 498.003C240.068 498.251 240.246 498.561 240.347 498.933L239.409 499.181C239.316 498.817 239.122 498.569 238.843 498.437C238.564 498.313 238.239 498.298 237.867 498.391L237.758 498.422C237.394 498.515 237.099 498.709 236.867 499.003C236.634 499.29 236.479 499.647 236.417 500.073C236.347 500.491 236.378 500.941 236.502 501.414C236.634 501.925 236.874 502.29 237.215 502.522C237.557 502.755 237.967 502.809 238.44 502.685L238.541 502.662C238.898 502.569 239.169 502.383 239.378 502.112C239.58 501.84 239.688 501.522 239.696 501.166L240.634 500.918C240.665 501.538 240.494 502.081 240.122 502.546C239.75 503.011 239.262 503.321 238.649 503.484V503.476Z"
          fill="black"
        />
        <path
          d="M241.951 502.491L241.556 494.337L242.494 494.089L242.657 497.228C242.789 496.887 242.982 496.6 243.246 496.368C243.509 496.135 243.812 495.98 244.161 495.887C244.525 495.794 244.881 495.794 245.215 495.91C245.548 496.027 245.827 496.244 246.044 496.561C246.261 496.887 246.385 497.298 246.409 497.809L246.571 501.274L245.633 501.522L245.478 498.057C245.463 497.74 245.401 497.468 245.292 497.243C245.176 497.019 245.021 496.864 244.819 496.778C244.618 496.685 244.393 496.678 244.145 496.74L244.044 496.763C243.765 496.833 243.525 496.98 243.323 497.182C243.122 497.391 242.967 497.662 242.866 498.003C242.765 498.344 242.719 498.747 242.742 499.205L242.889 502.235L241.951 502.484V502.491Z"
          fill="black"
        />
        <path
          d="M249.92 500.515C249.618 500.592 249.323 500.608 249.036 500.546C248.749 500.484 248.501 500.36 248.284 500.158C248.067 499.957 247.912 499.701 247.827 499.375C247.734 499.019 247.742 498.678 247.858 498.344C247.974 498.011 248.184 497.709 248.494 497.453C248.804 497.197 249.191 497.011 249.656 496.895L251.354 496.437V496.213C251.331 495.926 251.245 495.701 251.09 495.531C250.935 495.368 250.749 495.267 250.517 495.22C250.292 495.182 250.059 495.189 249.819 495.251L249.749 495.267C249.424 495.352 249.16 495.507 248.966 495.732C248.765 495.957 248.672 496.213 248.687 496.499L247.75 496.747C247.695 496.236 247.843 495.763 248.184 495.337C248.525 494.91 249.021 494.608 249.656 494.445C250.067 494.337 250.47 494.321 250.858 494.407C251.245 494.492 251.563 494.686 251.819 494.996C252.067 495.306 252.207 495.732 252.238 496.282L252.338 498.461C252.354 498.716 252.362 498.933 252.377 499.096C252.377 499.205 252.385 499.313 252.393 499.414C252.393 499.523 252.4 499.623 252.408 499.732L251.524 499.964C251.524 499.895 251.524 499.825 251.524 499.755C251.524 499.685 251.524 499.616 251.509 499.546C251.509 499.476 251.509 499.406 251.493 499.344C251.478 499.282 251.486 499.212 251.478 499.143C251.331 499.523 251.129 499.817 250.858 500.042C250.587 500.259 250.276 500.422 249.928 500.515H249.92ZM249.966 499.654C250.214 499.592 250.455 499.461 250.68 499.259C250.904 499.065 251.083 498.825 251.214 498.538C251.346 498.251 251.4 497.957 251.385 497.647L251.369 497.236L249.881 497.623C249.447 497.74 249.121 497.926 248.912 498.189C248.695 498.453 248.633 498.747 248.718 499.073C248.788 499.321 248.92 499.499 249.129 499.616C249.339 499.724 249.579 499.747 249.85 499.678L249.959 499.647L249.966 499.654Z"
          fill="black"
        />
        <path
          d="M254.028 499.321L253.749 493.492L254.687 493.244L254.733 494.058C254.865 493.717 255.059 493.43 255.323 493.197C255.586 492.965 255.896 492.81 256.245 492.717C256.609 492.624 256.966 492.631 257.299 492.74C257.632 492.856 257.904 493.073 258.129 493.391C258.346 493.709 258.47 494.128 258.493 494.639L258.656 498.096L257.718 498.344L257.563 494.879C257.539 494.562 257.477 494.29 257.369 494.066C257.26 493.841 257.105 493.686 256.904 493.6C256.702 493.515 256.478 493.5 256.229 493.569L256.121 493.6C255.85 493.67 255.609 493.81 255.408 494.019C255.206 494.228 255.051 494.5 254.951 494.848C254.85 495.189 254.803 495.593 254.819 496.042L254.966 499.073L254.028 499.321Z"
          fill="black"
        />
        <path
          d="M262.035 497.213C261.632 497.321 261.307 497.282 261.066 497.104C260.826 496.926 260.702 496.639 260.694 496.244L260.501 492.577L259.229 492.91L259.183 492.066L260.477 491.724L260.392 489.833L261.307 489.593L261.392 491.484L262.795 491.112L262.842 491.965L261.431 492.337L261.609 495.972C261.609 496.12 261.671 496.22 261.764 496.282C261.865 496.337 261.981 496.352 262.121 496.313L263.322 495.996L263.361 496.871L262.035 497.22V497.213Z"
          fill="black"
        />
        <path
          d="M266.973 496.034C266.508 496.158 266.074 496.158 265.663 496.05C265.252 495.934 264.896 495.717 264.593 495.383C264.291 495.05 264.074 494.624 263.942 494.104C263.787 493.5 263.764 492.926 263.896 492.368C264.02 491.818 264.276 491.345 264.648 490.949C265.02 490.554 265.477 490.291 266.027 490.143C266.438 490.035 266.826 490.019 267.198 490.105C267.562 490.19 267.888 490.376 268.159 490.655C268.43 490.934 268.624 491.306 268.733 491.763C268.756 491.856 268.779 491.98 268.795 492.135C268.81 492.29 268.818 492.445 268.826 492.608L264.81 493.662C264.88 494.027 265.012 494.345 265.206 494.616C265.399 494.887 265.632 495.073 265.911 495.182C266.19 495.29 266.485 495.306 266.795 495.22L266.903 495.19C267.252 495.096 267.523 494.941 267.725 494.709C267.926 494.476 268.043 494.205 268.074 493.887L269.012 493.639C268.996 494.213 268.818 494.709 268.484 495.127C268.143 495.546 267.647 495.841 266.981 496.019L266.973 496.034ZM267.81 492.073C267.756 491.616 267.578 491.291 267.283 491.081C266.988 490.88 266.624 490.833 266.182 490.949L266.082 490.973C265.671 491.081 265.345 491.306 265.105 491.647C264.865 491.988 264.733 492.399 264.717 492.872L267.81 492.058V492.073Z"
          fill="black"
        />
        <path
          d="M272.864 494.492C272.445 494.6 272.042 494.608 271.655 494.5C271.267 494.391 270.926 494.189 270.639 493.872C270.353 493.554 270.143 493.135 270.004 492.624C269.841 491.988 269.818 491.391 269.942 490.825C270.066 490.259 270.298 489.779 270.655 489.384C271.011 488.988 271.438 488.732 271.949 488.593C272.383 488.477 272.802 488.477 273.205 488.593C273.608 488.709 273.926 488.911 274.158 489.205L273.988 485.818L274.957 485.562L275.321 493.724L274.391 493.965L274.36 493.252C274.267 493.515 274.089 493.763 273.81 493.996C273.531 494.228 273.213 494.391 272.872 494.484L272.864 494.492ZM272.872 493.624C273.252 493.523 273.562 493.314 273.794 493.003C274.027 492.693 274.174 492.321 274.236 491.887C274.298 491.461 274.267 491.027 274.151 490.585C274.065 490.267 273.934 490.004 273.74 489.802C273.546 489.601 273.321 489.453 273.066 489.376C272.81 489.298 272.546 489.291 272.275 489.368L272.166 489.399C271.833 489.484 271.562 489.67 271.337 489.957C271.112 490.244 270.965 490.593 270.887 491.011C270.81 491.422 270.833 491.856 270.949 492.306C271.081 492.81 271.314 493.19 271.655 493.438C271.988 493.686 272.368 493.755 272.779 493.655L272.879 493.631L272.872 493.624Z"
          fill="black"
        />
        <path
          d="M738.226 377.622C736.784 370.134 731.986 364.158 725.692 360.926C725.901 358.717 725.808 356.438 725.366 354.151C722.909 341.407 710.739 333.02 698.012 335.16C693.004 326.811 683.152 322.067 673.044 324.013C668.107 324.966 663.828 327.377 660.549 330.718C656.27 328.834 651.395 328.183 646.457 329.137C636.342 331.09 628.962 339.159 627.42 348.771C615.002 351.663 606.995 363.871 609.429 376.498C609.878 378.832 610.661 381.018 611.708 383.025C607.158 388.351 604.98 395.621 606.414 403.032C607.84 410.435 612.553 416.364 618.738 419.62C618.521 421.845 618.614 424.131 619.056 426.434C621.49 439.045 633.443 447.401 646.031 445.495C651.015 453.905 660.906 458.703 671.06 456.75C675.998 455.796 680.277 453.386 683.555 450.045C687.834 451.928 692.71 452.579 697.647 451.626C707.794 449.673 715.189 441.541 716.692 431.883C729.281 429.123 737.443 416.814 734.994 404.086C734.559 401.823 733.808 399.706 732.815 397.753C737.443 392.412 739.668 385.087 738.226 377.615V377.622Z"
          fill="#424544"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M642.047 376.413C641.489 376.134 641.047 375.793 640.706 375.39C640.372 374.987 640.179 374.553 640.14 374.088C640.101 373.623 640.225 373.15 640.52 372.677L641.411 373.119C641.132 373.599 641.093 374.057 641.287 374.483C641.481 374.909 641.845 375.258 642.372 375.522L642.473 375.576C642.961 375.824 643.411 375.917 643.829 375.855C644.24 375.793 644.543 375.584 644.729 375.212C644.868 374.94 644.891 374.661 644.798 374.382C644.705 374.103 644.465 373.77 644.07 373.382L643.442 372.77C643.008 372.351 642.729 371.933 642.62 371.53C642.512 371.127 642.558 370.708 642.783 370.266C642.992 369.848 643.295 369.538 643.69 369.328C644.085 369.119 644.527 369.018 645.015 369.034C645.504 369.049 645.984 369.173 646.473 369.421C646.969 369.669 647.364 369.987 647.659 370.367C647.953 370.747 648.124 371.165 648.163 371.607C648.209 372.057 648.116 372.491 647.883 372.91L646.969 372.452C647.186 372.003 647.217 371.584 647.046 371.204C646.884 370.824 646.558 370.514 646.07 370.266L645.969 370.212C645.667 370.065 645.372 369.979 645.077 369.964C644.783 369.948 644.519 369.995 644.279 370.111C644.039 370.228 643.868 370.398 643.752 370.631C643.636 370.863 643.612 371.096 643.69 371.328C643.767 371.561 643.923 371.801 644.163 372.049L645.031 372.894C645.473 373.344 645.752 373.801 645.86 374.251C645.969 374.7 645.915 375.142 645.69 375.576C645.488 375.971 645.209 376.274 644.829 376.491C644.457 376.7 644.023 376.801 643.543 376.793C643.054 376.785 642.558 376.654 642.047 376.398V376.413Z"
          fill="white"
        />
        <path
          d="M647.31 378.925C646.938 378.739 646.721 378.499 646.659 378.204C646.597 377.909 646.69 377.615 646.946 377.305L649.217 374.421L648.046 373.832L648.573 373.165L649.767 373.762L650.953 372.29L651.798 372.716L650.612 374.189L651.906 374.84L651.379 375.506L650.077 374.855L647.806 377.7C647.713 377.816 647.682 377.925 647.721 378.033C647.759 378.142 647.837 378.227 647.969 378.289L649.077 378.847L648.527 379.529L647.302 378.917L647.31 378.925Z"
          fill="white"
        />
        <path
          d="M651.829 381.32C651.364 381.087 651.015 380.793 650.775 380.436C650.534 380.08 650.418 379.684 650.418 379.242C650.418 378.808 650.534 378.359 650.767 377.894C651.046 377.343 651.41 376.894 651.875 376.553C652.34 376.212 652.844 376.01 653.379 375.956C653.922 375.902 654.441 375.995 654.945 376.25C655.41 376.483 655.759 376.777 655.999 377.134C656.239 377.491 656.356 377.886 656.356 378.328C656.356 378.77 656.239 379.219 656.007 379.684C655.728 380.242 655.356 380.692 654.891 381.025C654.426 381.359 653.922 381.56 653.379 381.614C652.837 381.669 652.317 381.576 651.821 381.32H651.829ZM652.286 380.63C652.565 380.769 652.883 380.816 653.24 380.777C653.596 380.738 653.945 380.591 654.286 380.335C654.627 380.08 654.906 379.731 655.139 379.273C655.294 378.963 655.379 378.661 655.387 378.367C655.402 378.064 655.34 377.793 655.209 377.553C655.077 377.312 654.875 377.119 654.596 376.987L654.495 376.932C654.209 376.785 653.891 376.739 653.534 376.785C653.178 376.832 652.829 376.979 652.496 377.235C652.155 377.483 651.875 377.839 651.651 378.289C651.496 378.591 651.418 378.894 651.403 379.196C651.387 379.49 651.449 379.762 651.581 380.002C651.713 380.242 651.914 380.436 652.193 380.576L652.294 380.63H652.286Z"
          fill="white"
        />
        <path
          d="M655.278 382.925L658.921 378.367L659.79 378.801L659.286 379.444C659.611 379.274 659.945 379.188 660.293 379.188C660.642 379.188 660.983 379.274 661.309 379.436C661.65 379.607 661.906 379.847 662.084 380.15C662.262 380.452 662.324 380.801 662.278 381.18C662.231 381.568 662.045 381.956 661.727 382.359L659.565 385.064L658.697 384.63L660.867 381.925C661.061 381.677 661.193 381.429 661.255 381.188C661.317 380.948 661.309 380.731 661.216 380.529C661.123 380.328 660.96 380.173 660.735 380.057L660.634 380.002C660.379 379.878 660.115 379.824 659.828 379.847C659.542 379.87 659.247 379.979 658.937 380.165C658.635 380.359 658.34 380.63 658.053 380.979L656.162 383.351L655.294 382.917L655.278 382.925Z"
          fill="white"
        />
        <path
          d="M663.425 387.134C662.999 386.917 662.665 386.63 662.433 386.281C662.2 385.924 662.076 385.521 662.068 385.08C662.068 384.63 662.177 384.173 662.425 383.684C662.704 383.126 663.076 382.684 663.534 382.351C663.991 382.018 664.495 381.832 665.03 381.777C665.572 381.731 666.091 381.832 666.595 382.08C666.975 382.273 667.277 382.522 667.502 382.824C667.719 383.134 667.843 383.483 667.859 383.87C667.874 384.266 667.774 384.669 667.556 385.087C667.51 385.173 667.448 385.281 667.355 385.413C667.262 385.545 667.169 385.661 667.06 385.793L663.347 383.932C663.161 384.258 663.053 384.583 663.014 384.909C662.983 385.235 663.037 385.537 663.177 385.8C663.316 386.064 663.526 386.273 663.813 386.413L663.913 386.467C664.231 386.63 664.541 386.684 664.843 386.645C665.146 386.607 665.409 386.475 665.65 386.266L666.518 386.7C666.13 387.118 665.673 387.374 665.138 387.467C664.603 387.56 664.037 387.451 663.425 387.141V387.134ZM666.665 384.715C666.921 384.335 667.014 383.971 666.921 383.622C666.836 383.273 666.588 383.002 666.177 382.801L666.084 382.754C665.704 382.56 665.309 382.514 664.905 382.615C664.495 382.715 664.13 382.932 663.805 383.281L666.665 384.715Z"
          fill="white"
        />
        <path
          d="M672.641 391.753C672.045 391.451 671.572 391.064 671.238 390.583C670.905 390.102 670.719 389.575 670.688 389.002C670.665 388.428 670.797 387.839 671.099 387.242C671.502 386.436 672.021 385.785 672.657 385.273C673.3 384.762 673.998 384.459 674.765 384.359C675.533 384.258 676.3 384.397 677.067 384.785C677.548 385.025 677.936 385.335 678.23 385.723C678.525 386.103 678.695 386.529 678.749 386.994C678.804 387.459 678.718 387.932 678.494 388.413L677.579 387.955C677.804 387.498 677.819 387.056 677.641 386.63C677.463 386.203 677.114 385.862 676.602 385.607L676.502 385.552C676.005 385.304 675.478 385.219 674.92 385.296C674.362 385.382 673.827 385.622 673.331 386.017C672.827 386.42 672.417 386.94 672.091 387.583C671.858 388.056 671.734 388.498 671.727 388.916C671.719 389.335 671.835 389.723 672.068 390.071C672.3 390.42 672.649 390.707 673.114 390.94L673.215 390.994C673.525 391.149 673.843 391.242 674.184 391.273C674.525 391.304 674.843 391.257 675.161 391.133C675.471 391.009 675.742 390.8 675.967 390.505L676.881 390.963C676.564 391.397 676.176 391.722 675.726 391.932C675.277 392.141 674.789 392.234 674.261 392.203C673.734 392.18 673.199 392.025 672.657 391.753H672.641Z"
          fill="white"
        />
        <path
          d="M678.804 394.839C678.339 394.606 677.99 394.312 677.749 393.955C677.509 393.598 677.393 393.203 677.393 392.761C677.393 392.327 677.509 391.878 677.742 391.412C678.021 390.862 678.385 390.413 678.85 390.071C679.315 389.73 679.819 389.529 680.354 389.475C680.897 389.42 681.416 389.513 681.92 389.769C682.385 390.002 682.734 390.296 682.974 390.653C683.214 391.009 683.33 391.412 683.33 391.847C683.33 392.288 683.214 392.738 682.982 393.203C682.703 393.761 682.331 394.211 681.865 394.544C681.4 394.877 680.897 395.079 680.354 395.133C679.811 395.187 679.292 395.094 678.796 394.846L678.804 394.839ZM679.261 394.149C679.54 394.288 679.858 394.335 680.214 394.296C680.571 394.257 680.92 394.11 681.261 393.854C681.602 393.598 681.881 393.25 682.114 392.792C682.269 392.49 682.354 392.18 682.362 391.885C682.377 391.583 682.315 391.312 682.183 391.071C682.052 390.831 681.842 390.637 681.571 390.506L681.47 390.451C681.183 390.312 680.866 390.258 680.509 390.304C680.152 390.351 679.804 390.498 679.47 390.754C679.129 391.002 678.85 391.358 678.625 391.808C678.47 392.11 678.385 392.412 678.377 392.715C678.37 393.017 678.424 393.281 678.556 393.521C678.687 393.761 678.889 393.955 679.168 394.095L679.269 394.149H679.261Z"
          fill="white"
        />
        <path
          d="M682.245 396.443L687.346 390.064L688.214 390.498L683.113 396.877L682.245 396.443Z"
          fill="white"
        />
        <path
          d="M686.896 398.9C686.509 398.707 686.206 398.443 685.981 398.11C685.757 397.776 685.64 397.397 685.633 396.963C685.633 396.536 685.741 396.079 685.981 395.606C686.276 395.025 686.656 394.56 687.121 394.211C687.586 393.87 688.082 393.66 688.609 393.606C689.136 393.552 689.632 393.629 690.097 393.87C690.501 394.071 690.811 394.35 691.043 394.699C691.268 395.056 691.376 395.412 691.361 395.792L693.469 393.133L694.368 393.583L689.245 399.955L688.384 399.528L688.834 398.97C688.594 399.11 688.291 399.172 687.927 399.164C687.563 399.156 687.222 399.071 686.896 398.908V398.9ZM687.47 398.257C687.826 398.435 688.191 398.482 688.57 398.404C688.95 398.319 689.307 398.141 689.632 397.862C689.958 397.575 690.229 397.234 690.431 396.823C690.578 396.528 690.648 396.242 690.64 395.963C690.632 395.684 690.555 395.428 690.415 395.195C690.276 394.97 690.082 394.792 689.826 394.668L689.725 394.614C689.423 394.459 689.09 394.42 688.733 394.482C688.377 394.552 688.036 394.715 687.702 394.97C687.369 395.234 687.105 395.567 686.896 395.986C686.664 396.459 686.586 396.893 686.679 397.304C686.772 397.714 687.005 398.017 687.377 398.211L687.47 398.257Z"
          fill="white"
        />
        <path
          d="M693.5 402.086L698.601 395.707L703.538 398.18L702.934 398.924L698.926 396.916L697.19 399.071L700.701 400.831L700.112 401.582L696.601 399.823L694.415 402.544L693.493 402.086H693.5Z"
          fill="white"
        />
        <path
          d="M701.787 406.365C701.321 406.132 700.973 405.838 700.732 405.481C700.492 405.125 700.376 404.729 700.376 404.288C700.376 403.846 700.492 403.404 700.725 402.939C701.004 402.389 701.368 401.939 701.833 401.598C702.298 401.257 702.802 401.055 703.337 401.001C703.879 400.947 704.399 401.04 704.903 401.296C705.368 401.528 705.717 401.823 705.957 402.179C706.189 402.536 706.313 402.931 706.313 403.373C706.313 403.815 706.197 404.264 705.965 404.729C705.686 405.288 705.313 405.737 704.848 406.07C704.383 406.404 703.879 406.605 703.337 406.66C702.794 406.714 702.275 406.621 701.779 406.365H701.787ZM702.244 405.675C702.523 405.815 702.841 405.861 703.197 405.822C703.554 405.784 703.903 405.636 704.244 405.381C704.585 405.125 704.864 404.776 705.096 404.319C705.251 404.009 705.337 403.706 705.344 403.412C705.36 403.109 705.298 402.838 705.166 402.598C705.034 402.357 704.825 402.164 704.554 402.032L704.453 401.978C704.166 401.83 703.848 401.784 703.492 401.83C703.135 401.877 702.786 402.024 702.453 402.28C702.112 402.528 701.833 402.885 701.608 403.334C701.453 403.644 701.368 403.939 701.36 404.241C701.345 404.536 701.407 404.807 701.539 405.047C701.67 405.288 701.872 405.481 702.151 405.621L702.252 405.675H702.244Z"
          fill="white"
        />
        <path
          d="M704.414 407.551L708.22 406.179L708.119 403.024L709.057 403.497L709.119 405.838L711.77 404.854L712.84 405.388L709.143 406.729L709.243 409.97L708.298 409.497L708.251 407.07L705.484 408.086L704.422 407.551H704.414Z"
          fill="white"
        />
        <path
          d="M935.305 556.364C941.653 552.14 945.312 545.404 945.862 538.35C947.986 537.691 950.048 536.722 951.986 535.435C962.791 528.25 965.83 513.778 958.939 502.863C964.706 495.019 965.279 484.105 959.574 475.532C956.791 471.346 952.908 468.331 948.567 466.602C948.652 461.928 947.366 457.184 944.583 452.998C938.878 444.425 928.576 440.735 919.12 443.03C911.655 432.697 897.3 430.023 886.595 437.146C884.618 438.464 882.898 440.03 881.456 441.766C874.782 439.627 867.24 440.425 860.953 444.611C854.675 448.789 851.024 455.424 850.412 462.393C848.28 463.052 846.203 464.021 844.249 465.323C833.552 472.439 830.467 486.694 837.095 497.569C831.266 505.422 830.653 516.39 836.382 525.002C839.164 529.188 843.048 532.203 847.388 533.932C847.303 538.606 848.59 543.35 851.373 547.535C857.101 556.14 867.449 559.822 876.937 557.481C884.347 568.023 898.85 570.798 909.64 563.62C911.554 562.349 913.221 560.837 914.639 559.163C921.352 561.364 928.964 560.589 935.305 556.372V556.364Z"
          fill="#D2AC00"
          stroke="black"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M879.564 487.624C879.115 487.128 878.82 486.593 878.696 486.027C878.564 485.454 878.595 484.896 878.789 484.337C878.983 483.779 879.324 483.291 879.797 482.849C880.456 482.244 881.184 481.826 881.96 481.593C882.743 481.361 883.51 481.338 884.262 481.531C885.014 481.725 885.688 482.151 886.285 482.795C886.672 483.213 886.928 483.679 887.068 484.182C887.207 484.686 887.207 485.175 887.083 485.655C886.959 486.136 886.711 486.539 886.339 486.88L885.649 486.12C886.037 485.702 886.215 485.244 886.176 484.756C886.138 484.268 885.936 483.81 885.556 483.384L885.479 483.299C885.06 482.841 884.572 482.555 883.998 482.431C883.425 482.306 882.843 482.345 882.246 482.531C881.65 482.725 881.107 483.043 880.619 483.485C880.223 483.849 879.936 484.237 879.774 484.647C879.611 485.058 879.564 485.461 879.65 485.857C879.735 486.252 879.929 486.616 880.239 486.965L880.293 487.027C880.58 487.345 880.921 487.578 881.316 487.733C881.712 487.888 882.13 487.957 882.58 487.942C883.029 487.926 883.479 487.818 883.944 487.624H883.936L882.262 485.802L883.068 485.314L884.649 487.043C884.874 487.291 884.967 487.539 884.913 487.779C884.866 488.027 884.68 488.244 884.37 488.422L881.836 489.934L881.239 489.275L882.378 488.608C882.076 488.663 881.75 488.663 881.409 488.608C881.068 488.554 880.735 488.446 880.417 488.275C880.099 488.105 879.812 487.888 879.572 487.624H879.564Z"
          fill="black"
        />
        <path
          d="M884.556 493.081C884.208 492.701 883.983 492.298 883.89 491.88C883.797 491.461 883.828 491.05 883.983 490.64C884.146 490.229 884.409 489.857 884.797 489.508C885.254 489.089 885.758 488.81 886.316 488.655C886.874 488.5 887.409 488.492 887.936 488.632C888.463 488.771 888.913 489.05 889.292 489.461C889.641 489.841 889.866 490.244 889.959 490.663C890.052 491.081 890.021 491.5 889.866 491.903C889.711 492.314 889.44 492.694 889.06 493.042C888.603 493.461 888.091 493.748 887.533 493.895C886.975 494.042 886.432 494.042 885.905 493.895C885.378 493.748 884.928 493.477 884.556 493.066V493.081ZM885.231 492.601C885.44 492.833 885.719 492.988 886.068 493.081C886.417 493.166 886.796 493.159 887.199 493.042C887.603 492.926 887.998 492.701 888.37 492.36C888.626 492.128 888.812 491.88 888.928 491.601C889.044 491.322 889.091 491.05 889.052 490.779C889.013 490.508 888.889 490.26 888.688 490.035L888.61 489.95C888.393 489.717 888.114 489.554 887.765 489.469C887.417 489.384 887.037 489.399 886.634 489.516C886.231 489.632 885.835 489.857 885.471 490.198C885.215 490.43 885.029 490.678 884.913 490.95C884.797 491.221 884.75 491.492 884.789 491.771C884.82 492.042 884.944 492.298 885.153 492.523L885.231 492.608V492.601Z"
          fill="black"
        />
        <path
          d="M887.2 495.81L894.246 491.678L894.897 492.391L887.851 496.523L887.2 495.81Z"
          fill="black"
        />
        <path
          d="M890.664 499.763C890.37 499.445 890.184 499.088 890.091 498.693C889.998 498.298 890.029 497.902 890.176 497.499C890.323 497.096 890.595 496.716 890.99 496.36C891.471 495.918 891.99 495.616 892.556 495.468C893.114 495.313 893.649 495.298 894.16 495.43C894.672 495.561 895.106 495.817 895.455 496.205C895.757 496.538 895.951 496.91 896.036 497.321C896.121 497.732 896.09 498.104 895.943 498.453L898.865 496.724L899.54 497.468L892.478 501.584L891.835 500.879L892.447 500.515C892.176 500.553 891.874 500.515 891.533 500.367C891.192 500.228 890.905 500.026 890.664 499.763ZM891.432 499.367C891.695 499.662 892.029 499.833 892.409 499.895C892.796 499.957 893.191 499.91 893.594 499.763C894.005 499.616 894.377 499.391 894.711 499.081C894.951 498.864 895.122 498.616 895.215 498.352C895.308 498.088 895.331 497.825 895.277 497.561C895.23 497.298 895.106 497.065 894.92 496.856L894.842 496.771C894.61 496.515 894.315 496.36 893.959 496.298C893.602 496.236 893.222 496.267 892.819 496.391C892.416 496.515 892.044 496.732 891.703 497.05C891.316 497.406 891.091 497.786 891.029 498.197C890.967 498.608 891.075 498.972 891.362 499.298L891.432 499.375V499.367Z"
          fill="black"
        />
        <path
          d="M893.664 502.879L898.695 499.926L899.346 500.639L894.315 503.6L893.664 502.887V502.879ZM899.54 499.329L900.563 498.717L901.292 499.515L900.268 500.12L899.54 499.321V499.329Z"
          fill="black"
        />
        <path
          d="M895.494 504.887L902.54 500.755L903.191 501.468L896.145 505.6L895.494 504.887Z"
          fill="black"
        />
        <path
          d="M899.082 508.979C898.734 508.6 898.509 508.197 898.416 507.778C898.323 507.359 898.354 506.949 898.509 506.538C898.664 506.127 898.935 505.755 899.323 505.406C899.78 504.987 900.284 504.708 900.842 504.553C901.4 504.398 901.943 504.391 902.462 504.53C902.981 504.67 903.439 504.949 903.818 505.359C904.167 505.739 904.392 506.142 904.485 506.561C904.578 506.98 904.547 507.398 904.392 507.809C904.237 508.22 903.966 508.6 903.586 508.948C903.129 509.367 902.617 509.654 902.059 509.801C901.501 509.948 900.958 509.948 900.439 509.801C899.912 509.662 899.462 509.382 899.082 508.972V508.979ZM899.757 508.499C899.966 508.731 900.245 508.886 900.594 508.979C900.943 509.065 901.323 509.057 901.726 508.941C902.129 508.824 902.524 508.6 902.904 508.251C903.16 508.018 903.346 507.77 903.462 507.491C903.578 507.212 903.625 506.941 903.586 506.669C903.547 506.398 903.423 506.15 903.222 505.925L903.144 505.84C902.927 505.608 902.648 505.445 902.299 505.359C901.95 505.274 901.571 505.29 901.167 505.406C900.764 505.522 900.369 505.747 900.005 506.088C899.749 506.321 899.563 506.569 899.447 506.84C899.33 507.111 899.284 507.383 899.323 507.662C899.354 507.933 899.478 508.189 899.687 508.414L899.765 508.499H899.757Z"
          fill="black"
        />
        <path
          d="M694.4 159.317C693.927 159.789 693.407 160.107 692.841 160.262C692.276 160.417 691.71 160.41 691.152 160.239C690.586 160.069 690.082 159.758 689.625 159.301C688.989 158.665 688.539 157.96 688.268 157.193C687.997 156.425 687.943 155.658 688.105 154.891C688.268 154.123 688.656 153.433 689.276 152.813C689.679 152.41 690.128 152.131 690.625 151.968C691.121 151.813 691.609 151.782 692.097 151.891C692.578 151.999 692.996 152.224 693.353 152.581L692.624 153.309C692.19 152.937 691.725 152.782 691.237 152.844C690.749 152.906 690.299 153.131 689.896 153.518L689.818 153.596C689.384 154.03 689.113 154.542 689.02 155.115C688.927 155.689 688.981 156.27 689.198 156.859C689.415 157.448 689.756 157.976 690.229 158.448C690.609 158.828 691.012 159.092 691.431 159.239C691.849 159.386 692.252 159.41 692.64 159.309C693.028 159.208 693.392 158.999 693.717 158.665L693.772 158.611C694.074 158.309 694.291 157.96 694.431 157.557C694.57 157.154 694.617 156.735 694.578 156.286C694.539 155.836 694.415 155.394 694.198 154.937H694.206L692.454 156.697L691.927 155.914L693.578 154.263C693.81 154.022 694.058 153.929 694.306 153.968C694.554 154.007 694.779 154.177 694.973 154.487L696.593 156.952L695.965 157.58L695.244 156.472C695.306 156.774 695.322 157.092 695.291 157.433C695.26 157.774 695.159 158.107 695.004 158.441C694.849 158.766 694.648 159.061 694.392 159.309L694.4 159.317Z"
          fill="white"
        />
        <path
          d="M697.624 155.937L694.446 151.046L695.128 150.364L695.748 151.325C695.616 150.914 695.585 150.526 695.655 150.17C695.733 149.813 695.927 149.48 696.244 149.162L696.554 148.852L697.089 149.658L696.787 149.961C696.43 150.317 696.244 150.743 696.229 151.24C696.213 151.736 696.399 152.294 696.787 152.914L698.306 155.255L697.616 155.945L697.624 155.937Z"
          fill="white"
        />
        <path
          d="M702.143 151.581C701.833 151.891 701.5 152.077 701.151 152.139C700.802 152.201 700.469 152.139 700.136 151.96C699.802 151.782 699.508 151.488 699.244 151.084L697.33 148.154L698.027 147.457L699.965 150.441C700.205 150.821 700.492 151.053 700.81 151.139C701.128 151.224 701.422 151.131 701.678 150.875L701.763 150.798C701.965 150.596 702.104 150.34 702.159 150.046C702.221 149.743 702.213 149.426 702.12 149.085C702.035 148.743 701.88 148.395 701.647 148.046L699.996 145.503L700.686 144.813L703.864 149.705L703.182 150.387L702.693 149.643C702.771 150.022 702.763 150.371 702.678 150.689C702.593 151.015 702.414 151.309 702.143 151.581Z"
          fill="white"
        />
        <path
          d="M707.43 146.294C707.104 146.62 706.732 146.829 706.313 146.922C705.895 147.007 705.523 146.984 705.189 146.829L705.794 147.775L705.104 148.465L700.655 141.612L701.345 140.922L702.996 143.465C702.973 143.147 703.011 142.829 703.12 142.519C703.228 142.209 703.414 141.922 703.67 141.667C703.957 141.38 704.298 141.178 704.678 141.077C705.058 140.977 705.453 140.984 705.856 141.108C706.259 141.232 706.639 141.473 707.003 141.837C707.484 142.318 707.817 142.829 708.011 143.38C708.205 143.93 708.243 144.465 708.143 144.976C708.042 145.488 707.802 145.93 707.43 146.302V146.294ZM706.802 145.759C707.042 145.519 707.189 145.224 707.259 144.868C707.329 144.511 707.282 144.131 707.135 143.721C706.988 143.318 706.732 142.93 706.368 142.566C706.003 142.201 705.623 142 705.228 141.946C704.825 141.891 704.476 142.023 704.166 142.325L704.096 142.395C703.81 142.682 703.647 143.023 703.6 143.411C703.554 143.806 703.624 144.201 703.794 144.597C703.965 144.992 704.213 145.348 704.531 145.674C704.763 145.907 705.011 146.062 705.275 146.155C705.538 146.24 705.802 146.263 706.058 146.201C706.313 146.147 706.538 146.023 706.732 145.829L706.802 145.759Z"
          fill="white"
        />
        <path
          d="M712.243 141.473C711.918 141.798 711.546 142.007 711.127 142.1C710.708 142.186 710.336 142.163 710.003 142.007L710.608 142.953L709.918 143.643L705.468 136.791L706.151 136.101L707.802 138.643C707.771 138.326 707.817 138.008 707.926 137.698C708.034 137.388 708.22 137.101 708.476 136.845C708.771 136.55 709.104 136.357 709.484 136.256C709.863 136.155 710.259 136.163 710.662 136.287C711.065 136.411 711.445 136.651 711.809 137.016C712.29 137.496 712.623 138.008 712.817 138.558C713.011 139.108 713.049 139.643 712.949 140.155C712.848 140.666 712.607 141.108 712.235 141.48L712.243 141.473ZM711.615 140.938C711.848 140.697 712.003 140.403 712.073 140.046C712.142 139.69 712.104 139.31 711.949 138.899C711.801 138.496 711.546 138.108 711.181 137.744C710.817 137.38 710.437 137.178 710.042 137.124C709.639 137.07 709.29 137.202 708.98 137.504L708.91 137.574C708.623 137.86 708.46 138.201 708.414 138.589C708.375 138.984 708.437 139.38 708.608 139.775C708.778 140.17 709.026 140.527 709.344 140.853C709.577 141.085 709.825 141.24 710.088 141.333C710.352 141.418 710.615 141.434 710.871 141.38C711.127 141.325 711.352 141.201 711.546 141.008L711.615 140.938Z"
          fill="white"
        />
        <path
          d="M715.537 140.891L715.057 140.155L716.072 139.139C716.15 139.062 716.196 138.984 716.227 138.907C716.258 138.829 716.258 138.744 716.243 138.651C716.22 138.558 716.173 138.45 716.088 138.326L715.382 137.233L715.933 137.814L710.894 134.566L711.615 133.845L715.669 136.473L715.351 136.705L714.197 131.256L714.964 130.489L716.406 137.326L716.111 136.589L716.964 137.915C717.165 138.225 717.251 138.512 717.227 138.783C717.204 139.054 717.088 139.302 716.863 139.527L715.522 140.868L715.537 140.891Z"
          fill="white"
        />
        <path
          d="M313.652 654.343L310.892 646.654L313.466 645.065C313.853 644.824 314.233 644.677 314.613 644.615C314.993 644.561 315.334 644.6 315.652 644.731C315.969 644.863 316.233 645.111 316.458 645.468C316.737 645.917 316.838 646.382 316.76 646.871C316.683 647.359 316.458 647.793 316.078 648.173C316.458 648.103 316.799 648.134 317.117 648.274C317.434 648.413 317.69 648.638 317.892 648.956C318.109 649.312 318.225 649.7 318.233 650.119C318.241 650.537 318.14 650.94 317.923 651.328C317.706 651.715 317.396 652.033 316.993 652.281L313.652 654.351V654.343ZM315.055 648.157C315.427 647.925 315.675 647.615 315.799 647.235C315.923 646.855 315.869 646.483 315.644 646.119C315.442 645.793 315.194 645.623 314.892 645.6C314.59 645.576 314.233 645.693 313.822 645.948L312.094 647.018L312.962 649.46L315.055 648.165V648.157ZM316.411 651.537C316.783 651.312 317.031 651.01 317.171 650.63C317.303 650.258 317.241 649.855 316.969 649.421C316.783 649.119 316.535 648.94 316.241 648.886C315.946 648.832 315.613 648.917 315.249 649.142L313.288 650.359L314.194 652.909L316.404 651.537H316.411Z"
          fill="black"
        />
        <path
          d="M320.008 650.406L318.031 644.917L318.853 644.406L319.241 645.483C319.202 645.049 319.264 644.669 319.419 644.336C319.574 644.011 319.845 643.724 320.225 643.491L320.597 643.266L320.93 644.173L320.574 644.398C320.147 644.662 319.868 645.034 319.737 645.514C319.605 645.995 319.659 646.584 319.899 647.274L320.845 649.902L320.023 650.413L320.008 650.406Z"
          fill="black"
        />
        <path
          d="M321.132 641.778L320.721 640.662L321.643 640.088L322.054 641.204L321.132 641.778ZM323.535 648.212L321.558 642.724L322.38 642.212L324.356 647.7L323.535 648.212Z"
          fill="black"
        />
        <path
          d="M328.263 645.421C327.845 645.685 327.418 645.824 327 645.863C326.573 645.894 326.178 645.809 325.798 645.607C325.418 645.406 325.093 645.08 324.814 644.646C324.449 644.057 324.248 643.468 324.201 642.879C324.155 642.29 324.263 641.747 324.511 641.266C324.76 640.778 325.124 640.39 325.612 640.088C325.984 639.863 326.349 639.724 326.721 639.685C327.093 639.639 327.442 639.701 327.759 639.856C328.085 640.018 328.341 640.259 328.55 640.592L327.728 641.104C327.527 640.778 327.279 640.6 326.969 640.561C326.666 640.522 326.349 640.6 326.023 640.801L325.922 640.863C325.604 641.057 325.38 641.328 325.24 641.677C325.101 642.026 325.062 642.406 325.124 642.832C325.186 643.258 325.341 643.677 325.597 644.088C325.876 644.537 326.209 644.817 326.604 644.941C327 645.065 327.411 644.995 327.821 644.739L327.907 644.685C328.217 644.491 328.426 644.235 328.542 643.917C328.658 643.6 328.666 643.266 328.565 642.917L329.387 642.406C329.596 642.995 329.596 643.561 329.379 644.111C329.162 644.662 328.783 645.103 328.248 645.437L328.263 645.421Z"
          fill="black"
        />
        <path
          d="M331.131 643.507L328.372 635.817L329.193 635.306L330.953 640.181L332.433 635.988L333.604 635.259L332.333 638.801L335.991 640.499L335.015 641.104L332.007 639.701L331.395 641.437L331.961 642.995L331.139 643.507H331.131Z"
          fill="black"
        />
        <path
          d="M341.596 637.158C341.2 637.406 340.789 637.522 340.363 637.515C339.937 637.507 339.58 637.391 339.286 637.166L339.658 638.22L338.836 638.732L336.077 631.042L336.898 630.531L337.929 633.383C337.976 633.065 338.084 632.771 338.262 632.492C338.441 632.213 338.681 631.98 338.991 631.786C339.34 631.569 339.712 631.453 340.107 631.445C340.503 631.43 340.882 631.53 341.247 631.748C341.611 631.965 341.929 632.282 342.2 632.716C342.557 633.29 342.766 633.871 342.828 634.453C342.89 635.034 342.812 635.561 342.595 636.034C342.378 636.507 342.045 636.887 341.603 637.158H341.596ZM341.107 636.491C341.394 636.313 341.611 636.057 341.751 635.724C341.898 635.391 341.944 635.011 341.898 634.585C341.844 634.158 341.681 633.724 341.409 633.282C341.138 632.848 340.82 632.561 340.441 632.422C340.061 632.282 339.689 632.321 339.317 632.554L339.231 632.608C338.89 632.817 338.65 633.112 338.518 633.484C338.386 633.856 338.355 634.251 338.433 634.678C338.51 635.104 338.665 635.507 338.906 635.895C339.076 636.174 339.286 636.383 339.526 636.53C339.766 636.677 340.014 636.755 340.278 636.755C340.541 636.755 340.789 636.685 341.022 636.546L341.107 636.491Z"
          fill="black"
        />
        <path
          d="M344.936 637.352L344.642 636.53L345.867 635.771C345.96 635.716 346.029 635.654 346.076 635.585C346.122 635.515 346.146 635.437 346.146 635.337C346.146 635.236 346.122 635.127 346.068 634.988L345.634 633.763L346.037 634.453L341.875 630.143L342.735 629.608L346.076 633.096L345.719 633.251L345.843 627.686L346.766 627.112L346.611 634.096L346.487 633.314L347.014 634.802C347.138 635.143 347.161 635.453 347.076 635.709C346.991 635.972 346.82 636.182 346.549 636.352L344.936 637.352Z"
          fill="black"
        />
        <path
          d="M354.688 629.05C354.292 629.29 353.882 629.414 353.455 629.407C353.029 629.399 352.672 629.283 352.378 629.058L352.75 630.12L351.928 630.631L349.169 622.942L349.99 622.43L351.021 625.283C351.068 624.965 351.176 624.67 351.355 624.391C351.533 624.112 351.773 623.88 352.083 623.686C352.432 623.469 352.804 623.353 353.199 623.345C353.595 623.329 353.975 623.43 354.339 623.647C354.703 623.856 355.021 624.182 355.292 624.616C355.649 625.19 355.858 625.771 355.92 626.352C355.982 626.934 355.905 627.461 355.688 627.934C355.471 628.407 355.137 628.786 354.695 629.058L354.688 629.05ZM354.199 628.383C354.486 628.205 354.703 627.949 354.843 627.616C354.99 627.283 355.037 626.903 354.99 626.476C354.936 626.05 354.773 625.616 354.502 625.174C354.23 624.74 353.913 624.453 353.533 624.314C353.153 624.174 352.781 624.213 352.409 624.446L352.324 624.5C351.982 624.709 351.742 625.004 351.61 625.376C351.479 625.748 351.448 626.151 351.525 626.57C351.603 626.996 351.758 627.399 351.998 627.786C352.168 628.066 352.378 628.275 352.618 628.422C352.858 628.569 353.106 628.647 353.37 628.647C353.634 628.647 353.882 628.577 354.114 628.438L354.199 628.383Z"
          fill="black"
        />
        <path
          d="M357.718 627.043L355.742 621.554L356.563 621.043L356.951 622.12C356.912 621.686 356.974 621.306 357.129 620.981C357.284 620.655 357.548 620.369 357.935 620.136L358.308 619.911L358.641 620.818L358.284 621.043C357.858 621.306 357.579 621.678 357.447 622.159C357.315 622.64 357.37 623.229 357.61 623.919L358.548 626.546L357.726 627.058L357.718 627.043Z"
          fill="black"
        />
        <path
          d="M358.85 618.415L358.439 617.299L359.362 616.725L359.773 617.841L358.85 618.415ZM361.253 624.849L359.276 619.361L360.098 618.849L362.075 624.337L361.253 624.849Z"
          fill="black"
        />
        <path
          d="M365.981 622.058C365.563 622.322 365.136 622.469 364.718 622.5C364.299 622.531 363.896 622.446 363.516 622.244C363.137 622.043 362.811 621.717 362.54 621.283C362.175 620.694 361.974 620.105 361.927 619.516C361.881 618.927 361.989 618.384 362.237 617.903C362.485 617.415 362.858 617.028 363.338 616.725C363.71 616.5 364.075 616.361 364.447 616.322C364.819 616.283 365.167 616.338 365.485 616.493C365.811 616.655 366.074 616.896 366.276 617.229L365.454 617.741C365.253 617.415 364.997 617.237 364.695 617.198C364.392 617.159 364.075 617.237 363.749 617.438L363.656 617.5C363.338 617.694 363.113 617.965 362.974 618.314C362.834 618.663 362.796 619.043 362.858 619.469C362.92 619.896 363.075 620.314 363.33 620.725C363.609 621.175 363.943 621.454 364.338 621.578C364.733 621.702 365.144 621.632 365.555 621.376L365.64 621.322C365.95 621.128 366.16 620.872 366.276 620.554C366.392 620.229 366.4 619.903 366.299 619.555L367.121 619.043C367.33 619.632 367.33 620.198 367.113 620.748C366.896 621.299 366.516 621.74 365.981 622.074V622.058Z"
          fill="black"
        />
        <path
          d="M368.849 620.144L366.09 612.454L366.912 611.943L368.671 616.818L370.152 612.625L371.33 611.896L370.059 615.438L373.717 617.136L372.741 617.741L369.733 616.338L369.121 618.074L369.687 619.632L368.865 620.144H368.849Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2560_11952">
          <rect width="967" height="694" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <EndorserPhone />
  </Box>
);

export default ColorStack;
