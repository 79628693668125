import React from "react";
import { BlankColorInterface } from "../../../color_picker/interface/blank-color-interface";
import { CollectionProductInterface } from "../../../../pages/shop-all/interfaces/collection-interfaces";
import CustomPolishProductCard from "../../../../components/product-card/custom-product-card";
import ShopifyPolishProductCard from "../../../../components/product-card/shopify-product-card";

interface ProductCardContainerProps {
  productType: "shopify" | "custom";
  shopifyProduct?: CollectionProductInterface;
  customProduct?: BlankColorInterface;
  landing?: boolean;
}
const ProductCardContainer = ({
  shopifyProduct,
  customProduct,
  productType,
  landing,
}: ProductCardContainerProps) => (
  <>
    <ShopifyPolishProductCard landing={landing!} product={shopifyProduct!} />
    {/* {productType === "shopify" ? (
      <ShopifyPolishProductCard landing={landing!} product={shopifyProduct!} />
    ) : (
      <CustomPolishProductCard color={customProduct!} />
    )} */}
  </>
);

export default ProductCardContainer;
