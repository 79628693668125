import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";

import { FETCH_META_ENTRY_BY_HANDLE } from "../services/graphQl/queries/fetch-meta-entry-by-handle";
import { graphqlClientRequest } from "../services/graphQl/graphQl-common";

export const useGetHeaderEntry = () => {
  const [blankHeaders, setBlankHeaders] =
    useState<string[]>([]);

  const [isSuccess, setIsSuccess] = useState<boolean>(true);

  useEffect(() => {
    const getHeader = async () => {

      try {
        const headers = await fetchHeaderEntry({ type: `header`, handle: 'header-4k6b5w6u' })
        if (headers) {
          setBlankHeaders(headers);
          setIsSuccess(true)
        }
      } catch (err) {
        setIsSuccess(false);
        Sentry.captureException(err);
      }
    };
    getHeader();
  }, []);

  return { blankHeaders, isSuccess };
};


export const fetchHeaderEntry = async (handle: {
  type: string,
  handle: string
}) => {
  const data = {
    query: FETCH_META_ENTRY_BY_HANDLE,
    variables: {
      handle: handle,
    },
  };
  try {
    const res = await graphqlClientRequest.then((res) => res.post(data));
    if (res.data.errors) throw res.data.errors[0].message;


    const headers: string[] = JSON.parse(res.data.data.metaobject.display.value)

    return headers


  } catch (e) {
    throw new Error(`fetch header error: ${e}`);
  }
};