import React from "react";
import { Box, Typography } from "@mui/material";
import BlankCameraIcon from "../../components/icons/camera-icon";
import BlankPhotosIcon from "../../components/icons/photos-icon";
import BlankPalettesIcon from "../../components/icons/palettes-icon";
import HowItWorksCreateGraphics from "./components/hiw-graphics/how-it-works-create-graphics";
import CameraJourneyButton from "./components/color-journey-buttons/camera-journey-btn";
import PhotoPickerJourneyButton from "./components/color-journey-buttons/photo-picker-btn";
import PalettesJourneyButton from "./components/color-journey-buttons/palettes-btn";
import ColorJourneyTitle from "./components/color-journey-title";
import { useCheckMobileScreen } from "../../utils/hooks/useCheckMobileScreen";

interface HomePageProps {
  handleInputAction: () => void;
  handleCameraAction: () => void;
  handleLibraryNavigate: () => void;
  hiddenFileInput: React.RefObject<HTMLInputElement> | null;
  hiddenCameraInput: React.RefObject<HTMLInputElement> | null;
  isWalmart: boolean;
  isKiosk: boolean;
}

const HomePage = (props: HomePageProps) => {
  const { isMobile } = useCheckMobileScreen();
  const isKiosk = () => {
    const url = window.location.href;
    return url.includes("kiosk");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: { xs: "100%", md: "1400px" },
        overflow: "auto",
        m: "0 auto",
        pt: { xs: 6, md: 9 },
        pb: { xs: 4, md: 7 },
      }}
    >
      <ColorJourneyTitle isWalmart={isKiosk()} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column-reverse", md: "row-reverse" }}
          mt={{ xs: 2.5, md: 3.5 }}
        >
          <CameraJourneyButton
            icon={<BlankCameraIcon disabled={!isMobile} />}
            navFunction={props.handleCameraAction}
            buttonText="use your camera"
            hiddenFileInput={props.hiddenCameraInput}
          />
          <Box display="flex" flexDirection="column" alignItems="center">
            <PhotoPickerJourneyButton
              lightIcon={<BlankPhotosIcon iconColor="black" />}
              darkIcon={<BlankPhotosIcon iconColor="white" />}
              navFunction={props.handleInputAction}
              buttonText="select from your photos"
              hiddenFileInput={props.hiddenFileInput}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            mt={{ xs: 0.5, md: 3, xl: 1.5 }}
            mb={{ xs: 0.25, md: 2.25, xl: 1 }}
          >
            <Typography fontWeight="600">or</Typography>
          </Box>
          <PalettesJourneyButton
            icon={<BlankPalettesIcon />}
            navFunction={props.handleLibraryNavigate}
            buttonText="pick from our ready-made colors"
          />
        </Box>
      </Box>
      <HowItWorksCreateGraphics />
    </Box>
  );
};

export default HomePage;
