import React from "react";

const CloseModalIcon = () => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19.5" cy="19.5" r="19.5" fill="white" fillOpacity="0.75" />
    <path
      d="M13 14L25 26"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M13 26L25 14"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default CloseModalIcon;
