import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MakeItYoursTitle = () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Box mb={{ xs: 1.5, sm: 2.5 }} width="100%">
      <Typography
        textAlign={{ xs: "center", md: "left" }}
        variant="h3"
        fontSize={{ xs: "30px", sm: "48px" }}
        lineHeight="38px"
      >
        Make it custom
      </Typography>
    </Box>
    <Box width={{ xs: "315px", sm: "460px" }}>
      <Typography
        textAlign={{ xs: "center", md: "left" }}
        fontFamily="ABC Favorit Book"
        fontStyle="italic"
        fontSize={{ xs: "16px", sm: "20px" }}
        lineHeight={{ xs: "21px", sm: "27px" }}
      >
        Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi.
      </Typography>
    </Box>
  </Box>
);
export default MakeItYoursTitle;
