import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const LoaderSkeleton = () => (
  <Box
    width={{ xs: "100%", md: "400px" }}
    height={{ xs: "253px", md: "205px" }}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <Box mb={1} width="100%" height={{ xs: "150px", md: "100px" }}>
      <Skeleton height="100%" width="100%" />
    </Box>
    <Box mb={1}>
      <Skeleton
        animation="wave"
        variant="text"
        sx={{ fontSize: "2.125rem", width: "120px" }}
      />
    </Box>
    <Box height="38px" width={{ xs: "240px", sm: "190px" }}>
      <Skeleton animation="wave" variant="rectangular" />
    </Box>
  </Box>
);
export default LoaderSkeleton;
