export const CREATE_CHECKOUT_MUTATION = `
mutation createCheckout($input:CheckoutCreateInput!) {
	checkoutCreate(input: $input) {
		checkout {
			id, 
			webUrl,
			 discountApplications(first:5){edges{node{targetSelection}}}
			lineItems(first:5) {
				edges {
					node {
						title
						quantity
					}
				}
			}
		}
	}
}
`;
