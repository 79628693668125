import React, { useEffect, useMemo, useRef } from "react";
import * as Sentry from "@sentry/react";
import AppRouter from "./routing/app-router";
import { useAppDispatch, useAppSelector } from "./utils/hooks/redux-hooks";
import { fetchCart } from "./pages/cart/state/cart-actions";
import { getRandomDrawerColor } from "./utils/get-random-drawer-color";
import { setAppBarColor } from "./pages/landing/state/landing-slice";
import PixelService from "./services/pixels/pixel-service";
import useExternalScripts from "./utils/hooks/useExternalScripts";
import { useLocation } from "react-router-dom";
import {
  selectAttentiveDiscount,
  setAttentiveDiscount,
} from "./pages/cart/state/cart-slice";
import "./App.css";
import { setIsWalmart } from "./pages/walmart/state/walmart-slice";

function App() {
  const rendered = useRef<boolean>(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const attnDiscount = useAppSelector(selectAttentiveDiscount);
  const url = window.location.href;
  const walmartUrl = url.includes("walmart") || url.includes("Walmart") ;
  useExternalScripts({ url: "https://cdn.attn.tv/blankbeauty/dtag.js" });
  useEffect(() => {
    
    if (rendered.current) {
      if (walmartUrl) {
        dispatch(setIsWalmart(true));
      } else {
        dispatch(setIsWalmart(false));
      }
      const attDiscount = params.get("discount");
      if (attnDiscount === null) {
        dispatch(setAttentiveDiscount(attDiscount));
      }
      PixelService.pixelsInit();
      const bg = getRandomDrawerColor();
      dispatch(setAppBarColor(bg));
      const cartId = localStorage.getItem("cartId");
      if (process.env.NODE_ENV !== "production") {
        console.log("Cart id: ", cartId);
      }
      if (cartId) dispatch(fetchCart(cartId));
    }
    return () => {
      rendered.current = false;
    };
  }, [attnDiscount, dispatch, params, walmartUrl]);

  return <AppRouter />;
}

export default Sentry.withProfiler(App);
