import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  subTitle: string;
}

const CollectionProductTitle = ({ subTitle }: Props) => (
  <Box
    width="100%"
    display="flex"
    flexDirection="column"
    alignItems="center"
    py={{ xs: 3.75, md: 7 }}
  >
    <Box mb={2.5} px={3} maxWidth="900px">
      <Typography
        variant="h4"
        component="h3"
        textAlign="center"
        fontSize={{ xs: "22px", sm: "25.5px", md: "28.5px" }}
        lineHeight="32px"
      >
        {subTitle}
      </Typography>
    </Box>
    <Box
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      px={{ xs: 2, sm: 0 }}
      mb={{ xs: 3 }}
    >
    
      <li
        style={{
          paddingLeft: "1em",
          fontFamily: "ABC Favorit Book",
          fontStyle: "italic",
          fontSize: "16px",
          listStyleType: 'none',
        }}
      >
        13-free & vegan
      </li>
      <li
        style={{
          paddingLeft: "1em",
          fontFamily: "ABC Favorit Book",
          fontStyle: "italic",
          fontSize: "18px",
        }}
      >
        7+ days of wear
      </li>

      <li
        style={{
          paddingLeft: "1em",
          fontFamily: "ABC Favorit Book",
          fontStyle: "italic",
          fontSize: "18px",
        }}
      >
        Made to order
      </li>
    </Box>
  </Box>
);

export default CollectionProductTitle;
