import React from "react";
import Box from "@mui/material/Box";

interface Props {
  children: JSX.Element[];
}
const HorizontalScrollContainer = ({ children }: Props) => {
  return (
    <Box width="100%" position="relative" overflow="hidden">
      <Box
        width="100%"
        display="flex"
        pl={3}
        pr={1}
        pb={2}
        sx={{
          overflowX: "auto",

          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box width={{ xs: "1100px", sm: "800px", md: "1100px" }} display="flex">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default HorizontalScrollContainer;
